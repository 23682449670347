import React, { useState } from "react";
import { Col, message, Modal, Row, Typography } from "antd";

import CLoadingButton from "../../InputFields/CLoadingButton";
import Remark from "../BankAccount/Remark";
import { getAPIData } from "../../../apiHelper";

const { Title } = Typography;

export default function ConfirmationModal({
  handleClose,
  userId,
  bankId,
  btnType,
  onSave = () => {},
}) {
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [yesBtnLoading, setYesBtnLoading] = useState(false);

  console.log("btnType========>", btnType);

  const user_id = userId;
  const bank_id = bankId;
  const action_button = btnType;

  const documentUploadData = () => {
    setYesBtnLoading(true);
    const dataDoc = { user_id, bank_id, action_button };
    const endPoint = "bank-agent/approve-reject-status";
    getAPIData(endPoint, "post", dataDoc, false, "bankAcc")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setYesBtnLoading(false);
          onSave();
        } else {
          message.warning(result?.message);
          setYesBtnLoading(false);
        }
        setYesBtnLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setYesBtnLoading(false);
      });
  };

  const openModal = (type) => {
    if (type === "remark") {
      setModalType(type);
      setModalTitle("Remark");
      setModalWidth(500);
    }
  };

  const close = () => {
    setModalType("");
  };

  const yesBtnClose = () => {
    openModal("remark");
    handleClose();
  };

  return (
    <div>
      <div style={{ justifyContent: "center" }}>
        <Title
          level={5}
          className="poppins_regular"
          style={{ textAlign: "center" }}
        >
          Are you sure?
        </Title>
      </div>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={12}>
          <CLoadingButton
            size="large"
            value="NO"
            className="poppins_medium umoney_modal_outlined_btn"
            // loading={NobtnLoading}
            onClick={() => {
              // setConfirmation(false);
              // setNobtnLoading(true);
              handleClose("");
            }}
            block
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <CLoadingButton
            size="large"
            value=" YES"
            className="poppins_medium umoney_modal_filled_btn"
            loading={yesBtnLoading}
            onClick={() => {
              btnType === "3" ? documentUploadData() : yesBtnClose();
            }}
            block
          />
        </Col>
      </Row>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={close}
        centered
        width={modalWidth}
        footer={false}
      >
        {modalType === "remark" ? (
          <Remark
            userId={userId}
            bankId={bankId}
            btnType={btnType}
            onSave={() => {
              setModalType("");
              onSave();
            }}
          />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}
