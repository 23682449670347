import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  Tag,
  Modal,
  Collapse,
  Form,
  Input,
  Select,
  Radio,
  message,
  Spin,
  Button,
  Tabs,
} from "antd";
import Sidebar from "../../components/Sidebar";
import CTextField from "../../components/InputFields/CTextField";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import Images from "../../config/images";
import UpdateDetailsForFather from "../../components/Modals/CreditCheckAdminPanel/UpdateDetailsForFather";
import UpdateDetailForMother from "../../components/Modals/CreditCheckAdminPanel/UpdateDetailForMother";
import UpdateDetailForOther from "../../components/Modals/CreditCheckAdminPanel/UpdateDetailForOther";
import HistoryS9 from "../../components/Modals/CreditCheckAdminPanel/HistoryModalCreditCheck";
import UploadFiles from "../../components/Accordion/UploadFiles";
import { useLocation, useNavigate } from "react-router-dom";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import ClearInApp from "../../components/Modals/modal/ClearInApp";
import Viewchangelog from "../../components/Modals/modal/Viewchangelog";
import CallStatusLogModal from "../../components/Modals/modal/CommonModal/CallStatusLogModal";
import authActions from "../../redux/reducers/auth/action";
import commonActions from "../../redux/reducers/common/action";
import { credit_check_url, setHeader } from "../../api";
import axios from "axios";
import {
  MenuOutlined,
  CheckSquareOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined,
  BorderOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ClickToCallModal from "../../components/Modals/modal/ClickToCallModal";
import CallHistory from "../../components/Modals/modal/CallHistory";
import Algo from "../../components/Modals/DeviceChange/Algo";
import PhonePermissionCreditCheck from "../../components/Modals/CreditCheckAdminPanel/PhonePermissionCreditCheck";

const { user } = Images;

const { Search } = Input;

const { Title, Text } = Typography;
const { Header, Content } = Layout;
const { Panel } = Collapse;

const cibilPhoneColumns = [
  {
    title: "TYPE CODE",
    dataIndex: "type_code",
    key: "type_code",
  },
  {
    title: "REPORTED DATE",
    dataIndex: "reported_date",
    key: "reported_date",
  },
  {
    title: "NUMBER",
    dataIndex: "number",
    key: "number",
  },
];

const radioValues = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
  {
    value: "2",
    label: "Can't decide",
  },
];

const DetailviewCreditCheck = () => {
  const role = useSelector((state) => state.userData.accessRoleCode);
  // const role = "approver";
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const m_num = location?.pathname.split("/");
  const paramname = m_num[1];

  var verifiedApproved = "#3EA467";
  var pending = "#DE1717";
  var completed = "#ffbf00";
  var checked = "#0000FF";

  const [newUserId, setNewUserId] = useState();
  const dispatch = useDispatch();

  const [login, setLogin] = useState(false);
  const { setLoginTypeData, setRoleCode } = commonActions;
  const { setAccessToken } = authActions;

  const user_id =
    paramname === "dialer-credit-check-detail-view"
      ? newUserId
      : queryParameters.get("user_id");
  const agent_id = queryParameters.get("user");
  // const password = queryParameters.get("password");
  const mobile_num = queryParameters.get("phone_number");
  const unique_id = queryParameters.get("uniqueid");
  const call_id = queryParameters.get("call_id");
  const SIPexten = queryParameters.get("SIPexten");
  const CallResponse = queryParameters.get("call_response");
  const campaign = queryParameters.get("campaign");
  // const closer = queryParameters.get("closer");
  // const userName = queryParameters.get("user");
  // const agent_fullname = queryParameters.get("agent_fullname");
  const recording_id = queryParameters.get("recording_id");
  const recording_filename = queryParameters.get("recording_filename");

  const login_type = useSelector((state) => state.userData.accessLoginType);
  // const agent_role = useSelector((state) => state.userData.accessRoleName);

  const navigate = useNavigate();

  const pageType = "credit-check-detail";

  console.log("user_id=============>", user_id);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [spinLoading, setSpinLoading] = useState(false);

  const [form] = Form.useForm();
  const [incomeForm] = Form.useForm();
  const [spendingForm] = Form.useForm();
  const [callStatusForm] = Form.useForm();
  const [assetForm] = Form.useForm();
  const [topForm] = Form.useForm();
  const [userCibilForm] = Form.useForm();
  const [socialMediaForm] = Form.useForm();
  const [informationForm] = Form.useForm();

  //pagination
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);

  //user Data
  const [userData, setUserData] = useState();

  const [notificationForm] = Form.useForm();
  const [smsForm] = Form.useForm();

  //call status
  const [callStatusSelectData, setCallStatusSelectData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [callStatusBtnLoading, setCallStatusBtnLoading] = useState(false);

  const [userProvidedData, setUserProvidedData] = useState();
  const [matchData, setMatchData] = useState();

  const [partTimeVal, setPartTimeVal] = useState("");
  const [laptopVal, setLaptopVal] = useState("");
  const [vehicleVal, setVehicleVal] = useState("");
  const [fourVehicle, setFourVehicle] = useState("");

  const [livePlace, setLivePlace] = useState("");
  const [hostelRent, setHostelRent] = useState("");
  const [extraExpenditure, setExtraExpenditure] = useState();

  const [selectedTab, setSelectedTab] = useState("");

  // send Notification, sms, whatsapp
  const [notiSendBtnLoading, setNotiSendBtnLoading] = useState(false);
  const [smsSendBtnLoading, setSmsSendBtnLoading] = useState(false);
  const [whatsappSendBtnLoading, setWhatsappSendBtnLoading] = useState(false);

  // Notification List api
  const [notificationData, setNotificationData] = useState([]);

  // SMS List Api
  const [smsData, setSmsData] = useState([]);

  // whatsapp list api
  const [whatsappData, setWhatsappData] = useState([]);

  //Table Loading
  const [notificationTableLoading, setNotificationTableLoading] =
    useState(true);
  const [smsTableLoading, setSmsTableLoading] = useState(true);
  const [whatsappTableLoading, setWhatsappTableLoading] = useState(true);
  const [parentsTableLoading, setParentsTableLoading] = useState(false);
  const [deviceData, setDeviceData] = useState();

  // Search Device Log
  const [resultDevice, setResultDevice] = useState();

  //income check
  const [isApprove, setIsApprove] = useState(false);
  const [saveIncomeLoading, setSaveIncomeLoading] = useState(false);
  const [saveAppIncomeLoading, setSaveAppIncomeLoading] = useState(false);

  //Asset check
  const [saveAppAssetLoading, setSaveAppAssetLoading] = useState(false);
  const [saveAssetLoading, setSaveAssetLoading] = useState(false);

  //device list
  const [deviceListData, setDeviceListData] = useState([]);
  const [deviceListDrp, setDeviceListDrp] = useState([]);
  const [deviceList, setDeviceList] = useState();

  const [primaryDeviceList, setPrimaryDeviceList] = useState();
  const [primaryDeviceDrp, setPrimaryDeviceDrp] = useState();

  //spending check
  const [saveSpendingLoading, setSaveSpendigLoading] = useState(false);
  const [saveSpendingAppLoading, setSaveSpendingAppLoading] = useState(false);

  //call api for four and two wheeler response
  const [callApiResponseTwo, setCallApiResponseTwo] = useState();
  const [callApiResponseFour, setCallApiResponseFour] = useState();
  const [userFullName, setUserFullName] = useState();
  const [userRole, setUserRole] = useState();
  const [apiTwoWheelerLoading, setApiTwoWheelerLoading] = useState(false);
  const [apiFourWheelerLoading, setApiFourWheelerLoading] = useState(false);

  //parent contact save
  const [parentContactSaveLoading, setParentContactSaveLoading] =
    useState(false);

  // User cibil save
  const [userCibilSaveLoading, setUserCibilSaveLoading] = useState(false);

  //export contact
  const [isDownload, setIsDownload] = useState(false);

  // social media
  const [fbValue, setFbValue] = useState();
  const [lnValue, setLnValue] = useState();
  const [socialMediaSaveLoading, setSocialMediaSaveLoading] = useState(false);
  const [socialMediaAppLoading, setSocialMediaAppLoading] = useState(false);

  const [disable, setDisable] = useState(true);
  const [device_id, setDevice_id] = useState();
  const [value, setValue] = useState();

  const [changeLoading, setChangeLoading] = useState(false);

  const [cibilPhoneData, setCibilPhoneData] = useState();

  const [dialerRole, setDialerRole] = useState();

  const [ok, setOk] = useState(false);

  const [relationId, setRelationId] = useState();

  const [partTimeWork, setPartTimeWork] = useState();

  const [userRecord, setUserRecord] = useState();

  const [twoWheelerData, setTwoWheelerData] = useState();
  const [fourWheelerData, setFourWheelerData] = useState();

  const onFinishInformation = (data) => {
    console.log("success =====>>>>> ", data);
  };

  const [callApiTwoWheelerCount, setCallApiTwoWheelerCount] = useState(0);
  const [callApiFourWheelerCount, setCallApiFourWheelerCount] = useState(0);

  const livingData = [
    {
      value: "1",
      label: "College Hostel",
    },
    {
      value: "2",
      label: "PG/Hostel",
    },
    {
      value: "3",
      label: "With Parents",
    },
    {
      value: "4",
      label: "With Relatives/Friends",
    },
  ];

  const radioData = [
    {
      value: "1",
      label: "Yes",
    },
    {
      value: "0",
      label: "No",
    },
  ];

  const familyIncomeData = [
    {
      value: 1,
      label: "<2.5 lacs",
    },
    {
      value: 2,
      label: "Between 2.5 lacs to 5 lacs",
    },
    {
      value: 3,
      label: "Between 5 lacs to 10 lacs",
    },
    {
      value: 4,
      label: "Between 10 lacs to 50 lacs",
    },
    {
      value: 5,
      label: "Between 50 lacs to 1 crore",
    },
    {
      value: 6,
      label: ">1 crore",
    },
  ];

  const moduleOptions = [
    {
      value: "1",
      label: "KYC Details",
    },
    {
      value: "2",
      label: "PAN Details",
    },
    {
      value: "3",
      label: "Personal Information Details",
    },
    {
      value: "4",
      label: "Student Information Details",
    },
    {
      value: "5",
      label: "Selfie Details",
    },
    {
      value: "6",
      label: "Video Details",
    },
  ];
  const smscolumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "20%",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "SMS CONTENT",
      dataIndex: "content",
      key: "content",
      width: "35%",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "DELIVERY STATUS",
      dataIndex: "delivery_status",
      key: "delivery_status",
      render: (text, record) => (
        <div>
          <span>
            {record.delivery_status === "1"
              ? "Sent"
              : record.delivery_status === "2"
              ? "Delivered"
              : record.delivery_status === "3"
              ? "Undelivered"
              : ""}
          </span>
        </div>
      ),
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      width: "16%",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  const loadUserData = () => {
    setLoading(true);
    const endPoint = `cc-student/student-data/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setUserData(response);
            setTwoWheelerData(response?.assets?.two_wheeler);
            setFourWheelerData(response?.assets?.four_wheeler);
            setUserProvidedData(response?.contacts?.contact_info);
            setMatchData(response?.contacts?.matchOtherData);
            setPartTimeVal(response?.income?.work_part_time);
            setVehicleVal(response?.assets?.two_wheeler?.have_two_wheeler);
            setLaptopVal(response?.assets?.laptop?.have_laptop);
            setFourVehicle(response?.assets?.four_wheeler?.have_four_wheeler);
            setUserRole(response?.userData?.userType);
            setCibilPhoneData(response?.cibil?.phone_data);
            setExtraExpenditure(response?.spending?.extra_expenditure);
            setPartTimeWork(response?.income?.work_part_time);
            console.log("setTwoWheelerData", twoWheelerData);
            console.log("partTime====================>", partTimeWork);
            var user = response?.userData?.userName;
            var onlyName = user.split("(");
            var finalName = onlyName[0];
            setUserFullName(finalName);
            incomeForm.setFieldsValue({
              pocket_money_amount: response?.income?.pocket_money_amount,
              describe_part_time_work:
                response?.income?.describe_part_time_work,
              work_part_time: response?.income?.work_part_time,
              part_time_working_hours:
                response?.income?.part_time_working_hours,
              part_time_earn_amount: response?.income?.part_time_earn_amount,
              family_monthly_income_id:
                response?.spending?.family_monthly_income_id,
            });
            spendingForm.setFieldsValue({
              pocket_money_amount: response?.income?.pocket_money_amount,
              average_monthly_spend: response?.spending?.average_monthly_spend,
              currently_stay: response?.spending?.currently_stay,
              parents_provide_extra_money:
                response?.spending?.parents_provide_extra_money,
              describe_part_time_work:
                response?.income?.describe_part_time_work,
              family_monthly_income_id:
                response?.spending?.family_monthly_income_id,
              extra_expenditure: response?.spending?.extra_expenditure,
            });
            setLivePlace(response?.spending?.currently_stay);
            assetForm.setFieldsValue({
              have_laptop: response?.assets?.laptop?.have_laptop,
              laptop_cost: response?.assets?.laptop?.laptop_cost,
              plan_buy_two_wheeler_in_next_6_month:
                response?.assets?.two_wheeler
                  ?.plan_buy_two_wheeler_in_next_6_month,
              plan_buy_laptop_in_next_6_month:
                response?.assets?.laptop?.plan_buy_laptop_in_next_6_month,
              have_two_wheeler: response?.assets?.two_wheeler?.have_two_wheeler,
              two_wheeler_model:
                response?.assets?.two_wheeler?.two_wheeler_model,
              two_wheeler_vehicle_number:
                response?.assets?.two_wheeler?.two_wheeler_vehicle_number,
              have_four_wheeler:
                response?.assets?.four_wheeler?.have_four_wheeler,
              four_wheeler_model:
                response?.assets?.four_wheeler?.four_wheeler_model,
              four_wheeler_vehicle_number:
                response?.assets?.four_wheeler?.four_wheeler_vehicle_number,
              average_monthly_spend:
                response?.assets?.spending?.average_monthly_spend,
            });

            userCibilForm.setFieldsValue({
              no_of_accounts:
                response?.cibil?.retail_accounts_summary_data?.no_of_accounts,
              no_of_active_accounts:
                response?.cibil?.retail_accounts_summary_data
                  ?.no_of_active_accounts,
              no_of_write_offs:
                response?.cibil?.retail_accounts_summary_data?.no_of_write_offs,
              total_past_due:
                response?.cibil?.retail_accounts_summary_data?.total_past_due,
              most_severe_status_with_in_24_months:
                response?.cibil?.retail_accounts_summary_data
                  ?.most_severe_status_with_in_24_months,
              single_highest_credit:
                response?.cibil?.retail_accounts_summary_data
                  ?.single_highest_credit,
              single_highest_sanction_amount:
                response?.cibil?.retail_accounts_summary_data
                  ?.single_highest_sanction_amount,
              total_high_credit:
                response?.cibil?.retail_accounts_summary_data
                  ?.total_high_credit,
              average_open_balance:
                response?.cibil?.retail_accounts_summary_data
                  ?.average_open_balance,
              single_highest_balance:
                response?.cibil?.retail_accounts_summary_data
                  ?.single_highest_balance,
              no_of_past_due_accounts:
                response?.cibil?.retail_accounts_summary_data
                  ?.no_of_past_due_accounts,
              no_of_zero_balance_accounts:
                response?.cibil?.retail_accounts_summary_data
                  ?.no_of_zero_balance_accounts,
              recent_account_type:
                response?.cibil?.retail_accounts_summary_data
                  ?.recent_account_type,
              recent_account_date:
                response?.cibil?.retail_accounts_summary_data
                  ?.recent_account_date,
              oldest_account_type:
                response?.cibil?.retail_accounts_summary_data
                  ?.oldest_account_type,
              oldest_account_date:
                response?.cibil?.retail_accounts_summary_data
                  ?.oldest_account_date,
              total__balance_amount:
                response?.cibil?.retail_accounts_summary_data
                  ?.total__balance_amount,
              total_sanction_amount:
                response?.cibil?.retail_accounts_summary_data
                  ?.total_sanction_amount,
              total_credit_limit:
                response?.cibil?.retail_accounts_summary_data
                  ?.total__credit_limit,
              total_monthly_payment_amount:
                response?.cibil?.retail_accounts_summary_data
                  ?.total_monthly_payment_amount,
              type: response?.cibil?.score_details_data?.type,
              version: response?.cibil?.score_details_data?.version,
              name: response?.cibil?.score_details_data?.name,
              error_code: response?.cibil?.error?.error_code,
              error_description: response?.cibil?.error?.error_description,
              updatedAt: response?.cibil?.error?.updatedAt,
            });

            topForm.setFieldsValue({
              initiation_date: response?.actionData?.initiation_date,
              action_date: response?.actionData?.action_date,
              submission_date: response?.actionData?.submission_date,
            });

            socialMediaForm.setFieldsValue({
              geniune_fb: response?.socialMedia?.facebook?.is_genuine,
              remark_fb: response?.socialMedia?.facebook?.remarks,
              geniune_ln: response?.socialMedia?.linkedin?.is_genuine,
              remark_ln: response?.socialMedia?.linkedin?.remarks,
            });
            setLoading(false);
          }
          setLoading(false);
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const dialerData = () => {
    const data = {
      agent_id: agent_id,
      password: "12345678", // set static
      mobile_num: mobile_num,
    };
    const endPoint = "cc-student/dialer-login";
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status == true) {
          setLogin(true);
          var response = result?.data;
          console.log("response============", response);
          const token = response?.data?.token;
          const new_user_id = response?.data?.user_data?.user_id;
          console.log("new_user_id", new_user_id);
          setNewUserId(new_user_id);
          setDialerRole(response?.data?.user_data?.role);
          dispatch(setRoleCode(response?.data?.user_data?.role));
          localStorage.setItem("token", token);
          // dispatch(setLoginTypeData("dialer-credit-check"));
          dispatch(setLoginTypeData(response?.data?.user_data?.login_type));
          const lead_id = response?.data?.user_data?.lead_id;
          navigate({
            pathname: "/dialer-credit-check-detail-view",
            // search: `?mobile_no=${mobile_num}&&password=${password}&&agent_id=${agent_id}&&lead_id=${lead_id}`,
            search: `?phone_number=${mobile_num}&campaign=${campaign}&user=${agent_id}&uniqueid=${unique_id}&call_id=${call_id}&lead_id=${lead_id}&recording_id=${recording_id}&recording_filename=${recording_filename}&SIPexten=${SIPexten}`,
            // search: `?mobile_no=7048422928&&password=12345678&&agent_id=123&&lead_id=${lead_id}`,
            // dialer-detail-view?phone_number=&closer=&campaign&user=&agent_fullname=&uniqueid=&call_id=&lead_id=&recording_id=&recording_filename=&SIPexten=&password=
          });
          queryParameters.set("lead_id", response?.data?.user_data?.lead_id);
          console.log("lead_id====", lead_id);
          dispatch(setAccessToken(token));
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const callLogApi = () => {
    const data = {
      user_id: user_id,
      phone_number: mobile_num,
      phone_number_type: "PRIMARY", //need to set dynamic
      unique_id: unique_id, //random
      call_id: call_id, //random
      SIPexten: SIPexten, //random
      admin_apptype: userRole === "college-student" ? "stu_cc" : "sal_cc",
      Campaign: campaign, //random
      Iframe: dialerRole,
      call_type: "1", // need to set dynamic
      call_response: CallResponse, //random,
    };
    const endpoint = "cc-student/call-log-api";
    getAPIData(endpoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          console.log(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // send Sms
  const onFinishSms = (data) => {
    setSmsSendBtnLoading(true);
    setSpinLoading(true);
    console.log("success =====>>>>> ", data);

    const nData = {
      user_id: user_id,
      sms_temp_id: data?.sms_temp_id,
      comment: data?.comment,
    };

    const endPoint = "cc-student/send-sms";
    getAPIData(endPoint, "post", nData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          console.log("msg==========>", result.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
          smsForm.resetFields();
          loadsmsListData();
        } else {
          message.warning(result?.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSmsSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  const getTabValue = (value) => {
    if (value === "1") {
      setSelectedTab("Notification");
      smsForm.resetFields();
      form.resetFields();
    } else if (value === "2") {
      setSelectedTab("SMS");
      notificationForm.resetFields();
      form.resetFields();
    } else if (value === "3") {
      setSelectedTab("WHATSAPP");
      smsForm.resetFields();
      notificationForm.resetFields();
    }
  };

  //send notification
  const onFinishNotification = (data) => {
    setNotiSendBtnLoading(true);
    setSpinLoading(true);
    console.log("success =====>>>>> ", data);

    const nData = {
      user_id: user_id,
      notification_temp_id: data?.notification_temp_id,
      comment: data?.comment,
    };

    const endPoint = "cc-student/notificaton-send";
    getAPIData(endPoint, "post", nData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          notificationForm.resetFields();
          loadNotificationListData();
          setNotiSendBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setNotiSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setNotiSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  //Spending check Save Api
  const onFinishSpending = (data) => {
    if (isApprove) {
      setSaveSpendingAppLoading(true);
    } else {
      setSaveSpendigLoading(true);
    }
    setSpinLoading(true);
    const endPoint = "cc-steps/save-spending";
    const formData = {
      ...data,
      user_id: user_id,
      isApprove: isApprove,
      user_role: userRole,
    };
    // isApprove === true
    //   ? { ...data, user_id: user_id, isApprove: isApprove }
    //   : { ...data, user_id: user_id };
    getAPIData(endPoint, "post", formData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          setSpinLoading(false);
          if (isApprove) {
            setSaveSpendingAppLoading(false);
            setIsApprove(false);
          } else {
            setSaveSpendigLoading(false);
          }
          setChangeLoading(true);
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          if (isApprove) {
            setSaveSpendingAppLoading(false);
            setIsApprove(false);
          } else {
            setSaveSpendigLoading(false);
          }
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSpinLoading(false);
      });
  };

  const onFinishIncome = (data) => {
    if (isApprove) {
      setSaveAppIncomeLoading(true);
    } else {
      setSaveIncomeLoading(true);
    }
    setSpinLoading(true);
    const endPoint = "cc-steps/save-income";
    const formData = {
      ...data,
      user_id: user_id,
      isApprove: isApprove,
      user_role: userRole,
    };
    // isApprove === true
    //   ? { ...data, user_id: user_id, isApprove: isApprove }
    //   : { ...data, user_id: user_id };
    getAPIData(endPoint, "post", formData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success("Income data saved successfully");
          form.resetFields();
          if (isApprove) {
            setSaveAppIncomeLoading(false);
            setIsApprove(false);
          } else {
            setSaveIncomeLoading(false);
          }
          setChangeLoading(true);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setIsApprove(false);
          setSpinLoading(false);
          if (isApprove) {
            setSaveAppIncomeLoading(false);
            setIsApprove(false);
          } else {
            setSaveIncomeLoading(false);
          }
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSpinLoading(false);
      });
  };

  const onFinishAsset = (data) => {
    if (isApprove) {
      setSaveAppAssetLoading(true);
    } else {
      setSaveAssetLoading(true);
    }
    setSpinLoading(true);
    const endPoint = "cc-steps/save-assets";
    const formData = {
      ...data,
      user_id: user_id,
      isApprove: isApprove,
      user_role: userRole,
    };
    // isApprove === true
    //   ? { ...data, user_id: user_id, isApprove: isApprove }
    //   : { ...data, user_id: user_id };
    getAPIData(endPoint, "post", formData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          if (isApprove) {
            setSaveAppAssetLoading(false);
            setIsApprove(false);
          } else {
            setSaveAssetLoading(false);
          }
          setChangeLoading(true);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setIsApprove(false);
          if (isApprove) {
            setSaveAppAssetLoading(false);
            setIsApprove(false);
          } else {
            setSaveAssetLoading(false);
          }
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSpinLoading(false);
      });
  };

  const ApiClickTwoWheeler = async () => {
    setCallApiTwoWheelerCount(callApiTwoWheelerCount + 1);
    setApiTwoWheelerLoading(true);
    setSpinLoading(true);
    const header = setHeader();
    const endpoint = credit_check_url + "cc-student/get-vehicle-details";
    var vehicle_number = assetForm.getFieldValue("two_wheeler_vehicle_number");
    let vehicleNumber = vehicle_number.replace(/\s/g, "");
    const data = {
      user_id: user_id,
      vehicle_number: vehicleNumber,
      user_role: userRole,
      // full_name: userFullName,
    };
    const uploadData = await axios.post(endpoint, data, header);
    if (uploadData?.data?.status) {
      var response = uploadData?.data?.data;
      setCallApiResponseTwo(response);
      setSpinLoading(false);
      setApiTwoWheelerLoading(false);
    } else {
      message.warning(uploadData?.data?.message);
      setSpinLoading(false);
      setApiTwoWheelerLoading(false);
    }
    setSpinLoading(false);
    setApiTwoWheelerLoading(false);
  };

  const ApiClickFourWheeler = async () => {
    setCallApiFourWheelerCount(callApiFourWheelerCount + 1);
    setApiFourWheelerLoading(true);
    setSpinLoading(true);
    const header = setHeader();
    const endpoint = credit_check_url + "cc-student/get-vehicle-details";
    var vehicle_number = assetForm.getFieldValue("four_wheeler_vehicle_number");
    let vehicleNumber = vehicle_number.replace(/\s/g, "");
    const data = {
      user_id: user_id,
      vehicle_number: vehicleNumber,
      user_role: userRole,
      // full_name: userFullName,
    };
    const uploadData = await axios.post(endpoint, data, header);
    if (uploadData?.data?.status) {
      var response = uploadData?.data?.data;
      setCallApiResponseFour(response);
      setApiFourWheelerLoading(false);
      setSpinLoading(false);
    } else {
      message.warning(uploadData?.data?.message);
      setApiFourWheelerLoading(false);
      setSpinLoading(false);
    }
    setApiFourWheelerLoading(false);
    setSpinLoading(false);
  };

  const parentContactSave = async () => {
    setParentContactSaveLoading(true);
    setSpinLoading(true);
    const header = setHeader();
    const endpoint = credit_check_url + "cc-steps/save-parents-contact";
    const data = {
      isApprove: true,
      user_id: user_id,
      user_role: userRole,
    };
    const uploadData = await axios.post(endpoint, data, header);
    if (uploadData?.data?.status) {
      message.success(uploadData?.data?.message);
      setParentContactSaveLoading(false);
      setSpinLoading(false);
      setChangeLoading(true);
    } else {
      message.warning(uploadData?.data?.message);
      setParentContactSaveLoading(false);
      setSpinLoading(false);
    }
  };

  const userCibilSave = async () => {
    setUserCibilSaveLoading(true);
    setSpinLoading(true);
    const header = setHeader();
    const endPoint = credit_check_url + "cc-steps/approve-cibil";
    const data = {
      isApprove: true,
      user_id: user_id,
    };
    const uploadData = await axios.post(endPoint, data, header);
    if (uploadData?.data?.status) {
      message.success(uploadData?.data?.message);
      setUserCibilSaveLoading(false);
      setSpinLoading(false);
      setChangeLoading(true);
    } else {
      message.warning(uploadData?.data?.message);
      setUserCibilSaveLoading(false);
      setSpinLoading(false);
    }
  };

  const extractContactsData = () => {
    const endPoint = "device/extract-contacts";
    const data = { user_id: user_id, device_id: "4" }; // need to set dynamic
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            download(response);
          }
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const removeExtractContacts = (url) => {
    const endPoint = "device/remove-extract-contact-csv";
    const data = { url: url };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const download = async (url) => {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    });

    const urlParts = url.split("/");
    const filename = urlParts[urlParts.length - 1];
    const urlObject = window.URL || window.webkitURL;
    const downloadUrl = urlObject.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;

    link.addEventListener("load", handleDownloadSuccess(url));
    // link.addEventListener("error", handleDownloadError());

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadSuccess = (url) => {
    message.success("File downloaded successfully");
    setIsDownload(true);
    removeExtractContacts(url);
  };

  const onFinishSocialMedia = (data) => {
    console.log(
      "re,mark fb=======>",
      socialMediaForm.getFieldValue("remark_fb")
    );
    console.log(
      "remark ln=======>",
      socialMediaForm.getFieldValue("remark_ln")
    );
    console.log("gen fb=======>", socialMediaForm.getFieldValue("geniune_fb"));
    console.log("gen ln=======>", socialMediaForm.getFieldValue("geniune_ln"));
    if (isApprove) {
      setSocialMediaAppLoading(true);
    } else {
      setSocialMediaSaveLoading(true);
    }
    setSpinLoading(true);
    const endPoint = "cc-steps/save-social-media";
    const formData = {
      socialData: [
        {
          is_genuine: socialMediaForm.getFieldValue("geniune_fb"),
          remarks: socialMediaForm.getFieldValue("remark_fb"),
          type: "Facebook",
        },
        {
          is_genuine: socialMediaForm.getFieldValue("geniune_ln"),
          remarks: socialMediaForm.getFieldValue("remark_ln"),
          type: "Linkedin",
        },
      ],
      user_id: user_id,
      isApprove: isApprove,
      user_role: userRole,
    };

    getAPIData(endPoint, "post", formData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          socialMediaForm.resetFields();
          if (isApprove) {
            setSocialMediaAppLoading(false);
            setIsApprove(false);
          } else {
            setSocialMediaSaveLoading(false);
          }
          setChangeLoading(true);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setIsApprove(false);
          if (isApprove) {
            setSocialMediaAppLoading(false);
          } else {
            setSocialMediaSaveLoading(false);
          }
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSpinLoading(false);
        if (isApprove) {
          setSocialMediaAppLoading(false);
          setIsApprove(false);
        } else {
          setSocialMediaSaveLoading(false);
        }
      });
  };

  const pcolumns = [
    {
      title: "Module",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Permission",
      key: "tags",
      dataIndex: "tags",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
    },
  ];

  const tdata = [
    {
      key: "1",
      name: "Location",
      tags: (
        <div>
          <span>
            {deviceList?.location === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.location === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.location === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.location === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "2",
      name: "Contacts",
      tags: (
        <div>
          <span>
            {deviceList?.contacts === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.contacts === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.contacts === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.contacts === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "3",
      name: "SMS",
      tags: (
        <div>
          <span>
            {deviceList?.sms === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.sms === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.sms === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.sms === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "4",
      name: "Phone",
      tags: (
        <div>
          <span>
            {deviceList?.phone === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.phone === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.phone === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.phone === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "5",
      name: "Call Logs",
      tags: (
        <div>
          <span>
            {deviceList?.call_logs === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.call_logs === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.call_logs === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.call_logs === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "6",
      name: "Camera",
      tags: (
        <div>
          <span>
            {deviceList?.camera === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.camera === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.camera === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.camera === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "7",
      name: "Storage",
      tags: (
        <div>
          <span>
            {deviceList?.storage === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.storage === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.storage === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.storage === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "8",
      name: "Installed Application",
      tags: (
        <div>
          <span>
            {deviceList?.install_apps === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.install_apps === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.install_apps === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.install_apps === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "9",
      name: "Audio",
      tags: (
        <div>
          <span>
            {deviceList?.audio === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.audio === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.audio === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.audio === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
  ];

  const loadDeviceListData = () => {
    const data = { user_id: user_id };
    let deviceList = [];
    const endPoint = "cc-student/get-device-list";
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setDeviceListData(response);
            response.map((data) => {
              deviceList.push({
                value: data?.device_id,
                label: data?.model_company + " - " + data?.device_model,
              });
            });
            setDeviceListDrp(deviceList);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadPrimaryDeviceListData = () => {
    const data = { user_id: user_id, type: "primary" };
    let deviceList = [];
    const endPoint = "device/get-primary-device-list";
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setPrimaryDeviceList(response);
            response.map((data) => {
              deviceList.push({
                value: data?.device_id,
                label: data?.model_company + " " + data?.device_model,
              });
            });
            setPrimaryDeviceDrp(deviceList);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const selectTheDevice = (e) => {
    const device = deviceListData.filter((data) => data?.device_id === e);
    device.map((data) => {
      setDeviceList(data);
    });
  };

  const handleClose = () => {
    setModalType("");
    setPage(1);
    setCurrent(1);
  };

  //Notification Table
  const notificationColumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "20%",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },

    {
      title: "COMMENT",
      dataIndex: "content",
      key: "content",
      width: "30%",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "17%",
    },
    {
      title: "SEEN",
      dataIndex: "read_ts",
      key: "read_ts",
      width: "17%",
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      width: "16%",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  // whatsapp Table
  const whatsappColumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "COMMENT",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  // notification list api
  const loadNotificationListData = () => {
    setNotificationTableLoading(true);
    const endPoint = `cc-student/notification-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setNotificationData(response);
            setNotificationTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setNotificationTableLoading(false);
        }
        setNotificationTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setNotificationTableLoading(false);
      });
  };

  //notification drp api
  const loadNotificationModuleData = (value) => {
    const data = {
      type:
        selectedTab === "SMS"
          ? "sms"
          : selectedTab === "WHATSAPP"
          ? "whatsapp"
          : "notification",
      module: value,
    };
    const endPoint = "cc-student/notification-get-one";
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("sms_temp_id", response?.id);
            smsForm.setFieldValue("comment", response?.message_body);
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("whatsapp_temp_id", response?.id);
            form.setFieldValue("comment", response?.message_body);
          } else {
            notificationForm.setFieldValue(
              "notification_temp_id",
              response?.id
            );
            notificationForm.setFieldValue("comment", response?.message_body);
          }
        } else {
          message.warning(result?.message);
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("comment", "");
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("comment", "");
          } else {
            notificationForm.setFieldValue("comment", "");
          }
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  //SMS List Api
  const loadsmsListData = () => {
    setSmsTableLoading(false);
    const endPoint = `cc-student/get-sent-sms-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          // console.log(".then ⋙---------> response", response);
          if (!_.isEmpty(response) && _.isArray(response)) {
            setSmsData(response);
            setSmsTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setSmsTableLoading(false);
        }
        setSmsTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSmsTableLoading(false);
      });
  };

  //whatsapp List Api
  const loadWhatsappListData = () => {
    setWhatsappTableLoading(false);
    const endPoint = `cc-student/whatsapp-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setWhatsappData(response);
            setWhatsappTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setWhatsappTableLoading(false);
        }
        setWhatsappTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setWhatsappTableLoading(false);
      });
  };

  // Send Whatsapp
  const onFinishWhatsapp = (data) => {
    console.log("success =====>>>>> ", data);
    setWhatsappSendBtnLoading(true);
    setSpinLoading(true);

    const nData = {
      user_id: user_id,
      whatsapp_temp_id: data?.whatsapp_temp_id,
      comment: data?.comment,
    };

    const endPoint = "cc-student/send-whatsapp";
    getAPIData(endPoint, "post", nData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          setWhatsappSendBtnLoading(false);
          setSpinLoading(false);
          loadWhatsappListData();
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setWhatsappSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setWhatsappSendBtnLoading(false);
        setSpinLoading(false);
      });

    // const wpData = {
    //   message_body: "asdfghhhjkl",
    //   dynamic_data_param: "asdfghhhjkl",
    //   slug: "asdfghhhjkl",
    //   button_one_type: "asdfghhhjkl",
    //   button_one_value: "asdfghhhjkl",
    //   button_two_type: "asdfghhhjkl",
    //   button_two_value: "asdfghhhjkl",
    //   module: data?.module,
    // };
    // getAPIData(endPoint, "post", wpData)
    //   .then((result) => {
    //     if (result?.status === true) {
    //       message.success(result?.message);
    //       form.resetFields();
    //     } else {
    //       message.warning(result?.message);
    //     }
    //   })
    //   .catch((err) => {
    //     message.error(err);
    //   });
  };

  //Load Call Status Drp List
  const callStatusData = () => {
    const endpoint = "cc-student/get-dispositions";
    let cat = [];
    getAPIData(endpoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCallStatusSelectData(response);
          response.map((data) =>
            cat.push({
              label: data?.title,
              value: data?.id,
              text: data?.title,
            })
          );
          setCategoryData(cat);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const changeCategory = (e) => {
    callStatusForm.setFieldValue("sub_category", "");
    callStatusForm.setFieldValue("sub_sub_category", "");
    const subs = callStatusSelectData.filter((cat) => cat.id === e);
    let subd = [];
    subs.map((subCat) =>
      subCat.stu_onb_2.map((sub) =>
        subd.push({
          value: sub.id,
          label: sub.title,
          text: sub.title,
        })
      )
    );
    setSubCategoryData(subd);
  };

  const changeSubCategory = (e) => {
    callStatusForm.setFieldValue("sub_sub_category", "");
    setSelectedSubCat(e);
    callStatusSelectData.map((subcat) => {
      const subsubcat = subcat.stu_onb_2.filter((cat) => cat.id === e);
      subsubcat.map((sub) => {
        let subsubdata = [];
        sub.stu_onb_3.map((val) => {
          subsubdata.push({
            value: val.id,
            label: val.title,
            text: val.title,
          });
        });
        setSubSubCategoryData(subsubdata);
      });
    });
  };

  // Call status submit api
  const onFinishCallStatus = (data) => {
    setCallStatusBtnLoading(true);
    setSpinLoading(true);
    const dataCallStatus = { ...data, user_id: user_id, user_role: userRole };
    const dataCallStatusDialer = {
      ...data,
      user_id,
      unique_id: unique_id,
      call_id: call_id,
      admin_apptype: userRole === "college-student" ? "stu_cc" : "sal_cc",
      recording_id: recording_id,
      recording_filename: recording_filename,
    };
    const endPoint = "cc-student/add-disposition";
    getAPIData(
      endPoint,
      "post",
      login_type === "dialer-cc" ? dataCallStatusDialer : dataCallStatus,
      false,
      "credit_check"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          callStatusForm.resetFields();
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setCallStatusBtnLoading(false);
        setSpinLoading(false);
      });
  };
  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  //Search device log
  const searchDeviceLog = (e) => {
    setSpinLoading(true);
    const endPoint = "cc-student/search-by-number";
    // setSearchMobileTableLoading(true);
    const data = { number: e, user_id: user_id };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message?.success(result?.message);
          const response = result?.data;
          setResultDevice(response);
          setSpinLoading(false);
          // setSearchMobileTableLoading(false);
        } else {
          message?.warning(result?.message);
          // setSearchMobileTableLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        // setSearchMobileTableLoading(false);
        setSpinLoading(false);
      });
  };

  // other possible parent's numbers in contact list
  const selectDevice = (e) => {
    setSpinLoading(true);
    const endPoint = "cc-student/get-device-contact-list";
    setParentsTableLoading(true);
    const data = { user_id: user_id, device_id: e };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          console.log("response", response);
          setDeviceData(response);
          setSpinLoading(false);
          setParentsTableLoading(false);
        } else {
          message?.warning(result?.message);
          setSpinLoading(false);
          setParentsTableLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setSpinLoading(false);
        setParentsTableLoading(false);
      });
  };

  useEffect(() => {
    if (paramname === "dialer-credit-check-detail-view") {
      dialerData();
    }
    if (login) {
      loadUserData();
      callStatusData();
      loadNotificationListData();
      loadsmsListData();
      loadWhatsappListData();
      loadDeviceListData();
      callLogApi();
    }
  }, [login]);

  useEffect(() => {
    if (paramname === "credit-check-detailview") {
      loadUserData();
      callStatusData();
      loadNotificationListData();
      loadsmsListData();
      loadWhatsappListData();
      loadDeviceListData();
      loadPrimaryDeviceListData();
    }
  }, [login_type]);

  useEffect(() => {
    if (changeLoading) {
      loadUserData();
    }
    setChangeLoading(false);
  }, [changeLoading]);

  const openModal = (type, record) => {
    if (type === "father") {
      setModalType(type);
      setModalTitle("Update details for father");
      setModalWidth(500);
      setUserRecord(record);
    } else if (type === "mother") {
      setModalType(type);
      setModalTitle("Update details for mother");
      setModalWidth(500);
      setUserRecord(record);
    } else if (type === "other") {
      setModalType(type);
      setModalTitle("Update details for other");
      setModalWidth(500);
      setUserRecord(record);
    } else if (type === "history") {
      setModalType(type);
      setModalTitle("History");
      setModalWidth(1000);
    } else if (type === "SMS") {
      setModalType(type);
      setModalTitle("");
      setModalWidth(1600);
    } else if (type === "clear-in-app") {
      setModalType(type);
      setModalTitle("Clear in App notification");
      setModalWidth(500);
    } else if (type === "view-change-log") {
      setModalType(type);
      setModalTitle("View Change Log");
      setModalWidth(1700);
    } else if (type === "call-status-log") {
      setModalType(type);
      setModalTitle("Call Status Log");
      setModalWidth(1500);
    } else if (type === "click-to-call") {
      setModalType(type);
      setModalTitle("Click to call");
      setModalWidth(500);
    } else if (type === "call-history") {
      setModalTitle("Call History");
      setModalWidth(1000);
      setModalType("call-history");
    } else if (type === "phone-permission") {
      setModalType(type);
      setModalTitle("Phone Permission");
      setModalWidth(1200);
    } else if (type === "algo") {
      setModalType(type);
      setModalTitle("Algo");
      setModalWidth(900);
    } else if (type === "linked-user") {
      setModalType(type);
      setModalTitle("Target device linked users list");
      setModalWidth(900);
    }
  };

  //User provided info table
  const UserProvidedColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "RELATION",
      dataIndex: "relation_id",
      width: "10%",
      render: (text, record) => (
        <div>
          <span>
            {record?.relation_id === 1
              ? "Mother"
              : record?.relation_id === 2
              ? "Father"
              : record?.relation_id === 3
              ? "Sister"
              : record?.relation_id === 4
              ? "Brother"
              : record?.relation_id === 5
              ? "Uncle"
              : record?.relation_id === 6
              ? "Aunt"
              : record?.relation_id === 7
              ? "Roommate"
              : record?.relation_id === 8
              ? "Close friend"
              : record?.relation_id === 9
              ? "Other"
              : record?.relation_id === 10
              ? "Spouse"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "PHONE DETAILS",
      dataIndex: "phone_details",
      width: "10%",
      render: (text, record) => (
        <div>
          <span>
            {record?.phone_details === "1"
              ? "Has a mobile"
              : record?.phone_details === "2"
              ? "Does not use mobile"
              : record?.phone_details === "3"
              ? "Passes away"
              : record?.phone_details === "4"
              ? "Separated and not in touch"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "CONTACT NO.",
      dataIndex: "contact_no",
      width: "10%",
    },
    {
      title: "SAVED AS",
      dataIndex: "savedas",
      width: "10%",
    },
    {
      title: "IN CALL LOG",
      dataIndex: "incalllog",
      width: "10%",
    },
    {
      title: "CONTACT NO. AS PER RM",
      dataIndex: "contact_num_as_agent",
      width: "13%",
      render: (text, record) => (
        <div>
          <span>{record.contact_num_as_agent}</span>
          <span style={{ marginLeft: 20 }}>
            <EditOutlined
              style={{
                fontSize: "25px",
                color: "#009D93",
                cursor: "pointer",
              }}
              onClick={() =>
                record?.relation_id === 1
                  ? openModal("mother", record)
                  : record?.relation_id === 2
                  ? openModal("father", record)
                  : openModal("other", record)
              }
            />
          </span>
        </div>
      ),
    },
    {
      title: "RECENT COMMENT",
      dataIndex: "comment",
      width: "11%",
    },
    {
      title: "HISTORY",
      dataIndex: "history",
      key: "history",
      width: "10%",
      render: () => (
        <span>
          <Tag
            className="tag-form"
            onClick={() => {
              openModal("HISTORY");
            }}
          >
            HISTORY
          </Tag>
        </span>
      ),
    },
    {
      title: "VALIDATED",
      dataIndex: "validated",
      width: "13%",
      render: (text, record) => (
        <span>
          {record?.validated === "1"
            ? "Yes"
            : record?.validated === "0"
            ? "No"
            : ""}
        </span>
      ),
    },
  ];

  // device details Table
  const DeviceDetailData = [];
  for (let i = 0; i < 3; i++) {
    DeviceDetailData.push({
      key: i,
      deviceinfo: "",
      lastlogintime: "",
      primarydevice: "",
      primaryemailid: "",
    });
  }
  //User provided info matched with other users table
  const MatchedOtherUserColumns = [
    {
      title: "NUMBER",
      dataIndex: "contact_no",
      width: "10%",
    },
    {
      title: "RELATION WITH USER",
      dataIndex: "relation_id",
      width: "15%",
      render: (text, record) => (
        <div>
          <span>
            {record?.relation_id === 1
              ? "Mother"
              : record?.relation_id === 2
              ? "Father"
              : "Other"}
          </span>
        </div>
      ),
    },
    {
      title: "SAVED AS",
      dataIndex: "saved_as",
      width: "15%",
    },
    {
      title: "PRIMARY USER",
      dataIndex: "primaryuser",
      width: "15%",
    },
    {
      title: "DEVICE ID",
      dataIndex: "device_id",
      width: "15%",
    },
  ];
  const MatchedOtherUserData = [];
  for (let i = 0; i < 3; i++) {
    MatchedOtherUserData.push({
      key: i,
      number: "",
      relation: "",
      savedas: "",
      primaryuser: "",
      deviceid: "",
    });
  }

  //Other possible parent’s numbers in contact list
  const ContactlistColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      width: "15%",
    },
    {
      title: "DEVICE DETAILS",
      dataIndex: "details",
      width: "15%",
      render: (text, record) => (
        <div>
          <span>
            Model Company: {record?.model_company} <br />
            Device Model: {record?.device_model} <br />
            Device Model Id: {record?.device_model_id}
          </span>
        </div>
      ),
    },
    {
      title: "PROBABLE RELATION",
      dataIndex: "relation_name",
      width: "15%",
    },
  ];
  //Mobile number in all devices user has logged in from table
  const AllDevicesColumns = [
    {
      title: "DEVICE ID",
      dataIndex: "device_id",
      width: "10%",
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      width: "15%",
    },
    {
      title: "PRIMARY",
      dataIndex: "is_primary",
      width: "15%",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneno",
      width: "15%",
    },
    {
      title: "SAVED AS",
      dataIndex: "savedAs",
      width: "15%",
    },
    {
      title: "CALL LOG COUNT",
      dataIndex: "call_count",
      width: "10%",
    },
    {
      title: "LAST CALL TIMESTAMP",
      dataIndex: "lastcalltime",
      width: "15%",
    },
  ];
  const AllDevicesData = [];
  for (let i = 0; i < 3; i++) {
    AllDevicesData.push({
      key: i,
      id: "",
      number: "",
      primary: "",
      phoneno: "",
      savedas: "",
      calllogcount: "",
      lastcalltime: "",
    });
  }

  return loading === true ? (
    <CClickLoading />
  ) : (
    <Spin spinning={spinLoading} size="large">
      <div className={`${loading ? "page-loading app" : "app"}`}>
        <Layout
          style={{
            padding: "20px 0px 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              {login_type === "User" ? (
                <MenuOutlined
                  className="trigger"
                  onClick={() => setOpen(true)}
                />
              ) : (
                ""
              )}
              <Title
                level={4}
                className="poppins_regular umoney_page_title"
                style={{
                  paddingLeft: login_type === "dialer-cc" ? "20px" : "",
                }}
              >
                {userFullName}'s Detail View{" "}
              </Title>
            </Header>

            <Content
              className="site-layout-background  white_div"
              style={{
                padding: "20px 15px",
                borderRadius: "5px",
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" xl={2} lg={4} md={6} sm={8}>
                    <img
                      className="kyc-images"
                      src={user}
                      alt="User"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </Col>
                  <Col className="gutter-row" xl={22} lg={20} md={18} sm={16}>
                    <Row gutter={[16, 16]}>
                      <Col className="gutter-row" span={12}>
                        <Title level={4} className="poppins_semi umoney_title">
                          Information Check Status: {userFullName}
                        </Title>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="CALL HISTORY"
                          className="poppins_medium umoney_outlined_btn"
                          //   loading={PendingBtnLoading}
                          onClick={() => openModal("call-history")}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="CLICK TO CALL"
                          className="poppins_medium umoney_outlined_btn"
                          //   loading={PendingBtnLoading}
                          onClick={() => openModal("click-to-call")}
                          block
                        />
                      </Col>
                      <Col className="gutter-row mt-20" span={24}>
                        <Form
                          name="basic"
                          // onFinish={onFinish}
                          // onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          className="umoney-form"
                          requiredMark={false}
                          form={topForm}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={6}>
                              <Form.Item
                                label="Initiation Date"
                                name="initiation_date"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Initiation date is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                {/* <DatePicker
                                  size="large"
                                  style={{ width: "100%" }}
                                  placeholder="Select Initiation Date"
                                /> */}
                                <Input size="large" readOnly={true} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Submission Date"
                                name="submission_date"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Submission date is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                {/* <DatePicker
                                  size="large"
                                  style={{ width: "100%" }}
                                  placeholder="Select Submission Date"
                                /> */}
                                <Input size="large" readOnly={true} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Action Date"
                                name="action_date"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Action date is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                {/* <DatePicker
                                  size="large"
                                  style={{ width: "100%" }}
                                  placeholder="Select Action Date"
                                /> */}
                                <Input size="large" readOnly={true} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Status"
                                name="status"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Status is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input size="large" readOnly={true} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="white_div mt-20">
                <Title level={5} className="poppins_medium umoney_title">
                  Send Notification or SMS or Whatsapp
                </Title>
                <div className="tab-title">
                  <Row gutter={[16, 16]}>
                    <Col
                      className="gutter-row"
                      span={24}
                      style={{ paddingLeft: "-10px", paddingRight: "-10px" }}
                    >
                      <Tabs
                        defaultActiveKey="1"
                        type="card"
                        onTabClick={(value) => {
                          getTabValue(value);
                        }}
                      >
                        <Tabs.TabPane tab="NOTIFICATION" key="1">
                          <Row gutter={[16, 16]}>
                            <Col className="gutter-row" span={8}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  In app communication
                                </Title>
                                <Form
                                  name="basic"
                                  onFinish={onFinishNotification}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  form={notificationForm}
                                >
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Module"
                                      name="module"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Module is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Select
                                        size="large"
                                        options={moduleOptions}
                                        onChange={(value) => {
                                          loadNotificationModuleData(value);
                                        }}
                                        placeholder="Select  module"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Comment"
                                      name="comment"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Comment is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <TextArea
                                        size="large"
                                        type="text"
                                        rows={7}
                                        placeholder="Enter comment"
                                      />
                                    </Form.Item>
                                    <Form.Item name="notification_temp_id"></Form.Item>
                                  </div>
                                  <Form.Item>
                                    <CLoadingButton
                                      size="large"
                                      value="Send"
                                      className="poppins_medium umoney_btn "
                                      block
                                      htmlType="submit"
                                      loading={notiSendBtnLoading}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Row gutter={[16, 16]}>
                                  <Col span={20}>
                                    <Title
                                      level={5}
                                      className="poppins_medium umoney_title"
                                    >
                                      In app sent
                                    </Title>
                                  </Col>
                                  <Col span={4}>
                                    <CLoadingButton
                                      size="large"
                                      value="CLEAR IN APP"
                                      className="poppins_medium umoney_btn"
                                      onClick={() => {
                                        openModal("clear-in-app");
                                      }}
                                      block
                                    />
                                  </Col>
                                </Row>

                                <div className="umoney_table mt-10">
                                  <Table
                                    columns={notificationColumns}
                                    dataSource={notificationData}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 5,
                                      pageSizeOptions: [5, 10, 15, 20],
                                      locale: { items_per_page: "" },
                                    }}
                                    loading={notificationTableLoading}
                                    scroll={{ y: 200 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="SMS" key="2">
                          <Row gutter={[16, 16]}>
                            <Col className="gutter-row" span={8}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                  //margin:"10px"
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  SMS templates
                                </Title>
                                <Form
                                  name="basic"
                                  onFinish={onFinishSms}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  form={smsForm}
                                >
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Module"
                                      name="module"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Module is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Select
                                        size="large"
                                        options={moduleOptions}
                                        onChange={(val) => {
                                          loadNotificationModuleData(val);
                                        }}
                                        placeholder="Select module"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Comment"
                                      name="comment"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Comment is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <TextArea
                                        size="large"
                                        type="text"
                                        rows={7}
                                        placeholder="Enter comment"
                                      />
                                    </Form.Item>
                                    <Form.Item name="sms_temp_id" />
                                  </div>
                                  <Form.Item>
                                    <CLoadingButton
                                      size="large"
                                      value="Send"
                                      className="poppins_medium umoney_btn"
                                      block
                                      htmlType="submit"
                                      loading={smsSendBtnLoading}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  SMS sending history
                                </Title>
                                <div className="umoney_table mt-10">
                                  <Table
                                    columns={smscolumns}
                                    dataSource={smsData}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 5,
                                      pageSizeOptions: [5, 10, 15, 20],
                                      locale: { items_per_page: "" },
                                    }}
                                    loading={smsTableLoading}
                                    scroll={{ y: 200 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="WHATSAPP" key="3">
                          <Row gutter={[16, 16]}>
                            <Col className="gutter-row" span={8}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                  //margin:"10px"
                                }}
                              >
                                <Form
                                  name="basic"
                                  onFinish={onFinishWhatsapp}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  form={form}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    Whatsapp templates
                                  </Title>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Module"
                                      name="module"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Module is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Select
                                        size="large"
                                        options={moduleOptions}
                                        onChange={(val) => {
                                          loadNotificationModuleData(val);
                                        }}
                                        placeholder="Select module"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Comment"
                                      name="comment"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Comment is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <TextArea
                                        size="large"
                                        type="text"
                                        rows={7}
                                        placeholder="Enter comment"
                                      />
                                    </Form.Item>
                                    <Form.Item name="whatsapp_temp_id" />
                                  </div>
                                  <CLoadingButton
                                    size="large"
                                    value="Send"
                                    className="poppins_medium umoney_btn"
                                    htmlType="submit"
                                    loading={whatsappSendBtnLoading}
                                    block
                                  />
                                </Form>
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  Whatsapp sending history
                                </Title>
                                <div className="umoney_table mt-10">
                                  <Table
                                    columns={whatsappColumns}
                                    dataSource={whatsappData}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 5,
                                      pageSizeOptions: [5, 10, 15, 20],
                                      locale: { items_per_page: "" },
                                    }}
                                    loading={whatsappTableLoading}
                                    scroll={{ y: 200 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={19}>
                    <Title level={5} className="poppins_medium umoney_title">
                      Profile Status
                    </Title>
                  </Col>
                  <Col className="gutter-row" span={5}>
                    <CLoadingButton
                      size="large"
                      value="VIEW CHANGE LOG"
                      className="poppins_medium umoney_outlined_btn"
                      onClick={() => openModal("view-change-log")}
                      block
                    />
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      {userRole === "college-student" ? (
                        <Col
                          className="gutter-row"
                          xl={4}
                          lg={3}
                          md={4}
                          sm={6}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            className="poppins_medium br tag-style"
                            style={{
                              backgroundColor:
                                userData?.percentage?.income === "2"
                                  ? completed
                                  : userData?.percentage?.income === "3"
                                  ? checked
                                  : userData?.percentage?.income === "4"
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Income
                          </Tag>
                        </Col>
                      ) : (
                        ""
                      )}

                      {userRole === "college-student" ? (
                        <Col
                          className="gutter-row"
                          xl={4}
                          lg={3}
                          md={4}
                          sm={6}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            className="poppins_medium br tag-style"
                            style={{
                              backgroundColor:
                                userData?.percentage?.spending === "2"
                                  ? completed
                                  : userData?.percentage?.spending === "3"
                                  ? checked
                                  : userData?.percentage?.spending === "4"
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Spending
                          </Tag>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor:
                              userData?.percentage?.assets === "2"
                                ? completed
                                : userData?.percentage?.assets === "3"
                                ? checked
                                : userData?.percentage?.assets === "4"
                                ? verifiedApproved
                                : pending,
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Assets
                        </Tag>
                      </Col>
                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor:
                              userData?.percentage?.parent_contact === "2"
                                ? completed
                                : userData?.percentage?.parent_contact === "3"
                                ? checked
                                : userData?.percentage?.parent_contact === "4"
                                ? verifiedApproved
                                : pending,
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Parent Contact
                        </Tag>
                      </Col>
                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor:
                              userData?.percentage?.social_media === "2"
                                ? completed
                                : userData?.percentage?.social_media === "3"
                                ? checked
                                : userData?.percentage?.social_media === "4"
                                ? verifiedApproved
                                : pending,
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Social Media
                        </Tag>
                      </Col>
                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor:
                              userData?.percentage?.cibil === "2"
                                ? completed
                                : userData?.percentage?.cibil === "3"
                                ? checked
                                : userData?.percentage?.cibil === "4"
                                ? verifiedApproved
                                : pending,
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Cibil
                        </Tag>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="gutter-row"
                    span={19}
                    style={{ marginTop: 10 }}
                  >
                    <Title level={5} className="poppins_semi">
                      Call Status
                    </Title>
                  </Col>
                  <Col span={5}>
                    <CLoadingButton
                      size="large"
                      value="CALL STATUS LOG"
                      className="poppins_medium umoney_outlined_btn1 br"
                      onClick={() => {
                        openModal("call-status-log");
                      }}
                      block
                    />
                  </Col>
                  <Col className="gutter-row" span={24}>
                    <Form
                      name="basic"
                      onFinish={onFinishCallStatus}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                      form={callStatusForm}
                    >
                      <Row gutter={[16, 16]}>
                        <Col
                          className="gutter-row"
                          xl={12}
                          lg={12}
                          md={8}
                          sm={6}
                        ></Col>
                        <Col
                          className="gutter-row"
                          xl={12}
                          lg={12}
                          md={16}
                          sm={18}
                        >
                          <Row gutter={[16, 16]}>
                            <Col
                              className="gutter-row"
                              xl={4}
                              lg={4}
                              md={4}
                              sm={4}
                            >
                              {" "}
                            </Col>
                            <Col
                              className="gutter-row"
                              xl={10}
                              lg={4}
                              md={4}
                              sm={4}
                            >
                              {" "}
                            </Col>

                            <Col
                              className="gutter-row"
                              xl={10}
                              lg={10}
                              md={10}
                              sm={10}
                            ></Col>
                          </Row>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={5}
                          lg={5}
                          md={6}
                          sm={12}
                        >
                          <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                              {
                                required: true,
                                message: "Category is required!",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Select
                              size="large"
                              options={categoryData}
                              onChange={(e) => changeCategory(e)}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={5}
                          lg={5}
                          md={6}
                          sm={12}
                        >
                          <Form.Item
                            label="Sub Category"
                            name="sub_category"
                            rules={[
                              {
                                required: true,
                                message: "Sub Category is required!",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Select
                              value={selectedSubCat}
                              size="large"
                              options={subCategoryData}
                              onChange={(e) => changeSubCategory(e)}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={5}
                          lg={5}
                          md={6}
                          sm={12}
                        >
                          <Form.Item
                            label="Sub Sub Category"
                            name="sub_sub_category"
                            rules={[
                              {
                                required: true,
                                message: "Sub sub Category is required!",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Select
                              size="large"
                              options={subSubCategoryData}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={5}
                          lg={5}
                          md={6}
                          sm={12}
                        >
                          <Form.Item
                            label="Comment"
                            name="comment"
                            rules={[
                              {
                                required: true,
                                message: "comment is required!",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" type="text" />
                          </Form.Item>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={4}
                          lg={4}
                          md={6}
                          sm={12}
                        >
                          <Form.Item>
                            <CLoadingButton
                              size="large"
                              value="Submit"
                              htmlType="submit"
                              loading={callStatusBtnLoading}
                              className="poppins_medium umoney_btn"
                              block
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>

                  <Col className="gutter-row" span={12}>
                    <CTextField
                      size="large"
                      type="text"
                      label="Call assigned to RM"
                      placeholder="Call assigned to RM"
                      setvalue={(val) => {
                        // setPasstextfield(val);
                        // console.log("val======>>>>>>", val);
                      }}
                      readOnly={true}
                    />
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <CTextField
                      size="large"
                      type="text"
                      label="Call assigned to verifier"
                      placeholder="Call assigned to verifier"
                      setvalue={(val) => {
                        // setPasstextfield(val);
                        // console.log("val======>>>>>>", val);
                      }}
                      readOnly={true}
                    />
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Form
                  name="basic"
                  // onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="umoney-form mb-35"
                  requiredMark={false}
                  form={userCibilForm}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={16}>
                      <Title level={5} className="poppins_medium umoney_title">
                        User Cibil
                      </Title>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={4}>
                      <Form.Item>
                        <CLoadingButton
                          size="large"
                          value="SAVE & APPROVE"
                          htmlType="submit"
                          className="poppins_medium umoney_btn"
                          block
                          onClick={() => userCibilSave()}
                          loading={userCibilSaveLoading}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {_.isEmpty(userData?.cibil?.error) ? (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Title
                            level={5}
                            className="poppins_medium umoney_title"
                          >
                            Retail accounts summary data
                          </Title>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col span={12}>
                          <Form.Item
                            label="Number of accounts"
                            name="no_of_accounts"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Number of active accounts"
                            name="no_of_active_accounts"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Number of write offs"
                            name="no_of_write_offs"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Total past due"
                            name="total_past_due"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Most severe status with in 24 months"
                            name="most_severe_status_with_in_24_months"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Single highest credit"
                            name="single_highest_credit"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Single highest sanction amount"
                            name="single_highest_sanction_amount"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Total high credit"
                            name="total_high_credit"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Average open balance"
                            name="average_open_balance"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Single highest balance"
                            name="single_highest_balance"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Number of past due accounts"
                            name="no_of_past_due_accounts"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Number of zero balance accounts"
                            name="no_of_zero_balance_accounts"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Recent account type"
                            name="recent_account_type"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Recent account date"
                            name="recent_account_date"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Oldest account type"
                            name="oldest_account_type"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Oldest account date"
                            name="oldest_account_date"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Total balance amount"
                            name="total__balance_amount"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Total sanction amount"
                            name="total_sanction_amount"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Total credit limit"
                            name="total_credit_limit"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Total monthly payment amount"
                            name="total_monthly_payment_amount"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={24} className="mt-20">
                          <Title
                            level={5}
                            className="poppins_medium umoney_title"
                          >
                            Score details data
                          </Title>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Type"
                            name="type"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Version"
                            name="version"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Name"
                            name="name"
                            size="large"
                            className="poppins_medium mb-35"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={24} className="mt-20">
                          <Title
                            level={5}
                            className="poppins_medium umoney_title"
                          >
                            Phone data
                          </Title>
                        </Col>
                        <Col span={24}>
                          <div className="umoney_table">
                            <Table
                              columns={cibilPhoneColumns}
                              dataSource={cibilPhoneData}
                              pagination={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Title
                          level={5}
                          className="poppins_medium umoney_title"
                        >
                          Error
                        </Title>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Error Code"
                          name="error_code"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Salary Type is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input size="large" readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <Form.Item
                          label="Error Description"
                          name="error_description"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Salary Type is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input size="large" readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <Form.Item
                          label="Updated At"
                          name="updatedAt"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Salary Type is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input size="large" readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={12}></Col>
                      <Col span={8}>
                        <CLoadingButton
                          size="large"
                          value="SAVE & APPROVE"
                          htmlType="submit"
                          loading={userCibilSaveLoading}
                          onClick={() => userCibilSave()}
                          className="poppins_medium umoney_btn"
                          block
                        />
                      </Col>
                    </Row>
                  )}
                </Form>
              </div>
              <div className="white_div mt-20">
                <Title level={5} className="poppins_medium umoney_title">
                  Information
                </Title>
                <div className="mt-20">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form
                        name="basic"
                        onFinish={onFinishInformation}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="umoney-form"
                        requiredMark={false}
                        form={informationForm}
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={6}>
                            <Form.Item
                              label="Device"
                              name="Device"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Device",
                                },
                              ]}
                              className="poppins_medium mb-35"
                            >
                              <Select
                                options={deviceListDrp}
                                style={{ width: "100%" }}
                                size="large"
                                onChange={(e) => selectTheDevice(e)}
                                placeholder="Select device"
                              ></Select>
                            </Form.Item>
                          </Col>
                          <Col span={18}></Col>
                        </Row>
                      </Form>
                    </Col>

                    <Col className="gutter-row" xl={12} lg={12} md={24} sm={24}>
                      <div className="border-class">
                        <Title
                          level={5}
                          className="poppins_medium umoney_title p-10"
                        >
                          Phone Permission
                        </Title>
                        <Table
                          columns={pcolumns}
                          dataSource={tdata}
                          pagination={false}
                          style={{ paddingBottom: "2px" }}
                        />
                      </div>
                    </Col>
                    <Col className="gutter-row" xl={12} lg={12} md={24} sm={24}>
                      <Row gutter={[16, 16]}>
                        <Col
                          className="gutter-row"
                          xl={24}
                          lg={8}
                          md={8}
                          sm={10}
                        >
                          <div className="border-class">
                            <Title
                              level={5}
                              className="poppins_medium umoney_title p-10"
                            >
                              Device Details
                            </Title>
                            <Row className="p-10">
                              <Col className="gutter-row" span={4}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#009D93",
                                    fontSize: "16px",
                                  }}
                                >
                                  MODEL
                                </p>
                              </Col>
                              <Col className="gutter-row" span={20}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#494949",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  {!_.isEmpty(deviceList?.device_model)
                                    ? deviceList?.device_model
                                    : "-"}
                                </p>
                              </Col>
                              <Col className="gutter-row" span={4}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#009D93",
                                    fontSize: "16px",
                                  }}
                                >
                                  IMIE
                                </p>
                              </Col>
                              <Col className="gutter-row" span={20}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#494949",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  {!_.isEmpty(deviceList?.imei_no)
                                    ? deviceList?.imei_no
                                    : "-"}
                                </p>
                              </Col>
                              <Col className="gutter-row" span={4}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#009D93",
                                    fontSize: "16px",
                                  }}
                                >
                                  G-AD-ID
                                </p>
                              </Col>
                              <Col className="gutter-row" span={20}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#494949",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  {!_.isEmpty(deviceList?.g_ad_id)
                                    ? deviceList?.g_ad_id
                                    : "-"}
                                </p>
                              </Col>
                              <Col className="gutter-row" span={4}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#009D93",
                                    fontSize: "16px",
                                  }}
                                >
                                  DEVICE MODEL ID
                                </p>
                              </Col>
                              <Col className="gutter-row" span={20}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#494949",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  {!_.isEmpty(deviceList?.device_model_id)
                                    ? deviceList?.device_model_id
                                    : "-"}
                                </p>
                              </Col>
                              <Col className="gutter-row" span={4}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#009D93",
                                    fontSize: "16px",
                                  }}
                                >
                                  ANDROID VERSION
                                </p>
                              </Col>
                              <Col className="gutter-row" span={20}>
                                <p
                                  className="poppins_semi"
                                  style={{
                                    color: "#494949",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  {!_.isEmpty(deviceList?.android_version)
                                    ? deviceList?.android_version
                                    : "-"}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>

              {userRole === "college-student" ? (
                <div className="white_div" style={{ marginTop: 20 }}>
                  <Form
                    name="basic"
                    onFinish={onFinishIncome}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="umoney-form mb-35"
                    requiredMark={false}
                    form={incomeForm}
                  >
                    <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                      <Col span={16}>
                        <Title
                          level={5}
                          className="poppins_medium umoney_title"
                        >
                          Income Check
                        </Title>
                      </Col>
                      <Col span={8}>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <CLoadingButton
                              size="large"
                              value="SAVE"
                              className="poppins_medium umoney_btn"
                              htmlType="submit"
                              block
                              loading={saveIncomeLoading}
                            />
                          </Col>
                          <Col span={12}>
                            <CLoadingButton
                              size="large"
                              value="SAVE & APPROVE"
                              className="poppins_medium umoney_btn"
                              htmlType="submit"
                              block
                              onClick={() => setIsApprove(true)}
                              loading={saveAppIncomeLoading}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="How much pocket money do you get EVERY MONTH from your parents"
                          name="pocket_money_amount"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Pocket Money is required!",
                            },
                            {
                              pattern: /^[0-9]\d*(\.\d+)?$/g,
                              message: "Pocket Money contains only number.",
                            },
                          ]}
                          className="poppins_medium"
                        >
                          <Input
                            size="large"
                            placeholder="Enter pocket money amount"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Do you work part time?"
                          name="work_part_time"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Selection is required",
                            },
                          ]}
                          className="poppins_medium"
                        >
                          <Radio.Group
                            className="poppins_medium"
                            options={radioData}
                            size="large"
                            style={{ padding: "10px" }}
                            onChange={(e) => {
                              setPartTimeVal(e.target.value);
                              console.log(e.target.value);
                              setPartTimeWork(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      {partTimeVal === "1" ? (
                        <Col span={12}>
                          <Form.Item
                            label="Briefly describe your work"
                            name="describe_part_time_work"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Part time work is required!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              placeholder="Enter work description"
                            />
                          </Form.Item>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col span={12}>
                        <Form.Item
                          label="Family annual income"
                          name="family_monthly_income_id"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Select family monthly income!",
                            },
                          ]}
                          className="poppins_medium"
                        >
                          <Select
                            size="large"
                            options={familyIncomeData}
                            placeholder="Select family annual income"
                          />
                        </Form.Item>
                      </Col>
                      {partTimeVal === "1" ? (
                        <>
                          <Col span={12}>
                            <Form.Item
                              label="How many hours per week do you work on average?"
                              name="part_time_working_hours"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "Working hours is required!",
                                },
                                {
                                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                                  message:
                                    "Part time working hours contains only number.",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Input
                                size="large"
                                placeholder="Enter work hours per week"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="On average, how much you earn per month?"
                              name="part_time_earn_amount"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "Earn amount is required!",
                                },
                                {
                                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                                  message:
                                    "Part time earn amount contains only number.",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Input
                                size="large"
                                placeholder="Enter earn amount per month"
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Form>
                </div>
              ) : (
                ""
              )}

              {userRole === "college-student" ? (
                <div className="white_div" style={{ marginTop: 20 }}>
                  <Form
                    name="basic"
                    onFinish={onFinishSpending}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="umoney-form mb-35"
                    requiredMark={false}
                    form={spendingForm}
                  >
                    <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                      <Col span={16}>
                        <Title
                          level={5}
                          className="poppins_medium umoney_title"
                        >
                          Spending check
                        </Title>
                      </Col>
                      <Col span={8}>
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <CLoadingButton
                              size="large"
                              value="SAVE"
                              className="poppins_medium umoney_btn"
                              htmlType="submit"
                              block
                              loading={saveSpendingLoading}
                            />
                          </Col>
                          <Col span={12}>
                            <CLoadingButton
                              size="large"
                              value="SAVE & APPROVE"
                              className="poppins_medium umoney_btn"
                              htmlType="submit"
                              block
                              onClick={() => setIsApprove(true)}
                              loading={saveSpendingAppLoading}
                            />
                          </Col>
                        </Row>
                      </Col>

                      {partTimeWork === "1" ? (
                        <Col span={12}>
                          <Form.Item
                            label="Part time work"
                            name="describe_part_time_work"
                            size="large"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Part time work is required!",
                            //   },
                            // ]}
                            className="poppins_medium"
                          >
                            <Input size="large" readOnly />
                          </Form.Item>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col span={12}>
                        <Form.Item
                          label="Pocket money"
                          name="pocket_money_amount"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Pocket Money is required!",
                            },
                            {
                              pattern: /^[0-9]\d*(\.\d+)?$/g,
                              message:
                                "Pocket money amount contains only number.",
                            },
                          ]}
                          className="poppins_medium"
                        >
                          <Input
                            size="large"
                            placeholder="Enter pocket money amount"
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}> */}
                      {/* <Form.Item
                        label="Net cash flow"
                        name=""
                        size="large"
                        rules={[
                          {
                            required: true,
                            message: "Net Cash Flow is required!",
                          },
                        ]}
                        className="poppins_medium"
                      >
                        <Input size="large" />
                      </Form.Item> */}
                      {/* </Col> */}
                      <Col span={12}>
                        <Form.Item
                          label="Do you live in?"
                          name="currently_stay"
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Currently stay is required!",
                            },
                          ]}
                          className="poppins_medium"
                        >
                          <Select
                            size="large"
                            options={livingData}
                            placeholder="Select currently stay"
                            onChange={(e) => {
                              setLivePlace(e);
                              setHostelRent("");
                              setExtraExpenditure("");
                              if (e !== userData?.spending?.currently_stay) {
                                spendingForm.setFieldValue(
                                  "extra_expenditure",
                                  ""
                                );
                                spendingForm.setFieldValue(
                                  "average_monthly_spend",
                                  ""
                                );
                                spendingForm.setFieldValue(
                                  "parents_provide_extra_money",
                                  ""
                                );
                                spendingForm.setFieldValue(
                                  "college_fee_include_hostel_rent",
                                  ""
                                );
                              } else {
                                setExtraExpenditure(
                                  userData?.spending?.extra_expenditure
                                );
                                spendingForm.setFieldValue(
                                  "extra_expenditure",
                                  userData?.spending?.extra_expenditure
                                );
                                spendingForm.setFieldValue(
                                  "average_monthly_spend",
                                  userData?.spending?.average_monthly_spend
                                );
                                spendingForm.setFieldValue(
                                  "parents_provide_extra_money",
                                  userData?.spending
                                    ?.parents_provide_extra_money
                                );
                                spendingForm.setFieldValue(
                                  "college_fee_include_hostel_rent",
                                  userData?.spending
                                    ?.college_fee_include_hostel_rent
                                );
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      {livePlace === "4" ? (
                        <Col span={12}>
                          <Form.Item
                            label="Does it need an extra expenditure ?"
                            name="extra_expenditure"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "selection is required!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Radio.Group
                              className="poppins_medium"
                              options={radioData}
                              size="large"
                              style={{ padding: "10px" }}
                              onChange={(e) => {
                                setExtraExpenditure(e.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      ) : (
                        ""
                      )}

                      {livePlace === "1" ? (
                        <>
                          <Col span={12}>
                            <Form.Item
                              label="Does your college fees include the college hostel rent?"
                              name="college_fee_include_hostel_rent"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "selection is required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Radio.Group
                                className="poppins_medium"
                                options={radioData}
                                size="large"
                                style={{ padding: "10px" }}
                                onChange={(e) => {
                                  setHostelRent(e.target.value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {extraExpenditure === "1" ? (
                        <Col span={12}>
                          <Form.Item
                            label="What is your average monthly extra expenditure"
                            name="average_monthly_spend"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Extra Expenditure is required!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              placeholder="Enter average monthly extra expenditure"
                            />
                          </Form.Item>
                        </Col>
                      ) : (
                        ""
                      )}
                      {/* <Col span={12}></Col> */}
                      <Col span={12}>
                        {hostelRent === "0" || livePlace === "2" ? (
                          <Form.Item
                            label="What is your average monthly spend on living expenses"
                            name="average_monthly_spend"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Average monthly spend is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              placeholder="Enter average monthly spend"
                            />
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </Col>

                      <Col span={12}>
                        {hostelRent === "0" ||
                        livePlace === "2" ||
                        extraExpenditure === "1" ? (
                          <Form.Item
                            label="Does your parents provide extra money for that?"
                            name="parents_provide_extra_money"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "selection is required!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Radio.Group
                              className="poppins_medium"
                              options={radioData}
                              size="large"
                              style={{ padding: "10px" }}
                            />
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Form>
                </div>
              ) : (
                ""
              )}

              <div className="white_div" style={{ marginTop: 20 }}>
                <Form
                  name="basic"
                  onFinish={onFinishAsset}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="umoney-form mb-35"
                  requiredMark={false}
                  form={assetForm}
                >
                  <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                    <Col span={16}>
                      <Title level={5} className="poppins_medium umoney_title">
                        Asset Check
                      </Title>
                    </Col>
                    <Col span={8}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            className="poppins_medium umoney_btn"
                            htmlType="submit"
                            block
                            loading={saveAssetLoading}
                          />
                        </Col>
                        <Col span={12}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            className="poppins_medium umoney_btn"
                            htmlType="submit"
                            block
                            onClick={() => setIsApprove(true)}
                            loading={saveAppAssetLoading}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item
                            label="Do you own a laptop ?"
                            name="have_laptop"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "selection is required!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Radio.Group
                              className="poppins_medium"
                              options={radioData}
                              size="large"
                              style={{ padding: "10px" }}
                              onChange={(e) => {
                                setLaptopVal(e.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          {laptopVal === "1" ? (
                            <Form.Item
                              label="Price"
                              name="laptop_cost"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "price is required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Input
                                size="large"
                                placeholder="Enter laptop cost"
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              label="Do you plan to buy a laptop within 6 months ?"
                              name="plan_buy_laptop_in_next_6_month"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "Selection required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Radio.Group
                                className="poppins_medium"
                                options={radioData}
                                size="large"
                                style={{ padding: "10px" }}
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Do you own a 2-wheeler?"
                            name="have_two_wheeler"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Selection is required!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Radio.Group
                              className="poppins_medium"
                              options={radioData}
                              size="large"
                              style={{ padding: "10px" }}
                              onChange={(e) => {
                                setVehicleVal(e.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          {vehicleVal === "0" ? (
                            <Form.Item
                              label="Do you plan to buy a 2-wheeler within 6 months"
                              name="plan_buy_two_wheeler_in_next_6_month"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "selection is required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Radio.Group
                                className="poppins_medium"
                                options={radioData}
                                size="large"
                                style={{ padding: "10px" }}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={12}>
                          {vehicleVal === "1" ? (
                            <Form.Item
                              label="Which model ?"
                              name="two_wheeler_model"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "model is required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Input
                                size="large"
                                placeholder="Enter vehicle model"
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                          {vehicleVal === "1" ? (
                            <Form.Item
                              label="Enter the vehicle registration"
                              name="two_wheeler_vehicle_number"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "vehicle number is required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Input
                                size="large"
                                placeholder="Enter vehicle registration"
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                          <Form.Item
                            label="Does your Family own a 4-wheeler?"
                            name="have_four_wheeler"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Selection is required!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Radio.Group
                              className="poppins_medium"
                              options={radioData}
                              size="large"
                              style={{ padding: "10px" }}
                              onChange={(e) => {
                                setFourVehicle(e.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                          {fourVehicle === "1" ? (
                            <Form.Item
                              label="Which model ?"
                              name="four_wheeler_model"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "model is required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Input
                                size="large"
                                placeholder="Enter vehicle number"
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                          {fourVehicle === "1" ? (
                            <Form.Item
                              label="Enter the vehicle registration"
                              name="four_wheeler_vehicle_number"
                              size="large"
                              rules={[
                                {
                                  required: true,
                                  message: "Vehicle number is required!",
                                },
                              ]}
                              className="poppins_medium"
                            >
                              <Input
                                size="large"
                                placeholder="Enter vehicle number"
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          {vehicleVal === "1" ? (
                            <CLoadingButton
                              size="large"
                              value="CALL API [Two Wheeler]"
                              className="poppins_medium umoney_btn"
                              loading={apiTwoWheelerLoading}
                              onClick={() => ApiClickTwoWheeler()}
                              block
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={12}>
                          {fourVehicle === "1" ? (
                            <CLoadingButton
                              size="large"
                              value="CALL API [Four Wheeler]"
                              className="poppins_medium umoney_btn"
                              loading={apiFourWheelerLoading}
                              onClick={() => ApiClickFourWheeler()}
                              block
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={12}>
                          {vehicleVal === "1" ? (
                            <Col span={24}>
                              <div
                                className="border-class"
                                style={{ marginTop: "20px" }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title p-10"
                                >
                                  API RESPONSE [Two Wheeler]
                                </Title>
                                <Row className="p-10">
                                  <Col className="gutter-row" span={24}>
                                    <p>
                                      <b>OWNER: </b> {callApiResponseTwo?.owner}{" "}
                                      <br />
                                      <b>VEHICLE TYPE: </b>{" "}
                                      {callApiResponseTwo?.vehicle_type} <br />
                                      <b>OWNER FATHER: </b>{" "}
                                      {callApiResponseTwo?.owner_father} <br />
                                      <b>INSURANCE VALID TILL: </b>{" "}
                                      {callApiResponseTwo?.insurance_valid_till}{" "}
                                      <br />
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col span={12}>
                          {fourVehicle === "1" ? (
                            <Col span={24}>
                              <div
                                className="border-class"
                                style={{ marginTop: "20px" }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title p-10"
                                >
                                  API RESPONSE [Four Wheeler]
                                </Title>
                                <Row className="p-10">
                                  <Col className="gutter-row" span={24}>
                                    <p>
                                      <b>OWNER: </b>{" "}
                                      {callApiResponseFour?.owner} <br />
                                      <b>VEHICLE TYPE: </b>{" "}
                                      {callApiResponseFour?.vehicle_type} <br />
                                      <b>OWNER FATHER: </b>{" "}
                                      {callApiResponseFour?.owner_father} <br />
                                      <b>INSURANCE VALID TILL: </b>{" "}
                                      {
                                        callApiResponseFour?.insurance_valid_till
                                      }{" "}
                                      <br />
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={4}>
                    <Title level={5} className="poppins_medium umoney_title">
                      Parent Contact
                    </Title>
                  </Col>
                  <Col span={16}>
                    {role === "approver" ? (
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="ALGO LOGS"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                            disabled={value == "" ? true : false}
                            onClick={() => openModal("algo")}
                          />
                        </Col>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="ALGO & PHONE PERMISSION"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                            disabled={value == "" ? true : false}
                            onClick={() => {
                              openModal("phone-permission");
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="PREVIOUS USER HISTORY"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                            disabled={value == "" ? true : false}
                            onClick={() => openModal("linked-user")}
                          />
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col className="gutter-row" span={4}>
                    <CLoadingButton
                      size="large"
                      value="SAVE & APPROVE"
                      className="poppins_medium umoney_btn"
                      block
                      onClick={() => parentContactSave()}
                      loading={parentContactSaveLoading}
                    />
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form
                      name="basic"
                      onFinish={onFinishInformation}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Form.Item
                            label="User's primary device"
                            name="Device"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Select Device",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Select
                              label="User’s primary device"
                              options={primaryDeviceDrp}
                              size="large"
                              classes="poppins_regular filter_select_border br"
                              onChange={(e) => {
                                setDevice_id(e);
                                setDisable(false);
                                setValue(e);
                              }}
                              value={value}
                              style={{ width: "100%" }}
                              placeholder="Select primary device"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={18}></Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col span={12}>
                    {role === "approver" ? (
                      <Row gutter={[16, 16]}>
                        <Col span={3}>
                          <Button
                            className="primary-device-btn btntext-all"
                            style={{
                              background: "#DE1717 0% 0% no-repeat padding-box",
                              color: "white",
                              border: "none",
                              height: "40px",
                              width: "100px",
                            }}
                          >
                            Fail
                          </Button>
                        </Col>
                        <Col span={7}>
                          <CLoadingButton
                            size="large"
                            value="SET PRIMARY"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                          />
                        </Col>
                        <Col span={7}>
                          <CLoadingButton
                            size="large"
                            value="EXPORT CALL LOGS"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                          />
                        </Col>
                        <Col span={7}>
                          <CLoadingButton
                            size="large"
                            value="EXPORT CONTACTS"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            onClick={() => extractContactsData()}
                            block
                          />
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Title
                  level={5}
                  className="poppins_semi"
                  style={{ marginTop: 20 }}
                >
                  User provided info
                </Title>

                <div className="umoney_table">
                  <Table
                    columns={UserProvidedColumns}
                    dataSource={userProvidedData}
                    scroll={{
                      x: 1300,
                    }}
                    pagination={{
                      position: ["none", "bottomLeft"],
                      locale: { items_per_page: "" },
                      showSizeChanger: true,
                      defaultPageSize: 5,
                      pageSizeOptions: [5, 10, 15, 20],
                    }}
                  />
                </div>
                <Title
                  level={5}
                  className="poppins_semi"
                  style={{ marginTop: 20 }}
                >
                  User provided info matched with other users
                </Title>

                <div className="umoney_table">
                  <Table
                    columns={MatchedOtherUserColumns}
                    dataSource={matchData}
                    scroll={{
                      x: 1300,
                    }}
                    pagination={{
                      position: ["none", "bottomLeft"],
                      locale: { items_per_page: "" },
                      showSizeChanger: true,
                      defaultPageSize: 5,
                      pageSizeOptions: [5, 10, 15, 20],
                    }}
                  />
                </div>
                <Title
                  level={5}
                  className="poppins_semi"
                  style={{ marginTop: 20 }}
                >
                  Other possible parent’s numbers in contact list
                </Title>
                <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                  <Col className="gutter-row" span={12}>
                    <Form
                      name="basic"
                      onFinish={onFinishInformation}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Form.Item
                            label="Choose device"
                            name="Device"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Select Device",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Select
                              label="Choose device"
                              options={deviceListDrp}
                              size="large"
                              classes="poppins_regular filter_select_border br"
                              style={{ width: "100%" }}
                              onChange={(e) => selectDevice(e)}
                              placeholder="Select device"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={18}></Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <div className="umoney_table" style={{ marginTop: 10 }}>
                  <Table
                    columns={ContactlistColumns}
                    dataSource={deviceData}
                    scroll={{
                      x: 1300,
                    }}
                    pagination={{
                      position: ["none", "bottomLeft"],
                      locale: { items_per_page: "" },
                      showSizeChanger: true,
                      defaultPageSize: 5,
                      pageSizeOptions: [5, 10, 15, 20],
                    }}
                  />
                </div>
                <Title
                  level={5}
                  className="poppins_semi"
                  style={{ marginTop: 20 }}
                >
                  Mobile number in all devices user has logged in from
                </Title>
                <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                  <Col className="gutter-row" span={8}>
                    <Search
                      placeholder="Enter Keyword"
                      size="large"
                      style={{ width: "100%" }}
                      onSearch={(e) => {
                        searchDeviceLog(e);
                      }}
                    />
                  </Col>
                </Row>
                <div className="umoney_table" style={{ marginTop: 10 }}>
                  <Table
                    columns={AllDevicesColumns}
                    dataSource={resultDevice}
                    scroll={{
                      x: 1300,
                    }}
                    pagination={{
                      position: ["none", "bottomLeft"],
                      locale: { items_per_page: "" },
                      showSizeChanger: true,
                      defaultPageSize: 5,
                      pageSizeOptions: [5, 10, 15, 20],
                    }}
                  />
                </div>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Form
                  name="basic"
                  onFinish={onFinishSocialMedia}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="umoney-form mb-35"
                  requiredMark={false}
                  form={socialMediaForm}
                >
                  <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                    <Col span={16}>
                      <Title level={5} className="poppins_medium umoney_title">
                        Social Media Details
                      </Title>
                    </Col>
                    <Col span={8}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            className="poppins_medium umoney_btn"
                            htmlType="submit"
                            block
                            loading={socialMediaSaveLoading}
                          />
                        </Col>
                        <Col span={12}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            className="poppins_medium umoney_btn"
                            htmlType="submit"
                            block
                            onClick={() => setIsApprove(true)}
                            loading={socialMediaAppLoading}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Row gutter={[16, 16]}>
                            <Col span={3}>
                              <Title
                                level={5}
                                className="poppins_medium umoney_title"
                              >
                                Facebook
                              </Title>
                            </Col>
                            <Col span={7}>
                              <Text className="poppins_medium">Name: </Text>
                              <Text className="poppins_medium">
                                {!_.isEmpty(
                                  userData?.socialMedia?.facebook?.name
                                )
                                  ? userData?.socialMedia?.facebook?.name
                                  : "-"}
                              </Text>
                            </Col>

                            <Col span={7}>
                              <Text className="poppins_medium">Email: </Text>
                              <Text className="poppins_medium">
                                {!_.isEmpty(
                                  userData?.socialMedia?.facebook?.email
                                )
                                  ? userData?.socialMedia?.facebook?.email
                                  : "-"}
                              </Text>
                            </Col>
                            <Col span={7}>
                              <div style={{ display: "flex" }}>
                                <Text className="poppins_medium">Image: </Text>

                                {!_.isEmpty(
                                  userData?.socialMedia?.facebook?.profile_image
                                ) ? (
                                  <img
                                    src={
                                      userData?.socialMedia?.facebook
                                        ?.profile_image
                                    }
                                    // alt="User"
                                    style={{ height: "50%", width: "60%" }}
                                  />
                                ) : (
                                  "-"
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row gutter={[16, 16]}>
                            <Col span={3}>
                              <Title
                                level={5}
                                className="poppins_medium umoney_title"
                              >
                                Linkdin
                              </Title>
                            </Col>
                            <Col span={7}>
                              <Text className="poppins_medium">Name: </Text>
                              <Text className="poppins_medium">
                                {!_.isEmpty(
                                  userData?.socialMedia?.linkedin?.name
                                )
                                  ? userData?.socialMedia?.linkedin?.name
                                  : "-"}
                              </Text>
                            </Col>

                            <Col span={7}>
                              {/* <Text className="poppins_medium">Email: </Text>
                              <Text className="poppins_medium">
                                {!_.isEmpty(
                                  userData?.socialMedia?.linkedin?.email
                                )
                                  ? userData?.socialMedia?.linkedin?.email
                                  : "-"}
                              </Text> */}
                            </Col>
                            <Col span={7}>
                              <div style={{ display: "flex" }}>
                                <Text className="poppins_medium">Image: </Text>
                                {!_.isEmpty(
                                  userData?.socialMedia?.linkedin?.profile_image
                                ) ? (
                                  <img
                                    className="kyc-images"
                                    src={
                                      userData?.socialMedia?.linkedin
                                        ?.profile_image
                                    }
                                    alt="User"
                                    style={{ height: "50%", width: "60%" }}
                                  />
                                ) : (
                                  "-"
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    {role === "approver" ? (
                      <>
                        <Col span={12}>
                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                label="Geniune"
                                name="geniune_fb"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Selection is required",
                                  },
                                ]}
                                className="poppins_medium"
                              >
                                <Radio.Group
                                  className="m-10"
                                  options={radioValues}
                                  onChange={(e) => setFbValue(e.target.value)}
                                ></Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              {fbValue === "2" || fbValue === "0" ? (
                                <Form.Item
                                  label="Remark"
                                  name="remark_fb"
                                  size="large"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Remark is required",
                                    },
                                  ]}
                                  className="poppins_medium"
                                >
                                  <TextArea size="large" rows={1} />
                                </Form.Item>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Geniune"
                                name="geniune_ln"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Selection is required",
                                  },
                                ]}
                                className="poppins_medium"
                              >
                                <Radio.Group
                                  className="m-10"
                                  options={radioValues}
                                  onChange={(e) => setLnValue(e.target.value)}
                                ></Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              {lnValue === "0" || lnValue === "2" ? (
                                <Form.Item
                                  label="Remark"
                                  name="remark_ln"
                                  size="large"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Remark is required",
                                    },
                                  ]}
                                  className="poppins_medium"
                                >
                                  <TextArea size="large" rows={1} />
                                </Form.Item>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Form>
              </div>
              <Collapse accordion style={{ marginTop: 20 }}>
                <Panel header="Upload files" key="1">
                  <UploadFiles pageType={pageType} user_id={user_id} />
                </Panel>
              </Collapse>
              <Modal
                open={modalType}
                title={modalTitle}
                onCancel={handleClose}
                centered
                width={modalWidth}
                footer={false}
              >
                {modalType === "father" ? (
                  <UpdateDetailsForFather
                    role={role}
                    user_id={user_id}
                    record={userRecord}
                    handleClose={handleClose}
                    userRole={userRole}
                    isSuccess={(data) => {
                      setChangeLoading(data);
                    }}
                  />
                ) : modalType === "mother" ? (
                  <UpdateDetailForMother
                    role={role}
                    user_id={user_id}
                    record={userRecord}
                    handleClose={handleClose}
                    userRole={userRole}
                    isSuccess={(data) => {
                      setChangeLoading(data);
                    }}
                  />
                ) : modalType === "other" ? (
                  <UpdateDetailForOther
                    role={role}
                    user_id={user_id}
                    handleClose={handleClose}
                    userRole={userRole}
                    record={userRecord}
                    isSuccess={(data) => {
                      setChangeLoading(data);
                    }}
                  />
                ) : modalType === "history" ? (
                  <HistoryS9 />
                ) : modalType === "clear-in-app" ? (
                  <ClearInApp user_id={user_id} pageType={pageType} />
                ) : modalType === "view-change-log" ? (
                  <Viewchangelog
                    page={page}
                    setPage={setPage}
                    current={current}
                    setCurrent={setCurrent}
                    pageType={pageType}
                    user_id={user_id}
                  />
                ) : modalType === "call-status-log" ? (
                  <CallStatusLogModal
                    user_id={user_id}
                    current={current}
                    setCurrent={setCurrent}
                    page={page}
                    setPage={setPage}
                    pageType={pageType}
                  />
                ) : modalType === "click-to-call" ? (
                  <ClickToCallModal />
                ) : modalType === "call-history" ? (
                  <CallHistory
                    user_id={user_id}
                    userType={
                      userRole === "college-student" ? "stu_cc" : "sal_cc"
                    }
                  />
                ) : modalType === "phone-permission" ? (
                  <PhonePermissionCreditCheck
                    userId={user_id}
                    deviceId={device_id}
                  />
                ) : modalType === "algo" ? (
                  <Algo userId={user_id} type="algo-cc" deviceId={device_id} />
                ) : (
                  ""
                )}
              </Modal>
            </Content>
          </Layout>
        </Layout>
      </div>
    </Spin>
  );
};
export default DetailviewCreditCheck;
