import { Col, Modal, Row } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const EmailBanner = (props) => {
  const {
    onSave = () => {},
    bannerData,
    modalType,
    modalTitle,
    setModalType,
  } = props;

  const [form] = Form.useForm();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const onSubmitData = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "add-email-banner"
        ? "template/create-banner"
        : "template/update-banner/" + bannerData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setModalType(false);
  };

  useEffect(() => {
    if (modalType === "add-email-banner") {
      form.resetFields();
    } else {
      form.setFieldsValue(bannerData);
    }
  }, [form, bannerData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={500}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="poppins_medium" style={{ marginTop: "10px" }}>
        <Form
          name="basic"
          //onFinish={onFinish}
          onFinish={(data) => {
            onSubmitData(data);
          }}
          onFinishFailed={onFinishFailed}
          initialValues={modalType === "edit-email-banner" ? bannerData : ""}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="URL"
                name="url"
                rules={[
                  { required: true, message: "URL is required!" },
                  {
                    pattern:
                      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                    message: "Enter Valid URL",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input placeholder="URL will be here." size="large" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="Save"
                  className="poppins_medium umoney_btn"
                  loading={saveBtnLoading}
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EmailBanner;
