import { MenuOutlined } from "@ant-design/icons";
import { Col, Collapse, Layout, Row, Tag, Typography, Form, Input } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { useEffect } from "react";
import CClickLoading from "../../components/InputFields/CClickLoading";
import CSpin from "../../components/InputFields/CSpin";
import Sidebar from "../../components/Sidebar";
import EmploymentInformtaionDetails from "../../components/Accordion/EmploymentInformationDetails";
import OfferLetter from "../../components/Accordion/OfferLetter";
import BankStatement from "../../components/Accordion/BankStatement";
import OtherBankStatement from "../../components/Accordion/OtherBankStatement";
import CompanyIdCard from "../../components/Accordion/CompanyIdCard";
import PANFront from "../../components/Accordion/PAN/PANFront";
import SalarySlip from "../../components/Accordion/SalarySlip";
import SalaryInformation from "../../components/Accordion/SalaryInformation";
import SalaryCreditBank from "../../components/Accordion/SalaryCreditBank";
import ResubmittedGraduationDetails from "../../components/Accordion/ResubmittedGraduationDetails";

const { Title } = Typography;
const { Panel } = Collapse;

const ResubmittedLogs = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const loadData = () => {
    setTimeout(() => {
      setLoading(false);
      setPageLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  });

  const data = [
    {
      title: "Currently Working",
      data: {
        name: "working",
        work: "done",
      },
    },
    {
      title: "Received Job Offer",
      data: {
        name: "job-offer",
        work: "undone",
      },
    },
    {
      title: "Currently Working",
      data: {
        name: "work",
        work: "undone",
      },
    },
    {
      title: "Will Continue Studying",
      data: {
        name: "studying",
        work: "undone",
      },
    },
  ];

  return pageLoading === true ? (
    <CSpin />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            // height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined
                className="trigger"
                onClick={() => {
                  setOpen(true);
                }}
              />
              <Title level={4} className="poppins_regular umoney_page_title">
                {" "}
                Resubmitted Logs
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: 15,
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div">
                <Collapse accordion>
                  {data.map((item, index) => (
                    <Panel header={item?.title} key={index}>
                      {item?.title === "Currently Working" ? (
                        <Row gutter={[16, 16]}>
                          <Col className="gutter-row" span={6}>
                            <div className="border-class scroll_bar">
                              <Title
                                level={5}
                                className="poppins_medium umoney_title p-10"
                              >
                                Images
                              </Title>
                              <OfferLetter />
                              <BankStatement />
                              <OtherBankStatement />
                              <CompanyIdCard />
                              <PANFront />
                              <SalarySlip />
                            </div>
                          </Col>
                          <Col className="gutter-row" span={18}>
                            <Collapse accordion>
                              <Panel header="Company Information" key="1">
                                <EmploymentInformtaionDetails />
                              </Panel>
                              <Panel header="Other information" key="2">
                                <Form
                                  name="basic"
                                  // onFinish={onFinishCallStatus}
                                  // onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  // form={callStatusForm}
                                >
                                  <Row gutter={[16, 16]}>
                                    <Col className="gutter-row mt-10" span={8}>
                                      <Form.Item
                                        label="Company website"
                                        name="company_website"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "website is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Input
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="gutter-row mt-10" span={8}>
                                      <Form.Item
                                        label="No. of employees"
                                        name="no_of_employees"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "number of employee is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Input
                                          size="large"
                                          />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              </Panel>
                              <Panel header="Salary Information" key="3">
                                <SalaryInformation />
                              </Panel>
                              <Panel header="Salary credits into Bank" key="4">
                                <SalaryCreditBank />
                              </Panel>
                            </Collapse>
                          </Col>
                        </Row>
                      ) : item?.title === "Received Job Offer" ? (
                        <Row gutter={[16, 16]}>
                          <Col className="gutter-row" span={6}>
                            <div className="border-class scroll_bar">
                              <Title
                                level={5}
                                className="poppins_medium umoney_title p-10"
                              >
                                Images
                              </Title>
                              <OfferLetter />
                            </div>
                          </Col>
                          <Col className="gutter-row" span={18}>
                            <Collapse accordion>
                              <Panel header="Company Information" key="1">
                                <EmploymentInformtaionDetails />
                              </Panel>
                              <Panel header="Other information" key="2">
                                <Form
                                  name="basic"
                                  // onFinish={onFinishCallStatus}
                                  // onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  // form={callStatusForm}
                                >
                                  <Row gutter={[16, 16]}>
                                    <Col className="gutter-row mt-10" span={8}>
                                      <Form.Item
                                        label="Company website"
                                        name="company_website"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "website is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Input
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="gutter-row mt-10" span={8}>
                                      <Form.Item
                                        label="No. of employees"
                                        name="no_of_employees"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "number of employee is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Input
                                          size="large"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              </Panel>
                              <Panel header="Salary Information" key="3">
                                <SalaryInformation />
                              </Panel>
                            </Collapse>
                          </Col>
                        </Row>
                      ) : item.title === "Will Continue Studying" ? (
                        <Row gutter={[16, 16]}>
                          <Col className="gutter-row" span={6}>
                            <div className="border-class scroll_bar">
                              <Title
                                level={5}
                                className="poppins_medium umoney_title p-10"
                              >
                                Images
                              </Title>
                              <OfferLetter />
                            </div>
                          </Col>
                          <Col className="gutter-row" span={18}>
                            <Collapse accordion>
                              <Panel
                                header="Resubmitted graduation details"
                                key="1"
                              >
                                <ResubmittedGraduationDetails />
                              </Panel>
                            </Collapse>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default ResubmittedLogs;
