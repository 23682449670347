import { message, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import AddDocumentAndComment from "../Modals/modal/CommonModal/AddDocumentAndComment";
import { getAPIData } from "../../apiHelper";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { PreviewImage } from "../Modals/modal/CommonModal/PreviewImage";
import { useRef } from "react";
import Draggable from "react-draggable";
import PreviewModal from "../Modals/modal/CommonModal/PreviewModal";

const { Text } = Typography;

const UploadFiles = ({ pageType, user_id }) => {
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      // reader.onerror = (error) => reject(error);
    });

  const previewModalRef = useRef(null);

  const location = useLocation();
  const pathName = location?.pathname.split("/");
  // const user_id = pathName[pathName.length - 1];

  const [modalType, setModalType] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [modalTypeTitle, setModalTypeTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const [modalFile, setModalFileName] = useState("");
  const [modalFileType, setModalFileType] = useState({});
  const [modalFileComment, setModalFileComment] = useState("");
  // console.log(fileList);
  const handleCancel = () => setModalType("");

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setModalType("preview");
    setModalTypeTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleClose = () => {
    setModalType("");
  };

  const uploadFileValidation = () => {
    if (_.isEmpty(modalFileType)) {
    } else if (_.isEmpty(modalFile)) {
    } else {
      uploadFileApi();
    }
  };
  const [modalTitle, setModalTitle] = useState("");
  const [showMore, setShowMore] = useState("");

  const openModal = (type, id) => {
    if (type === "show-full-html") {
      setModalType(type);
      setShowMore(id);
      setModalTitle("Show Description");
    }
  };

  const uploadFileApi = () => {
    const endPoint = ``;
    const data = {
      image_type: modalFileType,
      description: modalFileComment,
      user_id: user_id,
      frontImg: modalFile,
    };
    getAPIData(endPoint, "post", data, false, "admin")
      .then((result) => {
        console.log(`💥️💥️💥️ ~~ .then ~~ result`, result);
        if (result?.status === true) {
          setModalType("");
          setModalTypeTitle("");
          setModalFileName("");
          setModalFileType("");
          setModalFileComment("");
        } else {
          console.log(`💥️💥️💥️ ~~ .then ~~ False -- >`, result);
        }
      })
      .catch((err) => {
        console.warn(`💥️💥️💥️ ~~ uploadFileApi ~~ err -- >`, err);
      });
  };

  useEffect(() => {
    agentUploadFileListApi();
  }, []);

  console.log(showMore);

  const agentUploadFileListApi = () => {
    const endPoint =
      pageType === "credit-check-detail"
        ? `cc-steps/agent-upload-file-list/${user_id}`
        : pageType === "std-to-sal"
        ? `stos-steps/agent-upload-file-list/${user_id}`
        : `steps/agent-upload-file-list/${user_id}`;
    getAPIData(
      endPoint,
      "get",
      {},
      false,
      pageType === "salaried"
        ? "salaried"
        : pageType === "credit-check-detail"
        ? "credit_check"
        : pageType === "std-to-sal"
        ? "stu_to_sal"
        : "student"
    )
      .then((result) => {
        console.log(`💥️💥️💥️ ~~ .then ~~ result`, result);
        if (result?.status === true) {
          setFileList(result?.data);
        } else {
          console.log(`💥️💥️💥️ ~~ .then ~~ False -- >`, result);
        }
      })
      .catch((err) => {
        console.warn(`💥️💥️💥️ ~~ agentUploadFileListApi ~~ err -- >`, err);
      });
  };

  // Modal Draggable
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <div>
      <Row gutter={[16, 20]} style={{ padding: 0 }}>
        {/* <Col className="gutter-row" span={6}>
          <iframe
            src="https://docs.google.com/viewerng/viewer?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true
"
            title="iframe"
            //frameborder="0"
            height="100%"
            width="100%"
          ></iframe>
        </Col> */}
        {/* <Row gutter={[16, 16]}> */}
        <div
          className="scroll_horizontal_bar"
          style={{
            overflowY: "revert",
            display: "flex",
            overflowX: "scroll",
            position: "relative",
          }}
        >
          {/* <div> */}
          {_.isArray(fileList) && !_.isEmpty(fileList)
            ? fileList?.map((item, index) => {
                return (
                  <div style={{ padding: 5, flexWrap: "nowrap" }}>
                    <div
                      style={{
                        height: "150px",
                        width: "300px",
                        borderWidth: 1,
                        borderStyle: "dashed",
                        borderColor: "#009d93",
                        background: "#DFFEFC",
                        marginBottom: 10,
                        borderRadius: 5,
                      }}
                    >
                      {item?.image_url
                        .substring(item?.image_url.lastIndexOf("/") + 1)
                        .split(".")[1] === "pdf" ? (
                        <div>
                          <iframe
                            src={item?.image_url}
                            title="iframe"
                            height="100%"
                            width="100%"
                          ></iframe>
                          <div
                            style={{
                              position: "relative",
                              zIndex: 10,
                              width: "100%",
                              height: "150px",
                              bottom: "158px",
                            }}
                            onClick={() => {
                              previewModalRef?.current?.open(
                                item?.image_url,
                                "pdf"
                              );
                            }}
                          ></div>
                        </div>
                      ) : (
                        <img
                          src={item?.image_url}
                          style={{ height: "100%", width: "100%" }}
                          onClick={() => {
                            setPreviewImage(item?.image_url);
                            setModalType("preview");
                            setModalTypeTitle("Preview Image");
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div
                      style={{
                        height: "150px",
                        width: "300px",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#009d93",
                        background: "#FFF",
                        borderRadius: 5,
                        padding: 15,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 16,
                          fontFamily: "poppinsMedium",
                        }}
                      >
                        Document type:{" "}
                        <span
                          style={{
                            color: "#009D93",
                          }}
                        >
                          {item?.title}
                        </span>
                      </span>
                      <div
                        style={{
                          fontSize: 16,
                          fontFamily: "poppinsMedium",
                          color: "#009D93",
                          width: "100%",
                          overflowX: "hidden",
                        }}
                      >
                        {item?.description?.substring(0, 60)}
                        <Text
                          onClick={() => {
                            openModal("show-full-html", item?.description);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {item?.description?.length > 70
                            ? "....Read More"
                            : ""}
                        </Text>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "310px",
                width: "310px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 1,
                borderStyle: "dashed",
                borderColor: "#009d93",
                borderRadius: 5,
                background: "#DFFEFC",
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 10,
              }}
              onClick={() => {
                setModalType("upload-doc");
                setModalTypeTitle("Add Document type & comment");
              }}
            >
              <PlusOutlined
                style={{
                  color: "#009D93",
                  fontSize: "50px",
                  cursor: "pointer",
                }}
              />
              <div
                style={{
                  marginTop: 8,
                  color: "#009D93",
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: "poppinsRegular",
                    cursor: "pointer",
                  }}
                >
                  Add New
                </span>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </Row>
      <Modal
        // destroyOnClose={true}
        open={modalType}
        onCancel={handleCancel}
        title={modalTypeTitle}
        footer={null}
        // style={
        // {
        // minWidth: "250px",
        // border:"1px solid black"
        //}
        //}
        centered
        mask={false}
        maskClosable={false}
        maskStyle={{ pointerEvents: "none" }}
        style={{ pointerEvents: "all" }}
        wrapClassName={"modal-wrapper"}
        // destroyOnClose={true}
        modalRender={(modal) => {
          if (modalType === "preview") {
            return (
              <Draggable
                // disabled={disabled}
                bounds={bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>
            );
          }
          return <div>{modal}</div>;
        }}
      >
        {modalType === "preview" ? (
          <PreviewImage Image={previewImage} />
        ) : modalType === "upload-doc" ? (
          <AddDocumentAndComment
            setModel={setModalType}
            onCancel={handleCancel}
            load={agentUploadFileListApi}
            pageType={pageType}
            user_id={user_id}
          />
        ) : (
          ""
        )}
      </Modal>
      {modalType === "show-full-html" ? (
        <Modal
          open={modalType}
          onCancel={handleClose}
          title={modalTitle}
          className="poppins_regular"
          width={1000}
          footer={null}
          closable={true}
          centered={true}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          {showMore}
        </Modal>
      ) : (
        ""
      )}
      <PreviewModal ref={previewModalRef} />
    </div>
  );
};

export default UploadFiles;
