import React, { useState, useEffect } from "react";
import { Typography, Table, Col, Row, Form, message, Switch } from "antd";
import CClickLoading from "../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import MobileAppModals from "../../components/Modals/MobileAppModals";
import _ from "lodash";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import { setHeader } from "../../api";

const SmsTemplate = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [smsData, setSMSData] = useState([]);
  const [deleteId, setDeleteId] = useState("");

  //open modal
  const [modalType, setModalType] = useState("");

  const loadTemplateData = () => {
    const endPoint = "template/sms-list";
    setLoading(true);
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
          setLoading(false);
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  //Set the values of Status in list
  const setValues = (email_id) => {
    let endPoint = `template/sms-changeStatus/` + email_id;
    const header = setHeader();

    getAPIData(endPoint, "get", {}, header)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadTemplateData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadEditEmailData = (id) => {
    const endPoint = "template/sms-get/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setSMSData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "collection_or_repayment" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              form.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();

    if (_.isEmpty(tableData)) {
      loadTemplateData();
    }
  }, [form, tableData]);

  const openModal = (type, id) => {
    if (type === "add-sms-content") {
      setModalType(type);
      setModalOpen(true);
    } else if (type === "edit-sms-content") {
      loadEditEmailData(id);
      setModalType(type);
      setModalOpen(true);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalOpen(true);
    }
  };

  const TableColumns = [
    {
      title: "SMS Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span>
          {record?.type === "1"
            ? "Registration"
            : record?.type === "2"
            ? "Bank"
            : record?.type === "3"
            ? "UPI"
            : record?.type === "4"
            ? "Help"
            : record?.type === "5"
            ? "Collection"
            : record?.type === "6"
            ? "Loan Filter"
            : record?.type === "7"
            ? "Device Change"
            : record?.type === "8"
            ? "Salary credit check extended"
            : record?.type === "9"
            ? "Loan Extension"
            : ""}
        </span>
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "CONTENT",
      dataIndex: "message_body",
      key: "message_body",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <div>
          <span>
            <Switch
              checked={record.status === "1" ? true : false}
              onChange={() => {
                setValues(record?.id);
              }}
            />
          </span>
        </div>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-sms-content", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />

        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <Title level={5} className="poppins_medium umoney_title">
                  SMS CONTENT CONFIGURATION
                </Title>
              </Col>
              <Col span={8}>
                <CLoadingButton
                  size="large"
                  value="ADD NEW SMS"
                  className="poppins_medium umoney_btn"
                  //icon={<PlusOutlined className="btn-icon" />}
                  onClick={() => openModal("add-sms-content")}
                  block
                />
              </Col>
            </Row>

            <div
              className="poppins_medium umoney_table"
              style={{ width: "100%", marginTop: "10px" }}
            >
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={TableColumns}
                dataSource={tableData}
              />
            </div>
          </div>
        </Content>
      </Layout>
      {modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          title="Delete Confirmation"
          type="sms_template"
          setOpen={setModalOpen}
          modalType={modalType}
          id={deleteId}
          setModalType={setModalType}
          onDelete={(val) => {
            setModalOpen(false);
            loadTemplateData();
          }}
        />
      ) : modalType === "edit-sms-content" ? (
        <MobileAppModals
          open={modalOpen}
          setOpen={setModalOpen}
          setModalType={setModalType}
          onSave={() => {
            setModalOpen(false);
            loadTemplateData();
          }}
          // smsData={modalType === "edit-sms-content" ? smsData : modalType === "add-sms-content" ? [] : ""}
          smsData={smsData}
          // title={
          //   modalType === "add-sms-content"
          //     ? "Add SMS content configuration" : modalType === "edit-sms-content" ?
          //      "Edit SMS content configuration" : null
          // }
          modalType={modalType}
          title="Edit SMS Content Configuration"
        />
      ) : modalType === "add-sms-content" ? (
        <MobileAppModals
          open={modalOpen}
          setOpen={setModalOpen}
          setModalType={setModalType}
          onSave={() => {
            setModalOpen(false);
            loadTemplateData();
          }}
          smsData={smsData}
          modalType={modalType}
          title="Add SMS Content Configuration"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default SmsTemplate;
