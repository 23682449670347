import { Col, DatePicker, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import CLoadingButton from "../InputFields/CLoadingButton";

const SalaryInformation = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const loadData = () => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const [salaryInfoCallApiBtnLoading, setSalaryInfoCallApiBtnLoading] =
    useState(false);
  const [salaryInfoDetailsBtnLoading, setSalaryInfoDetailsBtnLoading] =
    useState(false);

  const salaryInfoCallApiClick = () => {
    setSalaryInfoCallApiBtnLoading(true);
    setLoading(true);
  };
  const salaryInfoDetailsClick = () => {
    setSalaryInfoDetailsBtnLoading(true);
    setLoading(true);
  };

  useEffect(() => {
    loadData();
  });
  return (
    <div>
      {" "}
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              label="Annual CTC"
              name="Annual CTC"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Annual CTC required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter annual CTC"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Monthly Take Home Salary"
              name="Monthly Take Home Salary"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Monthly Take Home Salary required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter montly take home salary"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Last Salary Received Date"
              name="Last Salary Received Date"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "last salary received required",
                },
              ]}
            >
              <DatePicker size="large" style={{ width: "100%" }} placeholder="Select last salary received date"></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="PAN nmuber"
              name="PAN number"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "PAN number Salary required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter PAN number"></Input>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item className="poppins_medium">
              <CLoadingButton
                size="large"
                value="CALL API"
                className="poppins_medium umoney_btn"
                loading={salaryInfoCallApiBtnLoading}
                onClick={() => salaryInfoCallApiClick()}
                block
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item className="poppins_medium">
              <CLoadingButton
                size="large"
                value="DETAILS"
                className="poppins_medium umoney_btn"
                loading={salaryInfoDetailsBtnLoading}
                onClick={() => salaryInfoDetailsClick()}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SalaryInformation;
