import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import { PlusOutlined } from "@ant-design/icons";
import { getAPIData } from "../../apiHelper";
import CollegeDetailsModal from "../Modals/CollegeDetailsModal";
import _ from "lodash";
import moment from "moment";
import CropperModal from "../Modals/CropperModal";
import CCloseIcon from "../InputFields/CCloseIcon";

const { Text } = Typography;

const StudentInformationDetails = (props) => {
  console.log("props", props);
  const stdInfo = props?.stdInfo;
  const stdData = props?.data;
  console.log("stdInfo", stdInfo);
  const pincodeList = props?.pincodeList;
  const user_id = props?.user_id;
  const [form] = Form.useForm();

  console.log("jshdjdj", stdData?.personalInfo?.date_of_birth);

  const [frontImage, setFrontImage] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [idApprover, setIdApprover] = useState(false);

  //college list
  const [collegeList, setCollegeList] = useState([]);

  //marktype state
  const [markType, setMarkType] = useState(stdInfo?.course?.mark_type);

  //degree list state
  const [degreeList, setDegreeList] = useState([]);

  //Specialization list state
  const [specializationList, setSpecializationList] = useState([]);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);

  //Modal
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [collegeId, setCollegeId] = useState(stdInfo?.college_data?.college_id);

  const [startYear, setStartYear] = useState(stdInfo?.course?.start_from_year);

  const [modalOpen, setModalOpen] = useState(false);

  const [srcImg, setSrcImg] = useState();

  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");
  const [modalTypeCrop, setModalTypeCrop] = useState("");

  const [stdIdFrontName, setStdIdFrontName] = useState();
  const [stdIdBackName, setStdIdBackName] = useState();

  useLayoutEffect(() => {
    getPincodeValue(0, stdInfo?.pincode);
    getDegreeList(stdInfo?.course?.type_of_degree);
    getSpecializationList(stdInfo?.course?.spec_id);
  }, []);

  const handleClose = () => {
    setModalType("");
  };

  const onFinish = async (data) => {
    props?.loading(true);
    const endpoint = "steps/save-student-college";
    let images = [];
    if (idApprover) {
      props?.loading(true);
      setApproveBtnLoading(true);
      data.idApprover = idApprover;
    } else {
      props?.loading(true);
      setSaveBtnLoading(true);
    }
    if (frontImage?.[0]) {
      images.push({
        imageBase64: frontImage.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: stdIdFrontName,
        type: "frontImg",
      });
    }
    if (backImage?.[0]) {
      images.push({
        imageBase64: backImage.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: stdIdBackName,
        type: "backImg",
      });
    }

    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }

    data.type = "admin";
    data.user_id = user_id;
    getAPIData(endpoint, "post", data, false, "student")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          props?.isSuccess(true);
          props?.loading(false);
          setIdApprover(false);
          if (idApprover) {
            props?.loading(false);
            setApproveBtnLoading(false);
          } else {
            props?.loading(false);
            setSaveBtnLoading(false);
          }
        } else {
          message?.warning(result?.message);
          props?.loading(false);
          if (idApprover) {
            props?.loading(false);
            setApproveBtnLoading(false);
          } else {
            props?.loading(false);
            setSaveBtnLoading(false);
          }
        }
        if (idApprover) {
          props?.loading(false);
          setApproveBtnLoading(false);
        } else {
          props?.loading(false);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        if (idApprover) {
          props?.loading(false);
          setApproveBtnLoading(false);
        } else {
          props?.loading(false);
          setSaveBtnLoading(false);
        }
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  //mark type list
  const markTypeData = [
    { label: "CGPA", value: "cgpa" },
    { label: "Percentage", value: "percentage" },
  ];

  // degree type list
  const degreeTypeList = [
    {
      value: "1",
      label: "Diploma",
    },
    {
      value: "2",
      label: "UnderGraduate",
    },
    {
      value: "3",
      label: "Post-Graduate",
    },
    {
      value: "4",
      label: "PGDM",
    },
    {
      value: "5",
      label: "PGPM",
    },
    {
      value: "6",
      label: "Ph.D",
    },
  ];

  const attendanceData = [
    {
      value: "1",
      label: "Regular",
    },
    {
      value: "2",
      label: "Distance",
    },
    {
      value: "3",
      label: "Remote",
    },
    {
      value: "4",
      label: "Part Time",
    },
  ];

  const studentData = [
    {
      value: "1",
      label: "0-500",
    },
    {
      value: "2",
      label: "501-1000",
    },
    {
      value: "3",
      label: "1001-5000",
    },
    {
      value: "4",
      label: ">5000",
    },
  ];

  const months = [
    {
      value: "Jan",
      label: "Jan",
    },
    {
      value: "Feb",
      label: "Feb",
    },
    {
      value: "Mar",
      label: "Mar",
    },
    {
      value: "Apr",
      label: "Apr",
    },
    {
      value: "May",
      label: "May",
    },
    {
      value: "Jun",
      label: "Jun",
    },
    {
      value: "Jul",
      label: "Jul",
    },
    {
      value: "Aug",
      label: "Aug",
    },
    {
      value: "Sep",
      label: "Sep",
    },
    {
      value: "Oct",
      label: "October",
    },
    {
      value: "Nov",
      label: "November",
    },
    {
      value: "Dec",
      label: "December",
    },
  ];

  // Get Data Form Course Details To Date Years Data
  const currentYearPlus10Years = moment().year() + 10;
  // Get Data Form Course Details From Date Years Data
  const currentYear = moment().year();
  const Last50Years = currentYearPlus10Years - 50;

  const userBirthDate = stdData?.personalInfo?.date_of_birth;
  const splitBirthDate = !_.isEmpty(userBirthDate)
    ? userBirthDate?.split("/")
    : [];

  const userBirthYear =
    !_.isEmpty(splitBirthDate) && _.isArray(splitBirthDate)
      ? splitBirthDate[splitBirthDate.length - 1]
      : "";

  const userBirthDatePlus15Years = !_.isEmpty(userBirthYear)
    ? moment(userBirthYear).year() + 15
    : Last50Years;

  const toDateData = [];
  console.log("toDateData", toDateData);
  for (let i = userBirthDatePlus15Years; i <= currentYearPlus10Years; i++) {
    toDateData.push({ value: i.toString(), label: i });
  }

  const fromDateData = [];
  console.log("fromDateData", fromDateData);
  for (let i = userBirthDatePlus15Years; i <= currentYear; i++) {
    fromDateData.push({ value: i.toString(), label: i });
  }

  // };

  const completionYears = [];
  for (let i = parseInt(startYear); i < parseInt(startYear) + 7; i++) {
    completionYears.push({
      value: i.toString(),
      label: i.toString(),
    });
  }

  console.log("completionYears", completionYears);

  const openModal = (type) => {
    if (type === "college-details") {
      setModalType(type);
      setModalTitle("College Details");
      setModalWidth(1000);
    }
  };

  const openModalCrop = (type) => {
    setModalTypeCrop(type);
    setModalOpen(true);
  };

  if (!_.isEmpty(imageDoc) && imageType === "stdId-front") {
    setFrontImage(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  if (!_.isEmpty(imageDoc) && imageType === "stdId-back") {
    setBackImage(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  const getPincodeValue = async (e, labelPincode) => {
    const data = { pincode: labelPincode };
    const endPoint = "steps/get-college-by-pincode?type=admin";
    getAPIData(endPoint, "post", data, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          form.setFieldsValue({
            state: response?.pincodeData?.state,
            district: response?.pincodeData?.district,
          });

          let dData = [];
          response?.CollegeData.map((college, index) =>
            dData.push({ label: college?.college_name, value: college?.id })
          );
          setCollegeList(dData);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });

    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("state", abcd?.state);
    form.setFieldValue("district", abcd?.district);
  };

  const getDegreeList = (value) => {
    const endPoint = `steps/degree-list/${value}?type=admin`;

    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          let dArr = [];
          response.map((degree, index) =>
            dArr.push({ label: degree?.course_name, value: degree?.id })
          );
          setDegreeList(dArr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getSpecializationList = (value) => {
    const endPoint = `steps/specialization-list`;

    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          let dArr = [];
          response.map((spec, index) =>
            dArr.push({ label: spec?.name, value: spec?.id })
          );
          setSpecializationList(dArr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleImageFront = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setStdIdFrontName(event.target.files[0]?.name);
    setImageType("stdId-front");
    openModalCrop("crop-image");
  };
  const handleImageBack = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setStdIdBackName(event.target.files[0]?.name);
    setImageType("stdId-back");
    openModalCrop("crop-image");
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
        initialValues={{
          college_id: stdInfo?.college_data?.college_id,
          pincode_id: stdInfo?.college_data?.pincode_id,
          type_of_degree: stdInfo?.course?.type_of_degree,
          degree_id: stdInfo?.course?.degree_id,
          spec_id: stdInfo?.course?.spec_id,
          atten_type: stdInfo?.course?.atten_type,
          start_from_month: stdInfo?.course?.start_from_month,
          start_from_year: stdInfo?.course?.start_from_year,
          end_from_month: stdInfo?.course?.end_from_month,
          end_from_year: stdInfo?.course?.end_from_year,
          student_strength_id: stdInfo?.college_data?.student_strength,
          mark_type: stdInfo?.course?.mark_type,
          per_score: stdInfo?.course?.per_score,
          current_cgpa: stdInfo?.course?.current_cgpa,
          id_number: stdInfo?.id_number,
          district: stdInfo?.pincode?.district,
          state: stdInfo?.pincode?.state,
          cgpa_course: stdInfo?.course?.cgpa_course,
        }}
      >
        <Row gutter={[16, 20]}>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 20]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Name Of College"
                  name="college_id"
                  size="large"
                  rules={[
                    { required: true, message: "Name of college is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    showSearch
                    className="br"
                    size="large"
                    options={collegeList}
                    onChange={(value, options) => {
                      getPincodeValue(value, options);
                      setCollegeId(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toString().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toString()
                        .localeCompare((optionB?.label ?? "").toString())
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item>
                  <CLoadingButton
                    size="large"
                    value="DETAILS"
                    className="poppins_medium umoney_btn"
                    block
                    onClick={() => openModal("college-details")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="District"
              name="district"
              size="large"
              rules={[{ required: true, message: "city is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                placeholder="Enter district name"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="State"
              name="state"
              size="large"
              rules={[{ required: true, message: "state is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                placeholder="Enter state name"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Pincode"
              name="pincode_id"
              size="large"
              rules={[{ required: true, message: "pincode is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                showSearch
                className="br"
                size="large"
                options={pincodeList}
                onChange={(value, option) => {
                  form.setFieldValue("college_id", "");
                  getPincodeValue(value, option?.label);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .localeCompare((optionB?.label ?? "").toString())
                }
                placeholder="Select pincode"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Type of Degree"
              name="type_of_degree"
              size="large"
              rules={[
                { required: true, message: "type of degree is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={degreeTypeList}
                size="large"
                type="text"
                onChange={(value) => {
                  getDegreeList(value);
                  form.setFieldValue("degree_id", "");
                }}
                placeholder="Select type of degree"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Name of Degree"
              name="degree_id"
              size="large"
              rules={[
                { required: true, message: "name of degree is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={degreeList}
                size="large"
                onChange={(value) => {
                  getSpecializationList(value);
                }}
                placeholder="Select degree"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Specialization"
              name="spec_id"
              size="large"
              rules={[
                { required: true, message: "specialization is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={specializationList}
                size="large"
                type="text"
                // onChange={(value) => {
                //   getDegreeList(value);
                // }}
                placeholder="Select specialization"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Type of attendance"
              name="atten_type"
              size="large"
              rules={[
                { required: true, message: "Type of attendance is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={attendanceData}
                size="large"
                type="text"
                placeholder="Select type of attendance"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Join Month"
                  name="start_from_month"
                  size="large"
                  rules={[
                    { required: true, message: "Join Month is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    size="large"
                    placement="bottomRight"
                    options={months}
                    placeholder="Select join month"
                  ></Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Join Year"
                  name="start_from_year"
                  size="large"
                  rules={[
                    { required: true, message: "Join Year is required!" },
                    {},
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    size="large"
                    placement="bottomRight"
                    options={fromDateData}
                    placeholder="Select join year"
                    onChange={(e) => {
                      setStartYear(e);
                    }}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Completion Month"
                  name="end_from_month"
                  size="large"
                  rules={[
                    {
                      required: true,
                      message: "Completion Month is required!",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    size="large"
                    placement="bottomRight"
                    options={months}
                    placeholder="Select completion month"
                  ></Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Completion Year"
                  name="end_from_year"
                  size="large"
                  rules={[
                    { required: true, message: "Completion Year is required!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue("start_from_year") < value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Completion Year should greater than join year"
                          )
                        );
                      },
                    }),
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    options={completionYears}
                    size="large"
                    placement="bottomRight"
                    onChange={(e) => setStartYear(e)}
                    placeholder="Select completion year"
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="No. of students"
              name="student_strength_id"
              size="large"
              rules={[
                { required: true, message: "No. of students is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={studentData}
                size="large"
                type="text"
                placeholder="Select no. of student"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Marks"
                  name="mark_type"
                  size="large"
                  rules={[{ required: true, message: "Marks is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    label="Marks"
                    options={markTypeData}
                    size="large"
                    onChange={(val) => {
                      setMarkType(val);
                    }}
                    placeholder="Select mark type"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                {markType === "percentage" ? (
                  <Form.Item
                    className="poppins_medium mb-35"
                    label="Percentage"
                    name="per_score"
                    rules={[
                      {
                        pattern: /^[0-9]\d*(\.\d+)?$/g,
                        message: "Percentage contains only number.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter percentage"
                      size="large"
                      type="text"
                    />
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item
                      className="poppins_medium mb-35"
                      label="Graded CGPA"
                      name="cgpa_course"
                      rules={[
                        {
                          pattern: /^[0-9]\d*(\.\d+)?$/g,
                          message: "Graded CGPA contains only number.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter graded CGPA"
                        size="large"
                        type="text"
                        defaultValue={stdInfo?.course?.cgpa_course}
                        style={{ marginBottom: 20 }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="poppins_medium mb-35"
                      label="Current CGPA"
                      name="current_cgpa"
                      rules={[
                        {
                          pattern: /^[0-9]\d*(\.\d+)?$/g,
                          message: "Current CGPA contains only number.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter current CGPA"
                        size="large"
                        type="text"
                      />
                    </Form.Item>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Student ID Number"
              name="id_number"
              size="large"
              rules={[
                {
                  required: true,
                  message: "Student ID number is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                type="text"
                placeholder="Enter student id number"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={16}></Col>
          <Col className="gutter-row" span={4}>
            <Form.Item name="front_img">
              {_.isEmpty(frontImage) ? (
                <div
                  style={{
                    width: "100%",
                    height: "150px",
                    backgroundColor: "#dffefc",
                    border: "1px dashed #009d93",
                    borderRadius: "2px",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    className="file-choose"
                    style={{
                      width: "100%",
                      height: "100%",
                      color: "transparent",
                      opacity: 0,
                      position: "relative",
                      zIndex: "200",
                    }}
                    onChange={handleImageFront}
                  />
                  <div
                    style={{
                      position: "relative",
                      bottom: "55%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <Text>
                        {" "}
                        <PlusOutlined /> Upload student ID Front
                      </Text>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "150px",
                    backgroundColor: "#dffefc",
                    border: "1px dashed #009d93",
                    borderRadius: "2px",
                  }}
                >
                  <img
                    src={frontImage}
                    style={{ height: "100%", width: "100%" }}
                  ></img>
                  <CCloseIcon
                    onClick={() => setFrontImage("")}
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "0px",
                    }}
                  />
                </div>
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item name="back_img">
              {_.isEmpty(backImage) ? (
                <div
                  style={{
                    width: "100%",
                    height: "150px",
                    backgroundColor: "#dffefc",
                    border: "1px dashed #009d93",
                    borderRadius: "2px",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    className="file-choose"
                    style={{
                      width: "100%",
                      height: "100%",
                      color: "transparent",
                      opacity: 0,
                      position: "relative",
                      zIndex: "200",
                    }}
                    onChange={handleImageBack}
                  />
                  <div
                    style={{
                      position: "relative",
                      bottom: "55%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <Text>
                        {" "}
                        <PlusOutlined /> Upload Student ID Back
                      </Text>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "150px",
                    backgroundColor: "#dffefc",
                    border: "1px dashed #009d93",
                    borderRadius: "2px",
                  }}
                >
                  <img
                    src={backImage}
                    style={{ height: "100%", width: "100%" }}
                  ></img>
                  <CCloseIcon
                    onClick={() => setBackImage("")}
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "0px",
                    }}
                  />
                </div>
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={16}></Col>
          <Col className="gutter-row" span={8}>
            <Form.Item>
              <CLoadingButton
                size="large"
                htmlType="submit"
                value="SAVE"
                loading={saveBtnLoading}
                onClick={() => setIdApprover(false)}
                className="poppins_medium umoney_btn"
                block
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item>
              <CLoadingButton
                size="large"
                htmlType="submit"
                value="SAVE & APPROVE"
                loading={approveBtnLoading}
                onClick={() => setIdApprover(true)}
                className="poppins_medium umoney_btn"
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={modalType}
        title={modalTitle}
        footer={false}
        onCancel={handleClose}
        centered={true}
        width={modalWidth}
      >
        {modalType === "college-details" ? (
          <CollegeDetailsModal collegeId={collegeId} />
        ) : (
          ""
        )}
      </Modal>
      {modalTypeCrop === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalTypeCrop}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
    </div>
  );
};
async function findArrayElementByTitle(array, value) {
  return array.find((element) => {
    return element.value === value;
  });
}

export default StudentInformationDetails;
