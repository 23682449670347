import React from "react";
import { CloseOutlined } from "@ant-design/icons";

const CCloseIcon = (props) => {
  const { onClick } = props;
  return (
    <div className="close-icon d-flex">
      <CloseOutlined
        style={{ fontSize: "15px", color: "#fff", padding: 7 }}
        onClick={onClick}
      />
    </div>
  );
};

export default CCloseIcon;
