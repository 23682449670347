import { useState, useEffect } from "react";
import { Col, Form, Input, message, Modal, Row } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";
const CollectionModal = (props) => {
  const {
    title,
    open,
    setOpen,
    onFinishApi = () => {},
    dpdData,
    modalType,
  } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    // form.resetFields()
  };

  //onFinish
  const onFinish = (data) => {
    setLoading(true);
    const endPoint =
      modalType !== "edit-dpd"
        ? "system-config/dpd/create"
        : "system-config/dpd/update/" + dpdData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((res) => {
        if (res?.status === true) {
          setOpen(false);
          message.success(res?.message);
          onFinishApi(res?.status);
          form.resetFields();
        } else {
          message.warning(res?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(dpdData) && modalType === "edit-dpd") {
      //   form.setFieldsValue({"from": dpdData?.from, "to": dpdData?.to})
      form.setFieldsValue(dpdData);
    }
  }, [form, dpdData, modalType]);

  return (
    <Modal
      title={title}
      centered
      open={open}
      mask={true}
      footer={null}
      closable={true}
      className="poppins_regular"
      onCancel={handleClose}
      width={500}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} style={{ justifyContent: "center" }}>
          <Col className="gutter-row" span={15}>
            <Form.Item
              label="From"
              name="from"
              rules={[
                { required: true, message: "From is required!" },
                {
                  message: "This field can not contain whitespaces.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="From" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={15}>
            <Form.Item
              label="To"
              name="to"
              rules={[
                { required: true, message: "To is required!" },
                {
                  whitespace: true,
                  message: "This field can not contain whitespaces.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="To" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={15}>
            <Form.Item>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="Save"
                className="poppins_medium umoney_btn"
                loading={loading}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CollectionModal;
