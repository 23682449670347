import { Checkbox, Col, message, Row, Table } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getAPIData } from "../../../apiHelper";
import CLoadingButton from "../../InputFields/CLoadingButton";
import _ from "lodash";
import {
  CheckSquareOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined,
  BorderOutlined,
} from "@ant-design/icons";
import moment from "moment";

const AlgoLoanFilterModal = (props) => {
  const { user_id, device_id } = props;

  console.log("userId=======>", user_id);
  console.log("deviceId=======>", device_id);

  const [rerunBtnLoading, setRerunBtnloading] = useState(false);

  const [parameterData, setParameterData] = useState();

  const [permissionData, setPermissionData] = useState({});

  const [reRunTableLoading, setReRunTableLoading] = useState(false);

  const [failedpara, setFailedPara] = useState({});

  const loadFailedParaData = async () => {
    const endPoint = "loan/loan-filter-algo";
    setRerunBtnloading(true);
    setReRunTableLoading(true);
    const data = { loan_user_id: user_id, device_id: device_id, type: "admin" };

    getAPIData(endPoint, "post", data, false, "loan")
      .then((result) => {
        if (result?.status) {
          if (!_.isEmpty(result?.data?.algo_result)) {
            setParameterData([result?.data?.algo_result]);
            setFailedPara(result?.data?.algo_result);
          }
          setRerunBtnloading(false);
          setReRunTableLoading(false);
        } else {
          message.warning(result?.message);
          setRerunBtnloading(false);
          setReRunTableLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setRerunBtnloading(false);
        setReRunTableLoading(false);
      });
  };

  useEffect(() => {
    const loadPermissionData = async () => {
      setPermissionData("");
      setParameterData("");
      algoFail = [];
      const endPoint = "loan/get-algo-details";
      const data = { loan_user_id: user_id, device_id: device_id };
      getAPIData(endPoint, "post", data, false, "loan")
        .then((result) => {
          if (result?.status) {
            setPermissionData(result?.data?.phone_permission);
            console.log("result?.data?", result?.data);
            setParameterData(result?.data?.Algo);
            const para = result?.data?.Algo[0];
            console.log("para", para);
            setFailedPara(para);
          } else {
            setPermissionData({});
            message.warning(result?.message);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    };
    loadPermissionData();
  }, [device_id, props, user_id]);

  console.log("data======>", parameterData);

  const failedParaColumns = [
    {
      title: "FAILED PARAMETERS",
      dataIndex: "failed",
      key: "failed",
    },
  ];

  const moduleColumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "PERMISSION",
      dataIndex: "permission",
      key: "permission",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
    },
  ];

  const moduleTable = [
    {
      key: 1,
      module: "Location",
      permission: (
        <div>
          <span>
            {permissionData?.location === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 2,
      module: "Contacts",
      permission: (
        <div>
          <span>
            {permissionData?.contacts === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "Extract",
    },
    {
      key: 3,
      module: "SMS",
      permission: (
        <div>
          <span>
            {permissionData?.sms === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 4,
      module: "Phone",
      permission: (
        <div>
          <span>
            {permissionData?.phone === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 5,
      module: "Call Logs",
      permission: (
        <div>
          <span>
            {permissionData?.call_logs === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 6,
      module: "Camera",
      permission: (
        <div>
          <span>
            {permissionData?.camera === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 7,
      module: "Storage",
      permission: (
        <div>
          <span>
            {permissionData?.storage === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 8,
      module: "Installed Applications",
      permission: (
        <div>
          <span>
            {permissionData?.install_apps === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.install_apps === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.install_apps === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.install_apps === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "Extract",
    },
    {
      key: "9",
      module: "Audio",
      permission: (
        <div>
          <span>
            {permissionData?.audio === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.audio === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.audio === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.audio === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
  ];

  const table3Columns = [
    {
      title: "TEST RUN TIMESTAMP",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) =>
        moment(record?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "FAMILY COUNT",
      dataIndex: "family_count",
      key: "family_count",
      render: (text, record) => (
        <div>
          <span>
            {record?.family_count === null
              ? "NA"
              : record?.family_count === true
              ? "Y"
              : record?.family_count === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "LATEST MEDIA",
      dataIndex: "latest_media",
      key: "latest_media",
      render: (text, record) => (
        <div>
          <span>
            {record?.latest_media === null
              ? "NA"
              : record?.latest_media === true
              ? "Y"
              : record?.latest_media === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "BANKING SMS COUNT",
      dataIndex: "banking_sms_count",
      key: "banking_sms_count",
      render: (text, record) => (
        <div>
          <span>
            {record?.banking_sms_count === null
              ? "NA"
              : record?.banking_sms_count === true
              ? "Y"
              : record?.banking_sms_count === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "DATA USAGE",
      dataIndex: "data_usage",
      key: "data_usage",
      render: (text, record) => (
        <div>
          <span>
            {record?.data_usage === null
              ? "NA"
              : record?.data_usage === true
              ? "Y"
              : record?.data_usage === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "INSTALL APPS LIST",
      dataIndex: "install_apps_list",
      key: "install_apps_list",
      render: (text, record) => (
        <div>
          <span>
            {record?.install_apps_list === null
              ? "NA"
              : record?.install_apps_list === true
              ? "Y"
              : record?.install_apps_list === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "PHONE PERMISSION",
      dataIndex: "phone_permission",
      key: "phone_permission",
      render: (text, record) => (
        <div>
          <span>
            {record?.phone_permission === null
              ? "NA"
              : record?.phone_permission === true
              ? "Y"
              : record?.phone_permission === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "LOAN OVERDUE",
      dataIndex: "loan_overdue",
      key: "loan_overdue",
      render: (text, record) => (
        <div>
          <span>
            {record?.loan_overdue === null
              ? "NA"
              : record?.loan_overdue === true
              ? "Y"
              : record?.loan_overdue === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "SMS COUNT",
      dataIndex: "sms_count",
      key: "sms_count",
      render: (text, record) => (
        <div>
          <span>
            {record?.sms_count === null
              ? "NA"
              : record?.sms_count === true
              ? "Y"
              : record?.sms_count === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "CONTACT COUNT",
      dataIndex: "contact_counts",
      key: "contact_counts",
      render: (text, record) => (
        <div>
          <span>
            {record?.contact_counts === null
              ? "NA"
              : record?.contact_counts === true
              ? "Y"
              : record?.contact_counts === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "MEDIA COUNT",
      dataIndex: "media_count",
      key: "media_count",
      render: (text, record) => (
        <div>
          <span>
            {record?.media_count === null
              ? "NA"
              : record?.media_count === true
              ? "Y"
              : record?.media_count === false
              ? "X"
              : "-"}
          </span>
        </div>
      ),
    },
  ];

  const table3Data = [];
  for (let i = 1; i <= 4; i++) {
    table3Data.push({
      key: i,
      testruntimestamp: "09-08-21 | 10:11am",
      parameter1: "Location",
      parameter2: "Contacts",
      parameter3: "SMS",
      parameter4: "Phone",
      parameter5: "NA",
    });
  }

  let algoFail = [];

  for (const key in failedpara) {
    if (failedpara[key] === false && key != "algo_pass") {
      if (key === "family_count") {
        algoFail.push({
          failed: "Family count",
        });
      }
      if (key === "latest_media") {
        algoFail.push({
          failed: "Latest media",
        });
      }
      if (key === "banking_sms_count") {
        algoFail.push({
          failed: "Banking SMS count",
        });
      }
      if (key === "data_usage") {
        algoFail.push({
          failed: "Data usage",
        });
      }
      if (key === "install_apps_list") {
        algoFail.push({
          failed: "Install apps list",
        });
      }
      if (key === "phone_permission") {
        algoFail.push({
          failed: "Phone permission",
        });
      }
      if (key === "loan_overdue") {
        algoFail.push({
          failed: "Loan overdue",
        });
      }
      if (key === "sms_count") {
        algoFail.push({
          failed: "SMS count",
        });
      }
      if (key === "contact_counts") {
        algoFail.push({
          failed: "Contact counts",
        });
      }
      if (key === "media_count") {
        algoFail.push({
          failed: "Media count",
        });
      }
    }
  }
  console.log("algoFail", algoFail);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div
            style={{
              border: "1px solid #009D93",
              borderRadius: "5px",
              overflowY: "scroll",
              height: "510px",
            }}
            className="side_bar"
          >
            <Table
              dataSource={algoFail}
              columns={failedParaColumns}
              pagination={false}
              style={{
                marginTop: "15px",
              }}
            />
          </div>
          <CLoadingButton
            size="large"
            value="RE-RUN"
            className="poppins_medium urban_money_btn"
            style={{ marginTop: "27px", borderRadius: "5px" }}
            onClick={() => loadFailedParaData()}
            loading={rerunBtnLoading}
            block
          />
        </Col>
        <Col span={16}>
          <div
            style={{
              border: "1px solid #009D93",
              borderRadius: "5px",
              paddingBottom: "2px",
            }}
          >
            <Table
              dataSource={moduleTable}
              columns={moduleColumns}
              pagination={false}
              style={{
                marginTop: "10px",
                whiteSpace: "pre",
                //whiteSpace:"pre-wrap",
                textOverflow: "ellipsis",
              }}
            />
          </div>
        </Col>
        <Col span={24}>
          <div
            style={{
              border: "1px solid #009D93",
              borderRadius: "5px",
              paddingBottom: "2px",
            }}
          >
            <Table
              dataSource={parameterData}
              columns={table3Columns}
              pagination={false}
              style={{
                marginTop: "10px",
              }}
              loading={reRunTableLoading}
              scroll={{ y: "200px" }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AlgoLoanFilterModal;
