import React, { useState, useEffect } from "react";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { Content } from "antd/lib/layout/layout";
import {
  Typography,
  Table,
  Col,
  Row,
  Input,
  Form,
  message,
  Select,
} from "antd";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CommonModals from "../../../components/Modals/CommonModals";
import CreditCheckSubmissionModals from "../../../components/Modals/CreditCheckSubmissionModals";

const CreditCheckSubmission = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [familtyIncomeData, setFamilyIncomeData] = useState([]);
  const [relativeData, setRelativeData] = useState([]);

  const [editCancleIcon, setEditCancleIcon] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(Number);
  const [deleteId, setDeleteId] = useState(Number);

  // Modal States
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [tableType, setTableType] = useState("");

  const [modalTitle, setModalTitle] = useState("");
  const [editData, setEditData] = useState("");
  const [relativeEditData, setRelativeEditData] = useState();

  //Save btn
  const [saveNumberLoading, setSaveNumberLoading] = useState(false);

  const { Title } = Typography;

  const openModal = (type, id, record) => {
    if (type === "add-income") {
      setModalType(type);
      setModalTitle("Add Home Income");
    } else if (type === "edit-income") {
      setEditData(record);
      setModalType(type);
      setModalTitle("Edit Home Income");
    } else if (type === "add-relative-income") {
      setModalType(type);
      setModalTitle("Add Relative Income");
    } else if (type === "edit-relative-income") {
      setRelativeEditData(record);
      setModalType(type);
      setModalTitle("Edit Relative Income");
    }
  };

  // const incomeEdit = (id, name) => {
  //   Incomeform.setFieldValue("title", name);
  //   setEditCancleIcon(true);
  //   setIsEdit(true);
  //   setEditId(id);
  // };

  // const relationEdit = (id, name) => {
  //   Relationform.setFieldValue("relation", name);
  //   setEditCancleIcon(true);
  //   setIsEdit(true);
  //   setEditId(id);
  // };

  // const closeEdit = () => {
  //   setEditCancleIcon(false);
  //   setIsEdit(false);
  //   setEditId(Number);
  //   Incomeform.resetFields();
  // };

  const loadData = () => {
    const endPoint = "system-config/family/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setFamilyIncomeData(response?.income_list);
          setRelativeData(response?.relative_list);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const onFinishReq = (values) => {
    setSaveNumberLoading(true);
    let data;
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "credit_check_submission",
        key: key,
        value: value,
      })
    );
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSaveNumberLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedReq = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "credit_check_submission" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              form.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
  }, [form]);
  //income
  const incomeColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // incomeEdit(record?.id, record?.title);
                openModal("edit-income", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteType("family");
                setDeleteId(record?.id);
                setModalOpen(true);
                setTableType("income");
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  const relativeColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "RELATION",
      dataIndex: "relation",
      key: "relation",
    },
    {
      title: "Allowed In CC",
      dataIndex: "allowed_in_cc",
      key: "allowed_in_cc",
      render: (text, record) => (
        <div>
          <span>{record?.allowed_in_cc === "0" ? "No" : "Yes"}</span>
        </div>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // relationEdit(record?.id, record?.relation);
                openModal("edit-relative-income", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteType("family");
                setDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  Credit Check Number
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishReq}
                  onFinishFailed={onFailedReq}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={form}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Credit Check Number Input (pending)"
                        name="cc_number_input"
                        rules={[
                          {
                            required: true,
                            message: "Credit Check Number Input is required!",
                          },
                          {
                            message: "Please Enter characters",
                            pattern: new RegExp(/[a-zA-Z]/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Select
                          size="large"
                          options={[
                            {
                              value: "true",
                              label: "True",
                            },
                            {
                              value: "false",
                              label: "False",
                            },
                          ]}
                          placeholder="Enter credit check number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveNumberLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  MONTHLY FAMILY INCOME
                </Title>
              </Col>
              <Col className="gutter-row" span={6}>
                <CLoadingButton
                  size="large"
                  value="ADD"
                  icon={<PlusOutlined />}
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-income")}
                />
              </Col>
              <Col span={12}></Col>
            </Row>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={incomeColumns}
                dataSource={familtyIncomeData}
              />
            </div>

            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  OTHER RELATIVE OPTION
                </Title>
              </Col>
              <Col className="gutter-row" span={6}>
                <CLoadingButton
                  size="large"
                  icon={<PlusOutlined />}
                  value="ADD"
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-relative-income")}
                />
              </Col>
              <Col span={12}></Col>
            </Row>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={relativeColumns}
                dataSource={relativeData}
              />
            </div>
          </div>
        </Content>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            setOpen={setModalOpen}
            tableType={tableType}
            title="Delete Confirmation"
            type={deleteType}
            modalType={modalType}
            setModalType={setModalType}
            id={deleteId}
            onDelete={(val) => {
              loadData();
            }}
          />
        ) : modalType === "add-income" ? (
          <CreditCheckSubmissionModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            isEdit={isEdit}
            onSave={() => {
              setModalType(false);
            }}
            loadData={loadData}
          />
        ) : modalType === "edit-income" ? (
          <CreditCheckSubmissionModals
            editData={editData}
            modalTitle={modalTitle}
            modalType={modalType}
            isEdit={isEdit}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
            }}
            setEditCancleIcon={setEditCancleIcon}
            setIsEdit={setIsEdit}
            editCancleIcon={editCancleIcon}
            loadData={loadData}
          />
        ) : modalType === "add-relative-income" ? (
          <CreditCheckSubmissionModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            isEdit={isEdit}
            onSave={() => {
              setModalType(false);
            }}
            loadData={loadData}
          />
        ) : modalType === "edit-relative-income" ? (
          <CreditCheckSubmissionModals
            relativeEditData={relativeEditData}
            modalTitle={modalTitle}
            modalType={modalType}
            isEdit={isEdit}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
            }}
            setEditCancleIcon={setEditCancleIcon}
            setIsEdit={setIsEdit}
            editCancleIcon={editCancleIcon}
            loadData={loadData}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default CreditCheckSubmission;
