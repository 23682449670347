import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Switch,
  Form,
  Input,
  Space,
  Button,
  Upload,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  MergeCellsOutlined,
  SearchOutlined,
  CloudUploadOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import MergeModals from "../../components/Modals/MergeModals";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { api_url, setHeaderForFile } from "../../api";
import axios from "axios";
import DepartMentModals from "../../components/Modals/DepartMentModals";

const { Title } = Typography;
const { Header, Content } = Layout;

const Specialization = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [deleteId, setDeleteId] = useState("");
  const [mergeId, setMergeId] = useState([]);
  const [mergeData, setMergeData] = useState([]);

  //edit specialization
  const [editId, setEditId] = useState(Number);
  const [isEdit, setIsEdit] = useState(false);
  const [editCancleIcon, setEditCancleIcon] = useState(false);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //Search Table
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [editData, setEditData] = useState();

  const [filteredInfo, setFilteredInfo] = useState({});

  const openModal = (type, id, record) => {
    if (type === "add-department") {
      setModalType(type);
      setModalTitle("Add Department");
    } else if (type === "edit-department") {
      setEditData(record);
      setModalType(type);
      setModalTitle("Edit Department");
      setEditCancleIcon(true);
    }
  };

  const loadData = (loading = true) => {
    if (loading) {
      setLoading(true);
    }
    const endPoint = "master/department-list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const departmentEdit = (id, name) => {
    form.setFieldValue("name", name);
    setEditCancleIcon(true);
    setIsEdit(true);
    setEditId(id);
  };

  const changeStatus = (e, id) => {
    let endPoint = `master/department/change-status/` + id;
    const data = { status: e === true ? "1" : "0" };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadData(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // Search Functionality START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const csvUpload = async (data) => {
    try {
      const endPoint = api_url + "master/upload-department";
      const header = setHeaderForFile();

      const apiData = { uploadcsv: data?.file?.originFileObj };
      const uploadData = await axios.post(endPoint, apiData, header);
      if (uploadData?.data?.status) {
        message.success(uploadData?.data?.message);
        loadData();
      } else {
        message.warning(uploadData?.data?.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  // Search Functionality START
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // Search Functionality END

  useEffect(() => {
    if (_.isEmpty(tableData)) {
      loadData();
    }
  }, [tableData]);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "DEPARTMENTS",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      ...getColumnSearchProps("name"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => (
        <div>
          <span>
            {record.status !== "2" ? (
              <Switch
                checked={record.status === "1" ? true : false}
                onChange={(e) => changeStatus(e, record?.id)}
              />
            ) : (
              <MergeCellsOutlined
                style={{
                  color: "#009D93",
                  height: "20px",
                  width: "20px",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setModalType("merge");
                  setMergeId(record?.id);
                  setModalOpen(true);
                  setMergeData(record);
                }}
              />
            )}
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      filteredValue: filteredInfo.actions || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{ color: "#009D93", marginLeft: "20px", fontSize: "25px" }}
              onClick={() => {
                // departmentEdit(record?.id, record?.name);
                openModal("edit-department", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                height: "20px",
                width: "20px",
                marginLeft: "20px",
                fontSize: "25px",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              backgroundColor: "#009D93",
              borderRadius: "15px 0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Department
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 15,
              backgroundColor: "#DFFEFC",
            }}
          >
            <div className="white_div">
              <Col className="gutter-row" span={24}>
                <Row
                  gutter={[16, 16]}
                  xl={2}
                  lg={4}
                  md={6}
                  sm={8}
                  style={{ paddingBottom: "20px" }}
                >
                  <Col className="gutter-row" span={3}>
                    <Upload onChange={csvUpload}>
                      <CLoadingButton
                        size="large"
                        value="Upload CSV"
                        className="poppins_medium umoney_modal_filled_btn"
                        icon={<CloudUploadOutlined />}
                        block
                      />
                    </Upload>
                  </Col>
                  <Col className="gutter-row" span={3}>
                    {/* <Link to="/sample_csv/Department.csv" download target="_self"> */}
                    <a href="/sample_csv/Department.csv" download>
                      <DownloadOutlined /> Click to download
                    </a>
                  </Col>
                  <Col className="gutter-row" span={18}></Col>
                  <Col span={8}></Col>
                  <Col span={8}>
                    <CLoadingButton
                      size="large"
                      value="CLEAR FILTERS"
                      className="poppins_medium umoney_btn"
                      onClick={() => clearFilters()}
                      block
                    />
                  </Col>
                  <Col span={8}>
                    <CLoadingButton
                      size="large"
                      value="ADD DEPARTMENT"
                      icon={<PlusOutlined />}
                      className="poppins_medium umoney_btn"
                      onClick={() => openModal("add-department")}
                      block
                    />
                  </Col>
                </Row>
              </Col>
              <div className="umoney_table">
                <Table
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                  columns={tableColumns}
                  dataSource={tableData}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Content>
        </Layout>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            setOpen={setModalOpen}
            title="Delete Department"
            type="department"
            modalType={modalType}
            setModalType={setModalType}
            id={deleteId}
            onDelete={(val) => {
              loadData();
            }}
          />
        ) : modalType === "merge" ? (
          <MergeModals
            open={modalOpen}
            setOpen={setModalOpen}
            title="Merge Department"
            type="merge"
            modalType={modalType}
            editData={modalType === "merge" ? mergeData : []}
            setModalType={setModalType}
            id={mergeId}
            onSave={(val) => {
              loadData();
            }}
          />
        ) : modalType === "add-department" ? (
          <DepartMentModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            isEdit={isEdit}
            onSave={() => {
              setModalType(false);
            }}
            loadData={loadData}
          />
        ) : modalType === "edit-department" ? (
          <DepartMentModals
            editData={editData}
            modalTitle={modalTitle}
            modalType={modalType}
            isEdit={isEdit}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
            }}
            setEditCancleIcon={setEditCancleIcon}
            setIsEdit={setIsEdit}
            editCancleIcon={editCancleIcon}
            loadData={loadData}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default Specialization;
