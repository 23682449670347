import React, { useRef, useState } from "react";
import { Modal, Typography } from "antd";
import UploadImage from "../Modals/modal/CommonModal/UploadImage";
import RemoveImage from "../Modals/modal/RemoveImage";
import PreviewModal from "../Modals/modal/CommonModal/PreviewModal";
import CImageCard from "./KYC/CImageCard";

const { Title } = Typography;

const EmploymentDocument = ({ user_id, empImage, pageType, isSuccess }) => {
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const previewModalRef = useRef(null);

  const openModal = (type) => {
    if (type === "upload-image") {
      setModalType(type);
      setModalTitle("upload-image [KYC Front]");
      setModalWidth(500);
    } else if (type === "remove-image") {
      setModalType(type);
      setModalTitle("Remove Image");
      setModalWidth(500);
    }
  };

  const handleClose = () => {
    setModalType("");
  };

  return (
    <div className="border-class m-10">
      <div
        className="p-10 d-flex justify-between"
        style={{
          borderBottom: "1px solid #009D93",
        }}
      >
        <Title level={5} className="poppins_medium color_title">
          Employment Document
        </Title>
      </div>
      <CImageCard
        pdf
        image={empImage?.pdf_doc}
        type={"Employment Document"}
        onRemove={() => {
          openModal("remove-image");
        }}
        onPreview={() => {
          previewModalRef?.current?.open(empImage?.pdf_doc);
        }}
      />
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={handleClose}
        centered
        width={modalWidth}
        footer={false}
      >
        {modalType === "upload-image" ? (
          <UploadImage />
        ) : modalType === "remove-image" ? (
          <RemoveImage
            user_id={user_id}
            type={"emp-doc"}
            handleClose={handleClose}
            pageType={pageType}
            isSuccess={isSuccess}
          />
        ) : (
          ""
        )}
      </Modal>
      <PreviewModal ref={previewModalRef} />
    </div>
  );
};

export default EmploymentDocument;
