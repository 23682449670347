import { Col, Form, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";

const RemarkForm = ({
  device_id,
  followup_ts,
  user_id,
  action_button,
  close,
  loadData,
  closeRemark,
  loadLoanData,
  pageType,
}) => {
  const [loading, setLoading] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const onFinish = (data) => {
    setSubmitBtnLoading(true);
    data.followup_ts = moment(data?.follow_up?._d).format("YYYY-MM-DD");
    const dataRemark = { ...data, user_id, device_id, action_button };
    const endPoint = "loan/take-action";
    getAPIData(endPoint, "post", dataRemark, false, "loan")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSubmitBtnLoading(false);
          form.resetFields();
          if (pageType === "loan") {
            loadLoanData();
          } else {
            loadData();
          }
          closeRemark();
          close();
        } else {
          message.warning(result?.message);
          setSubmitBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSubmitBtnLoading(false);
      });
  };

  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Remarks"
              name="comment"
              rules={[{ required: true, message: "Remarks is required!" }]}
              className="poppins_medium"
            >
              <TextArea size="large" rows={4} placeholder="Enter remarks" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <CLoadingButton
              size="large"
              value="SUBMIT"
              htmlType="submit"
              className="umoney_btn poppins_medium"
              style={{ width: "100%" }}
              loading={submitBtnLoading}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RemarkForm;
