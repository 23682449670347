import { message, Table } from "antd";
import React, { useState } from "react";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import { useEffect } from "react";

const ActionHistoryLoanFilter = (props) => {
  const [tableData, setTableData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  const [pageSize, setPageSize] = useState(5);
  const [totalPage, setTotalPage] = useState();
  const [tablePagination, setTablePagination] = useState();

  const { user_id, device_id, current, setCurrent, page, setPage } = props;

  //1=Approve, -1=Reject, 2=Follow up, 3=Pending
  useEffect(() => {
    const loadActionHistoryData = () => {
      setTableLoading(true);
      setTableData("");
      const endPoint = `loan/loan-action-log`;
      const data = {
        user_id: user_id,
        device_id: device_id,
        limit: pageSize,
        page: page,
      };
      getAPIData(endPoint, "post", data, false, "loan")
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data?.result;
            var pagination = result?.data?.pagination;
            if (!_.isEmpty(response) && _.isArray(response)) {
              setTableData(response);
              setTablePagination(pagination);
              setTotalPage(result?.data?.pagination?.totalCount);
              setTableLoading(false);
            }
          } else {
            message.warning(result?.message);
            setTableLoading(false);
          }
        })
        .catch((err) => {
          message.error(err);
          setTableLoading(false);
        });
    };
    loadActionHistoryData();
  }, [device_id, props, user_id, page, pageSize, current]);

  // if(modal===true){
  //   loadActionHistoryData();
  //   setModal(false);
  // }

  const tableColumns = [
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "ACTION BY",
      dataIndex: "agentName",
      key: "agentName",
    },
    {
      title: "ACTION",
      dataIndex: "action_button",
      key: "action_button",
      render: (_noUse, data) => (
        <span>
          {data.action_button === "1"
            ? "Approve"
            : data.action_button === "-1"
            ? "Reject"
            : data.action_button === "2"
            ? "Follow Up"
            : ""}
        </span>
      ),
    },
    {
      title: "COMMENT",
      dataIndex: "comment",
      key: "comment",
      render: (text, record) => (
        <span>
          {record?.action_button === "2"
            ? record?.follow_up_comment
            : record?.comment}
        </span>
      ),
    },
    {
      title: "FOLLOW-UP TIMESTAMP",
      dataIndex: "followup_ts",
      key: "followup_ts",
    },
  ];

  return (
    <div className="umoney_table">
      <Table
        dataSource={tableData}
        pagination={
          tableData === ""
            ? false
            : {
                position: ["none", "bottomLeft"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                  setCurrent(page);
                },
                current: page,
                total: totalPage,
              }
        }
        columns={tableColumns}
        loading={tableLoading}
      />
    </div>
  );
};

export default ActionHistoryLoanFilter;
