import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const AddUPI = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  //Save btn
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const onFinishBankAccConfig = (values) => {
    setSaveBtnLoading(true);
    let data;
    let otp_config = [];

    Object.entries(values).map(([key, value]) =>
      otp_config.push({
        category: "addind_an_upi_id",
        key: key,
        value: value,
      })
    );
    data = { system_data: otp_config };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSaveBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedBankAccConfig = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "addind_an_upi_id" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              form.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
  }, [form]);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />

        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  CHARACTER MATCH PERCENTAGE
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishBankAccConfig}
                  onFinishFailed={onFailedBankAccConfig}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={form}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Character Match Percentage (pending)"
                        name="character_match_per"
                        rules={[
                          {
                            required: true,
                            message: "Character Match Percentage is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter character match percentage"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default AddUPI;
