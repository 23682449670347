import { React, useRef, useState, useEffect } from "react";
import { Input, Spin, Typography, Upload, Modal, message } from "antd";
import { Col, Row } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { Checkbox, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import UploadBankDocument from "../../Modals/BankAccount/UploadBankDocument";
import { getAPIData } from "../../../apiHelper";
import CImageCard from "../../Accordion/KYC/CImageCard";
import PreviewModal from "./CommonModal/PreviewModal";

const { Title } = Typography;

const DocumentModal = ({
  bank_id,
  user_id,
  ifscCode,
  accountNumber,
  onClose,
  bankAgentList,
}) => {
  const [savebtnLoading, setsavebtnLoading] = useState(false);

  const previewModalRef = useRef(null);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [form] = Form.useForm();

  //get bank document
  const [bankDocument, setBankDocument] = useState();

  const [checkedAcc, setCheckedACC] = useState(false);
  const [checkedIfsc, setCheckedIfsc] = useState(false);
  const [accCheck, setAccCheck] = useState(false);
  const [IfscCode, setIfscCode] = useState(false);

  const [spinLoading, setSpinLoading] = useState(false);

  const loadBankDocument = () => {
    setSpinLoading(true);
    form.resetFields();
    setBankDocument("");
    const endPoint = "bank-agent/get-documents/" + bank_id;
    console.log("endPoint", endPoint);
    getAPIData(endPoint, "get", {}, false, "bankAcc")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setBankDocument(response);
          setSpinLoading(false);
          // message.success(result?.message);
          setCheckedACC(false);
          setCheckedIfsc(false);
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
        }
        setSpinLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSpinLoading(false);
      });
  };

  // if (modal === "open") {
  //   loadBankDocument();
  //   setModal("close");
  // } else {
  //   form.resetFields();
  // }

  useEffect(() => {
    loadBankDocument();
  }, [bank_id, onClose]);

  const onFinish = (data) => {
    setsavebtnLoading(true);
    const bankData = {
      ...data,
      user_id,
      //  admins_user
      bank_id,
    };
    const endPoint = "bank-agent/update-user-bank";
    getAPIData(endPoint, "post", bankData, false, "bankAcc")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setsavebtnLoading(false);
          onClose();
          bankAgentList();
          form.resetFields();
          setAccCheck(false);
          setIfscCode(false);
        } else {
          message.warning(result?.message);
          setsavebtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setsavebtnLoading(false);
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const handleClose = () => {
    setModalType("");
  };

  console.log("User", ifscCode, accountNumber);

  const openModal = (type) => {
    setModalType(type);
    if (type === "upload-image") {
      setModalTitle("Upload image");
    }
  };

  const onChangeAcc = (e) => {
    setCheckedACC(e.target.checked);
    setAccCheck(!accCheck);
  };

  const onChangeIfsc = (e) => {
    setCheckedIfsc(e.target.checked);
    setIfscCode(!IfscCode);
  };

  return (
    <Spin spinning={spinLoading} size="medium">
      <div>
        <Upload
          // action={"http://localhost:3000/"}
          multiple
          listType="picture"
          accept=".png,.jpg,.jpeg,.doc"
          beforeUpload={(file) => {
            return false;
          }}
          iconRender={() => {
            return <Spin></Spin>;
          }}
          showUploadList={{ showDownloadIcon: true }}
        ></Upload>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={24}>
            <div className="border-class">
              <Title level={5} className="poppins_medium umoney_title p-10">
                Images
              </Title>
              <div className="border-class m-10">
                <div
                  className="p-10 d-flex justify-between"
                  style={{
                    borderBottom: "1px solid #009D93",
                  }}
                >
                  <div
                    className="d-flex justify-center border-5"
                    style={{ padding: "5px", background: "#009d93" }}
                  >
                    <UploadOutlined
                      style={{
                        fontSize: "25px",
                        color: "#fff",
                      }}
                      onClick={() => openModal("upload-image")}
                    />
                  </div>
                </div>
                <div className="relative m-20">
                  <CImageCard
                    pdf
                    image={bankDocument?.merge_file}
                    type="marksheet Image"
                    onPreview={() => {
                      previewModalRef?.current?.open(
                        bankDocument?.merge_file,
                        "pdf"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
          initialValues={{
            account_number: accountNumber,
            ifsc_code: ifscCode,
          }}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8} className="mt-20">
            <Col className="gutter-row" span={24}>
              <Row gutter={[16, 16]}>
                <Col span={2}>
                  <Checkbox
                    onChange={onChangeAcc}
                    checked={checkedAcc}
                    style={{ padding: 10 }}
                  />
                </Col>
                <Col span={22}>
                  <Form.Item
                    label="ACCOUNT NUMBER"
                    name="account_number"
                    size="large"
                    rules={[
                      {
                        required: true,
                        message: "Account number is required!",
                      },
                      {
                        pattern: /^[0-9]\d*(\.\d+)?$/g,
                        message: "Account number contains only number.",
                      },
                      {
                        max: 20,
                        message: "Account number contains maximum 20 digits ",
                      },
                      {
                        min: 6,
                        message: "Account number contains minimum 6 digits ",
                      },
                    ]}
                    className="poppins_medium mb-35"
                  >
                    <Input
                      size="large"
                      type="text"
                      style={{ width: 405 }}
                      disabled={checkedAcc === true ? false : true}
                      placeholder="Enter account number"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col className="gutter-row" span={24}>
              <Row gutter={[16, 16]}>
                <Col span={2}>
                  <Checkbox
                    onChange={onChangeIfsc}
                    checked={checkedIfsc}
                    style={{ padding: 10 }}
                  />
                </Col>
                <Col span={22}>
                  <Form.Item
                    label="IFSC CODE"
                    name="ifsc_code"
                    size="large"
                    rules={[
                      { required: true, message: "IFSC code is required!" },
                    ]}
                    className="poppins_medium mb-35"
                  >
                    <Input
                      size="large"
                      type="text"
                      style={{ width: 405 }}
                      disabled={checkedIfsc === true ? false : true}
                      placeholder="Enter IFSC code"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item>
                <CLoadingButton
                  size="large"
                  value=" SAVE"
                  className="poppins_medium umoney_modal_filled_btn"
                  loading={savebtnLoading}
                  htmlType="submit"
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal
          open={modalType}
          title={modalTitle}
          onCancel={handleClose}
          centered
          width={1150}
          footer={false}
          maskClosable={false}
          destroyOnClose={true}
        >
          {modalType === "upload-image" ? (
            <UploadBankDocument
              user_id={user_id}
              bank_id={bank_id}
              handleClose={handleClose}
              loadBankDocument={loadBankDocument}
            />
          ) : (
            ""
          )}
        </Modal>
        <PreviewModal ref={previewModalRef} />
      </div>
    </Spin>
  );
};

export default DocumentModal;
