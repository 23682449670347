import React, { useState, useEffect, useRef } from "react";
import { message, Table } from "antd";
import { getAPIData } from "../../../apiHelper";
import { useLocation } from "react-router-dom";
import _, { isEmpty, isArray } from "lodash";
import PreviewModal from "./CommonModal/PreviewModal";

export default function Viewchangelog(props) {
  const [tableLoading, setTableLoading] = useState(false);

  const {page,setPage,current,setCurrent,pageType,user_id} = props;

  const [pageSize, setPageSize] = useState(5);
  const [tablePagination, setTablePagination] = useState();
  const [totalPage, setTotalPage] = useState();

  const previewModalRef = useRef();

  const Viewchangelogcolumns = [
    {
      title: "TIMESTAMP",
      dataIndex: "timestamp",
      width: "15%",
      align: "left",
    },
    {
      title: "DATA CHANGED",
      dataIndex: "dataChanged",
      width: "15%",
      align: "left",
      render: (_, { dataChanged }) => {
        return (
          <>
            {!isEmpty(dataChanged) && isArray(dataChanged)
              ? dataChanged.map((item) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>{item}</span>
                    </div>
                  );
                })
              : null}
          </>
        );
      },
    },
    {
      title: "CHANGED BY (ROLE-AGENT ID)",
      dataIndex: "changedBy",
      width: "20%",
      align: "left",
    },
    {
      title: "PREVIOUS VALUE",
      key: "previous value",
      dataIndex: "oldData",
      width: "30%",
      align: "left",
      render: (_, { oldData }) => {
        return (
          <>
            {!isEmpty(oldData) && isArray(oldData)
              ? oldData.map((item, index) => {
                  return (
                    <div
                      style={{
                        marginTop: 2,
                        marginBottom: 2,
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <span>{item.field} : </span>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          border:
                            item?.fieldType === "character"
                              ? null
                              : "1px dashed #009d93",
                          borderRadius: 3,
                          padding: 5,
                          alignSelf: "flex-start",
                        }}
                      >
                        {item?.fieldType === "image" ? (
                          <img
                            src={item?.value}
                            alt="Old Data"
                            style={{ height: "100%", width: "100%" }}
                            onClick={() => {
                              previewModalRef?.current?.open(item.value);
                            }}
                          />
                        ) : item?.fieldType === "pdf" ? (
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                              }}
                              onClick={() => {
                                previewModalRef?.current?.open(
                                  item.value,
                                  "pdf"
                                );
                              }}
                            ></div>

                            <iframe
                              src={item.value}
                              title="iframe"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                        ) : item?.fieldType === "video" ? (
                          <video
                            onClick={() => {
                              previewModalRef?.current?.open(
                                item.value,
                                "video"
                              );
                            }}
                            style={{ height: "100%", width: "100%" }}
                            src={item.value}
                            type="video/mp4"
                            controls
                          />
                        ) : (
                          <span>{item.value}</span>
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
          </>
        );
      },
    },

    {
      title: "NEW VALUE",
      dataIndex: "newData",
      width: "30%",
      align: "left",
      render: (_, { newData }) => {
        return (
          <>
            {!isEmpty(newData) && isArray(newData)
              ? newData.map((item, index) => {
                  return (
                    <div
                      style={{
                        marginTop: 2,
                        marginBottom: 2,
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <span>{item.field}: </span>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          border:
                            item?.fieldType === "character"
                              ? null
                              : "1px dashed #009d93",
                          borderRadius: 3,
                          padding: 5,
                          alignSelf: "flex-start",
                        }}
                      >
                        {item?.fieldType === "image" ? (
                          <img
                            src={item?.value}
                            alt="New Data"
                            style={{ height: "100%", width: "100%" }}
                            onClick={() => {
                              previewModalRef?.current?.open(item.value);
                            }}
                          />
                        ) : item?.fieldType === "pdf" ? (
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                              }}
                              onClick={() => {
                                previewModalRef?.current?.open(
                                  item.value,
                                  "pdf"
                                );
                              }}
                            ></div>

                            <iframe
                              src={item.value}
                              title="iframe"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                        ) : item?.fieldType === "video" ? (
                          <video
                            onClick={() => {
                              previewModalRef?.current?.open(
                                item.value,
                                "video"
                              );
                            }}
                            style={{ height: "100%", width: "100%" }}
                            src={item.value}
                            type="video/mp4"
                            controls
                          />
                        ) : (
                          <span>{item.value}</span>
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const viewChangeLogApi = () => {
      setTableLoading(true);
      const endPoint =
        pageType === "salaried"
          ? `student/salaried-user-change-logs/${user_id}/${page}/${pageSize}`
          : pageType === "credit-check-detail"
          ? `cc-student/get-cc-change-logs/${user_id}/${page}/${pageSize}`
          : `student/user-change-logs/${user_id}/${page}/${pageSize}`;
      console.log("endPoint", endPoint);
      getAPIData(
        endPoint,
        "get",
        {},
        false,
        pageType === "salaried"
          ? "salaried"
          : pageType === "credit-check-detail"
          ? "credit_check"
          : "student"
      )
        .then((result) => {
          if (result?.status === true && !_.isEmpty(result?.data)) {
            if (
              !_.isEmpty(result.data?.result) &&
              _.isArray(result.data?.result)
            ) {
              setViewChangeLogData(result?.data?.result);
              console.log(result?.data?.res);
              var pagination = result?.data?.pagination;
              setTablePagination(pagination);
              setTotalPage(result?.data?.pagination?.totalCount);
            }
          }
          if (result?.code === 500) {
            message.error(result?.message);
            setTableLoading(false);
          }
          setTableLoading(false);
        })
        .catch((err) => {
          message.err(err);
          setTableLoading(false);
        });
    };

    viewChangeLogApi();
  }, [user_id, pageSize, page, current, props]);

  const [viewChangeLogData, setViewChangeLogData] = useState([]);
  return (
    <div className="umoney_table">
      <Table
        columns={Viewchangelogcolumns}
        dataSource={viewChangeLogData}
        pagination={{
          position: ["none", "bottomLeft"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 15, 20],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
            setCurrent(page);
          },
          total: totalPage,
          current: page,
        }}
        scroll={{
          y: 600,
        }}
        loading={tableLoading}
      />
      <PreviewModal ref={previewModalRef} />
    </div>
  );
}
