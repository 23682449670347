import { Typography, Layout, Col, Row, message } from "antd";
import React, { useState } from "react";
import { useEffect, useRef } from "react";
import CClickLoading from "../../components/InputFields/CClickLoading";
import Sidebar from "../../components/Sidebar";
import { MenuOutlined } from "@ant-design/icons";
import { Content, Header } from "antd/lib/layout/layout";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CSelectBtn from "../../components/InputFields/CSelectBtn";
import { Table, Tag, Modal, Input, Space, Button, Spin } from "antd";
import TargetDevice from "../../components/Modals/DeviceChange/TargetDevice";
import Algo from "../../components/Modals/DeviceChange/Algo";
import PhonePermission from "../../components/Modals/DeviceChange/PhonePermission";
import TicketHistory from "../../components/Modals/DeviceChange/TicketHistory";
import ActionHistory from "../../components/Modals/DeviceChange/ActionHistory";
import PastDevice from "../../components/Modals/DeviceChange/PastDevice";
import Finder from "../../components/Modals/DeviceChange/Finder";
import VerificationBox from "../../components/Modals/DeviceChange/VerificationBox";
import InformationUser from "../../components/Modals/DeviceChange/InformationUser";
import SelectDevice from "../../components/Modals/DeviceChange/SelectDevice";
import InternalDecision from "../../components/Modals/DeviceChange/InternalDecision";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { getAPIData } from "../../apiHelper";
import { device_change_url, setHeader } from "../../api";
import axios from "axios";
import { useSelector } from "react-redux";
import ClickToCallModal from "../../components/Modals/modal/ClickToCallModal";
import _ from "lodash";
import CallHistory from "../../components/Modals/modal/CallHistory";

const DeviceChangePanel = () => {
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [type, setType] = useState("");

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const role = useSelector((state) => state.userData.accessRoleCode);
  // const role="approver";

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);

  // assign agent
  const [userIds, setUserIds] = useState([]);
  const [selectedJunior, setSelectedJunior] = useState("");
  const [assignLoading, setAssignLoading] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [olddeviceId, setOldDeviceId] = useState("");

  const [totalCounts, setTotalCounts] = useState("");
  const [rejectCounts, setRejectedCount] = useState("");
  const [pendingCounts, setPendingCounts] = useState("");
  const [approveCounts, setApproveCounts] = useState("");
  const [followupCount, setFollowupCount] = useState("");
  const [temporaryCount, setTemporaryCount] = useState("");

  const [g_ad_id, setG_ad_id] = useState();
  const [device_unique_id, setDevice_unique_id] = useState();
  const [altNumber, setAltNumber] = useState();
  const [regNumber, setRegNumber] = useState();

  const [spinLoading, setSpinLoading] = useState(false);

  // Modal pagination
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);

  // {record?.action_status === "1"
  // ? "Approve"
  // : record?.action_status === "2"
  // ? "Reject"
  // : record?.action_status === "3"
  // ? "Follow Up"
  // : record?.action_status === "4"
  // ? "Set Temporary"
  // : record?.action_status === "5"
  // ? "Pending"
  // : ""}

  //Device Change list Api
  const [deviceChangeData, setDeviceChangeData] = useState();
  const loadDeviceChangeData = () => {
    setLoading(true);
    setTableLoading(true);
    const endPoint = "device/listing";
    getAPIData(endPoint, "get", {}, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            const rejCount = response.filter(
              (data) => data.action_status === "2"
            );
            const rejectedCount = rejCount.length;
            setRejectedCount(rejectedCount);

            const penCount = response.filter(
              (data) => data.action_status === "5"
            );
            setPendingCounts(penCount.length);

            const appCount = response.filter(
              (data) => data.action_status === "1"
            );
            const approvedCount = appCount.length;
            setApproveCounts(approvedCount);

            const followCount = response.filter(
              (data) => data?.action_status === "3"
            );
            const followCountlen = followCount.length;
            setFollowupCount(followCountlen);

            const tempCount = response.filter(
              (data) => data?.action_status === "4"
            );
            const tempCountlen = tempCount.length;
            setTemporaryCount(tempCountlen);

            let arr = [];
            response.map((data) => {
              arr.push({
                total: data.action_status,
              });
            });
            const total = arr?.length;
            setTotalCounts(total);

            setDeviceChangeData(response);
          }
        } else {
          message.warning(result?.message);
          setTableLoading(false);
        }
        setLoading(false);
        setTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
        setTableLoading(false);
      });
  };

  //Agent List
  const [agentList, setAgentList] = useState();
  const [agentListData, setAgentListData] = useState();
  const loadAgentList = () => {
    setLoading(true);
    const endPoint = "device/agents-list";
    let array = [];
    getAPIData(endPoint, "get", {}, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setAgentList(response);
          response.map((data) =>
            array.push({
              label:
                data?.first_name +
                " " +
                data?.middle_name +
                " " +
                data?.last_name,
              value: data?.user_id,
            })
          );
          setAgentListData(array);
        } else {
          message?.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };

  //Assign Profile
  const handleAssignClick = async () => {
    setAssignLoading(true);
    const endPoint = device_change_url + "device/assign-devices";
    const header = setHeader();
    const data = {
      userIds: JSON.stringify(userIds),
      approver_id: selectedJunior,
    };

    // form.append("userIds", userIds);
    // form.append("approver_id", selectedJunior);
    const assign = await axios.post(endPoint, data, header);
    if (assign?.data?.status) {
      setAssignLoading(false);
      loadDeviceChangeData();
      message.success(assign?.data?.message);
      setSelectedRowKeys("");
      setSelectedJunior("");
      setUserIds("");
    } else {
      message.error(assign?.data?.message);
      setAssignLoading(false);
    }
    setAssignLoading(false);
  };

  const agentSelectChange = (juniorId) => {
    setSelectedJunior(juniorId);
  };

  useEffect(() => {
    loadDeviceChangeData();
    loadAgentList();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "INFO",
      dataIndex: "info",
      key: "info",
      align: "top",
      width: "9%",
      sorter: (a, b) => a?.user_id - b?.user_id,
      filteredValue: filteredInfo.info || null,
      ...getColumnSearchProps("id"),
      render: (text, record) => (
        <div>
          <span>Ticket Id: {record.id}</span>
          <br />
          <span>Request TimeStamp: {record.createdAt}</span>
        </div>
      ),
    },
    {
      title: "USER DETAILS",
      dataIndex: "user_details",
      key: "user_details",
      width: "12%",
      filteredValue: filteredInfo.user_details || null,
      ...getColumnSearchProps("user_details"),
      render: (_noUse, userDetails) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: userDetails?.user_details }}
          />
        );
      },
    },
    {
      title: "OVER DUE AMOUNT",
      dataIndex: "overdueamount",
      key: "overdueamount",
      width: "10%",
      filteredValue: filteredInfo.overdueamount || null,
      render: (_noUse, overdueData) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: overdueData?.overdue_data }}
          />
        );
      },
    },
    {
      title: "USER COMMENTS",
      dataIndex: "usercomments",
      key: "usercommnets",
      width: "10%",
      filteredValue: filteredInfo.usercomments || null,
      render: (_noUse, data) => {
        return <div dangerouslySetInnerHTML={{ __html: "xxxxxx" }} />;
      },
    },
    {
      title: "CURRENT DEVICE DETAILS",
      dataIndex: "old_device",
      key: "old_device",
      width: "20%",
      filteredValue: filteredInfo.old_device || null,
      ...getColumnSearchProps("old_device"),
      render: (_noUse, data) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: data?.old_device }} />
            {!_.isEmpty(data?.old_device) ? (
              <div>
                <span>
                  <Tag
                    className="tag-form"
                    onClick={() => {
                      openModal("current-linked-user");
                      setUserId(data?.user_id);
                      setOldDeviceId(data?.old_device_id);
                    }}
                  >
                    LINKED USERS LIST
                  </Tag>
                </span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.old_device_login_overdue_data,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.old_device_primary_overdue_data,
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "TARGET DEVICE DETAILS",
      dataIndex: "new_device",
      key: "new_device",
      width: "20%",
      filteredValue: filteredInfo.new_device || null,
      ...getColumnSearchProps("new_device"),
      render: (_noUse, data) => {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: data?.new_device }} />
            <div>
              <span>
                <Tag
                  className="tag-form"
                  onClick={() => {
                    openModal("algo");
                    setG_ad_id(data?.g_ad_id);
                    setDevice_unique_id(data?.device_unique_id);
                    setUserId(data?.user_id);
                    setDeviceId(data?.device_id);
                  }}
                >
                  ALGO
                </Tag>
              </span>
              <br />
              <span>
                <Tag
                  className="tag-form"
                  onClick={() =>
                    openModal(
                      "phone-permission",
                      data?.user_id,
                      data?.device_id
                    )
                  }
                >
                  PHONE PERMISSIONS
                </Tag>
              </span>
              <br />
              <span>
                <Tag
                  className="tag-form"
                  onClick={() => {
                    openModal("linked-user");
                    setUserId(data?.user_id);
                    setDeviceId(data?.device_id);
                  }}
                >
                  LINKED USERS LIST
                </Tag>
              </span>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.new_device_login_overdue_data,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: data?.new_device_primary_overdue_data,
              }}
            />
          </div>
        );
      },
    },
    {
      title: "HISTORY",
      dataIndex: "history",
      key: "history",
      filteredValue: filteredInfo.history || null,
      render: (text, record) => (
        <div>
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("ticket-history");
                setDeviceId(record?.device_id);
              }}
            >
              TICKET HISTORY
            </Tag>
          </span>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: record.tickets,
            }}
          />
          <br />
          <span>
            <Tag
              className="tag-form"
              onClick={() =>
                openModal("action-history", record?.user_id, record?.device_id)
              }
            >
              ACTION HISTORY
            </Tag>
          </span>
          <br />
          <span>
            <Tag className="tag-form" onClick={() => openModal("call-history")}>
              CALL HISTORY
            </Tag>
          </span>
          <br />
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("past-device");
                setUserId(record?.user_id);
              }}
            >
              PAST DEVICE
            </Tag>
          </span>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: record.devices,
            }}
          />
        </div>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      render: (text, data) => (
        <div>
          <span>
            <Tag
              className="tag-form"
              onClick={() =>
                openModal("select-device", data?.user_id, data?.device_id)
              }
            >
              UPDATE
            </Tag>
          </span>
          <br />
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModalFinder("finder", data?.user_id, data?.device_id);
                setUserId(data?.user_id);
                setDeviceId(data?.device_id);
                console.log("data?.device_id", data?.device_id);
              }}
            >
              FINDER
            </Tag>
          </span>
          <br />
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("click-to-call", data?.user_id);
                setAltNumber(data?.alt_number);
                setRegNumber(data?.mobile_number);
              }}
            >
              CLICK TO CALL
            </Tag>
          </span>
          <br />
        </div>
      ),
    },
    {
      title: "LAST ACTION",
      dataIndex: "lastaction",
      key: "lastaction",
      width: "10%",
      filteredValue: filteredInfo.lastaction || null,
      render: (text, record) => (
        <div>
          <span>Timestamp: {record.approval_ts}</span>
          <br />
          <span>Agent Name: {record.agentName}</span>
          <br />
          <span>
            Action Taken:{" "}
            {record?.action_status === "1"
              ? "Approve"
              : record?.action_status === "2"
              ? "Reject"
              : record?.action_status === "3"
              ? "Follow Up"
              : record?.action_status === "4"
              ? "Set Temporary"
              : record?.action_status === "5"
              ? "Pending"
              : ""}
          </span>
          <br />
          {record?.action_status === "3" ? (
            <span> Follow up Timestamp: {record.followup_ts}</span>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "CURRENTLY WITH",
      dataIndex: "agentName",
      key: "agentName",
      filteredValue: filteredInfo.agentName || null,
      ...getColumnSearchProps("agentName"),
      hidden: true,
    },
    {
      title: "STATUS",
      dataIndex: "action_status",
      key: "action_status",
      width: "5%",
      align: "center",
      filteredValue: filteredInfo.action_status || null,
      render: (text, record) => (
        <div>
          <span>
            {record?.action_status === "1"
              ? "Approve"
              : record?.action_status === "2"
              ? "Reject"
              : record?.action_status === "3"
              ? "Follow Up"
              : record?.action_status === "4"
              ? "Set Temporary"
              : record?.action_status === "5"
              ? "Pending"
              : ""}
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Approve", text: "Approve" },
        { value: "2", label: "Reject", text: "Reject" },
        { value: "3", label: "Follow Up", text: "Follow Up" },
        { value: "4", label: "Set Temporary", text: "Set Temporary" },
        { value: "5", label: "Pending", text: "Pending" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        !_.isEmpty(record?.action_status)
          ? record.action_status
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          : "",
    },
  ];

  const tableCol = columns.filter((item) => !item.hidden);

  const [value, setValue] = useState("");

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [modalTypeFinder, setModalTypeFinder] = useState("");

  const openModal = (type, user_id = "", device_id = "") => {
    if (type === "linked-user") {
      setModalType(type);
      setUserId(user_id);
      setDeviceId(device_id);
      setModalTitle("Target device linked users list");
      setModalWidth(1000);
    } else if (type === "current-linked-user") {
      setModalType(type);
      setUserId(user_id);
      setDeviceId(device_id);
      setModalTitle("Current Linked Users list");
      setModalWidth(900);
    } else if (type === "algo") {
      setModalType(type);
      setModalTitle("Algo");
      setModalWidth(1250);
    } else if (type === "phone-permission") {
      setUserId(user_id);
      setDeviceId(device_id);
      setModalType(type);
      setModalTitle("Phone Permission");
      setModalWidth(700);
    } else if (type === "ticket-history") {
      setModalType(type);
      setModalTitle("Ticket History");
      setModalWidth(1850);
    } else if (type === "action-history") {
      setModalType(type);
      setUserId(user_id);
      setDeviceId(device_id);
      setModalTitle("Action History Logs");
      setModalWidth(1700);
    } else if (type === "call-history") {
      setModalType(type);
      setModalTitle("Call History (static)");
      setModalWidth(900);
    } else if (type === "past-device") {
      setModalType(type);
      setModalTitle("Past Devices");
      setModalWidth(1300);
    } else if (type === "select-device") {
      setModalType(type);
      setModalTitle("Select Device");
      setModalWidth(600);
      setUserId(user_id);
      setDeviceId(device_id);
    } else if (type === "verification- box") {
      setModalType(type);
      setModalTitle("Verification Box");
      setModalWidth(500);
    } else if (type === "internal-decision") {
      setModalType(type);
      setModalTitle("Internal Decision");
      setModalWidth(500);
    } else if (type === "information-user") {
      setModalType(type);
      setModalTitle("Information to User");
      setModalWidth(500);
    } else if (type === "click-to-call") {
      setModalType(type);
      setModalTitle("Click To Call");
      setModalWidth(500);
      setUserId(user_id);
      setType("device-change");
    }
  };

  const openModalFinder = (type, user_id = "", device_id = "") => {
    if (type === "finder") {
      setModalTypeFinder(type);
      setUserId(user_id);
      setDeviceId(device_id);
      setModalTitle("Finder");
      setModalWidth(1500);
    }
  };

  const handleClose = (type) => {
    setModalType("");
    setUserId("");
    setDeviceId("");
    setValue("");
    setPage(1);
    setCurrent(1);
  };

  const handleCloseFinder = (type) => {
    setModalTypeFinder("");
    setUserId("");
    setDeviceId("");
    setValue("");
  };

  const onSelectChange = (newSelectedRowKeys, selectedKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    let dataUser = [];
    selectedKeys.map((data) => {
      dataUser.push({
        user_id: data?.user_id,
        deviceId: data?.device_id,
      });
    });
    setUserIds(dataUser);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <Spin spinning={spinLoading} size="large">
        <div className={`${loading ? "page-loading app" : "app"}`}>
          <Layout
            style={{
              height: "100%",
              padding: "20px 0 0 20px",
              backgroundColor: "#DFFEFC",
            }}
          >
            <Sidebar open={open} setOpen={setOpen} />
            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                  backgroundColor: "#009D93",
                  borderRadius: "15px 0 0 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <MenuOutlined
                  className="trigger"
                  onClick={() => setOpen(true)}
                />
                <Title level={4} className="poppins_regular umoney_page_title">
                  {" "}
                  Device Change Panel
                </Title>
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  padding: 15,
                  backgroundColor: "#DFFEFC",
                }}
              >
                <div className="white_div">
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <CSelectBtn
                        options={agentListData}
                        size="large"
                        classes="poppins_regular filter_select_border"
                        defaultValue="lucy"
                        btnvalue="ASSIGN TO"
                        onChange={agentSelectChange}
                        onClick={() => handleAssignClick()}
                        btnLoading={assignLoading}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Total:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={totalCounts}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Pending:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={pendingCounts}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Approved:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={approveCounts}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Rejected:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={rejectCounts}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Follow up:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={followupCount}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Set temporary:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={temporaryCount}
                        block
                      />
                    </Col>
                    <Col span={12}></Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="CLEAR FILTERS"
                        className="poppins_medium umoney_btn"
                        onClick={() => clearFilters()}
                        block
                      />
                    </Col>
                  </Row>
                </div>
                <div className="white_div" style={{ marginTop: "20px" }}>
                  <div className="umoney_table">
                    <Table
                      dataSource={deviceChangeData}
                      rowSelection={
                        role === "supervisor_caller" ||
                        role === "supervisor_approver" ||
                        role === "superadmin"
                          ? rowSelection
                          : false
                      }
                      scroll={{ x: "120vw" }}
                      pagination={{
                        showSizeChanger: true,
                        locale: { items_per_page: "" },
                      }}
                      columns={
                        role === "supervisor_caller" ||
                        role === "supervisor_approver" ||
                        role === "superadmin"
                          ? columns
                          : tableCol
                      }
                      style={{
                        marginTop: "10px",
                      }}
                      rowKey={(record) => record.id}
                      onChange={handleChange}
                      loading={tableLoading}
                    />
                  </div>

                  <Modal
                    open={modalType}
                    title={modalTitle}
                    footer={false}
                    onCancel={handleClose}
                    centered
                    width={modalWidth}
                    // destroyOnClose={true}
                  >
                    {modalType === "linked-user" ? (
                      <TargetDevice
                        userId={userId}
                        deviceId={deviceId}
                        modalType={modalType}
                      />
                    ) : modalType === "current-linked-user" ? (
                      <TargetDevice userId={userId} deviceId={olddeviceId} />
                    ) : modalType === "algo" ? (
                      <Algo
                        userId={userId}
                        device_unique_id={device_unique_id}
                        g_ad_id={g_ad_id}
                        deviceId={deviceId}
                      />
                    ) : modalType === "phone-permission" ? (
                      <PhonePermission userId={userId} deviceId={deviceId} />
                    ) : modalType === "ticket-history" ? (
                      <TicketHistory
                        deviceId={deviceId}
                        modalType={modalType}
                      />
                    ) : modalType === "action-history" ? (
                      <ActionHistory
                        userId={userId}
                        deviceId={deviceId}
                        modalType={modalType}
                        page={page}
                        setPage={setPage}
                        current={current}
                        setCurrent={setCurrent}
                      />
                    ) : modalType === "call-history" ? (
                      <CallHistory />
                    ) : modalType === "past-device" ? (
                      <PastDevice userId={userId} modalType={modalType} />
                    ) : modalType === "select-device" ? (
                      <SelectDevice
                        userId={userId}
                        deviceId={deviceId}
                        handleClose={handleClose}
                        loadData={loadDeviceChangeData}
                      />
                    ) : modalType === "verification- box" ? (
                      <VerificationBox />
                    ) : modalType === "internal-decision" ? (
                      <InternalDecision />
                    ) : modalType === "information-user" ? (
                      <InformationUser />
                    ) : modalType === "click-to-call" ? (
                      <ClickToCallModal
                        user_id={userId}
                        type={type}
                        altNumber={altNumber}
                        regNumber={regNumber}
                      />
                    ) : (
                      ""
                    )}
                  </Modal>
                </div>
                <Modal
                  open={modalTypeFinder}
                  title={modalTitle}
                  footer={false}
                  onCancel={handleCloseFinder}
                  centered
                  width={modalWidth}
                  destroyOnClose={true}
                >
                  <Finder
                    deviceId={deviceId}
                    userId={userId}
                    value={value}
                    setValue={setValue}
                    modalType={modalType}
                    role={role}
                  />
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </div>
      </Spin>
    </>
  );
};

export default DeviceChangePanel;
