import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import Sidebar from "../../components/Sidebar";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import { Layout, Row, Col, Typography, message } from "antd";
import CClickLoading from "../../components/InputFields/CClickLoading";
import CSelectBtn from "../../components/InputFields/CSelectBtn";
import { getAPIData } from "../../apiHelper";
import axios from "axios";
import { setHeader, salaried_url } from "../../api";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import TableData from "./Components/TableData";

const SalariedList = () => {
  const { Header, Content } = Layout;
  const { Title } = Typography;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const pathType = pathName[1];
  const role = useSelector((state) => state.userData.accessRoleCode);
  // const role = "caller"
  const role_name = useSelector((state) => state.userData.accessRoleName);

  //sorting Btns
  const [totalCount, setTotalCount] = useState("");
  const [rejectedCount, setRejectedCount] = useState("");
  const [approvedCount, setApprovedCount] = useState("");
  const [pendingCount, setPendingCount] = useState("");
  const [checkedCount, setCheckedCount] = useState("");

  //Table sorting btn\
  const [juniorData, setJuniorData] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [selectedJunior, setSelectedJunior] = useState("");

  const handleAssignClick = async () => {
    setAssignLoading(true);
    const endPoint = salaried_url + "student/assign-salaried-profiles";
    const header = setHeader();
    const form = new FormData();

    form.append("user_ids", userIds);
    form.append("junior_id", selectedJunior);

    const assign = await axios.post(endPoint, form, header);
    if (assign?.data?.status) {
      setAssignLoading(false);
      loadJuniorsData();
      message.success(assign?.data?.message);
      setSelectedJunior("");
      setUserIds("");
    } else {
      message.error(assign?.data?.message);
      setAssignLoading(false);
    }
    setAssignLoading(false);
  };

  const agentSelectChange = (juniorId) => {
    setSelectedJunior(juniorId);
  };

  const loadJuniorsData = async () => {
    setLoading(true);
    const endPoint = "student/salaried-agent-list";
    getAPIData(endPoint, "get", {}, false, "salaried")
      .then((response) => {
        if (response?.status) {
          const agents = response?.data;
          if (agents) {
            let data = [];
            agents.map(async (agent) => {
              data.push({
                label:
                  agent?.first_name +
                  " " +
                  agent?.middle_name +
                  " " +
                  agent?.last_name,
                value: agent?.user_id,
              });
            });
            setJuniorData(data);
          }
        } else {
          message.warning(response?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    const loadData = () => {
      loadJuniorsData();
    };
    loadData();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title ">
                {" "}
                {role_name}'s List View{" "}
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  {role === "supervisor_caller" ||
                  role === "supervisor_approver" ||
                  role === "superadmin" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CSelectBtn
                          options={juniorData}
                          size="large"
                          classes="poppins_regular filter_select_border"
                          placeholder="Select agents"
                          btnvalue="ASSIGN TO"
                          onClick={handleAssignClick}
                          btnLoading={assignLoading}
                          onChange={agentSelectChange}
                        />
                      </Col>
                      <Col span={16}></Col>
                    </>
                  ) : (
                    <>
                      <Col className="gutter-row" span={16}>
                        <Title
                          level={5}
                          className="poppins_medium umoney_title"
                        >
                          Allocated to me:{" "}
                        </Title>
                      </Col>
                      <Col className="gutter-row" span={8}></Col>
                    </>
                  )}
                  {role === "caller" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Forwarded to Approver:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Checked:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={checkedCount}
                          block
                        />
                      </Col>
                    </>
                  ) : role === "approver" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Checked:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={checkedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Send to caller:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Completed:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Sent to calling:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approverd:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={approvedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                    </>
                  ) : role === "supervisor_caller" ||
                    role === "supervisor_approver" ||
                    role === "superadmin" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={approvedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Checked:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={checkedCount}
                          block
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {/* } */}
                <div className="mt-20">
                  <TableData
                    setTotalCount={setTotalCount}
                    setApprovedCount={setApprovedCount}
                    setPendingCount={setPendingCount}
                    setRejectedCount={setRejectedCount}
                    setLoading={setLoading}
                    setCheckedCount={setCheckedCount}
                    pathType={pathType}
                    user_ids={(val) => {
                      setUserIds(val);
                    }}
                    role={role}
                  />
                </div>

                {role === "caller" ? (
                  <Row gutter={[16, 16]} className="mt-20">
                    <Col className="gutter-row" span={12}>
                      <Title level={5} className="poppins_medium umoney_title ">
                        Forwarded to Approver:{" "}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={12}></Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Total:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={totalCount}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Back to Caller:"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="With Approver:"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Approved:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={approvedCount}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Rejected:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={rejectedCount}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Pending:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={pendingCount}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Rejected:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={rejectedCount}
                        block
                      />
                    </Col>
                    <Col span={24}>
                      <TableData
                        setTotalCount={setTotalCount}
                        setApprovedCount={setApprovedCount}
                        setPendingCount={setPendingCount}
                        setRejectedCount={setRejectedCount}
                        setLoading={setLoading}
                        setCheckedCount={setCheckedCount}
                        pathType={pathType}
                        user_ids={(val) => {
                          setUserIds(val);
                        }}
                        role={role}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default SalariedList;
