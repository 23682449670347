import React, { useRef, useState } from "react";
import { Col, Row, Form, Upload, message, Button, Modal } from "antd";
import CCloseIcon from "../InputFields/CCloseIcon";
import CLoadingButton from "../InputFields/CLoadingButton";
import { PlayCircleFilled } from "@ant-design/icons";

import { salaried_url, setHeaderForFile, student_url } from "../../api";
import axios from "axios";
import _ from "lodash";

import RemoveImage from "../Modals/modal/RemoveImage";
import PreviewModal from "../Modals/modal/CommonModal/PreviewModal";

const VideoDetails = (props) => {
  console.log("props", props);
  const VideoDetails = props?.VideoDetails;
  const user_id = props?.user_id;
  const [frontImage, setFrontImage] = useState([]);
  const [videoSaveBtnLoading, setVideoSaveBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const [idApprover, setIdApprover] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);

  const pageType = props?.pageType;
  const isSuccess = props?.isSuccess;

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [type, setType] = useState("video");

  let disableSaveAndApproveButtons = false;
  let disableSaveButtons = false;

  if (_.isNull(VideoDetails?.video) || _.isEmpty(frontImage)) {
    disableSaveAndApproveButtons = true;
    disableSaveButtons = true;
  }

  if (!_.isEmpty(frontImage)) {
    disableSaveAndApproveButtons = false;
    disableSaveButtons = false;
  }

  if (VideoDetails?.video) {
    disableSaveAndApproveButtons = false;
  }

  const openModal = (type) => {
    if (type === "remove-video") {
      setModalType(type);
      setModalTitle("Remove Video");
      setModalWidth(500);
    }
    if (type === "preview-video") {
      setModalType(type);
      setModalTitle("Preview Video");
      setModalWidth(500);
    }
  };

  const previewModalRef = useRef(null);

  const beforeFrontUpload = async (file) => {
    try {
      const res = await checkVideo(file);
      if (res?.status) {
        setFrontImage(file);
        return true;
      } else {
        message.warning(res?.message);
      }
    } catch (error) {
      return { status: false, message: error?.message, code: 500 };
    }
  };

  const checkVideo = async (file) => {
    const isMp4 = file?.type === "video/mp4";
    let status = true;
    let message = "";
    if (!isMp4) {
      message = "You can only upload MP4 file!";
      status = false;
    }
    return { message, status };
  };

  const onFinish = async (data) => {
    props?.loading(true);
    const ext = data?.front_img?.file?.type;
    if (!_.isEmpty(data?.front_img)) {
      if (ext !== "video/mp4") {
        props?.loading(false);
        return message.warning("You can only upload MP4 file!");
      }
    }
    const endpoint =
      pageType === "salaried"
        ? salaried_url + "steps/agent-save-video-data"
        : student_url + "steps/agent-save-video-data";
    const header = setHeaderForFile();

    const form = new FormData();
    if (idApprover) {
      setApproveBtnLoading(true);
      form.append("isApprove", idApprover);
    } else {
      setVideoSaveBtnLoading(true);
    }

    if (!_.isEmpty(frontImage) && idApprover) {
      form.append("frontImg", data?.front_img?.file?.originFileObj);
    }

    if (!idApprover) {
      if (data?.front_img?.file?.originFileObj) {
        form.append("frontImg", data?.front_img?.file?.originFileObj);
      }
    }

    form.append("user_id", user_id);

    const uploadData = await axios.post(endpoint, form, header);
    console.log("uploadData", uploadData);
    if (uploadData?.data?.status) {
      props?.isSuccess(true);
      props?.loading(false);
      message.success(uploadData?.data?.message);
      setFrontImage([]);
      setIdApprover(false);
      if (idApprover) {
        setApproveBtnLoading(false);
      } else {
        setVideoSaveBtnLoading(false);
      }
    } else {
      message.warning(uploadData?.data?.message);
      props?.loading(false);
      setIdApprover(false);
      if (idApprover) {
        setApproveBtnLoading(false);
      } else {
        setVideoSaveBtnLoading(false);
      }
    }
    props?.loading(false);
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const handleClose = () => {
    setModalType("");
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={5}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {_.isEmpty(VideoDetails?.video) ? (
              <div
                style={{
                  width: "auto",
                  border: "1px dashed #009d93",
                  borderRadius: 5,
                  background: "#dffefc",
                  padding: 20,
                  margin: 20,
                  height: 235,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="poppins_semi umoney_title">
                  No Video Added
                </span>
              </div>
            ) : (
              <>
                <div>
                  <img
                    src={VideoDetails?.thumb_image}
                    alt="User"
                    style={{ height: "100%", width: "105%" }}
                  />
                  <CCloseIcon
                    onClick={() => openModal("remove-video")}
                    style={{ position: "absolute", top: "-10px", right: "0px" }}
                  />
                </div>
                <PlayCircleFilled
                  style={{
                    position: "absolute",
                    left: "44%",
                    fontSize: "35px",
                    color: "white",
                  }}
                  onClick={() => {
                    previewModalRef?.current?.open(VideoDetails?.video);
                  }}
                />
              </>
            )}
          </div>
        </Col>
        <Col span={19}></Col>
        <Col span={24}>
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Col
              className="mt-20"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Item name="front_img">
                <Upload
                  maxCount="1"
                  beforeUpload={beforeFrontUpload}
                  onRemove={() => setFrontImage([])}
                >
                  <Button size="large">Upload Video</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={10} className="mt-20">
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <div>
                    <Form.Item>
                      <CLoadingButton
                        size="large"
                        value="SAVE"
                        htmlType="submit"
                        loading={videoSaveBtnLoading}
                        className="poppins_medium umoney_btn"
                        block
                        disabled={disableSaveButtons}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <CLoadingButton
                      size="large"
                      value="SAVE & APPROVE"
                      className="poppins_medium umoney_btn"
                      onClick={() => setIdApprover(true)}
                      htmlType="submit"
                      loading={approveBtnLoading}
                      block
                      disabled={disableSaveAndApproveButtons}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Form>
        </Col>
      </Row>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={handleClose}
        centered
        width={modalWidth}
        footer={false}
        // destroyOnClose={true}
      >
        {modalType === "remove-video" ? (
          <RemoveImage
            type={type}
            user_id={user_id}
            pageType={pageType}
            isSuccess={isSuccess}
          />
        ) : (
          ""
        )}
      </Modal>
      <PreviewModal ref={previewModalRef} />
    </div>
  );
};

export default VideoDetails;
