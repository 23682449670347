import React, { useState } from "react";
import { message, Table } from "antd";
import { useEffect } from "react";
import { getAPIData } from "../../../../apiHelper";
import _ from "lodash";

const tableColumns = [
  {
    title: "TIMESTAMP",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: "12%",
  },
  {
    title: "CHANGED BY",
    dataIndex: "agentName",
    key: "agentName",
    width: "17%",
  },
  {
    title: "L1",
    dataIndex: "l_one",
    key: "l_one",
    width: "10%",
  },
  {
    title: "L2",
    dataIndex: "l_two",
    key: "l_two",
    width: "10%",
  },
  {
    title: "L3",
    dataIndex: "l_three",
    key: "l_three",
    width: "20%",
  },
  {
    title: "COMMENT",
    dataIndex: "comment",
    key: "comment",
    width: "35%",
  },
];

const CallStatusLogModal = (props) => {
  const { user_id, current, setCurrent, page, setPage, pageType } = props;

  const [pageSize, setPageSize] = useState(5);
  const [tablePagination, setTablePagination] = useState();
  const [totalPage, setTotalPage] = useState();

  const [callStatusLogData, setCallStatusLogData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const loadCallStatusLogData = () => {
      setTableLoading(true);
      const endPoint =
        pageType === "salaried"
          ? `student/get-call-status-logs/${user_id}?page=${page}&&limit=${pageSize}`
          : pageType === "credit-check-detail"
          ? `internal-apis/get-call-status/${user_id}?page=${page}&&limit=${pageSize}`
          : `student/disposition-logs/${user_id}?page=${page}&&limit=${pageSize}`;
      console.log("endPoint", endPoint);
      getAPIData(
        endPoint,
        "get",
        {},
        false,
        pageType === "salaried"
          ? "salaried"
          : pageType === "credit-check-detail"
          ? "credit_check"
          : "student"
      )
        .then((result) => {
          if (result?.status === true && !_.isEmpty(result?.data)) {
            var response = result?.data?.result;
            var pagination = result?.data?.pagination;
            if (!_.isEmpty(response) && _.isArray(response)) {
              setTablePagination(pagination);
              setTotalPage(result?.data?.pagination?.totalCount);
              setCallStatusLogData(response);
              setTableLoading(false);
            }
          }
          if (result?.code === 500) {
            message.error(result?.message);
            setTableLoading(false);
          }
          setTableLoading(false);
        })
        .catch((err) => {
          message.error(err);
          setTableLoading(false);
        });
    };
    loadCallStatusLogData();
  }, [props, user_id, page, pageSize, current]);

  return (
    <div className="umoney_table">
      <Table
        dataSource={callStatusLogData}
        columns={tableColumns}
        pagination={{
          position: ["none", "bottomLeft"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 15, 20],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
            setCurrent(page);
          },
          total: totalPage,
          current: page,
        }}
        loading={tableLoading}
      />
    </div>
  );
};

export default CallStatusLogModal;
