import React, { useState, useEffect } from "react";
import { Col, Form, message, Modal, Radio, Row, Table } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const InformationUser = ({ id, handleCloseInfo, deviceId, userId, loadData }) => {
  const [informationData, setInformationData] = useState();
  const [form] = Form.useForm();

  const onFinishFailed = () => {};

  const loadInformation = () => {
    const endPoint = "device/info-to-user-list";
    getAPIData(endPoint, "get", {}, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          const arr = [];
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.content,
            });
          });
          setInformationData(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    const verifiedData = {
      ...data,
      id: id,
      user_id: userId,
      device_id: deviceId,
    };
    const endPoint = "device/add-info-to-user";
    getAPIData(endPoint, "post", verifiedData, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          // var response=result?.data?.id;
          // setId(response)
          form.resetFields();
          setSaveBtnLoading(false);
          handleCloseInfo();
          loadData();
          // openModal("information-user");
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  //save btn
  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const saveClick = () => {
    setLoading(true);
    setSaveBtnLoading(true);
  };

  useEffect(() => {
    loadInformation();
  }, []);

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="information_to_user_id"
              size="large"
              rules={[{ required: true, message: "select One" }]}
              className="poppins_medium mb-35"
            >
              <Radio.Group
                className="poppins_medium"
                options={informationData}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                onClick={saveClick}
                style={{ width: "100%" }}
                className="poppins_medium umoney_btn"
                loading={saveBtnLoading}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InformationUser;
