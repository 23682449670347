const actions = {
  SET_DEPARTMENT: "department/SET_DEPARTMENT",
  SET_ROLE: "role/SET_ROLE",
  SET_ROLE_NAME: "role/SET_ROLE_NAME",
  SET_ROLE_CODE: "role/SET_ROLE_CODE",
  SET_USER_DATA: "role/SET_USER_DATA",
  SET_LOGIN_TYPE: "type/SET_LOGIN_TYPE",

  setDepartmentData: (accessDepartment) => (dispatch) =>
    dispatch({
      type: actions.SET_DEPARTMENT,
      accessDepartment,
    }),

  setRoleData: (accessRole) => (dispatch) =>
    dispatch({
      type: actions.SET_ROLE,
      accessRole,
    }),

  setRoleName: (accessRoleName) => (dispatch) =>
    dispatch({
      type: actions.SET_ROLE_NAME,
      accessRoleName,
    }),

  setRoleCode: (accessRoleCode) => (dispatch) =>
    dispatch({
      type: actions.SET_ROLE_CODE,
      accessRoleCode,
    }),

  setUserData: (accessUserData) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      accessUserData,
    }),

    setLoginTypeData: (accessLoginType) => (dispatch) =>
    dispatch({
      type: actions.SET_LOGIN_TYPE,
      accessLoginType,
    }),

};

export default actions;
