import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";
const FeeDisbursalModals = ({
  modalType,
  modalTitle,
  setModalType,
  loadFeeData,
  editData,
}) => {
  const [form] = Form.useForm();
  const [gst, setGst] = useState();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType("");
  };

  console.log(editData);

  const feeTypeData = [
    {
      value: "f",
      label: "Fixed",
    },
    {
      value: "p",
      label: "Percentage",
    },
  ];

  const tenureData = [
    {
      value: "0",
      label: "NO",
    },
    {
      value: "1",
      label: "Day",
    },
    {
      value: "2",
      label: "Month",
    },
    {
      value: "3",
      label: "Quater",
    },
    {
      value: "4",
      label: "Yearly",
    },
  ];

  const gstData = [
    {
      value: "1",
      label: "YES",
    },
    {
      value: "0",
      label: "NO",
    },
  ];

  const submitData = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "edit-fee"
        ? "master/disbursal-fee/update"
        : "master/disbursal-fee/create";
    getAPIData(
      endPoint,
      "post",
      modalType === "edit-fee" ? { ...data, disbursal_id: editData?.id } : data,
      false,
      "admin"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSaveBtnLoading(false);
          handleClose();
          form.resetFields();
          loadFeeData();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const onFinishFailed = () => {
    console.log("failed");
  };

  useEffect(() => {
    if (modalType === "add-tenure") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }
  }, [form, editData, modalType]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={submitData}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Fee Type"
                name="fee_type"
                rules={[
                  {
                    required: true,
                    message: "Fee Type is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  size="large"
                  placeholder="Enter the fee type"
                  options={feeTypeData}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Tenure Dependent"
                name="tenure_dependent"
                rules={[
                  {
                    required: true,
                    message: "Tenure Dependent is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  size="large"
                  placeholder="Select the tenure "
                  options={tenureData}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Fee"
                name="fee"
                rules={[
                  {
                    required: true,
                    message: "Fee is required!",
                  },
                  {
                    pattern: /^[1-9]\d*(\.\d+)?$/g,
                    message: "Fee contains only number.",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the fee" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Max Cap"
                name="max_cap"
                rules={[
                  {
                    required: true,
                    message: "Max Cap required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the max cap" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="GST Applicable"
                name="gst_applicable"
                rules={[
                  {
                    required: true,
                    message: "GST required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  size="large"
                  placeholder="Select the GST"
                  options={gstData}
                  onChange={(e) => setGst(e)}
                />
              </Form.Item>
            </Col>
            {gst === "1" ? (
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="GST Percentage"
                  name="gst_percent"
                  rules={[
                    {
                      required: true,
                      message: "GST percentage required!",
                    },
                    {
                      pattern: /^[1-9]\d*(\.\d+)?$/g,
                      message: "GST Percentage contains only number.",
                    },
                    () => ({
                      validator(rule, value) {
                        if (value > 100) {
                          return Promise.reject("Value must be less than 100");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter the GST percentage" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}

            <Col span={24}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value=" SAVE"
                  className="poppins_medium umoney_modal_filled_btn"
                  block
                  loading={saveBtnLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default FeeDisbursalModals;
