import React, { useState, useEffect } from "react";
import CClickLoading from "../components/InputFields/CClickLoading";
import { Col, Layout, Row, message } from "antd";
import Sidebar from "../components/Sidebar";
import { MenuOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import CSelect from "../components/InputFields/CSelect";
import { getAPIData } from "../apiHelper";
import { setHeader } from "../api";
import commonActions from "../redux/reducers/common/action";
import { useDispatch } from "react-redux";

const { Header, Content } = Layout;

const Dashboard = () => {
  const userData = useSelector((state) => state?.userData);
  const login_email = userData?.accessUserData?.email;
  const supervisor_email = "superadmin@mailinator.com";

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [departments, setDepartments] = useState();
  const [roles, setRoles] = useState();

  const [userDepartment, setUserDepartment] = useState(
    userData?.accessDepartment
  );
  const [userRole, setUserRole] = useState(userData?.accessRole);

  const dispatch = useDispatch();
  const { setDepartmentData, setRoleData, setRoleName, setRoleCode } =
    commonActions;

  const loadData = () => {
    const endPoint = `internal-api/common-data`;
    const header = setHeader();

    getAPIData(endPoint, "get", {}, header)
      .then((result) => {
        if (result?.status === true) {
          setDepartments(result?.data?.Department);
          var role = result?.data?.Role;
          let array = [];
          for (let i = 0; i < role.length; i++) {
            if (i >= 2) {
              array.push({
                value: role[i]?.id,
                label: role[i]?.name,
                type: role[i]?.type,
              });
            }
            let arr = array.filter((cat) => cat.type === "2");
            setRoles(arr);
          }
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const departmentChange = (value) => {
    setUserDepartment(value);

    const endPoint = `user-agent/department-change`;
    const header = setHeader();
    const data = { department_id: value };

    getAPIData(endPoint, "post", data, header)
      .then((result) => {
        if (result?.status === true) {
          dispatch(setDepartmentData(result?.data?.department_id));
          message.success(result?.message);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const roleChange = (value) => {
    setUserRole(value);

    const endPoint = `user-agent/role-change`;
    const header = setHeader();
    const data = { role_id: value };

    getAPIData(endPoint, "post", data, header)
      .then((result) => {
        if (result?.status === true) {
          dispatch(setRoleData(result?.data?.role_id));
          dispatch(setRoleName(result?.data?.role_name));
          dispatch(setRoleCode(result?.data?.role_code));
          message.success(result?.message);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            padding: "20px 0 20px 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: "20px 15px",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              {login_email === supervisor_email ? (
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={16}></Col>
                  <Col className="gutter-row" span={4}>
                    <CSelect
                      name="department"
                      onChange={departmentChange}
                      defaultValue={userDepartment}
                      label="Department"
                      data={departments}
                      size="large"
                      classes="poppins_regular"
                      fontSize="15px"
                    />
                  </Col>
                  <Col className="gutter-row" span={4}>
                    <CSelect
                      name="role"
                      onChange={roleChange}
                      defaultValue={userRole}
                      label="Role"
                      data={roles}
                      size="large"
                      classes="poppins_regular"
                      fontSize="15px"
                    />
                  </Col>
                  {/* push package,json file */}
                  Latest Push : 05/05/2023 10:54 AM
                </Row>
              ) : (
                ""
              )}
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default Dashboard;
