import React from "react";
import { Table } from "antd";

export default function HistoryModalCreditCheck() {
  const historycolumns = [
    {
      title: "TIMESTAMP",
      dataIndex: "timestamp",
    },
    {
      title: "ACTION BY",
      dataIndex: "actionby",
    },
    {
      title: "NAME",
      dataIndex: "name",
    },
    {
      title: "RELATION",
      dataIndex: "relation",
    },

    {
      title: "STATUS",
      dataIndex: "status",
    },
    {
      title: "CONTACT NUMBER",
      dataIndex: "contactnumber",
    },
    {
      title: "COMMENT",
      dataIndex: "comment",
    },
    {
      title: "VALIDATED",
      dataIndex: "validated",
    },
  ];
  const Historydata = [
    {
      key: "1",
      timestamp: "12/02/2022 | 10:20 PM",
      actionby: "Agent (Joy Dove (21515))",
      name: "Joy Dove",
      relation: "Brother",
      status: "Has a mobile",
      contactnumber: "9876543210",
      comment: "Lorem ipsum",
      validated: "NA",
    },
    {
      key: "2",
      timestamp: "12/02/2022 | 10:20 PM",
      actionby: "Agent (Joy Dove (21515))",
      name: "Joy Dove",
      relation: "Brother",
      status: "Has a mobile",
      contactnumber: "9876543210",
      comment: "Lorem ipsum",
      validated: "NA",
    },
    {
      key: "3",
      timestamp: "12/02/2022 | 10:20 PM",
      actionby: "Agent (Joy Dove (21515))",
      name: "Joy Dove",
      relation: "Brother",
      status: "Has a mobile",
      contactnumber: "9876543210",
      comment: "Lorem ipsum",
      validated: "NA",
    },
  ];
  return (
    <div>
      <div className="umoney_table">
        <Table
          columns={historycolumns}
          dataSource={Historydata}
          pagination={false}
        />
      </div>
    </div>
  );
}
