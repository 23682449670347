import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import { CloseCircleOutlined } from "@ant-design/icons";
import CLoadingButton from "../InputFields/CLoadingButton";
const LoanRepaymentByUserModals = ({
  modalType,
  modalTitle,
  setModalType,
  isEdit,
  editId,
  loadDebitData,
  editData,
}) => {
  const [debitCardCharges] = Form.useForm();

  console.log(editData);

  const [saveDebitBtnLoading, setSaveDebitBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType(false);
  };

  //DEBIT CARD EXTRA CHARGES
  const onFinishDebitCardCharge = (values) => {
    setSaveDebitBtnLoading(true);

    const endPoint =
      modalType === "add-loan"
        ? "system-config/debit-charges/create"
        : "system-config/debit-charges/update/" + editData?.id;

    getAPIData(endPoint, "post", values, false)
      .then((result) => {
        if (result?.status === true) {
          setSaveDebitBtnLoading(false);
          message.success(result?.message);
          debitCardCharges.resetFields();
          handleClose();
          loadDebitData();
        } else {
          message.warning(result?.message);
          setSaveDebitBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedDebitCardCharge = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (modalType === "add-loan") {
      debitCardCharges.resetFields();
    } else {
      debitCardCharges.setFieldsValue(editData);
    }
  }, [debitCardCharges, editData, modalType]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={onFinishDebitCardCharge}
          onFinishFailed={onFailedDebitCardCharge}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={debitCardCharges}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Minimum amount of loan"
                name="min_loan_amount"
                rules={[
                  {
                    required: true,
                    message: "Minimum amount of loan is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter minimum amount of loan"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Maximum amount of loan"
                name="max_loan_amount"
                rules={[
                  {
                    required: true,
                    message: "Maximum amount of loan is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter maximum amount of loan"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Extra charge"
                name="extra_charges"
                rules={[
                  {
                    required: true,
                    message: "Extra charge is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter extra charge" />
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={editCancleIcon ? 1 : 0}>
                      {editCancleIcon ? (
                        <CloseCircleOutlined
                          onClick={closeEdit}
                          style={{ marginTop: 13 }}
                        />
                      ) : (
                        ""
                      )}
                    </Col> */}
            <Col className="gutter-row" span={24}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                className="poppins_medium umoney_btn"
                loading={saveDebitBtnLoading}
                block
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default LoanRepaymentByUserModals;
