import React from "react";
import _ from "lodash";
import CCloseIcon from "../../InputFields/CCloseIcon";
import CNewTab from "../../InputFields/CNewTab";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";

const CImageCard = (props) => {
  const {
    image = "",
    type = "",
    pdf = false,
    onRemove = () => {},
    onPreview = () => {},
  } = props;

  const download = async (url) => {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    });

    const urlParts = url.split("/");
    const filename = urlParts[urlParts.length - 1];
    const urlObject = window.URL || window.webkitURL;
    const downloadUrl = urlObject.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let typeFile;

  if (!_.isEmpty(image)) {
    const fileName = image.substring(image.lastIndexOf("/") + 1);
    const fileType = fileName?.split(".");
    const finalType = fileType[1];
    typeFile = finalType;
  }

  return (
    <div>
      {_.isEmpty(image) ? (
        <div
          style={{
            width: "auto",
            border: "1px dashed #009d93",
            borderRadius: 5,
            background: "#dffefc",
            padding: 20,
            margin: 20,
            height: 235,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="poppins_semi umoney_title">No Image Added</span>
        </div>
      ) : (
        <div className="relative m-20">
          {typeFile === "pdf" ? (
            <iframe
              src={image}
              title="iframe"
              height="100%"
              width="100%"
            ></iframe>
          ) : (
            <img
              src={image}
              alt={type}
              height="100%"
              width="100%"
              className="relative kyc-images"
              style={{ border: "1px solid #009d93" }}
            />
          )}

          <CCloseIcon onClick={onRemove} />
          <CNewTab PreviewImageFunc={onPreview} />

          <div
            style={{
              padding: "5px",
              background: "#009d93",
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
            className="d-flex justify-center border-5"
          >
            <a onClick={() => download(image)}>
              <DownloadOutlined
                style={{
                  fontSize: "25px",
                  color: "#fff",
                }}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default CImageCard;
