import types from "./action";

const initialState = {
  accessToken: "",
  accessForgotPassword: "",
  accessChangePassword: "",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: actions.accessToken,
      };
    default:
      return state;
  }
}
