import React, { useEffect, useState } from "react";

import { Row, Col, Form, Input, Select, Radio, Dropdown, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import TextArea from "antd/lib/input/TextArea";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

export default function UpdateDetailsForFatherDevice(props) {
  const {
    role,
    user_id,
    userRecord,
    handleClose,
    userRole,
    loadUserProvidedInfo,
    modalType,
  } = props;

  const [Value, setValue] = useState(userRecord?.phone_details);
  console.log("userRecord", userRecord);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setValue(value);
    if (value !== "1") {
      form.setFieldValue("contact_no", "");
    }
  };

  const [form] = Form.useForm();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const dropdownValue = [
    {
      value: "1",
      label: "Has a mobile",
    },
    {
      value: "2",
      label: "Does not use mobile",
    },
    {
      value: "3",
      label: "Passes away",
    },
    {
      value: "4",
      label: "Separated and not in touch",
    },
  ];

  const radioValue = [
    {
      value: "0",
      label: "No",
    },
    {
      value: "1",
      label: "Yes",
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      phone_details: userRecord?.phone_details,
      contact_no: _.isEmpty(userRecord?.contact_no)
        ? userRecord?.contact_num_as_agent
        : userRecord?.contact_no,
      comment: userRecord?.comment,
      validated: userRecord?.validated,
    });
    setValue(userRecord?.phone_details);
  },[props]);

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    const endPoint = `device/update-device-contact-info/${userRecord?.id}`;
    const formData = { ...data, relation_id: userRecord?.relation_id };
    console.log("formData", formData);
    getAPIData(endPoint, "post", formData, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          loadUserProvidedInfo();
          setSaveBtnLoading(false);
          handleClose();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Father"
              name="phone_details"
              rules={[
                {
                  required: true,
                  message: "Please enter the New value !",
                },
              ]}
              className="poppins_medium"
            >
              <Select
                defaultValue=""
                onChange={handleChange}
                size="large"
                options={dropdownValue}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Father's mobile number"
              name="contact_no"
              rules={[
                {
                  required: Value === "1" ? true : false,
                  message: "Please enter the New value !",
                },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Contact number contains only number.",
                },
                {
                  max: 10,
                  message: "Contact number contains maximum 10 digits.",
                },
                () => ({
                  async validator(rule, value) {
                    const firstDigit = String(value)[0];
                    if (!_.isUndefined(firstDigit) && firstDigit <= 5) {
                      return Promise.reject(
                        "Phone number must be start with 6 to 9 digits only."
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              className="poppins_medium"
            >
              <Input size="large" disabled={Value === "1" ? false : true} />
            </Form.Item>
          </Col>

          {role === "approver" ? (
            <>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Comment !",
                    },
                  ]}
                  className="poppins_medium"
                >
                  <TextArea
                    placeholder="Enter comment"
                    allowClear
                    rows={4}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                <Form.Item
                  label="Validated?"
                  name="validated"
                  rules={[
                    {
                      required: true,
                      message: "Please Select one !",
                    },
                  ]}
                  className="poppins_medium"
                >
                  <Radio.Group
                    options={radioValue}
                    style={{ marginTop: 20, marginLeft: 10 }}
                  ></Radio.Group>
                </Form.Item>
              </Col>{" "}
            </>
          ) : (
            ""
          )}

          <Col className="gutter-row" span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SAVE"
                htmlType="submit"
                className="poppins_medium umoney_modal_filled_btn"
                loading={saveBtnLoading}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
