import { Col, DatePicker, Form, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useEffect } from "react";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";
import moment from "moment";

const Remark1Form = ({
  device_id,
  user_id,
  action_button,
  close,
  actionButton,
  userId,
  deviceId,
  loadData,
  closeRemark,
  loadLoanData,
  pageType,
}) => {
  // const [loading, setLoading] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const onFinish = (data) => {
    setSubmitBtnLoading(true);
    data.followup_ts = moment(data?.followup_ts).format("YYYY-MM-DD HH:mm:ss");
    const dataRemark =
      actionButton === "3"
        ? {
            ...data,
            user_id: userId,
            device_id: deviceId,
            action_button: actionButton,
          }
        : { ...data, user_id, device_id, action_button };
    const endPoint =
      actionButton === "3" ? "device/add-follow-up" : "loan/take-action";
    getAPIData(
      endPoint,
      "post",
      dataRemark,
      false,
      actionButton === "3" ? "device_change" : "loan"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          setSubmitBtnLoading(false);
          if (pageType === "loan") {
            loadLoanData();
          } else {
            loadData();
          }
          close();
          closeRemark();
        } else {
          message.warning(result?.message);
          setSubmitBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSubmitBtnLoading(false);
      });
  };

  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        form={form}
        requiredMark={false}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {actionButton === "3" ? (
              <Form.Item
                label="Follow Up Date"
                name="followup_ts"
                rules={[{ required: true, message: "Please select the Date!" }]}
                className="poppins_medium"
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  showTime={true}
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                  placeholder="Select follow up date"
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Follow Up Date"
                name="followup_ts"
                rules={[{ required: true, message: "Please select the Date!" }]}
                className="poppins_medium"
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  showTime={true}
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                  placeholder="Select follow up date"
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            {actionButton === "3" ? (
              <Form.Item
                label="Remarks"
                name="follow_up_comment"
                rules={[{ required: true, message: "Enter Reamrks!" }]}
                className="poppins_medium"
              >
                <TextArea
                  size="large"
                  style={{ width: "100%" }}
                  rows={5}
                  placeholder="Enter remarks"
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Remarks"
                name="followup_comment"
                rules={[{ required: true, message: "Enter Reamrks!" }]}
                className="poppins_medium"
              >
                <TextArea
                  size="large"
                  style={{ width: "100%" }}
                  rows={5}
                  placeholder="Enter remarks"
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SUBMIT"
                htmlType="submit"
                className="umoney_btn poppins_medium"
                style={{ width: "100%" }}
                loading={submitBtnLoading}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Remark1Form;
