import React, { useState } from "react";
import { Col, Row, Upload, Form, message, Button } from "antd";
import ImgCrop from "antd-img-crop";
import CSelect from "../../../InputFields/CSelect";
import CLoadingButton from "../../../InputFields/CLoadingButton";
import {
  api_url,
  credit_check_url,
  salaried_url,
  setHeaderForFile,
  student_url,
  stdtosal_url,
} from "../../../../api";
import axios from "axios";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getAPIData } from "../../../../apiHelper";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";

const AddDocumentAndComment = (props) => {
  const location = useLocation();
  const pathName = location?.pathname.split("/");
  // const user_id = pathName[pathName.length - 1];
  const user_id = props?.user_id;
  console.log("user_id", user_id);
  const pageType = props?.pageType;
  console.log("pageType", pageType);

  const [formDoc] = Form.useForm();

  const onFinish = async (data) => {
    setSubmitBtnLoading(true);
    console.log("success =====>>>>> ", data);

    const endpoint =
      pageType === "salaried"
        ? salaried_url + "steps/agent-upload-file"
        : pageType === "credit-check-detail"
        ? credit_check_url + "cc-steps/agent-upload-file"
        : pageType === "std-to-sal"
        ? stdtosal_url + "stos-steps/agent-upload-file"
        : student_url + "steps/agent-upload-file";
    const header = setHeaderForFile();

    const form = new FormData();
    if (!_.isEmpty(file?.[0]?.originFileObj)) {
      if (file?.[0]) {
        form.append("frontImg", file?.[0]?.originFileObj);
      }
      form.append("image_type", data?.document_type);
      form.append("description", data?.comment);
      form.append("user_id", user_id);
      const uploadData = await axios.post(endpoint, form, header);
      console.log("uploadData", uploadData);
      if (uploadData?.data?.status) {
        setSubmitBtnLoading(false);
        message.success(uploadData?.data?.message);
        setFile([]);
        formDoc.resetFields();
        props?.setModel("");
        props?.load();
      } else {
        message.warning(uploadData?.data?.message);
        setSubmitBtnLoading(false);
      }
    } else {
      message.warning("Please Upload image");
      setSubmitBtnLoading(false);
    }
    setSubmitBtnLoading(false);
  };

  const [file, setFile] = useState([]);

  // const [imageUrl, setImageUrl] = useState();
  const [fileType, setFileType] = useState("");

  const [documentType, setDocumentType] = useState();
  const [documentList, setDocumentList] = useState();
  const [submitBtnLoading, setSubmitBtnLoading] = useState();

  console.log("DrpData", documentType);

  console.log("file", file);

  const DocumentTypeData = () => {
    const endpoint = "system-config/document-type/active-list";
    let cat = [];
    getAPIData(endpoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setDocumentType(response);
          response.map((data) =>
            cat.push({
              label: data?.title,
              value: data?.id,
              text: data?.title,
            })
          );
          setDocumentList(cat);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    DocumentTypeData();
    formDoc.resetFields();
    setFile([]);
  }, [props]);
  console.log("file", file);

  const beforeUpload = async (file) => {
    try {
      const res = await checkImage(file);
      if (res?.status) {
        setFile(file);
        return true;
      } else {
        message.warning(res?.message);
      }
    } catch (error) {
      return { status: false, message: error?.message, code: 500 };
    }
  };

  const checkImage = async (file) => {
    const isJpgOrPng =
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg";
    let status = true;
    let message = "";
    if (!isJpgOrPng) {
      message = "You can only upload JPG/PNG/JPEG file!";
      status = false;
    }

    return { message, status };
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={(error) => {
          console.log("errors =====>>>>> ", error);
        }}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={formDoc}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Document type"
              name="document_type"
              size="large"
              rules={[
                { required: true, message: "Document type is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <CSelect
                label="Document type"
                data={documentList}
                size="large"
                onChange={(val) => setFileType(val)}
                classes="poppins_regular filter_select_border"
                placeholder="Select document type"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            {/* <Form.Item
              // label="Upload document"
              name="upload_document"
              valuePropName="fileList"
              // size="large"
              // rules={[{ required: true, message: "Document is required!" }]}
              className="poppins_medium mb-35"
            > */}
            {/* <ImgCrop rotate>
              <Upload
                action={"http://localhost:3000/"}
                listType="picture-card"
                maxCount={1}
                fileList={file}
                beforeUpload={beforeUpload}
                accept="image/jpeg,image/png,image/jpg"
                onChange={(file) => {
                  setFile(file.fileList);
                }}
              >
                {file?.length >= 1 ? null : (
                  <div>
                    <PlusOutlined
                      style={{
                        fontSize: 24,
                        color: "#009d93",
                      }}
                    />

                    <div
                      style={{
                        marginTop: 8,
                        width: "100%",
                        color: "#009d93",
                      }}
                    >
                      Add New
                    </div>
                  </div>
                )}
              </Upload>
            </ImgCrop> */}
            <Upload
              listType="picture"
              fileList={file}
              className="upload-list-inline"
              onChange={(file) => {
                setFile(file.fileList);
              }}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />} style={{ width: "475px" }}>
                Upload
              </Button>
            </Upload>
            {/* </Form.Item> */}
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Comment"
              name="comment"
              size="large"
              rules={[{ required: true, message: "comment is required!" }]}
              className="poppins_medium mb-35"
              block
            >
              <TextArea rows={4} placeholder="Enter comment" size="large" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SUBMIT"
                className="poppins_medium umoney_btn"
                block
                htmlType="submit"
                loading={submitBtnLoading}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddDocumentAndComment;
