import React, { useState, useEffect, useRef } from "react";
import { Content } from "antd/lib/layout/layout";
import {
  Typography,
  Table,
  Row,
  Col,
  message,
  Input,
  Button,
  Space,
} from "antd";
import CClickLoading from "../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import { getAPIData } from "../../apiHelper";
import Sidebar from "../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  MenuOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import RoleManagementModals from "../../components/Modals/RoleManagementModals";
import Highlighter from "react-highlight-words";
import _ from "lodash";

const RoleManagement = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;
  const [tableData, setTableData] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  //For update
  const [roleData, setRoleData] = useState([]);

  const scroll = {};

  //Opening Modal
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const openModal = (type, id) => {
    if (type === "add-role") {
      setModalType(type);
      setModalOpen(true);
    } else if (type === "edit-role") {
      loadEditRoleData(id);
      setModalType(type);
      setModalOpen(true);
    }
  };

  const loadRoleData = () => {
    const endPoint = "user-agent/role-list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadEditRoleData = (id) => {
    //setYesbtnLoading(true)
    const endPoint = "user-agent/get-role/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        //setYesbtnLoading(false)
        if (result?.status === true) {
          var response = result?.data;
          setRoleData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    if (_.isEmpty(tableData)) {
      loadRoleData();
    }
  }, [tableData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const tableColumns = [
    {
      title: "ROLE ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      sorter: (a, b) => a.id - b.id,
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
    },
    {
      title: "ROLE NAME",
      dataIndex: "name",
      key: "name",
      width: "20%",
      filteredValue: filteredInfo.name || null,
      ...getColumnSearchProps("name"),
    },
    {
      title: "ROLE TYPE",
      dataIndex: "type",
      key: "type",
      width: "20%",
      // ...getColumnSearchProps("type"),
      filteredValue: filteredInfo.type || null,
      render: (text, record) => (
        <>
          {record?.type === "1" ? <span>App</span> : <span>Admin Panel</span>}
        </>
      ),
      filters: [
        { value: "1", label: "App", text: "App" },
        { value: "2", label: "Admin Panel", text: "Admin Panel" },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.type.includes(value),
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      filteredValue: filteredInfo.createdAt || null,
    },
    {
      title: "UPDATE AT",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "20%",
      filteredValue: filteredInfo.updated || null,
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      width: "10%",
      filteredValue: filteredInfo.actions || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-role", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          {/* <Layout className="site-layout"> */}
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              backgroundColor: "#009D93",
              borderRadius: "15px 0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Super Admin Dashboard
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: "20px 15px",
              borderRadius: "5px",
            }}
          >
            <div className="white_div">
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={8}></Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="CLEAR FILTERS"
                    className="poppins_medium umoney_btn"
                    onClick={() => clearFilters()}
                    block
                  />
                </Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="ADD NEW ROLE"
                    className="poppins_medium umoney_btn"
                    icon={<PlusOutlined className="btn-icon" />}
                    onClick={() => openModal("add-role")}
                    block
                  />
                </Col>
              </Row>

              <div className="umoney_table mt-20">
                <Table
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                  columns={tableColumns}
                  dataSource={tableData}
                  scroll={scroll}
                  bordered
                  onChange={handleChange}
                />
              </div>
            </div>
          </Content>
          {/* </Layout> */}
        </Layout>
        <RoleManagementModals
          open={modalOpen}
          setOpen={setModalOpen}
          title={modalType === "add-role" ? "Add Role" : "Edit Role"}
          modalType={modalType}
          roleData={roleData}
          onFinishApi={(val) => {
            loadRoleData();
          }}
        />
      </div>
    </>
  );
};

export default RoleManagement;
