/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { Modal, Form, Col, Row, Select, Input, message } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import { useEffect } from "react";
import _ from "lodash";
import { getAPIData } from "../../apiHelper";

const UserManagementModals = (props) => {
  const {
    open,
    title,
    setOpen,
    modalType,
    userData,
    onFinishApi = () => {},
    editRoleVal,
    editManagerVal,
  } = props;

  const id = userData?.user_id;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showManeger, setShowManager] = useState(false);
  const [editManagerShow, setEditManagerShow] = useState(false);
  const [isEmailChange, setIsEmailChange] = useState(false);
  const [isPhoneChange, setIsPhoneChange] = useState(false);
  const [isIpChange, setIsIpChange] = useState(false);

  // Departments Data && Roles Data && Manager Data
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesVal, setRolesVal] = useState([]);
  const [manager, setManager] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [supervisorRoleValues, setSupervisorRoleValues] = useState([]);

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinish = (data) => {
    setLoading(true);
    const endPoint =
      modalType === "add-user"
        ? "user-agent/create"
        : "user-agent/update/" + id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          onFinishApi(result?.status);
          setOpen(false);
          setIsEmailChange(false);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const loadCommonData = () => {
    const endPoint = "user-agent/common-data";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;

          setDepartments(response?.departments);

          var role = response?.roles;
          let array = [];
          for (let i = 0; i < role.length; i++) {
            if (i >= 2) {
              array.push({
                value: role[i]?.id,
                title: role[i]?.name,
                code: role[i]?.code,
              });
            }
          }
          setRoles(array);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const handleDepartment = (department_id) => {
    form.setFieldValue("role_id", "");
    setDepartmentId(department_id);
    form.setFieldsValue({ role_id: "", manager_id: "" });
    setEditManagerShow(false);
    setShow(false);

    let endPoint = `user-agent/department-selection`;
    const data = { department_id: department_id };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        const roleArray = [];
        const supervisorRolesId = [];

        departments.map((dept) => {
          if (department_id === dept?.id) {
            roles.map((role) => {
              if (result?.status === true) {
                setShow(true);
                // setManager(result?.data?.users);
                if (role?.code === "supervisor_approver") {
                  roleArray.push({
                    id: role?.value,
                    name: role?.title,
                  });
                  supervisorRolesId.push(role?.value);
                }
                if (role?.code === "supervisor_caller" && dept?.id != "8") {
                  roleArray.push({
                    id: role?.value,
                    name: role?.title,
                  });
                  supervisorRolesId.push(role?.value);
                }
                if (dept?.have_approver === "1" && role?.code === "approver") {
                  roleArray.push({ id: role?.value, name: role?.title });
                }
                if (dept?.have_caller === "1" && role?.code === "caller") {
                  roleArray.push({ id: role?.value, name: role?.title });
                }
              } else {
                setShow(false);
                if (role?.code === "supervisor_approver") {
                  roleArray.push({
                    id: role?.value,
                    name: role?.title,
                  });
                  supervisorRolesId.push(role?.value);
                }
                if (role?.title === "supervisor_caller" && dept?.id != "8") {
                  roleArray.push({
                    id: role?.value,
                    name: role?.title,
                  });
                  supervisorRolesId.push(role?.value);
                }
              }
            });
          }
          setRolesVal(roleArray);
          setSupervisorRoleValues(supervisorRolesId);
        });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  /*On change of Role */
  const handleRole = (role_id) => {
    if (supervisorRoleValues.includes(role_id)) {
      setShowManager(false);
    } else {
      roles.map((role) => {
        if (role?.value === role_id) {
          if (
            show &&
            (role?.code !== "supervisor_approver" ||
              role?.code !== "supervisor_caller")
          ) {
            getManagetList(departmentId, role_id);
          }
        }
      });
    }
  };

  /* Get the Managee List */
  const getManagetList = (data, role_id) => {
    const endPoint = "user-agent/get-department-data/";
    const managerData = { dep_id: data, role_id: role_id };
    getAPIData(endPoint, "post", managerData, false)
      .then((result) => {
        if (result?.status === true) {
          let resp_data = result?.data?.users;
          let manager_data = [];
          resp_data.map((manager) => {
            const firstName = !_.isNull(manager.first_name)
              ? manager.first_name + " "
              : "";
            const middleName = !_.isNull(manager.middle_name)
              ? manager.middle_name + " "
              : "";
            const lastName = !_.isNull(manager.last_name)
              ? manager.last_name
              : "";
            manager_data.push({
              value: manager.user_id,
              label: firstName + middleName + lastName,
            });
          });

          setShowManager(true);
          setManager(manager_data);
        } else {
          setShowManager(false);
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
    //setLoading(true);
  };

  async function emailCheck(value) {
    return new Promise(async (resolve, reject) => {
      try {
        const endPoint = "user-agent/email-or-phone-exist/";
        const data = { email: value };

        await getAPIData(endPoint, "post", data, false)
          .then(async (result) => {
            if (modalType === "edit-user" && userData?.email === value) {
              console.log("Email========>", userData?.email);
              return resolve(false);
            } else {
              return resolve(result.status);
            }
          })
          .catch((err) => {
            message.error(err);
          });
      } catch (error) {
        message.error(error);
      }
    });
  }

  async function phoneCheck(value) {
    return new Promise(async (resolve, reject) => {
      console.log("Value=======>", value);
      try {
        const endPoint = "user-agent/email-or-phone-exist/";
        const data = { phone_number: value };
        await getAPIData(endPoint, "post", data, false)
          .then(async (result) => {
            if (modalType === "edit-user" && userData?.phone_number === value) {
              return resolve(false);
            } else {
              return resolve(result.status);
            }
          })
          .catch((err) => {
            message.error(err);
          });
      } catch (error) {
        message.error(error);
      }
    });
  }

  async function ipCheck(value) {
    return new Promise(async (resolve, reject) => {
      console.log("Value=======>", value);
      try {
        const endPoint = "user-agent/ipaddress-exist/";
        const data = { registration_ip: value };
        await getAPIData(endPoint, "post", data, false)
          .then(async (result) => {
            if (
              modalType === "edit-user" &&
              userData?.registration_ip === value
            ) {
              return resolve(false);
            } else {
              return resolve(result.status);
            }
          })
          .catch((err) => {
            message.error(err);
          });
      } catch (error) {
        message.error(error);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    if (!_.isEmpty(userData) && modalType === "edit-user") {
      form.setFieldsValue(userData);
    }

    if (!_.isEmpty(userData) && modalType === "add-user") {
      form.resetFields();
    }

    if (!_.isEmpty(userData) && userData.manager_id != null) {
      setEditManagerShow(true);
    } else {
      setEditManagerShow(false);
    }

    loadCommonData();
  }, [form, modalType, userData]);

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        footer={null}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={900}
      >
        {modalType === "add-user" || modalType === "edit-user" ? (
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="First name"
                  name="first_name"
                  rules={[
                    { required: true, message: "First name is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      max: 25,
                      message: "First name should be less than 25 character",
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                      ),
                      message: "First name only contain alphabets",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter first name" />
                </Form.Item>
              </Col>

              {/* <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Middle Name"
                  name="middle_name"
                  rules={[
                    { required: true, message: "Middle name is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      max: 25,
                      message: "Middle name should be less than 25 character",
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                      ),
                      message: "Middle name only contain alphabets",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter Middle name" />
                </Form.Item>
              </Col> */}

              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Last name"
                  name="last_name"
                  rules={[
                    { required: true, message: "Last name is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      max: 25,
                      message: "Last name should be less than 25 character",
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                      ),
                      message: "Last name only contain alphabets",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter last name" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Email is required!" },
                    {
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Please enter valid email address.",
                    },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    () => ({
                      async validator(rule, value) {
                        setIsEmailChange(true);
                        const check = await emailCheck(value);

                        if (
                          !check ||
                          (!isEmailChange && modalType === "edit-user")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Email already exists!");
                      },
                    }),
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    placeholder="Enter email"
                    // onChange={emailCheck}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Phone number"
                  name="phone_number"
                  rules={[
                    { required: true, message: "Phone number is required!" },
                    {
                      pattern: /^[0-9]\d*(\.\d+)?$/g,
                      message: "Phone number contains only number.",
                    },
                    {
                      max: 10,
                      message: "Phone number contains maximum 10 digits.",
                    },
                    () => ({
                      async validator(rule, value) {
                        const firstDigit = String(value)[0];
                        if (!_.isUndefined(firstDigit) && firstDigit <= 5) {
                          return Promise.reject(
                            "Phone number must be start with 6 to 9 digits only."
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                    () => ({
                      async validator(rule, value) {
                        setIsPhoneChange(true);
                        const check = await phoneCheck(value);
                        if (
                          !check ||
                          (!isPhoneChange && modalType === "edit-user")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Phone Number already exists!");
                      },
                    }),
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter phone number" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="IP Address"
                  name="registration_ip"
                  rules={[
                    { required: true, message: "IP Address is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    () => ({
                      async validator(rule, value) {
                        setIsIpChange(true);
                        const check = await ipCheck(value);

                        if (
                          !check ||
                          (!isIpChange && modalType === "edit-user")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("IP Address already exists!");
                      },
                    }),
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter IP address" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Username is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter username" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                {modalType === "edit-user" ? (
                  ""
                ) : (
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      { required: true, message: "Password is required!" },
                      {
                        min: 6,
                        message: "Password at least minimum 6 characters.",
                      },
                      {
                        whitespace: true,
                        message: "This field can not contain whitespaces.",
                      },
                    ]}
                    className="poppins_medium mb-35"
                  >
                    <Input.Password size="large" placeholder="Enter password" />
                  </Form.Item>
                )}
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Agent Dialer Id"
                  name="agent_dialer_id"
                  rules={[
                    { required: true, message: "Agent dialer id is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter agent dialer id" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Department"
                  name="department_id"
                  size="large"
                  rules={[
                    { required: true, message: "Department is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      handleDepartment(value);
                    }}
                    options={
                      !_.isEmpty(departments) &&
                      departments.map((province) => ({
                        label: province.title,
                        value: province.id,
                      }))
                    }
                    placeholder="Select department"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Role"
                  name="role_id"
                  rules={[{ required: true, message: "Role is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    onChange={(value) => {
                      handleRole(value);
                    }}
                    options={
                      modalType === "edit-user"
                        ? !_.isEmpty(editRoleVal) &&
                          editRoleVal.map((province) => ({
                            value: province.id,
                            label: province.name,
                          }))
                        : !_.isEmpty(rolesVal) &&
                          rolesVal.map((province) => ({
                            value: province.id,
                            label: province.name,
                          }))
                    }
                    style={{ width: "100%" }}
                    placeholder="Select role"
                  />
                </Form.Item>
              </Col>

              {editManagerShow || (show && showManeger) ? (
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    label="Manager"
                    name="manager_id"
                    key="id"
                    rules={[
                      { required: true, message: "Manager is required!" },
                    ]}
                    className="poppins_medium mb-35"
                  >
                    <Select
                      className="br"
                      size="large"
                      options={
                        modalType === "edit-user"
                          ? !_.isEmpty(editManagerVal)
                            ? editManagerVal.map((manager) => ({
                                value: manager?.user_id,
                                label:
                                  manager?.first_name +
                                  " " +
                                  manager?.last_name,
                              }))
                            : manager
                          : manager
                      }
                      style={{ width: "100%" }}
                      placeholder="Select manager"
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col className="gutter-row" span={8}></Col>
              )}
              <Col className="gutter-row" span={8}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={loading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default UserManagementModals;
