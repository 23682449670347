import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Table,
  message,
  Input,
  Button,
  Space,
} from "antd";
import Sidebar from "../../../components/Sidebar";
import {
  MenuOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import EarnRewardsCoinsModal from "../../../components/Modals/EarnRewardsCoinsModal";
import CommonModals from "../../../components/Modals/CommonModals";
import Highlighter from "react-highlight-words";
import CClickLoading from "../../../components/InputFields/CClickLoading";

const { Title } = Typography;
const { Header, Content } = Layout;

const EarnRewardsCoins = () => {
  const [open, setOpen] = useState(false);
  const [tableListData, setTableListData] = useState([]);
  const [coinId, setCoinId] = useState(0);
  const [loading, setLoading] = useState(false);

  //Modal States
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [deleteId, setDeleteId] = useState(false);

  //Search Table
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const openModal = (type, id) => {
    if (type === "add-coins") {
      setModalType(type);
      setModalOpen(true);
    } else if (type === "edit-coins") {
      setCoinId(id);
      setModalType(type);
      setModalOpen(true);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalOpen(true);
    }
  };

  useEffect(() => {
    loadTableData();
  }, []);

  // Search Functionality START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // Search Functionality END

  const loadTableData = () => {
    setLoading(true);
    const endPoint = "system-config/earn_reward_coin/list";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableListData(response);
          setLoading(false);
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };

  const TableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id"),
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <div>
          <span>{record.title}</span>
        </div>
      ),
      ...getColumnSearchProps("title"),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "REDIRECTION PAGE",
      dataIndex: "redirection_page",
      key: "redirection_page",
      render: (text, record) => (
        <div>
          <span>{record?.redirection_page}</span>
        </div>
      ),
      ...getColumnSearchProps("redirection_page"),
    },
    {
      title: "SERIAL NUMBER",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text, record) => (
        <div>
          <span>{record.serial_number}</span>
        </div>
      ),
      sorter: (a, b) => a.serial_number - b.serial_number,
      ...getColumnSearchProps("serial_number"),
    },
    // {
    //   title: 'STATUS',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (text, record) => {
    //     return (
    //       <div>
    //         <span>
    //           <Switch
    //             checked={record.status === '1' ? true : false}
    //             onChange={() => {
    //               setValues(record, record?.id)
    //             }}
    //           />
    //         </span>
    //       </div>
    //     )
    //   },
    // },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                openModal("edit-coins", record?.id);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <Layout
      style={{
        height: "100%",
        padding: "20px 0 0 20px",
        backgroundColor: "#DFFEFC",
      }}
    >
      <Sidebar open={open} setOpen={setOpen} />
      <Header className="site-layout-background main-header">
        <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
        <Title level={4} className="poppins_regular umoney_page_title">
          Earned Reward Coins
        </Title>
      </Header>
      <Content
        className="site-layout-background white_div"
        style={{
          padding: "20px 15px",
          borderRadius: "5px",
        }}
      >
        <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
          <Col className="gutter-row" span={16}></Col>
          <Col className="gutter-row" span={8}>
            <CLoadingButton
              size="large"
              value="ADD COINS"
              icon={<PlusOutlined />}
              className="poppins_medium umoney_btn"
              onClick={() => openModal("add-coins")}
              block
            />
          </Col>
        </Row>

        <div className="umoney_table " style={{ marginTop: 20 }}>
          <Table
            pagination={{
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            columns={TableColumns}
            dataSource={tableListData}
            // scroll={scroll}
          />
        </div>
      </Content>

      {modalType === "add-coins" || modalType === "edit-coins" ? (
        <EarnRewardsCoinsModal
          open={modalOpen}
          title={modalType === "add-coins" ? "Add Coins" : "Edit-Coins"}
          setOpen={setModalOpen}
          modalType={modalType}
          coinId={coinId}
          setModalType={setModalType}
          onSave={() => {
            setModalType(false);
            loadTableData();
          }}
        />
      ) : null}

      {modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          title="Delete Confirmation"
          type="earnRewardCoin"
          setOpen={setModalOpen}
          modalType={modalType}
          id={deleteId}
          setModalType={setModalType}
          onDelete={(val) => {
            loadTableData();
          }}
        />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default EarnRewardsCoins;
