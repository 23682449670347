import { Table } from "antd";
import React from "react";

const CallHistoryLoanFilter = () => {
  const tableColumns = [
    {
      title: "NUMBER",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "CALL TYPE",
      dataIndex: "calltype",
      key: "calltype",
    },
    {
      title: "RESPONSE",
      dataIndex: "response",
      key: "response",
    },
    {
      title: "CALL DURATION",
      dataIndex: "callduration",
      key: "callduration",
    },
    {
      title: "AGENT DETAILS",
      dataIndex: "agentdetails",
      key: "agentdetails",
    },
    {
      title: "TIME STAMP",
      dataIndex: "timestamp",
      key: "timestamp",
    },
    {
      title: "REMARK",
      dataIndex: "remark",
      key: "remark",
    },
  ];

  const tableData = [];
  for (let i = 1; i <= 3; i++) {
    tableData.push({
      key: i,
      number: "1234567890",
      type: "registered",
      calltype: "dialer",
      response: "Connected",
      callduration: "04:06",
      agentdetails: "Piyush(CA7676)",
      timestamp: "10-12-2022 | 10:24 AM",
      remark: "lorem ipsum",
    });
  }

  return (
    <div>
      <Table dataSource={tableData} pagination={false} columns={tableColumns} />
    </div>
  );
};

export default CallHistoryLoanFilter;
