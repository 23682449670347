import React, { useState } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  Collapse,
  Form,
  Input,
  Select,
  Tag,
  Spin,
  message,
  Tabs,
  Button,
  Modal,
} from "antd";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import Images from "../../config/images";
import CClickLoading from "../../components/InputFields/CClickLoading";
import Sidebar from "../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined, EditOutlined } from "@ant-design/icons";
import { getAPIData } from "../../apiHelper";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { credit_check_url, setHeader } from "../../api";
import axios from "axios";
import UploadFiles from "../../components/Accordion/UploadFiles";
import CallHistory from "../../components/Modals/modal/CallHistory";
import ClickToCallModal from "../../components/Modals/modal/ClickToCallModal";

const { user } = Images;

const { Title } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;
const { Search } = Input;

const AdvanceCreditCheckDetailView = () => {
  const [form] = Form.useForm();
  const [smsForm] = Form.useForm();
  const [notificationForm] = Form.useForm();
  const [motherPhoneForm] = Form.useForm();
  const [fatherPhoneForm] = Form.useForm();
  const [otherPhoneForm] = Form.useForm();
  const [callStatusForm] = Form.useForm();
  const role = useSelector((state) => state.userData.accessRoleCode);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const m_num = location?.pathname.split("/");
  const paramname = m_num[1];
  const user_id = queryParameters.get("user_id");

  const pageType = "credit-check-detail";

  const [searchVal, setSearchVal] = useState("");
  const [top, setTop] = useState("none");
  const [bottom, setBottom] = useState("bottomLeft");
  const [loading, setLoading] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userRole, setUserRole] = useState();

  //User data
  const [userData, setUserData] = useState();
  const [userName, setUserName] = useState();
  const [motherData, setMotherData] = useState();

  const [selectedTab, setSelectedTab] = useState("");

  // send Notification, sms, whatsapp
  const [notiSendBtnLoading, setNotiSendBtnLoading] = useState(false);
  const [smsSendBtnLoading, setSmsSendBtnLoading] = useState(false);
  const [whatsappSendBtnLoading, setWhatsappSendBtnLoading] = useState(false);

  // Notification List api
  const [notificationData, setNotificationData] = useState([]);

  // SMS List Api
  const [smsData, setSmsData] = useState([]);

  // whatsapp list api
  const [whatsappData, setWhatsappData] = useState([]);

  //Table Loading
  const [notificationTableLoading, setNotificationTableLoading] =
    useState(true);
  const [smsTableLoading, setSmsTableLoading] = useState(true);
  const [whatsappTableLoading, setWhatsappTableLoading] = useState(true);

  //device list
  const [deviceList, setDeviceList] = useState();

  const [otherPhoneData, setOtherPhoneData] = useState();

  const [otherPhoneDataLength, setOtherPhoneDataLength] = useState();

  const [changeLoading, setChangeLoading] = useState(false);

  //btns loader
  const [fatherSaveLoading, setFatherSaveLoading] = useState(false);
  const [motherSaveLoading, setmotherSaveLoading] = useState(false);
  const [otherSaveLoading, setOtherSaveLoading] = useState(false);

  //call status
  const [callStatusSelectData, setCallStatusSelectData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [callStatusBtnLoading, setCallStatusBtnLoading] = useState(false);

  const [parentContactSaveLoading, setParentContactSaveLoading] =
    useState(false);

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [value, setValue] = useState();

  const [relationId, setRelationId] = useState();

  const [primaryDeviceList, setPrimaryDeviceList] = useState();
  const [primaryDeviceDrp, setPrimaryDeviceDrp] = useState();

  const [device_id, setDevice_id] = useState();
  const [disable, setDisable] = useState(true);

  //export contact
  const [isDownload, setIsDownload] = useState(false);

  //device list
  const [deviceListData, setDeviceListData] = useState([]);
  const [deviceListDrp, setDeviceListDrp] = useState([]);

  const [parentsTableLoading, setParentsTableLoading] = useState(false);
  const [deviceData, setDeviceData] = useState();

  // Search Device Log
  const [resultDevice, setResultDevice] = useState();

  const data = [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "Yiminghe",
      label: "yiminghe",
    },
  ];

  const MatchedOtherUserColumns = [
    {
      title: "NUMBER",
      dataIndex: "number",
      width: "10%",
    },
    {
      title: "RELATION WITH USER",
      dataIndex: "relation",
      width: "15%",
    },
    {
      title: "SAVED AS",
      dataIndex: "savedas",
      width: "15%",
    },
    {
      title: "PRIMARY USER",
      dataIndex: "primaryuser",
      width: "15%",
    },
    {
      title: "DEVICE ID",
      dataIndex: "deviceid",
      width: "15%",
    },
  ];
  const MatchedOtherUserData = [];
  for (let i = 0; i < 3; i++) {
    MatchedOtherUserData.push({
      key: i,
      number: "",
      relation: "",
      savedas: "",
      primaryuser: "",
      deviceid: "",
    });
  }
  //Other possible parent’s numbers in contact list
  const ContactlistColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      width: "15%",
    },
    {
      title: "DEVICE DETAILS",
      dataIndex: "dedvicedetail",
      width: "15%",
    },
    {
      title: "PROBABLE RELATION",
      dataIndex: "probablerelstion",
      width: "15%",
    },
    {
      title: "CALL LOG COUNT",
      dataIndex: "calllogcount",
      width: "15%",
    },
  ];
  const ContactlistData = [];
  for (let i = 0; i < 3; i++) {
    ContactlistData.push({
      key: i,
      name: "",
      number: "",
      dedvicedetail: "",
      probablerelstion: "",
      calllogcount: "",
    });
  }
  //Mobile number in all devices user has logged in from table
  const AllDevicesColumns = [
    {
      title: "DEVICE ID",
      dataIndex: "id",
      width: "10%",
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      width: "15%",
    },
    {
      title: "PRIMARY",
      dataIndex: "primary",
      width: "15%",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneno",
      width: "15%",
    },
    {
      title: "SAVED AS",
      dataIndex: "savedas",
      width: "15%",
    },
    {
      title: "CALL LOG COUNT",
      dataIndex: "calllogcount",
      width: "10%",
    },
    {
      title: "LAST CALL TIMESTAMP",
      dataIndex: "lastcalltime",
      width: "15%",
    },
  ];
  const AllDevicesData = [];
  for (let i = 0; i < 3; i++) {
    AllDevicesData.push({
      key: i,
      id: "",
      number: "",
      primary: "",
      phoneno: "",
      savedas: "",
      calllogcount: "",
      lastcalltime: "",
    });
  }

  //User provided info table
  const UserProvidedColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "RELATION",
      dataIndex: "relation_id",
      width: "10%",
      render: (text, record) => (
        <div>
          <span>
            {record?.relation_id === 1
              ? "Mother"
              : record?.relation_id === 2
              ? "Father"
              : "Other"}
          </span>
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status_id",
      width: "10%",
    },
    {
      title: "CONTACT NO.",
      dataIndex: "contact_no",
      width: "10%",
    },
    {
      title: "SAVED AS",
      dataIndex: "savedas",
      width: "10%",
    },
    {
      title: "IN CALL LOG",
      dataIndex: "incalllog",
      width: "10%",
    },
    {
      title: "CONTACT NO. AS PER RM",
      dataIndex: "contact_num_as_agent",
      width: "13%",
      render: (text, record) => (
        <div>
          <span>{record.contactnoasperrm}</span>
          <span style={{ marginLeft: 20 }}>
            <EditOutlined
              style={{
                fontSize: "25px",
                color: "#009D93",
                cursor: "pointer",
              }}
              onClick={() =>
                record?.relation_id === 1
                  ? openModal("mother", record?.relation_id)
                  : record?.relation_id === 2
                  ? openModal("father", record?.relation_id)
                  : openModal("other", record?.relation_id)
              }
            />
          </span>
        </div>
      ),
    },
    {
      title: "RECENT COMMENT",
      dataIndex: "comment",
      width: "11%",
    },
    {
      title: "HISTORY",
      dataIndex: "history",
      key: "history",
      width: "10%",
      render: () => (
        <span>
          <Tag
            className="tag-form"
            onClick={() => {
              openModal("HISTORY");
            }}
          >
            HISTORY
          </Tag>
        </span>
      ),
    },
    {
      title: "VALIDATED",
      dataIndex: "validated",
      width: "13%",
      render: (text, record) => (
        <span>
          {record?.validated === "1"
            ? "Yes"
            : record?.validated === "0"
            ? "No"
            : ""}
        </span>
      ),
    },
  ];

  //search result
  const searchColumns = [
    {
      title: "SAVED AS",
      dataIndex: "saveas",
      width: "15%",
    },
    {
      title: "PRIMARY USER",
      dataIndex: "primaryuser",
      width: "15%",
    },
    {
      title: "IS PRIMARY",
      dataIndex: "isprimary",
      width: "15%",
    },
    {
      title: "CALL COUNT",
      dataIndex: "callcount",
      width: "15%",
    },
    {
      title: "LINKED",
      dataIndex: "linked",
      width: "15%",
    },
  ];
  const searchData = [];
  for (let i = 0; i < 3; i++) {
    searchData.push({
      key: i,
      saveas: "",
      primaryuser: "",
      isprimary: "",
      callcount: "",
      linked: "",
    });
  }

  //Notification Table
  const notificationColumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "20%",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },

    {
      title: "COMMENT",
      dataIndex: "content",
      key: "content",
      width: "30%",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "17%",
    },
    {
      title: "SEEN",
      dataIndex: "read_ts",
      key: "read_ts",
      width: "17%",
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      width: "16%",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  // whatsapp Table
  const whatsappColumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "COMMENT",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  const onFinishFatherPhone = (data) => {
    console.log("success", data);
  };
  const onFinishMotherPhone = (data) => {
    console.log("success", data);
  };
  const onFinishOtherPhone = (data) => {
    console.log("success", data);
  };
  const onFinishOtherGmail = (data) => {
    console.log("success", data);
  };
  const onFinishOtherNach = (data) => {
    console.log("success", data);
  };
  const onFinishOtherUpi = (data) => {
    console.log("success", data);
  };

  const onFinishFailed = () => {
    console.log("Fail");
  };

  const moduleOptions = [
    {
      value: "1",
      label: "KYC Details",
    },
    {
      value: "2",
      label: "PAN Details",
    },
    {
      value: "3",
      label: "Personal Information Details",
    },
    {
      value: "4",
      label: "Student Information Details",
    },
    {
      value: "5",
      label: "Selfie Details",
    },
    {
      value: "6",
      label: "Video Details",
    },
  ];

  const smscolumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "20%",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "SMS CONTENT",
      dataIndex: "content",
      key: "content",
      width: "35%",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "DELIVERY STATUS",
      dataIndex: "delivery_status",
      key: "delivery_status",
      render: (text, record) => (
        <div>
          <span>
            {record.delivery_status === "1"
              ? "Sent"
              : record.delivery_status === "2"
              ? "Delivered"
              : record.delivery_status === "3"
              ? "Undelivered"
              : ""}
          </span>
        </div>
      ),
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      width: "16%",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  const openModal = (type, relation_id) => {
    if (type === "father") {
      setModalType(type);
      setModalTitle("Update details for father");
      setModalWidth(500);
      setRelationId(relation_id);
    } else if (type === "mother") {
      setModalType(type);
      setModalTitle("Update details for mother");
      setModalWidth(500);
      setRelationId(relation_id);
    } else if (type === "other") {
      setModalType(type);
      setModalTitle("Update details for other");
      setModalWidth(500);
      setRelationId(relation_id);
    } else if (type === "history") {
      setModalType(type);
      setModalTitle("History");
      setModalWidth(1000);
    } else if (type === "SMS") {
      setModalType(type);
      setModalTitle("");
      setModalWidth(1600);
    } else if (type === "clear-in-app") {
      setModalType(type);
      setModalTitle("Clear in App notification");
      setModalWidth(500);
    } else if (type === "view-change-log") {
      setModalType(type);
      setModalTitle("View Change Log");
      setModalWidth(1700);
    } else if (type === "call-status-log") {
      setModalType(type);
      setModalTitle("Call Status Log");
      setModalWidth(1500);
    } else if (type === "click-to-call") {
      setModalType(type);
      setModalTitle("Click to call");
      setModalWidth(500);
    } else if (type === "call-history") {
      setModalTitle("Call History");
      setModalWidth(1000);
      setModalType("call-history");
    } else if (type === "phone-permission") {
      setModalType(type);
      setModalTitle("Phone Permission");
      setModalWidth(1200);
    } else if (type === "algo") {
      setModalType(type);
      setModalTitle("Algo");
      setModalWidth(900);
    } else if (type === "linked-user") {
      setModalType(type);
      setModalTitle("Target device linked users list");
      setModalWidth(900);
    }
  };

  const handleClose = () => {
    setModalType("");
  };

  useEffect(() => {
    loadUserData();
    loadNotificationListData();
    loadsmsListData();
    loadWhatsappListData();
    loadDeviceList();
    callStatusData();
    loadDeviceListData();
  }, []);

  //Load Call Status Drp List
  const callStatusData = () => {
    const endpoint = "cc-student/get-dispositions";
    let cat = [];
    getAPIData(endpoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCallStatusSelectData(response);
          response.map((data) =>
            cat.push({
              label: data?.title,
              value: data?.id,
              text: data?.title,
            })
          );
          setCategoryData(cat);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const changeCategory = (e) => {
    callStatusForm.setFieldValue("sub_category", "");
    callStatusForm.setFieldValue("sub_sub_category", "");
    const subs = callStatusSelectData.filter((cat) => cat.id === e);
    let subd = [];
    subs.map((subCat) =>
      subCat.stu_onb_2.map((sub) =>
        subd.push({
          value: sub.id,
          label: sub.title,
          text: sub.title,
        })
      )
    );
    setSubCategoryData(subd);
  };

  const changeSubCategory = (e) => {
    callStatusForm.setFieldValue("sub_sub_category", "");
    setSelectedSubCat(e);
    callStatusSelectData.map((subcat) => {
      const subsubcat = subcat.stu_onb_2.filter((cat) => cat.id === e);
      subsubcat.map((sub) => {
        let subsubdata = [];
        sub.stu_onb_3.map((val) => {
          subsubdata.push({
            value: val.id,
            label: val.title,
            text: val.title,
          });
        });
        setSubSubCategoryData(subsubdata);
      });
    });
  };

  // Call status submit api
  const onFinishCallStatus = (data) => {
    setCallStatusBtnLoading(true);
    setSpinLoading(true);
    const dataCallStatus = { ...data, user_id: user_id, user_role: userRole };
    const endPoint = "cc-student/add-disposition";
    getAPIData(endPoint, "post", dataCallStatus, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          callStatusForm.resetFields();
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setCallStatusBtnLoading(false);
        setSpinLoading(false);
      });
  };

  useEffect(() => {
    if (changeLoading) {
      loadUserData();
    }
    setChangeLoading(false);
  }, [changeLoading]);

  const loadUserData = () => {
    setLoading(true);
    setSpinLoading(true);
    const endPoint = `cc-advance/approver-student-data/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setUserData(response);
          setLoading(false);
          setSpinLoading(false);
          setUserName(response?.userData?.userName);
          setOtherPhoneDataLength(response?.other?.length);
          setUserRole(response?.userData?.userType);
          form.setFieldsValue({
            action_date: response?.actionData?.action_date,
          });

          let father;
          response?.father.map((data) => {
            father = data;
          });

          fatherPhoneForm.setFieldsValue({
            father_name: father?.father_name,
            father_contact_no: father?.father_contact_no,
            father_comment: father?.father_comment,
            father_verify_ts: father?.father_verify_ts,
          });

          let mother;
          response?.mother.map((data) => {
            mother = data;
          });

          motherPhoneForm.setFieldsValue({
            mother_name: mother?.mother_name,
            mother_contact_no: mother?.mother_contact_no,
            mother_comment: mother?.mother_comment,
            mother_verify_ts: mother?.mother_verify_ts,
          });

          setOtherPhoneData(response?.other);
        } else {
          message.warning(result?.message);
          setLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setSpinLoading(false);
        message.error(err);
      });
  };

  const loadDeviceList = () => {
    const endPoint = `cc-advance/device-list-by-userid`;
    const data = { user_id: user_id };
    let arr = [];
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.device_id,
              label: data?.device_model,
            });
          });
          setDeviceList(arr);
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const getSaveInDataFather = (e) => {
    const endPoint = `cc-advance/get-save-in-name`;
    const data = {
      user_id: user_id,
      device_id: e,
      contact_no: [fatherPhoneForm.getFieldValue("father_contact_no")],
    };
    let arr = [];
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          let fathername;
          response?.map((data) => {
            fathername = data?.name_in_phone;
          });
          fatherPhoneForm.setFieldValue("save_in", fathername);
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };
  const getSaveInDataMother = (e) => {
    const endPoint = `cc-advance/get-save-in-name`;
    const data = {
      user_id: user_id,
      device_id: e,
      contact_no: [motherPhoneForm.getFieldValue("mother_contact_no")],
    };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          let motherName;
          response?.map((data) => {
            motherName = data?.name_in_phone;
          });
          motherPhoneForm.setFieldValue("save_in", motherName);
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const getSaveInDataOther = (e) => {
    const endPoint = `cc-advance/get-save-in-name`;

    let numbers = [];
    otherPhoneData?.map((data) => {
      numbers.push(data.other_contact_no);
    });

    const data = {
      user_id: user_id,
      device_id: e,
      contact_no: numbers,
    };

    let arr = [];
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;

          for (let i = 0; i < response.length; i++) {
            const saveInData = response[i];
            const fieldName = `save_in_${i}`;
            otherPhoneForm.setFieldValue(fieldName, saveInData?.name_in_phone);
          }
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const motherPhoneSave = () => {
    setSpinLoading(true);
    setmotherSaveLoading(true);
    const endPoint = `cc-advance/save-agent-comments`;
    const data = {
      user_id: user_id,
      commentData: [
        {
          contact_no: motherPhoneForm.getFieldValue("mother_contact_no"),
          comment: motherPhoneForm.getFieldValue("mother_comment"),
        },
      ],
    };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message?.success(result?.message);
          setChangeLoading(true);
          setSpinLoading(false);
          setmotherSaveLoading(false);
        } else {
          message?.warning(result?.message);
          setSpinLoading(false);
          setmotherSaveLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setSpinLoading(false);
        setmotherSaveLoading(false);
      });
  };
  const fatherPhoneSave = () => {
    setSpinLoading(true);
    setFatherSaveLoading(true);
    const endPoint = `cc-advance/save-agent-comments`;
    const data = {
      user_id: user_id,
      commentData: [
        {
          contact_no: fatherPhoneForm.getFieldValue("father_contact_no"),
          comment: fatherPhoneForm.getFieldValue("father_comment"),
        },
      ],
    };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          message?.success(result?.message);
          setChangeLoading(true);
          setSpinLoading(false);
          setFatherSaveLoading(false);
        } else {
          message?.warning(result?.message);
          setSpinLoading(false);
          setFatherSaveLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setSpinLoading(false);
        setFatherSaveLoading(false);
      });
  };
  const otherPhoneSave = () => {
    setSpinLoading(true);
    setOtherSaveLoading(true);
    const endPoint = `cc-advance/save-agent-comments`;

    let numbers = [];
    for (let i = 0; i < otherPhoneData.length; i++) {
      const fieldName = `other_comment${i}`;
      const numberData = otherPhoneForm.getFieldValue(fieldName);
      const commentData = otherPhoneForm.getFieldValue(fieldName);
      numbers.push({
        contact_no: numberData,
        comment: commentData,
      });
    }
    const data = {
      user_id: user_id,
      commentData: numbers,
    };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          message?.success(result?.message);
          setChangeLoading(true);
          setSpinLoading(false);
          setOtherSaveLoading(false);
        } else {
          message?.warning(result?.message);
          setSpinLoading(false);
          setOtherSaveLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setSpinLoading(false);
        setOtherSaveLoading(false);
      });
  };

  const getTabValue = (value) => {
    if (value === "1") {
      setSelectedTab("Notification");
      smsForm.resetFields();
      form.resetFields();
    } else if (value === "2") {
      setSelectedTab("SMS");
      notificationForm.resetFields();
      form.resetFields();
    } else if (value === "3") {
      setSelectedTab("WHATSAPP");
      smsForm.resetFields();
      notificationForm.resetFields();
    }
  };

  // notification list api
  const loadNotificationListData = () => {
    setNotificationTableLoading(true);
    const endPoint = `cc-student/notification-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setNotificationData(response);
            setNotificationTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setNotificationTableLoading(false);
        }
        setNotificationTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setNotificationTableLoading(false);
      });
  };

  //notification drp api
  const loadNotificationModuleData = (value) => {
    const data = {
      type:
        selectedTab === "SMS"
          ? "sms"
          : selectedTab === "WHATSAPP"
          ? "whatsapp"
          : "notification",
      module: value,
    };
    const endPoint = "cc-student/notification-get-one";
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("sms_temp_id", response?.id);
            smsForm.setFieldValue("comment", response?.message_body);
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("whatsapp_temp_id", response?.id);
            form.setFieldValue("comment", response?.message_body);
          } else {
            notificationForm.setFieldValue(
              "notification_temp_id",
              response?.id
            );
            notificationForm.setFieldValue("comment", response?.message_body);
          }
        } else {
          message.warning(result?.message);
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("comment", "");
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("comment", "");
          } else {
            notificationForm.setFieldValue("comment", "");
          }
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  //send notification
  const onFinishNotification = (data) => {
    setNotiSendBtnLoading(true);
    setSpinLoading(true);
    console.log("success =====>>>>> ", data);

    const nData = {
      user_id: user_id,
      notification_temp_id: data?.notification_temp_id,
      comment: data?.comment,
    };

    const endPoint = "cc-student/notificaton-send";
    getAPIData(endPoint, "post", nData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          notificationForm.resetFields();
          loadNotificationListData();
          setNotiSendBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setNotiSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setNotiSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  //SMS List Api
  const loadsmsListData = () => {
    setSmsTableLoading(false);
    const endPoint = `cc-student/get-sent-sms-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          // console.log(".then ⋙---------> response", response);
          if (!_.isEmpty(response) && _.isArray(response)) {
            setSmsData(response);
            setSmsTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setSmsTableLoading(false);
        }
        setSmsTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSmsTableLoading(false);
      });
  };

  // send Sms
  const onFinishSms = (data) => {
    setSmsSendBtnLoading(true);
    setSpinLoading(true);
    console.log("success =====>>>>> ", data);

    const nData = {
      user_id: user_id,
      sms_temp_id: data?.sms_temp_id,
      comment: data?.comment,
    };

    const endPoint = "cc-student/send-sms";
    getAPIData(endPoint, "post", nData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          console.log("msg==========>", result.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
          smsForm.resetFields();
          loadsmsListData();
        } else {
          message.warning(result?.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSmsSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  // Send Whatsapp
  const onFinishWhatsapp = (data) => {
    console.log("success =====>>>>> ", data);
    setWhatsappSendBtnLoading(true);
    setSpinLoading(true);

    const nData = {
      user_id: user_id,
      whatsapp_temp_id: data?.whatsapp_temp_id,
      comment: data?.comment,
    };

    const endPoint = "cc-student/send-whatsapp";
    getAPIData(endPoint, "post", nData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          setWhatsappSendBtnLoading(false);
          setSpinLoading(false);
          loadWhatsappListData();
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setWhatsappSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setWhatsappSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  //whatsapp List Api
  const loadWhatsappListData = () => {
    setWhatsappTableLoading(false);
    const endPoint = `cc-student/whatsapp-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setWhatsappData(response);
            setWhatsappTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setWhatsappTableLoading(false);
        }
        setWhatsappTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setWhatsappTableLoading(false);
      });
  };

  const parentContactSave = async () => {
    setParentContactSaveLoading(true);
    setSpinLoading(true);
    const header = setHeader();
    const endpoint = credit_check_url + "cc-steps/save-parents-contact";
    const data = {
      isApprove: true,
      user_id: user_id,
      user_role: userRole,
    };
    const uploadData = await axios.post(endpoint, data, header);
    if (uploadData?.data?.status) {
      message.success(uploadData?.data?.message);
      setParentContactSaveLoading(false);
      setSpinLoading(false);
      setChangeLoading(true);
    } else {
      message.warning(uploadData?.data?.message);
      setParentContactSaveLoading(false);
      setSpinLoading(false);
    }
  };

  const onFinishInformation = (data) => {
    console.log("success =====>>>>> ", data);
  };

  const loadPrimaryDeviceListData = () => {
    const data = { user_id: user_id, type: "primary" };
    let deviceList = [];
    const endPoint = "cc-student/get-device-list";
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setPrimaryDeviceList(response);
            response.map((data) => {
              primaryDeviceList.push({
                value: data?.device_id,
                label: data?.model_company + " - "  + data?.device_model,
              });
            });
            setPrimaryDeviceDrp(deviceList);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const extractContactsData = () => {
    const endPoint = "device/extract-contacts";
    const data = { user_id: user_id, device_id: "4" }; // need to set dynamic
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            download(response);
          }
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const removeExtractContacts = (url) => {
    const endPoint = "device/remove-extract-contact-csv";
    const data = { url: url };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const download = async (url) => {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    });

    const urlParts = url.split("/");
    const filename = urlParts[urlParts.length - 1];
    const urlObject = window.URL || window.webkitURL;
    const downloadUrl = urlObject.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;

    link.addEventListener("load", handleDownloadSuccess(url));
    // link.addEventListener("error", handleDownloadError());

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadSuccess = (url) => {
    message.success("File downloaded successfully");
    setIsDownload(true);
    removeExtractContacts(url);
  };

  const loadDeviceListData = () => {
    const data = { user_id: user_id };
    let deviceList = [];
    const endPoint = "cc-student/get-device-list";
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setDeviceListData(response);
            response.map((data) => {
              deviceList.push({
                value: data?.device_id,
                label: data?.model_company + " - " + data?.device_model,
              });
            });
            setDeviceListDrp(deviceList);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // other possible parent's numbers in contact list
  const selectDevice = (e) => {
    const endPoint = "cc-student/get-device-contact-list";
    setParentsTableLoading(true);
    const data = { user_id: user_id, device_id: e };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          console.log("response", response);
          setDeviceData(response);
          setParentsTableLoading(false);
        } else {
          message?.warning(result?.message);
          setParentsTableLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setParentsTableLoading(false);
      });
  };

  //Search device log
  const searchDeviceLog = (e) => {
    setSpinLoading(true);
    const endPoint = "cc-student/search-by-number";
    // setSearchMobileTableLoading(true);
    const data = { number: e, user_id: user_id };
    getAPIData(endPoint, "post", data, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message?.success(result?.message);
          const response = result?.data;
          setResultDevice(response);
          setSpinLoading(false);
          // setSearchMobileTableLoading(false);
        } else {
          message?.warning(result?.message);
          // setSearchMobileTableLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        // setSearchMobileTableLoading(false);
        setSpinLoading(false);
      });
  };

  return loading === true ? (
    <CClickLoading />
  ) : (
    <Spin spinning={spinLoading} size="large">
      <div className={`${loading ? "page-loading app" : "app"}`}>
        <Layout
          style={{
            // height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />

              <Title
                level={4}
                className="poppins_regular umoney_page_title"
                // style={{
                //   paddingLeft: login_type === "dialer-student" ? "20px" : "",
                // }}
              >
                {" "}
                {userName}'s Detail View{" "}
              </Title>
            </Header>

            <Content
              className="site-layout-background  white_div"
              style={{
                // padding: "20px 15px",
                borderRadius: "5px",
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" xl={2} lg={4} md={6} sm={8}>
                    <img
                      className="kyc-images"
                      src={user}
                      alt="User"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </Col>
                  <Col className="gutter-row" xl={22} lg={20} md={18} sm={16}>
                    <Row gutter={[16, 16]}>
                      <Col className="gutter-row" span={12}>
                        <Title level={4} className="poppins_semi umoney_title">
                          Information Check Status: {userName}
                        </Title>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="HISTORY"
                          className="poppins_medium umoney_outlined_btn"
                          onClick={() => openModal("call-history")}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="CLICK TO CALL"
                          className="poppins_medium umoney_modal_filled_btn"
                          onClick={() => openModal("click-to-call")}
                          block
                        />
                      </Col>
                      <Col
                        className="gutter-row"
                        span={24}
                        style={{ marginTop: "25px" }}
                      >
                        <Form
                          name="basic"
                          // onFinish={onFinish}
                          // onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          className="umoney-form"
                          requiredMark={false}
                          form={form}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={6}>
                              <Form.Item
                                label="Action Date"
                                name="action_date"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Salary Type is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  style={{ width: "100%" }}
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="white_div mt-20">
                <Title level={5} className="poppins_medium umoney_title">
                  Send Notification or SMS or Whatsapp
                </Title>
                <div className="tab-title">
                  <Row gutter={[16, 16]}>
                    <Col
                      className="gutter-row"
                      span={24}
                      style={{ paddingLeft: "-10px", paddingRight: "-10px" }}
                    >
                      <Tabs
                        defaultActiveKey="1"
                        type="card"
                        onTabClick={(value) => {
                          getTabValue(value);
                        }}
                      >
                        <Tabs.TabPane tab="Notification" key="1">
                          <Row gutter={[16, 16]}>
                            <Col className="gutter-row" span={8}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  In app communication
                                </Title>
                                <Form
                                  name="basic"
                                  onFinish={onFinishNotification}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  form={notificationForm}
                                >
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Module"
                                      name="module"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Module is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Select
                                        size="large"
                                        options={moduleOptions}
                                        onChange={(value) => {
                                          loadNotificationModuleData(value);
                                        }}
                                        placeholder="Select  module"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Comment"
                                      name="comment"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Comment is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <TextArea
                                        size="large"
                                        type="text"
                                        rows={7}
                                        placeholder="Enter comment"
                                      />
                                    </Form.Item>
                                    <Form.Item name="notification_temp_id"></Form.Item>
                                  </div>
                                  <Form.Item>
                                    <CLoadingButton
                                      size="large"
                                      value="Send"
                                      className="poppins_medium umoney_btn "
                                      block
                                      htmlType="submit"
                                      loading={notiSendBtnLoading}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Row gutter={[16, 16]}>
                                  <Col span={20}>
                                    <Title
                                      level={5}
                                      className="poppins_medium umoney_title"
                                    >
                                      In app sent
                                    </Title>
                                  </Col>
                                  <Col span={4}>
                                    <CLoadingButton
                                      size="large"
                                      value="CLEAR IN APP"
                                      className="poppins_medium umoney_btn"
                                      onClick={() => {
                                        // openModal("clear-in-app");.
                                      }}
                                      block
                                    />
                                  </Col>
                                </Row>

                                <div className="umoney_table mt-10">
                                  <Table
                                    columns={notificationColumns}
                                    dataSource={notificationData}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 5,
                                      pageSizeOptions: [5, 10, 15, 20],
                                      locale: { items_per_page: "" },
                                    }}
                                    loading={notificationTableLoading}
                                    scroll={{ y: 200 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="SMS" key="2">
                          <Row gutter={[16, 16]}>
                            <Col className="gutter-row" span={8}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                  //margin:"10px"
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  SMS templates
                                </Title>
                                <Form
                                  name="basic"
                                  onFinish={onFinishSms}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  form={smsForm}
                                >
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Module"
                                      name="module"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Module is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Select
                                        size="large"
                                        options={moduleOptions}
                                        onChange={(val) => {
                                          loadNotificationModuleData(val);
                                        }}
                                        placeholder="Select module"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Comment"
                                      name="comment"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Comment is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <TextArea
                                        size="large"
                                        type="text"
                                        rows={7}
                                        placeholder="Enter comment"
                                      />
                                    </Form.Item>
                                    <Form.Item name="sms_temp_id" />
                                  </div>
                                  <Form.Item>
                                    <CLoadingButton
                                      size="large"
                                      value="Send"
                                      className="poppins_medium umoney_btn"
                                      block
                                      htmlType="submit"
                                      loading={smsSendBtnLoading}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  SMS sending history
                                </Title>
                                <div className="umoney_table mt-10">
                                  <Table
                                    columns={smscolumns}
                                    dataSource={smsData}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 5,
                                      pageSizeOptions: [5, 10, 15, 20],
                                      locale: { items_per_page: "" },
                                    }}
                                    loading={smsTableLoading}
                                    scroll={{ y: 200 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="WHATSAPP" key="3">
                          <Row gutter={[16, 16]}>
                            <Col className="gutter-row" span={8}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                  //margin:"10px"
                                }}
                              >
                                <Form
                                  name="basic"
                                  onFinish={onFinishWhatsapp}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  className="umoney-form"
                                  requiredMark={false}
                                  form={form}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    Whatsapp templates
                                  </Title>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Module"
                                      name="module"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Module is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Select
                                        size="large"
                                        options={moduleOptions}
                                        onChange={(val) => {
                                          loadNotificationModuleData(val);
                                        }}
                                        placeholder="Select module"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="mt-20">
                                    <Form.Item
                                      label="Comment"
                                      name="comment"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Comment is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <TextArea
                                        size="large"
                                        type="text"
                                        rows={7}
                                        placeholder="Enter comment"
                                      />
                                    </Form.Item>
                                    <Form.Item name="whatsapp_temp_id" />
                                  </div>
                                  <CLoadingButton
                                    size="large"
                                    value="Send"
                                    className="poppins_medium umoney_btn"
                                    htmlType="submit"
                                    loading={whatsappSendBtnLoading}
                                    block
                                  />
                                </Form>
                              </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                              <div
                                style={{
                                  border: "1px solid #009D93",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  height: "100%",
                                }}
                              >
                                <Title
                                  level={5}
                                  className="poppins_medium umoney_title"
                                >
                                  Whatsapp sending history
                                </Title>
                                <div className="umoney_table mt-10">
                                  <Table
                                    columns={whatsappColumns}
                                    dataSource={whatsappData}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 5,
                                      pageSizeOptions: [5, 10, 15, 20],
                                      locale: { items_per_page: "" },
                                    }}
                                    loading={whatsappTableLoading}
                                    scroll={{ y: 200 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={19}>
                    <Title level={5} className="poppins_medium umoney_title">
                      Profile Status
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Income
                        </Tag>
                      </Col>

                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Spending
                        </Tag>
                      </Col>

                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Assets
                        </Tag>
                      </Col>
                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Parent Contact
                        </Tag>
                      </Col>
                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Social Media
                        </Tag>
                      </Col>
                      <Col
                        className="gutter-row"
                        xl={4}
                        lg={3}
                        md={4}
                        sm={6}
                        style={{ paddingTop: "25px" }}
                      >
                        <Tag
                          size="large"
                          className="poppins_medium br tag-style"
                          style={{
                            backgroundColor: "red",
                            color: "#fff",
                            border: "none",
                          }}
                        >
                          Cibil
                        </Tag>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="gutter-row"
                    span={19}
                    style={{ marginTop: 10 }}
                  >
                    <Title level={5} className="poppins_semi">
                      Call status
                    </Title>
                  </Col>
                  <Col
                    className="gutter-row"
                    span={5}
                    style={{ marginTop: 10 }}
                  >
                    <CLoadingButton
                      size="large"
                      value="VIEW CALL LOG"
                      className="poppins_medium umoney_outlined_btn"
                      //   loading={PendingBtnLoading}
                      //   onClick={pendingClick}
                      block
                    />
                  </Col>
                  <Col span={24}>
                    <Form
                      name="basic"
                      onFinish={onFinishCallStatus}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                      form={callStatusForm}
                    >
                      <Row gutter={[16, 16]}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Category"
                            className="poppins_medium"
                            name="category"
                            rules={[
                              {
                                required: true,
                                message: "Category is required!",
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              options={categoryData}
                              onChange={(e) => changeCategory(e)}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Sub Category"
                            className="poppins_medium"
                            name="sub_category"
                            rules={[
                              {
                                required: true,
                                message: "Sub Category is required",
                              },
                            ]}
                          >
                            <Select
                              svalue={selectedSubCat}
                              size="large"
                              options={subCategoryData}
                              onChange={(e) => changeSubCategory(e)}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Sub Sub Category"
                            name="sub_sub_category"
                            rules={[
                              {
                                required: true,
                                message: "Sub sub Category is required!",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Select
                              size="large"
                              options={subSubCategoryData}
                            ></Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={6}>
                          <Form.Item>
                            <CLoadingButton
                              size="large"
                              htmlType="submit"
                              value="SUBMIT"
                              className="poppins_medium umoney_btn"
                              loading={callStatusBtnLoading}
                              block
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Call assign to RM"
                            className="poppins_medium"
                            name="call_assign_to_rm"
                          >
                            <Input size="large"></Input>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Call assign to verifier"
                            className="poppins_medium"
                            name="call_assign_to_verifier"
                          >
                            <Input size="large"></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                    <Form
                      name="basic"
                      onFinish={fatherPhoneSave}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                      form={fatherPhoneForm}
                    >
                      <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                        <Col className="gutter-row" span={12}>
                          <Title level={5} className="poppins_semi">
                            Father's phone
                          </Title>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <Form.Item
                            label="User's Device"
                            name="user_device"
                            className="poppins_medium"
                          >
                            <Select
                              label="User’s device"
                              options={deviceList}
                              size="large"
                              classes="poppins_regular filter_select_border br"
                              style={{ width: "100%" }}
                              onChange={(e) => getSaveInDataFather(e)}
                              placeholder="Select device"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                            loading={fatherSaveLoading}
                          />
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Father name"
                            name="father_name"
                            rules={[
                              {
                                required: true,
                                message: "Father name is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter father name"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Save - in phone"
                            name="save_in"
                            rules={[
                              {
                                required: true,
                                message: "Save-in phone required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter save-in phone"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Father phone number"
                            name="father_contact_no"
                            rules={[
                              {
                                required: true,
                                message: "Father's phone number is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter father phone number"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="OTP verified timestamp"
                            name="father_verify_ts"
                            rules={[
                              {
                                required: true,
                                message: "OTP verified timestamp is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter OTP verified timestamp"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Comment"
                            name="father_comment"
                            rules={[
                              {
                                required: true,
                                message: "Comment is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" placeholder="Enter comment" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                    <Form
                      name="basic"
                      onFinish={motherPhoneSave}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                      form={motherPhoneForm}
                    >
                      <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                        <Col className="gutter-row" span={12}>
                          <Title level={5} className="poppins_semi">
                            Mother's phone
                          </Title>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <Form.Item
                            label="User's Device"
                            name="user_device"
                            className="poppins_medium"
                          >
                            <Select
                              label="User’s device"
                              options={deviceList}
                              size="large"
                              classes="poppins_regular filter_select_border br"
                              style={{ width: "100%" }}
                              onChange={(e) => getSaveInDataMother(e)}
                              placeholder="Select user device"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                            loading={motherSaveLoading}
                          />
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Mother name"
                            name="mother_name"
                            rules={[
                              {
                                required: true,
                                message: "Mother name is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter comment"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Save - in phone"
                            name="save_in"
                            rules={[
                              {
                                required: true,
                                message: "Save-in phone is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter save-in phone"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Mother phone number"
                            name="mother_contact_no"
                            rules={[
                              {
                                required: true,
                                message: "Mother's phone number is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter mother phone number"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="OTP verified timestamp"
                            name="mother_verify_ts"
                            rules={[
                              {
                                required: true,
                                message: "OTP verified timestamp is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input
                              size="large"
                              readOnly={true}
                              placeholder="Enter OTP  verified timestamp"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Comment"
                            name="mother_comment"
                            rules={[
                              {
                                required: true,
                                message: "Comment is required",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" placeholder="Enter comment" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                    <Form
                      name="basic"
                      onFinish={otherPhoneSave}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form m-0"
                      requiredMark={false}
                      form={otherPhoneForm}
                    >
                      <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                        <Col className="gutter-row" span={12}>
                          <Title level={5} className="poppins_semi">
                            Other phone
                          </Title>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <Form.Item
                            label="User's Device"
                            name="user_device"
                            className="poppins_medium"
                          >
                            <Select
                              label="User’s device"
                              options={deviceList}
                              size="large"
                              classes="poppins_regular filter_select_border br"
                              style={{ width: "100%" }}
                              onChange={(e) => getSaveInDataOther(e)}
                              placeholder="Select device"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                            loading={otherSaveLoading}
                          />
                        </Col>
                        <Col span={24}>
                          {otherPhoneData?.map((item, index) => (
                            <Row
                              gutter={[16, 16]}
                              key={index}
                              className="mt-20"
                            >
                              <Col className="gutter-row" span={12}>
                                <Form.Item
                                  label="Name"
                                  name="other_name"
                                  className="poppins_medium"
                                >
                                  <Input
                                    size="large"
                                    readOnly={true}
                                    defaultValue={item?.other_name}
                                    placeholder="Enter name"
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-row" span={12}>
                                <Form.Item
                                  label="Save - in phone"
                                  name={`save_in_${index}`}
                                  className="poppins_medium"
                                >
                                  <Input
                                    size="large"
                                    readOnly={true}
                                    placeholder="Enter save-in phone"
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-row" span={12}>
                                <Form.Item
                                  label="Phone number"
                                  name="other_contact_no"
                                  className="poppins_medium"
                                >
                                  <Input
                                    size="large"
                                    readOnly={true}
                                    defaultValue={item?.other_contact_no}
                                    placeholder="Enter phone number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-row" span={12}>
                                <Form.Item
                                  label="OTP verified timestamp"
                                  name="other_verify_ts"
                                  className="poppins_medium"
                                >
                                  <Input
                                    size="large"
                                    readOnly={true}
                                    defaultValue={item?.other_verify_ts}
                                    placeholder="Enter OTP verified timestamp"
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-row" span={12}>
                                <Form.Item
                                  label="Comment"
                                  name={`other_comment${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter  !",
                                    },
                                  ]}
                                  className="poppins_medium"
                                >
                                  <Input
                                    size="large"
                                    defaultValue={item?.other_comment}
                                    placeholder="Enter comment"
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-row" span={12}>
                                <Form.Item
                                  label="Relation"
                                  name="other_relation_id"
                                  className="poppins_medium"
                                >
                                  <Select
                                    defaultValue=""
                                    // onChange={handleChange}
                                    options={data}
                                    size="large"
                                    placeholder="Select relation"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div className="white_div " style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                    <Form
                      name="basic"
                      onFinish={onFinishOtherGmail}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form m-0"
                      requiredMark={false}
                    >
                      <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                        <Col className="gutter-row" span={16}>
                          <Title level={5} className="poppins_semi">
                            Gmail
                          </Title>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum "
                            name="1"
                            rules={[
                              {
                                required: true,
                                message: "Please enter!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="2"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum "
                            name="3"
                            rules={[
                              {
                                required: true,
                                message: "Please enter !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="4"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="5"
                            rules={[
                              {
                                required: true,
                                message: "Please enter !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="6"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>{" "}
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                    <Form
                      name="basic"
                      onFinish={onFinishOtherNach}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                    >
                      <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                        <Col className="gutter-row" span={16}>
                          <Title level={5} className="poppins_semi">
                            NACH
                          </Title>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum "
                            name="1"
                            rules={[
                              {
                                required: true,
                                message: "Please enter!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="2"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum "
                            name="3"
                            rules={[
                              {
                                required: true,
                                message: "Please enter !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="4"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="5"
                            rules={[
                              {
                                required: true,
                                message: "Please enter !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="6"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>{" "}
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                    <Form
                      name="basic"
                      onFinish={onFinishOtherUpi}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                    >
                      <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                        <Col className="gutter-row" span={16}>
                          <Title level={5} className="poppins_semi">
                            UPI
                          </Title>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE & APPROVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <CLoadingButton
                            size="large"
                            value="SAVE"
                            htmlType="submit"
                            className="poppins_medium umoney_modal_filled_btn"
                            block
                          />
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum "
                            name="1"
                            rules={[
                              {
                                required: true,
                                message: "Please enter!",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="2"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum "
                            name="3"
                            rules={[
                              {
                                required: true,
                                message: "Please enter !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="4"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="5"
                            rules={[
                              {
                                required: true,
                                message: "Please enter !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label="Lorem ipsum"
                            name="6"
                            rules={[
                              {
                                required: true,
                                message: "Please enter  !",
                              },
                            ]}
                            className="poppins_medium"
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>{" "}
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ marginTop: 20 }}>
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={4}></Col>
                  <Col span={16}>
                    {role === "approver" ? (
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="ALGO LOGS"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                            disabled={value == "" ? true : false}
                            onClick={() => openModal("algo")}
                          />
                        </Col>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="ALGO & PHONE PERMISSION"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                            disabled={value == "" ? true : false}
                            onClick={() => {
                              openModal("phone-permission");
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="PREVIOUS USER HISTORY"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                            disabled={value == "" ? true : false}
                            onClick={() => openModal("linked-user")}
                          />
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col className="gutter-row" span={4}>
                    <CLoadingButton
                      size="large"
                      value="SAVE & APPROVE"
                      className="poppins_medium umoney_btn"
                      block
                      onClick={() => parentContactSave()}
                      loading={parentContactSaveLoading}
                    />
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form
                      name="basic"
                      onFinish={onFinishInformation}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={18}></Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col span={12}>
                    {role === "approver" ? (
                      <Row gutter={[16, 16]}>
                        <Col span={3}>
                          <Button
                            className="primary-device-btn btntext-all"
                            style={{
                              background: "#DE1717 0% 0% no-repeat padding-box",
                              color: "white",
                              border: "none",
                              height: "40px",
                              width: "100px",
                            }}
                          >
                            Fail
                          </Button>
                        </Col>
                        <Col span={7}>
                          <CLoadingButton
                            size="large"
                            value="SET PRIMARY"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                          />
                        </Col>
                        <Col span={7}>
                          <CLoadingButton
                            size="large"
                            value="EXPORT CALL LOGS"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            block
                          />
                        </Col>
                        <Col span={7}>
                          <CLoadingButton
                            size="large"
                            value="EXPORT CONTACTS"
                            className="poppins_medium umoney_outlined_btn"
                            style={{ width: "100%" }}
                            onClick={() => extractContactsData()}
                            block
                          />
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Title
                  level={5}
                  className="poppins_semi"
                  style={{ marginTop: 20 }}
                >
                  User provided info matched with other users
                </Title>

                <div className="umoney_table">
                  <Table
                    columns={MatchedOtherUserColumns}
                    // dataSource={matchData}
                    scroll={{
                      x: 1300,
                    }}
                    pagination={false}
                  />
                </div>
                <Title
                  level={5}
                  className="poppins_semi"
                  style={{ marginTop: 20 }}
                >
                  Other possible parent’s numbers in contact list
                </Title>
                <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                  <Col className="gutter-row" span={12}>
                    <Form
                      name="basic"
                      onFinish={onFinishInformation}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      className="umoney-form"
                      requiredMark={false}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Form.Item
                            label="Choose device"
                            name="Device"
                            size="large"
                            rules={[
                              {
                                required: true,
                                message: "Select Device",
                              },
                            ]}
                            className="poppins_medium mb-35"
                          >
                            <Select
                              label="Choose device"
                              options={deviceListDrp}
                              size="large"
                              classes="poppins_regular filter_select_border br"
                              style={{ width: "100%" }}
                              onChange={(e) => selectDevice(e)}
                              placeholder="Select device"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={18}></Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <div className="umoney_table" style={{ marginTop: 10 }}>
                  <Table
                    columns={ContactlistColumns}
                    dataSource={ContactlistData}
                    scroll={{
                      x: 1300,
                    }}
                    pagination={false}
                  />
                </div>
                <Title
                  level={5}
                  className="poppins_semi"
                  style={{ marginTop: 20 }}
                >
                  Mobile number in all devices user has logged in from
                </Title>
                <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                  <Col className="gutter-row" span={8}>
                    <Search
                      placeholder="Enter Keyword"
                      size="large"
                      style={{ width: "100%" }}
                      onSearch={(e) => {
                        searchDeviceLog(e);
                      }}
                    />
                  </Col>
                </Row>
                <div className="umoney_table" style={{ marginTop: 10 }}>
                  <Table
                    columns={AllDevicesColumns}
                    dataSource={AllDevicesData}
                    scroll={{
                      x: 1300,
                    }}
                    pagination={false}
                  />
                </div>
              </div>
              <Collapse accordion style={{ marginTop: 20 }}>
                <Panel header="Upload files" key="1">
                  <UploadFiles pageType={pageType} user_id={user_id} />
                </Panel>
              </Collapse>
            </Content>
            <Modal
              open={modalType}
              title={modalTitle}
              footer={false}
              onCancel={handleClose}
              centered={true}
              width={modalWidth}
              // destroyOnClose={true}
            >
              {modalType === "call-history" ? (
                <CallHistory userType={pageType} user_id={user_id} />
              ) : modalType === "click-to-call" ? (
                <ClickToCallModal />
              ) : (
                ""
              )}
            </Modal>
          </Layout>
        </Layout>
      </div>
    </Spin>
  );
};
export default AdvanceCreditCheckDetailView;
