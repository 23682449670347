/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Switch,
  Input,
  Space,
  Button,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import CompanyBranchModals from "../../components/Modals/College/CompanyBranchModals";
import _ from "lodash";
import Highlighter from "react-highlight-words";

const { Title } = Typography;
const { Header, Content } = Layout;

const BranchCompany = () => {
  const scroll = {};
  // const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [deleteId, setDeleteId] = useState("");

  const [pincodeList, setPincodeList] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const [branchData, setBranchData] = useState({});

  const [modalTitle, setModalTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    console.log("Various parameters", pagination, filters);
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const openModal = (type, data) => {
    console.log("data", data);

    if (type === "add-company-branch") {
      setModalType(type);
      setModalTitle("Add Company Branch");
    } else if (type === "edit-company-branch") {
      console.log("data", data);
      setBranchData(data);
      setModalType(type);
      setModalTitle("Edit Company Branch");
    } else if (type === "delete") {
      setDeleteId(data?.id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
      setModalOpen(true);
    }
  };

  const loadData = () => {
    setLoading(true);
    const endPoint = "company/branch-list";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const statusChange = (e,id) => {
    const endPoint = "company/branch/change-status/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadPincodes = () => {
    const endPoint = "template/pincodes";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var pincode_list = [];
          result?.data.map(async (pin, key) => {
            pincode_list.push({
              value: pin.id,
              label: pin.pincode,
              state: pin.state,
              district: pin.district,
            });
          });
          setPincodeList(pincode_list);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const CompanyData = () => {
    const endPoint = "company/list";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          let data = [];
          result?.data.map((e, key) => {
            data.push({
              value: e.id,
              label: e.company_name,
            });
          });
          setCompanyList(data);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  useEffect(() => {
    if (_.isEmpty(tableData)) {
      loadData();
    }
    loadPincodes();
    CompanyData();
  }, [tableData]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const TableColumns = [
    {
      title: "BRANCH ID",
      dataIndex: "id",
      key: "id",
      width: "7%",
      sorter: (a, b) => a.id - b.id,
      filteredValue: filteredInfo.id || null,
    },
    {
      title: "COMPANY BRANCH NAME",
      dataIndex: "branch_name",
      key: "branch_name",
      ...getColumnSearchProps("branch_name"),
      filteredValue: filteredInfo.branch_name || null,
    },
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      key: "company_name",
      ...getColumnSearchProps("company_name"),
      filteredValue: filteredInfo.company_name || null,
    },
    {
      title: "DISTRICT",
      dataIndex: "district",
      key: "district",
      width: "10%",
      ...getColumnSearchProps("district"),
      filteredValue: filteredInfo.district || null,
    },
    {
      title: "STATE",
      dataIndex: "state",
      key: "state",
      width: "10%",
      ...getColumnSearchProps("state"),
      filteredValue: filteredInfo.state || null,
    },
    {
      title: "PINCODE",
      dataIndex: "pincode",
      key: "pincode",
      filters: pincodeList,
      ...getColumnSearchProps("pincode"),
      filteredValue: filteredInfo.pincode || null,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "5%",
      filteredValue: filteredInfo.status || null,
      // render: (text, record) => {
      //   // 0=Inactive, 1=Active, -1=Deleted
      //   return (
      //     <div>
      //       <span>
      //         <Switch
      //           checked={record?.status === "1" ? true : false}
      //           onChange={(e) => {
      //             statusChange(record?.id);
      //           }}
      //         />
      //       </span>
      //     </div>
      //   );
      // },
      render: (text, record) => (
        <div>
          <span>
            <Switch
              checked={record.status === "1" ? true : false}
              onChange={(e) => {statusChange(e, record?.id); console.log()}}
            />
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: "10%",
      filteredValue: filteredInfo.actions || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={(e) => openModal("edit-company-branch", record)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={(e) => openModal("delete", record)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            Company Branch
          </Title>
        </Header>
        <Content
          className="site-layout-background white_div"
          style={{
            padding: "20px 15px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={8}></Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="CLEAR FILTERS"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => clearFilters()}
                block
              />
            </Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="ADD"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => openModal("add-company-branch")}
                icon={<PlusOutlined />}
                block
              />
            </Col>
          </Row>

          <div className="umoney_table " style={{ marginTop: 20 }}>
            <Table
              pagination={{
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
              columns={TableColumns}
              dataSource={tableData}
              scroll={scroll}
              onChange={handleChange}
            />
          </div>
          {modalType === "delete" ? (
            <CommonModals
              open={modalOpen}
              title="Delete Confirmation"
              type="company-branch"
              setOpen={setModalOpen}
              modalType={modalType}
              id={deleteId}
              setModalType={setModalType}
              onDelete={(val) => {
                loadData();
              }}
            />
          ) : modalType === "add-company-branch" ? (
            <CompanyBranchModals
              pincodeList={pincodeList}
              companyList={companyList}
              modalTitle={modalTitle}
              setModalType={setModalType}
              modalType={modalType}
              onSave={() => {
                setModalType(false);
                loadData();
              }}
            />
          ) : modalType === "edit-company-branch" ? (
            <CompanyBranchModals
              pincodeList={pincodeList}
              companyList={companyList}
              modalTitle={modalTitle}
              modalType={modalType}
              branchData={branchData}
              setModalType={setModalType}
              onSave={() => {
                setModalType(false);
                loadData();
              }}
            />
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default BranchCompany;
