import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Input,
  Space,
  Button,
} from "antd";
import Sidebar from "../../components/Sidebar";

import { Switch } from "antd";

import {
  MenuOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import AddNewFAQ from "../../components/Modals/faq/AddNewFAQ";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import Highlighter from "react-highlight-words";

const { Title } = Typography;
const { Header, Content } = Layout;

const FaqList = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  const [modalTitle, setModalTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const [editData, setEditData] = useState([]);

  const [deleteId, setDeleteId] = useState("");

  //Search Table
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const openModal = (type, id) => {
    if (type === "ADD") {
      console.log("type", type);
      setModalType(type);
      setModalTitle("Add New FAQ");
    } else if (type === "EDIT") {
      loadEditData(id);
      setModalType(type);
      setModalTitle("Edit FAQ");
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType("delete");
      setModalOpen(true);
    }
  };

  const loadEditData = (id) => {
    setLoading(true);
    const endPoint = "faq/get-faq/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setEditData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadFaqData = () => {
    const endPoint = "faq/faq-list/faq";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadRoleData = () => {
    const endPoint = "user-agent/role-list";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          let name = [];
          result?.data.map((e) => {
            if (e?.type === "1") {
              name.push({ value: e?.id, label: e.name, text: e.name });
            }
          });
          setRoleData(name);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const setValues = (e, id) => {
    let endPoint = "faq/change-faq-status/" + id;
    const data = { status: e === true ? "1" : "0" };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadFaqData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // Search Functionality START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // Search Functionality END

  useEffect(() => {
    loadFaqData();
    loadRoleData();
  }, []);

  const listColumns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "10%",
      ...getColumnSearchProps("id"),
      filteredValue: filteredInfo.id || null,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "FAQ QUESTION",
      dataIndex: "title",
      filteredValue: filteredInfo.title || null,
      ...getColumnSearchProps("title"),
    },
    {
      title: "FAQ TYPE",
      dataIndex: "type",
      filteredValue: filteredInfo.type || null,
      render: (text, record) => (
        <div>
          <span>
            {record?.type === "1"
              ? "Support"
              : record?.type === "2"
              ? "Loan"
              : record?.type === "3"
              ? "Profile"
              : ">5000"}
          </span>
        </div>
      ),
      filters: [
        {
          value: "1",
          text: "Support",
        },
        {
          value: "2",
          text: "Loan",
        },
        {
          value: "3",
          text: "Profile",
        },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.type
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ROLE",
      dataIndex: "role_name",
      filteredValue: filteredInfo.role_name || null,
      ...getColumnSearchProps("role_name"),
    },
    {
      title: "STATUS",
      key: "status",
      filteredValue: filteredInfo.status || null,
      width: "10%",
      render: (text, record) => (
        <Row gutter={[16, 16]}>
          <Col span={3}>
            <Switch
              checked={record?.status === "1" ? true : false}
              onChange={(e) => {
                setValues(e, record?.id);
              }}
            />
          </Col>
        </Row>
      ),
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "Action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      width: "10%",
      render: (text, record) => (
        <Row gutter={[16, 16]}>
          <Col>
            <span>
              <EditOutlined
                style={{
                  fontSize: "25px",
                  color: "#009D93",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  openModal("EDIT", record?.id);
                }}
              />
            </span>
          </Col>
          <Col>
            <span>
              <DeleteOutlined
                style={{ fontSize: "25px", color: "red", cursor: "pointer" }}
                onClick={(e) => openModal("delete", record?.id)}
              />
            </span>
          </Col>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    loadFaqData();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}

      <Layout
        style={{
          padding: "20px 0 20px 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              backgroundColor: "#009D93",
              borderRadius: "15px 0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              FAQ List
            </Title>
          </Header>
          <Content
            className="site-layout-background white_div"
            style={{
              padding: "20px 15px",
              borderRadius: "5px",
            }}
          >
            <div>
              <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                <Col className="gutter-row" span={8}></Col>
                {/* <Col className="gutter-row" span={}></Col> */}
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="CLEAR FILTERS"
                    className="poppins_medium umoney_modal_filled_btn"
                    onClick={() => {
                      clearFilters();
                    }}
                    block
                  />
                </Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="ADD NEW FAQ"
                    className="poppins_medium umoney_modal_filled_btn"
                    onClick={() => {
                      openModal("ADD");
                    }}
                    icon={<PlusOutlined />}
                    block
                  />
                </Col>
              </Row>
            </div>
            <div className="umoney_table" style={{ marginTop: 20 }}>
              <Table
                columns={listColumns}
                dataSource={tableData}
                scroll={{
                  x: 1300,
                }}
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                onChange={handleChange}
              />
            </div>
            {modalType === "ADD" ? (
              <AddNewFAQ
                modalTitle={modalTitle}
                roleData={roleData}
                title="ADD FAQ"
                setModalType={setModalType}
                modalType={modalType}
                onSave={() => {
                  setModalType(false);
                  loadFaqData();
                }}
              />
            ) : modalType === "EDIT" ? (
              <AddNewFAQ
                faqData={editData}
                roleData={roleData}
                setModalType={setModalType}
                title="Edit FAQ"
                modalTitle={modalTitle}
                modalType={modalType}
                onSave={() => {
                  setModalType(false);
                  loadFaqData();
                }}
              />
            ) : modalType === "delete" ? (
              <CommonModals
                open={modalOpen}
                setOpen={setModalOpen}
                title="Delete FAQ"
                type="faq"
                modalType={modalType}
                setModalType={setModalType}
                id={deleteId}
                onDelete={(val) => {
                  loadFaqData();
                }}
              />
            ) : (
              ""
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default FaqList;
