import React from "react";
import { Row, Col, Table, Typography, Tag, message } from "antd";
import { useState } from "react";
import { getAPIData } from "../../../apiHelper";
import { useEffect } from "react";
import axios from "axios";
import _ from "lodash";

const columns = [
  {
    title: "USER DETAILS",
    dataIndex: "userdetails",
    key: "userdetails",
    width: "25%",
    render: (text, record) => (
      <div>
        <span>
          {record?.first_name} {record?.middle_name} {record?.last_name} (
          {record?.user_id})
        </span>
        <br />
        <span>{record?.mobile_number}</span>
        <br />
        <span>{record?.email}</span>
      </div>
    ),
  },
  {
    title: "TIME STAMP",
    dataIndex: "loginAt",
    key: "loginAt",
    width: "25%",
  },
  {
    title: "DEVICE STATUS AT THAT TIME",
    dataIndex: "devicestatus",
    key: "devicestatus",
    width: "30%",
    render: (text, record) => (
      <div>
        <span>
          {record.current_device_status === "0"
            ? "Not Primary"
            : record.current_device_status === "1"
            ? "Primary"
            : record.current_device_status === "2"
            ? "Login"
            : record.current_device_status === "3"
            ? "Temporary"
            : ""}
        </span>
      </div>
    ),
  },
  {
    title: "CURRENT OVERDUE ON DEVICE",
    dataIndex: "currentoverdue",
    key: "currentoverdue",
  },
];

const PastDevice = (props) => {
  const [pastDeviceData, setPastDeviceData] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [loginHistoryData, setLoginHistoryData] = useState();
  const [primaryHistoryData, setPrimaryHistoryData] = useState();

  const [fileData, setFileData] = useState();

  const { userId, modalType } = props;

  const [isDownload, setIsDownload] = useState(false);

  const loadPastDeviceData = () => {
    const endPoint = "device/past-device";
    setPrimaryHistoryData("");
    setLoginHistoryData("");
    setTableLoading(true);
    const data = { user_id: userId };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setPastDeviceData(response);
          setPrimaryHistoryData(response?.PrimaryHistory);
          setLoginHistoryData(response?.LoginHistory);
          setTableLoading(false);
        } else {
          message?.warning(result?.message);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setTableLoading(false);
      });
  };

  const extractContactsData = () => {
    const endPoint = "device/extract-contacts";
    const data = { user_id: "529", device_id: "4" }; // need to set dynamic
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            download(response);
          }
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const removeExtractContacts = (url) => {
    const endPoint = "device/remove-extract-contact-csv";
    const data = { url: url };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const download = async (url) => {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    });

    const urlParts = url.split("/");
    const filename = urlParts[urlParts.length - 1];
    const urlObject = window.URL || window.webkitURL;
    const downloadUrl = urlObject.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;

    link.addEventListener("load", handleDownloadSuccess(url));
    // link.addEventListener("error", handleDownloadError());

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadSuccess = (url) => {
    message.success("File downloaded successfully");
    setIsDownload(true);
    removeExtractContacts(url);
  };

  // const handleDownloadError=()=>{
  //   message.error("Something went wrong !!")
  //   setIsDownload(false);
  // }

  useEffect(() => {
    loadPastDeviceData();
  }, [userId, modalType, props]);

  const name = "Karan (32423)";
  const phone = "9879897787";
  const overdue = "RS. 40,000";

  const devices = "6";
  const LoginOverdue = ".";
  const CountAmount = "40,000";

  const { Title } = Typography;

  const dataOfUserColumns = [
    {
      title: "DEVICE DETAILS",
      dataIndex: "devicedetails",
      key: "devicedetails",
    },
    {
      title: "TIME STAMP",
      dataIndex: "timestamp",
      key: "timestamp",
    },
    {
      title: "CONTACTS",
      dataIndex: "contacts",
      key: "contacts",
    },
    {
      title: "CALL LOGS",
      dataIndex: "calllogs",
      key: "calllogs",
    },
    {
      title: "???",
      dataIndex: "???",
      key: "???",
    },
    {
      title: "???",
      dataIndex: "???",
      key: "???",
    },
    {
      title: "???",
      dataIndex: "???",
      key: "???",
    },
  ];

  const dataOfUserData = [];
  for (let i = 1; i <= 3; i++) {
    dataOfUserData.push({
      key: i,
      devicedetails: (
        <div>
          <span>Name(Id)</span>
          <br />
          <span>Phone No:</span>
          <br />
          <span>Email</span>
        </div>
      ),
      timestamp: "",
      contacts: (
        <span>
          <Tag className="tag-form" onClick={() => extractContactsData()}>
            EXTRACT
          </Tag>
        </span>
      ),
      calllogs: (
        <span>
          <Tag className="tag-form">EXTRACT</Tag>
        </span>
      ),
    });
  }

  const primaryHistoryColumns = [
    {
      title: "USER DETAILS",
      dataIndex: "userdetails",
      key: "userdetails",
      render: (text, record) => (
        <div>
          <span>
            {record?.first_name} {record?.middle_name} {record?.last_name} (
            {record?.user_id})
          </span>
          <br />
          <span>{record?.mobile_number}</span>
          <br />
          <span>{record?.email}</span>
        </div>
      ),
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "APPROVAL TIME STAMP",
      dataIndex: "approvaltimestamp",
      key: "approvaltimestamp",
    },
    {
      title: "TRANSFER TIME STAMP",
      dataIndex: "transfertimestamp",
      key: "transfertimestamp",
    },
    {
      title: "TRANSFER USER",
      dataIndex: "transferuser",
      key: "transferuser",
    },
    {
      title: "CURRENT OVERDUE ON DEVICE",
      dataIndex: "user_overdue",
      key: "user_overdue",
    },
    {
      title: "FIRST LOGIN TIME STAMP BY USER",
      dataIndex: "first_login_time",
      key: "first_login_time",
    },
    {
      title: "LAST LOGIN TIME STAMP BY USER",
      dataIndex: "last_login_time",
      key: "last_login_time",
    },
  ];

  // const primaryHistoryData = [
  //   {
  //     key: 1,
  //     userdetails: (
  //       <div>
  //         <span>Name(Id)</span>
  //         <br />
  //         <span>Phone No:</span>
  //         <br />
  //         <span>Email</span>
  //       </div>
  //     ),
  //     type: (
  //       <span>
  //         <Tag className="tag-form">TEMPORARY</Tag>
  //       </span>
  //     ),
  //     approvaltimestamp: "",
  //     transfertimestamp: "",
  //     transferuser: "Karan(32423) 9087656464 karan12@gmail.com",
  //     currentoverdue: "Count of loan accounts and amount",
  //     firstlogin: "",
  //     lastlogin: "",
  //   },
  //   {
  //     key: 2,
  //     userdetails: (
  //       <div>
  //         <span>Name(Id)</span>
  //         <br />
  //         <span>Phone No:</span>
  //         <br />
  //         <span>Email</span>
  //       </div>
  //     ),
  //     type: (
  //       <span>
  //         <Tag className="tag-form">PRIMARY</Tag>
  //       </span>
  //     ),
  //     approvaltimestamp: "",
  //     transfertimestamp: "",
  //     transferuser: "",
  //     currentoverdue: "Count of loan accounts and amount",
  //     firstlogin: "",
  //     lastlogin: "",
  //   },
  //   {
  //     key: 3,
  //     userdetails: (
  //       <div>
  //         <span>Name(Id)</span>
  //         <br />
  //         <span>Phone No:</span>
  //         <br />
  //         <span>Email</span>
  //       </div>
  //     ),
  //     type: (
  //       <span>
  //         <Tag className="tag-form">SET PRIMARY</Tag>
  //       </span>
  //     ),
  //     approvaltimestamp: "",
  //     transfertimestamp: "",
  //     transferuser: "",
  //     currentoverdue: "Count of loan accounts and amount",
  //     firstlogin: "",
  //     lastlogin: "",
  //   },
  //   {
  //     key: 4,
  //     userdetails: (
  //       <div>
  //         <span>Name(Id)</span>
  //         <br />
  //         <span>Phone No:</span>
  //         <br />
  //         <span>Email</span>
  //       </div>
  //     ),
  //     type: (
  //       <span>
  //         <Tag className="tag-form">LOGIN ONLY</Tag>
  //       </span>
  //     ),
  //     approvaltimestamp: "",
  //     transfertimestamp: "",
  //     transferuser: "",
  //     currentoverdue: "Count of loan accounts and amount",
  //     firstlogin: "",
  //     lastlogin: "",
  //   },
  // ];

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
        <Col span={4}>
          <Row>Name:</Row>
          <Row>Phone Number:</Row>
          <Row>Overdue(3):</Row>
        </Col>
        <Col className="poppins-medium" style={{ color: "#009D93" }}>
          <Row>{name}</Row>
          <Row>{phone}</Row>
          <Row>{overdue}</Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={6}>
          <Row>Number of Login Devices:</Row>
          <Row>Login Overdue:</Row>
          <Row> Count 4. Amount:</Row>
        </Col>
        <Col className="poppins-medium" style={{ color: "#009D93" }}>
          <Row>{devices}</Row>
          <Row>{LoginOverdue}</Row>
          <Row>{CountAmount}</Row>
        </Col>
        <Col span={5}></Col>
        <Col span={6}>
          <Row>Number of Login Devices:</Row>
          <Row>Login Overdue:</Row>
          <Row> Count 2. Amount:</Row>
        </Col>
        <Col className="poppins-medium" style={{ color: "#009D93" }}>
          <Row>{devices}</Row>
          <Row>{LoginOverdue}</Row>
          <Row>{CountAmount}</Row>
        </Col>
      </Row>
      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Login History
          </Title>
        </Row>
        <Table
          dataSource={loginHistoryData}
          pagination={false}
          columns={columns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Data of user
          </Title>
        </Row>
        <Table
          dataSource={dataOfUserData}
          pagination={false}
          columns={dataOfUserColumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Primary History
          </Title>
        </Row>
        <Table
          dataSource={primaryHistoryData}
          pagination={false}
          columns={primaryHistoryColumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};

export default PastDevice;
