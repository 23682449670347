import React from "react";
import { FullscreenOutlined } from "@ant-design/icons";

const CNewTab = ({ PreviewImageFunc }) => {
  return (
    <div className="d-flex new_tab">
      <FullscreenOutlined
        style={{ fontSize: "25px", color: "#fff" }}
        onClick={PreviewImageFunc}
      />
    </div>
  );
};

export default CNewTab;
