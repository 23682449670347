import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

const tableColumns = [
  {
    title: "NUMBER",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "TYPE",
    dataIndex: "phone_number_type",
    key: "phone_number_type",
  },
  {
    title: "CALL TYPE",
    dataIndex: "call_type",
    key: "call_type",
    render: (text, record) => (
      <span>
        {record?.call_type === "1"
          ? "Dialer"
          : record?.call_type === "2"
          ? "Click to call"
          : record?.call_type === "3"
          ? "Schedules Call back"
          : ""}
      </span>
    ),
  },
  {
    title: "RESPONSE",
    dataIndex: "call_response",
    key: "call_response",
  },
  {
    title: "CALL DURATION",
    dataIndex: "call_duration",
    key: "call_duration",
  },
  {
    title: "AGENT DETAILS",
    dataIndex: "agent_details",
    key: "agent_details",
  },
  {
    title: "TIME STAMP",
    dataIndex: "timestamp",
    key: "timestamp",
  },
  {
    title: "REMARK",
    dataIndex: "remark",
    key: "remark",
  },
];

const CallHistory = ({ user_id, userType, pageType }) => {
  console.log("userType", userType);
  console.log("user_id", user_id);

  const [tableLoading, setTableLoading] = useState(false);
  const [callHistoryData, setCallHistoryData] = useState();

  const loadCallHistoryData = () => {
    setTableLoading(true);
    const endPoint =
      userType === "stu_onb"
        ? "student/stu-onb-call-history"
        : userType === "sal_onb"
        ? "student/get-call-history"
        : pageType === "bank"
        ? ""
        : "cc-student/call-log-history";
    const data = { user_id: user_id, admin_apptype: userType };
    getAPIData(
      endPoint,
      "post",
      data,
      false,
      userType === "stu_onb"
        ? "student"
        : userType === "sal_onb"
        ? "salaried"
        : "credit_check"
    )
      .then((result) => {
        if (result?.status === true) {
          if (!_.isEmpty(result?.data) && _.isArray(result?.data))
            var response = result?.data;
          setCallHistoryData(response);
          setTableLoading(false);
        } else {
          message.warning(result?.message);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    loadCallHistoryData();
  }, [user_id]);

  return (
    <div>
      <Table
        dataSource={callHistoryData}
        pagination={false}
        columns={tableColumns}
        loading={tableLoading}
      />
    </div>
  );
};

export default CallHistory;
