import { message, Table } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

const columns = [
  {
    title: "TICKET ID",
    dataIndex: "id",
    key: "id",
    align: "top",
  },
  {
    title: "REQUEST TIMESTAMP",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "DEVICE MODEL",
    dataIndex: "device_model",
    key: "device_model",
  },
  {
    title: "G-AD-ID",
    dataIndex: "g_ad_id",
    key: "g_ad_id",
  },
  {
    title: "IMEI",
    dataIndex: "imei_no",
    key: "imei_no",
  },
  {
    title: "AGENT",
    dataIndex: "agentName",
    key: "agentName",
  },
  {
    title: "AGENT COMMENT",
    dataIndex: "comment",
    key: "comment",
    width: "",
  },
  {
    title: "AGENT ACTION TIMESTAMP",
    dataIndex: "updatedAt",
    key: "agentactiupdatedAtontime",
  },
  {
    title: "USER COMMENTS",
    dataIndex: "usercomments",
    key: "usercomments",
    width: "16%",
    render: (text, record) => <span>XXXX</span>,
  },
  {
    title: "STATUS",
    dataIndex: "action_button",
    key: "action_button",
    render: (text, record) => (
      <div>
        <span>
          {record?.action_button === "1"
            ? "Approve"
            : record?.action_button === "2"
            ? "Reject"
            : record?.action_button === "3"
            ? "Follow Up"
            : record?.action_button === "4"
            ? "Set Temporary"
            : record?.action_button === "5"
            ? "Pending"
            : ""}
        </span>
      </div>
    ),
  },
];

const TicketHistory = (props) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [ticketData, setTicketData] = useState();

  const { deviceId, modalType } = props;

  const loadTicketData = () => {
    const endPoint = `device/ticket-history/${deviceId}`;
    setTableLoading(true);
    setTicketData("");
    getAPIData(endPoint, "get", {}, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setTicketData(response);
          }
        } else {
          message?.warning(result?.message);
        }
        setTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    loadTicketData();
  }, [deviceId, modalType, props]);

  return (
    <div className="umoney_table">
      <Table
        dataSource={ticketData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          position: ["none", "bottomLeft"],
          locale: { items_per_page: "" },
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 15, 20],
        }}
      />
    </div>
  );
};

export default TicketHistory;
