import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import CLoadingButton from "../InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import CompanyDetailsModal from "../Modals/CompanyDetailsModal";
import CropperModal from "../Modals/CropperModal";
import _ from "lodash";
import CCloseIcon from "../InputFields/CCloseIcon";

const { Text } = Typography;

const EmploymentInformtaionDetails = (props) => {
  // console.log("props", props);
  const empData = props?.empInfo;
  const user_id = props?.user_id;

  const role = "Approver";
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const today = moment;
  // console.log("today", today);

  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [idApprover, setIdApprover] = useState(false);

  const [companyList, setCompanyList] = useState();
  const [companyData, setCompanyData] = useState();

  const [departmentList, setDepartmentList] = useState();
  const [designationList, setDesignationList] = useState();
  const [proofList, setProofList] = useState();

  const [pincodeList, setPincodeList] = useState();

  const [companyId, setCompanyId] = useState(empData?.company?.company_id);

  const [form] = Form.useForm();

  const [employmentInfoBtnLoading, setEmploymentInfoBtnLoading] =
    useState(false);
  const [employmentDetailsBtnLoading, setEmploymentDetailsBtnLoading] =
    useState(false);
  const [employmentCallApiBtnLoading, setEmploymentCallApiBtnLoading] =
    useState(false);
  const [employmentMergeBtnLoading, setEmploymentMergeBtnLoading] =
    useState(false);

  const [companyStatusData, setCompanyStatusData] = useState();

  const employmentDetailsClick = () => {
    setEmploymentDetailsBtnLoading(true);
    setLoading(true);
  };

  const employmentMergeClick = () => {
    setEmploymentMergeBtnLoading(true);
    setLoading(true);
  };

  const handleClose = () => {
    setModalType("");
  };

  useEffect(() => {
    getCompanyList();
    getDepartmentList();
    getDesignationList();
    getProofList();
    loadPincodes();
  }, []);

  const [document1, setDocument1] = useState([]);
  const [document2, setDocument2] = useState([]);
  const [document3, setDocument3] = useState([]);
  const [document4, setDocument4] = useState([]);
  const [document5, setDocument5] = useState([]);

  //Document name
  const [document1Name, setDocument1Name] = useState("");
  const [document2Name, setDocument2Name] = useState("");
  const [document3Name, setDocument3Name] = useState("");
  const [document4Name, setDocument4Name] = useState("");
  const [document5Name, setDocument5Name] = useState("");

  const document1Change = ({ fileList: newFileList }) => {
    setDocument1(newFileList);
  };
  const document2Change = ({ fileList: newFileList }) => {
    setDocument2(newFileList);
  };
  const document3Change = ({ fileList: newFileList }) => {
    setDocument3(newFileList);
  };
  const document4Change = ({ fileList: newFileList }) => {
    setDocument4(newFileList);
  };
  const document5Change = ({ fileList: newFileList }) => {
    setDocument5(newFileList);
  };

  useLayoutEffect(() => {
    getPincodeValue(0, empData?.company?.pincode_id);
  }, []);

  //Modal
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [srcImg, setSrcImg] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");
  const [modalTypeCrop, setModalTypeCrop] = useState("");
  const [typeCrop, setTypeCrop] = useState("");

  const openModal = (type) => {
    if (type === "company-details") {
      setModalType(type);
      setModalTitle("Company Details");
      setModalWidth(1000);
    }
  };

  const openModalCrop = (type) => {
    setModalTypeCrop(type);
    setModalOpen(true);
  };

  if (!_.isEmpty(imageDoc) && imageType === "emp1") {
    setDocument1(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "emp2") {
    setDocument2(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "emp3") {
    setDocument3(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "emp4") {
    setDocument4(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "emp5") {
    setDocument5(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  const handleImage1 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument1Name(event.target.files[0]?.name);
    setImageType("emp1");
    openModalCrop("crop-image");
  };
  const handleImage2 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument2Name(event.target.files[0]?.name);
    setImageType("emp2");
    openModalCrop("crop-image");
  };
  const handleImage3 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument3Name(event.target.files[0]?.name);
    setImageType("emp3");
    openModalCrop("crop-image");
  };
  const handleImage4 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument4Name(event.target.files[0]?.name);
    setImageType("emp4");
    openModalCrop("crop-image");
  };
  const handleImage5 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument5Name(event.target.files[0]?.name);
    setImageType("emp5");
    openModalCrop("crop-image");
  };

  const getPincodeValue = async (e) => {
    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("state", abcd?.state);
    form.setFieldValue("district", abcd?.district);
  };

  const onFinish = async (data) => {
    if (idApprover) {
      props?.loading(true);
      setApproveBtnLoading(true);
    } else {
      props?.loading(true);
      setSaveBtnLoading(true);
    }
    let images = [];
    const endpoint = "steps/agent-save-employee-data";
    // const form = new FormData();
    // form.append("company_id", data?.company_id);
    // form.append("address", data?.address);
    // form.append("pincode_id", data?.pincode_id);
    // form.append("monthly_salary", data?.monthly_salary);
    // form.append("ctc", data?.ctc);
    // form.append("department_id", data?.department_id);
    // form.append("designation_id", data?.designation_id);
    // form.append("date_of_join", data?.date_of_join);
    // form.append("proof_type_id", data?.proof_type_id);
    // form.append("user_id", user_id);
    // form.append("type", "admin");
    if (idApprover) {
      data.idApprover = idApprover;
    }
    data.user_id = user_id;
    if (document1?.[0]) {
      images.push({
        imageBase64: document1.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document1Name,
        type: "imageOne",
      });
    }

    if (document2?.[0]) {
      images.push({
        imageBase64: document2.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document2Name,
        type: "imageTwo",
      });
    }

    if (document3?.[0]) {
      images.push({
        imageBase64: document3.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document3Name,
        type: "imageThree",
      });
    }

    if (document4?.[0]) {
      images.push({
        imageBase64: document4.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document4Name,
        type: "imageFour",
      });
    }

    if (document5?.[0]) {
      images.push({
        imageBase64: document5.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document5Name,
        type: "imageFive",
      });
    }

    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }

    getAPIData(endpoint, "post", data, false, "salaried")
      .then((result) => {
        if (result?.status == true) {
          props?.isSuccess(true);
          if (idApprover) {
            props?.loading(false);
            setApproveBtnLoading(false);
          } else {
            props?.loading(false);
            setSaveBtnLoading(false);
          }
          message.success(result?.message);
        } else {
          message.warning(result?.message);
          if (idApprover) {
            props?.loading(false);
            setApproveBtnLoading(false);
          } else {
            props?.loading(false);
            setSaveBtnLoading(false);
          }
        }
        if (idApprover) {
          props?.loading(false);
          setApproveBtnLoading(false);
        } else {
          props?.loading(false);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        props?.loading(false);
        if (idApprover) {
          props?.loading(false);
          setApproveBtnLoading(false);
        } else {
          props?.loading(false);
          setSaveBtnLoading(false);
        }
      });
  };

  const getCompanyList = () => {
    const endPoint = `company/find-list`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.company_name,
            });
          });
          const company = response?.filter(
            (data) => data?.id === empData?.company?.company_id
          );

          company?.map((data) => {
            setCompanyStatusData(data);
          });

          setCompanyList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  console.log("companyStatusData=============>", companyStatusData);

  const getCompanyData = (value) => {
    const endPoint = `company/get/${value}`;
    getAPIData(endPoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          console.log("response", response);
          setCompanyData(response);
          form.setFieldValue("pincode_id", response?.pincode_id);
          form.setFieldValue("district", response?.district);
          form.setFieldValue("state", response?.state);
          setCompanyStatusData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getDepartmentList = () => {
    const endPoint = `employee/get-dep-list`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.name,
            });
          });
          setDepartmentList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getDesignationList = () => {
    const endPoint = `employee/get-des-list`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.name,
            });
          });
          setDesignationList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getProofList = () => {
    const endPoint = `master/list-proof-type`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.name,
            });
          });
          setProofList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadPincodes = () => {
    const endPoint = "internal-api/get-pincode";
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var pincode_list = [];
          result?.data.map(async (pin, key) => {
            pincode_list.push({
              value: pin.id,
              label: pin.pincode,
              state: pin.state,
              district: pin.district,
            });
          });
          setPincodeList(pincode_list);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
        initialValues={{
          company_id: empData?.company?.company_id,
          pincode_id: empData?.company?.pincode_id,
          address: empData?.company?.address,
          state: empData?.company?.state,
          district: empData?.company?.district,
          monthly_salary: empData?.salary?.monthly_salary,
          ctc: empData?.salary?.ctc,
          department_id: empData?.salary?.department_id,
          designation_id: empData?.salary?.designation_id,
          date_of_join: moment(empData?.salary?.date_of_join),
          proof_type_id: empData?.proof_img?.proof_type_id,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={8} className="gutter-row">
            <Row gutter={[16, 20]}>
              <Col className="gutter-row" span={24}>
                <Row gutter={[8, 8]}>
                  <Col span={companyStatusData?.status === "2" ? 12 : 24}>
                    <Form.Item
                      label="Company Name"
                      name="company_id"
                      className="poppins_medium"
                      rules={[
                        {
                          required: true,
                          message: "Company name Required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        className="br"
                        size="large"
                        options={companyList}
                        placeholder="Enter company name"
                        onChange={(value, options) => {
                          getCompanyData(value);
                          setCompanyId(value);
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toString().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toString()
                            .localeCompare((optionB?.label ?? "").toString())
                        }
                      />
                    </Form.Item>
                  </Col>
                  {companyStatusData?.status === "2" ? (
                    <Col span={12}>
                      <Form.Item
                        className="poppins_medium"
                        // style={{ marginLeft: "-45px" }}
                      >
                        <CLoadingButton
                          size="large"
                          value="DETAILS"
                          className="poppins_medium umoney_btn"
                          onClick={() => openModal("company-details")}
                          block
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Pincode"
              name="pincode_id"
              size="large"
              rules={[{ required: true, message: "pincode is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                showSearch
                className="br"
                size="large"
                options={pincodeList}
                onChange={getPincodeValue}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .localeCompare((optionB?.label ?? "").toString())
                }
                placeholder="Select pincode"
              />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Office Address"
              name="address"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Office Address Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter office address" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="District"
              name="district"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "City Required",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter district"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="State"
              name="state"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "state Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter state" disabled={true} />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Monthly Salary"
              name="monthly_salary"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Monthly salary Required",
                },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Monthly Salary contains only number.",
                },
              ]}
            >
              <Input size="large" placeholder="Enter monthly salary" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Annual CTC"
              name="ctc"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "CTC Required",
                },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "CTC contains only number.",
                },
              ]}
            >
              <Input size="large" placeholder="Enter annual CTC" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Department"
                  name="department_id"
                  className="poppins_medium"
                  rules={[
                    {
                      required: true,
                      message: "Department Required",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    options={departmentList}
                    placeholder="Select department"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={8} className="row-gutter">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Designation"
                  name="designation_id"
                  className="poppins_medium"
                  rules={[
                    {
                      required: true,
                      message: "Designation Required",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    options={designationList}
                    placeholder="Select designation"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Date Of Joining"
              name="date_of_join"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "select date of joining",
                },
              ]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Enter date of joining"
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(0, "day"))
                }
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item
              label="Proof Type"
              name="proof_type_id"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "select employment proof",
                },
              ]}
            >
              <Select
                options={proofList}
                size="large"
                placeholder="Select proof type"
              ></Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={2}></Col>
              <Col span={4}>
                <Form.Item className="poppins_medium mb-35" name="iamge_one">
                  {_.isEmpty(document1) ? (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        title=""
                        className="file-choose"
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "transparent",
                          opacity: 0,
                          position: "relative",
                          zIndex: "200",
                        }}
                        onChange={handleImage1}
                      />
                      <div
                        style={{
                          position: "relative",
                          bottom: "55%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Text>
                          {" "}
                          <PlusOutlined /> Upload document 1
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <img
                        src={document1}
                        style={{ height: "100%", width: "100%" }}
                      ></img>
                      <CCloseIcon
                        onClick={() => setDocument1("")}
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                        }}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item className="poppins_medium mb-35" name="iamge_two">
                  {_.isEmpty(document2) ? (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        title=""
                        className="file-choose"
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "transparent",
                          opacity: 0,
                          position: "relative",
                          zIndex: "200",
                        }}
                        onChange={handleImage2}
                      />
                      <div
                        style={{
                          position: "relative",
                          bottom: "55%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Text>
                          {" "}
                          <PlusOutlined /> Upload document 2
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <img
                        src={document2}
                        style={{ height: "100%", width: "100%" }}
                      ></img>
                      <CCloseIcon
                        onClick={() => setDocument2("")}
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                        }}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item className="poppins_medium mb-35" name="iamge_three">
                  {_.isEmpty(document3) ? (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        title=""
                        className="file-choose"
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "transparent",
                          opacity: 0,
                          position: "relative",
                          zIndex: "200",
                        }}
                        onChange={handleImage3}
                      />
                      <div
                        style={{
                          position: "relative",
                          bottom: "55%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Text>
                          {" "}
                          <PlusOutlined /> Upload document 3
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <img
                        src={document3}
                        style={{ height: "100%", width: "100%" }}
                      ></img>
                      <CCloseIcon
                        onClick={() => setDocument3("")}
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                        }}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item className="poppins_medium mb-35" name="iamge_four">
                  {_.isEmpty(document4) ? (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        title=""
                        className="file-choose"
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "transparent",
                          opacity: 0,
                          position: "relative",
                          zIndex: "200",
                        }}
                        onChange={handleImage4}
                      />
                      <div
                        style={{
                          position: "relative",
                          bottom: "55%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Text>
                          {" "}
                          <PlusOutlined /> Upload document 4
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <img
                        src={document4}
                        style={{ height: "100%", width: "100%" }}
                      ></img>
                      <CCloseIcon
                        onClick={() => setDocument4("")}
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                        }}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item className="poppins_medium mb-35" name="iamge_five">
                  {_.isEmpty(document5) ? (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        title=""
                        className="file-choose"
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "transparent",
                          opacity: 0,
                          position: "relative",
                          zIndex: "200",
                        }}
                        onChange={handleImage5}
                      />
                      <div
                        style={{
                          position: "relative",
                          bottom: "55%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Text>
                          {" "}
                          <PlusOutlined /> Upload document 5
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundColor: "#dffefc",
                        border: "1px dashed #009d93",
                        borderRadius: "2px",
                      }}
                    >
                      <img
                        src={document5}
                        style={{ height: "100%", width: "100%" }}
                      ></img>
                      <CCloseIcon
                        onClick={() => setDocument5("")}
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "0px",
                        }}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="gutter-row">
            <CLoadingButton
              size="large"
              value="SAVE"
              htmlType="submit"
              className="poppins_medium umoney_btn"
              loading={saveBtnLoading}
              block
              onClick={() => setIdApprover(false)}
            />
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item>
              <CLoadingButton
                size="large"
                htmlType="submit"
                value="SAVE & APPROVE"
                loading={approveBtnLoading}
                onClick={() => setIdApprover(true)}
                className="poppins_medium umoney_btn"
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={modalType}
        title={modalTitle}
        footer={false}
        onCancel={handleClose}
        centered={true}
        width={modalWidth}
      >
        {modalType === "company-details" ? (
          <CompanyDetailsModal companyId={companyId} />
        ) : (
          ""
        )}
      </Modal>
      {modalTypeCrop === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalTypeCrop}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
    </div>
  );
  async function findArrayElementByTitle(array, value) {
    return array.find((element) => {
      return element.value === value;
    });
  }
};

export default EmploymentInformtaionDetails;
