import React from "react";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Form, Input, Col, Row, message } from "antd";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";
import _ from "lodash";

const RejectedReasonsModals = ({
  modalType,
  modalTitle,
  setModalType,
  isEdit,
  editId,
  setEditCancleIcon,
  setIsEdit,
  setEditId,
  editCancleIcon,
  loadRejectedReasonsData,
  editData,
}) => {
  const handleClose = () => {
    setModalType(false);
  };

  const [form] = Form.useForm();
  const [saveRejectBtnLoading, setSaveRejectBtnLoading] = useState(false);
  // const [value, setValue] = useState("");

  const onFinishRejectedReasons = (data) => {
    setSaveRejectBtnLoading(true);

    const endPoint =
      modalType === "add-reason"
        ? "system-config/image-rejected-reason/create"
        : "system-config/image-rejected-reason/update/" + editData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveRejectBtnLoading(false);
        if (result?.status === true) {
          form.resetFields();
          // setEditTitle("");
          handleClose();
          message.success(result?.message);
          // if (isEdit) {
          // setEditCancleIcon(false);
          // setIsEdit(false);
          // setEditId(null);
          // }
          loadRejectedReasonsData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedRejectedReasons = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const closeEdit = () => {
    setEditCancleIcon(false);
    setIsEdit(false);
    form.resetFields();
  };

  console.log("editData========>", editData);

  // useEffect(() => {
  //   if (!_.isNull(editId) && !_.isEmpty(editTitle)) {
  //     form.setFieldValue("title", editTitle);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [editId, editTitle, form]);

  useEffect(() => {
    if (modalType === "add-document") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }
  }, [form, editData, modalType]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={onFinishRejectedReasons}
          onFinishFailed={onFailedRejectedReasons}
          // initialValues={{
          //   // title: editTitle,
          // }}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Enter Reason"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Rejected reason is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter reason"
                  suffix={
                    editCancleIcon ? (
                      <CloseCircleOutlined onClick={closeEdit} />
                    ) : (
                      ""
                    )
                  }
                  // onChange={(_) => setValue(_.target.value)}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                className="poppins_medium umoney_btn"
                loading={saveRejectBtnLoading}
                block
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default RejectedReasonsModals;
