import { Col, Form, Input, message, Row, Select, Typography } from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import CLoadingButton from "../InputFields/CLoadingButton";
import { useState } from "react";
import { salaried_url, setHeader } from "../../api";
import axios from "axios";
import _ from "lodash";
import CropperModal from "../Modals/CropperModal";
import CCloseIcon from "../InputFields/CCloseIcon";
import { getAPIData } from "../../apiHelper";

const BankInformation = (props) => {
  const { Text } = Typography;
  const { Title } = Typography;

  const [form] = Form.useForm();
  const bankData = props?.bankData;
  console.log("bankData", bankData);
  const user_id = props?.user_id;
  console.log("user_id=====", user_id);

  const roleId = props?.userRoleId;

  const salaryTypeList = [
    {
      value: "1",
      label: "Cash",
    },
    {
      value: "2",
      label: "Bank",
    },
  ];

  const uploadViaList = [
    {
      value: "1",
      label: "File Upload",
    },
    {
      value: "2",
      label: "Net Banking",
    },
    {
      value: "3",
      label: "Bank OTP",
    },
  ];

  const [value, setValue] = useState(bankData?.salary_type);
  const [uploadViaValue, setUploadViaValue] = useState("1");
  const [callApiResponseAccount, setCallApiResponseAccount] = useState();

  //Cash
  const [cashDocument1, setCashDocument1] = useState([]);
  const [cashDocument2, setCashDocument2] = useState([]);
  const [cashDocument3, setCashDocument3] = useState([]);
  const [cashDocument4, setCashDocument4] = useState([]);
  const [cashDocument5, setCashDocument5] = useState([]);
  const [cashDocument6, setCashDocument6] = useState([]);

  //bank
  const [bankDocument1, setBankDocument1] = useState([]);
  const [bankDocument2, setBankDocument2] = useState([]);
  const [bankDocument3, setBankDocument3] = useState([]);
  const [bankDocument4, setBankDocument4] = useState([]);
  const [bankDocument5, setBankDocument5] = useState([]);
  const [bankDocument6, setBankDocument6] = useState([]);
  const [bankDocument7, setBankDocument7] = useState([]);
  const [bankDocument8, setBankDocument8] = useState([]);
  const [bankDocument9, setBankDocument9] = useState([]);
  const [bankDocument10, setBankDocument10] = useState([]);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [callApiBtnLoading, setCallApiBtnLoading] = useState(false);

  const [callApiResponse, setCallApiResponse] = useState();

  const [detailsBtnLoading, setDetailsBtnLoading] = useState(false);

  const [ifscCode, setIfscCode] = useState(bankData?.ifsc);
  const [name, setName] = useState(bankData?.acc_name);
  const [accountNumber, setAccountNumber] = useState(bankData?.acc_number);

  const [count, setCount] = useState(0);
  console.log("count", count);
  const [viewCount, setViewCount] = useState(0);
  console.log("viewCount", viewCount);

  const [srcImg, setSrcImg] = useState();
  const [modalType, setModalType] = useState("");

  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // bank Image name
  const [uploadFileNameB1, setUploadFileNameB1] = useState("");
  const [uploadFileNameB2, setUploadFileNameB2] = useState("");
  const [uploadFileNameB3, setUploadFileNameB3] = useState("");
  const [uploadFileNameB4, setUploadFileNameB4] = useState("");
  const [uploadFileNameB5, setUploadFileNameB5] = useState("");
  const [uploadFileNameB6, setUploadFileNameB6] = useState("");
  const [uploadFileNameB7, setUploadFileNameB7] = useState("");
  const [uploadFileNameB8, setUploadFileNameB8] = useState("");
  const [uploadFileNameB9, setUploadFileNameB9] = useState("");
  const [uploadFileNameB10, setUploadFileNameB10] = useState("");

  //cash image name
  const [uploadFileNameC1, setUploadFileNameC1] = useState("");
  const [uploadFileNameC2, setUploadFileNameC2] = useState("");
  const [uploadFileNameC3, setUploadFileNameC3] = useState("");
  const [uploadFileNameC4, setUploadFileNameC4] = useState("");
  const [uploadFileNameC5, setUploadFileNameC5] = useState("");
  const [uploadFileNameC6, setUploadFileNameC6] = useState("");

  const ApiClick = async () => {
    props?.loading(true);
    setCallApiBtnLoading(true);
    const header = setHeader();
    const endpoint = salaried_url + "student/get-ifsc-data";

    const data = {
      ifsc_code: form.getFieldValue("ifsc"),
    };

    console.log("data", data);

    const uploadData = await axios.post(endpoint, data, header);
    if (uploadData?.data?.status) {
      var response = uploadData?.data?.data;
      setCallApiResponse(response);
      setCount(count + 1);
      console.log("callApiresponse", callApiResponse);
      props.loading(false);
      setCallApiBtnLoading(false);
    } else {
      message.warning(uploadData?.data?.message);
      props.loading(false);
      setCallApiBtnLoading(false);
    }
  };

  const onFinish = async (data) => {
    props?.loading(true);
    setSaveBtnLoading(true);

    const endpoint = "steps/agent-save-bank-data";

    let images = [];

    data.type = "admin";
    data.user_id = user_id;

    if (value === "1") {
      if (cashDocument1?.[0]) {
        images.push({
          imageBase64: cashDocument1.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameC1,
          type: "imageOne",
        });
      }

      if (cashDocument2?.[0]) {
        images.push({
          imageBase64: cashDocument2.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameC2,
          type: "imageTwo",
        });
      }

      if (cashDocument3?.[0]) {
        images.push({
          imageBase64: cashDocument3.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameC3,
          type: "imageThree",
        });
      }

      if (cashDocument4?.[0]) {
        images.push({
          imageBase64: cashDocument4.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameC4,
          type: "imageFour",
        });
      }

      if (cashDocument5?.[0]) {
        images.push({
          imageBase64: cashDocument5.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameC5,
          type: "imageFive",
        });
      }

      if (cashDocument6?.[0]) {
        images.push({
          imageBase64: cashDocument6.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameC6,
          type: "form_upload",
        });
      }
    } else {
      if (bankDocument1?.[0]) {
        images.push({
          imageBase64: bankDocument1.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB1,
          type: "imageOne",
        });
      }
      if (bankDocument2?.[0]) {
        images.push({
          imageBase64: bankDocument2.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB2,
          type: "imageTwo",
        });
      }
      if (bankDocument3?.[0]) {
        images.push({
          imageBase64: bankDocument3.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB3,
          type: "imageThree",
        });
      }
      if (bankDocument4?.[0]) {
        images.push({
          imageBase64: bankDocument4.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB4,
          type: "imageFour",
        });
      }
      if (bankDocument5?.[0]) {
        images.push({
          imageBase64: bankDocument5.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB5,
          type: "imageFive",
        });
      }
      if (bankDocument6?.[0]) {
        images.push({
          imageBase64: bankDocument6.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB6,
          type: "imageSix",
        });
      }
      if (bankDocument7?.[0]) {
        images.push({
          imageBase64: bankDocument7.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB7,
          type: "imageSeven",
        });
      }
      if (bankDocument8?.[0]) {
        images.push({
          imageBase64: bankDocument8.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB8,
          type: "imageEight",
        });
      }
      if (bankDocument9?.[0]) {
        images.push({
          imageBase64: bankDocument9.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB9,
          type: "imageNine",
        });
      }
      if (bankDocument10?.[0]) {
        images.push({
          imageBase64: bankDocument10.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ""
          ),
          filename: uploadFileNameB10,
          type: "imageTen",
        });
      }
    }

    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }
    getAPIData(endpoint, "post", data, false, "salaried")
      .then((result) => {
        if (result?.status === true) {
          props?.isSuccess(true);
          props?.loading(false);
          message.success(result?.message);
          setSaveBtnLoading(false);
        } else {
          message.warning(result?.message);
          props?.loading(false);
          setSaveBtnLoading(false);
        }
        props?.loading(false);
        setSaveBtnLoading(false);
      })
      .catch((err) => {
        message?.error(err);
        props?.loading(false);
        setSaveBtnLoading(false);
      });
  };

  const onFinishFailed = () => {
    console.log("Failed");
  };

  const [fileList, setFileList] = useState([]);
  console.log(fileList.length);

  const ApiClickAccount = async () => {
    setDetailsBtnLoading(true);
    try {
      props?.loading(true);
      const header = setHeader();
      const endpoint = salaried_url + "student/bank-kyc-kart-data";

      var acc_number = form.getFieldValue("acc_number");
      var ifsc_code = form.getFieldValue("ifsc");
      var name = form.getFieldValue("acc_name");
      var role_id = roleId;
      var userId = user_id;

      const data = {
        user_id: userId,
        acc_number: acc_number,
        ifsc_code: ifsc_code,
        name: name,
        user_role_id: role_id,
      };

      console.log("data", data);

      const uploadData = await axios.post(endpoint, data, header);
      // if (uploadData?.data?.status) {
      var response = uploadData?.data;
      setViewCount(viewCount + 1);
      console.log("response", response);
      setCallApiResponseAccount(response);
      setDetailsBtnLoading(false);
      props.loading(false);
      // } else {
      // message.warning(uploadData?.data?.message);
      // props.loading(false);
      // }
    } catch (error) {
      console.log("error", error);
      message.error(error);
      setDetailsBtnLoading(false);
    }
    setDetailsBtnLoading(false);
  };

  console.log("resposne======>", callApiResponseAccount);

  const openModal = (type) => {
    if (type === "crop-image") {
      setModalType(type);
      setModalOpen(true);
    }
  };

  if (!_.isEmpty(imageDoc) && imageType === "cash1") {
    setCashDocument1(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "cash2") {
    setCashDocument2(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "cash3") {
    setCashDocument3(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "cash4") {
    setCashDocument4(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "cash5") {
    setCashDocument5(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "cash6") {
    setCashDocument6(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  const handleImageCash1 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameC1(event.target.files[0]?.name);
    setImageType("cash1");
    openModal("crop-image");
  };
  const handleImageCash2 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameC2(event.target.files[0]?.name);
    setImageType("cash2");
    openModal("crop-image");
  };
  const handleImageCash3 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameC3(event.target.files[0]?.name);
    setImageType("cash3");
    openModal("crop-image");
  };
  const handleImageCash4 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameC4(event.target.files[0]?.name);
    setImageType("cash4");
    openModal("crop-image");
  };
  const handleImageCash5 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameC5(event.target.files[0]?.name);
    setImageType("cash5");
    openModal("crop-image");
  };
  const handleImageCash6 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameC6(event.target.files[0]?.name);
    setImageType("cash6");
    openModal("crop-image");
  };

  if (!_.isEmpty(imageDoc) && imageType === "bank1") {
    setBankDocument1(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank2") {
    setBankDocument2(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank3") {
    setBankDocument3(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank4") {
    setBankDocument4(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank5") {
    setBankDocument5(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank6") {
    setBankDocument6(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank7") {
    setBankDocument7(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank8") {
    setBankDocument8(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank9") {
    setBankDocument9(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "bank10") {
    setBankDocument10(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  const handleImageBank1 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB1(event.target.files[0]?.name);
    setImageType("bank1");
    openModal("crop-image");
  };
  const handleImageBank2 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB2(event.target.files[0]?.name);
    setImageType("bank2");
    openModal("crop-image");
  };
  const handleImageBank3 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB3(event.target.files[0]?.name);
    setImageType("bank3");
    openModal("crop-image");
  };
  const handleImageBank4 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB4(event.target.files[0]?.name);
    setImageType("bank4");
    openModal("crop-image");
  };
  const handleImageBank5 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB5(event.target.files[0]?.name);
    setImageType("bank5");
    openModal("crop-image");
  };
  const handleImageBank6 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB6(event.target.files[0]?.name);
    setImageType("bank6");
    openModal("crop-image");
  };
  const handleImageBank7 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB7(event.target.files[0]?.name);
    setImageType("bank7");
    openModal("crop-image");
  };
  const handleImageBank8 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB8(event.target.files[0]?.name);
    setImageType("bank8");
    openModal("crop-image");
  };
  const handleImageBank9 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB9(event.target.files[0]?.name);
    setImageType("bank9");
    openModal("crop-image");
  };
  const handleImageBank10 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setUploadFileNameB10(event.target.files[0]?.name);
    setImageType("bank10");
    openModal("crop-image");
  };

  return (
    <div style={{ width: "100%" }}>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
        initialValues={{
          salary_type: bankData?.salary_type,
          ifsc: bankData?.ifsc,
          acc_name: bankData?.acc_name,
          acc_number: bankData?.acc_number,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row mt-5" span={8}>
            <Form.Item
              label="Salary Type"
              name="salary_type"
              size="large"
              rules={[{ required: true, message: "Salary Type is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                size="large"
                className="poppins_reguler filter_select_border"
                options={salaryTypeList}
                onChange={(e) => setValue(e)}
                placeholder="Select salary type"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row mt-5" span={8}>
            <Row gutter={[16, 16]}>
              <Col span={14}>
                <Form.Item
                  label="IFSC CODE"
                  name="ifsc"
                  size="large"
                  rules={[
                    { required: true, message: "IFSC Code is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    className="poppins_reguler filter_select_border"
                    placeholder="Enter IFSC code"
                    onChange={(e) => setIfscCode(e.target.value)}
                  />
                </Form.Item>
                <div style={{ padding: "5px" }}>
                  <Text className="poppins_medium">
                    {callApiResponse?.branch} {callApiResponse?.state}
                  </Text>
                </div>
              </Col>
              <Col span={10}>
                <Form.Item className="poppins_medium">
                  <CLoadingButton
                    size="large"
                    value="CALL API"
                    className="poppins_medium umoney_btn"
                    loading={callApiBtnLoading}
                    onClick={() => ApiClick()}
                    block
                    disabled={_.isEmpty(ifscCode) ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row mt-5" span={8}>
            <Form.Item
              label="Account Name"
              name="acc_name"
              size="large"
              rules={[
                { required: true, message: "Account Name is required!" },
                {
                  pattern: new RegExp(/^[a-zA-Z ]*$/),
                  message: "Account name only contain alphabets",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                className="poppins_reguler filter_select_border"
                placeholder="Enter account name"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row mt-5" span={8}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Account Number"
                  name="acc_number"
                  size="large"
                  rules={[
                    { required: true, message: "Account Number is required!" },
                    {
                      max: 20,
                      message: "Account number contains maximum 20 digits ",
                    },
                    {
                      min: 6,
                      message: "Account number contains minimum 6 digits ",
                    },
                    {
                      pattern: /^[0-9]\d*(\.\d+)?$/g,
                      message: "Account Number contains only number.",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    className="poppins_reguler filter_select_border"
                    placeholder="Enter account number"
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CLoadingButton
                  size="large"
                  value="VIEW DETAILS"
                  className="poppins_medium umoney_btn"
                  onClick={ApiClickAccount}
                  loading={detailsBtnLoading}
                  block
                  disabled={
                    _.isEmpty(ifscCode) ||
                    _.isEmpty(name) ||
                    _.isEmpty(accountNumber)
                      ? true
                      : false
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Upload Via"
              name="upload_via"
              size="large"
              // rules={[{ required: true, message: "Choose Upload Via" }]}
              className="poppins_medium mb-35"
            >
              <Select
                size="large"
                options={uploadViaList}
                className="poppins_reguler filter_select_border"
                onChange={(e) => setUploadViaValue(e)}
                disabled={true}
                defaultValue="1"
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <div
              className="border-class"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <Title level={5} className="poppins_medium umoney_title p-10">
                API RESPONSE
              </Title>
              <Row style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
                <Col className="gutter-row" span={24}>
                  <Text className="poppins_medium">
                    {callApiResponseAccount?.data?.account_status ===
                    "active" ? (
                      <div>
                        <p>
                          <b>Account Number:</b>{" "}
                          {
                            callApiResponseAccount?.data?.details
                              ?.account_number
                          }
                        </p>
                        <p>
                          <b>BANK REFERENCE:</b>{" "}
                          {
                            callApiResponseAccount?.data?.details
                              ?.bank_reference
                          }
                        </p>
                        <p>
                          <b>IFSC:</b>{" "}
                          {callApiResponseAccount?.data?.details?.ifsc}
                        </p>
                        <p>
                          <b>NAME:</b>{" "}
                          {
                            callApiResponseAccount?.data?.details
                              ?.registered_name
                          }
                        </p>
                      </div>
                    ) : callApiResponseAccount?.data?.account_status ===
                      "inactive" ? (
                      "Account is Inactive or invalid"
                    ) : (
                      ""
                    )}
                  </Text>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            {value === "1" && uploadViaValue === "1" ? (
              <Row gutter={[16, 16]}>
                <Col span={2}></Col>
                <Col span={4}>
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_cash_one"
                  >
                    {_.isEmpty(cashDocument1) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageCash1}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 1
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={cashDocument1}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setCashDocument1("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_cash_two"
                  >
                    {_.isEmpty(cashDocument2) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageCash2}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 2
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={cashDocument2}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setCashDocument2("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_cash_three"
                  >
                    {_.isEmpty(cashDocument3) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageCash3}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 3
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={cashDocument3}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setCashDocument3("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_cash_four"
                  >
                    {_.isEmpty(cashDocument4) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageCash4}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 4
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={cashDocument4}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setCashDocument4("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_cash_five"
                  >
                    {_.isEmpty(cashDocument5) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageCash5}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 5
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={cashDocument5}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setCashDocument5("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={2}></Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="form_upload"
                  >
                    {_.isEmpty(cashDocument6) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageCash6}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Cash Form
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={cashDocument6}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setCashDocument6("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : value === "2" && uploadViaValue === "1" ? (
              <Row gutter={[16, 16]}>
                <Col span={2}></Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_bank_one"
                  >
                    {_.isEmpty(bankDocument1) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageBank1}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 1
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={bankDocument1}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setBankDocument1("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_bank_two"
                  >
                    {_.isEmpty(bankDocument2) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageBank2}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 2
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={bankDocument2}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setBankDocument2("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_bank_three"
                  >
                    {_.isEmpty(bankDocument3) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageBank3}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 3
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "200px",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={bankDocument3}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setBankDocument3("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_bank_four"
                  >
                    {_.isEmpty(bankDocument4) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageBank4}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 4
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={bankDocument4}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setBankDocument4("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>{" "}
                </Col>
                <Col span={4}>
                  {" "}
                  <Form.Item
                    className="poppins_medium mb-35"
                    name="image_bank_five"
                  >
                    {_.isEmpty(bankDocument5) ? (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageBank5}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload Document 5
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={bankDocument5}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setBankDocument5("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>{" "}
                </Col>
                <Col span={2}></Col>
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={2}></Col>
                    <Col span={4}>
                      {" "}
                      <Form.Item
                        className="poppins_medium mb-35"
                        name="image_bank_six"
                      >
                        {_.isEmpty(bankDocument6) ? (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              className="file-choose"
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "transparent",
                                opacity: 0,
                                position: "relative",
                                zIndex: "200",
                              }}
                              onChange={handleImageBank6}
                            />
                            <div
                              style={{
                                position: "relative",
                                bottom: "55%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Text>
                                {" "}
                                <PlusOutlined /> Upload Document 6
                              </Text>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={bankDocument6}
                              style={{ height: "100%", width: "100%" }}
                            ></img>
                            <CCloseIcon
                              onClick={() => setBankDocument6("")}
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "0px",
                              }}
                            />
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        className="poppins_medium mb-35"
                        name="image_bank_seven"
                      >
                        {_.isEmpty(bankDocument7) ? (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              className="file-choose"
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "transparent",
                                opacity: 0,
                                position: "relative",
                                zIndex: "200",
                              }}
                              onChange={handleImageBank7}
                            />
                            <div
                              style={{
                                position: "relative",
                                bottom: "55%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Text>
                                {" "}
                                <PlusOutlined /> Upload Document 7
                              </Text>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={bankDocument7}
                              style={{ height: "100%", width: "100%" }}
                            ></img>
                            <CCloseIcon
                              onClick={() => setBankDocument7("")}
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "0px",
                              }}
                            />
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      {" "}
                      <Form.Item
                        className="poppins_medium mb-35"
                        name="image_bank_eight"
                      >
                        {_.isEmpty(bankDocument8) ? (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              className="file-choose"
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "transparent",
                                opacity: 0,
                                position: "relative",
                                zIndex: "200",
                              }}
                              onChange={handleImageBank8}
                            />
                            <div
                              style={{
                                position: "relative",
                                bottom: "55%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Text>
                                {" "}
                                <PlusOutlined /> Upload Document 8
                              </Text>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={bankDocument8}
                              style={{ height: "100%", width: "100%" }}
                            ></img>
                            <CCloseIcon
                              onClick={() => setBankDocument8("")}
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "0px",
                              }}
                            />
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        className="poppins_medium mb-35"
                        name="image_bank_nine"
                      >
                        {_.isEmpty(bankDocument9) ? (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              className="file-choose"
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "transparent",
                                opacity: 0,
                                position: "relative",
                                zIndex: "200",
                              }}
                              onChange={handleImageBank9}
                            />
                            <div
                              style={{
                                position: "relative",
                                bottom: "55%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Text>
                                {" "}
                                <PlusOutlined /> Upload Document 9
                              </Text>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={bankDocument9}
                              style={{ height: "100%", width: "100%" }}
                            ></img>
                            <CCloseIcon
                              onClick={() => setBankDocument9("")}
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "0px",
                              }}
                            />
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      {" "}
                      <Form.Item
                        className="poppins_medium mb-35"
                        name="image_bank_ten"
                      >
                        {_.isEmpty(bankDocument10) ? (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              title=""
                              className="file-choose"
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "transparent",
                                opacity: 0,
                                position: "relative",
                                zIndex: "200",
                              }}
                              onChange={handleImageBank10}
                            />
                            <div
                              style={{
                                position: "relative",
                                bottom: "55%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Text>
                                {" "}
                                <PlusOutlined /> Upload Document 10
                              </Text>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              backgroundColor: "#dffefc",
                              border: "1px dashed #009d93",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={bankDocument10}
                              style={{ height: "100%", width: "100%" }}
                            ></img>
                            <CCloseIcon
                              onClick={() => setBankDocument10("")}
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "0px",
                              }}
                            />
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>

          <Col span={8}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SAVE"
                htmlType="submit"
                loading={saveBtnLoading}
                className="poppins_medium umoney_btn"
                block
                disabled={count > 0 && viewCount > 0 ? false : true}
                style={{
                  cursor:
                    count > 0 && viewCount > 0 ? "pointer" : "not-allowed",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {modalType === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalType}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default BankInformation;
