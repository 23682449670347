import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  Col,
  Row,
  Form,
  message,
  Input,
  Switch,
} from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import MobileAppModals from "../../../components/Modals/MobileAppModals";
import _ from "lodash";
import { getAPIData } from "../../../apiHelper";
import CommonModals from "../../../components/Modals/CommonModals";
import { setHeader } from "../../../api";

const CollectionOrRepayment = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  //Save btn
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [smsData, setSMSData] = useState([]);
  const [deleteId, setDeleteId] = useState("");

  //open modal
  const [modalType, setModalType] = useState("");

  const onFinishRecoveryNotice = (values) => {
    setSaveBtnLoading(true);
    let data;
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "collection_or_repayment",
        key: key,
        value: value,
      })
    );
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          //var response = result?.data;
          message.success(result?.message);
          setSaveBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedRecoveryNotice = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  const loadTemplateData = () => {
    const endPoint = "template/sms-list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  //Set the values of Status in list
  const setValues = (email_id) => {
    setLoading(true);
    let endPoint = `template/sms-changeStatus/` + email_id;
    const header = setHeader();

    getAPIData(endPoint, "get", {}, header)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadTemplateData();
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadEditEmailData = (id) => {
    setLoading(true);
    const endPoint = "template/sms-get/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setSMSData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "collection_or_repayment" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              form.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();

    if (_.isEmpty(tableData)) {
      loadTemplateData();
    }
  }, [form, tableData]);

  const openModal = (type, id) => {
    if (type === "add-sms-content") {
      setModalType(type);
      setModalOpen(true);
    } else if (type === "edit-sms-content") {
      loadEditEmailData(id);
      setModalType(type);
      setModalOpen(true);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalOpen(true);
    }
  };

  const TableColumns = [
    {
      title: "SMS Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span>
          {record?.type === "1"
            ? "Registration"
            : record?.type === "2"
            ? "Bank"
            : record?.type === "3"
            ? "UPI"
            : "Help"}
        </span>
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "CONTENT",
      dataIndex: "message_body",
      key: "message_body",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <div>
          <span>
            <Switch
              checked={record.status === "1" ? true : false}
              onChange={() => {
                setValues(record?.id);
              }}
            />
          </span>
        </div>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-sms-content", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />

        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Form
              name="basic"
              onFinish={onFinishRecoveryNotice}
              onFinishFailed={onFailedRecoveryNotice}
              autoComplete="off"
              className="umoney-form"
              requiredMark={false}
              form={form}
            >
              <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                <Col span={24}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    FORMATE OR RECOVERY NOTICE
                  </Title>
                </Col>
                <Col span={12} className="poppins_medium boxshadow-div">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Recovery notice (pending)"
                        name="formate_of_recovery_notice"
                        rules={[
                          {
                            required: true,
                            message: "Recovery notice is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter Recovery notice"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      </Layout>
      {modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          title="Delete Confirmation"
          type="sms_template"
          setOpen={setModalOpen}
          modalType={modalType}
          id={deleteId}
          setModalType={setModalType}
          onDelete={(val) => {
            setModalOpen(false);
            loadTemplateData();
          }}
        />
      ) : (
        <MobileAppModals
          open={modalOpen}
          setOpen={setModalOpen}
          setModalType={setModalType}
          onSave={() => {
            setModalOpen(false);
            loadTemplateData();
          }}
          smsData={modalType === "edit-sms-content" ? smsData : []}
          title={
            modalType === "add-sms-content"
              ? "Add SMS content configuration"
              : "Edit SMS content configuration"
          }
          modalType={modalType}
        />
      )}
    </div>
  );
};

export default CollectionOrRepayment;
