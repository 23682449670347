import { Col, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import Title from "antd/lib/skeleton/Title";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";

const CompanyDetailsModal = (props) => {
  const { companyId } = props;

  const [form] = Form.useForm();
  const [companyData, setCompanyData] = useState([]);

  const loadCompanyDetails = () => {
    var endPoint = `student/get-company-data/${companyId}`;
    getAPIData(endPoint, "get", {}, false, "salaried")
      .then((result) => {
        const response = result?.data;
        if (result?.status === true) {
          setCompanyData(response);
          form.setFieldsValue({
            company_name: response?.company_name,
            no_of_employee:
              response?.no_of_employee === "0"
                ? "0-500"
                : response?.no_of_employee === "1"
                ? "501-1000"
                : response?.no_of_employee === "2"
                ? "1001-5000"
                : response?.no_of_employee === "3"
                ? ">5000"
                : "",
            tentative_salary_date:
              response?.tentative_salary_date === "1"
                ? "1 to 10"
                : response?.tentative_salary_date === "2"
                ? "11 to 20"
                : response?.tentative_salary_date === "3"
                ? "21 to 31"
                : "",
            company_type:
              response?.company_type === "0"
                ? "Government"
                : response?.company_type === "1"
                ? "Private"
                : "",
            address: response?.address,
            landmark: response?.landmark,
            state: response?.pincode_id?.state,
            district: response?.pincode_id?.district,
            pincode_id: response?.pincode_id?.pincode,
            website: response?.website,
            google_map_link: response?.google_map_link,
          });
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    loadCompanyDetails();
  }, [companyId]);

  return (
    <div className="white_div boxshadow-div">
      <Form
        name="basic"
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
          <Col className="gutter-row" span={18}>
            <Form.Item
              label="Name of company"
              name="company_name"
              rules={[
                {
                  required: true,
                  message: "Name of company is required!",
                },
                {
                  message: "Please Enter Only Characters",
                  pattern: new RegExp(/[a-zA-Z]/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                placeholder="Enter Name of company"
                readOnly
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="No of employee"
              name="no_of_employee"
              rules={[
                {
                  required: true,
                  message: "No of employee is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input className="br" size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Tentative salary date"
              name="tentative_salary_date"
              rules={[
                {
                  required: true,
                  message: "Tentative salary date is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input className="br" size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Company type"
              name="company_type"
              rules={[
                {
                  required: true,
                  message: "Company type is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input className="br" size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Title level={5} style={{ fontSize: "18px" }}>
              Address
            </Title>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Address is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Landmark"
              name="landmark"
              rules={[
                {
                  required: true,
                  message: "Landmark is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="State"
              name="state"
              // rules={[
              //   {
              //     required: true,
              //     message: "State is required!",
              //   },
              // ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="District"
              name="district"
              // rules={[
              //   {
              //     required: true,
              //     message: "District is required!",
              //   },
              // ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Pincode"
              name="pincode_id"
              rules={[
                {
                  required: true,
                  message: "Pincode is required!",
                },
                {
                  message: "Please Enter Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Website"
              name="website"
              rules={[
                {
                  required: true,
                  message: "Website is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Google map link"
              name="google_map_link"
              rules={[
                {
                  required: true,
                  message: "Google map link is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

async function findArrayElementByTitle(array, value) {
  return array.find((element) => {
    return element.value === value;
  });
}

export default CompanyDetailsModal;
