import React from "react";
import { Modal, Col, Row, message, Typography } from "antd";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";

const CommonModals = (props) => {
  const {
    title,
    open,
    setOpen,
    modalType,
    id,
    type,
    setModalType,
    tableType,
    onDelete = () => {},
  } = props;

  const { Title } = Typography;

  const handleDelete = (id, type) => {
    var method = "GET";
    var data = {};
    let endPoint;
    if (type === "agent") {
      endPoint = `user-agent/remove-agent/${id}`;
    } else if (type === "email_template") {
      endPoint = `template/delete-email/${id}`;
    } else if (type === "sms_template") {
      endPoint = `template/sms-delete/${id}`;
    } else if (type === "notification_icon") {
      method = "POST";
      endPoint = `template/icon/change-status`;
      data = {
        icon_id: id,
        type: "remove",
      };
    } else if (type === "email_banner") {
      method = "POST";
      endPoint = `template/banner/change-status`;
      data = {
        banner_id: id,
        type: "remove",
      };
    } else if (type === "course") {
      endPoint = `course/delete/${id}`;
    } else if (type === "college") {
      endPoint = `institute/delete/${id}`;
    } else if (type === "notification_template") {
      endPoint = `template/notification-delete/${id}`;
    } else if (type === "utilization") {
      endPoint = `system-config/utilization/delete/${id}`;
    } else if (type === "terms") {
      endPoint = `system-config/terms/delete/${id}`;
    } else if (type === "signup_terms") {
      endPoint = `system-config/signup_terms/delete?terms_id=${id}`;
    } else if (type === "rejected-reason") {
      endPoint = `system-config/image-rejected-reason/delete/${id}`;
    } else if (type === "document-type") {
      endPoint = `system-config/document-type/delete/${id}`;
    } else if (type === "approveDevice") {
      endPoint = `system-config/device-change/delete/${id}`;
    } else if (type === "rejectDevice") {
      endPoint = `system-config/device-change/delete/${id}`;
    } else if (type === "debit-card") {
      endPoint = `system-config/debit-charges/delete/${id}`;
    } else if (type === "ext-interest-rate") {
      endPoint = `system-config/ext-interest-rate/delete/${id}`;
    } else if (type === "collection-days") {
      endPoint = `system-config/collection-days/delete/${id}`;
    } else if (type === "specialization") {
      endPoint = `specialization/delete/${id}`;
    } else if (type === "department") {
      endPoint = `master/department-delete/${id}`;
    } else if (type === "designation") {
      endPoint = `master/designation-delete/${id}`;
    } else if (type === "negative-words-apps") {
      endPoint = `system-config/negative/delete/${id}`;
    } else if (type === "earnRewardCoin") {
      endPoint = `system-config/earn_reward_coin/delete/${id}`;
    } else if (type === "company") {
      endPoint = `company/delete/${id}`;
    } else if (type === "company-branch") {
      endPoint = `company/branch-delete/${id}`;
    } else if (type === "CMS") {
      endPoint = `system-config/cms/delete/${id}`;
    } else if (type === "family") {
      endPoint = `system-config/family/delete`;
      method = "POST";
      data = {
        id: id,
        type: tableType === "income" ? "income" : "",
      };
    } else if (type === "dpdDelete") {
      endPoint = `system-config/dpd/delete/${id}`;
    } else if (type === "faq") {
      endPoint = `faq/faq-delete`;
      method = "POST";
      data = { id: id };
    } else if (type === "faq-Category") {
      endPoint = "faq/category-delete";
      method = "POST";
      data = { id: id };
    } else if (type === "emailUs") {
      endPoint = `email-us/delete/${id}`;
    } else if (type === "faq-sub-Category") {
      endPoint = `faq/sub-category-delete/${id}`;
    } else if (type === "user") {
      endPoint = "system-config/delete-info";
      method = "POST";
      data = { info_id: id };
    } else if (type === "internal-decision") {
      endPoint = "system-config/delete-internal-decision";
      method = "POST";
      data = { internal_id: id };
    } else if (type === "proof-type") {
      endPoint = "master/delete-proof-type";
      method = "POST";
      data = { proof_type_id: id };
    } else if (type === "tenure") {
      endPoint = "master/tenure/delete";
      method = "POST";
      data = { tenure_id: id };
    } else if (type === "fee-disbursal") {
      endPoint = "master/disbursal-fee/delete";
      method = "POST";
      data = { disbursal_id: id };
    } else if (type === "contact-status") {
      endPoint = "master/contact-status/delete";
      method = "POST";
      data = { contact_id: id };
    }

    getAPIData(endPoint, method, data, false)
      .then((result) => {
        if (result?.status === true) {
          onDelete(result?.status);
          message.success(result?.message);
        } else {
          message.warning(result?.message);
        }
        setModalType(false);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        footer={null}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={400}
      >
        {modalType === "delete" ? (
          <>
            <div style={{ justifyContent: "center" }}>
              <Title
                level={5}
                className="poppins_regular"
                style={{ textAlign: "center", marginBottom: "30px" }}
              >
                Are you sure you want to delete this ?
              </Title>
            </div>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <CLoadingButton
                  size="large"
                  value=" NO"
                  className="poppins_medium umoney_modal_outlined_btn"
                  onClick={() => {
                    setModalType("");
                    // onDelete();
                  }}
                  block
                />
              </Col>
              <Col className="gutter-row" span={12}>
                <CLoadingButton
                  size="large"
                  value=" YES"
                  className="poppins_medium umoney_modal_filled_btn"
                  onClick={() => {
                    handleDelete(id, type);
                    //setModalType("");
                  }}
                  block
                />
              </Col>
            </Row>{" "}
          </>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default CommonModals;
