import React, { useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import moment from "moment";

import { salaried_url, setHeader, student_url } from "../../api";
import axios from "axios";
import CLoadingButton from "../../components/InputFields/CLoadingButton";

const { Title } = Typography;
const PersonalInformationDetails = (props) => {
  const personalInfoData = props?.personalInfoData;
  const address = props?.address;
  const pincodeList = props?.pincodeList;
  const student_id = props?.user_id;

  const pageType = props?.pageType;

  const [form] = Form.useForm();
  const [idApprover, setIdApprover] = useState(false);

  const [disableSaveBtn, setDisableBtn] = useState(false);
  const [disableSaveApproveBtn, setDisableSaveApproveBtn] = useState(false);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [spouseVisible, setSpouseVisible] = useState(
    personalInfoData?.marital_status
  );

  const onFinish = async (data) => {
    if (idApprover) {
      props?.loading(true);
      setApproveBtnLoading(true);
    } else {
      props?.loading(true);
      setSaveBtnLoading(true);
    }
    console.log("success =====>>>>> ", data);
    const endpoint =
      pageType === "salaried"
        ? salaried_url + "steps/agent-save-personal-data"
        : student_url + "steps/save-personal-data";
    const header = setHeader();

    const dob = moment(data?.date_of_birth?._d).format("YYYY-MM-DD");

    const Personaldata = {
      first_name: data?.first_name,
      middle_name: data?.middle_name,
      last_name: data?.last_name,
      date_of_birth: dob,
      gender: data?.gender,
      marital_status: data?.marital_status,
      father_name: data?.father_name,
      mother_name: data?.mother_name,
      whatsapp_flag: data?.whatsapp_flag,
      alt_number: data?.alt_number,
      mobile_number: data?.mobile_number,
      current_address: data?.current_address,
      current_address_type: data?.current_address_type,
      current_pincode_id: data?.current_pincode_id,
      perm_address: data?.perm_address,
      perm_address_type: data?.perm_address_type,
      perm_pincode_id: data?.perm_pincode_id,
      user_id: student_id,
      spouse_name: data?.spouse_name,
      type: "admin",
      idApprover: idApprover,
      language: data?.language,
    };

    const uploadData = await axios.post(endpoint, Personaldata, header);
    if (uploadData?.data?.status) {
      props?.isSuccess(true);
      message.success(uploadData?.data?.message);
      props?.loadData();
      if (idApprover) {
        props?.loading(false);
        setApproveBtnLoading(false);
      } else {
        props?.loading(false);
        setSaveBtnLoading(false);
      }
    } else {
      message.warning(uploadData?.data?.message);
      if (idApprover) {
        props?.loading(false);
        setApproveBtnLoading(false);
      } else {
        props?.loading(false);
        setSaveBtnLoading(false);
      }
    }
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };
  const data = [
    {
      value: "1",
      label: "In hostel",
    },
    {
      value: "2",
      label: "In PG",
    },
    {
      value: "3",
      label: "On rent",
    },
    {
      value: "4",
      label: "Self-owned",
    },
    {
      value: "5",
      label: "With Parents",
    },
  ];

  const genderOptions = [
    {
      value: "m",
      label: "Male",
    },
    {
      value: "f",
      label: "Female",
    },
  ];

  const maritalOptions = [
    {
      value: "married",
      label: "Married",
    },
    {
      value: "unmarried",
      label: "Un-Married",
    },
  ];

  const Whatsapp = [
    {
      value: "2",
      label: "Alternate Number",
    },
    {
      value: "1",
      label: "Phone Number",
    },
  ];

  const getPincodeValuePerm = async (e) => {
    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("permanent_state", abcd?.state);
    form.setFieldValue("permanent_district", abcd?.district);
  };
  const getPincodeValueCurr = async (e) => {
    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("current_state", abcd?.state);
    form.setFieldValue("current_district", abcd?.district);
  };

  const changeAge = (date) => {
    if (date == null) {
      form.setFieldValue("age", null);
    } else {
      const birthDate = new Date(date);
      const difference = Date.now() - birthDate.getTime();
      const age = new Date(difference);
      const userAge = Math.abs(age.getUTCFullYear() - 1970);
      form.setFieldValue("age", userAge);
    }
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
        initialValues={{
          first_name: personalInfoData?.first_name,
          middle_name: personalInfoData?.middle_name,
          last_name: personalInfoData?.last_name,
          mobile_number: personalInfoData?.mobile_number,
          alt_number: personalInfoData?.alt_number,
          father_name: personalInfoData?.father_name,
          mother_name: personalInfoData?.mother_name,
          date_of_birth: moment(personalInfoData?.date_of_birth),
          age: personalInfoData?.age,
          gender: personalInfoData?.gender,
          marital_status: personalInfoData?.marital_status,
          whatsapp_flag: personalInfoData?.whatsapp_flag,
          perm_address: address?.perm_address,
          perm_pincode_id: address?.perm_pincode_id,
          permanent_district: address?.permanent_district,
          permanent_state: address?.permanent_state,
          perm_address_type: address?.perm_address_type,
          current_address: address?.current_address,
          current_pincode_id: address?.current_pincode_id,
          current_district: address?.current_district,
          current_state: address?.current_state,
          current_address_type: address?.current_address_type,
          spouse_name: personalInfoData?.spouse_name,
          language: personalInfoData?.language,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="First Name"
              name="first_name"
              size="large"
              rules={[
                { required: true, message: "First Name is required!" },
                {
                  pattern: new RegExp(
                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: "First Name only contain alphabets",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Middle Name"
              name="middle_name"
              size="large"
              className="poppins_medium mb-35"
              rules={[
                {
                  pattern: new RegExp(
                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: "Middle name only contain alphabets",
                },
              ]}
            >
              <Input size="large" type="text" placeholder="Enter middle name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Last Name"
              name="last_name"
              size="large"
              rules={[
                { required: true, message: "Last Name is required!" },
                {
                  pattern: new RegExp(
                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: "Last name only contain alphabets",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" placeholder="Enter last name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Phone Number"
              name="mobile_number"
              size="large"
              rules={[
                { required: true, message: "Phone Number is required!" },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Phone number contains only number.",
                },
                {
                  max: 10,
                  message: "Phone number contains maximum 10 digits.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                type="text"
                placeholder="Enter phone number"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Alternate Number"
              name="alt_number"
              size="large"
              rules={[
                { required: true, message: "Alternate Number is required!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("mobile_number") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Alternate Mobile No. should be different")
                    );
                  },
                }),
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Alternate number contains only number.",
                },
                {
                  max: 10,
                  message: "Alternate number contains maximum 10 digits.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                type="text"
                placeholder="Enter alternate number"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Father's Name"
              name="father_name"
              size="large"
              rules={[
                { required: true, message: "Father's name is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                type="text"
                placeholder="Enter father's name"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Mother's Name"
              name="mother_name"
              size="large"
              rules={[
                { required: true, message: "Mother's name is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                type="text"
                placeholder="Enter mother's name"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Date of Birth"
              name="date_of_birth"
              size="large"
              rules={[
                { required: true, message: "Date of birth is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <DatePicker
                size="large"
                placement="bottomRight"
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                onChange={(date) => changeAge(date)}
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(0, "day"))
                }
                placeholder="Enter date of birth"
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Age"
              name="age"
              size="large"
              rules={[{ required: true, message: "age is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                type="text"
                readOnly={true}
                placeholder="Enter age"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Gender"
              name="gender"
              size="large"
              rules={[{ required: true, message: "Gender is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                className="br"
                size="large"
                options={genderOptions}
                placeholder="Select gender"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Marital Status"
              name="marital_status"
              size="large"
              rules={[
                { required: true, message: "marital status is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                className="br"
                size="large"
                options={maritalOptions}
                placeholder="Select marital status"
                onChange={(e) => setSpouseVisible(e)}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Whatsapp Number"
              name="whatsapp_flag"
              size="large"
              rules={[
                { required: true, message: "Whatsapp number is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                className="br"
                size="large"
                options={Whatsapp}
                placeholder="select whatsapp number"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Language"
              name="language"
              size="large"
              rules={[
                { required: true, message: "Language is required!" },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: "Language only contains characters",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input className="br" size="large" placeholder="Enter language" />
            </Form.Item>
          </Col>
          {pageType === "salaried" && spouseVisible === "married" ? (
            <Col span={8}>
              <Form.Item
                label="Spouse Name"
                name="spouse_name"
                size="large"
                rules={[
                  { required: true, message: "Spouse Name is required!" },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  className="br"
                  size="large"
                  placeholder="Enter spouse name"
                />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}

          <Col className="gutter-row" span={16}></Col>
          <Col className="gutter-row" span={12}>
            <Title level={5} className="poppins_medium umoney_title p-10">
              Permanent address *Same as KYC
            </Title>
          </Col>
          <Col className="gutter-row" span={12}></Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Address"
              name="perm_address"
              size="large"
              rules={[{ required: true, message: "address is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Pincode"
              name="perm_pincode_id"
              size="large"
              rules={[{ required: true, message: "pincode is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                showSearch
                className="br"
                size="large"
                placeholder="Select pincode"
                options={pincodeList}
                onChange={getPincodeValuePerm}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .localeCompare((optionB?.label ?? "").toString())
                }
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="District"
              name="permanent_district"
              size="large"
              rules={[{ required: true, message: "city is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="State"
              name="permanent_state"
              size="large"
              rules={[{ required: true, message: "state is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Type"
              name="perm_address_type"
              size="large"
              rules={[{ required: true, message: "type is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                options={data}
                size="large"
                placeholder="Select address type"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={18}></Col>
          <Col className="gutter-row" span={12}>
            <Title level={5} className="poppins_medium umoney_title p-10">
              Current / Residence address
            </Title>
          </Col>
          <Col className="gutter-row" span={12}></Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Address"
              name="current_address"
              size="large"
              rules={[{ required: true, message: "address is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Pincode"
              name="current_pincode_id"
              size="large"
              rules={[{ required: true, message: "pincode is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                showSearch
                className="br"
                size="large"
                options={pincodeList}
                placeholder="Select pincode"
                onChange={getPincodeValueCurr}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .localeCompare((optionB?.label ?? "").toString())
                }
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="District"
              name="current_district"
              size="large"
              rules={[{ required: true, message: "city is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="State"
              name="current_state"
              size="large"
              rules={[{ required: true, message: "state is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Type"
              name="current_address_type"
              size="large"
              rules={[{ required: true, message: "type is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                options={data}
                size="large"
                placeholder="Select address type"
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={18}></Col>
          <Col className="gutter-row" span={6}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SAVE"
                htmlType="submit"
                onClick={() => setIdApprover(false)}
                className="poppins_medium umoney_btn"
                loading={saveBtnLoading}
                block
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SAVE & APPROVE"
                htmlType="submit"
                onClick={() => setIdApprover(true)}
                className="poppins_medium umoney_btn"
                loading={approveBtnLoading}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
async function findArrayElementByTitle(array, value) {
  return array.find((element) => {
    return element.value === value;
  });
}

export default PersonalInformationDetails;
