import React, { useState } from "react";
import { Layout, Col, Row, Typography } from "antd";
import Sidebar from "../../components/Sidebar";
import { MenuOutlined } from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CTableData from "../../components/InputFields/CTableData";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const { Header, Content } = Layout;

const History = () => {
  const { Title } = Typography;
  const [open, setOpen] = useState(false);
  const [selectedSingle, setSelectSingle] = useState("");
  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const pathType = pathName[1];

  const role = useSelector((state) => state.userData.accessRoleCode);
  const role_name = useSelector((state) => state.userData.accessRoleName);

  //sorting Btns
  const [totalCount, setTotalCount] = useState("");
  const [rejectedCount, setRejectedCount] = useState("");
  const [forwardToAppCount, setForwardToAppCount] = useState("");
  const [pendingCount, setPendingCount] = useState("");
  const [checkCount, setCheckCount] = useState("");
  const [approvedCount, setApprovedCount] = useState("");

  return (
    <>
      <div>
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title">
                {role_name}'s History
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                borderRadius: "5px",
                background: "#fff",
              }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      Allocated to me:{" "}
                    </Title>
                  </Col>
                  {role === "caller" ? (
                    <>
                      <Col className="gutter-row" span={8}>
                        <CLoadingButton
                          size="large"
                          value="Total actioned:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>

                      <Col className="gutter-row" span={8}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col span={8}>
                        <CLoadingButton
                          size="large"
                          value="Forwarded to Approver:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                    </>
                  ) : role === "approver" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Checked:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={checkCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Sent to Caller:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Completed:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Sent for Calling:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={approvedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                    </>
                  ) : role === "supervisor_caller" ||
                    role === "supervisor_approver" ||
                    role === "superadmin" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={approvedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Checked:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={checkCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  <Col className="gutter-row" span={24}>
                    <CTableData
                      setTotalCount={setTotalCount}
                      setForwardToAppCount={setForwardToAppCount}
                      setRejectedCount={setRejectedCount}
                      setCheckCount={setCheckCount}
                      setApprovedCount={setApprovedCount}
                      pathType={pathType}
                      selectedData={(val) => {
                        setSelectSingle(val);
                      }}
                      role={role}
                      setPendingCount={setPendingCount}
                    />
                  </Col>
                </Row>
                {role === "caller" ? (
                  <Row gutter={[16, 16]} className="mt-20">
                    <Col className="gutter-row" span={24}>
                      <Title level={5} className="poppins_medium umoney_title">
                        Forwarded To Approver:{" "}
                      </Title>
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Total:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={totalCount}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Back to Caller(Approved):"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Back to Caller(Rejected):"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Approved:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={approvedCount}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="Rejected:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={rejectedCount}
                        block
                      />
                    </Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="pending:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={pendingCount}
                        block
                      />
                    </Col>
                    <Col span={24}>
                      <CTableData
                        setTotalCount={setTotalCount}
                        setForwardToAppCount={setForwardToAppCount}
                        setRejectedCount={setRejectedCount}
                        setPendingCount={setPendingCount}
                        setCheckCount={setCheckCount}
                        setApprovedCount={setApprovedCount}
                        pathType={pathType}
                        selectedData={(val) => {
                          setSelectSingle(val);
                        }}
                        role={role}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default History;
