import React, { useState, useEffect } from "react";
import { Typography, Table, Row, Col, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import CommonModals from "../../../components/Modals/CommonModals";
import LoanDisbursalModals from "../../../components/Modals/LoanDisbursalModals";

const LoanDisbursal = () => {
  const [utilizationPurpose] = Form.useForm();
  const [termForm] = Form.useForm();
  const [loanDisbursal] = Form.useForm();
  const [paymentWalletFee] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  //Save btn
  const [saveWalletBtnLoading, setSaveWalletBtnLoading] = useState(false);
  const [saveDisbursalBtnLoading, setSaveDisbursalBtnLoading] = useState(false);

  // Utilization Data
  const [utilizationData, setUtilizationData] = useState([]);
  const [editCancleIcon, setEditCancleIcon] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(Number);
  const [deleteId, setDeleteId] = useState(Number);

  // Modal States
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [editData, setEditData] = useState("");
  const [termsEditData, setTermsEditData] = useState();

  // Terms States
  const [termsData, setTermsData] = useState([]);
  const [termsCancleIcon, setTermsCancleIcon] = useState(false);
  const [termsIsEdit, setTermsIsEdit] = useState(false);
  const [termsEditId, setTermsEditId] = useState(Number);
  const [termsDeleteId, setTermsDeleteId] = useState(Number);

  //validation for loan disbursal
  const [loanMinCharLimit, setLoanMinCharLimit] = useState("");
  const [loanMaxCharLimit, setLoanMaxCharLimit] = useState("");

  const openModal = (type, id, record) => {
    if (type === "add-purpose") {
      setModalType(type);
      setModalTitle("Add Utilization Purpose");
    } else if (type === "edit-purpose") {
      setEditData(record);
      setModalType(type);
      setModalTitle("Edit Utilization Purpose");
    } else if (type === "add-term") {
      setModalType(type);
      setModalTitle("Add Term");
    } else if (type === "edit-term") {
      setTermsEditData(record);
      setModalType(type);
      setModalTitle("Edit Term");
    }
  };

  //UTILIZATION PURPOSE

  const loadUtilizationList = () => {
    const endPoint = "system-config/utilization/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          setUtilizationData(result?.data);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadTermList = () => {
    const endPoint = "system-config/terms/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          setTermsData(result?.data);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  //PAYTM WALLTE FEE ON DISBURSAL %
  const onFinishPaymentWallet = (values) => {
    setSaveWalletBtnLoading(true);
    onSubmit("payment_wallet", values);
  };

  const onFailedPaymentWallet = (values) => {};

  //LOAN DISBURSAL LIMIT
  const onFinishLoanDisbursal = (values) => {
    const Sizecompare = parseInt(loanMaxCharLimit) > parseInt(loanMinCharLimit);

    if (!Sizecompare) {
      loanDisbursal.setFields([
        {
          name: "loan_disbursal_max_limit",
          errors: [
            "Loan disbursal Maximun  Limit should be greater than Loan disbursal Minimun Limit",
          ],
        },
      ]);
      setSaveDisbursalBtnLoading(false);
    } else {
      setSaveDisbursalBtnLoading(true);
      onSubmit("loan_disbursal_limit", values);
    }
  };

  const onFailedLoanDisbursal = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //On submit
  const onSubmit = (type, values) => {
    let data;
    let cat_array = [];

    if (type === "payment_wallet") {
      Object.entries(values).map(([key, value]) =>
        cat_array.push({
          category: "loan_disbursal",
          key: key,
          value: value,
        })
      );
    } else if (type === "loan_disbursal_limit") {
      Object.entries(values).map(([key, value]) =>
        cat_array.push({
          category: "loan_disbursal",
          key: key,
          value: value,
        })
      );
    }

    data = { system_data: cat_array };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          if (type === "payment_wallet") {
            message.success(result?.message);
            setSaveWalletBtnLoading(false);
          } else if (type === "loan_disbursal_limit") {
            message.success(result?.message);
            setSaveDisbursalBtnLoading(false);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "loan_disbursal" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              paymentWalletFee.setFieldsValue({ [data?.name]: data?.value });
              loanDisbursal.setFieldsValue({ [data?.name]: data?.value });
              if (data?.name === "loan_disbursal_min_limit") {
                setLoanMinCharLimit(data?.value);
              }
              if (data?.name === "loan_disbursal_max_limit") {
                setLoanMaxCharLimit(data?.value);
              }
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();

    if (_.isEmpty(utilizationData)) {
      loadUtilizationList();
    }
    loadTermList();
  }, [loanDisbursal, paymentWalletFee, utilizationData]);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                openModal("edit-purpose", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteType("utilization");
                setDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // termsEdit(record?.id, record?.name);
                openModal("edit-term", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteType("terms");
                setTermsDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  LIST OF UTILIZATION PURPOSE
                </Title>
              </Col>
              <Col className="gutter-row" span={6}>
                <CLoadingButton
                  size="large"
                  value="ADD"
                  icon={<PlusOutlined />}
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-purpose")}
                />
              </Col>
            </Row>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={tableColumns}
                dataSource={utilizationData}
              />
            </div>

            <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  TERMS
                </Title>
              </Col>
              <Col className="gutter-row" span={6}>
                <CLoadingButton
                  size="large"
                  value="ADD"
                  icon={<PlusOutlined />}
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-term")}
                />
              </Col>
            </Row>

            <Table
              dataSource={termsData}
              className="poppins_regular"
              pagination={false}
              columns={columns}
              rowClassName="device-change-panel-table"
              size="small"
              style={{
                marginTop: "20px",
                // whiteSpace: "pre",
                whiteSpace: "pre-wrap",
                textOverflow: "ellipsis",
                boxShadow: "0px 0px 6px #00000029",
                width: "50%",
              }}
            />

            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col className="gutter-row" span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  PAYTM WALLTE FEE ON DISBURSAL %
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishPaymentWallet}
                  onFinishFailed={onFailedPaymentWallet}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={paymentWalletFee}
                >
                  <Row gutter={[16, 16]}>
                    <Col className="gutter-row" span={24}>
                      <Form.Item
                        label="Paytm wallet fee on disbursal (pending)"
                        name="payment_wallet_fee_on_disbursal_per"
                        rules={[
                          {
                            required: true,
                            message:
                              "Paytm wallet fee on disbursal is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter paytm wallet fee on disbursal"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveWalletBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col className="gutter-row" span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  LOAN DISBURSAL LIMIT
                </Title>
              </Col>
              <Col span={12} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishLoanDisbursal}
                  onFinishFailed={onFailedLoanDisbursal}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={loanDisbursal}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Loan disbursal minimum limit (pending)"
                        name="loan_disbursal_min_limit"
                        onChange={(e) => {
                          setLoanMinCharLimit(e.target.value);
                        }}
                        rules={[
                          {
                            required: true,
                            message:
                              "Loan disbursal minimum limit is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter loan disbursal minimum limit"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Loan disbursal maximum limit (pending)"
                        name="loan_disbursal_max_limit"
                        onChange={(e) => {
                          setLoanMaxCharLimit(e.target.value);
                        }}
                        rules={[
                          {
                            required: true,
                            message:
                              "Loan disbursal maximum limit is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter loan disbursal maximum limit"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveDisbursalBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            setOpen={setModalOpen}
            title={
              deleteType === "utilization"
                ? "Delete Utilization"
                : "Delete Terms"
            }
            type={deleteType}
            modalType={modalType}
            setModalType={setModalType}
            id={deleteType === "utilization" ? deleteId : termsDeleteId}
            onDelete={(val) => {
              loadUtilizationList();
            }}
          />
        ) : modalType === "add-purpose" ? (
          <LoanDisbursalModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            isEdit={isEdit}
            onSave={() => {
              setModalType(false);
            }}
            loadUtilizationList={loadUtilizationList}
          />
        ) : modalType === "edit-purpose" ? (
          <LoanDisbursalModals
            editData={editData}
            modalTitle={modalTitle}
            modalType={modalType}
            isEdit={isEdit}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
            }}
            setEditCancleIcon={setEditCancleIcon}
            setIsEdit={setIsEdit}
            editCancleIcon={editCancleIcon}
            loadUtilizationList={loadUtilizationList}
          />
        ) : modalType === "add-term" ? (
          <LoanDisbursalModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            isEdit={isEdit}
            onSave={() => {
              setModalType(false);
            }}
            loadTermList={loadTermList}
          />
        ) : modalType === "edit-term" ? (
          <LoanDisbursalModals
            termsEditData={termsEditData}
            modalTitle={modalTitle}
            modalType={modalType}
            isEdit={isEdit}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
            }}
            setEditCancleIcon={setEditCancleIcon}
            setIsEdit={setIsEdit}
            editCancleIcon={editCancleIcon}
            loadTermList={loadTermList}
            termsCancleIcon={termsCancleIcon}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default LoanDisbursal;
