import React from "react";

const CClickLoading = () => {
  return (
    <div className="click-loader loader-1">
      <div className="loader-outter"></div>
      <div className="loader-inner"></div>
    </div>
  );
};

export default CClickLoading;
