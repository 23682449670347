import { Col, message, Modal, Row, Typography } from "antd";
import React, { useState } from "react";
import { getAPIData } from "../../../apiHelper";
import CLoadingButton from "../../InputFields/CLoadingButton";
import Remark from "../LoanFilter/Remark";

const ConfirmationDeviceChange = ({
  device_id,
  followup_ts,
  user_id,
  action_button,
  onClose,
  deviceId,
  userId,
  actionButton,
  handleCloseModal,
  loadData,
}) => {
  const { Title } = Typography;
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [yesBtnLoading,setYesBtnLoading]=useState(false);

  console.log("actionButton", actionButton);

  const close = () => {
    setModalType("");
  };

  const openModal = (type) => {
    if (type === "remark") {
      setModalType(type);
      setModalTitle("Remark");
      setModalWidth(500);
    }
  };

  const onReject = () => {
    setYesBtnLoading(true);
    const verifiedData = {
      user_id: userId,
      device_id: deviceId,
      action_button: actionButton,
    };
    const endPoint = "device/reject-btn";
    getAPIData(endPoint, "post", verifiedData, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setYesBtnLoading(false);  
          handleCloseModal();
          loadData();
        } else {
          message.warning(result?.message);
          setYesBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setYesBtnLoading(false);
      });
  };

  const followUp = () => {
    openModal("remark");
    onClose();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title
            level={5}
            className="poppins_medium"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Are you sure ?
          </Title>
        </Col>
        <Col span={12}>
          <CLoadingButton
            size="large"
            value="NO"
            onClick={() => handleCloseModal()}
            // loading={noBtnLoading}
            className="poppins_medium umoney_outlined_btn"
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <CLoadingButton 
            size="large"
            value="YES"
            onClick={() => {
              actionButton === "2"
                ? onReject()
                : actionButton === "3"
                ? followUp()
                : openModal("remark");
              onClose();
            }}
            loading={yesBtnLoading}
            className="poppins_medium umoney_modal_filled_btn"
            style={{ width: "100%", marginTop: "0px" }}
          />
        </Col>
      </Row>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={close}
        centered
        width={modalWidth}
        footer={false}
      >
        {modalType === "remark" ? (
          <Remark
            device_id={device_id}
            followup_ts={followup_ts}
            user_id={user_id}
            action_button={action_button}
            closeModal={() => setModalType("")}
            actionButton={actionButton}
            userId={userId}
            deviceId={deviceId}
            loadData={loadData}
          />
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default ConfirmationDeviceChange;
