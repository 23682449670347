/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import CClickLoading from "../../components/InputFields/CClickLoading";
import {
  Layout,
  Col,
  Row,
  Typography,
  Table,
  Tabs,
  Collapse,
  message,
  Form,
  Select,
  Input,
  Spin,
  Tag,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  PhoneOutlined,
  CheckSquareOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined,
  BorderOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import { Modal } from "antd";

import Images from "../../config/images";
import _ from "lodash";
import RemoveImage from "../../components/Modals/modal/RemoveImage";
import ClearInApp from "../../components/Modals/modal/ClearInApp";
import Viewchangelog from "../../components/Modals/modal/Viewchangelog";
import CallStatusLogModal from "../../components/Modals/modal/CommonModal/CallStatusLogModal";
import MatchFoundModal from "../../components/Modals/modal/MatchFoundModal";
import ClickToCallModal from "../../components/Modals/modal/ClickToCallModal";
import ClickToCallModal2 from "../../components/Modals/modal/ClickToCallModal2";
import KYCDetails from "../../components/Accordion/KYC/KYCDetails";
import KYCFront from "../../components/Accordion/KYC/KYCFront";
import KYCBack from "../../components/Accordion/KYC/KYCBack";
import PanDetails from "../../components/Accordion/PAN/PanDetails";
import PANFront from "../../components/Accordion/PAN/PANFront";
import PersonalInformationDetails from "../../components/Accordion/PersonalInformationDetails";
import StudentInformationDetails from "../../components/Accordion/StudentInformationDetails";
import SelfieDetails from "../../components/Accordion/SelfieDetails";
import VideoDetails from "../../components/Accordion/VideoDetails";
import UploadFiles from "../../components/Accordion/UploadFiles";
import { useLocation, useNavigate } from "react-router-dom";
import { getAPIData } from "../../apiHelper";
import StudentIdFront from "../../components/Accordion/StudentId/StudentIdFront";
import StudentIdBack from "../../components/Accordion/StudentId/StudentIdBack";
import Marksheet from "../../components/Accordion/Marksheet/Marksheet";
import { useDispatch } from "react-redux";
import authActions from "../../redux/reducers/auth/action";
import { useSelector } from "react-redux";
import commonActions from "../../redux/reducers/common/action";
import CallHistory from "../../components/Modals/modal/CallHistory";

const { user } = Images;

const { Header, Content } = Layout;
const { Panel } = Collapse;
const { TextArea } = Input;

var verifiedApproved = "#3EA467";
var pending = "#DE1717";
var completed = "#ffbf00";
var checked = "#0000FF";

const moduleOptions = [
  {
    value: "1",
    label: "KYC Details",
  },
  {
    value: "2",
    label: "PAN Details",
  },
  {
    value: "3",
    label: "Personal Information Details",
  },
  {
    value: "4",
    label: "Student Information Details",
  },
  {
    value: "5",
    label: "Selfie Details",
  },
  {
    value: "6",
    label: "Video Details",
  },
];

//SMS history
const smscolumns = [
  {
    title: "MODULE",
    dataIndex: "module",
    key: "module",
    width: "11%",
    render: (text, record) => (
      <div>
        <span>
          {moduleOptions.map((opt) =>
            opt.value === record.module ? opt.label : ""
          )}
        </span>
      </div>
    ),
  },
  {
    title: "SMS CONTENT",
    dataIndex: "content",
    key: "content",
    width: "40%",
  },
  {
    title: "TIME STAMP",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "DELIVERY STATUS",
    dataIndex: "delivery_status",
    key: "delivery_status",
    render: (text, record) => (
      <div>
        <span>
          {record.delivery_status === "1"
            ? "Sent"
            : record.delivery_status === "2"
            ? "Delivered"
            : record.delivery_status === "3"
            ? "Undelivered"
            : ""}
        </span>
      </div>
    ),
  },
  {
    title: "AGENT",
    dataIndex: "agent",
    key: "agent",
    width: "20%",
    render: (text, record) => (
      <div>
        <span>
          {record.agent_name} ({record.agent_role_name})
        </span>
      </div>
    ),
  },
];

const DetailView = () => {
  const location = useLocation();
  const m_num = location?.pathname.split("/");
  const paramname = m_num[1];
  const { setAccessToken } = authActions;

  const queryParameters = new URLSearchParams(location.search);
  // const agent_role = useSelector((state) => state.userData.accessRoleName);

  // const auth = useSelector((state) => state.auth);

  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const [type, setType] = useState("");

  //image
  const [fullName, setFullName] = useState("");
  const [name, setName] = useState("");

  //Modal
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [changeLoading, setChangeLoading] = useState(false);

  //student seven list api
  const [stdData, setStdData] = useState({});

  //pincodes
  const [pincodeList, setPincodeList] = useState(false);

  //call status
  const [callStatusSelectData, setCallStatusSelectData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState("");

  //Device List
  const [deviceListData, setDeviceListData] = useState([]);
  const [deviceListDrp, setDeviceListDrp] = useState([]);
  const [deviceList, setDeviceList] = useState();

  // Notification List api
  const [notificationData, setNotificationData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");

  // SMS List Api
  const [smsData, setSmsData] = useState([]);

  // whatsapp list api
  const [whatsappData, setWhatsappData] = useState([]);

  // Match Found Api
  const [matchFoundData, setMatchFoundData] = useState([]);

  //Table Loading
  const [notificationTableLoading, setNotificationTableLoading] =
    useState(true);
  const [smsTableLoading, setSmsTableLoading] = useState(true);
  const [whatsappTableLoading, setWhatsappTableLoading] = useState(true);

  //call status btn loading
  const [callStatusBtnLoading, setCallStatusBtnLoading] = useState(false);

  //approved Section btns
  const [btnsData, setBtnsData] = useState();

  // send Notification, sms, whatsapp
  const [notiSendBtnLoading, setNotiSendBtnLoading] = useState(false);
  const [smsSendBtnLoading, setSmsSendBtnLoading] = useState(false);
  const [whatsappSendBtnLoading, setWhatsappSendBtnLoading] = useState(false);

  //Form
  const [form] = Form.useForm();
  const [callStatusForm] = Form.useForm();
  const [informationForm] = Form.useForm();
  const [notificationForm] = Form.useForm();
  const [smsForm] = Form.useForm();

  //Click to call
  const [altNumber, setAltNumber] = useState();
  const [regNumber, setRegNumber] = useState();

  //Pagination
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);

  const [newUserId, setNewUserId] = useState();
  const dispatch = useDispatch();

  const [login, setLogin] = useState(false);
  const { setLoginTypeData, setRoleCode } = commonActions;

  const [dialerRole, setDialerRole] = useState();

  const pageType = "stu_onb";

  const user_id =
    paramname === "dialer-detail-view"
      ? newUserId
      : queryParameters.get("user_id");
  const agent_id = queryParameters.get("user");
  // const password = queryParameters.get("password");
  const mobile_num = queryParameters.get("phone_number");
  const unique_id = queryParameters.get("uniqueid");
  const call_id = queryParameters.get("call_id");
  const SIPexten = queryParameters.get("SIPexten");
  const CallResponse = queryParameters.get("call_response");
  const campaign = queryParameters.get("campaign");
  // const closer = queryParameters.get("closer");
  // const userName = queryParameters.get("user");
  // const agent_fullname = queryParameters.get("agent_fullname");
  const recording_id = queryParameters.get("recording_id");
  const recording_filename = queryParameters.get("recording_filename");

  const navigate = useNavigate();
  const login_type = useSelector((state) => state.userData.accessLoginType);

  const onFinishInformation = (data) => {
    console.log("success =====>>>>> ", data);
  };

  // send Sms
  const onFinishSms = (data) => {
    setSmsSendBtnLoading(true);
    setSpinLoading(true);

    const nData = {
      user_id: user_id,
      sms_temp_id: data?.sms_temp_id,
      comment: data?.comment,
    };

    const endPoint = "student/send-sms";
    getAPIData(endPoint, "post", nData, false, "student")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
          smsForm.resetFields();
          loadsmsListData();
        } else {
          message.warning(result?.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSmsSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  const getTabValue = (value) => {
    if (value === "1") {
      setSelectedTab("Notification");
      smsForm.resetFields();
      form.resetFields();
    } else if (value === "2") {
      setSelectedTab("SMS");
      notificationForm.resetFields();
      form.resetFields();
    } else if (value === "3") {
      setSelectedTab("WHATSAPP");
      smsForm.resetFields();
      notificationForm.resetFields();
    }
  };

  //send notification
  const onFinishNotification = (data) => {
    setNotiSendBtnLoading(true);
    setSpinLoading(true);

    const nData = {
      user_id: user_id,
      notification_temp_id: data?.notification_temp_id,
      comment: data?.comment,
    };

    const endPoint = "student/notificaton-send";
    getAPIData(endPoint, "post", nData, false, "student")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          notificationForm.resetFields();
          loadNotificationListData();
          setNotiSendBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setNotiSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setNotiSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  const handleClose = () => {
    setModalType("");
    setPage(1);
    setCurrent(1);
  };

  //Notification Table
  const notificationColumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "20%",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },

    {
      title: "COMMENT",
      dataIndex: "content",
      key: "content",
      width: "30%",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "17%",
    },
    {
      title: "SEEN",
      dataIndex: "read_ts",
      key: "read_ts",
      width: "17%",
      render: (text, record) => (
        <span> {!_.isEmpty(record?.read_ts) ? record?.read_ts : "-"} </span>
      ),
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      width: "20%",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  // whatsapp Table
  const whatsappColumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text, record) => (
        <div>
          <span>
            {moduleOptions.map((opt) =>
              opt.value === record.module ? opt.label : ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "COMMENT",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "TIME STAMP",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "AGENT",
      dataIndex: "agent",
      key: "agent",
      render: (text, record) => (
        <div>
          <span>
            {record.agent_name} ({record.agent_role_name})
          </span>
        </div>
      ),
    },
  ];

  //Phone Permission Table
  const pcolumns = [
    {
      title: "Module",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Permission",
      key: "tags",
      dataIndex: "tags",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
    },
  ];

  const tdata = [
    {
      key: "1",
      name: "Location",
      tags: (
        <div>
          <span>
            {deviceList?.location === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.location === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.location === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.location === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "2",
      name: "Contacts",
      tags: (
        <div>
          <span>
            {deviceList?.contacts === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.contacts === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.contacts === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.contacts === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "3",
      name: "SMS",
      tags: (
        <div>
          <span>
            {deviceList?.sms === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.sms === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.sms === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.sms === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "4",
      name: "Phone",
      tags: (
        <div>
          <span>
            {deviceList?.phone === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.phone === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.phone === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.phone === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "5",
      name: "Call Logs",
      tags: (
        <div>
          <span>
            {deviceList?.call_logs === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.call_logs === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.call_logs === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.call_logs === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "6",
      name: "Camera",
      tags: (
        <div>
          <span>
            {deviceList?.camera === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.camera === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.camera === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.camera === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "7",
      name: "Storage",
      tags: (
        <div>
          <span>
            {deviceList?.storage === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.storage === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.storage === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.storage === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "8",
      name: "Installed Application",
      tags: (
        <div>
          <span>
            {deviceList?.install_apps === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.install_apps === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.install_apps === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.install_apps === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      key: "9",
      name: "Audio",
      tags: (
        <div>
          <span>
            {deviceList?.audio === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.audio === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.audio === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : deviceList?.audio === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
  ];

  // Load User Data
  const loadData = () => {
    setLoading(true);
    const endPoint = `student/student-onboard-data/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          var personal_info = response?.personalInfo;
          var full_name =
            personal_info?.first_name +
            " " +
            personal_info?.middle_name +
            " " +
            personal_info?.last_name;
          setFullName(full_name);
          setName(personal_info?.first_name);
          setRegNumber(response?.personalInfo?.mobile_number);
          setAltNumber(response?.personalInfo?.alt_number);
          informationForm.setFieldValue("referred_by", response?.referUser);
          informationForm.setFieldValue(
            "profile_status",
            response?.profileStatus === "0"
              ? "Registered"
              : response?.profileStatus === "1"
              ? "Completed"
              : response?.profileStatus === "-1"
              ? "Reject"
              : response?.profileStatus === "2"
              ? "Approved"
              : response?.profileStatus === "3"
              ? "Checked"
              : ""
          );
          response?.percentage.map((data) => {
            setBtnsData(data);
          });
          setStdData(response);
          loadPincodes();
          setLoading(false);
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  // notification list api
  const loadNotificationListData = () => {
    const endPoint = `student/notification-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setNotificationData(response);
            setNotificationTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setNotificationTableLoading(false);
        }
        setNotificationTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setNotificationTableLoading(false);
      });
  };

  //notification drp api
  const loadNotificationModuleData = (value) => {
    const data = {
      type:
        selectedTab === "SMS"
          ? "sms"
          : selectedTab === "WHATSAPP"
          ? "whatsapp"
          : "notification",
      module: value,
    };
    const endPoint = "student/notification-get-one";
    getAPIData(endPoint, "post", data, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("sms_temp_id", response?.id);
            smsForm.setFieldValue("comment", response?.message_body);
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("whatsapp_temp_id", response?.id);
            form.setFieldValue("comment", response?.message_body);
          } else {
            notificationForm.setFieldValue(
              "notification_temp_id",
              response?.id
            );
            notificationForm.setFieldValue("comment", response?.message_body);
          }
        } else {
          message.warning(result?.message);
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("comment", "");
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("comment", "");
          } else {
            notificationForm.setFieldValue("comment", "");
          }
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  //SMS List Api
  const loadsmsListData = () => {
    setSmsTableLoading(false);
    const endPoint = `student/get-sent-sms-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setSmsData(response);
            setSmsTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setSmsTableLoading(false);
        }
        setSmsTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSmsTableLoading(false);
      });
  };

  //whatsapp List Api
  const loadWhatsappListData = () => {
    setWhatsappTableLoading(false);
    const endPoint = `student/whatsapp-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setWhatsappData(response);
            setWhatsappTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setWhatsappTableLoading(false);
        }
        setWhatsappTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setWhatsappTableLoading(false);
      });
  };

  // Match Found APi
  const loadMatchFoundData = () => {
    const data = { user_id: user_id };
    const endPoint = "student/check-match-users";
    getAPIData(endPoint, "post", data, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setMatchFoundData(response);
            informationForm.setFieldValue("match_found", "YES");
            // if(_.isEmpty(response)){
            //   informationForm.setFieldValue("match_found","NO")
            // }
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // Call Status submit Api
  const onFinishCallStatus = (data) => {
    setCallStatusBtnLoading(true);
    setSpinLoading(true);
    const dataCallStatus = { ...data, user_id };
    const dataCallStatusDialer = {
      ...data,
      user_id,
      unique_id: unique_id,
      call_id: call_id,
      admin_apptype: "stu_onb",
      recording_id: recording_id,
      recording_filename: recording_filename,
    };
    const endPoint = "student/add-disposition";
    getAPIData(
      endPoint,
      "post",
      login_type === "dialer-student" ? dataCallStatusDialer : dataCallStatus,
      false,
      "student"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          callStatusForm.resetFields();
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setCallStatusBtnLoading(false);
        setSpinLoading(false);
      });
  };
  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  // Send Whatsapp
  const onFinishWhatsapp = (data) => {
    setWhatsappSendBtnLoading(true);
    setSpinLoading(true);

    const nData = {
      user_id: user_id,
      whatsapp_temp_id: data?.whatsapp_temp_id,
      comment: data?.comment,
    };

    const endPoint = "student/send-whatsapp";
    getAPIData(endPoint, "post", nData, false, "student")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          setWhatsappSendBtnLoading(false);
          setSpinLoading(false);
          loadWhatsappListData();
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setWhatsappSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setWhatsappSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  //Loading Pincodes
  const loadPincodes = () => {
    const endPoint = "internal-api/get-pincode";
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var pincode_list = [];
          result?.data.map(async (pin, key) => {
            pincode_list.push({
              value: pin.id,
              label: pin.pincode,
              state: pin.state,
              district: pin.district,
            });
          });
          setPincodeList(pincode_list);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  //Load Call Status Drp List
  const callStatusData = () => {
    const endpoint = "student/get-dispositions";
    let cat = [];
    getAPIData(endpoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCallStatusSelectData(response);
          response.map((data) =>
            cat.push({
              label: data?.title,
              value: data?.id,
              text: data?.title,
            })
          );
          setCategoryData(cat);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const changeCategory = (e) => {
    callStatusForm.setFieldValue("sub_category", "");
    callStatusForm.setFieldValue("sub_sub_category", "");
    const subs = callStatusSelectData.filter((cat) => cat.id === e);
    let subd = [];
    subs.map((subCat) =>
      subCat.stu_onb_2.map((sub) =>
        subd.push({
          value: sub.id,
          label: sub.title,
          text: sub.title,
        })
      )
    );
    setSubCategoryData(subd);
  };

  const changeSubCategory = (e) => {
    callStatusForm.setFieldValue("sub_sub_category", "");
    setSelectedSubCat(e);
    callStatusSelectData.map((subcat) => {
      const subsubcat = subcat.stu_onb_2.filter((cat) => cat.id === e);
      subsubcat.map((sub) => {
        let subsubdata = [];
        sub.stu_onb_3.map((val) => {
          subsubdata.push({
            value: val.id,
            label: val.title,
            text: val.title,
          });
        });
        setSubSubCategoryData(subsubdata);
      });
    });
  };

  // Device list
  const loadDeviceListData = () => {
    const data = { user_id: user_id };
    let deviceList = [];
    const endPoint = "student/get-device-list";
    getAPIData(endPoint, "post", data, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setDeviceListData(response);
            response.map((data) => {
              deviceList.push({
                value: data?.device_id,
                label: data?.model_company + " - " + data?.device_model,
              });
            });
            setDeviceListDrp(deviceList);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const selectTheDevice = (e) => {
    const device = deviceListData.filter((data) => data?.device_id === e);
    device.map((data) => {
      setDeviceList(data);
    });
  };

  const openModal = (type) => {
    if (type === "remove-image") {
      setModalType(type);
      setModalTitle("Remove Image");
      setModalWidth(500);
    } else if (type === "error") {
      setModalType(type);
      setModalTitle("Error");
      setModalWidth(500);
    } else if (type === "clear-in-app") {
      setModalType(type);
      setModalTitle("Clear in App notification");
      setModalWidth(500);
    } else if (type === "call-history") {
      setModalType(type);
      setModalTitle("Call History");
      setModalWidth(1100);
    } else if (type === "view-change-log") {
      setModalType(type);
      setModalTitle("View Change Log");
      setModalWidth(1500);
    } else if (type === "call-status-log") {
      setModalType(type);
      setModalTitle("Call Status Log");
      setModalWidth(1500);
    } else if (type === "match-found") {
      setModalType(type);
      setModalTitle("Match Found");
      setModalWidth(900);
    } else if (type === "click-to-call") {
      setModalType(type);
      setModalTitle("Click To Call");
      setType("std-onboard");
      setModalWidth(500);
    } else if (type === "click-to-call-1") {
      setModalType(type);
      setModalTitle("Call Status Log");
      setModalWidth(500);
    } else if (type === "add-document") {
      setModalType(type);
      setModalTitle("Add Document type & comment");
      setModalWidth(500);
    }
  };

  const dialerData = () => {
    const data = {
      agent_id: agent_id,
      password: "12345678", // set static
      mobile_num: mobile_num,
    };
    const endPoint = "student/dialer-login";
    getAPIData(endPoint, "post", data, false, "student")
      .then((result) => {
        if (result?.status == true) {
          setLogin(true);
          var response = result?.data;
          const token = response?.data?.token;
          const new_user_id = response?.data?.user_data?.user_id;
          setNewUserId(new_user_id);
          setDialerRole(response?.data?.user_data?.role);
          dispatch(setRoleCode(response?.data?.user_data?.role));
          localStorage.setItem("token", token);
          dispatch(setLoginTypeData(response?.data?.user_data?.login_type));
          const lead_id = response?.data?.user_data?.lead_id;
          navigate({
            pathname: "/dialer-detail-view",
            search: `?phone_number=${mobile_num}&campaign=${campaign}&user=${agent_id}&uniqueid=${unique_id}&call_id=${call_id}&lead_id=${lead_id}&recording_id=${recording_id}&recording_filename=${recording_filename}&SIPexten=${SIPexten}&call_response=${CallResponse}`,
            // search: `?mobile_no=${mobile_num}&&password=${password}&&agent_id=${agent_id}&&lead_id=${lead_id}`,
            // search: `?mobile_no=7048422928&&password=12345678&&agent_id=123&&lead_id=${lead_id}`,
            // dialer-detail-view?phone_number=&&closer=&&campaign&&user=&&agent_fullname=&&uniqueid=&&call_id=&&lead_id=&&recording_id=&&recording_filename=&&SIPexten=&&password=
          });
          queryParameters.set("lead_id", response?.data?.user_data?.lead_id);
          dispatch(setAccessToken(token));
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // === phone_number=7048422928&&
  // === campaign&&
  // === uniqueid=&&
  // === call_id=&&
  // === SIPexten=&&
  // === password=12345678&&
  // closer=&&
  // user=&&
  // agent_fullname=&&
  // lead_id=&&
  // recording_id=&&
  // recording_filename=&&
  // agent_id=123

  const callLogApi = () => {
    const data = {
      user_id: user_id,
      phone_number: mobile_num,
      phone_number_type: "PRIMARY", //need to set dynamic
      unique_id: unique_id, //random
      call_id: call_id, //random
      SIPexten: SIPexten, //random
      admin_apptype: "stu_onb",
      Campaign: campaign, //random
      Iframe: dialerRole, //need to set dynamic
      call_type: "1", // need to set dynamic
      call_response: CallResponse, //random,
    };
    const endpoint = "student/call-log-api";
    getAPIData(endpoint, "post", data, false, "student")
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    if (paramname === "dialer-detail-view") {
      dialerData();
    }
    if (login) {
      loadData();
      callStatusData();
      loadNotificationListData();
      loadsmsListData();
      loadWhatsappListData();
      loadMatchFoundData();
      loadDeviceListData();
      callLogApi();
    }
  }, [login]);

  useEffect(() => {
    if (paramname === "detail-view") {
      loadData();
      callStatusData();
      loadNotificationListData();
      loadsmsListData();
      loadWhatsappListData();
      loadMatchFoundData();
      loadDeviceListData();
    }
  }, [login_type]);

  useEffect(() => {
    if (changeLoading) {
      loadData();
    }
    setChangeLoading(false);
  }, [changeLoading]);

  //close button Handle Click
  const handleClickOFKycFront = () => {
    openModal("add-document");
  };
  const handleRemoveImage = () => {
    openModal("remove-image");
  };

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <Spin spinning={spinLoading} size="large">
        <div className={`${loading ? "page-loading app" : "app"}`}>
          <Layout
            style={{
              // height: "100%",
              padding: "20px 0 0 20px",
              backgroundColor: "#DFFEFC",
            }}
          >
            <Sidebar open={open} setOpen={setOpen} />

            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                  backgroundColor: "#009D93",
                  borderRadius: "15px 0 0 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                {login_type === "User" ? (
                  <MenuOutlined
                    className="trigger"
                    onClick={() => setOpen(true)}
                  />
                ) : (
                  ""
                )}

                <Title
                  level={4}
                  className="poppins_regular umoney_page_title"
                  style={{
                    paddingLeft: login_type === "dialer-student" ? "20px" : "",
                  }}
                >
                  {" "}
                  {name}'s Detail View{" "}
                </Title>
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  padding: 15,
                  backgroundColor: "#DFFEFC",
                }}
              >
                <div className="white_div">
                  <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xl={2} lg={4} md={6} sm={8}>
                      <img
                        className="kyc-images"
                        src={
                          stdData?.selfie?.image ? stdData?.selfie?.image : user
                        }
                        alt="User"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Col>
                    <Col className="gutter-row" xl={22} lg={20} md={18} sm={16}>
                      <Row gutter={[16, 16]}>
                        <Col
                          className="gutter-row"
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                        >
                          <Title
                            level={4}
                            className="poppins_semi umoney_title"
                          >
                            Approved sections: {fullName}
                          </Title>
                        </Col>
                        {/* <Col className="gutter-row" span={8}></Col> */}
                        <Col
                          className="gutter-row"
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                        >
                          <Row gutter={[16, 16]}>
                            <Col
                              className="gutter-row"
                              xl={4}
                              lg={4}
                              sx={{
                                display: {
                                  xl: "block",
                                  lg: "block",
                                  md: "block",
                                  sm: "block",
                                },
                              }}
                            >
                              {" "}
                            </Col>
                            <Col
                              className="gutter-row"
                              xl={10}
                              lg={10}
                              md={10}
                              sm={12}
                            >
                              <CLoadingButton
                                size="large"
                                value="CALL HISTORY"
                                className="poppins_medium umoney_outlined_btn1"
                                block
                                onClick={() => openModal("call-history")}
                              />
                            </Col>
                            <Col
                              className="gutter-row"
                              xl={10}
                              lg={10}
                              md={12}
                              sm={12}
                            >
                              <CLoadingButton
                                size="large"
                                value="CLICK TO CALL"
                                className="poppins_medium umoney_btn"
                                block
                                icon={
                                  <PhoneOutlined rotate={90} size="large" />
                                }
                                onClick={() => openModal("click-to-call")}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={3}
                          lg={3}
                          md={4}
                          sm={6}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            className="poppins_medium br tag-style"
                            style={{
                              backgroundColor:
                                btnsData?.kyc === 100
                                  ? completed
                                  : btnsData?.kyc === 101
                                  ? checked
                                  : btnsData?.kyc === 102
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            KYC
                          </Tag>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={3}
                          lg={3}
                          md={4}
                          sm={6}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            value="PAN"
                            className="poppins_medium br tag-style"
                            block
                            style={{
                              backgroundColor:
                                btnsData?.pan === 100
                                  ? completed
                                  : btnsData?.pan === 101
                                  ? checked
                                  : btnsData?.pan === 102
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            PAN
                          </Tag>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={3}
                          lg={3}
                          md={4}
                          sm={6}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            value="Personal"
                            className="poppins_medium br tag-style"
                            block
                            style={{
                              background:
                                btnsData?.basic_info === 100
                                  ? completed
                                  : btnsData?.basic_info === 101
                                  ? checked
                                  : btnsData?.basic_info === 102
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Personal
                          </Tag>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={3}
                          lg={3}
                          md={4}
                          sm={6}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            value="Student"
                            className="poppins_medium br tag-style"
                            block
                            style={{
                              background:
                                btnsData?.college === 100
                                  ? completed
                                  : btnsData?.college === 101
                                  ? checked
                                  : btnsData?.college === 102
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Student
                          </Tag>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={4}
                          lg={4}
                          md={5}
                          sm={7}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            value="Selfie"
                            className="poppins_medium br tag-style"
                            block
                            style={{
                              background:
                                btnsData?.selfie === 100
                                  ? completed
                                  : btnsData?.selfie === 101
                                  ? checked
                                  : btnsData?.selfie === 102
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Selfie
                          </Tag>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={4}
                          lg={4}
                          md={5}
                          sm={7}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            value="Video"
                            className="poppins_medium br tag-style"
                            block
                            style={{
                              background:
                                btnsData?.selfie_video === 100
                                  ? completed
                                  : btnsData?.selfie_video === 101
                                  ? checked
                                  : btnsData?.selfie_video === 102
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Video
                          </Tag>
                        </Col>
                        <Col
                          className="gutter-row"
                          xl={4}
                          lg={4}
                          md={5}
                          sm={7}
                          style={{ paddingTop: "25px" }}
                        >
                          <Tag
                            size="large"
                            value="Bits & Pieces"
                            className="poppins_medium br tag-style"
                            block
                            style={{
                              background:
                                btnsData?.bits_piece === 100
                                  ? completed
                                  : btnsData?.bits_piece === 101
                                  ? checked
                                  : btnsData?.bits_piece === 102
                                  ? verifiedApproved
                                  : pending,
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Bits & Pieces
                          </Tag>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div className="white_div mt-20">
                  <Title level={5} className="poppins_medium umoney_title">
                    Send Notification or SMS or Whatsapp
                  </Title>
                  <div className="tab-title">
                    <Row gutter={[16, 16]}>
                      <Col
                        className="gutter-row"
                        span={24}
                        style={{ paddingLeft: "-10px", paddingRight: "-10px" }}
                      >
                        <Tabs
                          defaultActiveKey="1"
                          type="card"
                          onTabClick={(value) => {
                            getTabValue(value);
                          }}
                        >
                          <Tabs.TabPane tab="NOTIFICATION" key="1">
                            <Row gutter={[16, 16]}>
                              <Col className="gutter-row" span={8}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    In app communication
                                  </Title>
                                  <Form
                                    name="basic"
                                    onFinish={onFinishNotification}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="umoney-form"
                                    requiredMark={false}
                                    form={notificationForm}
                                  >
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Module"
                                        name="module"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Module is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Select
                                          size="large"
                                          options={moduleOptions}
                                          placeholder="Select module"
                                          onChange={(value) => {
                                            loadNotificationModuleData(value);
                                          }}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Comment"
                                        name="comment"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Comment is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <TextArea
                                          size="large"
                                          type="text"
                                          rows={7}
                                          placeholder="Enter comment"
                                        />
                                      </Form.Item>
                                      <Form.Item name="notification_temp_id"></Form.Item>
                                    </div>
                                    <Form.Item>
                                      <CLoadingButton
                                        size="large"
                                        value="SEND"
                                        className="poppins_medium umoney_btn"
                                        block
                                        htmlType="submit"
                                        loading={notiSendBtnLoading}
                                      />
                                    </Form.Item>
                                  </Form>
                                </div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Row gutter={[16, 16]}>
                                    <Col span={20}>
                                      <Title
                                        level={5}
                                        className="poppins_medium umoney_title"
                                      >
                                        In app sent
                                      </Title>
                                    </Col>
                                    <Col span={4}>
                                      <CLoadingButton
                                        size="large"
                                        value="CLEAR IN APP"
                                        className="poppins_medium umoney_btn"
                                        onClick={() => {
                                          openModal("clear-in-app");
                                        }}
                                        block
                                      />
                                    </Col>
                                  </Row>

                                  <div className="umoney_table mt-10">
                                    <Table
                                      columns={notificationColumns}
                                      dataSource={notificationData}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 5,
                                        pageSizeOptions: [5, 10, 15, 20],
                                        locale: { items_per_page: "" },
                                      }}
                                      loading={notificationTableLoading}
                                      scroll={{ y: 200 }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tabs.TabPane>
                          <Tabs.TabPane tab="SMS" key="2">
                            <Row gutter={[16, 16]}>
                              <Col className="gutter-row" span={8}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                    //margin:"10px"
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    SMS templates
                                  </Title>
                                  <Form
                                    name="basic"
                                    onFinish={onFinishSms}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="umoney-form"
                                    requiredMark={false}
                                    form={smsForm}
                                  >
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Module"
                                        name="module"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Module is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Select
                                          size="large"
                                          options={moduleOptions}
                                          onChange={(val) => {
                                            loadNotificationModuleData(val);
                                          }}
                                          placeholder="Select module"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Comment"
                                        name="comment"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Comment is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <TextArea
                                          size="large"
                                          type="text"
                                          rows={7}
                                          placeholder="Enter comment"
                                        />
                                      </Form.Item>
                                      <Form.Item name="sms_temp_id" />
                                    </div>
                                    <Form.Item>
                                      <CLoadingButton
                                        size="large"
                                        value="SEND"
                                        className="poppins_medium umoney_btn"
                                        block
                                        htmlType="submit"
                                        loading={smsSendBtnLoading}
                                      />
                                    </Form.Item>
                                  </Form>
                                  {/* <CLoadingButton
                                  size="large"
                                  value="Send"
                                  className="poppins_medium umoney_btn mt-20"
                                  htmlType="submit"
                                  //loading={loading}
                                  block
                                /> */}
                                </div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    SMS sending history
                                  </Title>
                                  <div className="umoney_table mt-10">
                                    <Table
                                      columns={smscolumns}
                                      dataSource={smsData}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 5,
                                        pageSizeOptions: [5, 10, 15, 20],
                                        locale: { items_per_page: "" },
                                      }}
                                      loading={smsTableLoading}
                                      scroll={{ y: 200 }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tabs.TabPane>
                          <Tabs.TabPane tab="WHATSAPP" key="3">
                            <Row gutter={[16, 16]}>
                              <Col className="gutter-row" span={8}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                    //margin:"10px"
                                  }}
                                >
                                  <Form
                                    name="basic"
                                    onFinish={onFinishWhatsapp}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="umoney-form"
                                    requiredMark={false}
                                    form={form}
                                  >
                                    <Title
                                      level={5}
                                      className="poppins_medium umoney_title"
                                    >
                                      Whatsapp templates
                                    </Title>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Module"
                                        name="module"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Module is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Select
                                          size="large"
                                          options={moduleOptions}
                                          onChange={(val) => {
                                            loadNotificationModuleData(val);
                                          }}
                                          placeholder="Select module"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Comment"
                                        name="comment"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Comment is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <TextArea
                                          size="large"
                                          type="text"
                                          rows={7}
                                          placeholder="Enter comment"
                                        />
                                      </Form.Item>
                                      <Form.Item name="whatsapp_temp_id" />
                                    </div>
                                    <CLoadingButton
                                      size="large"
                                      value="SEND"
                                      className="poppins_medium umoney_btn"
                                      htmlType="submit"
                                      loading={whatsappSendBtnLoading}
                                      block
                                    />
                                  </Form>
                                </div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    Whatsapp sending history
                                  </Title>
                                  <div className="umoney_table mt-20">
                                    <Table
                                      columns={whatsappColumns}
                                      dataSource={whatsappData}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 5,
                                        pageSizeOptions: [5, 10, 15, 20],
                                        locale: { items_per_page: "" },
                                      }}
                                      loading={whatsappTableLoading}
                                      scroll={{ y: 200 }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tabs.TabPane>
                        </Tabs>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="white_div mt-20">
                  {/* <Col className="gutter-row" span={4}> */}
                  <Title level={5} className="poppins_medium umoney_title">
                    Call Status
                  </Title>
                  {/* </Col> */}
                  <Form
                    name="basic"
                    onFinish={onFinishCallStatus}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={callStatusForm}
                  >
                    <Row gutter={[16, 16]}>
                      <Col
                        className="gutter-row"
                        xl={12}
                        lg={12}
                        md={8}
                        sm={6}
                      ></Col>
                      <Col
                        className="gutter-row"
                        xl={12}
                        lg={12}
                        md={16}
                        sm={18}
                      >
                        <Row gutter={[16, 16]}>
                          <Col
                            className="gutter-row"
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                          >
                            {" "}
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={10}
                            lg={10}
                            md={10}
                            sm={10}
                          >
                            <CLoadingButton
                              size="large"
                              value="CALL STATUS LOG"
                              className="poppins_medium umoney_outlined_btn1 br"
                              onClick={() => {
                                openModal("call-status-log");
                                setModal(true);
                              }}
                              block
                            />
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={10}
                            lg={10}
                            md={10}
                            sm={10}
                          >
                            <CLoadingButton
                              size="large"
                              value="VIEW CHANGE LOG"
                              className="poppins_medium umoney_outlined_btn1 br"
                              block
                              onClick={() => {
                                openModal("view-change-log");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Category"
                          name="category"
                          rules={[
                            {
                              required: true,
                              message: "Category is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={categoryData}
                            onChange={(e) => changeCategory(e)}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Sub Category"
                          name="sub_category"
                          rules={[
                            {
                              required: true,
                              message: "Sub Category is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            value={selectedSubCat}
                            size="large"
                            options={subCategoryData}
                            onChange={(e) => changeSubCategory(e)}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Sub Sub Category"
                          name="sub_sub_category"
                          rules={[
                            {
                              required: true,
                              message: "Sub sub Category is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={subSubCategoryData}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Comment"
                          name="comment"
                          rules={[
                            { required: true, message: "comment is required!" },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input size="large" type="text" />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={4} lg={4} md={6} sm={12}>
                        <Form.Item>
                          <CLoadingButton
                            size="large"
                            value="SUBMIT"
                            htmlType="submit"
                            loading={callStatusBtnLoading}
                            // onClick={callStatusSubmitClick}
                            className="poppins_medium umoney_btn"
                            block
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="white_div mt-20">
                  <Title level={5} className="poppins_medium umoney_title">
                    Information
                  </Title>
                  <div className="mt-20">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form
                          name="basic"
                          onFinish={onFinishInformation}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          className="umoney-form"
                          requiredMark={false}
                          form={informationForm}
                          initialValues={{
                            match_found: "NO",
                          }}
                        >
                          <Row gutter={[16, 16]}>
                            <Col
                              className="gutter-row"
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                            >
                              <Form.Item
                                label="Profile Status"
                                name="profile_status"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Profile Status is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Profile Status"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                            >
                              <Form.Item
                                label="Profession"
                                name="Profession"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Profession is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  defaultValue="Student"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                            >
                              <Form.Item
                                label="Match Found"
                                name="match_found"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "match found is required!",
                                  },
                                ]}
                                onClick={() =>
                                  !_.isEmpty(matchFoundData)
                                    ? openModal("match-found")
                                    : ""
                                }
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              className="gutter-row"
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                            >
                              <Form.Item
                                label="Referred By"
                                name="referred_by"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Referred By is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Device"
                                name="Device"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Device",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Select
                                  options={deviceListDrp}
                                  style={{ width: "100%" }}
                                  size="large"
                                  onChange={(e) => selectTheDevice(e)}
                                  placeholder="Select device"
                                ></Select>
                              </Form.Item>
                            </Col>
                            <Col span={18}></Col>
                          </Row>
                        </Form>
                      </Col>

                      <Col
                        className="gutter-row"
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                      >
                        <div className="border-class">
                          <Title
                            level={5}
                            className="poppins_medium umoney_title p-10"
                          >
                            Phone Permission
                          </Title>
                          <Table
                            columns={pcolumns}
                            dataSource={tdata}
                            pagination={false}
                            style={{ paddingBottom: "5px" }}
                          />
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                      >
                        <Row gutter={[16, 16]}>
                          <Col
                            className="gutter-row"
                            xl={14}
                            lg={8}
                            md={8}
                            sm={10}
                          >
                            <div className="border-class">
                              <Title
                                level={5}
                                className="poppins_medium umoney_title p-10"
                              >
                                Device Details
                              </Title>
                              <Row className="p-10">
                                <Col className="gutter-row" span={5}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#009D93",
                                      fontSize: "16px",
                                    }}
                                  >
                                    MODEL
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={19}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#494949",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {!_.isEmpty(deviceList?.device_model)
                                      ? deviceList?.device_model
                                      : "-"}
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={5}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#009D93",
                                      fontSize: "16px",
                                    }}
                                  >
                                    G-AD-ID
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={19}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#494949",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {!_.isEmpty(deviceList?.g_ad_id)
                                      ? deviceList?.g_ad_id
                                      : "-"}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                            <div className="border-class">
                              <Row className="p-10">
                                <Col className="gutter-row" span={24}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#494949",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {stdData?.AgentDetails?.AgentType ===
                                    "Approver"
                                      ? "Approver"
                                      : stdData?.AgentDetails?.AgentType ===
                                        "Caller"
                                      ? "Caller"
                                      : ""}
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={24}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#009D93",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {stdData?.AgentDetails?.AgentName}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={10}
                            lg={8}
                            md={8}
                            sm={10}
                          >
                            <div className="border-class">
                              <Title
                                level={5}
                                className="poppins_medium umoney_title p-10"
                              >
                                SMS Count
                              </Title>
                              <Row className="p-10">
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#009D93",
                                      fontSize: "16px",
                                    }}
                                  >
                                    FIRST NAME
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#494949",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {/* {!_.isEmpty(
                                      deviceList?.with_firstname_sms_count
                                    )
                                      ? deviceList?.with_firstname_sms_count
                                      : "-"} */}
                                    {deviceList?.with_firstname_sms_count}
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#009D93",
                                      fontSize: "16px",
                                    }}
                                  >
                                    MIDDLE NAME
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#494949",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {/* {!_.isEmpty(
                                      deviceList?.with_middlename_sms_count
                                    )
                                      ? deviceList?.with_middlename_sms_count
                                      : "-"} */}
                                    {deviceList?.with_middlename_sms_count}
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#009D93",
                                      fontSize: "16px",
                                    }}
                                  >
                                    LAST NAME
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#494949",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {/* {!_.isEmpty(
                                      deviceList?.with_lastname_sms_count
                                    )
                                      ? deviceList?.with_lastname_sms_count
                                      : "-"} */}
                                    {deviceList?.with_lastname_sms_count}
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#009D93",
                                      fontSize: "16px",
                                    }}
                                  >
                                    OTP SMS
                                  </p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <p
                                    className="poppins_semi"
                                    style={{
                                      color: "#494949",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {/* {!_.isEmpty(deviceList?.otp_sms_count)
                                      ? deviceList?.otp_sms_count
                                      : "-"} */}
                                    {deviceList?.otp_sms_count}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="white_div mt-20">
                  <Row gutter={[16, 16]}>
                    <Col className="gutter-row" span={6}>
                      <div className="border-class scroll_bar">
                        <Title
                          level={5}
                          className="poppins_medium umoney_title p-10"
                        >
                          Images
                        </Title>
                        <KYCFront
                          handleClickOFKycFront={handleClickOFKycFront}
                          handleRemoveImage={handleRemoveImage}
                          KYCFrontImage={stdData?.KYC?.front_img}
                          user_id={user_id}
                          isSuccess={(data) => {
                            setChangeLoading(data);
                          }}
                        />
                        <KYCBack
                          handleClickOFKycFront={handleClickOFKycFront}
                          KYCBackImage={stdData?.KYC?.back_img}
                          user_id={user_id}
                          isSuccess={(data) => {
                            setChangeLoading(data);
                          }}
                        />
                        <PANFront
                          handleClickOFKycFront={handleClickOFKycFront}
                          PANFrontImage={
                            _.isEmpty(stdData?.PAN?.form60_url)
                              ? stdData?.PAN?.front_img
                              : stdData?.PAN?.form60_url
                          }
                          user_id={user_id}
                          message={message}
                          isSuccess={(data) => {
                            setChangeLoading(data);
                          }}
                        />
                        <StudentIdFront
                          studentIdFrontImage={
                            stdData?.student_id_card?.front_img
                          }
                          user_id={user_id}
                          isSuccess={(data) => {
                            setChangeLoading(data);
                          }}
                        />
                        <StudentIdBack
                          studentIdBackImage={
                            stdData?.student_id_card?.back_img
                          }
                          user_id={user_id}
                          isSuccess={(data) => {
                            setChangeLoading(data);
                          }}
                        />
                        <Marksheet
                          marksheetImage={stdData?.marksheet}
                          user_id={user_id}
                          isSuccess={(data) => {
                            setChangeLoading(data);
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="gutter-row" span={18}>
                      <div
                        className="border-class"
                        style={{ paddingBottom: "2px", paddingTop: "1px" }}
                      >
                        <Collapse accordion>
                          <Panel header="KYC Details" key="1">
                            <KYCDetails
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                              KYCDetails={stdData?.KYC}
                              user_id={user_id}
                              loading={(data) => {
                                setSpinLoading(data);
                              }}
                              loadData={loadData}
                            />
                          </Panel>
                          <Panel header="PAN Details" key="2">
                            <PanDetails
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                              PANDetails={stdData?.PAN}
                              user_id={user_id}
                              loading={(data) => {
                                setSpinLoading(data);
                              }}
                            />
                          </Panel>
                          <Panel header="Personal Information Details" key="3">
                            <PersonalInformationDetails
                              personalInfoData={stdData?.personalInfo}
                              address={stdData?.address}
                              pincodeList={pincodeList}
                              user_id={user_id}
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                              loading={(data) => {
                                setSpinLoading(data);
                              }}
                              loadData={loadData}
                            />
                          </Panel>
                          <Panel header="Student Information Details" key="4">
                            <StudentInformationDetails
                              stdInfo={stdData?.student_info_details}
                              data={stdData}
                              pincodeList={pincodeList}
                              user_id={user_id}
                              loading={(data) => {
                                setSpinLoading(data);
                              }}
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                            />
                          </Panel>
                          <Panel header="Selfie Details" key="5">
                            <SelfieDetails
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                              RemoveImage={handleRemoveImage}
                              selfieData={stdData?.selfie}
                              user_id={user_id}
                              loading={(data) => {
                                setSpinLoading(data);
                              }}
                            />
                          </Panel>
                          <Panel header="Video Details" key="6">
                            <VideoDetails
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                              RemoveImage={handleRemoveImage}
                              VideoDetails={stdData?.video}
                              user_id={user_id}
                              loading={(data) => {
                                setSpinLoading(data);
                              }}
                            />
                          </Panel>
                          <Panel
                            header="Upload Files"
                            key="8"
                            style={{ borderRadius: "5px" }}
                          >
                            <UploadFiles user_id={user_id} />
                          </Panel>
                        </Collapse>
                      </div>
                    </Col>
                  </Row>
                  <Modal
                    open={modalType}
                    title={modalTitle}
                    footer={false}
                    onCancel={handleClose}
                    centered={true}
                    width={modalWidth}
                    // destroyOnClose={true}
                  >
                    {modalType === "remove-image" ? (
                      <RemoveImage />
                    ) : modalType === "error" ? (
                      <div>
                        <Row gutter={[16, 16]}>
                          <Col className="gutter-row" span={12}>
                            <Title
                              level={5}
                              className="poppins_regular umoney_title"
                            >
                              API call limit exceeded
                            </Title>
                          </Col>
                        </Row>
                      </div>
                    ) : modalType === "clear-in-app" ? (
                      <ClearInApp user_id={user_id} handleClose={handleClose} />
                    ) : modalType === "call-history" ? (
                      <CallHistory userType={pageType} user_id={user_id} />
                    ) : modalType === "view-change-log" ? (
                      <Viewchangelog
                        page={page}
                        setPage={setPage}
                        current={current}
                        setCurrent={setCurrent}
                        user_id={user_id}
                      />
                    ) : modalType === "call-status-log" ? (
                      <CallStatusLogModal
                        user_id={user_id}
                        modal={modal}
                        setModal={setModal}
                        current={current}
                        setCurrent={setCurrent}
                        page={page}
                        setPage={setPage}
                      />
                    ) : modalType === "match-found" ? (
                      <MatchFoundModal matchFoundData={matchFoundData} />
                    ) : modalType === "click-to-call" ? (
                      <ClickToCallModal
                        user_id={user_id}
                        type={type}
                        altNumber={altNumber}
                        regNumber={regNumber}
                        paramname={paramname}
                        agent_id={agent_id}
                      />
                    ) : modalType === "click-to-call-1" ? (
                      <ClickToCallModal2 />
                    ) : (
                      ""
                    )}
                  </Modal>
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
      </Spin>
    </>
  );
};

export default DetailView;
