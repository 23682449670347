import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  Tag,
  message,
  Input,
  Space,
  Button,
} from "antd";
import Sidebar from "../../components/Sidebar";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import CSelectBtn from "../../components/InputFields/CSelectBtn";
import { useLocation } from "react-router-dom";
import { getAPIData } from "../../apiHelper";
import { useSelector } from "react-redux";
import { useRef } from "react";
import CTableSearch from "../../components/InputFields/CTableSearch";
import moment from "moment";
import Highlighter from "react-highlight-words";
import _ from "lodash";

const { Title } = Typography;
const { Header, Content } = Layout;

const AdvanceCreditCheckHistory = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [top, setTop] = useState("none");
  const [bottom, setBottom] = useState("bottomLeft");
  const [savebtnLoading, setsavebtnLoading] = useState(false);

  const role = useSelector((state) => state.userData.accessRoleCode);

  // const role = "superadmin"; //"caller","Approver",Supervisor

  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const pathType = pathName[2];

  const [listData, setListData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  //Search functionality
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  //sort btns
  const [total, setTotal] = useState("");
  const [approved, setApproved] = useState("");
  const [rejected, setRejected] = useState("");
  const [pending, setPending] = useState("");

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  var success = "#3EA467";
  var fail = "#DE1717";

  const data = [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "disabled",
      disabled: true,
      label: "Disabled",
    },
    {
      value: "Yiminghe",
      label: "yiminghe",
    },
  ];

  const loadData = () => {
    setLoading(true);
    setTableLoading(true);
    const endpoint = "cc-advance/list";
    const data = { type: pathType };
    getAPIData(endpoint, "get", data, false, "credit_check")
      .then((result) => {
        console.log("fetch");
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setTableLoading(false);
            setListData(response);

            let arr = [];
            response.map((data) => {
              arr.push({
                total: data.status,
              });
            });

            const totalCount = arr?.length;
            setTotal(totalCount);

            const rejCount = response.filter((data) => data.status === "-1");
            const rejectedCount = rejCount.length;
            setRejected(rejectedCount);

            const penCount = response.filter((data) => data.status === "0");
            const pendedCount = penCount.length;
            setPending(pendedCount);

            const appCount = response.filter((data) => data.status === "2");
            const approvedCount = appCount.length;
            setApproved(approvedCount);
            setLoading(false);
          }
        } else {
          message.warning(result?.message);
          setLoading(false);
          setTableLoading(false);
        }
        setTableLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const hasSelected = selectedRowKeys.length > 0;
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys, confirm, dataIndex);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilter: (value, record) =>
      moment(record[dataIndex]).format("DD-MM-YYYY") ===
      value.format("DD-MM-YYYY"),

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CTableSearch
        datePicker
        onDateChange={(e) => {
          setSelectedKeys([e]);
        }}
        onSearch={() => {
          handleSearch(selectedKeys, confirm, dataIndex);
        }}
        onReset={() => clearFilters && handleReset(clearFilters, confirm)}
      />
    ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape={false}
          textToHighlight={text ? text : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchTextProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "USER DETAILS",
      dataIndex: "user_details",
      key: "user_details",
      width: "12%",
      filteredValue: filteredInfo.user_details || null,
      sorter: (a, b) => a?.user_id - b?.user_id,
      ...getColumnSearchTextProps("user_details"),
      render: (_noUse, userDetails) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: userDetails?.user_details }}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    {
      title: "MODULE STATUS",
      dataIndex: "module",
      key: "id",
      width: "25  %",
      filteredValue: filteredInfo.module || null,
      render: (text, record) => (
        <>
          <Tag
            className="module_status"
            color={record.income === 100 ? success : fail}
          >
            Income check
          </Tag>
          <Tag
            className="module_status"
            color={record.spending === 100 ? success : fail}
          >
            Spending check
          </Tag>
          <Tag
            className="module_status"
            color={record.asset === 100 ? success : fail}
          >
            Asset check
          </Tag>
          <Tag
            className="module_status"
            color={record.parent_contact === 100 ? success : fail}
          >
            Parent contact
          </Tag>
          <Tag
            className="module_status"
            color={record.social_media === 100 ? success : fail}
          >
            Social media
          </Tag>
          <Tag
            className="module_status"
            color={record.cibil === 100 ? success : fail}
          >
            Cibil
          </Tag>
        </>
      ),
    },
    {
      title: "USER ACCESS TIMESTAMP",
      dataIndex: "users_createdAt",
      width: "13%",
      filteredValue: filteredInfo.users_createdAt || null,
      ...getColumnSearchProps("users_createdAt"),
    },
    {
      title: "USER LAST ACTIONED AT",
      dataIndex: "percentage_updatedAt",
      width: "14%",
      filteredValue: filteredInfo.percentage_updatedAt || null,
      ...getColumnSearchTextProps("percentage_updatedAt"),
      render: (text, record) => (
        <span>
          {record?.agentName} <br /> Timestamp - {record?.percentage_updatedAt}
        </span>
      ),
    },
    {
      title: "AGENT LAST ACTIONED",
      dataIndex: "agent_last_action_at",
      width: "10%",
      filteredValue: filteredInfo.agent_last_action_at || null,
      ...getColumnSearchProps("agent_last_action_at"),
    },
    {
      title: "LAST CALL STATUS",
      dataIndex: "CreditCheckDisp",
      width: "13%",
      filteredValue: filteredInfo.CreditCheckDisp || null,
      ...getColumnSearchTextProps("CreditCheckDisp"),
      render: (_noUse, CreditCheckDisp) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: CreditCheckDisp?.CreditCheckDisp
                ? CreditCheckDisp?.CreditCheckDisp
                : "-",
            }}
          />
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => (
        <span>
          {record?.status === "0"
            ? "Pending"
            : record?.status === "1"
            ? "Checked"
            : record?.status === "-1"
            ? "Reject"
            : record?.status === "2"
            ? "Approve"
            : ""}
        </span>
      ),
      filters: [
        { value: "0", label: "Pending", text: "Pending" },
        { value: "-1", label: "Reject", text: "Reject" },
        { value: "2", label: "Approve", text: "Approve" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "CURRENTLY WITH",
      dataIndex: "agentName",
      width: "13%",
      hidden: true,
      filteredValue: filteredInfo.agentName || null,
      ...getColumnSearchTextProps("agentName"),
    },
  ];

  const newColumns = columns.filter((item) => !item.hidden);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title ">
                {" "}
                {role}'s History View{" "}
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  {role === "supervisor_caller" ||
                  role === "supervisor_approver" ||
                  role === "superadmin" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CSelectBtn
                          // options={juniorData}
                          size="large"
                          classes="poppins_regular filter_select_border"
                          placeholder="Select agents"
                          btnvalue="ASSIGN TO"
                          // onClick={handleAssignClick}
                          // btnLoading={assignLoading}
                          // onChange={agentSelectChange}
                        />
                      </Col>
                      <Col span={16}></Col>
                    </>
                  ) : (
                    <>
                      <Col className="gutter-row" span={16}>
                        <Title
                          level={5}
                          className="poppins_medium umoney_title"
                        >
                          Allocated to me:{" "}
                        </Title>
                      </Col>
                      <Col className="gutter-row" span={8}></Col>
                    </>
                  )}
                  {role === "caller" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total Actioned:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={total}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejected}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Forwarded to CC Approver:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pending}
                          block
                        />
                      </Col>
                    </>
                  ) : role === "approver" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          // countval={totalCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Sent to CC Caller:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval=""
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved"
                          className="poppins_medium umoney_outlined_btn"
                          countval=""
                          block
                        />
                      </Col>
                    </>
                  ) : role === "supervisor_caller" ||
                    role === "supervisor_approver" ||
                    role === "superadmin" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          // countval={totalCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved:"
                          className="poppins_medium umoney_outlined_btn"
                          // countval={approvedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          // countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pending}
                          block
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="CLEAR FILTERS"
                      className="poppins_medium umoney_outlined_btn"
                      onClick={() => clearFilters()}
                      block
                    />
                  </Col>
                </Row>

                {/* } */}
                <div className="mt-20">
                  <div className="umoney_table">
                    <Table
                      columns={
                        role === "superadmin" ||
                        role === "supervisor_caller" ||
                        role === "supervisor_approver"
                          ? columns
                          : newColumns
                      }
                      dataSource={listData}
                      scroll={{
                        x: 1300,
                      }}
                      pagination={{
                        position: [top, bottom],
                        showSizeChanger: true,
                        locale: { items_per_page: "" },
                      }}
                      rowSelection={
                        role === "superadmin" ||
                        role === "supervisor_caller" ||
                        role === "supervisor_approver"
                          ? rowSelection
                          : ""
                      }
                      loading={tableLoading}
                      rowKey={(record) => record.user_id}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {role === "caller" ? (
                  <Row gutter={[16, 16]} className="mt-20">
                    <Col className="gutter-row" span={12}>
                      <Title level={5} className="poppins_medium umoney_title ">
                        Forwarded to Approver:{" "}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={12}></Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Total:"
                        className="poppins_medium umoney_outlined_btn"
                        block
                        countval={total}
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Back to CC Caller (Approved):"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Back to CC Caller (Rejected):"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Approved:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={approved}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Rejected:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={rejected}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Pending:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={pending}
                        block
                      />
                    </Col>
                    <Col span={12}></Col>
                    <Col span={6}>
                      <CLoadingButton
                        size="large"
                        value="CLEAR FILTERS"
                        className="poppins_medium umoney_outlined_btn"
                        onClick={() => clearFilters()}
                        block
                      />
                    </Col>
                    <Col span={24}>
                      <div className="umoney_table" style={{ marginTop: 20 }}>
                        <Table
                          columns={
                            role === "superadmin" ||
                            role === "supervisor_caller" ||
                            role === "supervisor_approver"
                              ? columns
                              : newColumns
                          }
                          dataSource={listData}
                          scroll={{
                            x: 1300,
                          }}
                          pagination={{
                            position: [top, bottom],
                            showSizeChanger: true,
                            locale: { items_per_page: "" },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};
export default AdvanceCreditCheckHistory;
