import { Col, Modal, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import Title from "antd/lib/skeleton/Title";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";

const CollegeDetailsModal = (props) => {
  const { onSave = () => {}, isMerge, editId, collegeId } = props;
  console.log("collegeId", collegeId);

  const [form] = Form.useForm();
  const [savebtnLoading, setSaveBtnLoading] = useState(false);
  // const [pincodeList, setPincodeList] = useState(false);

  const [collegeData, setCollegeData] = useState([]);
  const [collegeDetails, setCollegeDetails] = useState();

  const institiute_type = [
    {
      value: "0",
      label: "Government",
    },
    {
      value: "1",
      label: "Private",
    },
  ];

  const affiliation_type = [
    {
      value: "0",
      label: "Affiliated",
    },
    {
      value: "1",
      label: "Deemed",
    },
  ];

  const no_of_students = [
    {
      value: "0",
      label: "0-500",
      text: "0-500",
    },
    {
      value: "1",
      label: "501-1000",
      text: "501-1000",
    },
    {
      value: "2",
      label: "1001-5000",
      text: "1001-5000",
    },
    {
      value: "3",
      label: ">5000",
      text: ">5000",
    },
  ];

  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo===>>", errorInfo);
  };

  //   const getPincodeValue = async (e) => {
  //     let abcd = await findArrayElementByTitle(pincodeList, e);
  //     form.setFieldValue("state", abcd?.state);
  //     form.setFieldValue("district", abcd?.district);
  //   };

  if (editId && _.isEmpty(collegeData)) {
    const endPoint = "institute/get/" + editId;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCollegeData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  const loadCollegeDetails = () => {
    var endPoint = `student/get-college-data/${collegeId}`;
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        setSaveBtnLoading(false);
        const response = result?.data;
        if (result?.status === true) {
          setCollegeDetails(response);
          form.setFieldsValue({
            college_name: response?.college_name,
            no_of_student:
              response?.no_of_student === "0"
                ? "0-500"
                : response?.no_of_student === "0"
                ? "0-500"
                : response?.no_of_student === "1"
                ? "501-1000"
                : response?.no_of_student === "2"
                ? "1001-5000"
                : response?.no_of_student === "3"
                ? ">5000"
                : "",
            institiute_type:
              response?.institiute_type === "0"
                ? "Government"
                : response?.institiute_type === "1"
                ? "Private"
                : "",
            affiliation_type:
              response?.affiliation_type === "0"
                ? "affiliated"
                : response?.affiliation_type === "1"
                ? "Deemed"
                : "",
            min_fees_band: response?.min_fees_band,
            max_fees_band: response?.max_fees_band,
            mean_fees_band: response?.mean_fees_band,
            medium_fees_band: response?.medium_fees_band,
            address: response?.address,
            landmark: response?.landmark,
            state: response?.pincode_id?.state,
            district: response?.pincode_id?.district,
            state: response?.pincode_id?.state,
            pincode_id: response?.pincode_id?.pincode,
            website: response?.website,
            google_map_link: response?.google_map_link,
          });
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    loadCollegeDetails();
  }, [collegeId]);

  return (
    <div className="white_div boxshadow-div">
      <Form
        name="basic"
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
          <Col className="gutter-row" span={18}>
            <Form.Item
              label="Name of college"
              name="college_name"
              rules={[
                {
                  required: true,
                  message: "Name of college is required!",
                },
                {
                  message: "Please Enter Only Characters",
                  pattern: new RegExp(/[a-zA-Z]/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="No of student"
              name="no_of_student"
              rules={[
                {
                  required: true,
                  message: "No of student is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input className="br" size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Institiute type"
              name="institiute_type"
              rules={[
                {
                  required: true,
                  message: "Institiute type is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input className="br" size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Affiliation type"
              name="affiliation_type"
              rules={[
                {
                  required: true,
                  message: "Affiliation type is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input className="br" size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Minimum fees"
              name="min_fees_band"
              rules={[
                {
                  required: true,
                  message: "Minimum fees is required!",
                },
                {
                  message: "Please Enter Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Maximum fees"
              name="max_fees_band"
              rules={[
                {
                  required: true,
                  message: "Maximum fees is required!",
                },
                {
                  message: "Please Enter Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Mean fees"
              name="mean_fees_band"
              rules={[
                {
                  required: true,
                  message: "Mean fees is required!",
                },
                {
                  message: "Please Enter Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Median fees"
              name="medium_fees_band"
              rules={[
                {
                  required: true,
                  message: "Median fees is required!",
                },
                {
                  message: "Please Enter Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Title level={5} style={{ fontSize: "18px" }}>
              Address
            </Title>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Address is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Landmark"
              name="landmark"
              rules={[
                {
                  required: true,
                  message: "Landmark is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="State"
              name="state"
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="District"
              name="district"
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Pincode"
              name="pincode_id"
              rules={[
                {
                  required: true,
                  message: "Pincode is required!",
                },
                {
                  message: "Please Enter Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input readOnly size="large" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Website"
              name="website"
              rules={[
                {
                  required: true,
                  message: "Website is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Google map link"
              name="google_map_link"
              rules={[
                {
                  required: true,
                  message: "Google map link is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

async function findArrayElementByTitle(array, value) {
  return array.find((element) => {
    return element.value === value;
  });
}

export default CollegeDetailsModal;
