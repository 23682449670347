import React from "react";
import Images from "../config/images";
import { Typography } from "antd";
import { Link } from "react-router-dom";

const NotFound404 = () => {
  const { not_found } = Images;
  const { Title } = Typography;

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{ objectFit: "contain" }}
          src={not_found}
          width={450}
          height={450}
          alt="404"
        />
        <Title className="poppins_medium">Page not found.</Title>
        <Link to="/">Go to Home</Link>
      </div>
    </div>
  );
};

export default NotFound404;
