import { useEffect, useState } from "react";
import { Form, Modal, Row, Col, Input, Select, message } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import _ from "lodash";
import { getAPIData } from "../../apiHelper";

const EmailModal = (props) => {
  const [form] = Form.useForm();
  const {
    title,
    open,
    setOpen,
    onFinishApi = () => {},
    emailGetData,
    modalType,
    rolesVal,
  } = props;
  const id = emailGetData?.id;

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    if (!_.isEmpty(emailGetData) && modalType === "edit-emailus") {
      form.setFieldsValue(emailGetData);
    } else {
      form.resetFields();
    }
  }, [form, modalType, emailGetData]);

  const onFinish = (data) => {
    setLoading(true);
    const endpoint =
      modalType === "edit-emailus" ? "email-us/update/" + id : "email-us/add";
    getAPIData(endpoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          setOpen(false);
          message.success(result?.message);
          form.resetFields();
          onFinishApi(result?.status);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };
  const onFinishFailed = () => {};

  return (
    <Modal
      title={title}
      centered
      open={open}
      mask={true}
      footer={null}
      closable={true}
      className="poppins_regular"
      onCancel={handleClose}
      width={900}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Role ID"
              name="role_id"
              size="large"
              rules={[{ required: true, message: "Role is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                className="br"
                size="large"
                style={{ width: "100%" }}
                options={rolesVal}
                placeholder="Select icon id"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true, message: "Title is required!" },
                {
                  whitespace: true,
                  message: "This field can not contain whitespaces.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="Enter title" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Type"
              name="type"
              size="large"
              rules={[{ required: true, message: "Type is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                className="br"
                size="large"
                style={{ width: "100%" }}
                options={[
                  { label: "Support", value: "1" },
                  { label: "Loan", value: "2" },
                  { label: "Profile", value: "3" },
                ]}
                placeholder="Select type"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="Save"
                className="poppins_medium umoney_btn"
                loading={loading}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EmailModal;
