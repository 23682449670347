import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Switch,
  Form,
  Input,
  Space,
  Button,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import SpecializationModals from "../../components/Modals/SpecializationModals";

const { Title } = Typography;
const { Header, Content } = Layout;

const Specialization = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [deleteId, setDeleteId] = useState("");

  //edit specialization
  const [editId, setEditId] = useState(Number);
  const [isEdit, setIsEdit] = useState(false);
  const [editCancleIcon, setEditCancleIcon] = useState(false);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //Search Table
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  //Opening Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [editData, setEditData] = useState();

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const openModal = (type, id, record) => {
    if (type === "add-specialization") {
      setModalType(type);
      setModalTitle("Add Specialization");
    } else if (type === "edit-specialization") {
      setEditData(record);
      setModalType(type);
      setModalTitle("Edit Specialization");
      setEditCancleIcon(true);
    }
  };

  const loadSpecializationsData = () => {
    const endPoint = "specialization/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const specializationEdit = (id, name) => {
    form.setFieldValue("name", name);
    setEditCancleIcon(true);
    setIsEdit(true);
    setEditId(id);
  };

  const changeStatus = (e, id) => {
    let endPoint = `specialization/change-status/` + id;
    const data = { status: e === true ? "1" : "0" };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadSpecializationsData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // Search Functionality START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // Search Functionality END

  useEffect(() => {
    if (_.isEmpty(tableData)) {
      loadSpecializationsData();
    }
  }, [tableData]);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "SPECIALIZATIONS",
      dataIndex: "name",
      key: "name",
      filteredValue: filteredInfo.name || null,
      ...getColumnSearchProps("name"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
      align: "center",
      render: (text, record) => (
        <div>
          <span>
            <Switch
              checked={record.status === "1" ? true : false}
              onChange={(e) => changeStatus(e, record?.id)}
            />
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      filteredValue: filteredInfo.actions || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{ color: "#009D93", marginLeft: "20px", fontSize: "25px" }}
              onClick={() => {
                // specializationEdit(record?.id, record?.name);
                openModal("edit-specialization", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                height: "20px",
                width: "20px",
                marginLeft: "20px",
                fontSize: "25px",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              backgroundColor: "#009D93",
              borderRadius: "15px 0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Specialization
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 15,
              backgroundColor: "#DFFEFC",
            }}
          >
            <div className="white_div">
              {/* <Row gutter={[16, 16]}> */}
              <Row gutter={[16, 16]}>
                <Col span={8}></Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="CLEAR FILTERS"
                    className="poppins_medium umoney_btn"
                    onClick={() => clearFilters()}
                    block
                  />
                </Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="ADD SPECIALIZATION"
                    icon={<PlusOutlined />}
                    className="poppins_medium umoney_btn"
                    onClick={() => openModal("add-specialization")}
                    block
                  />
                </Col>
              </Row>
              {/* <Col className="gutter-row" span={6}>
                  <Input
                    placeholder="Module Search..."
                    size="large"
                    searchvalue={(val) => {
                      // setSearchVal(val);
                    }}
                  />
                </Col> */}
              {/* </Row> */}
              <div className="umoney_table mt-20">
                <Table
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                  columns={tableColumns}
                  dataSource={tableData}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Content>
        </Layout>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            setOpen={setModalOpen}
            title="Delete Specialization"
            type="specialization"
            modalType={modalType}
            setModalType={setModalType}
            id={deleteId}
            onDelete={(val) => {
              loadSpecializationsData();
            }}
          />
        ) : modalType === "add-specialization" ? (
          <SpecializationModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            isEdit={isEdit}
            onSave={() => {
              setModalType(false);
            }}
            loadSpecializationsData={loadSpecializationsData}
          />
        ) : modalType === "edit-specialization" ? (
          <SpecializationModals
            editData={editData}
            modalTitle={modalTitle}
            modalType={modalType}
            isEdit={isEdit}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
            }}
            setEditCancleIcon={setEditCancleIcon}
            setIsEdit={setIsEdit}
            loadSpecializationsData={loadSpecializationsData}
            editCancleIcon={editCancleIcon}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default Specialization;
