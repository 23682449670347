import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";
const ContactStatusModals = ({
  modalType,
  modalTitle,
  setModalType,
  loadContactData,
  editData,
}) => {
  const [form] = Form.useForm();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType("");
  };

  console.log(editData);

  const submitData = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "edit-contact"
        ? "master/contact-status/update"
        : "master/contact-status/create";
    getAPIData(
      endPoint,
      "post",
      modalType === "edit-contact"
        ? { ...data, contact_id: editData?.id }
        : data,
      false,
      "admin"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSaveBtnLoading(false);
          handleClose();
          form.resetFields();
          loadContactData();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const onFinishFailed = () => {
    console.log("failed");
  };

  useEffect(() => {
    if (modalType === "add-contact") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }
  }, [form, editData, modalType]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={submitData}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Title is required!",
                  },
                  //   {
                  //     message: "Value should be number",
                  //     pattern: new RegExp(/^[0-9]+$/),
                  //   },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the title" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value=" SAVE"
                  className="poppins_medium umoney_modal_filled_btn"
                  block
                  loading={saveBtnLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ContactStatusModals;
