import React, { useState } from "react";
import { Col, message, Row, Typography, Spin, Tag } from "antd";
import { getAPIData } from "../../../apiHelper";
import { useEffect } from "react";
import CLoadingButton from "../../InputFields/CLoadingButton";

const { Title, Text } = Typography;

const ViewApiResponse = (props) => {
  const [clickToCallData, setClickToCallData] = useState();

  const {
    user_id,
    user_role_id,
    name,
    ifsc_code,
    acc_number,
    handleCloseModal,
  } = props;

  const [spinLoading, setSpinLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const loadClickToCallData = () => {
    const endPoint = `bank-agent/bank-kyc-kart`;
    setBtnLoading(true);
    setSpinLoading(true);
    setClickToCallData("");
    const data = {
      user_id: user_id,
      user_role_id: user_role_id,
      name: name,
      ifsc_code: ifsc_code,
      acc_number: acc_number,
    };
    getAPIData(endPoint, "post", data, false, "bankAcc")
      .then((result) => {
        if (result?.status === true) {
          setClickToCallData(result?.data);
          setSpinLoading(false);
          setBtnLoading(false);
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSpinLoading(false);
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    loadClickToCallData();
  }, [acc_number, ifsc_code, name, props, user_id, user_role_id]);

  return (
    <Spin spinning={spinLoading} size="medium">
      <div>
        <div className="border-class">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={5} className="poppins_medium umoney_title p-10">
                ACCOUNT DETAILS
              </Title>
            </Col>
          </Row>

          {clickToCallData?.account_status === "active" ? (
            <Row className="p-10">
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{ color: "#009D93", fontSize: "16px" }}
                >
                  NAME
                </p>
              </Col>
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{
                    color: "#494949",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {clickToCallData?.details?.registered_name}
                </p>
              </Col>
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{ color: "#009D93", fontSize: "16px" }}
                >
                  ACCOUNT NUMBER
                </p>
              </Col>
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{
                    color: "#494949",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {clickToCallData?.details?.account_number}
                </p>
              </Col>
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{ color: "#009D93", fontSize: "16px" }}
                >
                  BANK REFERENCE
                </p>
              </Col>
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{
                    color: "#494949",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {clickToCallData?.details?.bank_reference}
                </p>
              </Col>
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{ color: "#009D93", fontSize: "16px" }}
                >
                  IFSC
                </p>
              </Col>
              <Col className="gutter-row" span={12}>
                <p
                  className="poppins_semi"
                  style={{
                    color: "#494949",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {clickToCallData?.details?.ifsc}
                </p>
              </Col>
            </Row>
          ) : (
            <div className="mb-10" style={{ paddingBottom: "10px" }}>
              <Text className="poppins_medium p-10">
                Account is inactive or invalid
              </Text>
            </div>
          )}
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12} className="mt-10">
            <CLoadingButton
              size="large"
              value="OK"
              htmlType="submit"
              className="poppins_medium umoney_btn"
              block
              onClick={() => handleCloseModal()}
            />
          </Col>
          <Col span={12} className="mt-10">
            <CLoadingButton
              size="large"
              value="RE-RUN"
              htmlType="submit"
              className="poppins_medium umoney_btn"
              block
              onClick={() => loadClickToCallData()}
              loading={btnLoading}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default ViewApiResponse;
