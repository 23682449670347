import { Col, Form, Input, Row, Table, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import CLoadingButton from "../InputFields/CLoadingButton";
import { setHeader, salaried_url } from "../../api";
import axios from "axios";
import _ from "lodash";

const { Title } = Typography;

const cibilPhoneColumns = [
  {
    title: "TYPE CODE",
    dataIndex: "type_code",
    key: "type_code",
  },
  {
    title: "REPORTED DATE",
    dataIndex: "reported_date",
    key: "reported_date",
  },
  {
    title: "NUMBER",
    dataIndex: "number",
    key: "number",
  },
];

const UserCibil = ({ user_id, cibilData, isSuccess, loading }) => {
  console.log("cibilData", cibilData);
  console.log("user_id", user_id);
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [userCibilForm] = Form.useForm();

  // const [loading, setLoading] = useState(false);
  const [userCibilSaveLoading, setUserCibilSaveLoading] = useState(false);

  const userCibilSave = async () => {
    setUserCibilSaveLoading(true);
    loading(true);
    const header = setHeader();
    const endPoint = salaried_url + "steps/approve-cibil-data";
    const data = {
      user_id: user_id,
    };
    const uploadData = await axios.post(endPoint, data, header);
    if (uploadData?.data?.status) {
      message.success(uploadData?.data?.message);
      setUserCibilSaveLoading(false);
      loading(false);
      isSuccess(true);
    } else {
      message.warning(uploadData?.data?.message);
      setUserCibilSaveLoading(false);
      loading(false);
    }
    setUserCibilSaveLoading(false);
    loading(false);
  };

  return (
    <div>
      <Form
        name="basic"
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form mb-35"
        requiredMark={false}
        form={userCibilForm}
        initialValues={{
          no_of_accounts:
            cibilData?.retail_accounts_summary_data?.no_of_accounts,
          no_of_active_accounts:
            cibilData?.retail_accounts_summary_data?.no_of_active_accounts,
          no_of_write_offs:
            cibilData?.retail_accounts_summary_data?.no_of_write_offs,
          total_past_due:
            cibilData?.retail_accounts_summary_data?.total_past_due,
          most_severe_status_with_in_24_months:
            cibilData?.retail_accounts_summary_data
              ?.most_severe_status_with_in_24_months,
          single_highest_credit:
            cibilData?.retail_accounts_summary_data?.single_highest_credit,
          single_highest_sanction_amount:
            cibilData?.retail_accounts_summary_data
              ?.single_highest_sanction_amount,
          total_high_credit:
            cibilData?.retail_accounts_summary_data?.total_high_credit,
          average_open_balance:
            cibilData?.retail_accounts_summary_data?.average_open_balance,
          single_highest_balance:
            cibilData?.retail_accounts_summary_data?.single_highest_balance,
          no_of_past_due_accounts:
            cibilData?.retail_accounts_summary_data?.no_of_past_due_accounts,
          no_of_zero_balance_accounts:
            cibilData?.retail_accounts_summary_data
              ?.no_of_zero_balance_accounts,
          recent_account_type:
            cibilData?.retail_accounts_summary_data?.recent_account_type,
          recent_account_date:
            cibilData?.retail_accounts_summary_data?.recent_account_date,
          oldest_account_type:
            cibilData?.retail_accounts_summary_data?.oldest_account_type,
          oldest_account_date:
            cibilData?.retail_accounts_summary_data?.oldest_account_date,
          total__balance_amount:
            cibilData?.retail_accounts_summary_data?.total__balance_amount,
          total_sanction_amount:
            cibilData?.retail_accounts_summary_data?.total_sanction_amount,
          total_credit_limit:
            cibilData?.retail_accounts_summary_data?.total__credit_limit,
          total_monthly_payment_amount:
            cibilData?.retail_accounts_summary_data
              ?.total_monthly_payment_amount,
          type: cibilData?.score_details_data?.type,
          version: cibilData?.score_details_data?.version,
          name: cibilData?.score_details_data?.name,
          error_code: cibilData?.error?.error_code,
          error_description: cibilData?.error?.error_description,
          updatedAt: cibilData?.error?.updatedAt,
        }}
      >
        {_.isEmpty(cibilData?.error) ? (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  Retail accounts summary data
                </Title>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className="mt-20">
              <Col span={12}>
                <Form.Item
                  label="Number of accounts"
                  name="no_of_accounts"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Number of active accounts"
                  name="no_of_active_accounts"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Number of write offs"
                  name="no_of_write_offs"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Total past due"
                  name="total_past_due"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Most severe status with in 24 months"
                  name="most_severe_status_with_in_24_months"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Single highest credit"
                  name="single_highest_credit"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Single highest sanction amount"
                  name="single_highest_sanction_amount"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Total high credit"
                  name="total_high_credit"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Average open balance"
                  name="average_open_balance"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Single highest balance"
                  name="single_highest_balance"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Number of past due accounts"
                  name="no_of_past_due_accounts"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Number of zero balance accounts"
                  name="no_of_zero_balance_accounts"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Recent account type"
                  name="recent_account_type"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Recent account date"
                  name="recent_account_date"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Oldest account type"
                  name="oldest_account_type"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Oldest account date"
                  name="oldest_account_date"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Total balance amount"
                  name="total__balance_amount"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Total sanction amount"
                  name="total_sanction_amount"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Total credit limit"
                  name="total_credit_limit"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Total monthly payment amount"
                  name="total_monthly_payment_amount"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={24} className="mt-20">
                <Title level={5} className="poppins_medium umoney_title">
                  Score details data
                </Title>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Type"
                  name="type"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Version"
                  name="version"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  size="large"
                  className="poppins_medium mb-35"
                >
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={24} className="mt-20">
                <Title level={5} className="poppins_medium umoney_title">
                  Phone data
                </Title>
              </Col>
              <Col span={24}>
                <div className="umoney_table">
                  <Table
                    columns={cibilPhoneColumns}
                    dataSource={cibilData?.phone_data}
                    pagination={false}
                  />
                </div>
              </Col>
              <Col span={8}>
                <CLoadingButton
                  size="large"
                  value="SAVE & APPROVE"
                  htmlType="submit"
                  loading={userCibilSaveLoading}
                  onClick={() => userCibilSave()}
                  className="poppins_medium umoney_btn"
                  block
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={5} className="poppins_medium umoney_title">
                Error
              </Title>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Error Code"
                name="error_code"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Salary Type is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" readOnly />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="Error Description"
                name="error_description"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Salary Type is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" readOnly />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="Updated At"
                name="updatedAt"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Salary Type is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" readOnly />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={8}>
              <CLoadingButton
                size="large"
                value="SAVE & APPROVE"
                htmlType="submit"
                loading={userCibilSaveLoading}
                onClick={() => userCibilSave()}
                className="poppins_medium umoney_btn"
                block
              />
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default UserCibil;
