/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Switch,
  Upload,
  Space,
  Button,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MergeCellsOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import CourseModals from "../../components/Modals/College/CourseModals";
import { setHeader, setHeaderForFile } from "../../api";
import axios from "axios";
import { api_url } from "../../api";
import Input from "antd/lib/input/Input";
import Highlighter from "react-highlight-words";

const { Title } = Typography;
const { Header, Content } = Layout;

const Course = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [isMerge, setIsMerge] = useState([]);
  const [courseId, setCourseId] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const scroll = {};

  //Opening Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  //Search Table
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const openModal = (type, id, is_merge = false) => {
    if (type === "add-course") {
      setModalType(type);
      setModalTitle("Add Course");
    } else if (type === "edit-course") {
      setCourseId(id);
      setModalType(type);
      setIsMerge(is_merge);
      setModalTitle("Edit Course");
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
      setModalOpen(true);
    }
  };

  const course_type = [
    {
      value: "1",
      label: "Diploma",
      text: "Diploma",
    },
    {
      value: "2",
      label: "Under Graduate",
      text: "Under Graduate",
    },
    {
      value: "3",
      label: "Post Graduate",
      text: "Post Graduate",
    },
    {
      value: "4",
      label: "PGDM",
      text: "PGDM",
    },
    {
      value: "5",
      label: "PGPM",
      text: "PGPM",
    },
    {
      value: "6",
      label: "Ph.D",
      text: "Ph.D",
    },
  ];

  const csvUpload = async (data) => {
    try {
      const endPoint = api_url + "course/upload-course";
      const header = setHeaderForFile();

      const apiData = { uploadcsv: data?.file?.originFileObj };
      const uploadData = await axios.post(endPoint, apiData, header);
      if (uploadData?.data?.status) {
        message.success(uploadData?.data?.message);
        loadCourseData();
      } else {
        message.warning(uploadData?.data?.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    loadCourseData();
  }, []);

  const loadCourseData = () => {
    const endPoint = "course/course-list";
    setLoading(true);
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
          setLoading(false);
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
        setLoading(false);
      });
  };

  //Set the values of Status in list
  const setValues = (course_id) => {
    console.log("course_id", course_id);
    let endPoint = `course/change-status/` + course_id;
    const header = setHeader();

    getAPIData(endPoint, "get", {}, header)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadCourseData();
          // props?.setOpen(false);
          // setModalType("");
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  // Search functaionality start
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // search functionality end

  const TableColumns = [
    {
      title: "COURSE ID",
      dataIndex: "id",
      key: "id",
      filteredValue: filteredInfo.id || null,
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id"),
    },
    {
      title: "COURSE NAME",
      dataIndex: "course_name",
      key: "course_name",
      filteredValue: filteredInfo.course_name || null,
      ...getColumnSearchProps("course_name"),
    },
    {
      title: "COURSE TYPE",
      dataIndex: "course_type",
      key: "course_type",
      filteredValue: filteredInfo.course_type || null,
      render: (text, record) => (
        <div>
          <span>
            {record?.course_type === "1"
              ? "Diploma"
              : record?.course_type === "2"
              ? "Under Graduate"
              : record?.course_type === "3"
              ? "Post Graduate"
              : record?.course_type === "4"
              ? "PGDM"
              : record?.course_type === "5"
              ? "PGPM"
              : "Ph.D"}
          </span>
        </div>
      ),
      filters: course_type,
      filterSearch: true,
      onFilter: (value, record) =>
        record.course_type
          .toString()
          .toLowerCase()
          .startsWith(value.toString().toLowerCase()),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => {
        return (
          <div>
            <span>
              {record.status !== "2" ? (
                <Switch
                  checked={record.status === "1" ? true : false}
                  onChange={() => {
                    setValues(record?.id);
                  }}
                />
              ) : (
                <MergeCellsOutlined
                  style={{
                    color: "#009D93",
                    height: "20px",
                    width: "20px",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => openModal("edit-course", record?.id, true)}
                />
              )}
            </span>
          </div>
        );
      },
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-course", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            Course
          </Title>
        </Header>
        <Content
          className="site-layout-background white_div"
          style={{
            padding: "20px 15px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={3}>
              <Upload onChange={csvUpload}>
                <CLoadingButton
                  size="large"
                  value="Upload CSV"
                  className="poppins_medium umoney_modal_filled_btn"
                  icon={<CloudUploadOutlined />}
                  block
                />
              </Upload>
            </Col>
            <Col className="gutter-row" span={3}>
              {/* <Link to="/sample_csv/Course.csv" download target="_self"> */}
              <a href="/sample_csv/Course.csv" download>
                <DownloadOutlined /> Click to download
              </a>
            </Col>
            <Col className="gutter-row" span={2}></Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="CLEAR FILTERS"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => clearFilters()}
                block
              />
            </Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="ADD"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => openModal("add-course")}
                icon={<PlusOutlined />}
                block
              />
            </Col>
          </Row>

          <div className="umoney_table " style={{ marginTop: 20 }}>
            <Table
              pagination={{
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
              columns={TableColumns}
              dataSource={tableData}
              scroll={scroll}
              onChange={handleChange}
            />
          </div>
          {modalType === "delete" ? (
            <CommonModals
              open={modalOpen}
              title="Delete Confirmation"
              type="course"
              setOpen={setModalOpen}
              modalType={modalType}
              id={deleteId}
              setModalType={setModalType}
              onDelete={(val) => {
                loadCourseData();
              }}
            />
          ) : modalType === "add-course" ? (
            <CourseModals
              course_type={course_type}
              modalTitle={modalTitle}
              setModalType={setModalType}
              modalType={modalType}
              onSave={() => {
                setModalType(false);
                loadCourseData();
              }}
            />
          ) : modalType === "edit-course" ? (
            <CourseModals
              course_type={course_type}
              modalTitle={modalTitle}
              modalType={modalType}
              courseId={courseId}
              isMerge={isMerge}
              setModalType={setModalType}
              onSave={() => {
                setModalType(false);
                loadCourseData();
              }}
            />
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </div>
  );
};

/*
async function findInArray(array, label, key) {
  return array.find((element) => {
    console.log("element--->", element);
    return element.label === label;
  });
}
*/

export default Course;
