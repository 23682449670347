import React, { useState, useEffect } from "react";

import { Row, Col, Form, Input, Select, Radio, Dropdown, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import TextArea from "antd/lib/input/TextArea";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

export default function UpdateDetailsForFather(props) {
  const { role, user_id, relation_id, handleClose, userRole, record, isSuccess } = props;
  const [Value, setValue] = useState(record?.phone_details);

  console.log("record", record);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setValue(value);
    if (value !== "1") {
      form.setFieldValue("contact_no", "");
    }
  };

  const [form] = Form.useForm();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const dropdownValue = [
    {
      value: "1",
      label: "Has a mobile",
    },
    {
      value: "2",
      label: "Does not use mobile",
    },
    {
      value: "3",
      label: "Passes away",
    },
    {
      value: "4",
      label: "Separated and not in touch",
    },
  ];

  const radioValue = [
    {
      value: "0",
      label: "No",
    },
    {
      value: "1",
      label: "Yes",
    },
  ];

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    const endPoint = "cc-steps/save-parents-contact";
    const formData = {
      ...data,
      user_id: user_id,
      relation_id: record?.relation_id,
      user_role: userRole,
    };
    getAPIData(endPoint, "post", formData, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          setSaveBtnLoading(false);
          isSuccess(true);
          handleClose();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      phone_details: record?.phone_details,
      contact_no: _.isEmpty(record?.contact_no)
        ? record?.contact_num_as_agent
        : record?.contact_no,
      comment: record?.comment,
      validated: record?.validated,
    });
    setValue(record?.phone_details);
  }, [props]);

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Father"
              name="phone_details"
              rules={[
                {
                  required: true,
                  message: "Please enter the New value !",
                },
              ]}
              className="poppins_medium"
            >
              <Select
                defaultValue=""
                onChange={handleChange}
                size="large"
                options={dropdownValue}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Father's mobile number"
              name="contact_no"
              rules={[
                {
                  required: Value === "1" ? true : false,
                  message: "Please enter the New value !",
                },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Contact number contains only number.",
                },
                {
                  max: 10,
                  message: "Contact number contains maximum 10 digits.",
                },
              ]}
              className="poppins_medium"
            >
              <Input size="large" disabled={Value === "1" ? false : true} />
            </Form.Item>
          </Col>

          {role === "approver" ? (
            <>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Comment !",
                    },
                  ]}
                  className="poppins_medium"
                >
                  <TextArea
                    placeholder="Enter comment"
                    allowClear
                    rows={4}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24} style={{ padding: 0 }}>
                <Form.Item
                  label="Validated?"
                  name="validated"
                  rules={[
                    {
                      required: true,
                      message: "Please Select one !",
                    },
                  ]}
                  className="poppins_medium"
                >
                  <Radio.Group
                    options={radioValue}
                    style={{ marginTop: 20, marginLeft: 10 }}
                  ></Radio.Group>
                </Form.Item>
              </Col>{" "}
            </>
          ) : (
            ""
          )}

          <Col className="gutter-row" span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SAVE"
                htmlType="submit"
                className="poppins_medium umoney_modal_filled_btn"
                loading={saveBtnLoading}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
