import React, { useState } from "react";
import { Col, Row, Form, message, Input, Modal, Typography } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import CCloseIcon from "../InputFields/CCloseIcon";
import { PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import RemoveImage from "../Modals/modal/RemoveImage";
import PreviewModal from "../Modals/modal/CommonModal/PreviewModal";
import { useRef } from "react";
import CropperModal from "../Modals/CropperModal";
import { getAPIData } from "../../apiHelper";

const { Text } = Typography;

const SelfieDetails = (props) => {
  const selfieData = props?.selfieData;
  // const RemoveImage = props?.RemoveImage;
  const user_id = props?.user_id;
  const [frontImage, setFrontImage] = useState([]);
  const [selfieSaveBtnLoading, setSelfieSaveBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const [idApproveBtn, setIdApprover] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);

  const pageType = props?.pageType;
  const isSuccess = props?.isSuccess;

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [srcImg, setSrcImg] = useState();
  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");
  const [modalTypeCrop, setModalTypeCrop] = useState("");

  const [imageName, setImageName] = useState("");

  const openModal = (type) => {
    if (type === "remove-image") {
      setModalType(type);
      setModalTitle("Remove Image");
      setModalWidth(500);
    }
  };

  const previewModalRef = useRef(null);

  const handleClose = () => {
    setModalType("");
  };

  const onFinish = async (data) => {
    props?.loading(true);
    console.log("success =====>>>>> ", data);
    const endpoint = "steps/agent-save-selfie-data";
    let images = [];
    if (idApproveBtn) {
      setApproveBtnLoading(true);
      data.isApprove = idApproveBtn;
    } else {
      setSelfieSaveBtnLoading(true);
    }

    if (frontImage?.[0]) {
      // form.append("frontImg", frontImage?.[0]?.originFileObj);
      images.push({
        imageBase64: frontImage.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: imageName,
        type: "frontImg",
      });
    }
    data.user_id = user_id;
    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }
    getAPIData(
      endpoint,
      "post",
      data,
      false,
      pageType === "salaried" ? "salaried" : "student"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          props?.isSuccess(true);
          props?.loading(false);
          setIdApprover(false);
          if (idApproveBtn) {
            setApproveBtnLoading(false);
          } else {
            setSelfieSaveBtnLoading(false);
          }
        } else {
          message?.warning(result?.message);
          props?.loading(false);
          if (idApproveBtn) {
            setApproveBtnLoading(false);
          } else {
            setSelfieSaveBtnLoading(false);
          }
        }
        props?.loading(false);
        if (idApproveBtn) {
          setApproveBtnLoading(false);
        } else {
          setSelfieSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        props?.loading(false);
        if (idApproveBtn) {
          setApproveBtnLoading(false);
        } else {
          setSelfieSaveBtnLoading(false);
        }
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const openModalCrop = (type) => {
    setModalTypeCrop(type);
    setModalOpen(true);
  };

  if (!_.isEmpty(imageDoc) && imageType === "selfie") {
    setFrontImage(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  const handleImage = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setImageName(event.target.files[0]?.name);
    setImageType("selfie");
    openModalCrop("crop-image");
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        initialValues={{ name: selfieData?.user_name }}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={10}>
            {pageType === "salaried" ? (
              " "
            ) : (
              <Form.Item
                label="Profile Name"
                name="name"
                size="large"
                rules={[
                  { required: true, message: "Profile name is required!" },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter profile name" />
              </Form.Item>
            )}
          </Col>
          <Col className="gutter-row" span={4}></Col>
          <Col span={10}></Col>
          <Col className="gutter-row" span={5}>
            {_.isEmpty(selfieData?.image) ? (
              <div
                style={{
                  width: "auto",
                  border: "1px dashed #009d93",
                  borderRadius: 5,
                  background: "#dffefc",
                  padding: 20,
                  margin: 20,
                  height: 235,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="poppins_semi umoney_title">
                  No Image Added
                </span>
              </div>
            ) : (
              <>
                <img
                  src={selfieData?.image}
                  alt="User"
                  style={{ height: "100%", width: "100%" }}
                  onClick={() => {
                    previewModalRef?.current?.open(selfieData?.image);
                  }}
                />
                <CCloseIcon
                  onClick={() => openModal("remove-image")}
                  style={{ position: "absolute", top: "-10px", right: "0px" }}
                />
              </>
            )}
          </Col>
          {/* <Col span={20}></Col>  */}
          <Col span={5}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // margin: "20px 10px",
                marginLeft: "20px",
                marginTop: "20px",
              }}
            >
              <Form.Item name="front_img">
                {_.isEmpty(frontImage) ? (
                  <div
                    style={{
                      width: "100%",
                      height: "150px",
                      backgroundColor: "#dffefc",
                      border: "1px dashed #009d93",
                      borderRadius: "2px",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      title=""
                      className="file-choose"
                      style={{
                        width: "100%",
                        height: "100%",
                        color: "transparent",
                        opacity: 0,
                        position: "relative",
                        zIndex: "200",
                      }}
                      onChange={handleImage}
                    />
                    <div
                      style={{
                        position: "relative",
                        bottom: "55%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text>
                        {" "}
                        <PlusOutlined /> Upload Selfie
                      </Text>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      backgroundColor: "#dffefc",
                      border: "1px dashed #009d93",
                      borderRadius: "2px",
                    }}
                  >
                    <img
                      src={frontImage}
                      style={{ height: "100%", width: "100%" }}
                    ></img>
                    <CCloseIcon
                      onClick={() => setFrontImage("")}
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "0px",
                      }}
                    />
                  </div>
                )}
              </Form.Item>
            </div>
          </Col>
          <Col span={16}></Col>
          <Col span={8}></Col>
          <Col xl={5}>
            <div>
              <Form.Item>
                <CLoadingButton
                  size="large"
                  value="SAVE"
                  htmlType="submit"
                  loading={selfieSaveBtnLoading}
                  className="poppins_medium umoney_btn"
                  block
                />
              </Form.Item>
            </div>
          </Col>
          <Col xl={5}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SAVE & APPROVE"
                className="poppins_medium umoney_btn"
                onClick={() => setIdApprover(true)}
                htmlType="submit"
                loading={approveBtnLoading}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={handleClose}
        centered
        width={modalWidth}
        footer={false}
      >
        {modalType === "remove-image" ? (
          <RemoveImage
            user_id={user_id}
            type={"selfie"}
            handleClose={handleClose}
            pageType={pageType}
            isSuccess={isSuccess}
          />
        ) : (
          ""
        )}
      </Modal>
      {modalTypeCrop === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalType}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
      <PreviewModal ref={previewModalRef} />
    </div>
  );
};

export default SelfieDetails;
