import React, { useState } from "react";
import { Form, message, Typography, Upload } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { PlusOutlined } from "@ant-design/icons";
import CropperModal from "../CropperModal";
import _ from "lodash";
import CCloseIcon from "../../InputFields/CCloseIcon";
import { getAPIData } from "../../../apiHelper";

const { Text } = Typography;

const UploadBankDocument = ({
  user_id,
  bank_id,
  handleClose,
  loadBankDocument,
}) => {
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [form] = Form.useForm();

  console.log("props=============>", user_id, bank_id);

  const [document1, setDocument1] = useState([]);
  const [document2, setDocument2] = useState([]);
  const [document3, setDocument3] = useState([]);
  const [document4, setDocument4] = useState([]);
  const [document5, setDocument5] = useState([]);

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const [srcImg, setSrcImg] = useState();
  const [modalType, setModalType] = useState("");

  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [document1Name, setDocument1Name] = useState("");
  const [document2Name, setDocument2Name] = useState("");
  const [document3Name, setDocument3Name] = useState("");
  const [document4Name, setDocument4Name] = useState("");
  const [document5Name, setDocument5Name] = useState("");

  const onFinish = async (data) => {
    console.log("success =====>>>>> ", data);
    setSubmitBtnLoading(true);
    const endpoint = "bank-agent/bank-document-upload";
    let images = [];
    if (document1?.[0]) {
      images.push({
        imageBase64: document1.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document1Name,
        type: "imageOne",
      });
    }

    if (document2?.[0]) {
      images.push({
        imageBase64: document2.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document2Name,
        type: "imageTwo",
      });
    }

    if (document3?.[0]) {
      images.push({
        imageBase64: document3.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document3Name,
        type: "imageThree",
      });
    }

    if (document4?.[0]) {
      images.push({
        imageBase64: document4.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document4Name,
        type: "imageFour",
      });
    }

    if (document5?.[0]) {
      images.push({
        imageBase64: document5.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: document5Name,
        type: "imageFive",
      });
    }

    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }

    data.user_id = user_id;
    data.bank_id = bank_id;
    getAPIData(endpoint, "post", data, false, "bankAcc")
      .then((result) => {
        if (result?.status) {
          message.success(result?.message);
          setSubmitBtnLoading(false);
          setDocument1([]);
          setDocument2([]);
          setDocument3([]);
          setDocument4([]);
          setDocument5([]);
          handleClose();
          loadBankDocument();
        } else {
          message.warning(result?.message);
          setSubmitBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSubmitBtnLoading(false);
      });

  };

  const openModal = (type) => {
    if (type === "crop-image") {
      setModalType(type);
      setModalOpen(true);
    }
  };

  if (!_.isEmpty(imageDoc) && imageType === "doc1") {
    setDocument1(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "doc2") {
    setDocument2(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "doc3") {
    setDocument3(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "doc4") {
    setDocument4(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "doc5") {
    setDocument5(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  const handleImage1 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument1Name(event.target.files[0]?.name);
    setImageType("doc1");
    openModal("crop-image");
  };
  const handleImage2 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument2Name(event.target.files[0]?.name);
    setImageType("doc2");
    openModal("crop-image");
  };
  const handleImage3 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument3Name(event.target.files[0]?.name);
    setImageType("doc3");
    openModal("crop-image");
  };
  const handleImage4 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument4Name(event.target.files[0]?.name);
    setImageType("doc4");
    openModal("crop-image");
  };
  const handleImage5 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setDocument5Name(event.target.files[0]?.name);
    setImageType("doc5");
    openModal("crop-image");
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form.Item className="poppins_medium mb-35" name="image_one">
            {_.isEmpty(document1) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImage1}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>
                    {" "}
                    <PlusOutlined /> Upload Bank Document1
                  </Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={document1}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setDocument1("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_two">
            {_.isEmpty(document2) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImage2}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>
                    {" "}
                    <PlusOutlined /> Upload Bank Document2
                  </Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={document2}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setDocument2("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_three">
            {_.isEmpty(document3) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImage3}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>
                    {" "}
                    <PlusOutlined /> Upload Bank Document3
                  </Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={document3}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setDocument3("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_four">
            {_.isEmpty(document4) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImage4}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>
                    {" "}
                    <PlusOutlined /> Upload Bank Document4
                  </Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={document4}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setDocument4("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_five">
            {_.isEmpty(document5) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImage5}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>
                    {" "}
                    <PlusOutlined /> Upload Bank Document5
                  </Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={document5}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setDocument5("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
        </div>
        <Form.Item>
          <CLoadingButton
            size="large"
            value="SUBMIT"
            className="poppins_medium umoney_btn mt-10"
            htmlType="submit"
            loading={submitBtnLoading}
            block
          />
        </Form.Item>
      </Form>
      {modalType === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalType}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default UploadBankDocument;
