import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";
const TermsAndConditionModals = ({
  modalType,
  modalTitle,
  setModalType,
  editData,
  loadTermList,
  termsEditData,
}) => {
  const [termForm] = Form.useForm();
  const [saveTermBtnLoading, setSaveTermBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType(false);
  };

  const onFailedTerms = (values) => {};

  //TERMS
  const onFinishTerms = (data) => {
    setSaveTermBtnLoading(true);

    const endPoint =
      modalType === "add-term"
        ? "system-config/signup_terms/create"
        : "system-config/signup_terms/update?terms_id=" + termsEditData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveTermBtnLoading(false);
        if (result?.status === true) {
          message.success(result?.message);
          termForm.resetFields();
          handleClose();
          loadTermList();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    if (modalType === "add-term") {
      termForm.resetFields();
    } else if (modalType === "edit-term") {
      termForm.setFieldsValue(termsEditData);
    }
  }, [editData, modalType, termsEditData, termForm]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={onFinishTerms}
          onFinishFailed={onFailedTerms}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={termForm}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Term title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Term title is required!",
                  },
                  {
                    message: "Please Enter Letters",
                    pattern: new RegExp(/[a-zA-Z]/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter term title" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Term desc"
                name="desc"
                rules={[
                  {
                    required: true,
                    message: "Term desc is required!",
                  },
                  {
                    message: "Please Enter Letters",
                    pattern: new RegExp(/[a-zA-Z]/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter term desc" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                className="poppins_medium umoney_btn"
                loading={saveTermBtnLoading}
                block
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default TermsAndConditionModals;
