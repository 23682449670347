import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import Draggable from "react-draggable";
import _ from "lodash";

class PreviewModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      image: "",
      type: "",
      bounds: {
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      },
    };
    this.draggleRef = React.createRef(null);
  }
  open(image, type) {
    const fileName = image.substring(image.lastIndexOf("/") + 1);
    const fileType = fileName?.split(".");
    const finalType = fileType[1];
    console.log("finalType", finalType);
    if (!_.isEmpty(image) && _.isString(image)) {
      this.setState({
        image: image,
        visible: true,
        type: finalType,
      });
    }
    if(type === "mp4"){
      document.querySelector("video").play();
    }
  }

  close() {
    this.setState({
      visible: false,
    });
    document.querySelector("video").pause();
  }

  onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    this.setState({
      bounds: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      },
    });
  };

  render() {
    const { modalTitle = "" } = this.props;

    const title = _.isEmpty(modalTitle)
      ? `Preview ${
          this.state.type === "pdf"
            ? "PDF"
            : this.state.type === "mp4"
            ? "Video"
            : "Image"
        }`
      : modalTitle;

    return (
      <Modal
        open={this.state.visible}
        onCancel={() => {
          this.close();
        }}
        width={this.state.type === "pdf" ? 1000 : 600}
        title={title}
        footer={null}
        centered
        mask={false}
        maskClosable={false}
        maskStyle={{ pointerEvents: "none" }}
        style={{ pointerEvents: "all" }}
        wrapClassName={"modal-wrapper"}
        modalRender={(modal) => {
          return (
            <Draggable
              bounds={this.state.bounds}
              onStart={(event, uiData) => this.onStart(event, uiData)}
            >
              <div ref={this.draggleRef}>{modal}</div>
            </Draggable>
          );
        }}
      >
        {this.state.type === "pdf" ? (
          <iframe
            src={`${this.state.image}`}
            title="iframe"
            height="700"
            width="100%"
          ></iframe>
        ) : this.state.type === "mp4" ? (
          <video
            style={{ height: 500, width: "100%" }}
            src={this.state.image}
            type="video/mp4"
            controls
            autoPlay
          />
        ) : (
          <img
            src={this.state.image}
            alt="User"
            style={{ height: "100%", width: "100%" }}
          />
        )}
      </Modal>
    );
  }
}

export default connect(null, null, null, {
  forwardRef: true,
})(PreviewModal);
