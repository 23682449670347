import { Col, Row, Typography, Input } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
const { TextArea } = Input;

const ClickToCallModal2 = () => {
  const { Title } = Typography;

  //FUNCTIONALITY IS PENDING

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <Title level={5} className="poppins_medium umoney_title">
            Call time
          </Title>
        </Col>
        <Col className="gutter-row" span={24}>
          <Title
            level={3}
            className="poppins_medium umoney_title"
            style={{ color: "#009D93" }}
          >
            10:20:21
          </Title>
        </Col>
        <Col className="gutter-row" span={24}>
          <TextArea
            rows={4}
            placeholder="Enter here"
            style={{ fontSize: 20, margin: 2 }}
            size="large"
          />
        </Col>
        <Col className="gutter-row" span={24}>
          <CLoadingButton
            size="large"
            value="Hang Up"
            className="poppins_medium urban_money_btn"
            block
          />
        </Col>
      </Row>
    </div>
  );
};

export default ClickToCallModal2;
