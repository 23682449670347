import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Table,
  message,
  Input,
  Space,
  Button,
} from "antd";
import Sidebar from "../../../components/Sidebar";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import EmailModal from "../../../components/Modals/EmailusModals";
import { getAPIData } from "../../../apiHelper";
import CommonModals from "../../../components/Modals/CommonModals";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import _ from "lodash";
import Highlighter from "react-highlight-words";

const { Title } = Typography;
const { Header, Content } = Layout;

const Emailus = () => {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(true);

  const [emailusData, setEmailusData] = useState([]);
  const [emailGetData, setEmailGetData] = useState({});

  //Modal States
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  //Search Table
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  // Roles Data
  const [roles, setRoles] = useState([]);

  const openModal = (type, id) => {
    if (type === "add-email") {
      setModalType(type);
      setModalOpen(true);
    } else if (type === "edit-emailus") {
      loadEditEmailusData(id);
      setModalType(type);
      setModalOpen(true);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalOpen(true);
    }
  };

  // Search Functionality START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // Search Functionality END

  const TableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      filteredValue: filteredInfo.title || null,
      render: (text, record) => (
        <div>
          <span>{record.title}</span>
        </div>
      ),
      ...getColumnSearchProps("title"),
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      filteredValue: filteredInfo.type || null,
      render: (text, record) => (
        <div>
          <span>
            {record?.type === "1"
              ? "Support"
              : record?.type === "2"
              ? "Loan"
              : "Profile"}
          </span>
        </div>
      ),
      filters: [
        { text: "Support", value: "1" },
        { text: "Loan", value: "2" },
        { text: "Profile", value: "3" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.type
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                openModal("edit-emailus", record?.id);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  const loadEditEmailusData = (id) => {
    const endpoint = "email-us/get/" + id;
    getAPIData(endpoint, "get", {}, false)
      .then((res) => {
        if (res.status === true) {
          setEmailGetData(res?.data);
        } else {
          message.warning(res.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadEmailUsData = () => {
    setLoading(true);
    const endpoint = "email-us/list";
    getAPIData(endpoint, "get", {}, false)
      .then((res) => {
        if (res.status) {
          setEmailusData(res?.data);
          setLoading(false);
        } else {
          message.warning(res?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const rolesData = () => {
    const endpoint = "internal-api/get-roles";
    const data = { type: "1" };
    getAPIData(endpoint, "post", data, false)
      .then((response) => {
        if (response?.status) {
          let roleData = response?.data;
          let roleOption = [];
          if (!_.isEmpty(roleData)) {
            roleData.map(async (role) => {
              roleOption.push({ label: role?.name, value: role?.id });
            });
          }
          setRoles(roleOption);
        } else {
          message.warning(response?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    loadEmailUsData();
    rolesData();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Header className="site-layout-background main-header">
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              Email Us
            </Title>
          </Header>
          <Content
            className="site-layout-background white_div"
            style={{
              padding: "20px 15px",
              borderRadius: "5px",
            }}
          >
            <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
              <Col className="gutter-row" span={8}></Col>
              <Col className="gutter-row" span={8}>
                <CLoadingButton
                  size="large"
                  value="CLEAR FILTERS"
                  className="poppins_medium umoney_btn"
                  onClick={() => clearFilters()}
                  block
                />
              </Col>
              <Col className="gutter-row" span={8}>
                <CLoadingButton
                  size="large"
                  value="ADD EMAIL"
                  icon={<PlusOutlined />}
                  className="poppins_medium umoney_btn"
                  onClick={() => openModal("add-email")}
                  block
                />
              </Col>
            </Row>

            <div className="umoney_table " style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={TableColumns}
                dataSource={emailusData}
                // scroll={scroll}
                onChange={handleChange}
              />
            </div>
          </Content>
          {modalType === "add-email" || modalType === "edit-emailus" ? (
            <EmailModal
              open={modalOpen}
              title={modalType === "add-email" ? "Add Email" : "Edit-email"}
              setOpen={setModalOpen}
              modalType={modalType}
              emailGetData={emailGetData}
              onFinishApi={(val) => {
                loadEmailUsData();
              }}
              rolesVal={roles}
              // editRoleVal={roleVal}
              // editManagerVal={managerVal}
            />
          ) : null}

          {modalType === "delete" ? (
            <CommonModals
              open={modalOpen}
              title="Delete Confirmation"
              type="emailUs"
              setOpen={setModalOpen}
              modalType={modalType}
              id={deleteId}
              setModalType={setModalType}
              onDelete={(val) => {
                loadEmailUsData();
              }}
            />
          ) : null}
        </Layout>
      </div>
    </>
  );
};

export default Emailus;
