import { Col, Modal, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Form, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const CourseModel = (props) => {
  const { modalType, modalTitle, setModalType, type } = props;

  const [form] = Form.useForm();
  const [savebtnLoading, setSaveBtnLoading] = useState(false);
  const [fromData, setFromData] = useState(false);
  const [toData, setToData] = useState(false);
  const [fromStudent, setFromStudent] = useState();
  const [toStudent, setToStudent] = useState();
  const [fromSelectValue, setFromSelectValue] = useState(null);
  const [toSelectValue, setToSelectValue] = useState(null);

  const handleClose = () => {
    setModalType(false);
  };
  const onSubmitData = (data) => {
    message.warning("FUNCTIONALITY IS PENDING");
    /*
    setSaveBtnLoading(true);
    // FUNCTIONALITY IS PENDING
    var endPoint = "";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
      */
  };

  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo===>>", errorInfo);
  };

  /*On change of From Select */
  const handleChangeFrom = (id) => {
    // FUNCTIONALITY IS PENDING
    // Get List of student is pending.
    form.setFieldsValue({ to_data: false });
    console.log("form--->", form);
    getDropdownData(type, id);
    setFromSelectValue(id);
    setToSelectValue(null);
    setFromStudent("1 Students have selected this " + type);
  };

  /*On change of To Select */
  const handleChangeTo = (id) => {
    // FUNCTIONALITY IS PENDING
    // Get List of student is pending.
    getDropdownData(type, id);
    setToStudent("100 Students have selected this " + type);
    setToSelectValue(id);
  };

  const getDropdownData = (type, id = false) => {
    let data = {};
    let endPoint = "company/list";
    if (id) {
      data = { expt_company_id: id };
    }

    getAPIData(endPoint, "get", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          let dropdown_list = [];
          response.map(async (data, key) => {
            dropdown_list.push({
              value: data.id,
              label: data.company_name,
            });
          });
          console.log("dropdown_list---->", dropdown_list);
          if (id) {
            setToData(dropdown_list);
          } else {
            setFromData(dropdown_list);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    getDropdownData(type);
  }, [type]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={500}
      footer={null}
      closable={true}
      centered={true}
    >
      <Form
        name="basic"
        onFinish={(data) => {
          onSubmitData(data);
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={"From " + type}
              name="from_data"
              rules={[
                {
                  required: true,
                  message: "From " + type + " is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                className="br"
                size="large"
                options={fromData}
                onChange={(value) => {
                  handleChangeFrom(value);
                }}
                value={fromSelectValue}
              />
              {fromStudent}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={"To " + type}
              name="to_data"
              rules={[
                {
                  required: true,
                  message: "To " + type + " is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                className="br"
                size="large"
                options={toData}
                onChange={(value) => {
                  handleChangeTo(value);
                }}
                value={toSelectValue}
              />
              {toStudent}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={18}></Col>

          <Col className="gutter-row" span={6}>
            <CLoadingButton
              htmlType="submit"
              size="large"
              value=" SAVE"
              className="poppins_medium umoney_modal_filled_btn"
              loading={savebtnLoading}
              block
            />
          </Col>
          <Col className="gutter-row" span={18}></Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CourseModel;
