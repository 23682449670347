import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";
const DeviceChangeModals = ({
  modalType,
  modalTitle,
  setModalType,
  editData,
  rejectEditData,
  loadDeviceApprovedData,
}) => {
  const [approvingListform] = Form.useForm();
  const [rejectListForm] = Form.useForm();
  const handleClose = () => {
    setModalType(false);
  };

  const [addApproveDeviceBtnLoading, setAddApproveDeviceBtnLoading] =
    useState(false);
  const [rejectDeviceBtnLoading, setRejectDeviceBtnLoading] = useState(false);

  //DEVICE CHANGE APPROVING LIST
  const onFinishApprovingList = (value) => {
    setAddApproveDeviceBtnLoading(true);

    const endPoint =
      modalType === "approve-device-edit"
        ? "system-config/device-change/update/" + editData?.id
        : "system-config/device-change/create";
    const data = { name: value?.name, type: "1" };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status) {
          message.success(result?.message);
          setAddApproveDeviceBtnLoading(false);
          approvingListform.resetFields();
          loadDeviceApprovedData();
          handleClose();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onFailedApprovingList = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  //DEVICE CHANGE REJECTING LIST
  const onFinishRejectList = (value) => {
    setRejectDeviceBtnLoading(true);

    const endPoint =
      modalType === "reject-device-edit"
        ? "system-config/device-change/update/" + rejectEditData?.id
        : "system-config/device-change/create";
    const data = { name: value?.name, type: "0" };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status) {
          message.success(result?.message);
          setRejectDeviceBtnLoading(false);
          rejectListForm.resetFields();
          loadDeviceApprovedData();
          handleClose();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedRejectList = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  useEffect(() => {
    if (modalType === "add-approve-device") {
      approvingListform.resetFields();
    } else if (modalType === "approve-device-edit") {
      approvingListform.setFieldsValue(editData);
    } else if (modalType === "add-reject-device") {
      rejectListForm.resetFields();
    } else if (modalType === "reject-device-edit") {
      rejectListForm.setFieldsValue(rejectEditData);
    }
  }, [approvingListform, editData, modalType, rejectEditData, rejectListForm]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        {modalType === "add-approve-device" ||
        modalType === "approve-device-edit" ? (
          <Form
            name="basic"
            onFinish={onFinishApprovingList}
            onFinishFailed={onFailedApprovingList}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={approvingListform}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Device name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Device name is required!",
                    },
                    {
                      message: "Please Enter Only Characters",
                      pattern: new RegExp(/[a-zA-Z]/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter device name" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="SAVE"
                  className="poppins_medium umoney_btn"
                  loading={addApproveDeviceBtnLoading}
                  block
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <Form
            name="basic"
            onFinish={onFinishRejectList}
            onFinishFailed={onFailedRejectList}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={rejectListForm}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Device name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Device name is required!",
                    },
                    {
                      message: "Please Enter Only Characters",
                      pattern: new RegExp(/[a-zA-Z]/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter device name" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="SAVE"
                  className="poppins_medium umoney_btn"
                  loading={rejectDeviceBtnLoading}
                  block
                />
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default DeviceChangeModals;
