import React, { useState } from "react";
import { Col, Form, Input, Row, Select, Typography, message } from "antd";

import CLoadingButton from "../../InputFields/CLoadingButton";
import { PlusOutlined } from "@ant-design/icons";
import { setHeader, student_url, salaried_url } from "../../../api";
import axios from "axios";
import _ from "lodash";
import CCloseIcon from "../../InputFields/CCloseIcon";
import CropperModal from "../../Modals/CropperModal";
import { getAPIData } from "../../../apiHelper";

const { Title, Text } = Typography;

const PanDetails = (props) => {
  const PANDetails = props?.PANDetails;
  console.log("PANDetails", PANDetails);
  const user_id = props?.user_id;
  const pageType = props?.pageType;
  const [callPanApiBtnLoading, setCallApiPanBtnLoading] = useState(false);
  const [panSaveBtnLoading, setPanSaveBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const [tableResponse, setTableResponse] = useState([]);
  const [idApproveBtn, setIdApprover] = useState(false);

  const [frontImage, setFrontImage] = useState([]);

  const [approveBtnLoading, setApproveBtnLoading] = useState(false);

  const [panNumber, setPanNumber] = useState(PANDetails?.number);

  const [form60, setForm60] = useState(PANDetails?.is_available);
  console.log("form60======>", form60);

  const [count, setCount] = useState(0);

  const [srcImg, setSrcImg] = useState();
  const [modalType, setModalType] = useState("");

  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [frontImageName, setFrontImageName] = useState("");

  const onFinish = async (data) => {
    props?.loading(true);
    const endpoint = "steps/agent-save-pan-data";
    let images = [];
    if (idApproveBtn) {
      setApproveBtnLoading(true);
      data.isApprove = idApproveBtn;
    } else {
      setPanSaveBtnLoading(true);
    }
    if (frontImage?.[0]) {
      images.push({
        imageBase64: frontImage.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: frontImageName,
        type: "frontImg",
      });
    }
    // form.append("user_id", user_id);
    // form.append("form_60", data?.form_60);
    // form.append("pan_number", data?.pan_number);

    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }

    data.user_id = user_id;

    getAPIData(
      endpoint,
      "post",
      data,
      false,
      pageType === "salaried" ? "salaried" : "student"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          props?.isSuccess(true);
          props?.loading(false);
          setIdApprover(false);
          if (idApproveBtn) {
            setApproveBtnLoading(false);
          } else {
            setPanSaveBtnLoading(false);
          }
        } else {
          message.warning(result?.message);
          props?.loading(false);
          if (idApproveBtn) {
            setApproveBtnLoading(false);
          } else {
            setPanSaveBtnLoading(false);
          }
        }
        props?.loading(false);
        if (idApproveBtn) {
          setApproveBtnLoading(false);
        } else {
          setPanSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        props?.loading(false);
        if (idApproveBtn) {
          setApproveBtnLoading(false);
        } else {
          setPanSaveBtnLoading(false);
        }
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const callPanApiClick = async () => {
    setCount(count + 1);
    props?.loading(true);
    setCallApiPanBtnLoading(true);

    const header = setHeader();
    const endpoint =
      pageType === "salaried"
        ? salaried_url + "student/kyc-data"
        : student_url + "student/kyc-data";

    var kyc_number = form.getFieldValue("pan_number");

    const data = {
      user_id: user_id,
      kyc_type: "5",
      kyc_number: kyc_number,
    };

    const uploadData = await axios.post(endpoint, data, header);

    if (uploadData?.data?.status) {
      setTableResponse(uploadData?.data?.data);
      props?.loading(false);
      setCallApiPanBtnLoading(false);
      // setApproveBtnLoading(false);
    } else {
      message.warning(uploadData?.data?.message);
      props?.loading(false);
      setCallApiPanBtnLoading(false);
      // setApproveBtnLoading(false);
    }
  };

  const data = [
    {
      value: "0",
      label: "Yes",
    },
    {
      value: "1",
      label: "No",
    },
  ];
  console.log("frontImage", frontImage);

  const openModal = (type) => {
    if (type === "crop-image") {
      setModalType(type);
      setModalOpen(true);
    }
  };

  if (!_.isEmpty(imageDoc) && imageType === "pan") {
    setFrontImage(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  const handleImageFront = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setFrontImageName(event.target.files[0]?.name);
    setImageType("pan");
    openModal("crop-image");
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        initialValues={{
          pan_number: PANDetails?.number,
          form_60: PANDetails?.is_available,
        }}
        form={form}
      >
        <Row gutter={[24, 16]}>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="PAN Number"
                  name="pan_number"
                  size="large"
                  rules={[
                    {
                      required: form60 === "1" ? true : false,
                      message: "PAN number is required!",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    placeholder="Enter PAN number"
                    onChange={(e) => setPanNumber(e.target.value)}
                    disabled={form60 === "0" ? true : false}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Form 60"
                  name="form_60"
                  size="large"
                  rules={[
                    !_.isEmpty(PANDetails?.number)
                      ? ""
                      : { required: true, message: "selection is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    options={data}
                    size="large"
                    disabled={PANDetails?.number !== "null" ? true : false}
                    onChange={(e) => setForm60(e)}
                    placeholder="Select form 60"
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Item name="front_img">
                    {_.isEmpty(frontImage) ? (
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          className="file-choose"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "transparent",
                            opacity: 0,
                            position: "relative",
                            zIndex: "200",
                          }}
                          onChange={handleImageFront}
                        />
                        <div
                          style={{
                            position: "relative",
                            bottom: "55%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text>
                            {" "}
                            <PlusOutlined /> Upload PAN Front
                          </Text>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          backgroundColor: "#dffefc",
                          border: "1px dashed #009d93",
                          borderRadius: "2px",
                        }}
                      >
                        <img
                          src={frontImage}
                          style={{ height: "100%", width: "100%" }}
                        ></img>
                        <CCloseIcon
                          onClick={() => setFrontImage("")}
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "0px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <CLoadingButton
                    size="large"
                    value="SAVE"
                    htmlType="submit"
                    loading={panSaveBtnLoading}
                    onClick={() => setIdApprover(false)}
                    className="poppins_medium umoney_btn"
                    block
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <CLoadingButton
                  size="large"
                  value="SAVE & APPROVE"
                  className="poppins_medium umoney_btn"
                  block
                  loading={approveBtnLoading}
                  htmlType="submit"
                  onClick={() => setIdApprover(true)}
                  disabled={count < 1 && form60 === "1" ? true : false}
                  style={{
                    cursor:
                      count < 1 && form60 === "1" ? "not-allowed" : "pointer",
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <div>
              <CLoadingButton
                size="large"
                value="CALL API"
                loading={callPanApiBtnLoading}
                onClick={callPanApiClick}
                className="poppins_medium umoney_btn"
                block
                disabled={
                  panNumber == "null" || _.isEmpty(panNumber) ? true : false
                }
                style={{
                  cursor:
                    _.isEmpty(panNumber) || panNumber == "null"
                      ? "not-allowed"
                      : "pointer",
                }}
              />
            </div>

            <div className="border-class" style={{ marginTop: "20px" }}>
              <Title level={5} className="poppins_medium umoney_title p-10">
                API RESPONSE
              </Title>
              <Row className="p-10">
                <Col className="gutter-row" span={24}>
                  {tableResponse?.Name_as_per_Pan_Card ? (
                    <p>
                      <b>NAME AS PER PAN CARD: </b>
                      {tableResponse?.Name_as_per_Pan_Card}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
      {modalType === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalType}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PanDetails;
