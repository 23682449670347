import React, { useState, useLayoutEffect } from "react";
import { Col, Form, Input, message, Row, Select, Upload } from "antd";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import { setHeaderForFile, student_url } from "../../api";
import axios from "axios";
import { useEffect } from "react";
import _ from "lodash";

const GraduationDetails = (props) => {
  const stdInfo = props?.stdInfo;
  console.log("stdInfo", stdInfo);
  const pincodeList = props?.pincodeList;
  const user_id = props?.user_id;
  const [form] = Form.useForm();

  const [frontImage, setFrontImage] = useState([]);
  const [backImage, setBackImage] = useState([]);
  const [idApprover, setIdApprover] = useState(false);

  //college list
  const [collegeList, setCollegeList] = useState([]);

  //marktype state
  const [markType, setMarkType] = useState(stdInfo?.course?.mark_type);

  //degree list state
  const [degreeList, setDegreeList] = useState([]);

  //Specialization list state
  const [specializationList, setSpecializationList] = useState([]);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);

  useLayoutEffect(() => {
    getPincodeValue(0, stdInfo?.old_student_info?.pincode);
    getDegreeList(stdInfo?.old_student_info?.course?.type_of_degree);
    getSpecializationList(stdInfo?.old_student_info?.course?.spec_id);
  }, [props]);

  const onFinish = async (data) => {
    if (idApprover) {
      props?.loading(true);
      setApproveBtnLoading(true);
    } else {
      props?.loading(true);
      setSaveBtnLoading(true);
    }
    const form = new FormData();
    form.append("pincode_id", data?.pincode_id);
    form.append("college_id", data?.college_id);
    form.append("student_strength_id", data?.student_strength_id);
    form.append("degree_id", data?.degree_id);
    form.append("spec_id", data?.spec_id);
    form.append("atten_type", data?.atten_type);
    form.append("start_from_month", data?.start_from_month);
    form.append("end_from_month", data?.end_from_month);
    form.append("start_from_year", data?.start_from_year);
    form.append("end_from_year", data?.end_from_year);
    form.append("mark_type", data?.mark_type);
    form.append("cgpa_course", data?.cgpa_course);
    form.append("current_cgpa", data?.current_cgpa);
    form.append("per_score", data?.per_score);
    form.append("user_id", user_id);
    form.append("type", "admin");
    form.append("idApprover", idApprover);
    form.append("id_number", data?.id_number);
    if (frontImage?.[0]) {
      form.append("frontImg", frontImage?.[0]?.originFileObj);
    }
    if (backImage?.[0]) {
      form.append("backImg", backImage?.[0]?.originFileObj);
    }

    const header = setHeaderForFile();
    const endPoint = student_url + "steps/save-student-college";
    console.log("form==========>", form);
    const uploadData = await axios.post(endPoint, form, header);
    console.log("uploadData", uploadData);
    if (uploadData?.data?.status) {
      props?.isSuccess(true);
      if (idApprover) {
        props?.loading(false);
        setApproveBtnLoading(false);
      } else {
        props?.loading(false);
        setSaveBtnLoading(false);
      }
      message.success(uploadData?.data?.message);
    } else {
      // props?.loading(false);
      // setSaveBtnLoading(false);
      message.warning(uploadData?.data?.message);
      if (idApprover) {
        props?.loading(false);
        setApproveBtnLoading(false);
      } else {
        props?.loading(false);
        setSaveBtnLoading(false);
      }
    }
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  //mark type list
  const markTypeData = [
    { label: "CGPA", value: "cgpa" },
    { label: "Percentage", value: "percentage" },
  ];

  // degree type list
  const degreeTypeList = [
    {
      value: "1",
      label: "Diploma",
    },
    {
      value: "2",
      label: "UnderGraduate",
    },
    {
      value: "3",
      label: "Post-Graduate",
    },
    {
      value: "4",
      label: "PGDM",
    },
    {
      value: "5",
      label: "PGPM",
    },
    {
      value: "6",
      label: "Ph.D",
    },
  ];

  const attendanceData = [
    {
      value: "1",
      label: "Regular",
    },
    {
      value: "2",
      label: "Distance",
    },
    {
      value: "3",
      label: "Remote",
    },
    {
      value: "4",
      label: "Part Time",
    },
  ];

  const studentData = [
    {
      value: "1",
      label: "0-500",
    },
    {
      value: "2",
      label: "501-1000",
    },
    {
      value: "3",
      label: "1001-5000",
    },
    {
      value: "4",
      label: ">5000",
    },
  ];

  const months = [
    {
      value: "January",
      label: "January",
    },
    {
      value: "February",
      label: "February",
    },
    {
      value: "March",
      label: "March",
    },
    {
      value: "April",
      label: "April",
    },
    {
      value: "May",
      label: "May",
    },
    {
      value: "June",
      label: "June",
    },
    {
      value: "July",
      label: "July",
    },
    {
      value: "August",
      label: "August",
    },
    {
      value: "September",
      label: "September",
    },
    {
      value: "October",
      label: "October",
    },
    {
      value: "November",
      label: "November",
    },
    {
      value: "December",
      label: "December",
    },
  ];

  const years = [];

  for (let i = 0; i <= 30; i++) {
    if (i <= 9) {
      years.push({
        value: `200${i}`,
        label: `200${i}`,
      });
    } else {
      years.push({
        value: `20${i}`,
        label: `20${i}`,
      });
    }
  }

  const getPincodeValue = async (e, labelPincode) => {
    const data = { pincode: labelPincode };
    const endPoint = "steps/get-college-by-pincode";
    getAPIData(endPoint, "post", data, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          form.setFieldsValue({
            state: response?.pincodeData?.state,
            district: response?.pincodeData?.district,
          });

          let dData = [];
          response?.CollegeData.map((college, index) =>
            dData.push({ label: college?.college_name, value: college?.id })
          );
          setCollegeList(dData);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });

    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("state", abcd?.state);
    form.setFieldValue("district", abcd?.district);
  };

  const getDegreeList = (value) => {
    const endPoint = `steps/degree-list/${value}`;

    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          let dArr = [];
          response.map((degree, index) =>
            dArr.push({ label: degree?.course_name, value: degree?.id })
          );
          setDegreeList(dArr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getSpecializationList = (value) => {
    const endPoint = `steps/specialization-list`;

    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          let dArr = [];
          response.map((spec, index) =>
            dArr.push({ label: spec?.name, value: spec?.id })
          );
          setSpecializationList(dArr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
        initialValues={{
          college_id: stdInfo?.old_student_info?.college_data?.college_id,
          pincode_id: stdInfo?.old_student_info?.college_data?.pincode_id,
          type_of_degree: stdInfo?.old_student_info?.course?.type_of_degree,
          degree_id: stdInfo?.old_student_info?.course?.degree_id,
          spec_id: stdInfo?.old_student_info?.course?.spec_id,
          atten_type: stdInfo?.old_student_info?.course?.atten_type,
          start_from_month: stdInfo?.old_student_info?.course?.start_from_month,
          start_from_year: stdInfo?.old_student_info?.course?.start_from_year,
          end_from_month: stdInfo?.old_student_info?.course?.end_from_month,
          end_from_year: stdInfo?.old_student_info?.course?.end_from_year,
          student_strength_id:
            stdInfo?.old_student_info?.college_data?.student_strength,
          mark_type: stdInfo?.old_student_info?.course?.mark_type,
          per_score: stdInfo?.old_student_info?.course?.per_score,
          current_cgpa: stdInfo?.old_student_info?.course?.current_cgpa,
          id_number: stdInfo?.old_student_info?.id_number,
          district: stdInfo?.pincode?.district,
          state: stdInfo?.pincode?.state,
          cgpa_course: stdInfo?.old_student_info?.course?.cgpa_course,
        }}
      >
        <Row gutter={[16, 20]}>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 20]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Name Of College"
                  name="college_id"
                  size="large"
                  rules={[
                    { required: true, message: "Name of college is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    showSearch
                    bordered={true}
                    className="br"
                    size="large"
                    options={collegeList}
                    onChange={(value, options) => {
                      getPincodeValue(value, options);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toString().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toString()
                        .localeCompare((optionB?.label ?? "").toString())
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item>
                  <CLoadingButton
                    size="large"
                    value="DETAILS"
                    className="poppins_medium umoney_btn"
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="District"
              name="district"
              size="large"
              rules={[{ required: true, message: "city is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                placeholder="Enter district name"
                disabled={true}
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="State"
              name="state"
              size="large"
              rules={[{ required: true, message: "state is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                placeholder="Enter state name"
                disabled={true}
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Pincode"
              name="pincode_id"
              size="large"
              rules={[{ required: true, message: "pincode is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                showSearch
                className="br"
                size="large"
                options={pincodeList}
                readOnly={true}
                onChange={(value, option) => {
                  form.setFieldValue("college_id", "");
                  getPincodeValue(value, option?.label);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .localeCompare((optionB?.label ?? "").toString())
                }
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Type of Degree"
              name="type_of_degree"
              size="large"
              rules={[
                { required: true, message: "type of degree is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={degreeTypeList}
                size="large"
                type="text"
                onChange={(value) => {
                  getDegreeList(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Name of Degree"
              name="degree_id"
              size="large"
              rules={[
                { required: true, message: "name of degree is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={degreeList}
                size="large"
                onChange={(value) => {
                  getSpecializationList(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Specialization"
              name="spec_id"
              size="large"
              rules={[
                { required: true, message: "specialization is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                options={specializationList}
                size="large"
                type="text"
                // onChange={(value) => {
                //   getDegreeList(value);
                // }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Type of attendance"
              name="atten_type"
              size="large"
              rules={[
                { required: true, message: "Type of attendance is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select options={attendanceData} size="large" type="text" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Join Month"
                  name="start_from_month"
                  size="large"
                  rules={[
                    { required: true, message: "Join Month is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    size="large"
                    placement="bottomRight"
                    options={months}
                  ></Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Join Year"
                  name="start_from_year"
                  size="large"
                  rules={[
                    { required: true, message: "Join Year is required!" },
                    {},
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    size="large"
                    placement="bottomRight"
                    options={years}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Completion Month"
                  name="end_from_month"
                  size="large"
                  rules={[
                    {
                      required: true,
                      message: "Completion Month is required!",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    size="large"
                    placement="bottomRight"
                    options={months}
                  ></Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Completion Year"
                  name="end_from_year"
                  size="large"
                  rules={[
                    { required: true, message: "Completion Year is required!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue("start_from_year") < value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Completion Year should greater than join year"
                          )
                        );
                      },
                    }),
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    options={years}
                    size="large"
                    placement="bottomRight"
                    onChange={(e) => console.log("e", e)}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="No. of students"
              name="student_strength_id"
              size="large"
              rules={[
                { required: true, message: "No. of students is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select options={studentData} size="large" type="text" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Marks"
                  name="mark_type"
                  size="large"
                  rules={[{ required: true, message: "Marks is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    label="Marks"
                    options={markTypeData}
                    size="large"
                    onChange={(val) => {
                      setMarkType(val);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                {markType === "percentage" ? (
                  <Form.Item
                    className="poppins_medium mb-35"
                    label="Percentage"
                    name="per_score"
                    rules={[
                      {
                        pattern: /^[0-9]\d*(\.\d+)?$/g,
                        message: "Percentage contains only number.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter percentage"
                      size="large"
                      type="text"
                    />
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item
                      className="poppins_medium mb-35"
                      label="Graded CGPA"
                      name="cgpa_course"
                      rules={[
                        {
                          pattern: /^[0-9]\d*(\.\d+)?$/g,
                          message: "Graded CGPA contains only number.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter graded CGPA"
                        size="large"
                        type="text"
                        defaultValue={stdInfo?.course?.cgpa_course}
                        style={{ marginBottom: 20 }}
                        readOnly
                      />
                    </Form.Item>
                    <Form.Item
                      className="poppins_medium mb-35"
                      label="Current CGPA"
                      name="current_cgpa"
                      rules={[
                        {
                          pattern: /^[0-9]\d*(\.\d+)?$/g,
                          message: "Current CGPA contains only number.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter current CGPA"
                        size="large"
                        type="text"
                        readOnly
                      />
                    </Form.Item>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Student ID Number"
              name="id_number"
              size="large"
              rules={[
                {
                  required: true,
                  message: "Student ID number is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" type="text" readOnly />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={16}></Col>
        </Row>
      </Form>
    </div>
  );
};
async function findArrayElementByTitle(array, value) {
  return array.find((element) => {
    return element.value === value;
  });
}

export default GraduationDetails;
