import React, { useState } from "react";
import { Table } from "antd";

const tableColumns = [
  {
    title: "CUSTOMER ID",
    dataIndex: "user_id",
    key: "user_id",
  },
  {
    title: "NAME",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "MATCHING FIELDS",
    dataIndex: "matching_fields",
    key: "matching_fields",
    // render:(text,record)=>(
    //   record?.matching_fields?.map((data)=>{
    //     return <span>{data}, </span>;
    //   })
    // )
    render: (text, record) => {
      const myString = record?.matching_fields.join(", ");
      return <span>{myString}</span>;
    },
  },
  {
    title: "PERCENTAGE",
    dataIndex: "percentage",
    key: "percentage",
  },
];

const MatchFoundModal = ({ matchFoundData }) => {
  return (
    <div>
      <Table
        dataSource={matchFoundData}
        className="poppins_regular umoney_table"
        pagination={false}
        columns={tableColumns}
        size="large"
      />
    </div>
  );
};

export default MatchFoundModal;
