import React, { useState } from "react";
import Form from "antd/es/form/Form";
import { Checkbox, Col, Input, Radio, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CLoadingButton from "../../InputFields/CLoadingButton";

const TakeActionModal = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const [form] = Form.useForm();

  const [updateBtnLoadig, setUpdateBtnLoading] = useState(false);

  const updateClick = () => {
    setUpdateBtnLoading(true);
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} className="poppins_medium">
          <Col span={24}>
            <Form.Item
              label="Action taken by caller"
              name="Days"
              rules={[
                {
                  required: true,
                  message: "Please enter your days!",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Enter Comments"
              name="Enter Comments"
              rules={[
                {
                  required: true,
                  message: "Please enter your comments!",
                },
              ]}
            >
              <TextArea row={6} size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="UPDATE"
                htmlType="submit"
                className="poppins_medium umoney_modal_filled_btn"
                loading={updateBtnLoadig}
                onClick={() => updateClick()}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TakeActionModal;
