import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Input,
  Space,
  Button,
  Modal,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";

import CommonModals from "../../components/Modals/CommonModals";
import CMSModals from "../../components/Modals/cms/CMSModals";
import Highlighter from "react-highlight-words";

const { Title, Text } = Typography;
const { Header, Content } = Layout;

const CMS = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [cmsData, setCmsData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const scroll = {};

  //Opening Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  // const [filteredInfo, setFilteredInfo] = useState({});

  const [cmsShow, setCmsShow] = useState();
  const [showMore, setShowMore] = useState();

  const openModal = (type, id, record) => {
    if (type === "add-CMS") {
      setModalType(type);
      setModalTitle("Add CMS");
    } else if (type === "edit-CMS") {
      // loadEditCmsData(id);
      setEditData(record);
      setModalType(type);
      setModalTitle("Edit CMS");
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
      setModalOpen(true);
    } else if (type === "show-full-html") {
      setModalType(type);
      setShowMore(id);
      setModalTitle("Show Description");
    }
  };

  // const loadEditCmsData = (id) => {
  //     const endPoint = "system-config/cms/get/"+id;
  //     getAPIData(endPoint, "get", {}, false)
  //       .then((result) => {
  //        setLoading(false);
  //         if (result?.status === true) {
  //           var response = result?.data;

  //           setCmsData(response);
  //         } else {
  //           message.warning(result?.message);
  //         }
  //       })
  //       .catch((err) => {
  //         message.error(err);
  //       });
  //   };
  useEffect(() => {
    loadCmsData();
  }, []);

  const handleClose = () => {
    setModalType("");
  };

  const loadCmsData = () => {
    setLoading(true);
    const endPoint = "system-config/cms/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCmsData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  //Table Search Functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const TableColumns = [
    {
      title: " ID",
      dataIndex: "id",
      key: "id",
      width: "7%",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id"),
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      width: "10%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "SLUG",
      dataIndex: "slug",
      key: "slug",
      width: "10%",
      ...getColumnSearchProps("slug"),
    },
    {
      title: "APP-BODY",
      dataIndex: "app_body",
      key: "app_body",
      width: "10%",
      ...getColumnSearchProps("app_body"),
      // dangerouslySetInnerHTML={app_body}
      render: (_noUse, record) => (
        <div>
          <div>
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html: record.app_body.slice(0, 100),
              }}
            />
            <Text
              style={{
                color: "#000",
                fontWeight: 700,
                cursor: "pointer",
                marginLeft: "3px",
                // display:"inline-block"
              }}
              onClick={() => {
                openModal("show-full-html", record.app_body);
                setCmsShow(record?.id);
              }}
            >
              {record?.app_body.length === 0 ? "" : "...Read More"}
            </Text>
          </div>
        </div>
      ),
      // render: (_noUse, record) => {
      //   return <div dangerouslySetInnerHTML={{ __html: record.app_body }} />;
      // },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={(e) => openModal("edit-CMS", record?.id, record)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            CMS
          </Title>
        </Header>
        <Content
          className="site-layout-background white_div"
          style={{
            padding: "20px 15px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={16}></Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="ADD"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => openModal("add-CMS")}
                icon={<PlusOutlined />}
                block
              />
            </Col>
          </Row>

          <div className="umoney_table " style={{ marginTop: 20 }}>
            <Table
              pagination={{
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
              columns={TableColumns}
              dataSource={cmsData}
              scroll={scroll}
            />
          </div>
          {modalType === "delete" ? (
            <CommonModals
              open={modalOpen}
              title="Delete Confirmation"
              type="CMS"
              setOpen={setModalOpen}
              modalType={modalType}
              id={deleteId}
              setModalType={setModalType}
              onDelete={(val) => {
                loadCmsData();
              }}
            />
          ) : modalType === "add-CMS" ? (
            <CMSModals
              modalTitle={modalTitle}
              setModalType={setModalType}
              modalType={modalType}
              onSave={() => {
                setModalType(false);
                loadCmsData();
              }}
            />
          ) : modalType === "edit-CMS" ? (
            <CMSModals
              editData={editData}
              modalTitle={modalTitle}
              modalType={modalType}
              cmsData={cmsData}
              setModalType={setModalType}
              onSave={() => {
                setModalType(false);
                loadCmsData();
              }}
            />
          ) : modalType === "show-full-html" ? (
            <Modal
              open={modalType}
              onCancel={handleClose}
              title={modalTitle}
              className="poppins_regular"
              width={1000}
              footer={null}
              closable={true}
              centered={true}
              bodyStyle={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 200px)",
              }}
            >
              <div>
                <div dangerouslySetInnerHTML={{ __html: showMore }} />
                <p></p>
              </div>
            </Modal>
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default CMS;
