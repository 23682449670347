import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Form, Input, message, Select } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const ThirdPartyApis = () => {
  const [dbsform] = Form.useForm();
  const [sendNotiForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;
  const [uploadViaValue, setUploadViaValue] = useState("1");

  const uploadViaList = [
    {
      value: "1",
      label: "IMPS",
    },
    {
      value: "2",
      label: "NEFT",
    },
  ];

  //Save btn
  const [saveNotiLoading, setSaveNotiLoading] = useState(false);

  //Save Notification
  const onFinishSendWhatsapReq = (values) => {
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "sent_whatsapp_api",
        key: key,
        value: value,
      })
    );
    callAPI(system_arr);
  };

  const onFinishDBS = (values) => {
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "dbs_active_api",
        key: key,
        value: uploadViaValue,
      })
    );
    callAPI(system_arr);
  };

  const callAPI = (system_arr) => {
    let data;
    setSaveNotiLoading(true);
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSaveNotiLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedSendWhatsapReq = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //send Notification
  const loadNotiData = () => {
    const data = {
      category_name: ["sent_whatsapp_api", "dbs_active_api"],
      multiple: true,
    };
    const endPoint = "system-config/get-config-data";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map(async (data) => {
            if (data?.category == "sent_whatsapp_api") {
              sendNotiForm.setFieldsValue({ [data?.name]: data?.value });
            } else {
              dbsform.setFieldsValue({ [data?.name]: data?.value });
            }
          });
        }
        // else {
        //   message.warning(result?.message);
        // }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    loadNotiData();
  }, [sendNotiForm]);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header className="site-layout-background main-header">
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Super Admin Dashboard
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 15,
              backgroundColor: "#DFFEFC",
            }}
          >
            <div className="white_div">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    Send Whatsapp API
                  </Title>
                </Col>
                <Col span={6} className="poppins_medium boxshadow-div">
                  <Form
                    name="basic"
                    onFinish={onFinishSendWhatsapReq}
                    onFinishFailed={onFailedSendWhatsapReq}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={sendNotiForm}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Future Tech Api Key"
                          name="future_tech_api_key"
                          rules={[
                            {
                              required: true,
                              message: "Future Tech Api Key is required!",
                            },
                            // {
                            //   message: "Please Enter Number",
                            //   pattern: new RegExp(/^[0-9]+$/),
                            // },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter future tech api key"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveNotiLoading}
                          block
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    DBS Bank API
                  </Title>
                </Col>
                <Col span={6} className="poppins_medium boxshadow-div">
                  <Form
                    name="basic"
                    onFinish={onFinishDBS}
                    onFinishFailed={onFailedSendWhatsapReq}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={dbsform}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Sent money via"
                          name="sent_money_via"
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={uploadViaList}
                            className="poppins_reguler filter_select_border"
                            onChange={(e) => setUploadViaValue(e)}
                            disabled={false}
                            defaultValue="1"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveNotiLoading}
                          block
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default ThirdPartyApis;
