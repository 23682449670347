import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Table,
  Input,
  message,
  Row,
  Col,
  Button,
  Space,
} from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import { Content } from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  MenuOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import CollegeLimitModals from "../../../components/Modals/CollegeLimitModals";
import Highlighter from "react-highlight-words";

const CollegeWiseLimit = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [collegeLimitData, setCollegeLimitData] = useState([]);
  const [oneCollegeLimitData, setOneCollegeLimitData] = useState([]);
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const loadCollegeLimitData = () => {
    setLoading(true);
    const endPoint = "system-config/college-limit/list";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        console.log("result =====>>>>> ", result);
        if (result?.status) {
          const collegeData = result?.data;
          let stateArray = [];
          let districtArray = [];
          let pincodeArray = [];

          collegeData?.map(async (college) => {
            if (_.isEmpty(stateArray)) {
              stateArray.push({
                value: college?.state,
                label: college?.state,
                text: college?.state,
              });
            } else {
              let index = _.findIndex(stateArray, (e) => {
                return e.text === college?.state;
              });
              if (index < 0) {
                stateArray.push({
                  value: college?.state,
                  label: college?.state,
                  text: college?.state,
                });
              }
            }

            if (_.isEmpty(districtArray)) {
              districtArray.push({
                value: college?.district,
                label: college?.district,
                text: college?.district,
              });
            } else {
              let index = _.findIndex(districtArray, (e) => {
                return e.text === college?.district;
              });
              if (index < 0) {
                districtArray.push({
                  value: college?.district,
                  label: college?.district,
                  text: college?.district,
                });
              }
            }

            if (_.isEmpty(pincodeArray)) {
              pincodeArray.push({
                value: college?.pincode,
                label: college?.pincode,
                text: college?.pincode,
              });
            } else {
              let index = _.findIndex(pincodeArray, (e) => {
                return e.text === college?.pincode;
              });
              if (index < 0) {
                pincodeArray.push({
                  value: college?.pincode,
                  label: college?.pincode,
                  text: college?.pincode,
                });
              }
            }
          });
          setStateList(stateArray);
          setDistrictList(districtArray);
          setPincodeList(pincodeArray);
          setCollegeLimitData(result?.data);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const addNewCollege = () => {
    setModalOpen(true);
    setModalType("new");
  };

  const collegeLimitEdit = (id) => {
    setModalOpen(true);
    setModalType("edit");

    const endPoint = "system-config/college-limit/get/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status) {
          setOneCollegeLimitData(result?.data);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    if (_.isEmpty(collegeLimitData)) {
      loadCollegeLimitData();
    }
  }, [collegeLimitData]);

  const columns = [
    {
      title: "KEY",
      dataIndex: "id",
      width: "10%",
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "COLLEGE NAME",
      dataIndex: "college_name",
      width: "30%",
      filteredValue: filteredInfo.college_name || null,
      ...getColumnSearchProps("college_name"),
    },
    {
      title: "STATE",
      dataIndex: "state",
      width: "10%",
      filteredValue: filteredInfo.state || null,
      filters: stateList,
      filterSearch: true,
      onFilter: (value, record) =>
        record.state
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "DISTRICT",
      dataIndex: "district",
      width: "10%",
      filteredValue: filteredInfo.district || null,
      filters: districtList,
      filterSearch: true,
      onFilter: (value, record) =>
        record.district
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "PINCODE",
      dataIndex: "pincode",
      width: "10%",
      filteredValue: filteredInfo.pincode || null,
      editable: false,
      filters: pincodeList,
      filterSearch: true,
      onFilter: (value, record) =>
        record.pincode
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "LIMIT",
      dataIndex: "limit",
      filteredValue: filteredInfo.limit || null,
      width: "20%",
      ...getColumnSearchProps("limit"),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      filteredValue: filteredInfo.actions || null,
      key: "actions",
      width: "10%",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                collegeLimitEdit(record?.id);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  const { Title } = Typography;
  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title">
                {" "}
                Super Admin Dashboard
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: 15,
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={8}>
                    {" "}
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <CLoadingButton
                      size="large"
                      value="CLEAR FILTERS"
                      className="poppins_medium umoney_btn"
                      onClick={() => clearFilters()}
                      block
                    />
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <CLoadingButton
                      size="large"
                      value="ADD NEW COLLEGE"
                      className="poppins_medium umoney_btn"
                      icon={<PlusOutlined className="btn-icon" />}
                      onClick={addNewCollege}
                      block
                    />
                  </Col>
                </Row>
                <div className="umoney_table mt-20">
                  <Table
                    pagination={{
                      showSizeChanger: true,
                      locale: { items_per_page: "" },
                    }}
                    columns={columns}
                    dataSource={collegeLimitData}
                    bordered
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Content>
          </Layout>
          {modalType === "new" || modalType === "edit" ? (
            <CollegeLimitModals
              open={modalOpen}
              setOpen={setModalOpen}
              title={modalType === "new" ? "Add College" : "Edit College"}
              modalType={modalType}
              collegeLimitData={oneCollegeLimitData}
              onFinishApi={(val) => {
                loadCollegeLimitData();
              }}
            />
          ) : (
            ""
          )}
        </Layout>
      </div>
    </>
  );
};

export default CollegeWiseLimit;
