import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Modal,
  Upload,
  Button,
} from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { UploadOutlined } from "@ant-design/icons";
import { setHeaderForFile } from "../../../api";
import { api_url } from "../../../api";
import { getAPIData } from "../../../apiHelper";
import axios from "axios";
import _ from "lodash";
import { Editor } from "@tinymce/tinymce-react";
import CCloseIcon from "../../InputFields/CCloseIcon";

const AddCategoryForm = (props) => {
  const {
    onSave = () => {},
    categoryId,
    faqData,
    modalType,
    modalTitle,
    setModalType,
  } = props;

  const [form] = Form.useForm();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [selectvalue, setselectvalue] = useState(1);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryFile, setCategoryFile] = useState({});
  const [descriptionVal, setDesriptionVal] = useState("");

  console.log(faqData);

  if (categoryId && _.isEmpty(categoryData)) {
    const endPoint = "faq/get-one-category";
    const data = {
      category_id: categoryId,
    };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCategoryData(response);
          setselectvalue(response?.has_sub);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  const checkImage = async (file) => {
    const isJpgOrPng =
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg";
    let status = true;
    let message = "";
    if (!isJpgOrPng) {
      message = "You can only upload JPG/PNG/JPEG file!";
      status = false;
    }

    return { message, status };
  };

  const beforeUpload = async (file, type) => {
    setCategoryFile(file);
    const res = checkImage(file);

    if (!res.status) {
      Notification("error", res.message);
    }
    return false;
  };

  const onFinish = async (data) => {
    setSaveBtnLoading(true);
    const ext = data?.upload?.file?.type.split("/")[0];
    if (ext !== "image") {
      let endPoint = "";
      let apidata = {};
      if (modalType === "add-category") {
        endPoint = api_url + "faq/add-faq-category";

        apidata = {
          category_title: data?.title,
          faq_id: data?.faq_id,
          has_sub: data?.has_sub,
          description: descriptionVal === null ? "" : descriptionVal,
          Video: categoryFile || {},
        };
      } else {
        endPoint = api_url + "faq/update-category";
        console.log("data========>", data?.upload);
        apidata = {
          category_id: categoryData?.id,
          category_title: data?.title,
          faq_id: data?.faq_id,
          has_sub: data?.has_sub,
          description: descriptionVal === null ? "" : descriptionVal,
          // Video:
          //   data?.upload?.file?.originFileObj === undefined
          //     ? null
          //     :
          //      data?.upload?.file?.originFileObj,
          Video: categoryFile || {},
        };
      }

      const header = setHeaderForFile();
      const uploadData = await axios.post(endPoint, apidata, header);
      if (uploadData?.data?.status) {
        onSave();
        form.resetFields();
        message.success(uploadData?.data?.message);
      } else {
        message.warning(uploadData?.data?.message);
      }
      setSaveBtnLoading(false);
    } else {
      setSaveBtnLoading(false);
      message.warning("Upload only video!");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setModalType(false);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setselectvalue(value);
  };

  useEffect(() => {
    if (modalType === "add-category") {
      form.resetFields();
    } else {
      form.setFieldsValue(categoryData);
      setDesriptionVal(categoryData?.description);
    }
  }, [form, categoryData, modalType]);

  const Data = [
    {
      value: "1",
      label: "YES",
    },
    {
      value: "0",
      label: "NO",
    },
  ];

  const removeVideo = () => {
    const dataRemoveImage = {
      url: categoryData?.media,
      imageType: "image_one",
      type: "faq",
      faq_id: categoryData?.id,
    };
    const endPoint = "faq/remove-file";
    getAPIData(endPoint, "post", dataRemoveImage, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          console.log("fext");
          message.success(result?.message);
          onSave();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={1000}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="white_div boxshadow-div">
        <Form
          name="basic"
          onFinish={(data) => {
            onFinish(data);
          }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="FAQ Question"
                name="faq_id"
                rules={[
                  {
                    required: true,
                    message: "Please enter your FAQ Question !",
                  },
                ]}
              >
                <Select size="large" options={faqData} placeholder="Select FAQ question" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Category"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Category!",
                  },
                ]}
              >
                <Input size="large" placeholder="Select category" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Sub Category"
                name="has_sub"
                rules={[
                  {
                    required: true,
                    message: "Please select the sub Category !",
                  },
                ]}
              >
                <Select onChange={handleChange} options={Data} size="large" placeholder="Select sub category" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              {selectvalue === "0" ? (
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={24}>
                    <Editor
                      init={{
                        selector: "textarea#basic-example",
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        placeholder: "Enter Description",
                      }}
                      apiKey="6budtobxncjw14eh01jd03p5t9evyup11ce3njgc5a62pue4"
                      onEditorChange={(newText) => setDesriptionVal(newText)}
                      value={descriptionVal}
                    />
                  </Col>
                  <Col className="gutter-row faq-cat-upload" span={6}>
                    <Form.Item name="upload">
                      <Upload
                        maxCount="1"
                        beforeUpload={beforeUpload}
                        accept="video/*"
                      >
                        <Button icon={<UploadOutlined />}>Upload Video</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24}>
                    {modalType === "edit-category" ? (
                      categoryData?.media === null ? (
                        ""
                      ) : (
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "0px",
                              left: "320px",
                              zIndex: "100",
                            }}
                            onClick={() => removeVideo()}
                          >
                            <CCloseIcon />
                          </div>
                          <video
                            src={categoryData?.media}
                            type="video/mp4"
                            width="320"
                            height="240"
                            controls
                          ></video>
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Col>
            <Col className="gutter-row" span={9}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value=" SAVE"
                  className="poppins_medium umoney_modal_filled_btn"
                  loading={saveBtnLoading}
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCategoryForm;
