import React, { useEffect } from "react";
import { Modal, Col, Row, message, Form, Input } from "antd";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";

const MergeModals = (props) => {
  const {
    title,
    open,
    setOpen,
    modalType,
    id,
    editData,

    type,
    setModalType,
    onSave = () => {},
  } = props;
  const [form] = Form.useForm();

  const handleMerge = (record) => {
    let method = "POST";
    let data = {};
    let endPoint;
    if (type === "merge") {
      endPoint = `master/department-merge`;
      data = {
        department_id: id,
        name: record?.name,
      };
    }

    getAPIData(endPoint, method, data, false)
      .then((result) => {
        if (result?.status === true) {
          onSave(result?.message);
          message.success(result?.message);
        } else {
          message.warning(result?.message);
        }
        setModalType(false);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    if (modalType === "merge") {
      form.setFieldsValue(editData);
      console.log("editData", editData);
    }
  }, [form, editData, modalType]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        footer={null}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={400}
      >
        {modalType === "merge" ? (
          <Form
            name="basic"
            onFinish={handleMerge}
            onFinishFailed={handleClose}
            initialValues={true}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Department"
                  name="name"
                  rules={[
                    {
                      required: true,
                      // message: "Department is required!"
                    },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    //  {
                    //    message: "Please Enter Department"
                    //  },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter department" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    //  loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default MergeModals;
