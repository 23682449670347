import React from "react";
import { Button, Col, Drawer, Menu, message, Row } from "antd";
import Images from "../config/images";
import {
  UnorderedListOutlined,
  TeamOutlined,
  UserOutlined,
  HighlightOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

const Sidebar = (props) => {
  const { open, setOpen } = props;
  const { logo } = Images;
  const navigate = useNavigate();
  const location = useLocation();
  const role_code = useSelector((state) => state.userData.accessRoleCode);
  const departmentId = useSelector((state) => state.userData.accessDepartment);

  const onClose = () => {
    setOpen(false);
  };

  const menuClick = (e) => {
    navigate(e?.key);
  };

  let openDefault = [];
  let template = [
    "/email-banner",
    "/email-template",
    "/notification-banner",
    "/notification-template",
  ];
  let configuration = [
    "/loan-disbursal",
    "/general-management",
    "/device-change",
    "/add-bank-account",
    "/add-upi-id",
    // "/refer-earn-coins",
    // "/loan-repayment-by-user",
    // "/credit-check-submission",
    // "/salary-credit-check-form",
    // "/currently-working",
    // "/received-job-offer",
    // "/collection-or-repayment",
    // "/loan-extension",
    // "/new-reg-salaried",
    // "/bank-ac-verification",
    // "/device-change-list",
    // "/collection-days-logic",
    // "/loan-filter-logic",
    // "/dialer-logic-config",
    // "/new-student-user-approval",
    // "/credit-check-admin-app",
    // "/loan-filter-admin-panel",
    // "/new-salaried-acquistion",
    // "/salary-credit-check-admin",
    // "/user-defect-identification",
    // "/loan-management-sys",
    // "/limit-determination-engine",

    // "/device-filter-algo-1",
    // "/device-filter-algo-2",
    // "/device-filter-algo-3",
  ];

  if (template.includes(location?.pathname)) {
    openDefault = ["template"];
  } else if (configuration.includes(location?.pathname)) {
    openDefault = ["mobile-panel", "configuration"];
  }

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [];
  if (role_code === "superadmin") {
    items.push(
      getItem("User management", "/user-management", <UserOutlined />),
      getItem("Role management", "/role-management", <TeamOutlined />),
      getItem("Templates", "template", <HighlightOutlined />, [
        getItem("Email Banner", "/email-banner", <UnorderedListOutlined />),
        getItem("Email Template", "/email-template", <UnorderedListOutlined />),
        getItem(
          "Notification Icon",
          "/notification-icon",
          <UnorderedListOutlined />
        ),
        getItem(
          "Notification Template",
          "/notification-template",
          <UnorderedListOutlined />
        ),
        getItem("SMS Template", "/sms-template", <UnorderedListOutlined />),
      ]),

      getItem("Configuration", "configuration", <UnorderedListOutlined />, [
        getItem("Admin Panel", "admin-panel", <UnorderedListOutlined />, [
          getItem("College wise limit", "/college-wise-limit"),
          getItem("Rejected Reasons", "/rejected-reasons"),
          getItem("Document Type", "/document-type"),
        ]),
        getItem("Mobile Panel", "mobile-panel", <UnorderedListOutlined />, [
          getItem("General Management (Pending)", "/general-management"),
          getItem("Loan Disbursal  (Pending)", "/loan-disbursal"),
          getItem("Device Change Filter  (Pending)", "/device-change-filter"),
          getItem("Adding A Bank Account  (Pending)", "/add-bank-account"),
          getItem("Adding An Upi ID  (Pending)", "/add-upi-id"),
          getItem("Refer & Earn and Coins  (Pending)", "/refer-earn-coins"),
          getItem(
            "Loan repayment by user  (Pending)",
            "/loan-repayment-by-user"
          ),
          getItem(
            "Credit Check submission  (Pending)",
            "/credit-check-submission"
          ),
          getItem(
            "Salary Credit Check Form  (Pending)",
            "/salary-credit-check-form"
          ),
          getItem("Currently Working  (Pending)", "/currently-working"),
          getItem("Received Job Offer  (Pending)", "/received-job-offer"),
          getItem("Loan extension  (Pending)", "/loan-extension"),
          getItem("New reg. - salaried  (Pending)", "/new-reg-salaried"),
          getItem("Collection days logic  (Pending)", "/collection-days-logic"),
          getItem(
            "Collection or repayment  (Pending)",
            "/collection-or-repayment"
          ),
          getItem("Loan Filter Logic  (Pending)", "/loan-filter-logic"),
          getItem(
            "Device Filter Logic",
            "device-filter-logic",
            <UnorderedListOutlined />,
            [
              getItem("Algo(1)  (Pending)", "/device-filter-algo-1"),
              getItem("Algo(2)  (Pending)", "/device-filter-algo-2"),
              getItem("Algo(3)  (Pending)", "/device-filter-algo-3"),
            ]
          ),
          getItem(
            "Dialer Logic Configuration  (Pending)",
            "/dialer-logic-config"
          ),

          getItem(
            "Approver Bucket Time Logic  (Pending)",
            "approver-bucket-time",
            <UnorderedListOutlined />,
            [
              getItem(
                "Acquisition For Student  (Pending)",
                "/acquisition-for-student"
              ),
              getItem(
                "Acquisition For Salaried  (Pending)",
                "/acquisition-for-salaried"
              ),
              getItem("Credit Check  (Pending)", "/credit-check"),
              getItem("Salary Credit Check  (Pending)", "/salary-credit-check"),
              getItem("Device Change  (Pending)", "/device-change"),
              getItem(
                "Bank Account Verification  (Pending)",
                "/bank-ac-verification"
              ),
              getItem("Loan Filter  (Pending)", "/loan-filter"),
              getItem("Collection  (Pending)", "/collection"),
              getItem("Defect  (Pending)", "/user-defect-identification"),
            ]
          ),

          getItem("Loan Management System  (Pending)", "/loan-management-sys"),
          getItem(
            "Limit Determination Engine  (Pending)",
            "/limit-determination-engine"
          ),
          getItem("Coin Management  (Pending)", "/coin-management"),
          getItem("On Boarding  (Pending)", "/on-boarding"),
          getItem("Loan Application", "/loan-application"),
          getItem("Third Party APIs", "/third-party-apis"),
        ]),
      ]),

      getItem("Master CRUD", "master-crud", <UnorderedListOutlined />, [
        getItem("College", "/college-list", <UnorderedListOutlined />),
        getItem("Course", "/course", <UnorderedListOutlined />),
        getItem("Company", "/company", <UnorderedListOutlined />),
        getItem("Company Branch", "/branch-company", <UnorderedListOutlined />),
        getItem("Specialization", "/specialization", <UnorderedListOutlined />),
        getItem("Department", "/department", <UnorderedListOutlined />),
        getItem("Designation", "/designation", <UnorderedListOutlined />),
        getItem(
          "Earned Reward Coin",
          "/earn-rewards-coins",
          <UnorderedListOutlined />
        ),
        getItem("CMS", "/CMS", <UnorderedListOutlined />),
        getItem(
          "Information To User",
          "/information-to-user",
          <UnorderedListOutlined />
        ),
        getItem(
          "Internal Decision",
          "/internal-decision",
          <UnorderedListOutlined />
        ),
        getItem(
          "Proof Type List",
          "/proof-type-list",
          <UnorderedListOutlined />
        ),
        getItem("Tenure", "/tenure-list", <UnorderedListOutlined />),
        getItem("Fee Disbursal", "/fee-disbursal", <UnorderedListOutlined />),
        getItem("Contact Status", "/contact-status", <UnorderedListOutlined />),
      ]),

      getItem("FAQ Management", "faq-management", <UnorderedListOutlined />, [
        getItem("Dashboard", "/dashboard", <UnorderedListOutlined />),
        getItem("FAQ", "/faq", <UnorderedListOutlined />),
        getItem("FAQ Category", "/faq-category", <UnorderedListOutlined />),
        getItem(
          "FAQ Sub Category",
          "/faq-sub-category",
          <UnorderedListOutlined />
        ),
      ]),
      getItem("Email Us", "/email-us", <UnorderedListOutlined />)
    );
  }

  if (departmentId === 1 || role_code === "superadmin") {
    items.push(
      getItem(
        "Student Onboarding",
        "student-onboarding",
        <UnorderedListOutlined />,
        [
          getItem("History", "/history", <UnorderedListOutlined />),
          getItem("List", "/list", <UnorderedListOutlined />),
        ]
      )
    );
  }

  if (departmentId === 6 || role_code === "superadmin") {
    items.push(
      getItem("Bank Account", "bank-account", <UnorderedListOutlined />, [
        getItem("Bank Account Approval", "/bank-account-approval"),
      ])
    );
  }

  if (departmentId === 5 || role_code === "superadmin") {
    items.push(
      getItem("Device Change", "device-change", <UnorderedListOutlined />, [
        getItem("Device Change Panel", "/device-change-panel"),
      ])
    );
  }

  if (departmentId === 7 || role_code === "superadmin") {
    items.push(
      getItem("Loan Filter", "loan-filter", <UnorderedListOutlined />, [
        getItem("Loan Filter Advisor", "/loan-filter-advisor"),
      ])
    );
  }

  if (departmentId === 2 || role_code === "superadmin") {
    items.push(
      getItem(
        "Salaried Onboarding",
        "salaried-onboarding",
        <UnorderedListOutlined />,
        [
          getItem("History", "/salaried-history", <UnorderedListOutlined />),
          getItem("List", "/salaried-list", <UnorderedListOutlined />),
        ]
      )
    );
  }

  if (departmentId === 4 || role_code === "superadmin") {
    items.push(
      getItem(
        "Student to salaried",
        "Salary-credit-check",
        <UnorderedListOutlined />,
        [
          getItem(
            "History",
            "/student-to-salaried/history",
            <UnorderedListOutlined />
          ),
          getItem(
            "List",
            "/student-to-salaried/list",
            <UnorderedListOutlined />
          ),
        ]
      )
    );
  }

  if (departmentId === 3 || departmentId === 9 || role_code === "superadmin") {
    items.push(
      getItem("Credit Check", "credit-check", <UnorderedListOutlined />, [
        getItem("List", "/credit-check/list", <UnorderedListOutlined />),
        getItem("History", "/credit-check/history", <UnorderedListOutlined />),
      ])
    );
  }

  if (
    departmentId === 11 ||
    departmentId === 10 ||
    role_code === "superadmin"
  ) {
    items.push(
      getItem(
        "Advance Credit Check",
        "advance-credit-check",
        <UnorderedListOutlined />,
        [
          getItem(
            "List",
            "/advance-credit-check/list",
            <UnorderedListOutlined />
          ),
          getItem(
            "History",
            "/advance-credit-check/history",
            <UnorderedListOutlined />
          ),
        ]
      )
    );
  }

  const [btnLoadig, setBtnLoading] = useState(false);

  const logoutUser = () => {
    setBtnLoading(true);
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <Drawer
        title={
          <>
            <img
              src={logo}
              height="100"
              width="100"
              alt="Logo"
              onClick={() => navigate("/")}
            />
          </>
        }
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        key="left"
      >
        <Menu
          mode="inline"
          theme="light"
          items={items}
          onClick={menuClick}
          defaultSelectedKeys={[location?.pathname]}
          defaultOpenKeys={openDefault}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="mt-10"
        >
          <Button
            icon={<LogoutOutlined />}
            onClick={() => logoutUser()}
            className="poppins_medium umoney_btn"
            loading={btnLoadig}
          >
            LogOut
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
