import { format } from "date-fns";
import React, { useState, useEffect, useRef } from "react";
import { Content } from "antd/lib/layout/layout";
import {
  Typography,
  Table,
  Row,
  Col,
  message,
  Switch,
  Input,
  Button,
  Space,
  Modal,
} from "antd";
import CClickLoading from "../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import { getAPIData } from "../../apiHelper";
import Sidebar from "../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  MenuOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import BannerModals from "../../components/Modals/template/EmailBannerModals";
import { setHeader } from "../../api";
import CommonModals from "../../components/Modals/CommonModals";
import Highlighter from "react-highlight-words";

const EmailBanner = () => {
  const max_char = 30;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { Title, Text } = Typography;
  const [tableData, setTableData] = useState([]);
  const [showMore, setShowMore] = useState("");

  //For update
  const [bannerData, setBannerData] = useState([]);

  const scroll = {};

  const [deleteId, setDeleteId] = useState("");

  //Opening Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const openModal = (type, id) => {
    if (type === "add-email-banner") {
      setModalType(type);
      setModalTitle("Add Email Banner");
    } else if (type === "edit-email-banner") {
      loadEditBannerData(id);
      setModalType(type);
      setModalTitle("Edit Email Banner");
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
      setModalOpen(true);
    } else if (type === "show-full-url") {
      setModalType(type);
      setShowMore(id);
      setModalTitle("Show Email Banner");
      setModalOpen(true);
    }
  };

  const loadBannerData = () => {
    const endPoint = "template/banner-list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadEditBannerData = (id) => {
    setLoading(true);
    const endPoint = "template/get-banner/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setBannerData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  //Set the values of Status in list
  const setValues = (banner_id) => {
    let endPoint = `template/banner/change-status`;
    const header = setHeader();
    const data = {
      banner_id,
      type: "change_status",
    };

    getAPIData(endPoint, "post", data, header)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadBannerData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    loadBannerData();
  }, []);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      width: "20%",
      filteredValue: filteredInfo.url || null,
      ...getColumnSearchProps("url"),
      // render: (text, record) => (
      //   <span onClick={() => openModal("show-full-url", record.url)}>
      //     {record.url.length > max_char
      //       ? record.url.substring(0, max_char) + "... Read More"
      //       : record.url}
      //   </span>
      // ),
      render: (text, record) => (
        <div>
          {record.url.length > max_char
            ? record.url.substring(0, max_char)
            : record.url}
          <Text
            style={{
              color: "#000",
              fontWeight: 700,
              cursor: "pointer",
              marginLeft: "0px",
            }}
            onClick={() =>
              openModal("show-full-url", record.url, {
                url: record.media,
              })
            }
          >
            .... Read More
          </Text>
        </div>
      ),
    },
    {
      title: "CREATED ON",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      filteredValue: filteredInfo.createdAt || null,
      render: (text, record) => (
        <span>
          {format(new Date(record?.createdAt), "yyyy/MM/dd kk:mm:ss")}
        </span>
      ),
    },
    {
      title: "UPDATE ON",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "20%",
      filteredValue: filteredInfo.updatedAt || null,
      render: (text, record) => (
        <span>
          {format(new Date(record?.updatedAt), "yyyy/MM/dd kk:mm:ss")}
        </span>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
      key: "status",
      width: "20%",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => (
        <div>
          <span>
            <Switch
              checked={record.status === "1" ? true : false}
              onChange={() => {
                setValues(record?.id);
              }}
            />
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      filteredValue: filteredInfo.actions || null,
      width: "10%",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-email-banner", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          {/* <Layout className="site-layout"> */}
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              backgroundColor: "#009D93",
              borderRadius: "15px 0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Super Admin Dashboard
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: "20px 15px",
              borderRadius: "5px",
            }}
          >
            <div className="white_div">
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={8}></Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="CLEAR FILTERS"
                    className="poppins_medium umoney_btn"
                    onClick={() => clearFilters()}
                    block
                  />
                </Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="ADD NEW BANNER"
                    className="poppins_medium umoney_btn"
                    icon={<PlusOutlined className="btn-icon" />}
                    onClick={() => openModal("add-email-banner")}
                    block
                  />
                </Col>
              </Row>

              <div className="umoney_table mt-20">
                <Table
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                  columns={tableColumns}
                  dataSource={tableData}
                  scroll={scroll}
                  onChange={handleChange}
                  bordered
                />
              </div>
            </div>
          </Content>
          {/* </Layout> */}
        </Layout>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            title="Delete Confirmation"
            type="email_banner"
            setOpen={setModalOpen}
            modalType={modalType}
            id={deleteId}
            setModalType={setModalType}
            onDelete={(val) => {
              loadBannerData(val);
            }}
          />
        ) : modalType === "add-email-banner" ? (
          <BannerModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            onSave={() => {
              setModalType(false);
              loadBannerData();
            }}
          />
        ) : modalType === "edit-email-banner" ? (
          <BannerModals
            modalTitle={modalTitle}
            modalType={modalType}
            bannerData={bannerData}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
              loadBannerData();
            }}
          />
        ) : modalType === "show-full-url" ? (
          <Modal
            open={modalOpen}
            onCancel={handleClose}
            title={modalTitle}
            className="poppins_regular"
            width={1000}
            footer={null}
            closable={true}
            centered={true}
            bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
          >
            {showMore}
          </Modal>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default EmailBanner;
