import React, { useState } from "react";
import { Col, Form, Input, Row, Select, Typography, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { PlusOutlined } from "@ant-design/icons";
import {
  setHeaderForFile,
  student_url,
  setHeader,
  salaried_url,
} from "../../../api";
import axios from "axios";
import _ from "lodash";
import CropperModal from "../../Modals/CropperModal";
import CCloseIcon from "../../InputFields/CCloseIcon";
import { getAPIData } from "../../../apiHelper";

const { Title, Text } = Typography;

const kyc_type = [
  {
    value: "1",
    label: "Voter Card",
  },
  {
    value: "2",
    label: "Passport",
  },
  {
    value: "3",
    label: "Driving License",
  },
  {
    value: "4",
    label: "Adhaar Card",
  },
];

const KYCDetails = (props) => {
  const KYCDetails = props?.KYCDetails;
  const user_id = props?.user_id;
  const [callApiBtnLoading, setCallApiBtnLoading] = useState(false);
  const [kycDetailSaveBtnLoading, setKycDetailSaveBtnLoading] = useState(false);
  const [form] = Form.useForm();

  const pageType = props?.pageType;

  const [frontImage, setFrontImage] = useState([]);
  console.log("frontImage", frontImage);
  const [backImage, setBackImage] = useState([]);
  const [idApproveBtn, setIdApprover] = useState(false);
  const [type, setType] = useState();
  const [callApiResponse, setCallApiResponse] = useState({});

  const [approveBtnLoading, setApproveBtnLoading] = useState(false);

  const [count, setCount] = useState(0);
  console.log("count========>", count);
  const [modalOpen, setModalOpen] = useState(false);

  const [kycType, setKycType] = useState(KYCDetails?.id_type);
  const [kycNumber, setKycNumber] = useState(KYCDetails?.id_number);
  const [srcImg, setSrcImg] = useState();
  const [modalType, setModalType] = useState("");

  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");

  const [frontImageName, setFrontImageName] = useState("");
  const [backImageName, setBackImageName] = useState("");

  console.log("kycNumber", kycNumber);

  const openModal = (type) => {
    if (type === "crop-image") {
      setModalType(type);
      setModalOpen(true);
    }
  };

  // if (!_.isEmpty(srcImg)) {
  //   openModal("crop-image");
  // }

  if (!_.isEmpty(imageDoc) && imageType === "kyc-front") {
    setFrontImage(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  if (!_.isEmpty(imageDoc) && imageType === "kyc-back") {
    setBackImage(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  //need to Changes
  const ApiClick = async () => {
    setCount(count + 1);
    props?.loading(true);
    setCallApiBtnLoading(true);
    const header = setHeader();
    const endpoint =
      pageType === "salaried"
        ? salaried_url + "student/kyc-data"
        : student_url + "student/kyc-data";

    var kyc_type = form.getFieldValue("kyc_type");
    var kyc_numberT = form.getFieldValue("kyc_number");
    var kyc_number = kyc_numberT.replace(/\s/g, "");

    console.log(kyc_number.replace(/\s/g, ""));

    setType(kyc_type);

    const data = {
      user_id: user_id,
      kyc_type: kyc_type,
      kyc_number: kyc_number,
    };

    console.log("data", data);

    const uploadData = await axios.post(endpoint, data, header);
    if (uploadData?.data?.status) {
      var response = uploadData?.data?.data;
      setCallApiResponse(response);
      props.loading(false);
      setCallApiBtnLoading(false);
    } else {
      message.warning(uploadData?.data?.message);
      props.loading(false);
      setCallApiBtnLoading(false);
    }
  };

  const onFinish = async (data) => {
    console.log("data", data);
    props?.loading(true);
    const endpoint = "steps/agent-save-kyc-data";
    let images = [];
    console.log("idApproveBtn", idApproveBtn);
    if (idApproveBtn) {
      setApproveBtnLoading(true);
      data.isApprove = idApproveBtn;
    } else {
      setKycDetailSaveBtnLoading(true);
    }

    let newKycNum = data?.kyc_number;
    data.kyc_number = newKycNum.replace(/\s/g, "");

    data.user_id = user_id;
    if (frontImage[0]) {
      images.push({
        imageBase64: frontImage.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: frontImageName,
        type: "frontImg",
      });
    }
    if (backImage[0]) {
      images.push({
        imageBase64: backImage.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: backImageName,
        type: "backImg",
      });
    }

    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }

    console.log("data", data);

    getAPIData(
      endpoint,
      "post",
      data,
      false,
      pageType === "salaried" ? "salaried" : "student"
    )
      .then((result) => {
        console.log("result", result);
        if (result?.status === true) {
          message.success(result?.message);
          props?.isSuccess(true);
          props?.loading(false);
          setIdApprover(false);
          if (idApproveBtn) {
            setApproveBtnLoading(false);
          } else {
            setKycDetailSaveBtnLoading(false);
          }
        } else {
          message.warning(result?.message);
          props?.loading(false);
          if (idApproveBtn) {
            setApproveBtnLoading(false);
          } else {
            setKycDetailSaveBtnLoading(false);
          }
        }
        if (idApproveBtn) {
          setApproveBtnLoading(false);
        } else {
          setKycDetailSaveBtnLoading(false);
        }
        props?.loading(false);
      })
      .catch((err) => {
        message.error(err);
        props?.loading(false);
        if (idApproveBtn) {
          setApproveBtnLoading(false);
        } else {
          setKycDetailSaveBtnLoading(false);
        }
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  console.log("kyc========>", KYCDetails?.id_type);

  const handleImageFront = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setFrontImageName(event.target.files[0]?.name);
    setImageType("kyc-front");
    openModal("crop-image");
  };
  const handleImageBack = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setBackImageName(event.target.files[0]?.name);
    setImageType("kyc-back");
    openModal("crop-image");
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          initialValues={{
            kyc_type: KYCDetails?.id_type,
            kyc_number: KYCDetails?.id_number,
          }}
          form={form}
        >
          <Col className="gutter-row mt-5" span={24}>
            <Form.Item
              label="KYC Type"
              name="kyc_type"
              size="large"
              rules={[{ required: true, message: "KYC type is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                options={kyc_type}
                size="large"
                placeholder="Select KYC type"
                className="poppins_reguler filter_select_border"
                onChange={(e) => {
                  setKycType(e);
                  if (e !== KYCDetails?.id_type) {
                    form.setFieldValue("kyc_number", "");
                  } else {
                    form.setFieldValue("kyc_number", KYCDetails?.id_number);
                  }
                }}
              />
            </Form.Item>

            <div className="mt-20">
              <Form.Item
                label="KYC Number"
                name="kyc_number"
                size="large"
                rules={[{ required: true, message: "KYC number is required!" }]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter KYC number"
                  onChange={(e) => setKycNumber(e.target.value)}
                />
              </Form.Item>
            </div>
            <div
              className="mt-20"
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "space-between",
              // }}
            >
              <div>
                <Row gutter={[24, 16]}>
                  <Col span={12}>
                    <Form.Item name="front_img">
                      {_.isEmpty(frontImage) ? (
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            backgroundColor: "#dffefc",
                            border: "1px dashed #009d93",
                            borderRadius: "2px",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            title=""
                            className="file-choose"
                            style={{
                              width: "100%",
                              height: "100%",
                              color: "transparent",
                              opacity: 0,
                              position: "relative",
                              zIndex: "200",
                            }}
                            onChange={handleImageFront}
                          />
                          <div
                            style={{
                              position: "relative",
                              bottom: "55%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text>
                              {" "}
                              <PlusOutlined /> Upload KYC Front
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            backgroundColor: "#dffefc",
                            border: "1px dashed #009d93",
                            borderRadius: "2px",
                          }}
                        >
                          <img
                            src={frontImage}
                            style={{ height: "100%", width: "100%" }}
                          ></img>
                          <CCloseIcon
                            onClick={() => setFrontImage("")}
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "0px",
                            }}
                          />
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="back_img">
                      {_.isEmpty(backImage) ? (
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            backgroundColor: "#dffefc",
                            border: "1px dashed #009d93",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            title=""
                            className="file-choose"
                            style={{
                              width: "100%",
                              height: "100%",
                              color: "transparent",
                              opacity: 0,
                              position: "relative",
                              zIndex: "200",
                              cursor: "pointer",
                            }}
                            onChange={handleImageBack}
                          />
                          <div
                            style={{
                              position: "relative",
                              bottom: "55%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Text>
                              {" "}
                              <PlusOutlined /> Upload KYC Back
                            </Text>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            backgroundColor: "#dffefc",
                            border: "1px dashed #009d93",
                            borderRadius: "2px",
                          }}
                        >
                          <img
                            src={backImage}
                            style={{ height: "100%", width: "100%" }}
                          ></img>
                          <CCloseIcon
                            onClick={() => setBackImage("")}
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "0px",
                            }}
                          />
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mt-20">
              <Form.Item>
                <CLoadingButton
                  size="large"
                  value="SAVE"
                  htmlType="submit"
                  loading={kycDetailSaveBtnLoading}
                  onClick={() => setIdApprover(false)}
                  className="poppins_medium umoney_btn"
                  block
                />
              </Form.Item>
            </div>
            <div className="mt-20">
              <Form.Item>
                <CLoadingButton
                  size="large"
                  value="SAVE & APPROVE"
                  className="poppins_medium umoney_btn"
                  onClick={() => setIdApprover(true)}
                  htmlType="submit"
                  loading={approveBtnLoading}
                  block
                  disabled={count > 0 ? false : true}
                  style={{
                    cursor: count < 1 ? "not-allowed" : "pointer",
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Form>
        <Col className="gutter-row" span={8}>
          <div>
            <CLoadingButton
              size="large"
              value="CALL API"
              className="poppins_medium umoney_btn"
              loading={callApiBtnLoading}
              onClick={ApiClick}
              block
              disabled={
                _.isEmpty(kycNumber) || _.isEmpty(kycType) ? true : false
              }
              style={{
                cursor:
                  _.isEmpty(kycNumber) || _.isEmpty(kycType)
                    ? "not-allowed"
                    : "pointer",
              }}
            />
          </div>
          <div className="border-class" style={{ marginTop: "20px" }}>
            <Title level={5} className="poppins_medium umoney_title p-10">
              API RESPONSE
            </Title>
            <Row className="p-10">
              <Col className="gutter-row" span={24}>
                {type === "4" ? (
                  <p>
                    <b>NAME AS AADHAR CARD: </b>{" "}
                    {callApiResponse?.Name_as_per_Aadhar_Card}
                  </p>
                ) : type === "1" ? (
                  <div>
                    <p>
                      <b>NAME AS PER VOTER ID: </b>{" "}
                      {callApiResponse?.Name_as_per_Voter_ID}
                    </p>{" "}
                    <p>
                      {" "}
                      <b>FATHER'S NAME AS PER VOTER ID: </b>{" "}
                      {callApiResponse?.Father_Name_as_per_Voter_ID}
                    </p>{" "}
                  </div>
                ) : type === "2" ? (
                  <p>
                    {" "}
                    <b>NAME AS PER PASSPORT:</b>{" "}
                    {callApiResponse?.Name_as_per_Passport}{" "}
                  </p>
                ) : type === "3" ? (
                  <div>
                    <p>
                      {" "}
                      <b>NAME AS PER DL:</b> {callApiResponse?.Name_as_per_DL}
                    </p>{" "}
                    <p>
                      <b>DOB AS PER DL:</b> {callApiResponse?.DOB_as_per_DL}
                    </p>{" "}
                    <p>
                      <b>FATHER NAME AS PER DL:</b>{" "}
                      {callApiResponse?.Father_Name_as_per_DL}
                    </p>{" "}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          <Col span={24}></Col>
        </Col>
      </Row>
      {modalType === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalType}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default KYCDetails;
