import React from "react";
import Remark1Form from "../../LoanFilterForm/Remark1Form";
import RemarkForm from "../../LoanFilterForm/RemarkForm";

const Remark = ({
  device_id,
  followup_ts,
  user_id,
  action_button,
  closeModal,
  actionButton,
  userId,
  deviceId,
  loadData,
  close,
  loadLoanData,
  pageType
}) => {

  return (
    <>
      {action_button === "2" || actionButton === "3" ? (
        <Remark1Form
          device_id={device_id}
          followup_ts={followup_ts}
          user_id={user_id}
          action_button={action_button}
          actionButton={actionButton}
          close={() => closeModal()}
          userId={userId}
          deviceId={deviceId}
          loadData={loadData}
          closeRemark={close}
          loadLoanData={loadLoanData}
          pageType={pageType}
        />
      ) : (
        <RemarkForm
          device_id={device_id}
          followup_ts={followup_ts}
          user_id={user_id}
          action_button={action_button}
          close={() => closeModal()}
          loadData={loadData}
          closeRemark={close}
          loadLoanData={loadLoanData}
          pageType={pageType}
        />
      )}
    </>
  );
};

export default Remark;
