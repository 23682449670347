import React, { useState, useEffect } from "react";
import { Typography, Table, Row, Col, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import CommonModals from "../../../components/Modals/CommonModals";
import LoanRepaymentByUserModals from "../../../components/Modals/LoanRepaymentByUserModals";

const LoanRepaymentByUser = () => {
  const [debitCardCharges] = Form.useForm();
  const [paytmExtraCharge] = Form.useForm();
  const [pastDaysForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  const [savePaytmBtnLoading, setSavePaytmBtnLoading] = useState(false);
  const [savePastDayBtnLoading, setSavePastDayBtnLoading] = useState(false);

  // Save btn for Debit Card
  const [debitData, setDebitData] = useState([]);

  // Debit Card edit states
  const [isEdit, setIsEdit] = useState(false);
  const [editCancleIcon, setEditCancleIcon] = useState(false);
  const [editId, setEditId] = useState(Number);

  // Modal states
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [editData, setEditData] = useState();

  // Debit Card delete states
  const [deleteId, setDeleteId] = useState(Number);

  const openModal = (type, id, record) => {
    if (type === "add-loan") {
      setModalType(type);
      setModalTitle("Add Loan");
    } else if (type === "edit-loan") {
      setEditData(record);
      console.log("edit", editData);
      setModalType(type);
      setModalTitle("Edit Loan");
    }
  };

  const loadDebitData = () => {
    const endPoint = "system-config/debit-charges/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          setDebitData(result?.data);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    if (_.isEmpty(debitData)) {
      loadDebitData();
    }
  }, [debitData]);

  //PAYTM EXTRA CHARGES
  const onFinishPaytmExtraCharge = (values) => {
    setSavePaytmBtnLoading(true);
    let data;
    let otp_config = [];

    Object.entries(values).map(([key, value]) =>
      otp_config.push({
        category: "loan_repayment_by_user",
        key: key,
        value: value,
      })
    );
    data = { system_data: otp_config };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSavePaytmBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedPaytmExtraCharge = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  //Past Days
  const onFinishPastDays = (values) => {
    setSavePastDayBtnLoading(true);
    let data;
    let otp_config = [];

    Object.entries(values).map(([key, value]) =>
      otp_config.push({
        category: "last_loan_taken",
        key: key,
        value: value,
      })
    );
    data = { system_data: otp_config };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSavePastDayBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedPastDays = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  const tableColumns = [
    {
      title: "LOAN AMOUNT",
      dataIndex: "max_loan_amount",
      key: "max_loan_amount",
      render: (text, record) => (
        <div>
          <span>{record?.min_loan_amount + "-" + record?.max_loan_amount}</span>
        </div>
      ),
    },
    {
      title: "EXTRA CHARGES",
      dataIndex: "extra_charges",
      key: "extra_charges",
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{ color: "#009D93", marginLeft: "20px", fontSize: "25px" }}
              onClick={() => {
                // debitCardEdit(record);
                openModal("edit-loan", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                height: "20px",
                width: "20px",
                marginLeft: "20px",
                fontSize: "25px",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  const loadDaysData = () => {
    const data = { category_name: "last_loan_taken" };
    const endPoint = "system-config/get-config-data";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map(async (data) => {
            pastDaysForm.setFieldsValue({ [data?.name]: data?.value });
          });
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "loan_repayment_by_user" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              paytmExtraCharge.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
    loadDaysData();
  }, [paytmExtraCharge, pastDaysForm]);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  DEBIT CARD EXTRA CHARGES
                </Title>
              </Col>
              <Col className="gutter-row" span={6}>
                <CLoadingButton
                  size="large"
                  value="ADD"
                  icon={<PlusOutlined />}
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-loan")}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={12}>
                <Table
                  dataSource={debitData}
                  className="poppins_regular"
                  columns={tableColumns}
                  rowClassName="device-change-panel-table"
                  size="small"
                  style={{
                    marginTop: "10px",
                    // whiteSpace: "pre",
                    whiteSpace: "pre-wrap",
                    textOverflow: "ellipsis",
                    boxShadow: "0px 0px 6px #00000029",
                    // width: "100%",
                  }}
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col className="gutter-row" span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  PAYTM EXTRA CHARGES
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishPaytmExtraCharge}
                  onFinishFailed={onFailedPaytmExtraCharge}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={paytmExtraCharge}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Extra charge (pending)"
                        name="paytm_extra_charges"
                        rules={[
                          {
                            required: true,
                            message: "Extra charge is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input size="large" placeholder="Enter extra charge" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={savePaytmBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col className="gutter-row" span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  LAST LOAN TAKEN
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishPastDays}
                  onFinishFailed={onFailedPastDays}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={pastDaysForm}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Particular Device Past Days (pending)"
                        name="particular_device_past_days"
                        rules={[
                          {
                            required: true,
                            message:
                              "Particular Device Past Days  is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter particular device past days "
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={savePastDayBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
      {modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          setOpen={setModalOpen}
          title="Delete debit card"
          type="debit-card"
          modalType={modalType}
          setModalType={setModalType}
          id={deleteId}
          onDelete={(val) => {
            loadDebitData();
          }}
        />
      ) : modalType === "add-loan" ? (
        <LoanRepaymentByUserModals
          modalTitle={modalTitle}
          setModalType={setModalType}
          modalType={modalType}
          isEdit={isEdit}
          onSave={() => {
            setModalType(false);
          }}
          loadDebitData={loadDebitData}
        />
      ) : modalType === "edit-loan" ? (
        <LoanRepaymentByUserModals
          editData={editData}
          modalTitle={modalTitle}
          modalType={modalType}
          isEdit={isEdit}
          setModalType={setModalType}
          onSave={() => {
            setModalType(false);
          }}
          setEditCancleIcon={setEditCancleIcon}
          setIsEdit={setIsEdit}
          editCancleIcon={editCancleIcon}
          loadDebitData={loadDebitData}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LoanRepaymentByUser;
