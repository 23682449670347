import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import CLoadingButton from "../InputFields/CLoadingButton";
const TenureModals = ({
  modalType,
  modalTitle,
  setModalType,
  loadTenureData,
  editData,
}) => {
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [form] = Form.useForm();

  const handleClose = () => {
    setModalType("");
  };

  console.log(editData);

  const submitData = (data) => {
    console.log("fetch");
    const endPoint =
      modalType === "edit-tenure"
        ? "master/tenure/update"
        : "master/tenure/create";
    setSaveBtnLoading(true);
    getAPIData(
      endPoint,
      "post",
      modalType === "edit-tenure" ? { ...data, tenure_id: editData?.id } : data,
      false,
      "admin"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSaveBtnLoading(false);
          handleClose();
          form.resetFields();
          loadTenureData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const onFinishFailed = () => {
    console.log("failed");
  };

  useEffect(() => {
    if (modalType === "add-tenure") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }
  }, [form, editData, modalType]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={submitData}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Days"
                name="days"
                rules={[
                  {
                    required: true,
                    message: "Days is required!",
                  },
                  {
                    pattern: /^[0-9]\d*(\.\d+)?$/g,
                    message: "Days contains only number.",
                  },
                  () => ({
                    validator(rule, value) {
                      if (value > 365) {
                        return Promise.reject("Value must be less than 365");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the days" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value=" SAVE"
                  loading={saveBtnLoading}
                  className="poppins_medium umoney_btn"
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default TenureModals;
