import React, { useState, useEffect } from "react";
import { Typography, Table, Col, Row, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import MobileAppModals from "../../../components/Modals/MobileAppModals";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import CommonModals from "../../../components/Modals/CommonModals";

const LoanExtension = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  //Save btn
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //open Modal
  const [modalType, setModalType] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  //load data
  const [intrestData, setIntrestData] = useState([]);
  const [editdata, setEditdata] = useState([]);

  const [deleteId, setDeleteId] = useState(Number);

  const onFinish = (values) => {
    setSaveBtnLoading(true);
    let data;
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "loan_extension",
        key: key,
        value: value,
      })
    );
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          //var response = result?.data;
          message.success(result?.message);
          setSaveBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadInterestData = () => {
    const endPoint = "system-config/ext-interest-rate/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          setIntrestData(result?.data);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadEditInterestData = (id) => {
    const endPoint = "system-config/ext-interest-rate/get/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setEditdata(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "loan_extension" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              form.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };
    loadData();
    if (_.isEmpty(intrestData)) {
      loadInterestData();
    }
  }, [form, intrestData]);

  const openModal = (type, id) => {
    if (type === "add-ext-interest") {
      setModalType(type);
      setModalWidth(800);
      setModalOpen(true);
    } else if (type === "edit-ext-interest") {
      loadEditInterestData(id);
      setModalType(type);
      setModalWidth(800);
      setModalOpen(true);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalWidth(800);
      setModalOpen(true);
    }
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "DAYS",
      dataIndex: "min_days",
      key: "min_days",
      render: (text, record) => (
        <div>
          <span>{record?.min_days + "-" + record?.max_days}</span>
        </div>
      ),
    },
    {
      title: "INTEREST RATE",
      dataIndex: "interest_rate",
      key: "interest_rate",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{ color: "#009D93", marginLeft: "20px", fontSize: "25px" }}
              onClick={() => {
                openModal("edit-ext-interest", record?.id);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                height: "20px",
                width: "20px",
                marginLeft: "20px",
                fontSize: "25px",
              }}
              onClick={() => {
                openModal("delete", record?.id);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  LOAN EXTENSION ELIGIBILITY
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={form}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Loan extension eligibility days (pending)"
                        name="loan_extension_eligibility_days"
                        rules={[
                          {
                            required: true,
                            message:
                              "Loan extension eligibility days is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter loan extension eligibility days"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <div style={{ width: "50%", marginTop: "20px" }}>
              <Row gutter={[16, 16]}>
                <Col span={20}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    LOAN EXTENSION INTEREST RATE
                  </Title>
                </Col>
                <Col span={4}>
                  <CLoadingButton
                    size="large"
                    value="ADD"
                    className="poppins_medium umoney_btn"
                    onClick={() => openModal("add-ext-interest")}
                    block
                  />
                </Col>
              </Row>
              <div className="umoney_table mt-20">
                <Table
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                  columns={tableColumns}
                  dataSource={intrestData}
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
      {modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          setOpen={setModalOpen}
          title="Delete Extension Interest Rate"
          type="ext-interest-rate"
          modalType={modalType}
          setModalType={setModalType}
          id={deleteId}
          onDelete={(val) => {
            loadInterestData();
          }}
        />
      ) : modalType === "add-ext-interest" ||
        modalType === "edit-ext-interest" ? (
        <MobileAppModals
          open={modalOpen}
          setOpen={setModalOpen}
          onSave={() => {
            setModalOpen(false);
            loadInterestData();
          }}
          editData={modalType === "edit-ext-interest" ? editdata : []}
          title={
            modalType === "add-ext-interest"
              ? "Add new loan extension interest rate"
              : "Edit new loan extension interest rate"
          }
          modalType={modalType}
          modalWidth={modalWidth}
        />
      ) : (
        " "
      )}
    </div>
  );
};

export default LoanExtension;
