import { Checkbox, Col, DatePicker, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import CLoadingButton from "../InputFields/CLoadingButton";

const SalaryCreditBank = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [salaryCreditSaveBtnLoading, setSalaryCreditSaveBtnLoading] =
    useState(false);

  const salaryCreditSaveClick = () => {
    setLoading(true);
    setSalaryCreditSaveBtnLoading(true);
  };

  const loadData = () => {
    setTimeout(() => {
      setLoading(false);
      setSalaryCreditSaveBtnLoading(false);
    }, 5000);
  };

  useEffect(() => {
    loadData();
  });

  return (
    <div>
      {" "}
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              label="Last Salary Date"
              name="Last Salary Date"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "last salary date and amount required",
                },
              ]}
            >
              <DatePicker size="large" style={{ width: "100%" }} placeholder="Select last salary date"></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item className="poppins_medium">
              <Checkbox>Has the Salary changed?</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Monthly Take Home Salary"
              name="Monthly Take Home Salary"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Monthly Take Home Salary required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter montly take home salary"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Salary Amount"
              name="Salary Amount"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Salary Amount required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter salary amount"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Annual CTC"
              name="Annual CTC"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Annual CTC required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter annual CTC"></Input>
            </Form.Item>
          </Col>

          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item className="poppins_medium">
              <CLoadingButton
                size="large"
                value="SAVE"
                htmlType="submit"
                className="poppins_medium umoney_btn"
                loading={salaryCreditSaveBtnLoading}
                onClick={() => salaryCreditSaveClick()}
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SalaryCreditBank;
