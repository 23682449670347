import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import { CloseCircleOutlined } from "@ant-design/icons";
import CLoadingButton from "../InputFields/CLoadingButton";
const CreditCheckSubmissionModals = ({
  modalType,
  modalTitle,
  setModalType,
  editData,
  loadData,
  relativeEditData,
}) => {
  const [Incomeform] = Form.useForm();
  const [Relationform] = Form.useForm();

  const [saveIncomeBtnLoading, setSaveIncomeBtnLoading] = useState(false);
  const [saveRelativeBtnLoading, setSaveRelativeBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType(false);
  };

  //Family Income
  const onFinishFamilyIncome = (values) => {
    setSaveIncomeBtnLoading(true);
    OnSubmit("income", values);
  };
  const onFailedFamilyIncome = (errorInfo) => {
    console.log("----errorInfo-----", errorInfo);
  };

  //Relative
  const onFinishRelativeSubmit = (values) => {
    OnSubmit("", values);
  };
  const onFailedRelativeSubmit = (errorInfo) => {
    console.log("----errorInfo-----", errorInfo);
  };

  //Submit
  const OnSubmit = (type = null, values) => {
    let data = "";
    if (type === "income") {
      data = {
        type: type,
        name: values?.title,
      };
    } else {
      data = {
        name: values?.relation,
        allowed_in_cc: values?.allowed_in_cc,
      };
    }

    const endPoint =
      modalType === "add-income" || modalType === "add-relative-income"
        ? "system-config/family/create"
        : modalType === "edit-income"
        ? "system-config/family/update/" + editData?.id
        : "system-config/family/update/" + relativeEditData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          type === "income"
            ? setSaveIncomeBtnLoading(false)
            : setSaveRelativeBtnLoading(false);

          message.success(result?.message);
          type === "income"
            ? Incomeform.resetFields()
            : Relationform.resetFields();
          handleClose();
          loadData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    if (modalType === "add-income") {
      Incomeform.resetFields();
    } else if (modalType === "edit-income") {
      Incomeform.setFieldsValue(editData);
    } else if (modalType === "add-relative-income") {
      Relationform.resetFields();
    } else if (modalType === "edit-relative-income") {
      Relationform.setFieldsValue(relativeEditData);
    }
  }, [Incomeform, Relationform, editData, modalType, relativeEditData]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        {modalType === "add-income" || modalType === "edit-income" ? (
          <Form
            name="basic"
            onFinish={onFinishFamilyIncome}
            onFinishFailed={onFailedFamilyIncome}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={Incomeform}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Title  is required!",
                    },
                    {
                      message: "Please Enter Only Characters",
                      pattern: new RegExp(/[a-zA-Z]/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter title " />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="SAVE"
                  className="poppins_medium umoney_btn"
                  loading={saveIncomeBtnLoading}
                  block
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <Form
            name="basic"
            onFinish={onFinishRelativeSubmit}
            onFinishFailed={onFailedRelativeSubmit}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={Relationform}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Relation"
                  name="relation"
                  rules={[
                    {
                      required: true,
                      message: "Title  is required!",
                    },
                    {
                      message: "Please Enter Only Characters",
                      pattern: new RegExp(/[a-zA-Z]/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter title " />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Allowed in CC"
                  name="allowed_in_cc"
                  rules={[
                    {
                      required: true,
                      message: "Title  is required!",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    size="large"
                    options={[
                      {
                        value: "1",
                        label: "Yes",
                      },
                      {
                        value: "0",
                        label: "No",
                      },
                    ]}
                    placeholder="Select allowed in CC"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="SAVE"
                  className="poppins_medium umoney_btn"
                  loading={saveRelativeBtnLoading}
                  block
                />
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default CreditCheckSubmissionModals;
