import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Input, message, Select } from "antd";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

export default function AddNewFAQ(props) {
  const {
    onSave = () => {},
    faqData,
    roleData,
    modalType,
    modalTitle,
    setModalType,
  } = props;

  const [form] = Form.useForm();
  const [savebtnLoading, setSaveBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType(false);
  };

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    var endPoint =
      modalType === "ADD" ? "faq/add-faq" : "faq/update-faq/" + faqData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          onSave();
          setSaveBtnLoading(false);
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const faqType = [
    {
      value: "1",
      label: "Support",
    },
    {
      value: "2",
      label: "Loan",
    },
    {
      value: "3",
      label: "Profile",
    },
  ];

  useEffect(() => {
    if (modalType === "ADD") {
      form.resetFields();
    } else {
      form.setFieldsValue(faqData);
    }
  }, [form, faqData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={1000}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="white_div boxshadow-div">
        <Form
          name="basic"
          onFinish={(data) => {
            onFinish(data);
          }}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="FAQ question"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please enter the faq question!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter FAQ question" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="FAQ Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please enter the faq type!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select size="large" options={faqType} placeholder="Select fAQ type" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Role"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "Please enter the role!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select size="large" options={roleData} placeholder="Select role" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value=" SAVE"
                  className="poppins_medium umoney_modal_filled_btn"
                  loading={savebtnLoading}
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
