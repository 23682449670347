import { Col, message, Modal, Row, Typography } from "antd";
import React, { useState } from "react";
import { getAPIData } from "../../../apiHelper";
import CLoadingButton from "../../InputFields/CLoadingButton";
import Remark from "./Remark";

const ConfirmationModalLoan = ({
  device_id,
  followup_ts,
  user_id,
  action_button,
  onClose,
  deviceId,
  userId,
  actionButton,
  handleCloseModal,
  loadLoanData,
  pageType
}) => {
  const { Title } = Typography;
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  console.log("actionButton", actionButton);

  const close = () => {
    setModalType("");
  };

  const openModal = (type) => {
    if (type === "remark") {
      setModalType(type);
      setModalTitle("Remark");
      setModalWidth(500);
    }
  };

  const followUp = () => {
    openModal("remark");
    onClose();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title
            level={5}
            className="poppins_medium"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Are you sure ?
          </Title>
        </Col>
        <Col span={12}>
          <CLoadingButton
            size="large"
            value="NO"
            onClick={() => onClose()}
            // loading={noBtnLoading}
            className="poppins_medium umoney_outlined_btn"
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <CLoadingButton
            size="large"
            value="YES"
            onClick={() => {
              openModal("remark");
              onClose();
            }}
            // loading={yesBtnLoading}
            className="poppins_medium umoney_modal_filled_btn"
            style={{ width: "100%", marginTop: "0px" }}
          />
        </Col>
      </Row>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={close}
        centered
        width={modalWidth}
        footer={false}
      >
        {modalType === "remark" ? (
          <Remark
            device_id={device_id}
            followup_ts={followup_ts}
            user_id={user_id}
            action_button={action_button}
            closeModal={() => setModalType("")}
            actionButton={actionButton}
            userId={userId}
            deviceId={deviceId}
            close={close}
            loadLoanData={loadLoanData}
            pageType={pageType}
          />
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default ConfirmationModalLoan;
