import { Col, Modal, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import Title from "antd/lib/skeleton/Title";
import { getAPIData } from "../../../apiHelper";

const CompanyBranchModel = (props) => {
  const {
    onSave = () => {},
    companyData,
    companyList,
    branchData,
    modalType,
    modalTitle,
    setModalType,
    pincodeList,
  } = props;

  const [form] = Form.useForm();
  const [savebtnLoading, setSaveBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType(false);
  };
  const onSubmitData = (data) => {
    setSaveBtnLoading(true);
    var endPoint =
      modalType === "add-company-branch"
        ? "company/branch-create"
        : "company/branch-update/" + branchData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo===>>", errorInfo);
  };

  const getPincodeValue = async (e) => {
    console.log("e===>>", e);
    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("state", abcd?.state);
    form.setFieldValue("district", abcd?.district);
  };

  useEffect(() => {
    if (modalType === "add-company") {
      form.resetFields();
    } else if (modalType === "edit-company-branch") {
      form.setFieldsValue(branchData);
    } else {
      form.setFieldsValue(companyData);
    }
  }, [form, companyData, branchData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={1000}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="white_div boxshadow-div">
        <Form
          name="basic"
          onFinish={(data) => {
            onSubmitData(data);
          }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={16}>
              <Form.Item
                label="Name of branch"
                name="branch_name"
                rules={[
                  {
                    required: true,
                    message: "Name of branch is required!",
                  },
                  {
                    message: "Please Enter Only Characters",
                    pattern: new RegExp(/[a-zA-Z]/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter name of branch" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                label="Company"
                name="company_id"
                rules={[
                  {
                    required: true,
                    message: "Company is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select className="br" size="large" options={companyList} placeholder="Select company" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Title level={5} style={{ fontSize: "18px" }}></Title>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Address is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter address" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Landmark"
                name="landmark"
                rules={[
                  {
                    required: true,
                    message: "Landmark is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter landmark" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Title level={5} style={{ fontSize: "18px" }}></Title>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pincode"
                name="pincode_id"
                rules={[
                  {
                    required: true,
                    message: "Pincode is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  showSearch
                  className="br"
                  size="large"
                  options={pincodeList}
                  onChange={getPincodeValue}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .localeCompare((optionB?.label ?? "").toString())
                  }
                  placeholder="Select pincode"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "State is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter state" disabled={true} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="District"
                name="district"
                rules={[
                  {
                    required: true,
                    message: "District is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter district"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Google map link"
                name="google_map_link"
                rules={[
                  {
                    required: true,
                    message: "Google map link is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter google map link" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}></Col>

            <Col className="gutter-row" span={6}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value=" SAVE"
                className="poppins_medium umoney_modal_filled_btn"
                loading={savebtnLoading}
                block
              />
            </Col>
            <Col className="gutter-row" span={18}></Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

async function findArrayElementByTitle(array, value) {
  return array.find((element) => {
    return element.value === value;
  });
}

export default CompanyBranchModel;
