/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Switch,
  Input,
  Space,
  Button,
  Modal,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import AddCategoryForm from "../../components/Modals/faq/AddCategoryForm";
import Highlighter from "react-highlight-words";
import CCloseIcon from "../../components/InputFields/CCloseIcon";

const { Title, Text } = Typography;
const { Header, Content } = Layout;

const FaqCategory = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let [tableData, setTableData] = useState([]);
  const [showMore, setShowMore] = useState("");
  const [readMore, setReadMore] = useState(false);

  const [editID, setEditID] = useState(0);
  const [deleteId, setDeleteId] = useState("");
  const scroll = {};

  const [faqData, setFaqData] = useState([]);
  const [url, setUrl] = useState("");

  //Opening Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  //Search Table
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const [faqId, setFaqId] = useState();
  const [mediaUrl, setMediaUrl] = useState();

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const loadData = () => {
    setLoading(true);
    const endPoint = "faq/category-list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        setLoading(false);
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
          setLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const openModal = (type, id, data) => {
    if (type === "add-category") {
      loadFaqData();
      setModalType(type);
      setModalTitle("Add Category");
    } else if (type === "edit-category") {
      loadFaqData();
      setEditID(id);
      setModalType(type);
      setModalTitle("Edit Category");
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
      setModalOpen(true);
    } else if (type === "show-full-html") {
      setModalType(type);
      setShowMore(id);
      setUrl(data.url);
      setModalTitle("Show Description");
    } else if (type === "remove-video") {
      setModalType(type);
      setModalTitle("Remove video");
    }
  };

  const handleClose = () => {
    setModalType(false);
  };

  const loadFaqData = () => {
    const endPoint = "faq/faq-list/category";

    let name = [];
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          result?.data.map((e) => {
            name.push({ value: e?.id, label: e.title, text: e.title });
          });
          setFaqData(name);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  // Search Functionality START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // Search Functionality END

  useEffect(() => {
    loadData();
    loadFaqData();
  }, []);

  //Set the values of Status in list
  const setValues = (e, id) => {
    let endPoint = "faq/change-category-status";
    const data = {
      status: e === true ? "1" : "0",
      category_id: id,
    };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const TableColumns = [
    {
      title: "CATEGORY ID",
      dataIndex: "id",
      key: "id",
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "FAQ QUESTION ",
      dataIndex: "master_faq_title",
      key: "master_faq_title",
      filteredValue: filteredInfo.master_faq_title || null,
      filters: faqData,
      filterSearch: true,
      ...getColumnSearchProps("master_faq_title"),
    },
    {
      title: "CATEGORY",
      dataIndex: "title",
      key: "title",
      filteredValue: filteredInfo.title || null,
      width: "25%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "SUB CATEGORY",
      dataIndex: "sub",
      key: "has_sub",
      filteredValue: filteredInfo.has_sub || null,
      width: "10%",
      render: (text, record) => {
        // 0=Inactive, 1=Active, 2=Pending, -1=Deleted
        let has_sub = record?.has_sub === "1" ? "Yes" : "No";
        return <div>{has_sub}</div>;
      },
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      filteredValue: filteredInfo.description || null,
      width: "12%",
      ...getColumnSearchProps("description"),
      render: (_noUse, record) => (
        <div>
          <div>
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html: record.description.slice(0, 100),
              }}
            />
            <Text
              style={{
                color: "#000",
                fontWeight: 700,
                cursor: "pointer",
                marginLeft: "3px",
                // display:"inline-block"
              }}
              onClick={() => {
                openModal("show-full-html", record.description, {
                  url: record.media,
                });
                setFaqId(record?.id);
                setMediaUrl(record?.media);
              }}
            >
              {record?.description.length === 0 ? "" : "...Read More"}
            </Text>
          </div>

          {/* {readMore
            ? `${record.description}`
            : `${record?.description?.substring(0, 30)}${
                record?.description?.length > 30 ? "...     " : "...     "
              }`}

          <Text
            style={{ color: "#000", fontWeight: 700, cursor: "pointer", marginLeft:'3px' }}
            onClick={() =>
              openModal("show-full-html", record.description, {
                url: record.media,
              })
            }
          >
            {readMore ? "Read Less" : "Read More"}
          </Text> */}
        </div>
      ),
    },

    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
      width: "5%",
      align: "center",
      render: (text, record) => {
        // 0=Inactive, 1=Active, 2=Pending, -1=Deleted
        return (
          <div>
            <span>
              <Switch
                checked={record.status === "1" ? true : false}
                onChange={(e) => {
                  setValues(e, record?.id);
                }}
              />
            </span>
          </div>
        );
      },
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      width: "10%",
      align: "center",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-category", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  const removeVideo = () => {
    const dataRemoveImage = {
      url: mediaUrl,
      imageType: "image_one",
      type: "faq",
      faq_id: faqId,
    };
    const endPoint = "faq/remove-file";
    getAPIData(endPoint, "post", dataRemoveImage, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          console.log("fext");
          message.success(result?.message);
          handleClose();
          loadData();
          loadFaqData();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          // height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            FAQ CATEGORY
          </Title>
        </Header>
        <Content
          className="site-layout-background white_div"
          style={{
            padding: "20px 15px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={8}></Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="ADD"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => openModal("add-category")}
                icon={<PlusOutlined />}
                block
              />
            </Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="CLEAR FILTERS"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => clearFilters()}
                block
              />
            </Col>
          </Row>

          <div className="umoney_table " style={{ marginTop: 20 }}>
            <Table
              pagination={{
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
              columns={TableColumns}
              dataSource={tableData}
              scroll={scroll}
              onChange={handleChange}
            />
          </div>
          {modalType === "delete" ? (
            <CommonModals
              open={modalOpen}
              title="Delete Confirmation"
              type="faq-Category"
              setOpen={setModalOpen}
              modalType={modalType}
              id={deleteId}
              setModalType={setModalType}
              onDelete={(val) => {
                loadData();
              }}
            />
          ) : modalType === "add-category" ? (
            <AddCategoryForm
              faqData={faqData}
              modalTitle={modalTitle}
              setModalType={setModalType}
              modalType={modalType}
              onSave={() => {
                setModalType(false);
                loadData();
              }}
            />
          ) : modalType === "edit-category" ? (
            <AddCategoryForm
              faqData={faqData}
              categoryId={editID}
              modalTitle={modalTitle}
              modalType={modalType}
              setModalType={setModalType}
              onSave={() => {
                setModalType(false);
                loadData();
              }}
            />
          ) : modalType === "show-full-html" ? (
            <Modal
              open={modalType}
              onCancel={handleClose}
              title={modalTitle}
              className="poppins_regular"
              width={1000}
              footer={null}
              closable={true}
              centered={true}
              bodyStyle={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 200px)",
              }}
            >
              <div>
                <div dangerouslySetInnerHTML={{ __html: showMore }} />
                <p></p>
                {url === null ? (
                  ""
                ) : (
                  <div style={{ position: "relative", display:'inline-block' }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "320px",
                        zIndex: "100",
                      }}
                      onClick={() => removeVideo()}
                    >
                      <CCloseIcon />
                    </div>
                    <video
                      src={url}
                      type="video/mp4"
                      width="320"
                      height="240"
                      controls
                    ></video>
                  </div>
                )}
              </div>
            </Modal>
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default FaqCategory;
