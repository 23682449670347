const actions = {
  SET_ACCESSTOKEN: "auth/SET_ACCESSTOKEN",

  setAccessToken: (accessToken) => (dispatch) =>
    dispatch({
      type: actions.SET_ACCESSTOKEN,
      accessToken,
    }),
};
export default actions;
