import { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  message,
  Button,
  Upload,
  Select,
} from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import { UploadOutlined } from "@ant-design/icons";
import { setHeaderForFile } from "../../api";
import axios from "axios";
import { api_url } from "../../api";
import _ from "lodash";
import { getAPIData } from "../../apiHelper";

const EarnRewardsCoinsModal = (props) => {
  const {
    title,
    open,
    setOpen,
    coinId,
    modalType,
    setModalType,
    onSave = () => {},
  } = props;
  const [form] = Form.useForm();
  const [coinData, setCoinData] = useState([]);
  const [coinImage, setCoinImage] = useState({});

  const handleClose = () => {
    setModalType(false);
  };

  const pageList = [
    {
      value: "Login page",
      label: "Login Page",
    },
    {
      value: "Dashboard",
      label: "Dashboard",
    },
  ];

  if (modalType === "edit-coins" && _.isEmpty(coinData)) {
    const endPoint = "system-config/earn_reward_coin/get/" + coinId;
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data[0];
          setCoinData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }
  const onFinish = async (data) => {
    var type = modalType === "edit-coins" ? "update/" + coinData?.id : "create";
    const endpoint = api_url + "system-config/earn_reward_coin/" + type;
    const header = setHeaderForFile();
    const apiData = {
      title: data?.title,
      icon: coinImage || {},
      description: data?.description,
      redirection_page: data?.redirection_page,
      serial_number: data?.serial_number,
    };

    const uploadData = await axios.post(endpoint, apiData, header);
    if (uploadData?.data?.status) {
      setOpen(false);
      onSave();
      form.resetFields();
      message.success(uploadData?.data?.message);
    } else {
      message.warning(uploadData?.data?.message);
    }
  };

  useEffect(() => {
    if (modalType === "add-coins") {
      form.setFieldsValue();
    } else {
      form.setFieldsValue(coinData);
    }
  }, [form, modalType, coinData]);

  const onFinishFailed = (errorInfo) => {};

  const checkImage = async (file) => {
    const isJpgOrPng =
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg";
    let status = true;
    let message = "";
    if (!isJpgOrPng) {
      message = "You can only upload JPG/PNG/JPEG file!";
      status = false;
    }

    return { message, status };
  };

  const beforeUpload = async (file, type) => {
    setCoinImage(file);
    const res = checkImage(file);

    if (!res.status) {
      Notification("error", res.message);
    }
    return false;
  };

  return (
    <Modal
      title={title}
      centered={true}
      open={open}
      footer={null}
      closable={true}
      className="poppins_regular"
      onCancel={handleClose}
      width={900}
    >
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={8}>
            <Form.Item
              name="upload"
              size="large"
              className="poppins_medium mb-35"
            >
              {modalType === "edit-coins" ? (
                <div>
                  <Upload
                    maxCount="1"
                    beforeUpload={beforeUpload}
                    accept="image/*"
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{
                        width: 270,
                        height: 41,
                        border: "1px solid #009d93",
                        borderRadius: "3px",
                      }}
                    >
                      Upload
                    </Button>
                  </Upload>
                  <img
                    src={coinData.url}
                    alt="icon"
                    className="kyc-images"
                    style={{ marginTop: 5 }}
                  />
                </div>
              ) : (
                <Upload
                  maxCount="1"
                  beforeUpload={beforeUpload}
                  accept="image/*"
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{
                      width: 270,
                      height: 41,
                      border: "1px solid #009d93",
                      borderRadius: "3px",
                    }}
                  >
                    Upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true, message: "Title is required!" },
                {
                  whitespace: true,
                  message: "This field can not contain whitespaces.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="Enter title" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Description is required!" },
                {
                  whitespace: true,
                  message: "This field can not contain whitespaces.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="Enter description" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Redirection Page"
              name="redirection_page"
              rules={[
                { required: true, message: "Redirection Page is required!" },
              ]}
              className="poppins_medium mb-35"
            >
              <Select
                size="large"
                placeholder="Enter redirection page"
                options={pageList}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Serial Number"
              name="serial_number"
              type="number"
              rules={[
                { required: true, message: "Serial Number is required!" },
                {
                  message: "Value should be number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="Enter serial number" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="Save"
                className="poppins_medium umoney_btn"
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EarnRewardsCoinsModal;
