import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import CLoadingButton from "../InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { salaried_url, setHeaderForFile } from "../../api";
import axios from "axios";

const CompanyInformation = (props) => {
  const empData = props?.comapnyData;
  console.log("empData", empData);
  const user_id = props?.user_id;

  const role = "Approver";
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const today = moment;
  console.log("today", today);

  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [idApprover, setIdApprover] = useState(false);

  const [companyList, setCompanyList] = useState();
  const [companyData, setCompanyData] = useState();

  const [departmentList, setDepartmentList] = useState();
  const [designationList, setDesignationList] = useState();
  const [proofList, setProofList] = useState();

  const [pincodeList, setPincodeList] = useState();

  const [form] = Form.useForm();

  const [employmentDetailsBtnLoading, setEmploymentDetailsBtnLoading] =
    useState(false);
  const [employmentMergeBtnLoading, setEmploymentMergeBtnLoading] =
    useState(false);

  const employmentDetailsClick = () => {
    setEmploymentDetailsBtnLoading(true);
    setLoading(true);
  };

  const employmentMergeClick = () => {
    setEmploymentMergeBtnLoading(true);
    setLoading(true);
  };

  useEffect(() => {
    getCompanyList();
    getDepartmentList();
    getDesignationList();
    getProofList();
    loadPincodes();
  }, []);

  const [document1, setDocument1] = useState([]);
  const [document2, setDocument2] = useState([]);
  const [document3, setDocument3] = useState([]);
  const [document4, setDocument4] = useState([]);
  const [document5, setDocument5] = useState([]);

  useLayoutEffect(() => {
    getPincodeValue(0, empData?.company?.pincode_id);
  }, []);

  // const onFinish = async (data) => {
  //   if (idApprover) {
  //     props?.loading(true);
  //     setApproveBtnLoading(true);
  //   } else {
  //     props?.loading(true);
  //     // setSaveBtnLoading(true);
  //   }
  //   const endPoint = salaried_url + "steps/agent-save-employee-data";
  //   getAPIData(endPoint, "post", data, false,"salaried" )
  //   .then((res) => {
  //     console.log("res", res);

  //   })
  //   .catch((err) => {
  //     message.error(err)
  //   })
  // const form = new FormData();
  // form.append("company_id", data?.company_id);
  // form.append("address", data?.address);
  // form.append("pincode_id", data?.pincode_id);
  // form.append("monthly_salary", data?.monthly_salary);
  // form.append("ctc", data?.ctc);
  // form.append("department_id", data?.department_id);
  // form.append("designation_id", data?.designation_id);
  // form.append("date_of_join", data?.date_of_join);
  // form.append("acc_number", data?.acc_number);
  // form.append("acc_name", data?.acc_name);
  // form.append("ifsc", data?.ifsc);
  // form.append("salary_type", data?.salary_type);
  // form.append("proof_type_id", data?.proof_type_id);
  // form.append("user_id", user_id);
  // form.append("type", "admin");
  // form.append("idApprover", idApprover);

  // const header = setHeaderForFile();
  // const endPoint = salaried_url + "steps/agent-save-employee-data";
  // console.log("form==========>", form);
  // const uploadData = await axios.post(endPoint, form, header);
  // console.log("uploadData", uploadData);
  // if (uploadData?.data?.status) {
  //   props?.isSuccess(true);
  //   if (idApprover) {
  //     props?.loading(false);
  //     setApproveBtnLoading(false);
  //   } else {
  //     props?.loading(false);
  //     // setSaveBtnLoading(false);
  //   }
  //   message.success(uploadData?.data?.message);
  // } else {
  //   message.warning(uploadData?.data?.message);
  //   if (idApprover) {
  //     props?.loading(false);
  //     setApproveBtnLoading(false);
  //   } else {
  //     props?.loading(false);
  //     setSaveBtnLoading(false);
  //   }
  // }
  // };

  const getPincodeValue = async (e) => {
    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("state", abcd?.state);
    form.setFieldValue("district", abcd?.district);
  };

  const onFinish = async (data) => {
    if (idApprover) {
      props?.loading(true);
      setApproveBtnLoading(true);
    } else {
      props?.loading(true);
      setSaveBtnLoading(true);
    }
    const form = new FormData();
    form.append("company_id", data?.company_id);
    form.append("address", data?.address);
    form.append("pincode_id", data?.pincode_id);
    form.append("monthly_salary", data?.monthly_salary);
    form.append("ctc", data?.ctc);
    form.append("department_id", data?.department_id);
    form.append("designation_id", data?.designation_id);
    form.append("date_of_join", data?.date_of_join);
    form.append("proof_type_id", data?.proof_type_id);
    form.append("user_id", user_id);
    form.append("type", "admin");
    if (idApprover) {
      form.append("idApprover", idApprover);
    }
    if (document1?.[0]) {
      form.append("imageOne", document1?.[0]?.originFileObj);
    }
    if (document2?.[0]) {
      form.append("imageTwo", document2?.[0]?.originFileObj);
    }
    if (document3?.[0]) {
      form.append("imageThree", document3?.[0]?.originFileObj);
    }
    if (document4?.[0]) {
      form.append("imageFour", document4?.[0]?.originFileObj);
    }
    if (document5?.[0]) {
      form.append("imageFive", document5?.[0]?.originFileObj);
    }

    const header = setHeaderForFile();
    const endPoint = salaried_url + "steps/agent-save-employee-data";
    console.log("form==========>", form);
    const uploadData = await axios.post(endPoint, form, header);
    console.log("uploadData", uploadData);
    if (uploadData?.data?.status) {
      props?.isSuccess(true);
      if (idApprover) {
        props?.loading(false);
        setApproveBtnLoading(false);
      } else {
        props?.loading(false);
        setSaveBtnLoading(false);
      }
      message.success(uploadData?.data?.message);
    } else {
      // props?.loading(false);
      // setSaveBtnLoading(false);
      message.warning(uploadData?.data?.message);
      if (idApprover) {
        props?.loading(false);
        setApproveBtnLoading(false);
      } else {
        props?.loading(false);
        setSaveBtnLoading(false);
      }
    }
  };

  const getCompanyList = () => {
    const endPoint = `company/find-list`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.company_name,
            });
          });
          setCompanyList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getCompanyData = (value) => {
    const endPoint = `company/get/${value}`;
    getAPIData(endPoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCompanyData(response);
          form.setFieldValue("pincode_id", response?.pincode_id);
          form.setFieldValue("district", response?.district);
          form.setFieldValue("state", response?.state);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getDepartmentList = () => {
    const endPoint = `employee/get-dep-list`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.name,
            });
          });
          setDepartmentList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getDesignationList = () => {
    const endPoint = `employee/get-des-list`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "user")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.name,
            });
          });
          setDesignationList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getProofList = () => {
    const endPoint = `master/list-proof-type`;
    let arr = [];
    getAPIData(endPoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.name,
            });
          });
          setProofList(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadPincodes = () => {
    const endPoint = "internal-api/get-pincode";
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var pincode_list = [];
          result?.data.map(async (pin, key) => {
            pincode_list.push({
              value: pin.id,
              label: pin.pincode,
              state: pin.state,
              district: pin.district,
            });
          });
          setPincodeList(pincode_list);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
        initialValues={{
          company_id: empData?.company_id,
          pincode_id: empData?.pincode_id,
          address: empData?.address,
          state: empData?.state,
          district: empData?.district,
          monthly_salary: empData?.monthly_salary,
          ctc: empData?.ctc,
          department_id: empData?.department_id,
          designation_id: empData?.designation_id,
          date_of_join: moment(empData?.date_of_join),
          proof_type_id: empData?.proof_type_id,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={8} className="gutter-row">
            <Row gutter={[16, 20]}>
              <Col className="gutter-row" span={24}>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Form.Item
                      label="Company Name"
                      name="company_id"
                      className="poppins_medium"
                      rules={[
                        {
                          required: true,
                          message: "Company name Required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        className="br"
                        size="large"
                        options={companyList}
                        placeholder="Enter company name"
                        onChange={(value, options) => {
                          getCompanyData(value);
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toString().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toString()
                            .localeCompare((optionB?.label ?? "").toString())
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="poppins_medium"
                      // style={{ marginLeft: "-45px" }}
                    >
                      <CLoadingButton
                        size="large"
                        value="DETAILS"
                        className="poppins_medium umoney_btn"
                        loading={employmentDetailsBtnLoading}
                        onClick={() => employmentDetailsClick()}
                        block
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Pincode"
              name="pincode_id"
              size="large"
              rules={[{ required: true, message: "pincode is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select
                showSearch
                className="br"
                size="large"
                options={pincodeList}
                placeholder="Select pincode"
                onChange={getPincodeValue}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .localeCompare((optionB?.label ?? "").toString())
                }
              />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Office Address"
              name="address"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Office Address Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter office address" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="District"
              name="district"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "City Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter district" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="State"
              name="state"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "state Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter state" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Monthly Salary"
              name="monthly_salary"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "Monthly salary Required",
                },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Monthly Salary contains only number.",
                },
              ]}
            >
              <Input size="large" placeholder="Enter monthly salary" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Annual CTC"
              name="ctc"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "CTC Required",
                },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "CTC contains only number.",
                },
              ]}
            >
              <Input size="large" placeholder="Enter annual CTC" />
            </Form.Item>
          </Col>
          <Col span={8} className="row-gutter">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Department"
                  name="department_id"
                  className="poppins_medium"
                  rules={[
                    {
                      required: true,
                      message: "Department Required",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    options={departmentList}
                    placeholder="Select department"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={8} className="row-gutter">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="Designation"
                  name="designation_id"
                  className="poppins_medium"
                  rules={[
                    {
                      required: true,
                      message: "Designation Required",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    options={designationList}
                    placeholder="Select designation"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="row-gutter">
            <Form.Item
              label="Date Of Joining"
              name="date_of_join"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "select date of joining",
                },
              ]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                placeholder="Enter date of joining"
                disabledDate={(current) =>
                  current.isAfter(moment().subtract(0, "day"))
                }
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item
              label="Proof Type"
              name="proof_type_id"
              className="poppins_medium"
              rules={[
                {
                  required: true,
                  message: "select employment proof",
                },
              ]}
            >
              <Select
                options={proofList}
                size="large"
                placeholder="Select proof type"
              ></Select>
            </Form.Item>
          </Col>
          <Col span={16}> </Col>
          <Col span={8} className="gutter-row">
            <CLoadingButton
              size="large"
              value="SAVE"
              htmlType="submit"
              className="poppins_medium umoney_btn"
              loading={saveBtnLoading}
              block
              onClick={() => setIdApprover(false)}
            />
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item>
              <CLoadingButton
                size="large"
                htmlType="submit"
                value="SAVE & APPROVE"
                loading={approveBtnLoading}
                onClick={() => setIdApprover(true)}
                className="poppins_medium umoney_btn"
                block
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
  async function findArrayElementByTitle(array, value) {
    return array.find((element) => {
      return element.value === value;
    });
  }
};

export default CompanyInformation;
