import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Modal,
  Upload,
  Button,
} from "antd";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { UploadOutlined } from "@ant-design/icons";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import { api_url, setHeaderForFile } from "../../../api";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import CCloseIcon from "../../InputFields/CCloseIcon";

const AddNewSubCategory = (props) => {
  const {
    onSave = () => {},
    subCategoryId,
    faqData,
    modalType,
    modalTitle,
    setModalType,
  } = props;

  const [form] = Form.useForm();
  const [category, setCategory] = useState([]);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [descriptionVal, setDesriptionVal] = useState("");

  const editorRef = useRef(null);

  if (subCategoryId && _.isEmpty(editData)) {
    const endPoint = "faq/one-sub-category/";
    const data = { sub_category_id: subCategoryId };
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          setEditData(result?.data[0]);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  const onFinish = async (data) => {
    console.log("Success:", data);
    try {
      const editorText = editorRef.current.getContent();
      console.log("onFinish ⋙---------> editorText", editorText);
      const ext = data?.upload?.file?.type.split("/")[0];
      if (ext !== "image") {
        let apidata = {};

        let endPoint = "";
        if (modalType === "add-sub-category") {
          endPoint = api_url + "faq/add-faq-sub-category";
          apidata = {
            title: data?.title,
            category_id: data?.master_faq_category_title,
            description: data?.description === undefined ? null : editorText,
            Video:
              data?.upload?.file?.originFileObj === undefined
                ? null
                : data?.upload?.file?.originFileObj,
          };
        } else {
          endPoint = api_url + "faq/update-sub-category";
          apidata = {
            sub_category_id: editData?.id,
            title: data?.title,
            description: data?.description === undefined ? null : editorText,
            category_id: _.isString(data?.master_faq_category_title)
              ? editData?.category_id
              : data?.master_faq_category_title,
            Video:
              data?.upload?.file?.originFileObj === undefined
                ? null
                : data?.upload?.file?.originFileObj,
          };
        }

        setSaveBtnLoading(true);
        const header = setHeaderForFile();
        const uploadData = await axios.post(endPoint, apidata, header);

        if (uploadData?.data?.status) {
          setSaveBtnLoading(false);
          onSave();
          form.resetFields();
          message.success(uploadData?.data?.message);
        } else {
          setSaveBtnLoading(false);
          message.warning(uploadData?.data?.message);
        }
      } else {
        setSaveBtnLoading(false);
        message.warning("Upload only video!");
      }
    } catch (error) {
      message.error(error);
      setSaveBtnLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setModalType(false);
  };

  const handleSelect = (id) => {
    console.log("id", id);
    const endPoint = "faq/get-faq-id/" + id;
    if (!_.isEmpty(category)) {
      setCategory([]);
    }
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        let name = [];
        console.log("result?.data", result?.data);
        if (result?.status === true) {
          result?.data.map((e) =>
            name.push({ value: e?.id, label: e.title, text: e.title })
          );
          setCategory(name);
        } else {
          console.log(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    if (modalType === "add-sub-category") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
      setDesriptionVal(editData?.description);
    }
  }, [form, editData, modalType]);

  const removeVideo = () => {
    const dataRemoveImage = {
      url: editData?.media,
      imageType: "image_one",
      type: "faq-sub",
      faq_id: editData?.id,
    };
    const endPoint = "faq/remove-file";
    getAPIData(endPoint, "post", dataRemoveImage, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          console.log("fext");
          message.success(result?.message);
          onSave();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={1000}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="white_div boxshadow-div">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="FAQ Question"
                name="faq_id"
                rules={[
                  {
                    required: true,
                    message: "Please add FAQ Questions",
                  },
                ]}
              >
                <Select
                  size="large"
                  options={faqData}
                  onChange={handleSelect}
                  placeholder="Select FAQ question"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Category"
                name="master_faq_category_title"
                rules={[
                  {
                    required: true,
                    message: "Please select the Category!",
                  },
                ]}
              >
                <Select options={category} size="large" placeholder="Select category" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Sub Category"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please enter the sub Category !",
                  },
                ]}
              >
                <Input size="large" placeholder="Select sub category" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Description !",
                  },
                ]}
              >
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue="Write somethong awesome."
                  init={{
                    height: 400,
                    selector: "textarea#basic-example",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onEditorChange={(newText) => setDesriptionVal(newText)}
                  value={descriptionVal}
                  // handleEditorChange(content, editor);
                />
                {/* <TextArea rows={4} size="large" /> */}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="upload">
                <Upload maxCount="1">
                  <Button icon={<UploadOutlined />}>Upload Video</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              {modalType === "edit-sub-category" ? (
                editData?.media === null ? (
                  ""
                ) : (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "320px",
                        zIndex: "100",
                      }}
                      onClick={() => removeVideo()}
                    >
                      <CCloseIcon />
                    </div>
                    <video
                      src={editData?.media}
                      type="video/mp4"
                      width="320"
                      height="240"
                      controls
                    ></video>
                  </div>
                )
              ) : (
                ""
              )}
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value=" SAVE"
                  className="poppins_medium umoney_modal_filled_btn"
                  loading={saveBtnLoading}
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNewSubCategory;
