import React, { useState, useEffect } from "react";
import {
  Typography,
  Col,
  Row,
  Form,
  Input,
  message,
  Table,
  Switch,
} from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  MenuOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import CollectionModal from "../../../components/Modals/DPDModals";
import CommonModals from "../../../components/Modals/CommonModals";
import _ from "lodash";

const Collection = () => {
  const [approverForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { Title } = Typography;

  //Save btn
  const [saveApproverBtnLoading, setSaveApproverBtnLoading] = useState(false);

  //tableData
  const [dpdListData, setDpdListData] = useState([]);

  const [dpdData, setdpdData] = useState([]);
  const [response, setResponse] = useState("");
  //modal states
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  //APPROVER
  const onFinishApproverSubmit = (values) => {
    setSaveApproverBtnLoading(true);
    let data;
    let approver = [];

    Object.entries(values).map(([key, value]) =>
      approver.push({
        category: "collection",
        key: key,
        value: value,
      })
    );
    data = { system_data: approver };
    console.log("data", data);
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          //var response = result?.data;
          message.success(result?.message);
          setSaveApproverBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const openModal = (type, id) => {
    if (type === "add-dpd") {
      setModalType(type);
      setModalOpen(true);
    } else if (type === "edit-dpd") {
      loadEditDpdData(id);
      setModalType(type);
      setModalOpen(true);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalOpen(true);
    }
  };

  const onFailedApproverSubmit = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  //Load Dpd Data
  const loadDpdListData = () => {
    const endPoint = "system-config/dpd/list";
    getAPIData(endPoint, "get", {}, false).then((response) => {
      if (response?.status === true) {
        setDpdListData(response?.data);
        setResponse(response?.data);
      }
    });
  };

  //Load Dpd Edit Data
  const loadEditDpdData = (id) => {
    const endPoint = "system-config/dpd/get/" + id;
    getAPIData(endPoint, "get", {}, false).then((res) => {
      if (res?.status === true) {
        setdpdData(res?.data);
      }
    });
  };

  //active & inactive func
  const setValues = (item, id) => {
    let dummyArray = [...response];
    if (!_.isEmpty(item)) {
      var n_index = dummyArray.findIndex((key) => key.id === id);

      dummyArray[n_index].status =
        dummyArray[n_index].status === "1" ? "0" : "1";
    }

    setResponse(dummyArray);

    const endPoint = "system-config/dpd/change-status/" + id;
    getAPIData(endPoint, "get", {}, false)
      .then((res) => {
        if (res?.status === true) {
          message.success(res?.message);
        } else {
          message.warning(res?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "collection" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              approverForm.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
    loadDpdListData();
  }, [approverForm]);

  const TableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "From",
      dataIndex: "from",
      key: "title",
      render: (text, record) => (
        <div>
          <span>{record.from}</span>
        </div>
      ),
    },
    {
      title: "To",
      dataIndex: "to",
      key: "title",
      render: (text, record) => (
        <div>
          <span>{record.to}</span>
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div>
            <span>
              <Switch
                checked={record.status === "1" ? true : false}
                onChange={() => {
                  setValues(record, record?.id);
                }}
              />
            </span>
          </div>
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                openModal("edit-dpd", record?.id);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  APPROVER
                </Title>
              </Col>
              <Col span={12} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishApproverSubmit}
                  onFinishFailed={onFailedApproverSubmit}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={approverForm}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Bucket time (pending)"
                        name="approver_bucket_time"
                        rules={[
                          {
                            required: true,
                            message: "Bucket time is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input size="large" placeholder="Enter Bucket time" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Follow Up time (pending)"
                        name="approver_follow_up_time"
                        rules={[
                          {
                            required: true,
                            message: "Follow Up Time is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter Follow Up Time"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveApproverBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row gutter={[5, 16]}>
              <Col className="gutter-row" span={8} />
              <Col className="gutter-row" span={8}>
                <CLoadingButton
                  size="large"
                  value="Add"
                  className="poppins_medium umoney_modal_filled_btn"
                  onClick={() => {
                    // handleDelete(id, type);
                    setModalType("add-dpd");
                    setModalOpen(true);
                  }}
                  icon={<PlusOutlined />}
                  block
                  style={{ width: 300, marginTop: 20 }}
                />
              </Col>
              <div
                className="umoney_table "
                style={{ marginTop: 20, width: 925 }}
              >
                <Table
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                  columns={TableColumns}
                  dataSource={dpdListData}
                  // scroll={scroll}
                />
              </div>
            </Row>
          </div>
        </Content>
      </Layout>

      {modalType === "add-dpd" || modalType === "edit-dpd" ? (
        <CollectionModal
          title={modalType === "add-dpd" ? "Add-Dpd" : "Edit-dpd"}
          open={modalOpen}
          setOpen={setModalOpen}
          onFinishApi={loadDpdListData}
          dpdData={dpdData}
          modalType={modalType}
        />
      ) : null}

      {modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          title="Delete Confirmation"
          type="dpdDelete"
          setOpen={setModalOpen}
          modalType={modalType}
          id={deleteId}
          setModalType={setModalType}
          onDelete={(val) => {
            loadDpdListData();
          }}
        />
      ) : null}
    </div>
  );
};

export default Collection;
