import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Layout,
  Table,
  message,
  Input,
  Space,
  Button,
  Modal,
} from "antd";
import Sidebar from "../../components/Sidebar";

import { MenuOutlined, SearchOutlined } from "@ant-design/icons";

import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import Highlighter from "react-highlight-words";

const { Title } = Typography;
const { Header, Content } = Layout;

const FaqDashboard = () => {
  const [loading, setLoading] = useState(false);
  const { Text } = Typography;
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [showMore, setShowMore] = useState("");
  const [readMore, setReadMore] = useState(false);

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const openModal = (type, id) => {
    if (type === "show-full-html") {
      setModalType(type);
      setShowMore(id);
      setModalTitle("Show FAQ Description");
    }
  };

  const handleClose = () => {
    setModalType("");
  };

  const loadFaqData = () => {
    setLoading(true);
    const endPoint = "faq/dashboard";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  // Search Functionality START
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const listColumns = [
    {
      title: "ID",
      dataIndex: "faq_id",
      width: "5%",
      ...getColumnSearchProps("faq_id"),
      sorter: (a, b) => a.faq_id - b.faq_id,
    },
    {
      title: "FAQ QUESTION",
      dataIndex: "faq_title",
      width: "15%",
      ...getColumnSearchProps("faq_title"),
    },
    {
      title: "CATEGORY",
      dataIndex: "faq_cat_title",
      width: "15%",
      ...getColumnSearchProps("faq_cat_title"),
    },
    {
      title: "SUB CATEGORY",
      dataIndex: "faq_sub_cat_title",
      width: "15%",
      ...getColumnSearchProps("faq_sub_cat_title"),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "faq_sub_cat_description",
      width: "15%",
      ...getColumnSearchProps("faq_sub_cat_description"),
      render: (text, record) => (
        <div>
          {readMore
            ? `${record?.faq_sub_cat_description}`
            : `${record?.faq_sub_cat_description?.substring(0, 30)}${
                record?.faq_sub_cat_description?.length > 50 ? "... " : " "
              }`}

          <Text
            style={{
              color: "#000",
              fontWeight: 700,
              cursor: "pointer",
              marginLeft: "3px",
            }}
            onClick={() =>
              openModal("show-full-html", record.faq_sub_cat_description)
            }
          >
            {record?.faq_sub_cat_description?.length > 30
              ? "....Read More"
              : ""}
          </Text>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadFaqData();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}

      <Layout
        style={{
          padding: "20px 0 20px 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              backgroundColor: "#009D93",
              borderRadius: "15px 0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              FAQ List
            </Title>
          </Header>
          <Content
            className="site-layout-background white_div"
            style={{
              padding: "20px 15px",
              borderRadius: "5px",
            }}
          >
            <div className="umoney_table" style={{ marginTop: 20 }}>
              <Table
                columns={listColumns}
                dataSource={tableData}
                scroll={{
                  x: 1300,
                }}
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
      {modalType === "show-full-html" ? (
        <Modal
          open={modalType}
          onCancel={handleClose}
          title={modalTitle}
          className="poppins_regular"
          width={1000}
          footer={null}
          closable={true}
          centered={true}
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          {showMore}
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default FaqDashboard;
