import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const DeviceChangeFilter = () => {
  const [deviceChangefilter] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  //Save btn
  const [saveDeviceChangeLoading, setSaveDeviceChangeLoading] = useState(false);

  const onFinishDeviceChangeReq = (values) => {
    setSaveDeviceChangeLoading(true);
    let data;
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "device_change_filter",
        key: key,
        value: value,
      })
    );
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSaveDeviceChangeLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedDeviceChangeReq = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "device_change_filter" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              deviceChangefilter.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
  }, [deviceChangefilter]);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header className="site-layout-background main-header">
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Super Admin Dashboard
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 15,
              backgroundColor: "#DFFEFC",
            }}
          >
            <div className="white_div">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    DEVICE CHANGE REQUEST NO.
                  </Title>
                </Col>
                <Col span={6} className="poppins_medium boxshadow-div">
                  <Form
                    name="basic"
                    onFinish={onFinishDeviceChangeReq}
                    onFinishFailed={onFailedDeviceChangeReq}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={deviceChangefilter}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Device change request no. (pending)"
                          name="device_change_request_no"
                          rules={[
                            {
                              required: true,
                              message: "Device change request no. is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter device change request no"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveDeviceChangeLoading}
                          block
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default DeviceChangeFilter;
