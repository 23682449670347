import React, { useState, useEffect } from "react";
import { Typography, Row, Input, Col, Form, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";

import { getAPIData } from "../../../apiHelper";

const GeneralManagement = () => {
  const [otpConfiguration] = Form.useForm();
  const [imageUpload] = Form.useForm();
  const [ageLimit] = Form.useForm();
  const [profileName] = Form.useForm();
  const [durationOfCourse] = Form.useForm();
  const [creditCheckLimit] = Form.useForm();
  const [loanInterestRate] = Form.useForm();
  const [loanInterestAfterDueDate] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;
  console.log(profileName);

  //Save btn
  const [saveOtpBtnLoading, setSaveOtpBtnLoading] = useState(false);
  const [saveImageBtnLoading, setSaveImageBtnLoading] = useState(false);
  const [saveAgeBtnLoading, setSaveAgeBtnLoading] = useState(false);
  const [saveProfileBtnLoading, setSaveProfileBtnLoading] = useState(false);
  const [saveDurationBtnLoading, setSaveDurationBtnLoading] = useState(false);
  const [saveCreditLimitBtnLoading, setSaveCreditLimitBtnLoading] =
    useState(false);
  const [saveLoanBtnLoading, setSaveLoanBtnLoading] = useState(false);
  const [saveLoanDueBtnLoading, setSaveLoanDueBtnLoading] = useState(false);

  //validation for Image Upload
  const [sizeLower, setSizeLower] = useState("");
  const [sizeUpper, setSizeUpper] = useState("");

  const [resolutionSizeLower, setResolutionSizeLower] = useState("");
  const [resolutionSizeUpper, setResolutionSizeUpper] = useState("");

  //validation for profile name
  const [minCharLimit, setMinCharLimit] = useState("");
  const [maxCharLimit, setMaxCharLimit] = useState("");
  const [namematch, setNamematch] = useState("");
  //OTP CONFIGURATION
  const onFinishOtpConfiguration = (values) => {
    setSaveOtpBtnLoading(true);
    onSubmit("otp_configuration", values);
  };

  const onFailedOtpConfiguration = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Image Upload
  const onFinishImageUpload = (values) => {
    const Sizecompare = parseInt(sizeUpper) > parseInt(sizeLower);
    const Rescompare =
      parseInt(resolutionSizeUpper) > parseInt(resolutionSizeLower);

    if (!Sizecompare) {
      imageUpload.setFields([
        {
          name: "image_upload_size_upper",
          errors: ["Upper Limit should be greater than Lower limit"],
        },
      ]);
      setSaveImageBtnLoading(false);
    } else if (!Rescompare) {
      imageUpload.setFields([
        {
          name: "image_upload_resolution_upper",
          errors: [
            "Resolution Upper Limit should be greater than Resolution Lower limit",
          ],
        },
      ]);
      setSaveImageBtnLoading(false);
    } else {
      setSaveImageBtnLoading(true);
      onSubmit("image_upload", values);
    }
  };

  const onFailedImageUpload = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //AGE LIMIT
  const onFinishAgeLimit = (values) => {
    setSaveAgeBtnLoading(true);
    onSubmit("age_limit", values);
  };

  const onFailedAgeLimit = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Profile Name
  const onFinishProfileName = (values) => {
    const Sizecompare = parseInt(maxCharLimit) > parseInt(minCharLimit);

    if (!Sizecompare) {
      profileName.setFields([
        {
          name: "profile_name_max_char",
          errors: [
            "Maximun Character Limit should be greater than Minimun Character Limit",
          ],
        },
      ]);
      setSaveProfileBtnLoading(false);
    } else {
      setSaveProfileBtnLoading(true);
      onSubmit("profile_name", values);
    }
  };

  const onFailedProfileName = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Maximum Allowed Duaration of course
  const onFinishDurationOfCourse = (values) => {
    setSaveDurationBtnLoading(true);
    onSubmit("max_allowed_duration_of_course", values);
  };

  const onFailedDurationOfCourse = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Credit Check Limit
  const onFinishCreditCheck = (values) => {
    setSaveCreditLimitBtnLoading(true);
    onSubmit("credit_check_limit", values);
  };

  const onFailedCreditCheck = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Student Loan Interest Rate %
  const onFinishStudLoanInterestRate = (values) => {
    setSaveLoanBtnLoading(true);
    onSubmit("stud_loan_interest_rate", values);
  };

  const onFailedStudLoanInterestRate = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Student Loan Interest After Due Date
  const onFinishStudLoanIntAfterDueDate = (values) => {
    setSaveLoanDueBtnLoading(true);
    onSubmit("stud_loan_interest_after_due_date", values);
  };

  const onFailedStudLoanIntAfterDueDate = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //On submit for all section
  const onSubmit = (type, values) => {
    let data;
    let otp_config = [];

    if (type === "otp_configuration") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "image_upload") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "age_limit") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "profile_name") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "no_of_student_on_campus") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "max_allowed_duration_of_course") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "credit_check_limit") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "stud_loan_interest_rate") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    } else if (type === "stud_loan_interest_after_due_date") {
      Object.entries(values).map(([key, value]) =>
        otp_config.push({
          category: "general_management",
          key: key,
          value: value,
        })
      );
    }

    data = { system_data: otp_config };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          if (type === "otp_configuration") {
            message.success(result?.message);
            setSaveOtpBtnLoading(false);
          } else if (type === "image_upload") {
            message.success(result?.message);
            setSaveImageBtnLoading(false);
          } else if (type === "age_limit") {
            message.success(result?.message);
            setSaveAgeBtnLoading(false);
          } else if (type === "profile_name") {
            message.success(result?.message);
            setSaveProfileBtnLoading(false);
          } else if (type === "max_allowed_duration_of_course") {
            message.success(result?.message);
            setSaveDurationBtnLoading(false);
          } else if (type === "credit_check_limit") {
            message.success(result?.message);
            setSaveCreditLimitBtnLoading(false);
          } else if (type === "stud_loan_interest_rate") {
            message.success(result?.message);
            setSaveLoanBtnLoading(false);
          } else if (type === "stud_loan_interest_after_due_date") {
            message.success(result?.message);
            setSaveLoanDueBtnLoading(false);
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "general_management" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              otpConfiguration.setFieldsValue({ [data?.name]: data?.value });
              imageUpload.setFieldsValue({ [data?.name]: data?.value });
              if (data?.name === "image_upload_size_lower") {
                setSizeLower(data?.value);
              }
              if (data?.name === "image_upload_size_upper") {
                setSizeUpper(data?.value);
              }
              if (data?.name === "image_upload_resolution_lower") {
                setResolutionSizeLower(data?.value);
              }
              if (data?.name === "image_upload_resolution_upper") {
                setResolutionSizeUpper(data?.value);
              }
              if (data?.name === "profile_name_min_char") {
                setMinCharLimit(data?.value);
              }
              if (data?.name === "profile_name_max_char") {
                setMaxCharLimit(data?.value);
              }
              ageLimit.setFieldsValue({ [data?.name]: data?.value });
              profileName.setFieldsValue({ [data?.name]: data?.value });
              durationOfCourse.setFieldsValue({ [data?.name]: data?.value });
              creditCheckLimit.setFieldsValue({ [data?.name]: data?.value });
              loanInterestRate.setFieldsValue({ [data?.name]: data?.value });
              loanInterestAfterDueDate.setFieldsValue({
                [data?.name]: data?.value,
              });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
  }, [
    ageLimit,
    creditCheckLimit,
    durationOfCourse,
    imageUpload,
    loanInterestAfterDueDate,
    loanInterestRate,
    otpConfiguration,
    profileName,
  ]);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />

          <Header className="site-layout-background main-header">
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Super Admin Dashboard
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: "20px 15px",
              borderRadius: "5px",
            }}
          >
            <div className="white_div">
              {/* OTP CONFIGURATION start*/}
              <Form
                name="basic"
                onFinish={onFinishOtpConfiguration}
                onFinishFailed={onFailedOtpConfiguration}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={otpConfiguration}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      OTP CONFIGURATION
                    </Title>
                  </Col>
                  <Col span={12} className="poppins_medium boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Initial OTP duration (Sec)."
                          name="otp_configuration_initial_otp_duration"
                          rules={[
                            {
                              required: true,
                              message: "Initial OTP duration is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter initial OTP duration"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="OTP re-trigger timer (Sec)."
                          name="otp_configuration_otp_retrigger_timer"
                          rules={[
                            {
                              required: true,
                              message:
                                "OTP re-trigger timer (Sec). is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter OTP re-trigger timer (sec)"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveOtpBtnLoading}
                          style={{ width: "100%" }}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>

              {/* IMAGE UPLOAD  start*/}
              <Form
                name="basic"
                onFinish={onFinishImageUpload}
                onFinishFailed={onFailedImageUpload}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={imageUpload}
              >
                <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
                  <Col span={24}>
                    <Title
                      level={5}
                      style={{ marginTop: "20px" }}
                      className="poppins_medium umoney_title"
                    >
                      IMAGE UPLOAD
                    </Title>
                  </Col>
                  <Col span={24} className="poppins_medium boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item
                          label="Size lower limit (pending)"
                          name="image_upload_size_lower"
                          onChange={(e) => {
                            setSizeLower(e.target.value);
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Size lower limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter size lower limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Size Upper Limit (pending)"
                          name="image_upload_size_upper"
                          onChange={(e) => {
                            setSizeUpper(e.target.value);
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Size Upper Limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter size upper limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Resolution Lower limit (pending)"
                          name="image_upload_resolution_lower"
                          onChange={(e) => {
                            setResolutionSizeLower(e.target.value);
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Resolution Lower limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter resolution lower limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Resolution Upper limit (pending)"
                          name="image_upload_resolution_upper"
                          onChange={(e) => {
                            setResolutionSizeUpper(e.target.value);
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Resolution Upper limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter resolution upper limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveImageBtnLoading}
                          style={{ width: "100%" }}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>

              {/* AGE LIMIT start */}
              <Form
                name="basic"
                onFinish={onFinishAgeLimit}
                onFinishFailed={onFailedAgeLimit}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={ageLimit}
              >
                <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      AGE LIMIT
                    </Title>
                  </Col>
                  <Col span={12} className="boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Minimun age limit"
                          name="age_limit_min_age"
                          rules={[
                            {
                              required: true,
                              message: "Minimun age limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter minimun age limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Maximum age limit"
                          name="age_limit_max_age"
                          rules={[
                            {
                              required: true,
                              message: "Maximum age limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter maximum age limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveAgeBtnLoading}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>

              {/* PROFILE NAME start*/}
              <Form
                name="basic"
                onFinish={onFinishProfileName}
                onFinishFailed={onFailedProfileName}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={profileName}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      PROFILE NAME
                    </Title>
                  </Col>
                  <Col span={18} className="poppins_medium boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <Form.Item
                          label="Maximum Character Limit"
                          name="profile_name_max_char"
                          onChange={(e) => {
                            setMaxCharLimit(e.target.value);
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Maximum Character Limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter maximum character limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Minimum character limit"
                          name="profile_name_min_char"
                          onChange={(e) => {
                            setMinCharLimit(e.target.value);
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Minimum character limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter minimum character limit (Sec)"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Name match %"
                          name="profile_name_match_per"
                          onChange={(e) => {
                            setNamematch(e.target.value);
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Name Match is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter minimum character limit (Sec)"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveProfileBtnLoading}
                          style={{ width: "100%" }}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>

              {/* MAXIMUM ALLOWED DURATION OF COURSE start*/}
              <Form
                name="basic"
                onFinish={onFinishDurationOfCourse}
                onFinishFailed={onFailedDurationOfCourse}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={durationOfCourse}
              >
                <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      MAXIMUM ALLOWED DURATION OF COURSE
                    </Title>
                  </Col>
                  <Col span={6} className="poppins_medium boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Maximum duration of course (pending)"
                          name="max_allowed_duration_of_course"
                          rules={[
                            {
                              required: true,
                              message:
                                "Maximum duration of course is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter maximum duration of course (Sec)"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveDurationBtnLoading}
                          //onClick={() => saveDurationClick()}
                          style={{ width: "100%" }}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>

              {/* CREDIT CHECK LIMIT */}
              <Form
                name="basic"
                onFinish={onFinishCreditCheck}
                onFinishFailed={onFailedCreditCheck}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={creditCheckLimit}
              >
                <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      CREDIT CHECK LIMIT
                    </Title>
                  </Col>
                  <Col span={6} className="poppins_medium boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Credit Check Limit (pending)"
                          name="credit_check_limit"
                          rules={[
                            {
                              required: true,
                              message: "Credit Check Limit is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter credit check limit"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveCreditLimitBtnLoading}
                          style={{ width: "100%" }}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>

              {/* STUDENT LOAN INTEREST RATE % */}
              <Form
                name="basic"
                onFinish={onFinishStudLoanInterestRate}
                onFinishFailed={onFailedStudLoanInterestRate}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={loanInterestRate}
              >
                <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      STUDENT LOAN INTEREST RATE %
                    </Title>
                  </Col>
                  <Col span={6} className="poppins_medium boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Interest rate %"
                          name="student_loan_interest_rate_per"
                          rules={[
                            {
                              required: true,
                              message: "Interest rate % is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter interest rate % (sec)"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveLoanBtnLoading}
                          //onClick={() => saveLoanClick()}
                          style={{ width: "100%" }}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>

              {/* STUDENT LOAN INTEREST AFTER DUE DATE % */}
              <Form
                name="basic"
                onFinish={onFinishStudLoanIntAfterDueDate}
                onFinishFailed={onFailedStudLoanIntAfterDueDate}
                autoComplete="off"
                className="umoney-form"
                requiredMark={false}
                form={loanInterestAfterDueDate}
              >
                <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5} className="poppins_medium umoney_title">
                      STUDENT LOAN INTEREST AFTER DUE DATE %
                    </Title>
                  </Col>
                  <Col span={6} className="poppins_medium boxshadow-div">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Loan interest after due date %"
                          name="student_loan_interest_after_due_date_per"
                          rules={[
                            {
                              required: true,
                              message:
                                "Loan interest after due date % is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter loan interest after due date % "
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveLoanDueBtnLoading}
                          // onClick={() => saveLoanDueClick()}
                          style={{ width: "100%" }}
                          block
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
};

export default GeneralManagement;
