import React, { useState, useEffect, useRef } from "react";
import { Form, Input, message, Col, Modal, Row } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";

const CMSModals = (props) => {
  const {
    onSave = () => {},
    editData,
    modalType,
    modalTitle,
    setModalType,
  } = props;
  const [form] = Form.useForm();
  const [savebtnLoading, setSaveBtnLoading] = useState(false);
  // const [value, setValue] = useState("");
  const editorRef = useRef(null);

  console.log("editData=========>", editData);

  const handleClose = () => {
    setModalType(false);
  };

  const [slug, setSlug] = useState();

  // function convertToPlain(html) {
  //   // Create a new div element
  //   var tempDivElement = document.createElement("div");

  //   // Set the HTML content with the given value
  //   tempDivElement.innerHTML = html;

  //   // Retrieve the text property of the element
  //   return tempDivElement.textContent || tempDivElement.innerText || "";
  // }

  const onSubmitData = (data) => {
    const editorText = editorRef.current.getContent();
    data.app_body = editorText;
    console.log(data);
    setSaveBtnLoading(true);
    var endPoint =
      modalType === "add-CMS"
        ? "system-config/cms/create"
        : "system-config/cms/update/" + editData?.id;

    // data.dynamic_data_param = "1";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  useEffect(() => {
    if (modalType === "add-CMS") {
      form.resetFields();
    } else {
      console.log(editData.app_body);
      form.setFieldsValue(editData);
    }
  }, [form, editData, modalType]);

  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo===>>", errorInfo);
  };

  const createSlug = (e) => {
    var slug = e
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/[^\w-]+/g, "");
    form.setFieldValue("slug", slug);
  };

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={1000}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="white_div boxshadow-div">
        <Form
          name="basic"
          onFinish={(data) => {
            onSubmitData(data);
          }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Title is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter the title"
                  onChange={(e) => createSlug(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Template Header (Slug)"
                name="slug"
                rules={[
                  {
                    required: true,
                    message: "Template Header is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter the slug"
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={24}>
              <Form.Item
                label="App Body"
                name="app_body"
                // rules={[
                //   {
                //     required: true,
                //     message: "app body is required!",
                //   },
                // ]}
                className="poppins_medium mb-35"
              >
                <div>
                  {/* <EditorToolbar />
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={handleChange}
                    placeholder={"Write something awesome..."}
                    modules={modules}
                    formats={formats}
                  /> */}
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={editData?.app_body}
                    init={{
                      height: 400,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Meta title"
                name="meta_title"
                rules={[
                  {
                    required: false,
                    message: "meta title is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter meta title" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Meta keyword"
                name="meta_keyword"
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter meta keyword" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Meta description" name="meta_description">
                <Input size="large" placeholder="Enter description" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value=" SAVE"
                className="poppins_medium umoney_modal_filled_btn"
                loading={savebtnLoading}
                block
              />
            </Col>
            <Col className="gutter-row" span={18}></Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default CMSModals;
