import React, { useState } from "react";

import { Row, Col, Form, Input, Select, Radio, message, } from "antd";

import CLoadingButton from "../InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";

const UpdateDetailsForOther = ({
  userId,
  deviceId,
  handleClose,
  loadUserProvidedInfo,
}) => {
  const [Value, setValue] = useState(false);
  const [relationList, setRelationList] = useState();

  const [form] = Form.useForm();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const loadRelationList = () => {
    const endPoint = "device/get-relative-list";
    let array = [];
    getAPIData(endPoint, "get", {}, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            array.push({
              value: data?.id,
              label: data?.relation,
            });
          });
          setRelationList(array);
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  console.log(relationList);

  useEffect(() => {
    loadRelationList();
    form.resetFields();
  }, []);

  const onFinish = (data) => {
    data.type = "admin";
    data.userId = userId;
    const endPoint = "device/save-device-contact-info";
    setSaveBtnLoading(true);
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSaveBtnLoading(false);
          loadUserProvidedInfo();
          form.resetFields();
          handleClose();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="umoney-form m-0"
      requiredMark={false}
      form={form}
    >
      <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Relation"
            name="relation_id"
            className="poppins_medium mb-35"
            rules={[
              {
                required: true,
                message: "Please choose relation!",
              },
            ]}
          >
            <Select size="large" options={relationList} />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Name"
            name="name"
            className="poppins_medium mb-35"
            rules={[
              {
                required: true,
                message: "Please enter the Name!",
              },
              {
                pattern: new RegExp(
                  /^[a-zA-Z\s]*$/
                ),
                message: "Name only contain alphabets",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Mobile number"
            name="contact_no"
            className="poppins_medium mb-35"
            rules={[
              {
                required: true,
                message: "Please enter the Mobile number!",
              },
              {
                pattern: /^[0-9]\d*(\.\d+)?$/g,
                message: "Mobile number contains only number.",
              },
              {
                min: 10,
                message: "Mobile number contains minimum 10 digits.",
              },
              {
                max:10,
                message : "Mobile number contains only 10 digits"
              },
              () => ({
                async validator(rule, value) {
                  const firstDigit = String(value)[0];
                  if (!_.isUndefined(firstDigit) && firstDigit <= 5) {
                    return Promise.reject(
                      "Phone number must be start with 6 to 9 digits only."
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Comment"
            name="comment"
            className="poppins_medium mb-35"
            rules={[
              {
                required: true,
                message: "Please enter the Comment !",
              },
            ]}
          >
            <TextArea rows={4} size="large" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={24}>
          <Form.Item
            label="Validated ?"
            name="validated"
            className="poppins_medium mb-35"
            rules={[
              {
                required: true,
                message: "Please choose one !",
              },
            ]}
          >
            <Radio.Group style={{ paddingTop: "15px" }}>
              <Radio value={1}>YES</Radio>
              <Radio value={0}>NO</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={24}>
          <Form.Item>
            <CLoadingButton
              size="large"
              value="SAVE"
              htmlType="submit"
              className="poppins_medium umoney_modal_filled_btn"
              block
              loading={saveBtnLoading}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UpdateDetailsForOther;
