import React, { useState, useEffect } from "react";
import { Typography, Table, Col, Row, Form, message, Input } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import CommonModals from "../../../components/Modals/CommonModals";
import DeviceChangeModals from "../../../components/Modals/DeviceChangeModals";

const DeviceChange = () => {
  const [temporaryDevice] = Form.useForm();
  const [deviceChangeHours] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  //Save btn
  const [saveTempDeviceBtnLoading, setSaveTempDeviceBtnLoading] =
    useState(false);

  // Approve device states
  const [approveDeviceList, setApproveDeviceList] = useState([]);
  const [approverDeviceDeleteId, setApproverDeviceDeleteId] = useState(Number);

  // Reject device states
  const [rejectDeviceList, setRejectDeviceList] = useState([]);
  const [rejectDeviceDeleteId, setRejectDeviceDeleteId] = useState(Number);

  // Modal States
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [editData, setEditData] = useState();
  const [rejectEditData, setRejectEditData] = useState();

  const openModal = (type, id, record) => {
    if (type === "add-approve-device") {
      setModalType(type);
      setModalTitle("Add Approve Device");
    } else if (type === "approve-device-edit") {
      setEditData(record);
      setModalType(type);
      setModalTitle("Edit Approve Device");
    } else if (type === "add-reject-device") {
      setModalType(type);
      setModalTitle("Add Approve Device");
    } else if (type === "reject-device-edit") {
      setRejectEditData(record);
      setModalType(type);
      setModalTitle("Edit Approve Device");
    }
  };

  //TEMPORARY DEVICE IDENTIFYING
  const onFinishDeviceChange = (values) => {
    setSaveTempDeviceBtnLoading(true);
    let data;
    let device_list = [];

    Object.entries(values).map(([key, value]) =>
      device_list.push({
        category: "device_change",
        key: key,
        value: value,
      })
    );
    data = { system_data: device_list };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          //var response = result?.data;
          message.success(result?.message);
          setSaveTempDeviceBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedDeviceChange = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  //DEVICE CHANGE HOURS

  //TEMPORARY DEVICE IDENTIFYING
  const onFinishDeviceChangeHours = (values) => {
    setSaveTempDeviceBtnLoading(true);
    let data;
    let device_list = [];

    Object.entries(values).map(([key, value]) =>
      device_list.push({
        category: "device_change",
        key: key,
        value: value,
      })
    );
    data = { system_data: device_list };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          //var response = result?.data;
          message.success(result?.message);
          setSaveTempDeviceBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedDeviceChangeHours = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  const loadDeviceApprovedData = () => {
    setLoading(true);
    const endPoint = "system-config/device-change/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status) {
          setApproveDeviceList(result?.data?.approveList);
          setRejectDeviceList(result?.data?.rejectList);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadData = () => {
    const data = { category_name: "device_change" };
    const endPoint = "system-config/get-config-data";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map(async (data) => {
            temporaryDevice.setFieldsValue({ [data?.name]: data?.value });
            deviceChangeHours.setFieldsValue({ [data?.name]: data?.value });
          });
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    loadData();
    loadDeviceApprovedData();
  }, [deviceChangeHours, temporaryDevice]);

  const approveTableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "DEVICE NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // approveDeviceEdit(record?.id, record?.name);
                openModal("approve-device-edit", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteType("approveDevice");
                setApproverDeviceDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  const rejectTableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "DEVICE NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ACTIONS",
      dataIndex: "",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // rejectDeviceEdit(record?.id, record?.name);
                openModal("reject-device-edit", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteType("rejectDevice");
                setRejectDeviceDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  TEMPORARY DEVICE IDENTIFYING
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishDeviceChange}
                  onFinishFailed={onFailedDeviceChange}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={temporaryDevice}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Temporary device identifying days (pending)"
                        name="temporary_device_identifying_days"
                        rules={[
                          {
                            required: true,
                            message:
                              "Temporary device identifying days is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter temporary device identifying days"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveTempDeviceBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  DEVICE CHANGE APPROVING LISt
                </Title>
              </Col>
              <Col span={6}>
                <CLoadingButton
                  size="large"
                  icon={<PlusOutlined />}
                  value="ADD"
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-approve-device")}
                />
              </Col>
            </Row>

            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={approveTableColumns}
                dataSource={approveDeviceList}
              />
            </div>

            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  DEVICE CHANGE REJECTING LISt
                </Title>
              </Col>
              <Col span={6}>
                <CLoadingButton
                  size="large"
                  value="ADD"
                  icon={<PlusOutlined />}
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-reject-device")}
                />
              </Col>
            </Row>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={rejectTableColumns}
                dataSource={rejectDeviceList}
              />
            </div>

            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  DEVICE CHANGE HOURS
                </Title>
              </Col>
              <Col span={12} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishDeviceChangeHours}
                  onFinishFailed={onFailedDeviceChangeHours}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={deviceChangeHours}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Bucket time"
                        name="device_change_hrs_bucket_time (pending)"
                        rules={[
                          {
                            required: true,
                            message: "Bucket time is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input size="large" placeholder="Enter bucket time" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Follow Up Time"
                        name="device_change_hrs_follow_up_time (pending)"
                        rules={[
                          {
                            required: true,
                            message: "Follow Up Time is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter follow up time"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveTempDeviceBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
      {modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          setOpen={setModalOpen}
          title={
            deleteType === "approveDevice"
              ? "Delete Approve Device"
              : "Delete Reject Device"
          }
          type={deleteType}
          modalType={modalType}
          setModalType={setModalType}
          id={
            deleteType === "approveDevice"
              ? approverDeviceDeleteId
              : rejectDeviceDeleteId
          }
          onDelete={(val) => {
            loadDeviceApprovedData();
          }}
        />
      ) : modalType === "add-approve-device" ? (
        <DeviceChangeModals
          modalTitle={modalTitle}
          setModalType={setModalType}
          modalType={modalType}
          onSave={() => {
            setModalType(false);
          }}
          loadData={loadData}
          loadDeviceApprovedData={loadDeviceApprovedData}
        />
      ) : modalType === "approve-device-edit" ? (
        <DeviceChangeModals
          editData={editData}
          modalTitle={modalTitle}
          modalType={modalType}
          setModalType={setModalType}
          onSave={() => {
            setModalType(false);
          }}
          loadData={loadData}
          loadDeviceApprovedData={loadDeviceApprovedData}
        />
      ) : modalType === "add-reject-device" ? (
        <DeviceChangeModals
          modalTitle={modalTitle}
          setModalType={setModalType}
          modalType={modalType}
          onSave={() => {
            setModalType(false);
          }}
          loadData={loadData}
          loadDeviceApprovedData={loadDeviceApprovedData}
        />
      ) : modalType === "reject-device-edit" ? (
        <DeviceChangeModals
          rejectEditData={rejectEditData}
          modalTitle={modalTitle}
          modalType={modalType}
          setModalType={setModalType}
          onSave={() => {
            setModalType(false);
          }}
          loadData={loadData}
          loadDeviceApprovedData={loadDeviceApprovedData}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DeviceChange;
