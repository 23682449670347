import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import VerificationBox from "./VerificationBox";
import ConfirmationDeviceChange from "./ConfirmationDeviceChange";

const SelectDevice = ({ deviceId, userId, handleClose, loadData }) => {
  const [loading, setLoading] = useState(false);
  const [approveBtnLoading, setapprovedBtnLoading] = useState(false);
  const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
  const [tempBtnLoading, setTempBtnLoading] = useState(false);
  const [followBtnLoading, setFollowBtnLoading] = useState(false);

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [actionButton, setActionButton] = useState();

  console.log(deviceId, userId);
  const [type, setType] = useState("");

  const openModal = (type) => {
    if (type === "verification-box") {
      setModalType(type);
      setModalTitle("Verification Box");
      setModalWidth(500);
      setActionButton("1");
      handleClose();
    } else if (type === "verification-box-mark") {
      setModalType(type);
      setModalTitle("Verification Box");
      setModalWidth(500);
      setActionButton("4");
      handleClose();
    } else if (type === "reject") {
      setModalType(type);
      setModalTitle("Confirmation Modal");
      setModalWidth(500);
      setActionButton("2");
      handleClose();
    } else if (type === "follow-up") {
      setModalType(type);
      setModalTitle("Confirmation Modal");
      setModalWidth(500);
      setActionButton("3");
      handleClose();
    }
  };

  const handleCloseModal = () => {
    setModalType("");
  };

  // const loadData = () => {
  //   setTimeout(() => {
  //     setLoading(false);
  //     setapprovedBtnLoading(false);
  //     setFollowBtnLoading(false);
  //     setRejectBtnLoading(false);
  //     setTempBtnLoading(false);
  //   }, 5000);
  // };

  // useEffect(() => {
  //   loadData();
  // });

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
        <Col span={12}>
          <CLoadingButton
            size="large"
            value="APPROVE"
            className="poppins_medium umoney_outlined_btn"
            loading={approveBtnLoading}
            onClick={() => {
              openModal("verification-box");
            }}
            style={{
              background: "#3EA467 0% 0% no-repeat padding-box",
              color: "white",
              border: "none",
            }}
            block
          />
        </Col>
        <Col span={12}>
          <CLoadingButton
            size="large"
            value="REJECT"
            className="poppins_medium umoney_outlined_btn"
            loading={rejectBtnLoading}
            onClick={() => openModal("reject")}
            // countval="57"
            style={{
              background: "#DE1717 0% 0% no-repeat padding-box",
              color: "white",
              border: "none",
            }}
            block
          />
        </Col>
        <Col span={12}>
          <CLoadingButton
            size="large"
            value="MARK AS TEMPORARY"
            className="poppins_medium umoney_outlined_btn"
            loading={tempBtnLoading}
            onClick={() => openModal("verification-box-mark")}
            // countval="57"
            style={{
              background: "#FFC200 0% 0% no-repeat padding-box",
              color: "white",
              border: "none",
            }}
            block
          />
        </Col>
        <Col span={12}>
          <CLoadingButton
            size="large"
            value="FOLLOW-UP"
            className="poppins_medium umoney_outlined_btn"
            loading={followBtnLoading}
            // countval="57"
            style={{
              background: "#4C9AFF 0% 0% no-repeat padding-box",
              color: "white",
              border: "none",
            }}
            block
            onClick={() => openModal("follow-up")}
          />
        </Col>
      </Row>
      <Modal
        open={modalType}
        title={modalTitle}
        footer={false}
        onCancel={handleCloseModal}
        centered
        width={modalWidth}
      >
        {modalType === "verification-box" ? (
          <VerificationBox
            deviceId={deviceId}
            userId={userId}
            actionButton={actionButton}
            handleCloseModal={handleCloseModal}
            loadData={loadData}
          />
        ) : modalType === "verification-box-mark" ? (
          <VerificationBox
            deviceId={deviceId}
            userId={userId}
            actionButton={actionButton}
            handleCloseModal={handleCloseModal}
            type={type}
            loadData={loadData}
          />
        ) : modalType === "reject" ? (
          <ConfirmationDeviceChange
            deviceId={deviceId}
            userId={userId}
            actionButton={actionButton}
            handleCloseModal={handleCloseModal}
            loadData={loadData}
          />
        ) : modalType === "follow-up" ? (
          <ConfirmationDeviceChange
            deviceId={deviceId}
            userId={userId}
            actionButton={actionButton}
            handleCloseModal={handleCloseModal}
            loadData={loadData}
          />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default SelectDevice;
