import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useState } from "react";
import CClickLoading from "../components/InputFields/CClickLoading";
import Sidebar from "../components/Sidebar";
import {
  MenuOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect, useRef } from "react";
import { getAPIData } from "../apiHelper";
import CLoadingButton from "../components/InputFields/CLoadingButton";
import CommonModals from "../components/Modals/CommonModals";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { setHeader } from "../api/index";
import FeeDisbursalModals from "../components/Modals/FeeDisbursalModals";

const { Title } = Typography;

const FeeDisbursal = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const openModal = (type, id, record) => {
    if (type === "add-fee") {
      setModalType(type);
      setModalTitle("Add Fee Disbursal");
    } else if (type === "edit-fee") {
      setEditData(record);
      setModalType(type);
      setModalTitle("Edit Fee Disbursal");
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
      setModalOpen(true);
    }
  };

  const [feeData, setFeeData] = useState();
  const [editData, setEditData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editCancleIcon, setEditCancleIcon] = useState(false);

  console.log("Edit", editData);
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const loadFeeData = () => {
    setLoading(true);
    const endPoint = "master/disbursal-fee/list";
    getAPIData(endPoint, "get", {}, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setFeeData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };

  const setValues = (id) => {
    const data = { disbursal_id: id };
    let endPoint = `master/disbursal-fee/status-change`;
    const header = setHeader();

    getAPIData(endPoint, "post", data, header)
      .then((result) => {
        if (result?.status === true) {
          loadFeeData();
          message.success(result?.message);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    if (_.isEmpty(feeData)) {
      loadFeeData();
    }
  }, [loadFeeData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const TableColumns = [
    {
      title: " ID",
      dataIndex: "id",
      key: "id",
      width: "7%",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id"),
      filteredValue: filteredInfo.id || null,
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "7%",
      filteredValue: filteredInfo.name || null,
      ...getColumnSearchProps("name"),
    },
    {
      title: "FEE TYPE",
      dataIndex: "fee_type",
      key: "fee_type",
      width: "7%",
      filteredValue: filteredInfo.fee_type || null,
      render: (text, record) => (
        <div>
          <span>{record?.fee_type === "f" ? "Fixed" : "Percentage"}</span>
        </div>
      ),
      filters: [
        { value: "f", label: "Fixed", text: "Fixed" },
        { value: "p", label: "Percentage", text: "Percentage" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.fee_type
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "TENURE DEPENDENT",
      dataIndex: "tenure_dependent",
      key: "tenure_dependent",
      width: "7%",
      filteredValue: filteredInfo.tenure_dependent || null,
      render: (text, record) => (
        <div>
          <span>
            {record?.tenure_dependent === "0"
              ? "NO"
              : record?.tenure_dependent === "1"
              ? "Day"
              : record?.tenure_dependent === "2"
              ? "Month"
              : record?.tenure_dependent === "3"
              ? "Quater"
              : record?.tenure_dependent === "4"
              ? "Yearly"
              : ""}
          </span>
        </div>
      ),
      filters: [
        { value: "0", label: "No", text: "No" },
        { value: "1", label: "Day", text: "Day" },
        { value: "2", label: "Month", text: "Month" },
        { value: "3", label: "Quater", text: "Quarter" },
        { value: "4", label: "Yearly", text: "Yearly" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.tenure_dependent
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "FEE",
      dataIndex: "fee",
      key: "fee",
      width: "7%",
      filteredValue: filteredInfo.fee || null,
      ...getColumnSearchProps("fee"),
    },
    {
      title: "MAX CAPACITY",
      dataIndex: "max_cap",
      key: "max_cap",
      width: "7%",
      filteredValue: filteredInfo.max_cap || null,
      ...getColumnSearchProps("max_cap"),
    },
    {
      title: "GST APPLICABLE",
      dataIndex: "gst_applicable",
      key: "gst_applicable",
      width: "7%",
      filteredValue: filteredInfo.gst_applicable || null,
      render: (text, record) => (
        <div>
          <span>{record?.gst_applicable === "0" ? "No" : "Yes"}</span>
        </div>
      ),

      filters: [
        { value: "0", label: "No", text: "No" },
        { value: "1", label: "Yes", text: "Yes" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.gst_applicable
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "GST PERCENTAGE",
      dataIndex: "gst_percent",
      key: "gst_percent",
      width: "7%",
      filteredValue: filteredInfo.gst_percentage || null,
      ...getColumnSearchProps("name"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "5%",
      align: "center",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => {
        // 0=Inactive, 1=Active, 2=Pending, -1=Deleted
        return (
          <div>
            <span>
              <Switch
                checked={record.status === "1" ? true : false}
                onChange={() => {
                  setValues(record?.id);
                }}
              />
            </span>
          </div>
        );
      },
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: "10%",
      align: "center",
      filteredValue: filteredInfo.action || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // internalDataEdit(record?.id, record?.content);
                openModal("edit-fee", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          //   height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            Fee Disbursal
          </Title>
        </Header>
        <Content
          className="site-layout-background white_div"
          style={{
            padding: "20px 15px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={8}></Col>
            <Col span={8}>
              <CLoadingButton
                size="large"
                value="CLEAR FILTERS"
                className="poppins_medium umoney_modal_filled_btn"
                block
                onClick={() => clearFilters()}
              />
            </Col>
            <Col span={8}>
              <CLoadingButton
                size="large"
                value="ADD"
                icon={<PlusOutlined />}
                className="poppins_medium umoney_modal_filled_btn"
                block
                onClick={() => openModal("add-fee")}
              />
            </Col>
          </Row>
          <div className="umoney_table " style={{ marginTop: 20 }}>
            <Table
              pagination={{
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
              columns={TableColumns}
              dataSource={feeData}
              onChange={handleChange}
            />
          </div>
        </Content>
      </Layout>

      {modalType === "add-fee" ? (
        <FeeDisbursalModals
          modalTitle={modalTitle}
          setModalType={setModalType}
          modalType={modalType}
          isEdit={isEdit}
          onSave={() => {
            setModalType(false);
          }}
          loadFeeData={loadFeeData}
        />
      ) : modalType === "delete" ? (
        <CommonModals
          open={modalOpen}
          title="Delete Confirmation"
          type="fee-disbursal"
          setOpen={setModalOpen}
          modalType={modalType}
          id={deleteId}
          setModalType={setModalType}
          onDelete={(val) => {
            loadFeeData();
          }}
        />
      ) : modalType === "edit-fee" ? (
        <FeeDisbursalModals
          editData={editData}
          modalTitle={modalTitle}
          modalType={modalType}
          isEdit={isEdit}
          setModalType={setModalType}
          onSave={() => {
            setModalType(false);
          }}
          setEditCancleIcon={setEditCancleIcon}
          setIsEdit={setIsEdit}
          editCancleIcon={editCancleIcon}
          loadFeeData={loadFeeData}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default FeeDisbursal;
