import React, { useState, useEffect } from "react";
import { Typography, Table, Col, Row, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import MobileAppModals from "../../../components/Modals/MobileAppModals";
import CommonModals from "../../../components/Modals/CommonModals";

const LoanFilterLogic = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  const [wordTableData, setWordTableData] = useState([]);
  const [appTableData, setAppTableData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const [singleData, setSingleData] = useState("");
  const [deleteId, setDeleteId] = useState("");

  //open Modal

  const [modalTitle, setModalTitle] = useState("");

  //Save btn
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const onFinish = (values) => {
    setSaveBtnLoading(true);
    let data;
    let loan = [];

    Object.entries(values).map(([key, value]) =>
      loan.push({
        category: "loan_filter_logic",
        key: key,
        value: value,
      })
    );
    data = { system_data: loan };
    console.log("data", data);
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          //var response = result?.data;
          message.success(result?.message);
          setSaveBtnLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  //Load the table data
  const loadNegativeData = () => {
    const endPoint = "system-config/negative/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setWordTableData(response?.WordsList);
          setAppTableData(response?.AppsList);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const openModal = (type, action, id) => {
    setModalOpen(true);
    if (type === "Add") {
      setModalTitle(type + " Negative");
      setModalType("add-negative");
    } else if (type === "Edit") {
      setModalTitle(type + " " + action + " Negative");
      setModalType("edit-negative");
      loadSingleData(id);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
    }
  };

  const loadSingleData = (id) => {
    setLoading(true);
    const endPoint = "system-config/negative/get/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setSingleData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "loan_filter_logic" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              form.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
    loadNegativeData();
  }, [form]);

  //CALLS
  const wordsColumns = [
    {
      title: "NEGATIVE WORDS",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("Edit", "Words", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", "Words", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  const appColumns = [
    {
      title: "NEGATIVE APPS",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("Edit", "Apps", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", "Apps", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  LOAN FILTER LOGIC
                </Title>
              </Col>
              <Col span={12} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={form}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Parent Contact Keyword (pending)"
                        name="loan_filter_logic_parent_contact_keyword"
                        rules={[
                          {
                            required: true,
                            message: "Parent Contact Keyword is required!",
                          },
                          {
                            message: "Please Enter Only Characters",
                            pattern: new RegExp(/[a-zA-Z]/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter parent contact keyword"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Family Contact Keyword (pending)"
                        name="loan_filter_logic_family_contact_keyword"
                        rules={[
                          {
                            required: true,
                            message: "Family Contact Keyword is required!",
                          },
                          {
                            message: "Please Enter Only Characters",
                            pattern: new RegExp(/[a-zA-Z]/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter family contact keyword"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <Row gutter={[16, 16]}>
                <Col span={10}>
                  <Title level={5} className="poppins_medium umoney_title">
                    NEGATIVE WORDS
                  </Title>
                </Col>
                <Col span={2}>
                  <CLoadingButton
                    size="large"
                    value="ADD"
                    className="poppins_medium umoney_btn"
                    onClick={() => openModal("Add", "Words")}
                    block
                  />
                </Col>
                <Col span={12}></Col>
              </Row>
            </div>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={wordsColumns}
                dataSource={wordTableData}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <Title level={5} className="poppins_medium umoney_title">
                NEGATIVE APPS
              </Title>
            </div>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={appColumns}
                dataSource={appTableData}
              />
            </div>
          </div>
        </Content>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            title="Delete Confirmation"
            type="negative-words-apps"
            setOpen={setModalOpen}
            modalType={modalType}
            id={deleteId}
            setModalType={setModalType}
            onDelete={(val) => {
              loadNegativeData();
            }}
          />
        ) : modalType === "add-negative" || modalType === "edit-negative" ? (
          <MobileAppModals
            open={modalOpen}
            setOpen={setModalOpen}
            setModalType={setModalType}
            onSave={() => {
              setModalOpen(false);
              loadNegativeData();
            }}
            editData={modalType === "edit-negative" ? singleData : []}
            title={modalTitle}
            modalType={modalType}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default LoanFilterLogic;
