/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { Modal, Form, Col, Row, Select, Input, message } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import { useEffect } from "react";
import _ from "lodash";
import { getAPIData } from "../../apiHelper";

const CollegeLimitModals = (props) => {
  const {
    open,
    title,
    setOpen,
    modalType,
    onFinishApi = () => {},
    collegeLimitData,
  } = props;

  const id = collegeLimitData?.id;
  const [form] = Form.useForm();
  const [collegeList, setCollegeList] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinish = (data) => {
    setSaveLoading(true);
    const endPoint =
      modalType === "new"
        ? "system-config/college-limit/create"
        : "system-config/college-limit/update/" + id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status) {
          setOpen(false);
          onFinishApi(result?.status);
          message.success(result?.message);
          setSaveLoading(false);
          form.resetFields();
        } else {
          setSaveLoading(false);
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  useEffect(() => {
    const loadCollegeList = () => {
      const endPoint = "system-config/college-list";
      const data = modalType === "new" ? { type: "new" } : { type: "edit" };

      getAPIData(endPoint, "get", data, false)
        .then((result) => {
          if (result?.status) {
            const clgData = result?.data;
            setCollegeList(clgData);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    };

    loadCollegeList();

    if (!_.isEmpty(collegeLimitData) && modalType === "edit") {
      form.setFieldsValue(collegeLimitData);
    }
  }, [collegeLimitData, form, modalType]);

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        footer={null}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={700}
      >
        {modalType === "new" || modalType === "edit" ? (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="College Name"
                  name="institute_id"
                  size="large"
                  rules={[
                    { required: true, message: "College name is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    style={{ width: "100%" }}
                    disabled={modalType === "new" ? false : true}
                    options={
                      !_.isEmpty(collegeList) &&
                      collegeList.map((data) => ({
                        label: data.college_name,
                        value: data.id,
                      }))
                    }
                    placeholder="Select college name"
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Limit"
                  name="limit"
                  size="large"
                  rules={[{ required: true, message: "limit is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter limit" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default CollegeLimitModals;
