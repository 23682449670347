import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Form, Input, message, Select } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const OnBoarding = () => {
  const [onBoarding] = Form.useForm();
  const [callNowform] = Form.useForm();
  const [callBackform] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  //Save btn
  const [saveOnBoardingLoading, setSaveOnBoardingLoading] = useState(false);
  const [saveCallNowNumberLoading, setSaveCallNowNumberLoading] =
    useState(false);
  const [saveCallBackNumberLoading, setSaveCallBackNumberLoading] =
    useState(false);

  const onFinishOnBoardingReq = (values) => {
    setSaveOnBoardingLoading(true);
    let data;
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "section",
        key: key,
        value: value,
      })
    );
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSaveOnBoardingLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedOnBoardingReq = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishCallNowReq = (values) => {
    setSaveCallNowNumberLoading(true);
    let data;
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "on_boarding",
        key: key,
        value: value,
      })
    );
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSaveCallNowNumberLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedCallNowReq = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishCallBackReq = (values) => {
    setSaveCallBackNumberLoading(true);
    let data;
    let system_arr = [];

    Object.entries(values).map(([key, value]) =>
      system_arr.push({
        category: "on_boarding",
        key: key,
        value: value,
      })
    );
    data = { system_data: system_arr };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          setSaveCallBackNumberLoading(false);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedCallBackReq = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const loadData = () => {
    const data = { category_name: "section" };
    const endPoint = "system-config/get-config-data";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map(async (data) => {
            onBoarding.setFieldsValue({ [data?.name]: data?.value });
          });
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadCallData = () => {
    const data = { category_name: "on_boarding" };
    const endPoint = "system-config/get-config-data";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map(async (data) => {
            callNowform.setFieldsValue({ [data?.name]: data?.value });
            callBackform.setFieldsValue({ [data?.name]: data?.value });
          });
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    loadData();
    loadCallData();
  }, [onBoarding, callNowform, callBackform]);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header className="site-layout-background main-header">
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Super Admin Dashboard
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 15,
              backgroundColor: "#DFFEFC",
            }}
          >
            <div className="white_div">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    SECTION
                  </Title>
                </Col>
                <Col span={12} className="poppins_medium boxshadow-div">
                  <Form
                    name="basic"
                    onFinish={onFinishOnBoardingReq}
                    onFinishFailed={onFailedOnBoardingReq}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={onBoarding}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Section Controller"
                          name="section_controller"
                          rules={[
                            {
                              required: true,
                              message: "Title  is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={[
                              {
                                value: "1",
                                label: "All Steps Required",
                              },
                              {
                                value: "0",
                                label: "Two Steps Required",
                              },
                            ]}
                            placeholder="Select section controller"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Section Opener"
                          name="section_opener"
                          rules={[
                            {
                              required: true,
                              message: "Title  is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={[
                              {
                                value: "1",
                                label: "Disable Steps",
                              },
                              {
                                value: "0",
                                label: "Open Steps",
                              },
                            ]}
                            placeholder="Select section opener"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveOnBoardingLoading}
                          block
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    Call Now
                  </Title>
                </Col>
                <Col span={12} className="poppins_medium boxshadow-div">
                  <Form
                    name="basic"
                    onFinish={onFinishCallNowReq}
                    onFinishFailed={onFailedCallNowReq}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={callNowform}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Call Now"
                          name="call_now"
                          rules={[
                            {
                              required: true,
                              message: "Call Now is required!",
                            },
                            {
                              message: "Please Enter Characters",
                              pattern: new RegExp(/[a-zA-Z]/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={[
                              {
                                value: "true",
                                label: "True",
                              },
                              {
                                value: "false",
                                label: "False",
                              },
                            ]}
                            placeholder="Select call  now"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Call Now Number (Pending)"
                          name="call_now_number"
                          rules={[
                            {
                              required: true,
                              message: "Call Now Number is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter call now number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveCallNowNumberLoading}
                          block
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title
                    level={5}
                    style={{ marginTop: "20px" }}
                    className="poppins_medium umoney_title"
                  >
                    Call Back List
                  </Title>
                </Col>
                <Col span={12} className="poppins_medium boxshadow-div">
                  <Form
                    name="basic"
                    onFinish={onFinishCallBackReq}
                    onFinishFailed={onFailedCallBackReq}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={callBackform}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Call Back List Student Id"
                          name="call_back_list_id_student"
                          rules={[
                            {
                              required: true,
                              message: "Call Back List Student Id is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter call back list student id"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Call Back List Salaried Id"
                          name="call_back_list_id_salaried"
                          rules={[
                            {
                              required: true,
                              message:
                                "Call Back List Salaried Id is required!",
                            },
                            {
                              message: "Please Enter Number",
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input
                            size="large"
                            placeholder="Enter call back list salaried id"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <CLoadingButton
                          htmlType="submit"
                          size="large"
                          value="SAVE"
                          className="poppins_medium umoney_btn"
                          loading={saveCallBackNumberLoading}
                          block
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default OnBoarding;
