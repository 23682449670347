/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  message,
  Switch,
  Input,
  Space,
  Button,
} from "antd";
import Sidebar from "../../components/Sidebar";
import {
  MenuOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MergeCellsOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import CommonModals from "../../components/Modals/CommonModals";
import CompanyModals from "../../components/Modals/College/CompanyModals";
import MergeModals from "../../components/Modals/College/MergeModals";
import { setHeader } from "../../api";
import Highlighter from "react-highlight-words";

const { Title } = Typography;
const { Header, Content } = Layout;

const Company = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [isMerge, setIsMerge] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const scroll = {};

  const [editId, setEditId] = useState("");
  const [pincodeList, setPincodeList] = useState();

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  //Opening Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const handleChange = (pagination, filters) => {
    console.log("Various parameters", pagination, filters);
    setFilteredInfo(filters);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };

  const openModal = (type, id, is_merge = false) => {
    setModalOpen(true);
    setModalType(type);
    if (type === "add-company") {
      setModalTitle("Add Company");
    } else if (type === "edit-company") {
      setEditId(id);
      setIsMerge(is_merge);
      setModalTitle("Edit Company");
    } else if (type === "delete") {
      setDeleteId(id);
      setModalTitle("Delete Confirmation");
    } else if (type === "merge-company") {
      setModalTitle("Merge Company");
    }
  };

  const no_of_employee = [
    {
      value: "0",
      label: "0-500",
      text: "0-500",
    },
    {
      value: "1",
      label: "501-1000",
      text: "501-1000",
    },
    {
      value: "2",
      label: "1001-5000",
      text: "1001-5000",
    },
    {
      value: "3",
      label: ">5000",
      text: ">5000",
    },
  ];

  const loadPincodes = () => {
    const endPoint = "template/pincodes";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var pincode_list = [];
          result?.data.map(async (pin, key) => {
            pincode_list.push({
              value: pin.id,
              label: pin.pincode,
              state: pin.state,
              district: pin.district,
            });
          });
          setPincodeList(pincode_list);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    loadCompanyData();
    loadPincodes();
  }, []);

  const loadCompanyData = () => {
    setLoading(true);
    const endPoint = "company/list";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTableData(response);
          setLoading(false);
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  //Set the values of Status in list
  const setValues = (company_id) => {
    let endPoint = `company/change-status/` + company_id;
    console.log("endPoint", endPoint);
    const header = setHeader();

    getAPIData(endPoint, "get", {}, header)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          loadCompanyData();
          // props?.setOpen(false);
          // setModalType("");
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const TableColumns = [
    {
      title: "COMPANY ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
    },
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      key: "company_name",
      filteredValue: filteredInfo.company_name || null,
      ...getColumnSearchProps("company_name"),
    },
    {
      title: "NUMBER OF EMPLOYEE",
      dataIndex: "no_of_employee",
      key: "no_of_employee",
      filteredValue: filteredInfo.no_of_employee || null,
      render: (text, record) => (
        <div>
          <span>
            {record?.no_of_employee === "0"
              ? "0-500"
              : record?.no_of_employee === "1"
              ? "501-1000"
              : record?.no_of_employee === "2"
              ? "1001-5000"
              : ">5000"}
          </span>
        </div>
      ),
      filters: no_of_employee,
      filterSearch: true,
      onFilter: (value, record) =>
        record.no_of_employee
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
      filteredValue: filteredInfo.no_of_employee || null,
    },
    {
      title: "DISTRICT",
      dataIndex: "district",
      key: "district",
      filteredValue: filteredInfo.district || null,
      ...getColumnSearchProps("district"),
    },
    {
      title: "STATE",
      dataIndex: "state",
      key: "state",
      filteredValue: filteredInfo.state || null,
      ...getColumnSearchProps("state"),
    },
    {
      title: "PINCODE",
      dataIndex: "pincode",
      key: "pincode",
      filteredValue: filteredInfo.pincode || null,
      ...getColumnSearchProps("pincode"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => {
        // 0=Inactive, 1=Active, 2=Pending, -1=Deleted
        return (
          <div>
            <span>
              {record.status !== "2" ? (
                <Switch
                  checked={record.status === "1" ? true : false}
                  onChange={() => {
                    setValues(record?.id);
                  }}
                />
              ) : (
                <MergeCellsOutlined
                  style={{
                    color: "#009D93",
                    height: "20px",
                    width: "20px",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => openModal("edit-company", record?.id, true)}
                />
              )}
            </span>
          </div>
        );
      },
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
      filteredValue: filteredInfo.status || null,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      filteredValue: filteredInfo.action || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-company", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            Company
          </Title>
        </Header>
        <Content
          className="site-layout-background white_div"
          style={{
            padding: "20px 15px",
            borderRadius: "5px",
          }}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={6}>
              <CLoadingButton
                size="large"
                value="Merge Company"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => openModal("merge-company")}
                icon={<MergeCellsOutlined />}
                block
              />
            </Col>
            <Col className="gutter-row" span={2}></Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="CLEAR FILTERS"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => clearFilters()}
                block
              />
            </Col>
            <Col className="gutter-row" span={8}>
              <CLoadingButton
                size="large"
                value="ADD"
                className="poppins_medium umoney_modal_filled_btn"
                onClick={() => openModal("add-company")}
                icon={<PlusOutlined />}
                block
              />
            </Col>
          </Row>

          <div className="umoney_table " style={{ marginTop: 20 }}>
            <Table
              pagination={{
                showSizeChanger: true,
                locale: { items_per_page: "" },
              }}
              columns={TableColumns}
              dataSource={tableData}
              scroll={scroll}
              onChange={handleChange}
            />
          </div>
          {modalType === "delete" ? (
            <CommonModals
              open={modalOpen}
              title="Delete Confirmation"
              type="company"
              setOpen={setModalOpen}
              modalType={modalType}
              id={deleteId}
              setModalType={setModalType}
              onDelete={(val) => {
                loadCompanyData();
              }}
            />
          ) : modalType === "add-company" ? (
            <CompanyModals
              pincodeList={pincodeList}
              no_of_employee={no_of_employee}
              modalTitle={modalTitle}
              setModalType={setModalType}
              modalType={modalType}
              onSave={() => {
                setModalType(false);
                loadCompanyData();
              }}
            />
          ) : modalType === "edit-company" ? (
            <CompanyModals
              pincodeList={pincodeList}
              no_of_employee={no_of_employee}
              modalTitle={modalTitle}
              modalType={modalType}
              editId={editId}
              isMerge={isMerge}
              setModalType={setModalType}
              onSave={() => {
                setModalType(false);
                loadCompanyData();
              }}
            />
          ) : modalType === "merge-company" ? (
            <MergeModals
              modalTitle={modalTitle}
              modalType={modalType}
              setModalType={setModalType}
              type="company"
              onSave={() => {}}
            />
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </div>
  );
};

/*
async function findInArray(array, label, key) {
  return array.find((element) => {
    console.log("element--->", element);
    return element.label === label;
  });
}
*/

export default Company;
