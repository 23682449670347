import React, { useState, useEffect } from "react";
import { Typography, Table, Row, Col, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import CommonModals from "../../../components/Modals/CommonModals";
import TermsAndConditionModals from "../../../components/Modals/TermsAndConditionModals";

const TermsAndCondition = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  // Modal States
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [termsEditData, setTermsEditData] = useState();

  // Terms States
  const [termsData, setTermsData] = useState([]);
  const [termsDeleteId, setTermsDeleteId] = useState(Number);

  const openModal = (type, id, record) => {
    setModalType(type);
    if (type === "add-term") {
      setModalTitle("Add Term");
    } else if (type === "edit-term") {
      setTermsEditData(record);
      setModalTitle("Edit Term");
    }
  };

  const loadTermList = () => {
    const endPoint = "system-config/signup_terms/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          setTermsData(result?.data);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    loadTermList();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "20%",
    },
    {
      title: "Desc",
      dataIndex: "desc",
      key: "desc",
      width: "50%",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // termsEdit(record?.id, record?.name);
                openModal("edit-term", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteType("signup_terms");
                setTermsDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row style={{ marginTop: "20px" }} gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Title level={5} className="poppins_medium umoney_title">
                  TERMS
                </Title>
              </Col>
              <Col className="gutter-row" span={6}>
                <CLoadingButton
                  size="large"
                  value="ADD"
                  icon={<PlusOutlined />}
                  className="poppins_medium umoney_btn"
                  block
                  onClick={() => openModal("add-term")}
                />
              </Col>
            </Row>

            <Table
              dataSource={termsData}
              className="poppins_regular"
              pagination={false}
              columns={columns}
              rowClassName="device-change-panel-table"
              size="small"
              style={{
                marginTop: "20px",
                // whiteSpace: "pre",
                whiteSpace: "pre-wrap",
                textOverflow: "ellipsis",
                boxShadow: "0px 0px 6px #00000029",
                width: "50%",
              }}
            />
          </div>
        </Content>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            setOpen={setModalOpen}
            title={"Delete Terms"}
            type={deleteType}
            modalType={modalType}
            setModalType={setModalType}
            id={termsDeleteId}
            onDelete={(val) => {
              loadTermList();
            }}
          />
        ) : modalType === "add-term" ? (
          <TermsAndConditionModals
            modalTitle={modalTitle}
            setModalType={setModalType}
            modalType={modalType}
            onSave={() => {
              setModalType(false);
            }}
            loadTermList={loadTermList}
          />
        ) : modalType === "edit-term" ? (
          <TermsAndConditionModals
            termsEditData={termsEditData}
            modalTitle={modalTitle}
            modalType={modalType}
            setModalType={setModalType}
            onSave={() => {
              setModalType(false);
            }}
            loadTermList={loadTermList}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default TermsAndCondition;
