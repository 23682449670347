import antdImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import CLoadingButton from "../InputFields/CLoadingButton";

const DesignationModals = ({
  modalType,
  modalTitle,
  setModalType,
  setEditCancleIcon,
  setIsEdit,
  setEditId,
  editCancleIcon,
  loadData,
  editData,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [depData, setDepData] = useState([]);

  const [form] = Form.useForm();

  const handleClose = () => {
    setModalType(false);
  };

  const closeEdit = () => {
    form.resetFields();
    setEditCancleIcon(false);
    setIsEdit(false);
    setEditId(Number);
  };

  const loadDepartmentData = () => {
    const endPoint = "master/department-list";

    let name = [];
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          result?.data.map((e) =>
            name.push({ value: e?.id, label: e.name, text: e.name })
          );
          setDepData(name);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinish = (data) => {
    setSaveLoading(true);
    const endPoint =
      modalType === "add-designation"
        ? "master/designation-create"
        : "master/designation-update/" + editData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          handleClose();
          loadData();
        } else {
          message.warning(result?.message);
        }
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        message.error(err);
      });
  };

  const onFailed = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  useEffect(() => {
    if (modalType === "add-designation") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }
    loadDepartmentData();
  }, [form, editData, modalType]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Designation"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Designation is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter designation name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Department"
                name="department_id"
                rules={[
                  {
                    required: true,
                    message: "Department is required!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select department"
                  options={depData}
                  // onClick={handlechange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              {editCancleIcon ? (
                <CloseCircleOutlined
                  onClick={closeEdit}
                  style={{ marginTop: 13, marginLeft: 20 }}
                />
              ) : (
                ""
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                className="poppins_medium umoney_btn"
                block
                loading={saveLoading}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default DesignationModals;
