import { Col, Form, Input, message, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import TextArea from "antd/lib/input/TextArea";

const RemoveImage = ({
  user_id,
  type,
  handleClose,
  pageType,
  isSuccess,
  bank_id,
}) => {
  const [rejectedReasonsData, setRejectedReasonData] = useState();
  const [dropDownData, setDropDownData] = useState();
  const [loading, setLoading] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [form] = Form.useForm();

  const submitData = (data) => {
    setSaveBtnLoading(true);
    const dataRemoveImage =
      type === "kyc_frontImg" || type === "kyc_backImg"
        ? { ...data, user_id, type }
        : type === "pan-image"
        ? { ...data, user_id }
        : type === "selfie"
        ? { ...data, user_id }
        : type === "std_id_frontImg" || type === "std_id_backImg"
        ? { ...data, user_id, type }
        : type === "video"
        ? { ...data, user_id }
        : type === "marksheet"
        ? { ...data, user_id }
        : type === "bank_statement"
        ? { ...data, user_id, bank_id }
        : type === "emp-doc"
        ? { ...data, user_id }
        : type === "cash-doc"
        ? { ...data, user_id }
        : type === "cash-form"
        ? { ...data, user_id }
        : "";
    const endPoint =
      type === "kyc_frontImg" || type === "kyc_backImg"
        ? "steps/reject-kyc-image"
        : type === "pan-image"
        ? "steps/reject-pan-image"
        : type === "selfie"
        ? "steps/reject-selfie-image"
        : type === "std_id_frontImg" || type === "std_id_backImg"
        ? "steps/reject-student-card-image"
        : type === "video"
        ? "steps/reject-selfie-video"
        : type === "marksheet"
        ? "steps/reject-student-marksheet"
        : pageType === "salaried" && type === "kyc_frontImg"
        ? "steps/reject-kyc-image"
        : pageType === "salaried" && type === "kyc_backImg"
        ? "steps/reject-kyc-image"
        : pageType === "salaried" && type === "pan-image"
        ? "steps/reject-pan-image"
        : pageType === "salaried" && type === "selfie"
        ? "steps/reject-selfie-image"
        : pageType === "salaried" && type === "video"
        ? "steps/reject-selfie-video"
        : pageType === "salaried" && type === "bank_statement"
        ? "steps/reject-bank-img"
        : pageType === "salaried" && type === "emp-doc"
        ? "steps/reject-emp-images"
        : pageType === "salaried" && type === "cash-doc"
        ? "steps/reject-cash-img"
        : pageType === "salaried" && type === "cash-form"
        ? "steps/reject-form"
        : "";
    getAPIData(
      endPoint,
      "post",
      dataRemoveImage,
      false,
      pageType === "salaried" ? "salaried" : "student"
    )
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          isSuccess(true);
          form.resetFields();
          setSaveBtnLoading(false);
          handleClose();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const rejectReasonsData = () => {
    const endpoint = "system-config/image-rejected-reason/list";
    let reasonsList = [];
    getAPIData(endpoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setRejectedReasonData(response);
          response.map((data) => {
            reasonsList.push({
              value: data?.id,
              label: data?.title,
            });
          });
          setDropDownData(reasonsList);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    rejectReasonsData();
  }, []);

  // console.log(dropDownData);

  return (
    <div className="p-10">
      <Form
        name="basic"
        onFinish={submitData}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Rejection Reasons"
              name="reason_id"
              size="large"
              rules={[{ required: true, message: "Selection is required!" }]}
              className="poppins_medium mb-35"
            >
              <Select size="large" options={dropDownData} placeholder="Select rejection reasons" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Comment"
              name="reject_comment"
              size="large"
              rules={[{ required: true, message: "Comment is required!" }]}
              className="poppins_medium mb-35"
            >
              <TextArea size="large" type="text" rows={4} placeholder="Enter comment" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                htmlType="submit"
                value="SUBMIT"
                className="poppins_medium umoney_btn"
                block
                loading={saveBtnLoading}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RemoveImage;
