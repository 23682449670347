import { Col, Layout, Row, Typography } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { MenuOutlined } from "@ant-design/icons";
import CSelectBtn from "../../components/InputFields/CSelectBtn";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import TableComponent from "./Components/TableComponent";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const { Title } = Typography;

const agentData = [
  {
    value: "Joydeep",
    label: "Joydeep",
  },
  {
    value: "Dewesh",
    label: "Dewesh",
  },
];

const asignData = [
  {
    value: "Joydeep",
    label: "Joydeep",
  },
  {
    value: "Dewesh",
    label: "Dewesh",
  },
];

const ListView = () => {
  const role = useSelector((state) => state.userData.accessRoleCode);
  console.log(role);

  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [totalCount, setTotalCount] = useState();
  const [pendingCount, setPendingCount] = useState();
  const [rejectedCount, setRejectedCount] = useState();
  const [approvedCount, setApprovedCount] = useState();

  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const pathType = pathName[2];
  console.log("pathType", pathType);

  return pageLoading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            // height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title">
                {role}'s List View
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: 15,
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div">
                {role === "caller" ? (
                  <>
                    <Row gutter={[16, 16]} className="mt-20">
                      <Col span={24}>
                        <Title level={4} className="poppins_semi umoney_title ">
                          Allocated to me:
                        </Title>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[16, 16]}>
                          <Col span={6}>
                            <CLoadingButton
                              size="large"
                              value="Total: "
                              className="poppins_medium umoney_outlined_btn"
                              block
                              countval={totalCount}
                            />
                          </Col>
                          <Col span={6}>
                            <CLoadingButton
                              size="large"
                              value="Pending: "
                              className="poppins_medium umoney_outlined_btn"
                              block
                              countval={pendingCount}
                            />
                          </Col>
                          <Col span={6}>
                            <CLoadingButton
                              size="large"
                              value="Rejected: "
                              className="poppins_medium umoney_outlined_btn"
                              countval={rejectedCount}
                              block
                            />
                          </Col>
                          <Col span={6}>
                            <CLoadingButton
                              size="large"
                              value="FORWARDED TO APPROVER: "
                              className="poppins_medium umoney_outlined_btn"
                              countval="xx"
                              block
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : role === "approver" ? (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <Title level={4} className="poppins_semi umoney_title ">
                          Allocated to me:
                        </Title>
                      </Col>
                      <Col span={8}></Col>
                      <Col span={8}></Col>

                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Checked: "
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Sent to Caller: "
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Completed: "
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Sent for calling: "
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={approvedCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                    </Row>
                  </>
                ) : role === "supervisor_caller" ||
                  role === "supervisor_approver" ||
                  role === "superadmin" ? (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <CSelectBtn
                          options={agentData}
                          size="large"
                          classes="poppins_regular filter_select_border"
                          defaultValue="lucy"
                          btnvalue="AGENT"
                        />
                      </Col>
                      <Col span={6}>
                        <CSelectBtn
                          options={asignData}
                          size="large"
                          classes="poppins_regular filter_select_border"
                          defaultValue="lucy"
                          btnvalue="ASSiGN TO"
                        />
                      </Col>
                      <Col span={12}></Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={approvedCount}
                          block
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                <div className="mt-20 ">
                  <TableComponent
                    role={role}
                    setTotalCount={setTotalCount}
                    setApprovedCount={setApprovedCount}
                    setPendingCount={setPendingCount}
                    setRejectedCount={setRejectedCount}
                    pathType={pathType}
                  />
                </div>
              </div>
              {role === "caller" && (
                <>
                  <div className="white_div mt-20">
                    <Row gutter={[16, 16]} className="mt-20">
                      <Col span={24}>
                        <Title level={4} className="poppins_semi umoney_title ">
                          Forwarded to Approver:
                        </Title>
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={totalCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Back to caller: "
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="With Approver: "
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={approvedCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejectedCount}
                          block
                        />
                      </Col>
                      <Col span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending: "
                          className="poppins_medium umoney_outlined_btn"
                          countval={pendingCount}
                          block
                        />
                      </Col>
                    </Row>
                    <div className="mt-20">
                      <TableComponent
                        role={role}
                        setTotalCount={setTotalCount}
                        setApprovedCount={setApprovedCount}
                        setPendingCount={setPendingCount}
                        setRejectedCount={setRejectedCount}
                        pathType={pathType}
                      />
                    </div>
                  </div>
                </>
              )}
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default ListView;
