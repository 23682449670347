import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import { CloseCircleOutlined } from "@ant-design/icons";
import CLoadingButton from "../InputFields/CLoadingButton";

const DocumentTypeModals = ({
  modalType,
  modalTitle,
  setModalType,
  isEdit,
  editId,
  setEditCancleIcon,
  setIsEdit,
  setEditId,
  editCancleIcon,
  loadDocumentTypeData,
  editData,
}) => {
  const [saveDocumentBtnLoading, setSaveDocumentBtnLoading] = useState(false);

  const closeEdit = () => {
    form.resetFields();
    setEditCancleIcon(false);
    setIsEdit(false);
    setEditId(Number);
  };
  const [form] = Form.useForm();

  const handleClose = () => {
    setModalType(false);
  };

  console.log("editId", editData?.id);
  console.log(modalType);

  const onFinishDocumentType = (data) => {
    setSaveDocumentBtnLoading(true);

    const endPoint =
      modalType === "add-document"
        ? "system-config/document-type/create"
        : "system-config/document-type/update/" + editData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveDocumentBtnLoading(false);
        if (result?.status === true) {
          message.success(result?.message);
          if (isEdit) {
            setEditCancleIcon(false);
            setIsEdit(false);
            setEditId(Number);
          }
          form.resetFields();
          loadDocumentTypeData();
          handleClose();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFailedDocumentType = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (modalType === "add-document") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }
  }, [form, editData, modalType]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={onFinishDocumentType}
          onFinishFailed={onFailedDocumentType}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Enter Type"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Document type is required!",
                  },
                  {
                    message: "Please Enter Only Characters",
                    pattern: new RegExp(/[a-zA-Z]/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter type"
                  suffix={
                    editCancleIcon ? (
                      <CloseCircleOutlined onClick={closeEdit} />
                    ) : (
                      ""
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                className="poppins_medium umoney_btn"
                loading={saveDocumentBtnLoading}
                block
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default DocumentTypeModals;
