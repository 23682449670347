import React, { useState, useEffect, useRef } from "react";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { Typography } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import { Switch, Table, Row, Col, message, Input, Space, Button } from "antd";
import { getAPIData } from "../../apiHelper";
import { useSelector } from "react-redux";
import { setHeader } from "../../api";
import _ from "lodash";
import UserManagementModals from "../../components/Modals/UserManagementModals";
import CommonModals from "../../components/Modals/CommonModals";
import CClickLoading from "../../components/InputFields/CClickLoading";
import Highlighter from "react-highlight-words";

const UserManagement = (props) => {
  const auth = useSelector((state) => state.auth);
  const token = auth?.accessToken;
  const data = { token };

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [userData, setUserData] = useState([]);

  const [response, setResponse] = useState("");

  const { Title } = Typography;
  const scroll = {};

  //Opening Modal
  const [modalType, setModalType] = useState("");

  // Delete modal
  const [deleteId, setDeleteId] = useState("");
  const [tableData, setTableData] = useState([]);

  // Edit user
  const [roleVal, setRoleVal] = useState([]);
  const [managerVal, setManagerVal] = useState([]);

  // Modal States
  const [modalOpen, setModalOpen] = useState(false);

  // Common Data States
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const openModal = (type, id) => {
    if (type === "add-user") {
      setModalType(type);
      setModalOpen(true);
    } else if (type === "edit-user") {
      loadEditUserData(id);
      setModalType(type);
      setModalOpen(true);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalOpen(true);
    }
  };

  const loadUserData = () => {
    setLoading(true);
    const endPoint = "user-agent/agent-list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setResponse(response);
          setTableData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadEditUserData = (id) => {
    const endPoint = "user-agent/get-agent/" + id;
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data?.agentData;
          setUserData(response);
          setRoleVal(result?.data?.roleOptions);
          setManagerVal(result?.data?.managerOptions);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const loadCommonData = () => {
    const endPoint = "user-agent/common-data";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;

          // setDepartments(response?.departments);

          var role = response?.roles;
          var department = response?.departments;
          let array = [];
          let depArray = [];
          for (let i = 0; i < role.length; i++) {
            if (i >= 2) {
              array.push({
                value: role[i]?.name,
                title: role[i]?.name,
                label: role[i]?.name,
                text: role[i]?.name,
              });
            }
          }

          for (let i = 0; i < department.length; i++) {
            depArray.push({
              value: department[i]?.title,
              title: department[i]?.title,
              label: department[i]?.title,
              text: department[i]?.title,
            });
          }
          setRoles(array);
          setDepartments(depArray);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const TableColumns = [
    {
      title: "USER ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "8%",
      filteredValue: filteredInfo.user_id || null,
      sorter: (a, b) => a.user_id - b.user_id,
    },
    {
      title: "NAME",
      dataIndex: "full_name",
      key: "full_name",
      width: "10%",
      filteredValue: filteredInfo.full_name || null,
      ...getColumnSearchProps("full_name"),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: "20%",
      filteredValue: filteredInfo.email || null,
      ...getColumnSearchProps("email"),
    },
    {
      title: "ROLE",
      dataIndex: "role",
      key: "role",
      width: "10%",
      filters: roles,
      filterSearch: true,
      filteredValue: filteredInfo.role || null,
      onFilter: (value, record) => record.role.toString().includes(value),
    },
    {
      title: "USERNAME",
      dataIndex: "username",
      key: "username",
      width: "7%",
      filteredValue: filteredInfo.username || null,
      ...getColumnSearchProps("username"),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phonenumber",
      key: "phonenumber",
      width: "10%",
      filteredValue: filteredInfo.phonenumber || null,
      ...getColumnSearchProps("phonenumber"),
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      width: "10%",
      filteredValue: filteredInfo.manager || null,
      ...getColumnSearchProps("manager"),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: "10%",
      filters: departments,
      filterSearch: true,
      filteredValue: filteredInfo.department || null,
      onFilter: (value, record) => record.department.toString().includes(value),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "5%",
      align: "center",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => (
        <div>
          <span>
            <Switch
              checked={record.status === "1" ? true : false}
              onChange={() => {
                setValues(record, record?.user_id);
              }}
            />
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Active", text: "Active" },
        { value: "0", label: "Inactive", text: "Inactive" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: "10%",
      align: "center",
      filteredValue: filteredInfo.action || null,
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("edit-user", record?.user_id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", record?.user_id)}
            />
          </span>
        </div>
      ),
    },
  ];

  //Set the values of Status in list
  const setValues = (item, user_id) => {
    let dummyArray = [...response];
    if (!_.isEmpty(item)) {
      var n_index = dummyArray.findIndex((key) => key.user_id === user_id);

      dummyArray[n_index].status =
        dummyArray[n_index].status === "1" ? "0" : "1";
    }

    setResponse(dummyArray);
    if (data) {
      let endPoint = `user-agent/change-status-agent/` + user_id;
      const header = setHeader();

      getAPIData(endPoint, "get", {}, header)
        .then((result) => {
          if (result?.status === true) {
            message.success(result?.message);
            // props?.setOpen(false);
            // setModalType("");
          } else {
            message.warning(result?.message);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      console.log("Error");
    }
  };

  useEffect(() => {
    loadUserData();
    loadCommonData();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div>
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              backgroundColor: "#009D93",
              borderRadius: "15px 0 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
            <Title level={4} className="poppins_regular umoney_page_title">
              {" "}
              Super Admin Dashboard
            </Title>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              padding: 15,
              backgroundColor: "#DFFEFC",
            }}
          >
            <div className="white_div">
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={8}></Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="CLEAR FILTERS"
                    className="poppins_medium umoney_btn"
                    onClick={() => clearFilters()}
                    block
                  />
                </Col>
                <Col className="gutter-row" span={8}>
                  <CLoadingButton
                    size="large"
                    value="ADD USER"
                    icon={<PlusOutlined />}
                    className="poppins_medium umoney_btn"
                    onClick={() => openModal("add-user")}
                    block
                  />
                </Col>
              </Row>
              <div className="umoney_table mt-20">
                <Table
                  pagination={{
                    showSizeChanger: true,
                    locale: { items_per_page: "" },
                  }}
                  columns={TableColumns}
                  dataSource={tableData}
                  scroll={scroll}
                  bordered
                  onChange={handleChange}
                />
              </div>
            </div>
          </Content>
        </Layout>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            title="Delete Confirmation"
            type="agent"
            setOpen={setModalOpen}
            modalType={modalType}
            id={deleteId}
            setModalType={setModalType}
            onDelete={(val) => {
              loadUserData();
            }}
          />
        ) : (
          ""
        )}

        {modalType === "add-user" || modalType === "edit-user" ? (
          <UserManagementModals
            open={modalOpen}
            title={modalType === "add-user" ? "Add User" : "Edit User"}
            setOpen={setModalOpen}
            modalType={modalType}
            userData={userData}
            onFinishApi={(val) => {
              loadUserData();
            }}
            editRoleVal={roleVal}
            editManagerVal={managerVal}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default UserManagement;
