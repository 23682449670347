import React, { useEffect, useState } from "react";
import { Input, Typography } from "antd";

const CTextField = (props) => {
  const { Text } = Typography;
  const [changeValue, setChangeValue] = useState("");

  const handleChange = (e) => {
    const { value } = e?.target;
    setChangeValue(value);
  };

  useEffect(() => {
    props?.setvalue(changeValue);
  }, [changeValue, props]);

  return (
    <div className="umoney-input">
      <Text className="poppins_semi"> {props?.label} </Text>
      <Input {...props} onChange={handleChange} />
    </div>
  );
};

export default CTextField;
