import React from "react";
import { Button } from "antd";

const CLoadingButton = (props) => {
  return (
    <Button {...props}>
      {props?.value} {props?.countval}
    </Button>
  );
};

export default CLoadingButton;
