const dev_mode = "live";
export const api_url =
  dev_mode === "live"
    ? "https://adminapi-dev.urban-money.com/"
    : "http://localhost:4001/";
export const user_url =
  dev_mode === "live"
    ? "https://userapi-dev.urban-money.com/"
    : "http://localhost:4002/";
export const student_url =
  dev_mode === "live"
    ? "https://studentapi-dev.urban-money.com/"
    : "http://localhost:4003/";
export const loan_url =
  dev_mode === "live"
    ? "https://loanapi-dev.urban-money.com/"
    : "http://localhost:4004/";
export const bank_url =
  dev_mode === "live"
    ? "https://bankapi-dev.urban-money.com/"
    : "http://localhost:4005/";
export const coin_url =
  dev_mode === "live"
    ? "https://coinapi-dev.urban-money.com/"
    : "http://localhost:4006/";
export const bank_acc_url =
  dev_mode === "live"
    ? "https://bankadminapi-dev.urban-money.com/"
    : "http://localhost:4007/";
export const device_change_url =
  dev_mode === "live"
    ? "https://deviceapi-dev.urban-money.com/"
    : "http://localhost:4008/";
export const student_cc_url =
  dev_mode === "live"
    ? "https://studentccapi-dev.urban-money.com/"
    : "http://localhost:4009/";
export const apply_loan_url =
  dev_mode === "live"
    ? "https://applyloanapi-dev.urban-money.com/"
    : "http://localhost:4010/";
export const salaried_url =
  dev_mode === "live"
    ? "https://salariedapi-dev.urban-money.com/"
    : "http://localhost:4011/";
export const saltostu_url =
  dev_mode === "live"
    ? "https://stutosalapi-dev.urban-money.com/"
    : "http://localhost:4014/";
export const credit_check_url =
  dev_mode === "live"
    ? "https://ccadminapi-dev.urban-money.com/"
    : "http://localhost:4015/";
export const stdtosal_url =
  dev_mode === "live"
    ? "https://stosadminapi-dev.urban-money.com/"
    : "http://localhost:4018/";

export const setHeader = () => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return headers;
};

export const setHeaderForFile = () => {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return headers;
};
