import React, { useEffect, useState } from "react";
import { Modal, Form, Col, Row, Input, Select, message } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";

const MobileAppModals = (props) => {
  const {
    title,
    open,
    setOpen,
    modalType,
    smsData,
    editData,
    onSave,
    modalWidth,
  } = props;

  const id = smsData?.id;
  const [form] = Form.useForm();
  const [Negativeform] = Form.useForm();
  const { TextArea } = Input;

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const smsModuleData = [
    {
      value: "1",
      label: "KYC Details",
    },
    {
      value: "2",
      label: "PAN Details",
    },
    {
      value: "3",
      label: "Personal Information Details",
    },
    {
      value: "4",
      label: "Student Information Details",
    },
    {
      value: "5",
      label: "Selfie  Details",
    },
    {
      value: "6",
      label: "Video Details",
    },
    {
      value: "7",
      label: "Credit Checks",
    },
  ];

  const onFinish = (data) => {
    setSaveBtnLoading(true);

    const endPoint =
      modalType === "add-sms-content"
        ? "template/sms-create"
        : "template/sms-update/" + id;

    data.dynamic_data_param = "1";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          form.resetFields();
          setOpen(false);
          onSave(result?.status);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onInterestFinish = (data) => {
    setSaveBtnLoading(true);
    let id = editData?.id;
    console.log("id", id);
    const endPoint =
      modalType === "add-ext-interest"
        ? "system-config/ext-interest-rate/create"
        : "system-config/ext-interest-rate/update/" + id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          form.resetFields();
          setOpen(false);
          onSave(result?.status);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  //Save the Negative Words and Apps
  const onFinishNegative = (data) => {
    let id = editData?.id;
    setSaveBtnLoading(true);

    const endPoint =
      modalType === "add-negative"
        ? "system-config/negative/create"
        : "system-config/negative/update/" + id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          form.resetFields();
          setOpen(false);
          onSave(result?.status);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onFinishFailedNegative = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  const typeList = [
    {
      value: "0",
      label: "Words",
    },
    {
      value: "1",
      label: "Apps",
    },
  ];

  const sms_type = [
    {
      value: "1",
      label: "Registration",
    },
    {
      value: "2",
      label: "Bank",
    },
    {
      value: "3",
      label: "UPI",
    },
    {
      value: "4",
      label: "Help",
    },
    {
      value: "5",
      label: "Collection",
    },
    {
      value: "6",
      label: "Loan filter",
    },
    {
      value: "7",
      label: "Device change",
    },
    {
      value: "8",
      label: "Salary credit check extended",
    },
    {
      value: "9",
      label: "Loan extension",
    },
    {
      value: "10",
      label: "Credit Check",
    },
  ];

  const handleClose = () => {
    console.log("closed");
    form.resetFields();
    setOpen(false);
    //setModalType("add-sms-content");
  };

  useEffect(() => {
    if (modalType === "add-sms-content") {
      console.log("modalType", modalType);
      form.resetFields();
    } else {
      form.setFieldsValue(smsData);
    }

    if (modalType === "add-ext-interest") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }

    if (modalType === "add-negative") {
      Negativeform.resetFields();
    } else {
      Negativeform.setFieldsValue(editData);
    }

    if (!_.isEmpty(smsData) && modalType === "add-sms-content") {
      form.resetFields();
    }
  }, [form, smsData, editData, modalType, Negativeform]);

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        footer={null}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={modalWidth}
      >
        {modalType === "add-sms-content" || modalType === "edit-sms-content" ? (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={modalType === "edit-sms-content" ? smsData : ""}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="SMS type"
                  name="type"
                  rules={[{ required: true, message: "SMS type is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    placeholder="Select sms type"
                    options={sms_type}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Module"
                  name="module"
                  rules={[{ required: true, message: "Module is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    placeholder="Select module"
                    options={smsModuleData}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Template Header (Slug)"
                  name="slug"
                  rules={[
                    { required: true, message: "Template Header is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    placeholder="Enter template header"
                    disabled={modalType === "add-sms-content" ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Content"
                  name="message_body"
                  rules={[
                    { required: true, message: "Content is required!" },
                    //{ whitespace: true, message: "This field can not contain whitespaces." }
                  ]}
                  className="poppins_medium mb-35"
                >
                  <TextArea rows={4} placeholder="Enter content" size="large" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : modalType === "add-new-loan" || modalType === "edit-new-loan" ? (
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Minimum days"
                  name="min_days"
                  rules={[
                    { required: true, message: "Minimum days is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter minimum days" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Maximum days"
                  name="max_days"
                  rules={[
                    { required: true, message: "Maximum days is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter maximum days" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Interest rate"
                  name="interest_rate"
                  rules={[
                    { required: true, message: "Interest rate is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter interest rate" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : modalType === "add-call" ? (
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Calls"
                  name="calls"
                  rules={[
                    { required: true, message: "Calls is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter calls" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : modalType === "add-ext-interest" ||
          modalType === "edit-ext-interest" ? (
          <Form
            name="basic"
            onFinish={onInterestFinish}
            onFinishFailed={onFinishFailed}
            initialValues={modalType === "edit-ext-interest" ? editData : ""}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Minimum days"
                  name="min_days"
                  rules={[
                    { required: true, message: "Minimum days is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter minimum days" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Maximum days"
                  name="max_days"
                  rules={[
                    { required: true, message: "Maximum days is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter maximum days" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Interest rate"
                  name="interest_rate"
                  rules={[
                    { required: true, message: "Interest rate is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter interest rate" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : modalType === "add-negative" || modalType === "edit-negative" ? (
          <Form
            name="basic"
            onFinish={onFinishNegative}
            onFinishFailed={onFinishFailedNegative}
            initialValues={modalType === "Edit" ? smsData : ""}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={Negativeform}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Names"
                  name="name"
                  rules={[{ required: true, message: "Names is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter names" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: "Type is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    style={{ width: "100%" }}
                    disabled={modalType === "add-negative" ? false : true}
                    options={typeList}
                    placeholder="Enter type"
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default MobileAppModals;
