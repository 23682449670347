import { message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../../apiHelper";
import {
  CheckSquareOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined,
  BorderOutlined,
} from "@ant-design/icons";

const columns = [
  {
    title: "MODULE",
    dataIndex: "module",
    key: "module",
  },
  {
    title: "PERMISSION",
    dataIndex: "permission",
    key: "permission",
    align: "center",
  },
  {
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
];

const PhonePermission = (props) => {
  const { userId, deviceId } = props;
  console.log("props===========>", props);
  const [permissionData, setPermissionData] = useState({});
  const [tableLoading, setTableLoading] = useState(false);

  const tableData = [
    {
      key: 1,
      module: "Location",
      permission: (
        <div>
          <span>
            {permissionData?.location === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 2,
      module: "Contacts",
      permission: (
        <div>
          <span>
            {permissionData?.contacts === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "Extract",
    },
    {
      key: 3,
      module: "SMS",
      permission: (
        <div>
          <span>
            {permissionData?.sms === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 4,
      module: "Phone",
      permission: (
        <div>
          <span>
            {permissionData?.phone === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 5,
      module: "Call Logs",
      permission: (
        <div>
          <span>
            {permissionData?.call_logs === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 6,
      module: "Camera",
      permission: (
        <div>
          <span>
            {permissionData?.camera === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 7,
      module: "Storage",
      permission: (
        <div>
          <span>
            {permissionData?.storage === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "",
    },
    {
      key: 8,
      module: "Installed Applications",
      permission: (
        <div>
          <span>
            {permissionData?.install_apps === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.install_apps === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.install_apps === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.install_apps === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
      action: "Extract",
    },
    {
      key: "9",
      module: "Audio",
      permission: (
        <div>
          <span>
            {permissionData?.audio === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.audio === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.audio === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.audio === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const loadPermissionData = async () => {
      setTableLoading(true);
      const endPoint = "device/get-device-permission-list";
      const data = { user_id: userId, device_id: deviceId };

      getAPIData(endPoint, "post", data, false, "device_change")
        .then((result) => {
          if (result?.status) {
            setPermissionData(result?.data);
            setTableLoading(false);
          } else {
            setPermissionData({});
            message.warning(result?.message);
            setTableLoading(false);
          }
          setTableLoading(false);
        })
        .catch((err) => {
          message.error(err);
          setTableLoading(false);
        });
    };
    loadPermissionData();
  }, [deviceId, props, userId]);

  return (
    <div className="umoney_table">
      <Table
        dataSource={tableData}
        pagination={false}
        columns={columns}
        loading={tableLoading}
      />
    </div>
  );
};

export default PhonePermission;
