import React, { useState, useEffect } from "react";
import CLoadingButton from "../../InputFields/CLoadingButton";
import {
  Typography,
  Layout,
  Col,
  Row,
  Button,
  Select,
  Table,
  Modal,
  message,
  Input,
  Tag,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import UpdateDetailsForOther from "../UpdateDetailsForOther";
import { getAPIData } from "../../../apiHelper";
import PhonePermission from "./PhonePermission";
import Algo from "./Algo";
import TargetDevice from "./TargetDevice";
import _ from "lodash";
import axios from "axios";
import { EditOutlined } from "@ant-design/icons";
import UpdateDetailsForFatherDevice from "./UpdateDetailsForFatherDevice";
import UpdateDetailForMotherDevice from "./UpdateDetailsForMotherDevice";
import UpdateDetailForOtherDevice from "./UpdateDetailsForOtherDevice";

const { Search } = Input;

const matchUserColumns = [
  {
    title: "RELATION",
    dataIndex: "relation_name",
    key: "relation_name",
  },
  {
    title: "NUMBER",
    dataIndex: "contact_no",
    key: "contact_no",
  },
  {
    title: "SAVED AS",
    dataIndex: "saved_as",
    key: "saved_as",
  },
  {
    title: "PRIMARY USER (undone)",
    dataIndex: "primaryuser",
    key: "primaryuser",
  },
  {
    title: "DEVICE ID",
    dataIndex: "device_model",
    key: "device_model",
  },
];

const parentColumns = [
  {
    title: "NAME",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "NUMBER",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "DEVICE DETAILS",
    dataIndex: "details",
    key: "details",
    render: (text, record) => (
      <div>
        <span>
          Model Company: {record?.model_company} <br />
          Device Model: {record?.device_model} <br />
          Device Model Id: {record?.device_model_id}
        </span>
      </div>
    ),
  },
  {
    title: "PROBABLE RELATION",
    dataIndex: "relation_name",
    key: "relation_name",
  },
];

const searchColumns = [
  {
    title: "SAVED AS",
    dataIndex: "savedAs",
    key: "savedAs",
  },
  {
    title: "PRIMARY USER (undone)",
    dataIndex: "primary",
    key: "primary",
  },
  {
    title: "IS PRIMARY",
    dataIndex: "is_primary",
    key: "is_primary",
  },
  {
    title: "LINKED",
    dataIndex: "linked",
    key: "linked",
  },
];

const loggedInColumns = [
  {
    title: "DEVICE ID",
    dataIndex: "device_id",
    key: "device_id",
  },
  {
    title: "PRIMARY",
    dataIndex: "is_primary",
    key: "is_primary",
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "SAVED AS",
    dataIndex: "savedAs",
    key: "savedAs",
  },
  {
    title: "LAST CALL TIMESTAMP (undone)",
    dataIndex: "lastcall",
    key: "lastcall",
  },
];

const Finder = (props) => {
  const { Title } = Typography;
  const { userId, deviceId, value, setValue, role } = props;

  //user's primary device btns
  const [exportContactBtnLoading, setExportContactBtnLoading] = useState(false);

  const [userInfoData, setUserInfoData] = useState();
  const [matchUserData, setMatchUserData] = useState();

  const [deviceList, setDeviceList] = useState();
  const [deviceData, setDeviceData] = useState();
  const [resultData, setResultData] = useState();
  const [resultDevice, setResultDevice] = useState();

  //table loading
  const [tableLoading, setTableLoading] = useState(false);
  const [parentsTableLoading, setParentsTableLoading] = useState(false);
  const [searchTableLoading, setSearchTableLoading] = useState(false);
  const [searchMobileTableLoading, setSearchMobileTableLoading] =
    useState(false);

  const [devicePrimaryList, setDevicePrimaryList] = useState();
  console.log("devicePrimaryList", devicePrimaryList);
  const [disable, setDisable] = useState(true);
  const [device_id, setDevice_id] = useState();

  const [isDownload, setIsDownload] = useState();

  const [selected, setSelected] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [userRecord, setUserRecord] = useState();

  const loadUserProvidedInfo = () => {
    setMatchUserData();
    setUserInfoData();
    const endPoint = "device/get-save-device-contact-info";
    setTableLoading(true);
    const data = { user_id: userId, type: "admin" };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setUserInfoData(response?.contact_info);
          if (!_.isEmpty(response?.matchOtherData)) {
            setMatchUserData(response?.matchOtherData);
          }
          setTableLoading(false);
        } else {
          message?.warning(result?.message);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setTableLoading(false);
      });
  };

  const loadPrimaryDeviceList = () => {
    setDevicePrimaryList([]);
    setDeviceData("");
    const endPoint = "device/get-primary-device-list";
    const data = { user_id: userId, type: "primary" };
    let arr = [];
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.device_id,
              label: data?.model_company + " " + data?.device_model,
            });
          });
          setDisable(true);
          setDevicePrimaryList(arr);
        } else {
          message?.warning(result?.message);
        }
        console.log("arr=========>", arr);
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const loadDeviceList = () => {
    setDeviceList([]);
    const endPoint = "device/get-device-list";
    const data = { user_id: userId };
    let arr = [];
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          response.map((data) => {
            arr.push({
              value: data?.device_id,
              label: data?.model_company + " - " + data?.device_model,
            });
          });
          setDeviceList(arr);
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const selectDevice = (e) => {
    const endPoint = "device/get-device-contact-list";
    setParentsTableLoading(true);
    const data = { user_id: userId, device_id: e };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          console.log("response", response);
          setDeviceData(response);
          setParentsTableLoading(false);
        } else {
          message?.warning(result?.message);
          setParentsTableLoading(false);
        }
      })
      .catch((err) => {
        message?.error(err);
        setParentsTableLoading(false);
      });
  };

  const searchDevice = (e) => {
    if (_.isEmpty(searchValue)) {
      message.warning("Field cannot be empty");
    } else {
      const endPoint = "device/search-number";
      setSearchTableLoading(false);
      const data = { number: e };
      getAPIData(endPoint, "post", data, false, "device_change")
        .then((result) => {
          if (result?.status === true) {
            message?.success(result?.message);
            const response = result?.data;
            setResultData(response);
            setSearchTableLoading(false);
          } else {
            message?.warning(result?.message);
            setSearchTableLoading(false);
          }
        })
        .catch((err) => {
          message?.error(err);
          setSearchTableLoading(false);
        });
    }
  };

  const searchDeviceLog = (e) => {
    if (_.isEmpty(inputValue)) {
      message.warning("Field cannot be empty");
    } else {
      const endPoint = "device/search-number";
      setSearchMobileTableLoading(true);
      const data = { number: e, user_id: userId };
      getAPIData(endPoint, "post", data, false, "device_change")
        .then((result) => {
          if (result?.status === true) {
            message?.success(result?.message);
            const response = result?.data;
            setResultDevice(response);
            setSearchMobileTableLoading(false);
          } else {
            message?.warning(result?.message);
            setSearchMobileTableLoading(false);
          }
        })
        .catch((err) => {
          message?.error(err);
          setSearchMobileTableLoading(false);
        });
    }
  };

  console.log("deviceList", deviceList);

  const extractContactsData = () => {
    setExportContactBtnLoading(true);
    const endPoint = "device/extract-contacts";
    const data = { user_id: userId, device_id: device_id }; // need to set dynamic
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            download(response);
          }
          setExportContactBtnLoading(false);
        } else {
          message?.warning(result?.message);
          setExportContactBtnLoading(false);
        }
        setExportContactBtnLoading(false);
      })
      .catch((err) => {
        message?.error(err);
        setExportContactBtnLoading(false);
      });
  };

  const removeExtractContacts = (url) => {
    const endPoint = "device/remove-extract-contact-csv";
    const data = { url: url };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
        } else {
          message?.warning(result?.message);
        }
      })
      .catch((err) => {
        message?.error(err);
      });
  };

  const download = async (url) => {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    });

    const urlParts = url.split("/");
    const filename = urlParts[urlParts.length - 1];
    const urlObject = window.URL || window.webkitURL;
    const downloadUrl = urlObject.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = filename;

    link.addEventListener("load", handleDownloadSuccess(url));
    // link.addEventListener("error", handleDownloadError());

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadSuccess = (url) => {
    message.success("File downloaded successfully");
    setIsDownload(true);
    removeExtractContacts(url);
  };

  // const handleDownloadError = () => {
  //   console.log("Something went wrong !!");
  //   setIsDownload(false);
  // };

  const userColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "8%",
    },
    {
      title: "RELATION",
      dataIndex: "relation_id",
      key: "relation_id",
      width: "9%",
      render: (text, record) => (
        <div>
          <span>
            {record?.relation_id === 1
              ? "Mother"
              : record?.relation_id === 2
              ? "Father"
              : record?.relation_id === 3
              ? "Sister"
              : record?.relation_id === 4
              ? "Brother"
              : record?.relation_id === 5
              ? "Uncle"
              : record?.relation_id === 6
              ? "Aunt"
              : record?.relation_id === 7
              ? "Roommate"
              : record?.relation_id === 8
              ? "Close friend"
              : record?.relation_id === 9
              ? "Other"
              : record?.relation_id === 10
              ? "Spouse"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "PHONE DETAILS",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div>
          <span>
            {record?.phone_details === "1"
              ? "Has a mobile"
              : record?.phone_details === "2"
              ? "Does not use mobile"
              : record?.phone_details === "3"
              ? "Passes away"
              : record?.phone_details === "4"
              ? "Separated and not in touch"
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "CONTACT NO",
      dataIndex: "contact_no",
      key: "contact_no",
    },
    {
      title: "SAVED AS",
      dataIndex: "savedas",
      key: "savedas",
      render: (text, record) => (
        <span>{!_.isEmpty(record.savedas) ? record?.savedas : "-"}</span>
      ),
    },
    {
      title: "IN CALL LOCK (undone)",
      dataIndex: "calllock",
      key: "calllock",
      width: "8%",
    },
    {
      title: "CONTACT NO. AS PER RM",
      dataIndex: "contact_num_as_agent",
      key: "contact_num_as_agent",
      width: "13%",
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <span>{record.contact_num_as_agent}</span>
          <span style={{ marginLeft: 20 }}>
            <EditOutlined
              style={{
                fontSize: "25px",
                color: "#009D93",
                cursor: "pointer",
              }}
              onClick={() =>
                record?.relation_id === 1
                  ? openModal("mother", record)
                  : record?.relation_id === 2
                  ? openModal("father", record)
                  : openModal("other", record)
              }
            />
          </span>
        </div>
      ),
    },
    {
      title: "RECENT COMMENT",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "HISTORY (undone)",
      dataIndex: "history",
      key: "history",
      render: (text, record) => (
        <div>
          {" "}
          <Tag className="tag-form">HISTORY</Tag>{" "}
        </div>
      ),
    },
    {
      title: "VALIDATED",
      dataIndex: "validated",
      key: "validated",
      render: (text, record) => (
        <div>
          <span>
            {record?.validated === "1"
              ? "Yes"
              : record?.validated === "0"
              ? "No"
              : "-"}
          </span>
        </div>
      ),
    },
  ];

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const handleClose = () => {
    setModalType("");
  };

  const openModal = (type, record) => {
    if (type === "create-details") {
      setModalType(type);
      setModalTitle("Create Details");
      setModalWidth(500);
    } else if (type === "phone-permission") {
      setModalType(type);
      setModalTitle("Phone Permission");
      setModalWidth(700);
    } else if (type === "algo") {
      setModalType(type);
      setModalTitle("Algo");
      setModalWidth(1250);
    } else if (type === "linked-user") {
      setModalType(type);
      setModalTitle("Target device linked users list");
      setModalWidth(900);
    } else if (type === "father") {
      setModalType(type);
      setModalTitle("Update details for father");
      setModalWidth(500);
      setUserRecord(record);
    } else if (type === "mother") {
      setModalType(type);
      setModalTitle("Update details for mother");
      setModalWidth(500);
      setUserRecord(record);
    } else if (type === "other") {
      setModalType(type);
      setModalTitle("Update details for other");
      setModalWidth(500);
      setUserRecord(record);
    }
  };

  useEffect(() => {
    loadUserProvidedInfo();
    loadDeviceList();
    loadPrimaryDeviceList();
    setSelected("");
    setSearchValue("");
    setResultData("");
    setResultDevice("");
    setInputValue("");
  }, [userId, props]);

  return (
    <>
      <div>
        <Layout>
          <Layout className="site-layout">
            <Content
              className="site-layout-background"
              style={{
                // padding: 15,
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div poppins_regular">
                <div>
                  <Row gutter={[16, 16]}>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={4}
                    >
                      <Title level={5} className="poppins_medium umoney_title">
                        Parent Contact
                      </Title>
                    </Col>
                    <Col span={20}>
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="ALGO LOGS"
                            className="poppins_medium umoney_outlined_btn"
                            onClick={() => openModal("algo")}
                            disabled={value == "" ? true : false}
                            block
                          />
                        </Col>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="ALGO & PHONE PERMISSION"
                            className="poppins_medium umoney_outlined_btn"
                            onClick={() => {
                              openModal("phone-permission");
                            }}
                            disabled={value == "" ? true : false}
                            block
                          />
                        </Col>
                        <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="PREVIOUS USER HISTORY"
                            className="poppins_medium umoney_outlined_btn"
                            onClick={() => openModal("linked-user")}
                            disabled={value == "" ? true : false}
                            block
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={4}>
                      <span className="leftlable-finder">
                        User’s primary device
                      </span>
                    </Col>
                    <Col span={20}>
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <Select
                            style={{
                              width: "100%",
                            }}
                            size="large"
                            options={devicePrimaryList}
                            onChange={(e) => {
                              setDevice_id(e);
                              setDisable(false);
                              setValue(e);
                            }}
                            value={value}
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                          />
                        </Col>
                        <Col span={2}>
                          <Button
                            className="primary-device-btn "
                            style={{
                              background: "#DE1717 0% 0% no-repeat padding-box",
                              color: "white",
                              border: "none",
                              width: "100%",
                            }}
                            size="large"
                          >
                            Fail
                          </Button>
                        </Col>
                        <Col span={6}>
                          <CLoadingButton
                            size="large"
                            value="EXPORT CONTACTS"
                            className="poppins_medium umoney_outlined_btn"
                            loading={exportContactBtnLoading}
                            onClick={() => extractContactsData()}
                            disabled={value == "" ? true : false}
                            block
                          />
                        </Col>
                        {/* <Col span={8}>
                          <CLoadingButton
                            size="large"
                            value="CREATE DETAILS"
                            className="poppins_medium umoney_outlined_btn"
                            onClick={() => openModal("create-details")}
                            block
                          />
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={4}
                    >
                      <Title level={5} className="poppins_medium umoney_title">
                        User Provided Info
                      </Title>
                    </Col>
                  </Row>
                  <div className="umoney_table mt-10">
                    <Table
                      dataSource={userInfoData}
                      pagination={{
                        position: ["none", "bottomLeft"],
                        locale: { items_per_page: "" },
                        showSizeChanger: true,
                        defaultPageSize: 5,
                        pageSizeOptions: [5, 10, 15, 20],
                      }}
                      columns={userColumns}
                      style={{
                        marginBottom: "10px",
                      }}
                      loading={tableLoading}
                    />
                  </div>
                  <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={10}
                    >
                      <Title level={5} className="poppins_medium umoney_title">
                        User provided info matched with other users
                      </Title>
                    </Col>
                  </Row>
                  <div className="umoney_table mt-10">
                    <Table
                      dataSource={matchUserData}
                      pagination={{
                        position: ["none", "bottomLeft"],
                        locale: { items_per_page: "" },
                        showSizeChanger: true,
                        defaultPageSize: 5,
                        pageSizeOptions: [5, 10, 15, 20],
                      }}
                      columns={matchUserColumns}
                      style={{
                        marginBottom: "10px",
                      }}
                      loading={tableLoading}
                    />
                  </div>
                  <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={10}
                    >
                      <Title level={5} className="poppins_medium umoney_title">
                        Other possible parent’s numbers in contact list
                      </Title>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Choose Device
                    </Col>
                    <Col>
                      <Select
                        size="large"
                        style={{
                          width: 400,
                        }}
                        options={deviceList}
                        onChange={(e) => {
                          selectDevice(e);
                          setSelected(e);
                        }}
                        value={selected}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    </Col>
                  </Row>
                  <div className="umoney_table">
                    <Table
                      dataSource={deviceData}
                      columns={parentColumns}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      loading={parentsTableLoading}
                      pagination={{
                        position: ["none", "bottomLeft"],
                        locale: { items_per_page: "" },
                        showSizeChanger: true,
                        defaultPageSize: 5,
                        pageSizeOptions: [5, 10, 15, 20],
                      }}
                    />
                  </div>

                  <Search
                    placeholder="Enter Keyword"
                    size="large"
                    style={{ width: "40%" }}
                    onSearch={(e) => {
                      searchDevice(e);
                    }}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />

                  <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={10}
                    >
                      <Title level={5} className="poppins_medium umoney_title">
                        Search result
                      </Title>
                    </Col>
                  </Row>
                  <div className="umoney_table">
                    <Table
                      dataSource={resultData}
                      pagination={{
                        position: ["none", "bottomLeft"],
                        locale: { items_per_page: "" },
                        showSizeChanger: true,
                        defaultPageSize: 5,
                        pageSizeOptions: [5, 10, 15, 20],
                      }}
                      columns={searchColumns}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      loading={searchTableLoading}
                    />
                  </div>

                  <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={10}
                    >
                      <Title level={5} className="poppins_medium umoney_title">
                        Mobile number in all devices user has logged in from
                      </Title>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    {" "}
                    <Search
                      placeholder="Enter Keyword"
                      size="large"
                      style={{ width: "40%" }}
                      onSearch={(e) => {
                        searchDeviceLog(e);
                      }}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </Row>
                  <div className="umoney_table">
                    <Table
                      dataSource={resultDevice}
                      pagination={{
                        position: ["none", "bottomLeft"],
                        locale: { items_per_page: "" },
                        showSizeChanger: true,
                        defaultPageSize: 5,
                        pageSizeOptions: [5, 10, 15, 20],
                      }}
                      columns={loggedInColumns}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      loading={searchMobileTableLoading}
                    />
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
        <Modal
          open={modalType}
          title={modalTitle}
          footer={false}
          onCancel={handleClose}
          centered
          width={modalWidth}
        >
          {modalType === "create-details" ? (
            <UpdateDetailsForOther
              userId={userId}
              deviceId={deviceId}
              handleClose={handleClose}
              loadUserProvidedInfo={loadUserProvidedInfo}
            />
          ) : modalType === "phone-permission" ? (
            <PhonePermission userId={userId} deviceId={device_id} />
          ) : modalType === "algo" ? (
            <Algo userId={userId} deviceId={device_id} type="algo-finder" />
          ) : modalType === "linked-user" ? (
            <TargetDevice userId={userId} deviceId={device_id} />
          ) : modalType === "father" ? (
            <UpdateDetailsForFatherDevice
              role={role}
              userRecord={userRecord}
              handleClose={handleClose}
              loadUserProvidedInfo={loadUserProvidedInfo}
              modalType={modalType}
            />
          ) : modalType === "mother" ? (
            <UpdateDetailForMotherDevice
              role={role}
              userRecord={userRecord}
              handleClose={handleClose}
              loadUserProvidedInfo={loadUserProvidedInfo}
              modalType={modalType}
            />
          ) : modalType === "other" ? (
            <UpdateDetailForOtherDevice
              role={role}
              handleClose={handleClose}
              userRecord={userRecord}
              loadUserProvidedInfo={loadUserProvidedInfo}
              modalType={modalType}
            />
          ) : (
            ""
          )}
        </Modal>
      </div>
    </>
  );
};

export default Finder;
