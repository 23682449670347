import React, { useState, useEffect } from "react";
import { Col, Form, message, Modal, Radio, Row, Table } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import InformationUser from "./InformationUser";

const InternalDecision = ({ id, handleCloseInternal, userId, deviceId, loadData }) => {
  const [InternalDecisionData, setInternalDecisionData] = useState();
  const [form] = Form.useForm();

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const handleCloseInfo = () => {
    setModalType("");
  };

  const openModal = (type) => {
    if (type === "information-user") {
      setModalType(type);
      setModalTitle("Information User");
      setModalWidth(500);
    }
  };

  const onFinishFailed = () => {};

  const loadInternalDecision = () => {
    const endPoint = "device/decision-list";
    getAPIData(endPoint, "get", {}, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          const arr = [];
          response.map((data) => {
            arr.push({
              value: data?.id,
              label: data?.content,
            });
          });
          setInternalDecisionData(arr);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    const verifiedData = { ...data, id: id, user_id: userId };
    const endPoint = "device/add-internal-decision";
    getAPIData(endPoint, "post", verifiedData, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          // var response=result?.data?.id;
          // setId(response)
          form.resetFields();
          setSaveBtnLoading(false);
          openModal("information-user");
          handleCloseInternal();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  //save btn
  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const saveClick = () => {
    setLoading(true);
    setSaveBtnLoading(true);
  };

  useEffect(() => {
    loadInternalDecision();
  }, []);

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="internal_decision_id"
              size="large"
              rules={[{ required: true, message: "select One" }]}
              className="poppins_medium mb-35"
            >
              <Radio.Group
                className="poppins_medium"
                options={InternalDecisionData}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "10px",
                }}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                onClick={saveClick}
                style={{ width: "100%" }}
                className="poppins_medium umoney_btn"
                loading={saveBtnLoading}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        open={modalType}
        title={modalTitle}
        footer={false}
        onCancel={handleCloseInfo}
        centered
        width={modalWidth}
      >
        {modalType === "information-user" ? (
          <InformationUser
            id={id}
            handleCloseInfo={handleCloseInfo}
            userId={userId}
            deviceId={deviceId}
            loadData={loadData}
          />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default InternalDecision;
