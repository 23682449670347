import React from "react";
import { Select } from "antd";
import { Typography } from "antd";
import _ from "lodash";

const CSelect = (props) => {
  const { data, defaultValue, onChange = () => {} } = props;
  const { Text } = Typography;
  const options = [];
  !_.isEmpty(data) ? (
    data.map((row) => {
      options.push({
        value: row?.id ? row?.id : row?.value,
        label: row?.title ? row?.title : row?.label,
      });
    })
  ) : (
    <div></div>
  );

  return (
    <div className="urban-select">
      <Text className="poppins_semi"> {props?.label} </Text>
      <Select
        className="br"
        {...props}
        style={{ width: "100%" }}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
      ></Select>
    </div>
  );
};

export default CSelect;
