import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Form, message, Typography, Upload } from "antd";
import CLoadingButton from "../../../InputFields/CLoadingButton";
import { PlusOutlined } from "@ant-design/icons";
import { student_url } from "../../../../api";
import { setHeaderForFile } from "../../../../api";
import axios from "axios";
import CropperModal from "../../CropperModal";
import _ from "lodash";
import CCloseIcon from "../../../InputFields/CCloseIcon";
import { getAPIData } from "../../../../apiHelper";

const { Text } = Typography;

const UploadImage = (props) => {
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const user_id = props?.user_id;

  console.log("props", props);

  const [srcImg, setSrcImg] = useState();
  const [modalType, setModalType] = useState("");

  const [imageDoc, setImageDoc] = useState("");
  const [imageType, setImageType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [marksheet1, setMarksheet1] = useState([]);
  const [marksheet2, setMarksheet2] = useState([]);
  const [marksheet3, setMarksheet3] = useState([]);
  const [marksheet4, setMarksheet4] = useState([]);
  const [marksheet5, setMarksheet5] = useState([]);

  // marksheet name
  const [marksheet1Name, setMarksheet1Name] = useState("");
  const [marksheet2Name, setMarksheet2Name] = useState("");
  const [marksheet3Name, setMarksheet3Name] = useState("");
  const [marksheet4Name, setMarksheet4Name] = useState("");
  const [marksheet5Name, setMarksheet5Name] = useState("");

  // const onFinish = (data) => {
  //   console.log("success =====>>>>> ", data);
  // };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const [fileList, setFileList] = useState([]);
  console.log(fileList.length);

  console.log("marksheet1?.[0]?.originFileObj", marksheet1?.[0]?.originFileObj);

  const onFinish = async (data) => {
    console.log("success =====>>>>> ", data);
    setSubmitBtnLoading(true);
    const endpoint = "steps/save-student-marksheet";
    let images = [];

    if (marksheet1?.[0]) {
      images.push({
        imageBase64: marksheet1.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: marksheet1Name,
        type: "imageOne",
      });
    }
    if (marksheet2?.[0]) {
      images.push({
        imageBase64: marksheet2.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: marksheet2Name,
        type: "imageTwo",
      });
    }
    if (marksheet3?.[0]) {
      images.push({
        imageBase64: marksheet3.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: marksheet3Name,
        type: "imageThree",
      });
    }
    if (marksheet4?.[0]) {
      images.push({
        imageBase64: marksheet4.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: marksheet4Name,
        type: "imageFour",
      });
    }
    if (marksheet5?.[0]) {
      images.push({
        imageBase64: marksheet5.replace(/^data:([A-Za-z-+/]+);base64,/, ""),
        filename: marksheet5Name,
        type: "imageFive",
      });
    }

    if (!_.isEmpty(images)) {
      data.images = JSON.stringify(images);
    }
    data.user_id = user_id;

    getAPIData(endpoint, "post", data, false, "student")
      .then((result) => {
        if (result?.status === true) {
          props?.isSuccess(true);
          message.success(result?.message);
          setMarksheet1([]);
          setMarksheet2([]);
          setMarksheet3([]);
          setMarksheet4([]);
          setMarksheet5([]);
          props.onClose();
          setSubmitBtnLoading(false);
        } else {
          message.warning(result?.message);
          setSubmitBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        props?.loading(false);
      });
  };

  // console.log("marksheet1", marksheet1);

  const openModal = (type) => {
    if (type === "crop-image") {
      setModalType(type);
      setModalOpen(true);
    }
  };

  if (!_.isEmpty(imageDoc) && imageType === "marksheet1") {
    setMarksheet1(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "marksheet2") {
    setMarksheet2(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "marksheet3") {
    setMarksheet3(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "marksheet4") {
    setMarksheet4(imageDoc);
    setImageType("");
    setImageDoc("");
  }
  if (!_.isEmpty(imageDoc) && imageType === "marksheet5") {
    setMarksheet5(imageDoc);
    setImageType("");
    setImageDoc("");
  }

  const handleImageM1 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setMarksheet1Name(event.target.files[0]?.name);
    setImageType("marksheet1");
    openModal("crop-image");
  };
  const handleImageM2 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setMarksheet2Name(event.target.files[0]?.name);
    setImageType("marksheet2");
    openModal("crop-image");
  };
  const handleImageM3 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setMarksheet3Name(event.target.files[0]?.name);
    setImageType("marksheet3");
    openModal("crop-image");
  };
  const handleImageM4 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setMarksheet4Name(event.target.files[0]?.name);
    setImageType("marksheet4");
    openModal("crop-image");
  };
  const handleImageM5 = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setMarksheet5Name(event.target.files[0]?.name);
    setImageType("marksheet5");
    openModal("crop-image");
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form.Item className="poppins_medium mb-35" name="image_one">
            {_.isEmpty(marksheet1) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImageM1}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text> + Upload Marksheet1</Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={marksheet1}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setMarksheet1("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_two">
            {_.isEmpty(marksheet2) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImageM2}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text> + Upload Marksheet2</Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={marksheet2}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setMarksheet2("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_three">
            {_.isEmpty(marksheet3) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImageM3}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text> + Upload Marksheet3</Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={marksheet3}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setMarksheet3("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_four">
            {_.isEmpty(marksheet4) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImageM4}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text> + Upload Marksheet4</Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={marksheet4}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setMarksheet4("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
          <Form.Item className="poppins_medium mb-35" name="image_five">
            {_.isEmpty(marksheet5) ? (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  className="file-choose"
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                    opacity: 0,
                    position: "relative",
                    zIndex: "200",
                  }}
                  onChange={handleImageM5}
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "55%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text> + Upload Marksheet5</Text>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  backgroundColor: "#dffefc",
                  border: "1px dashed #009d93",
                  borderRadius: "2px",
                }}
              >
                <img
                  src={marksheet5}
                  style={{ height: "100%", width: "100%" }}
                ></img>
                <CCloseIcon
                  onClick={() => setMarksheet5("")}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              </div>
            )}
          </Form.Item>
        </div>
        <Form.Item>
          <CLoadingButton
            size="large"
            value="SAVE"
            className="poppins_medium umoney_btn mt-10"
            htmlType="submit"
            loading={submitBtnLoading}
            block
          />
        </Form.Item>
      </Form>
      {modalType === "crop-image" ? (
        <CropperModal
          open={modalOpen}
          title="Image Cropper"
          setOpen={setModalOpen}
          modalType={modalType}
          srcImg={srcImg}
          setImageDoc={setImageDoc}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default UploadImage;
