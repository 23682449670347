import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import TakeActionModal from "../../../components/Modals/SalaryCreditCheck/TakeActionModal";
import AddDocumentAndComment from "../../../components/Modals/modal/CommonModal/AddDocumentAndComment";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CTableSearch from "../../../components/InputFields/CTableSearch";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";

const TableComponent = ({
  role,
  pathType,
  setTotalCount,
  setApprovedCount,
  setPendingCount,
  setRejectedCount,
}) => {
  const [top, setTop] = useState("none");
  const navigate = useNavigate();

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  //Table Data
  const [tableData, setTableData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  const openModal = (type) => {
    if (type === "take-action") {
      setModalType(type);
      setModalTitle("Take Action");
      setModalWidth(500);
    } else if (type === "add-document") {
      setModalType(type);
      setModalTitle("Add Document Type  & comments");
      setModalWidth(400);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchDateProps = (dataIndex) => ({
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilter: (value, record) =>
      moment(record[dataIndex]).format("DD-MM-YYYY") ===
      value.format("DD-MM-YYYY"),

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CTableSearch
        datePicker
        onDateChange={(e) => {
          setSelectedKeys([e]);
        }}
        onSearch={() => {
          handleSearch(selectedKeys, confirm, dataIndex);
        }}
        onReset={() => clearFilters && handleReset(clearFilters, confirm)}
      />
    ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
      console.log("dataIndex", dataIndex);
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape={false}
          textToHighlight={text ? text : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "USER DETAILS",
      dataIndex: "user_details",
      key: "user_details",
      width: "10%",
      filteredValue: filteredInfo.user_details || null,
      ...getColumnSearchProps("user_details"),
      render: (_noUse, userDetails) => (
        <div
          dangerouslySetInnerHTML={{ __html: userDetails?.user_details }}
          style={{ cursor: "pointer" }}
        />
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(
              `/student-to-salaried-detailview?user_id=${record.user_id}`,
              {
                state: { type: record?.current_status, counts: record?.counts },
              }
            );
          },
        };
      },
    },
    {
      title: "TYPE",
      dataIndex: "current_status",
      key: "current_status",
      width: "9%",
      render: (text, record) => (
        <span>
          {record?.current_status === "1"
            ? `Currently working (${record?.counts})`
            : record?.current_status === "2"
            ? `Received job offer- Yet to start working (${record?.counts})`
            : record?.current_status === "3"
            ? `Looking for a job (${record?.counts})`
            : record?.current_status === "4"
            ? `Will continue studying (${record?.counts})`
            : ""}
        </span>
      ),
      filteredValue: filteredInfo.current_status || null,
      filters: [
        { value: "1", label: "Currently working", text: "Currently working" },
        { value: "2", label: "Received job offer", text: "Received job offer" },
        { value: "3", label: "Looking for a job", text: "Looking for a job" },
        {
          value: "4",
          label: "Will continue studying",
          text: "Will continue studying",
        },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.current_status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
    },
    {
      title: "USER REGISTRATION TIMESTAMP",
      dataIndex: "users_createdAt",
      key: "users_createdAt",
      width: "10%",
      filteredValue: filteredInfo.users_createdAt || null,
      ...getColumnSearchDateProps("users_createdAt"),
    },
    {
      title: "USER LAST ACTION",
      dataIndex: "percentage_updatedAt",
      key: "percentage_updatedAt",
      width: "10%",
      filteredValue: filteredInfo.percentage_updatedAt || null,
    },
    {
      title: "AGENT LAST ACTION",
      dataIndex: "agent_last_action_at",
      key: "agent_last_action_at",
      width: "10%",
      filteredValue: filteredInfo.agent_last_action_at || null,
      render: (text, record) => (
        <div>
          <span>{record?.agent_last_action_at}</span>
          <br />
          <span>{record?.agentName}</span>
        </div>
      ),
    },
    {
      title: "CALL STATUS",
      dataIndex: "stuToSalOnbDisp",
      key: "stuToSalOnbDisp",
      width: "15%",
      filteredValue: filteredInfo.stuToSalOnbDisp || null,
      ...getColumnSearchProps("stuToSalOnbDisp"),
      render: (_noUse, stuToSalOnbDisp) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: stuToSalOnbDisp?.stuToSalOnbDisp
                ? stuToSalOnbDisp?.stuToSalOnbDisp
                : "-",
            }}
          />
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: "6%",
      filteredValue: filteredInfo.action || null,
      render: (text, record) => <Tag className="tag-form">DETAILS</Tag>,
    },
    {
      title: "HISTORY",
      dataIndex: "history",
      key: "history",
      width: "8%",
      filteredValue: filteredInfo.history || null,
      render: (text, record) => (
        <div>
          <Tag className="tag-form">CALL HISTORY</Tag>
          <br />
          <Tag className="tag-form">CALL STATUS LOGS</Tag>
          <Tag className="tag-form">VIEW CHANGE LOGS</Tag>
        </div>
      ),
    },
    {
      title: "CURRENTLY WITH",
      dataIndex: "agentName",
      key: "agentName",
      hidden: true,
      filteredValue: filteredInfo.agentName || null,
      ...getColumnSearchProps("agentName"),
    },
  ];

  const normalcolumns = columns.filter((item) => !item.hidden);

  const handleClose = () => {
    setModalType("");
  };

  useEffect(() => {
    setTableLoading(true);
    const loadListData = () => {
      const endPoint = `stu-to-sal/list`;
      console.log("endPoint", endPoint);
      const data = { type: pathType };
      getAPIData(endPoint, "get", data, false, "stu_to_sal")
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            if (!_.isEmpty(response) && _.isArray(response)) {
              if (pathType === "list") {
                const rejCount = response.filter(
                  (data) => data.status === "-1"
                );
                const rejectedCount = rejCount.length;
                setRejectedCount(rejectedCount);

                const penCount = response.filter((data) => data.status === "0");
                const pendedCount = penCount.length;
                setPendingCount(pendedCount);

                const appCount = response.filter((data) => data.status === "2");
                const approvedCount = appCount.length;
                setApprovedCount(approvedCount);

                // const total = rejectedCount + pendedCount + approvedCount;
                // setTotalCount(total);
                let arr = [];
                response.map((data) => {
                  arr.push({
                    total: data.status,
                  });
                });

                const total = arr?.length;
                setTotalCount(total);

                setTableData(response);
                setTableLoading(false);
              } else {
                const rejCount = response.filter(
                  (data) => data.status === "-1"
                );
                const rejectedCount = rejCount.length;
                setRejectedCount(rejectedCount);

                // const appCount = response.filter((data) => data.status === "2");
                // const approvedCount = appCount?.length;
                // setForwardToAppCount(approvedCount);

                const penCount = response.filter((data) => data.status === "0");
                const pendedCount = penCount.length;

                let arr = [];
                response.map((data) => {
                  arr.push({
                    total: data.status,
                  });
                });

                const total = arr?.length;
                setTotalCount(total);

                setTableData(response);
                setTableLoading(false);
              }
            }
          } else {
            message.warning(result?.message);
            setTableLoading(false);
          }
          setTableLoading(false);
        })
        .catch((err) => {
          message.error(err);
          setTableLoading(false);
        });
    };

    loadListData();
  }, [
    pathType,
    setApprovedCount,
    setPendingCount,
    setRejectedCount,
    setTotalCount,
  ]);

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <CLoadingButton
            size="large"
            value="CLEAR FILTERS"
            className="poppins_medium umoney_btn"
            block
            onClick={() => clearFilters()}
          />
        </Col>
      </Row>

      <div className="mt-20 umoney_table">
        <Table
          columns={
            role === "caller" || role === "approver" ? normalcolumns : columns
          }
          dataSource={tableData}
          pagination={{
            showSizeChanger: true,
            locale: { items_per_page: "" },
          }}
          rowSelection={
            role === "Supervisor"
              ? {
                  rowSelection,
                }
              : false
          }
          loading={tableLoading}
          onChange={handleChange}
          scroll={{ x: "100vw" }}
        />
        <Modal
          open={modalType}
          title={modalTitle}
          onCancel={handleClose}
          centered
          width={modalWidth}
          footer={false}
        >
          {modalType === "take-action" ? (
            <TakeActionModal />
          ) : modalType === "add-document" ? (
            <AddDocumentAndComment />
          ) : (
            ""
          )}
        </Modal>
      </div>
    </div>
  );
};

export default TableComponent;
