import React, { useState, useEffect, useRef } from "react";
import {
  Switch,
  Typography,
  Table,
  message,
  Input,
  Button,
  Space,
  Col,
  Row,
} from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";
import CommonModals from "../../../components/Modals/CommonModals";
import Highlighter from "react-highlight-words";
import RejectedReasonsForm from "../../../components/RejectReasonsForm";
import RejectedReasonsModals from "../../../components/Modals/RejectedReasonsModals";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";

function RejectedReasons() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;

  //Save btn

  const [rejectedReasonsData, setRejectedReasonsData] = useState([]);

  // Rejected reasons edit states
  const [isEdit, setIsEdit] = useState(false);
  const [editCancleIcon, setEditCancleIcon] = useState(false);
  const [editId, setEditId] = useState(null);

  // Modal states
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // Rejected reasons delete states
  const [deleteId, setDeleteId] = useState(Number);

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [id, setId] = useState(null);
  const [name, setName] = useState("");

  const [editTitle, setEditTitle] = useState("");
  const [editData, setEditData] = useState();
  const [modalTitle, setModalTitle] = useState("");

  // const onFailedRejectedReasons = (errorInfo) => {
  //   console.log('Failed:', errorInfo)
  // }

  const openModal = (type, id, record) => {
    if (type === "add-reason") {
      setModalType(type);
      setModalTitle("Add Rejected Reason");
    } else if (type === "edit-reason") {
      setEditData(record);
      console.log("edit======>", record);
      setModalType(type);
      setModalTitle("Edit Rejected Reason");
    }
  };

  const loadRejectedReasonsData = () => {
    setLoading(true);
    const endPoint = "system-config/image-rejected-reason/list";
    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          setRejectedReasonsData(result?.data);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const rejectedReasonsEdit = (id, name) => {
    setId(id);
    setName(name);
    // form.setFieldValue('title', name)
    setEditTitle(name);
    setEditCancleIcon(true);
    setIsEdit(true);
    setEditId(id);
  };

  const changeStatus = (e, id) => {
    const endPoint = "system-config/image-rejected-reason/change-status/" + id;
    const data = { status: e === true ? "1" : "0" };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          loadRejectedReasonsData();
          message.success(result?.message);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    if (_.isEmpty(rejectedReasonsData)) {
      loadRejectedReasonsData();
    }
  }, [rejectedReasonsData]);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id"),
    },
    {
      title: "REASONS",
      dataIndex: "title",
      key: "title",
      width: "70%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      align: "center",
      key: "actions",
      width: "20%",
      render: (text, record) => (
        <div>
          <span>
            <Switch
              defaultChecked={record?.status === "1"}
              onChange={(e) => changeStatus(e, record?.id)}
            />
          </span>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                // rejectedReasonsEdit(record?.id, record?.title);
                openModal("edit-reason", record?.id, record);
              }}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalType("delete");
                setDeleteId(record?.id);
                setModalOpen(true);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title">
                {" "}
                Super Admin Dashboard
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: 15,
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  <Col span={16}></Col>
                  <Col className="gutter-row" span={8}>
                    <CLoadingButton
                      size="large"
                      value="ADD REASON"
                      icon={<PlusOutlined />}
                      className="poppins_medium umoney_btn"
                      block
                      onClick={() => openModal("add-reason")}
                    />
                  </Col>
                </Row>

                <div className="umoney_table mt-20">
                  <Table
                    pagination={{
                      showSizeChanger: true,
                      locale: { items_per_page: "" },
                    }}
                    columns={tableColumns}
                    dataSource={rejectedReasonsData}
                    bordered
                  />
                </div>
              </div>
            </Content>
          </Layout>
          {modalType === "delete" ? (
            <CommonModals
              open={modalOpen}
              setOpen={setModalOpen}
              title="Delete Rejected Reason"
              type="rejected-reason"
              modalType={modalType}
              setModalType={setModalType}
              id={deleteId}
              onDelete={(val) => {
                loadRejectedReasonsData();
              }}
            />
          ) : modalType === "add-reason" ? (
            <RejectedReasonsModals
              modalTitle={modalTitle}
              setModalType={setModalType}
              modalType={modalType}
              isEdit={isEdit}
              onSave={() => {
                setModalType(false);
              }}
              loadRejectedReasonsData={loadRejectedReasonsData}
            />
          ) : modalType === "edit-reason" ? (
            <RejectedReasonsModals
              editData={editData}
              modalTitle={modalTitle}
              modalType={modalType}
              isEdit={isEdit}
              setModalType={setModalType}
              onSave={() => {
                setModalType(false);
              }}
              setEditCancleIcon={setEditCancleIcon}
              setIsEdit={setIsEdit}
              editCancleIcon={editData}
              loadRejectedReasonsData={loadRejectedReasonsData}
            />
          ) : (
            ""
          )}
        </Layout>
      </div>
    </>
  );
}

export default RejectedReasons;
