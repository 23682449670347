import { Button, DatePicker, Space, Input } from "antd";
import React, { forwardRef } from "react";
import { SearchOutlined } from "@ant-design/icons";

const CTableSearch = forwardRef((props, ref) => {
  const {
    datePicker = false,
    onSearch = () => {},
    onDateChange = () => {},
    onReset = () => {},
    onChangeText = () => {},
    onkeydown = () => {},
    value,
    dataIndex = "",
  } = props;
  return (
    <div style={{ padding: 8 }} onKeyDown={onkeydown}>
      {datePicker ? (
        <DatePicker
          onChange={onDateChange}
          onPressEnter={onSearch}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          allowClear={true}
        />
      ) : (
        <Input
          ref={ref}
          placeholder={`Search ${dataIndex}`}
          value={value}
          onChange={onChangeText}
          onPressEnter={onSearch}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
      )}
      <Space>
        <Button
          type="primary"
          onClick={onSearch}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={onReset} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
});
export default CTableSearch;
