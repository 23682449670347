import { Col, Row, Select, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const AddNotificationTemplate = (props) => {
  const {
    onSave = () => {},
    notificationData,
    modalType,
    modalTitle,
    setModalType,
  } = props;

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [iconList, setIconList] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const moduleData = [
    {
      value: "1",
      label: "KYC",
    },
    {
      value: "2",
      label: "PAN",
    },
    {
      value: "3",
      label: "Personal Information",
    },
    {
      value: "4",
      label: "Student Information",
    },
    {
      value: "5",
      label: "Selfie",
    },
    {
      value: "6",
      label: "Video",
    },
  ];

  const onSubmitData = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "add-noti-template"
        ? "template/notification-create"
        : "template/notification-update/" + notificationData?.id;

    data.dynamic_data_param = "1";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setModalType(false);
  };

  useEffect(() => {
    const loadNotificationIcons = () => {
      const endPoint = "template/icon-active-list";
      console.log("endPoint", endPoint);

      getAPIData(endPoint, "get", {}, false)
        .then((result) => {
          console.log("result", result);
          if (result?.status === true) {
            var icon_list = [];
            result?.data.map((icon) =>
              icon_list.push({
                value: icon.id,
                label: <img src={icon.url} alt="" height={20} width={20} />,
              })
            );
            setIconList(icon_list);
            form.resetFields();
          } else {
            message.warning(result?.message);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    };

    loadNotificationIcons();
    if (modalType === "add-noti-template") {
      form.resetFields();
    } else {
      form.setFieldsValue(notificationData);
    }
  }, [form, notificationData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={500}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="poppins_medium" style={{ marginTop: "10px" }}>
        <Form
          name="basic"
          //onFinish={onFinish}
          onFinish={(data) => {
            onSubmitData(data);
          }}
          onFinishFailed={onFinishFailed}
          initialValues={
            modalType === "edit-noti-template" ? notificationData : ""
          }
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Message Body"
                name="message_body"
                rules={[
                  { required: true, message: "Message Body is required!" },
                ]}
                className="poppins_medium mb-35"
              >
                <TextArea
                  rows={4}
                  // onChange={convertToSlug}
                  placeholder="Message body will be here."
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Template Header (Slug)"
                name="slug"
                rules={[
                  { required: true, message: "Template Header is required!" },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter template header"
                  disabled={modalType === "add-noti-template" ? false : true}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Screen"
                name="screen"
                rules={[{ required: true, message: "Screen is required!" }]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter screen" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Icon"
                name="icon_id"
                className="poppins_medium mb-35"
              >
                <Select
                  className="br"
                  size="large"
                  options={iconList}
                  style={{ width: "100%" }}
                  placeholder="Select icon"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Module"
                name="module"
                className="poppins_medium mb-35"
              >
                <Select
                  className="br"
                  size="large"
                  options={moduleData}
                  style={{ width: "100%" }}
                  placeholder="Select module"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="Save"
                  className="poppins_medium umoney_btn"
                  loading={saveBtnLoading}
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNotificationTemplate;
