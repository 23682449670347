import { Col, Row, Modal } from "antd";
import React, { useState } from "react";
import CLoadingButton from "../../InputFields/CLoadingButton";
import ConfirmationModal from "../../Modals/modal/ConfirmationModal";

const ConfirmStatus = ({ userId, bankId, onSave = () => {} }) => {
  const [btnType, setBtnType] = useState("");

  const handleClose = () => {
    setModalType("");
  };

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const openModal = (type) => {
    if (type === "confirmation-modal") {
      setModalType(type);
      setModalTitle("Confirmation");
      setModalWidth(500);
    }
  };

  return (
    <div>
      {" "}
      <Row gutter={[16, 16]} style={{ padding: 10 }}>
        <Col className="gutter-row" span={12}>
          <CLoadingButton
            size="large"
            value="APPROVE"
            className="poppins_medium "
            // loading={ApprovebtnLoading}
            onClick={() => {
              openModal("confirmation-modal");
              setBtnType("1");
            }}
            block
            style={{
              backgroundColor: "#3EA467",
              color: "#fff",
              borderRadius: 5,
              borderColor: "#3EA467",
            }}
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <CLoadingButton
            size="large"
            value="REJECT"
            className="poppins_medium "
            // loading={RejectbtnLoading}
            onClick={() => {
              openModal("confirmation-modal");
              setBtnType("-1");
            }}
            block
            style={{
              backgroundColor: "#DE1717",
              color: "#fff",
              borderRadius: 5,
              borderColor: "#DE1717",
            }}
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <CLoadingButton
            size="large"
            value=" FOLLOW-UP"
            className="poppins_medium"
            onClick={() => {
              openModal("confirmation-modal");
              setBtnType("2");
            }}
            block
            style={{
              backgroundColor: "#4C9AFF",
              color: "#fff",
              borderRadius: 5,
              borderColor: "#4C9AFF",
            }}
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <CLoadingButton
            size="large"
            value="UPLOAD DOCUMENT"
            className="poppins_medium"
            onClick={() => {
              openModal("confirmation-modal");
              setBtnType("3");
            }}
            block
            style={{
              backgroundColor: "orange",
              color: "#fff",
              borderRadius: 5,
              borderColor: "#4C9AFF",
            }}
          />
        </Col>
      </Row>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={handleClose}
        centered
        width={modalWidth}
        footer={false}
      >
        {modalType === "confirmation-modal" ? (
          <ConfirmationModal
            handleClose={handleClose}
            userId={userId}
            bankId={bankId}
            btnType={btnType}
            onSave={() => {
              setModalType("");
              onSave();
            }}
          />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default ConfirmStatus;
