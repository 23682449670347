import React, { useRef, useState } from "react";
import { Modal, Typography } from "antd";
import UploadImage from "../../Modals/modal/CommonModal/UploadImage";
import RemoveImage from "../../Modals/modal/RemoveImage";
import PreviewModal from "../../Modals/modal/CommonModal/PreviewModal";
import CImageCard from "../KYC/CImageCard";

const { Title } = Typography;

const StudentIdFront = ({ studentIdFrontImage, user_id, isSuccess }) => {
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const previewModalRef = useRef(null);

  const openModal = (type) => {
    if (type === "remove-image") {
      setModalType(type);
      setModalTitle("Remove Image");
      setModalWidth(500);
    }
  };

  const handleClose = () => {
    setModalType("");
  };

  return (
    <div className="border-class m-10">
      <div
        className="p-10 d-flex justify-between"
        style={{
          borderBottom: "1px solid #009D93",
        }}
      >
        <Title level={5} className="poppins_medium color_title">
          Student Id Front
        </Title>
      </div>
      <CImageCard
        pdf
        image={studentIdFrontImage}
        type={"Student Id Front"}
        onRemove={() => {
          openModal("remove-image");
        }}
        onPreview={() => {
          previewModalRef?.current?.open(studentIdFrontImage);
        }}
      />
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={handleClose}
        centered
        width={modalWidth}
        footer={false}
        // destroyOnClose={true}
      >
        {modalType === "remove-image" ? (
          <RemoveImage
            user_id={user_id}
            type={"std_id_frontImg"}
            handleClose={handleClose}
            isSuccess={isSuccess}
          />
        ) : (
          ""
        )}
      </Modal>
      <PreviewModal ref={previewModalRef} />
    </div>
  );
};

export default StudentIdFront;
