import React, { useEffect, useState } from "react";
import { Row, Col, Table, Radio, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

const columns = [
  {
    title: "ALGO",
    dataIndex: "algo",
    key: "algo",
  },
  {
    title: "RUN",
    dataIndex: "run",
    key: "run",
  },
];

const columnspara = [
  {
    title: "ALGO",
    dataIndex: "algo",
    key: "algo",
  },
  {
    title: "FAILED PARAMETERS",
    dataIndex: "failed_parameters",
    key: "failed_parameters",
    render: (text, record) => {
      const myString = record?.failed_parameters.join(", ");
      return <span>{myString}</span>;
    },
  },
];

const algo1Columns = [
  {
    title: "TEST RUN TIMESTAMP",
    dataIndex: "createdAt",
    key: "createdAt",
    width: "20%",
  },
  {
    title: "GMAIL",
    dataIndex: "gmail",
    key: "gmail",
    width: "10%",
    render: (text, record) => (
      <div>
        <span>
          {record?.gmail === null ? "NA" : record?.gmail === true ? "Y" : "X"}
        </span>
      </div>
    ),
  },

  {
    title: "MOBILE NUMBER",
    dataIndex: "mobile_number",
    key: "mobile_number",
    render: (text, record) => (
      <div>
        <span>
          {record?.mobile_number === null
            ? "NA"
            : record?.mobile_number === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "MATCH PERCENTAGE",
    dataIndex: "match_percentage",
    key: "match_percentage",
    render: (text, record) => (
      <div>
        <span>
          {record?.match_percentage === null
            ? "NA"
            : record?.match_percentage === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "LAST LOAN",
    dataIndex: "last_loan",
    key: "last_loan",
    render: (text, record) => (
      <div>
        <span>
          {record?.last_loan === null
            ? "NA"
            : record?.last_loan === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
];

const algo2Columns = [
  {
    title: "TEST RUN TIMESTAMP",
    dataIndex: "createdAt",
    key: "createdAt",
    width: "10%",
  },
  {
    title: "CONTACT COUNTS",
    dataIndex: "contact_counts",
    key: "contact_counts",
    width: "10%",
    render: (text, record) => (
      <div>
        <span>
          {record?.contact_counts === null
            ? "NA"
            : record?.contact_counts === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "SMS COUNT",
    dataIndex: "sms_count",
    key: "sms_count",
    render: (text, record) => (
      <div>
        <span>
          {record?.sms_count === null
            ? "NA"
            : record?.sms_count === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "FAMILY COUNT",
    dataIndex: "family_count",
    key: "family_count",
    render: (text, record) => (
      <div>
        <span>
          {record?.family_count === null
            ? "NA"
            : record?.family_count === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "LATEST MEDIA",
    dataIndex: "latest_media",
    key: "latest_media",
    render: (text, record) => (
      <div>
        <span>
          {record?.latest_media === null
            ? "NA"
            : record?.latest_media === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "BANKING SMS COUNT",
    dataIndex: "banking_sms_count",
    key: "banking_sms_count",
    render: (text, record) => (
      <div>
        <span>
          {record?.banking_sms_count === null
            ? "NA"
            : record?.banking_sms_count === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "MEDIA COUNT",
    dataIndex: "media_count",
    key: "media_count",
    render: (text, record) => (
      <div>
        <span>
          {record?.media_count === null
            ? "NA"
            : record?.media_count === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "INSTALL APPS LIST",
    dataIndex: "install_apps_list",
    key: "install_apps_list",
    render: (text, record) => (
      <div>
        <span>
          {record?.install_apps_list === null
            ? "NA"
            : record?.install_apps_list === true
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
];

const algo3Columns = [
  {
    title: "TEST RUN TIMESTAMP",
    dataIndex: "createdAt",
    key: "createdAt",
    width: "20%",
  },

  {
    title: "GMAIL",
    dataIndex: "gmail",
    key: "gmail",
    render: (text, record) => (
      <div>
        <span>
          {record?.gmail === null ? "NA" : record?.gmail === 1 ? "Y" : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "MOBILE NUMBER",
    dataIndex: "mobile_number",
    key: "mobile_number",
    render: (text, record) => (
      <div>
        <span>
          {record?.mobile_number === null
            ? "NA"
            : record?.mobile_number === 1
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "MATCH PERCENTAGE",
    dataIndex: "match_percentage",
    key: "match_percentage",
    render: (text, record) => (
      <div>
        <span>
          {record?.match_percentage === null
            ? "NA"
            : record?.match_percentage === 1
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "PARENTS CONTACT",
    dataIndex: "parents_contact",
    key: "parents_contact ",
    render: (text, record) => (
      <div>
        <span>
          {record?.parents_contact === null
            ? "NA"
            : record?.parents_contact === 1
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
  {
    title: "CONTACT MATCH",
    dataIndex: "contact_match",
    key: "contact_match",
    render: (text, record) => (
      <div>
        <span>
          {record?.contact_match === null
            ? "NA"
            : record?.contact_match === 1
            ? "Y"
            : "X"}
        </span>
      </div>
    ),
  },
];

const Algo = (props) => {
  // console.log("device_unique_id", device_unique_id);
  // console.log("g_ad_id", g_ad_id);
  // console.log("userId", userId);
  const { userId, g_ad_id, device_unique_id, type, deviceId } = props;

  const [value, setValue] = useState("");
  // const [pageLoading,setPageLoading]=useState(true)

  //Re-run btn
  const [loading, setLoading] = useState(false);
  const [rerrunLoading, setRerunLoading] = useState(false);

  const [tableLoading, setTableLoading] = useState(false);

  const [algoData, setAlgoData] = useState();
  const [algoDetails, setAlgoDetails] = useState();

  const [failedParametersData, setFailedParametersData] = useState([]);

  const [algoTableData, setAlgoTableData] = useState([]);

  const getInitialAlgoData = () => {
    setTableLoading(true);
    const data = { user_id: userId, device_id: deviceId };
    const endPoint = "device/get-algo-details";
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setAlgoTableData(response?.Algo);
          setFailedParametersData(response?.AlgoFailedData);
          setTableLoading(false);
        } else {
          message?.warning(result?.message);
          setTableLoading(false);
        }
        setTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
      });
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setAlgoData("");
    setValue(e.target.value);
  };

  const getPastAlgoData = (valueId) => {
    setTableLoading(true);
    const data = { user_id: userId, device_id: deviceId, type: valueId };
    const endPoint = "device/get-algo-list";
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setAlgoData(response);
          setTableLoading(false);
        } else {
          message?.warning(result?.message);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
      });
  };

  const getAlgoData = () => {
    setTableLoading(true);
    setRerunLoading(true);
    const data =
      type === "algo-finder" || type === "algo-cc"
        ? { user_id: userId, device_id: deviceId, type: value }
        : {
            user_id: userId,
            g_ad_id: g_ad_id,
            device_unique_id: device_unique_id,
            type: value,
            device_id: deviceId,
          };
    const endPoint = "device/algorithms";
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var res = result?.data;
          var response = result?.data?.algo_result;
          console.log("jhdhzxjchzxjcn", result?.data?.parameter);
          setAlgoData(result?.data?.algoResult?.algo_result);
          console.log("value", value);
          setAlgoTableData(result?.data?.algo);
          setFailedParametersData(result?.data?.AlgoFailedData);
          setTableLoading(false);
          setRerunLoading(false);
        } else {
          message?.warning(result?.message);
          setTableLoading(false);
          setRerunLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
        setRerunLoading(false);
      });
  };

  useEffect(() => {
    getInitialAlgoData();
    setAlgoData("");
    setValue("");
  }, [userId, g_ad_id, device_unique_id, deviceId, props]);

  return (
    <div style={{ marginTop: "10px" }}>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <div
            style={{
              border: "1px solid #009D93",
              // marginRight: "25px",
              borderRadius: "5px",
              paddingBottom: "2px",
              paddingTop: "2px",
            }}
          >
            <Table
              className="poppins_regular"
              dataSource={algoTableData}
              pagination={false}
              columns={columns}
              rowClassName="table-css"
              style={{ whiteSpace: "pre" }}
              loading={tableLoading}
            />
          </div>
        </Col>

        <Col span={16}>
          <div
            style={{
              border: "1px solid #009D93",
              borderRadius: "5px",
              paddingBottom: "2px",
              paddingTop: "2px",
            }}
          >
            <Table
              className="poppins_regular"
              dataSource={failedParametersData}
              pagination={false}
              columns={columnspara}
              rowClassName="table-css"
              style={{ whiteSpace: "break-spaces" }}
              loading={tableLoading}
            />
          </div>
        </Col>

        <Col span={4} className="poppins_regular">
          <Radio.Group
            onChange={onChange}
            value={value}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Radio
              className="radio"
              value={1}
              onChange={(e) => getPastAlgoData(e.target.value)}
            >
              Algo 1
            </Radio>
            <Radio
              className="radio"
              value={2}
              onChange={(e) => getPastAlgoData(e.target.value)}
            >
              Algo 2
            </Radio>
            <Radio
              className="radio"
              value={3}
              onChange={(e) => getPastAlgoData(e.target.value)}
            >
              Algo 3
            </Radio>
          </Radio.Group>
          <CLoadingButton
            htmlType="submit"
            size="large"
            value="RE-RUN"
            onClick={() => getAlgoData()}
            style={{ width: "100%", marginTop: "20px" }}
            className="poppins_medium umoney_btn"
            loading={rerrunLoading}
            disabled={value === "" ? true : false}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <div
          style={{
            border: "1px solid #009D93",
            borderRadius: "5px",
            width: "100%",
            paddingBottom: "2px",
            paddingTop: "2px",
          }}
          className="umoney_table"
        >
          <Table
            dataSource={algoData}
            pagination={false}
            columns={
              value === 1
                ? algo1Columns
                : value === 2
                ? algo2Columns
                : value === 3
                ? algo3Columns
                : ""
            }
            size="large"
            loading={tableLoading}
            scroll={{ y: "300px" }}
          />
        </div>
      </Row>
    </div>
  );
};

export default Algo;
