import React, { useState, useEffect, useRef } from "react";
import CClickLoading from "../../components/InputFields/CClickLoading";
import {
  Button,
  Col,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import Sidebar from "../../components/Sidebar";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import CSelectBtn from "../../components/InputFields/CSelectBtn";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import AlgoLoanFilterModal from "../../components/Modals/LoanFilter/AlgoLoanFilterModal";
import ConfirmStatus from "../../components/Modals/LoanFilter/ConfirmStatus";
import ConfirmationModalLoan from "../../components/Modals/LoanFilter/ConfirmationModalLoan";
import Remark from "../../components/Modals/LoanFilter/Remark";
import CallHistoryLoanFilter from "../../components/Modals/LoanFilter/CallHistoryLoanFilter";
import ActionHistoryLoanFilter from "../../components/Modals/LoanFilter/ActionHistoryLoanFilter";
import Highlighter from "react-highlight-words";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";
import { useSelector } from "react-redux";
import { loan_url, setHeader } from "../../api";
import axios from "axios";
import ClickToCallModal from "../../components/Modals/modal/ClickToCallModal";
import moment from "moment";
import CTableSearch from "../../components/InputFields/CTableSearch";

const { Header, Content } = Layout;
const { Title } = Typography;

const LoanFilterAdvisor = () => {
  const role = useSelector((state) => state.userData.accessRoleCode);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);

  const [tableLoading, setTableLoading] = useState(false);
  const [type, setType] = useState("");

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  //Modal
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [pendingCount, setPendingCount] = useState();
  const [followUpCount, setFollowUpCount] = useState();
  const [approvedCount, setApprovedCount] = useState();
  const [rejectCount, setRejectCount] = useState();

  const [user_id, setUser_id] = useState("");
  const [device_id, setDevice_id] = useState("");
  const [followup_ts, setFollowup_ts] = useState("");

  const [modal, setModal] = useState(false);
  const [regNumber, setRegNumber] = useState("");
  const [altNumber, setAltNumber] = useState("");

  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchDateProps = (dataIndex) => ({
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilter: (value, record) =>
      moment(record[dataIndex]).format("DD-MM-YYYY") ===
      value.format("DD-MM-YYYY"),

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CTableSearch
        datePicker
        onDateChange={(e) => {
          setSelectedKeys([e]);
        }}
        onSearch={() => {
          handleSearch(selectedKeys, confirm, dataIndex);
        }}
        onReset={() => clearFilters && handleReset(clearFilters, confirm)}
      />
    ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape={false}
          textToHighlight={text ? text : ""}
        />
      ) : (
        text
      ),
  });

  const openModal = (type) => {
    if (type === "algo") {
      setModalType(type);
      setModalTitle("Algo");
      setModalWidth(1300);
    } else if (type === "confirm-status") {
      setModalType(type);
      setModalTitle("Confirm Status");
      setModalWidth(500);
    } else if (type === "confirmation") {
      setModalType(type);
      setModalTitle("Confirmation");
      setModalWidth(500);
    } else if (type === "remark") {
      setModalType(type);
      setModalTitle("Remark");
      setModalWidth(500);
    } else if (type === "remark1") {
      setModalType(type);
      setModalTitle("Remark");
      setModalWidth(500);
    } else if (type === "call-history") {
      setModalType(type);
      setModalTitle("Call History (static)");
      setModalWidth(1200);
    } else if (type === "action-history") {
      setModalType(type);
      setModalTitle("Action History");
      setModalWidth(1000);
    } else if (type === "click-to-call") {
      setModalType(type);
      setModalTitle("Click To Call");
      setModalWidth(500);
      setType("loan-filter");
    }
  };

  const [userIds, setUserIds] = useState([]);
  const [selectedJunior, setSelectedJunior] = useState("");

  const [assignLoading, setAssignLoading] = useState(false);

  const handleAssignClick = async () => {
    setAssignLoading(true);
    setSpinLoading(true);
    const endPoint = loan_url + "loan/assign-loan-filer";
    const header = setHeader();

    const data = {
      userIds: JSON.stringify(userIds),
      approver_id: selectedJunior,
    };
    const assign = await axios.post(endPoint, data, header);
    if (assign?.data?.status) {
      setAssignLoading(false);
      setSpinLoading(false);
      message.success(assign?.data?.message);
      loadAgentData();
      setSelectedJunior("");
      setUserIds("");
      setSelectedRowKeys("");
    } else {
      message.error(assign?.data?.message);
      setAssignLoading(false);
      setSpinLoading(false);
    }
  };

  const agentSelectChange = (e) => {
    setSelectedJunior(e);
  };

  const [agentData, setAgentData] = useState();

  const loadAgentData = async () => {
    setLoading(true);
    const endPoint = "loan/loan-agent-list";
    getAPIData(endPoint, "get", {}, false, "loan")
      .then((response) => {
        if (response?.status) {
          const agents = response?.data;
          if (agents) {
            let data = [];
            agents.map(async (agent) => {
              data.push({
                label:
                  agent?.first_name +
                  " " +
                  agent?.middle_name +
                  " " +
                  agent?.last_name,
                value: agent?.user_id,
              });
            });
            console.log("data", data);
            setAgentData(data);
          }
        } else {
          message.warning(response?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const tableColumns = [
    {
      title: "REQUEST ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
      filteredValue: filteredInfo.id || null,
      sorter: (a, b) => a?.id - b?.id,
      ...getColumnSearchProps("id"),
    },
    {
      title: "REQUEST TIMESTAMP",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "12%",
      filteredValue: filteredInfo.createdAt || null,
      ...getColumnSearchDateProps("createdAt"),
    },
    {
      title: "USER DETAILS",
      dataIndex: "user_details",
      key: "user_details",
      filteredValue: filteredInfo.user_details || null,
      width: "12%",
      ...getColumnSearchProps("user_details"),
      render: (_noUse, userDetails) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: userDetails?.user_details }}
          />
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      filteredValue: filteredInfo.action || null,
      render: (_noUse, record) => (
        <div>
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("algo");
                setUser_id(record?.user_id);
                setDevice_id(record?.device_id);
              }}
            >
              ALGO
            </Tag>
          </span>
          <br />
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("confirm-status");
                setUser_id(record?.user_id);
                setFollowup_ts(record?.followup_ts);
                setDevice_id(record?.device_id);
              }}
            >
              TAKE ACTION{" "}
            </Tag>
          </span>
          <br />
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("click-to-call");
                setUser_id(record?.user_id);
                setAltNumber(record?.alt_number);
                setRegNumber(record?.mobile_number);
              }}
            >
              CLICK TO CALL
            </Tag>
          </span>
        </div>
      ),
    },
    {
      title: "ACTION BY TIMESTAMP",
      dataIndex: "status_ts",
      key: "status_ts",
      width: "14%",
      filteredValue: filteredInfo.status_ts || null,
      ...getColumnSearchDateProps("status_ts"),
      render: (text, record) => (
        <div>
          <span>{record?.agentName}</span>
          <br />
          <span>Timestamp: {record?.status_ts}</span>
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "action_status",
      key: "action_status",
      filteredValue: filteredInfo.action_status || null,
      render: (text, record) => (
        <div>
          <span>
            {record?.action_status === "1"
              ? "Approve"
              : record?.action_status === "-1"
              ? "Reject"
              : record?.action_status === "2"
              ? "Follow Up"
              : record?.action_status === "3"
              ? "Pending"
              : "-"}
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Approve", text: "Approve" },
        { value: "-1", label: "Reject", text: "Reject" },
        { value: "2", label: "Follow up", text: "Follow UP" },
        { value: "3", lable: "Pending", text: "Pending" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record?.action_status != null
          ? record.action_status.indexOf(value) === 0
          : "",
    },
    {
      title: "REMARKS",
      dataIndex: "comment",
      key: "comment",
      filteredValue: filteredInfo.comment || null,
      ...getColumnSearchProps("comment"),
      render: (text, record) =>
        // _.isEmpty(record?.comment) ? "-" : <span>{record?.comment}</span>,
        record?.action_status !== "2" ? (
          <span>{record?.comment}</span>
        ) : (
          <span>{record?.follow_up_comment}</span>
        ),
    },
    {
      title: "FOLLOW-UP TIMESTAMP",
      dataIndex: "followup_ts",
      key: "followup_ts",
      width: "13%",
      filteredValue: filteredInfo.followup_ts || null,
      ...getColumnSearchDateProps("followup_ts"),
    },
    {
      title: "HISTORY",
      dataIndex: "history",
      key: "history",
      filteredValue: filteredInfo.history || null,
      render: (_noUse, data) => (
        <div>
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("action-history");
                setModal(true);
                setUser_id(data?.user_id);
                setDevice_id(data?.device_id);
              }}
            >
              ACTION HISTORY
            </Tag>
          </span>
          <br />
          <span>
            <Tag
              className="tag-form"
              onClick={() => {
                openModal("call-history");
              }}
            >
              CALL HISTORY
            </Tag>
          </span>
          <br />
        </div>
      ),
    },

    {
      title: "CURRENTLY WITH",
      dataIndex: "agentName",
      key: "agentName",
      filteredValue: filteredInfo.agentName || null,
      hidden: true,
      ...getColumnSearchProps("agentName"),
    },
  ];

  const newColumns = tableColumns.filter((item) => !item.hidden);

  const user_ids = (val) => {
    setUserIds(val);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys, selectedKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    let dataUser = [];
    selectedKeys.map((data) => {
      dataUser.push({
        user_id: data?.user_id,
        deviceId: data?.device_id,
      });
    });
    setUserIds(dataUser);
    console.log(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [tableData, setTableData] = useState();

  const loadLoanData = () => {
    setTableLoading(true);
    const endPoint = `loan/loan-list`;
    getAPIData(endPoint, "get", {}, false, "loan")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setLoading(false);
          if (!_.isEmpty(response) && _.isArray(response)) {
            setTableData(response);
            setTableLoading(false);
            console.log("response======>", response);
            const total = response?.length;
            console.log("Total=========>", total);
            setTotalCount(total);

            const approve = response?.filter(
              (data) => data?.action_status === "1"
            );
            setApprovedCount(approve.length);

            const followUp = response?.filter(
              (data) => data?.action_status === "2"
            );
            setFollowUpCount(followUp.length);

            const reject = response?.filter(
              (data) => data?.action_status === "-1"
            );
            setRejectCount(reject.length);

            const pending = response?.filter(
              (data) => data?.action_status === "3"
            );
            setPendingCount(pending.length);
          }
        } else {
          message.warning(result?.message);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    loadLoanData();
    loadAgentData();
  }, []);

  const handleClose = () => {
    setModalType("");
    setPage(1);
    setCurrent(1);
  };

  const pageType = "loan";

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <Spin spinning={spinLoading} size="large">
        <div className={`${loading ? "page-loading app" : "app"}`}>
          {/* {loading === true ? <CClickLoading /> : ""} */}
          <Layout
            style={{
              height: "100%",
              padding: "20px 0 0 20px",
              backgroundColor: "#DFFEFC",
            }}
          >
            <Sidebar open={open} setOpen={setOpen} />
            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                  backgroundColor: "#009D93",
                  borderRadius: "15px 0 0 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <MenuOutlined
                  className="trigger"
                  onClick={() => setOpen(true)}
                />
                <Title level={4} className="poppins_regular umoney_page_title">
                  {" "}
                  Statistics: Loan filter advisor
                </Title>
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  padding: 15,
                  backgroundColor: "#DFFEFC",
                }}
              >
                <div className="white_div">
                  <Row gutter={[16, 16]}>
                    {role === "superadmin" ||
                    role === "supervisor_caller" ||
                    role === "supervisor_approver" ||
                    role === "approver" ? (
                      <>
                        <Col span={8}>
                          <CSelectBtn
                            options={agentData}
                            size="large"
                            classes="poppins_regular filter_select_border"
                            btnvalue="AGENT"
                            onClick={handleAssignClick}
                            onChange={agentSelectChange}
                            btnLoading={assignLoading}
                          />
                        </Col>
                        <Col span={16}></Col>
                      </>
                    ) : (
                      ""
                    )}

                    <Col span={5}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="Total: "
                        className="poppins_medium umoney_outlined_btn"
                        countval={totalCount}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col span={5}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="Pending: "
                        className="poppins_medium umoney_outlined_btn"
                        style={{ width: "100%" }}
                        countval={pendingCount}
                      />
                    </Col>
                    <Col span={5}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="Follow up: "
                        className="poppins_medium umoney_outlined_btn"
                        countval={followUpCount}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col span={5}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="Approved: "
                        className="poppins_medium umoney_outlined_btn"
                        style={{ width: "100%" }}
                        countval={approvedCount}
                      />
                    </Col>
                    <Col span={4}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="Rejected: "
                        className="poppins_medium umoney_outlined_btn"
                        style={{ width: "100%" }}
                        countval={rejectCount}
                      />
                    </Col>
                    <Col span={5}>
                      <CLoadingButton
                        size="large"
                        value="CLEAR FILTERS"
                        className="poppins_medium umoney_btn"
                        style={{ width: "100%" }}
                        onClick={() => {
                          clearFilters();
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="white_div" style={{ marginTop: "20px" }}>
                  <div className="umoney_table" style={{width:"100%"}}>
                    <Table
                      dataSource={tableData}
                      pagination={{
                        showSizeChanger: true,
                        locale: { items_per_page: "" },
                      }}
                      rowKey={(record) => record.id}
                      rowSelection={
                        role === "superadmin" ||
                        role === "supervisor_caller" ||
                        role === "supervisor_approver" ||
                        role === "approver"
                          ? rowSelection
                          : ""
                      }
                      columns={
                        role === "superadmin" ||
                        role === "supervisor_caller" ||
                        role === "supervisor_approver" ||
                        role === "approver"
                          ? tableColumns
                          : newColumns
                      }
                      style={{
                        marginTop: "10px",
                      }}
                      scroll={{x:"100vw"}}
                      onChange={handleChange}
                      loading={tableLoading}
                    />
                  </div>
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
        <Modal
          open={modalType}
          title={modalTitle}
          footer={false}
          onCancel={handleClose}
          centered
          width={modalWidth}
        >
          {modalType === "algo" ? (
            <AlgoLoanFilterModal device_id={device_id} user_id={user_id} />
          ) : modalType === "confirm-status" ? (
            <ConfirmStatus
              device_id={device_id}
              user_id={user_id}
              followup_ts={followup_ts}
              onSave={() => {
                setModalType("");
              }}
              loadLoanData={loadLoanData}
              pageType={pageType}
            />
          ) : modalType === "confirmation" ? (
            <ConfirmationModalLoan />
          ) : modalType === "remark" ? (
            <Remark />
          ) : modalType === "call-history" ? (
            <CallHistoryLoanFilter />
          ) : modalType === "action-history" ? (
            <ActionHistoryLoanFilter
              user_id={user_id}
              device_id={device_id}
              modal={modal}
              setModal={setModal}
              current={current}
              setCurrent={setCurrent}
              page={page}
              setPage={setPage}
            />
          ) : modalType === "click-to-call" ? (
            <ClickToCallModal
              user_id={user_id}
              regNumber={regNumber}
              altNumber={altNumber}
            />
          ) : (
            ""
          )}
        </Modal>
      </Spin>
    </>
  );
};

export default LoanFilterAdvisor;
