import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Input, Col, Row, message } from "antd";
import CLoadingButton from "../components/InputFields/CLoadingButton";
import { getAPIData } from "../apiHelper";
import authActions from "../redux/reducers/auth/action";
import commonActions from "../redux/reducers/common/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../api";

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { setAccessToken } = authActions;
  const {
    setDepartmentData,
    setRoleData,
    setRoleName,
    setRoleCode,
    setUserData,
    setLoginTypeData,
  } = commonActions;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //  const path= localStorage.getItem("path");
  const path = localStorage.getItem("path");
  const isAuth = localStorage.getItem("token");
  const inputTagRef = useRef(null);

  const onFinish = (data) => {
    setLoading(true);
    const endPoint = "user-agent/login";
    const header = setHeader();
    getAPIData(endPoint, "post", data, header)
      .then((result) => {
        if (result?.status === true) {
          var resultValue = result?.data;
          localStorage.setItem("token", resultValue.token);
          dispatch(setAccessToken(resultValue.token));
          dispatch(setUserData(resultValue?.user_data));
          dispatch(setDepartmentData(resultValue?.user_data?.department_id));
          dispatch(setRoleData(resultValue?.user_data?.role_id));
          dispatch(setLoginTypeData(resultValue?.user_data?.login_type));
          message.success(result?.message);
          form.resetFields();
          navigate(path === null ? "/" : path);
          //To get the name of role
          const endPoint = "user-agent/single-role-data";
          const header = setHeader();
          const roledata = {
            role_id: resultValue?.user_data?.role_id,
          };
          getAPIData(endPoint, "post", roledata, header).then((result) => {
            if (result?.status === true) {
              dispatch(setRoleName(result?.data?.name));
              dispatch(setRoleCode(result?.data?.code));
            } else {
              console.log("Role name not set");
            }
          });
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus();
    }
  }, [inputTagRef.current]);

  useEffect(() => {
    console.log("fetch");
    if (isAuth !== null) {
      navigate("/");
    }
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Modal
        title="Login"
        centered
        open={true}
        mask={false}
        footer={null}
        closable={false}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
            {/* <Col className="gutter-row" span={24} > 
            <div style={{color:"red"}}>Username: admin, Password: 12345678</div>
            </Col> */}
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: "Username is required!" }]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter username"
                  ref={inputTagRef}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Password is required!" }]}
                className="poppins_medium mb-35"
              >
                <Input.Password size="large" placeholder="Enter password" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="Login"
                  className="poppins_medium umoney_btn"
                  loading={loading}
                  style={{ width: "25%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Login;
