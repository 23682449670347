import { Col, Row, Radio, Space, Form, Input, Modal, message } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { getAPIData } from "../../../apiHelper";
import CLoadingButton from "../../InputFields/CLoadingButton";
import ClickToCallModal2 from "./ClickToCallModal2";

const ClickToCallModal = ({
  user_id,
  type,
  altNumber,
  regNumber,
  paramname,
  agent_id,
}) => {
  const [form] = Form.useForm();
  const [fieldDisable, setFieldDisable] = useState(true);
  console.log("paramname", paramname);

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [callNowLoading, setCallNowLoading] = useState(false);

  const openModal = (type) => {
    if (type === "click-to-call-modal-2") {
      setModalType(type);
      setModalTitle("Click To Call");
      setModalWidth(500);
    }
  };

  const handleClose = () => {
    setModalType("");
  };

  useEffect(() => {
    form.setFieldValue("type", "");
    form.setFieldValue("phone_number", "");
  }, [altNumber, regNumber]);

  const onFinishFailed = () => {
    console.log("Failed");
  };

  const clickToCallFinish = (data) => {
    setCallNowLoading(true);
    const nData = { ...data, agent_dialer_id: agent_id };
    const endPoint = "student/dialer/call";
    getAPIData(endPoint, "post", nData, false, "student")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setCallNowLoading(false);
          form.resetFields();
        } else {
          message.warning(result?.message);
          setCallNowLoading(false);
        }
        setCallNowLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setCallNowLoading(false);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={paramname === "dialer-detail-view" ? clickToCallFinish : ""}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} className="poppins_medium">
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="type"
              size="large"
              // rules={[{ required: true, message: "Email id is required!" }]}
              className="poppins_medium mb-35"
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio
                    value={1}
                    style={{ fontSize: 18, margin: 2 }}
                    onChange={() => {
                      form.setFieldValue("phone_number", regNumber);
                      setFieldDisable(true);
                    }}
                  >
                    Registered number
                  </Radio>
                  <Radio
                    value={2}
                    style={{ fontSize: 18, margin: 2 }}
                    onChange={() => {
                      form.setFieldValue("phone_number", altNumber);
                      setFieldDisable(true);
                    }}
                  >
                    Alternate number
                  </Radio>
                  <Radio
                    value={3}
                    style={{ fontSize: 18, margin: 2 }}
                    onChange={() => {
                      setFieldDisable(false);
                      form.resetFields();
                    }}
                  >
                    Custom number
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="phone_number"
              size="large"
              // rules={[{ required: true, message: "Email id is required!" }]}
              className="poppins_medium mb-35"
            >
              <Input
                size="large"
                readOnly={fieldDisable === false ? false : true}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="CALL NOW"
                htmlType="submit"
                className="poppins_medium umoney_btn"
                block
                loading={callNowLoading}
                // onClick={() => openModal("click-to-call-modal-2")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={modalType}
        title={modalTitle}
        footer={false}
        onCancel={handleClose}
        centered={true}
        width={modalWidth}
      >
        {modalType === "click-to-call-modal-2" ? <ClickToCallModal2 /> : ""}
      </Modal>
    </div>
  );
};

export default ClickToCallModal;
