import React, { useState } from "react";
import { message, Table } from "antd";
import { getAPIData } from "../../../apiHelper";
import { useEffect } from "react";
import _ from "lodash";

export default function ActionHistory(props) {
  const [ActionHistoryData, setActionHistoryData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  const [pageSize, setPageSize] = useState(5);
  const [tablePagination, setTablePagination] = useState();
  const [totalPage, setTotalPage] = useState();

  const { user_id, bank_id, current, setCurrent, page, setPage } = props;

  useEffect(() => {
    const loadActionHistoryData = () => {
      setTableLoading(true);
      setActionHistoryData("");
      const data = {
        user_id: user_id,
        bank_id: bank_id,
        page: page,
        limit: pageSize,
      };
      const endPoint = `bank-agent/get-action-history`;
      console.log("endPoint", endPoint);
      getAPIData(endPoint, "post", data, false, "bankAcc")
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data?.result;
            var pagination = result?.data?.pagination;
            if(!_.isEmpty(response) && _.isArray(response)){
              setTablePagination(pagination);
              setTotalPage(result?.data?.pagination?.totalCount);
              setActionHistoryData(response);
            }
            setTableLoading(false);
          } else {
            message.warning(result?.message);
            setTableLoading(false);
          }
        })
        .catch((err) => {
          message.error(err);
          setTableLoading(false);
        });
    };
    loadActionHistoryData();
  }, [bank_id, props, user_id, page, pageSize, current]);

  const Actionhistorycolumns = [
    {
      title: "TIMESTAMP",
      dataIndex: "createdAt",
    },
    {
      title: "ACTION BY",
      dataIndex: "action_by",
      render: (text, record) => (
        <span>
          {record?.agentName} ({record?.user_id})
        </span>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "action_button",
      render: (_noUse, data) => (
        <div>
          <span>
            {!_.isEmpty(data?.action_button)
              ? data?.action_button === "1"
                ? "Approve"
                : data?.action_button === "-1"
                ? "Reject"
                : data?.action_button === "2"
                ? "Follow Up"
                : data?.action_button === "3"
                ? "Document Upload"
                : data?.action_button === "4"
                ? "Pending"
                : ""
              : "-"}
          </span>
        </div>
      ),
    },
    {
      title: "COMMENT",
      dataIndex: "comment",
    },

    {
      title: "APPROVE FINAL REMARK",
      dataIndex: "approve_reason_1",
    },
    {
      title: "FOLLOW-UP TIMESTAMP",
      dataIndex: "followup_ts",
    },
  ];

  console.log("TotalPage", totalPage);

  return (
    <div>
      <div className="umoney_table">
        <Table
          columns={Actionhistorycolumns}
          dataSource={ActionHistoryData}
          pagination={
            ActionHistoryData === ""
              ? false
              : {
                  position: ["none", "bottomLeft"],
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                  defaultPageSize: 5,
                  pageSizeOptions: [5, 10, 15, 20],
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                    setCurrent(page);
                  },
                  total: totalPage,
                  current: page,
                }
          }
          loading={tableLoading}
        />
      </div>
    </div>
  );
}
