import { Col, Modal, Row } from "antd";
import React, { useState, useEffect } from "react";
import CLoadingButton from "../../InputFields/CLoadingButton";
import ConfirmationModalLoan from "./ConfirmationModalLoan";

const ConfirmStatus = ({ device_id, followup_ts, user_id, onSave, loadLoanData, pageType}) => {
  const [loading, setLoading] = useState(false);
  const [approveBtnLoading, setApprovedBtnLoading] = useState(false);
  const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
  const [followupBtnLoading, setFollowupBtnLoading] = useState(false);

  const handleClose = () => {
    setModalType("");
  };

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [action_button, setAction_button] = useState("");

  // const approveClick = () => {
  //   setLoading(true);
  //   setApprovedBtnLoading(true);
  // };
  // const rejectClick = () => {
  //   setLoading(true);
  //   setRejectBtnLoading(true);
  // };
  // const followupClick = () => {
  //   setLoading(true);
  //   setFollowupBtnLoading(true);
  // };

  const openModal = (type) => {
    if (type === "confirmation-modal") {
      setModalType(type);
      setModalTitle("Confirmation");
      setModalWidth(500);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <CLoadingButton
            size="large"
            value="APPROVE"
            className="poppins_medium urban_money_btn"
            onClick={() => {
              openModal("confirmation-modal");
              setAction_button("1");
              onSave();
            }}
            loading={approveBtnLoading}
            style={{
              background: "#3EA467 0% 0% no-repeat padding-box",
              marginTop: "0px",
              borderRadius: "5px",
            }}
            block
          />
        </Col>
        <Col span={8}>
          <CLoadingButton
            size="large"
            value="REJECT"
            className="poppins_medium urban_money_btn"
            onClick={() => {
              openModal("confirmation-modal");
              setAction_button("-1");
              onSave();
            }}
            loading={rejectBtnLoading}
            style={{
              background: "#DE1717 0% 0% no-repeat padding-box",
              marginTop: "0px",
              borderRadius: "5px",
            }}
            block
          />
        </Col>
        <Col span={8}>
          <CLoadingButton
            size="large"
            value="FOLLOW-UP"
            className="poppins_medium urban_money_btn"
            onClick={() => {
              openModal("confirmation-modal");
              setAction_button("2");
              onSave();
            }}
            loading={followupBtnLoading}
            style={{
              background: "#4C9AFF 0% 0% no-repeat padding-box",
              marginTop: "0px",
              borderRadius: "5px",
            }}
            block
          />
        </Col>
      </Row>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={handleClose}
        centered
        width={modalWidth}
        footer={false}
      >
        {modalType === "confirmation-modal" ? (
          <ConfirmationModalLoan
            handleClose={handleClose}
            device_id={device_id}
            followup_ts={followup_ts}
            user_id={user_id}
            action_button={action_button}
            onClose={() => setModalType("")}
            loadLoanData={loadLoanData}
            pageType={pageType}
          />
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default ConfirmStatus;
