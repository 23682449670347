import { Modal, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import _ from "lodash";

const { Text } = Typography;

const CropperModal = (props) => {
  const { open, title, setOpen, modalType, imageUrl, srcImg, setImageDoc } =
    props;
  console.log("imageUrl", imageUrl);

  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setResult(null);
    setImage(null);
    setImageDoc("");
    setCrop({
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50,
    });
  };

  const getCroppedImg = async () => {
    try {
      setResult(null);
      const canvas = document.createElement("canvas");
      const scaleX = image.target.naturalWidth / image.target.width;
      const scaleY = image.target.naturalHeight / image.target.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      console.log("image", image);
      ctx.drawImage(
        image.target,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      const base64Image = canvas.toDataURL("image/jpeg");
      setResult(base64Image);
    } catch (e) {
      console.log(e);
      console.log("crop the image");
    }
  };

  const setFinalCroppedImage = () => {
    console.log("result", result);
    if (_.isEmpty(result)) {
      setImageDoc(srcImg);
    } else {
      setImageDoc(result);
      setResult(null);
    }
    setOpen(false);
    setCrop({
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50,
    });
  };

  // console.log("image===========------->", imageDoc)

  const onLoad = (img) => {
    console.log("on load function");
    console.log(img);
    setImage(img);
  };

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={600}
        onOk={setFinalCroppedImage}
        maskClosable={false}
        okButtonProps={{
          style: { borderRadius: "5px", width: "150px" },
          disabled: result === null ? true : false,
        }}
        cancelButtonProps={{ style: { borderRadius: "5px", width: "150px" } }}
      >
        <div>
          {srcImg && (
            <div style={{ border: "2px dashed #009d93" }}>
              <ReactCrop
                style={{ width: "100%" }}
                crop={crop}
                onChange={setCrop}
                onComplete={getCroppedImg}
              >
                <img
                  src={srcImg}
                  id="canvas"
                  onLoad={onLoad}
                  style={{ width: "100%", height: "100%" }}
                />
              </ReactCrop>
            </div>
          )}
          {/* {result && (
            <div
              className="col-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Text className="poppins_medium mt-20">Cropped Image : </Text>
              <img src={result} alt="cropped image" className="mt-10" />
            </div>
          )} */}
        </div>
      </Modal>
    </div>
  );
};

export default CropperModal;
