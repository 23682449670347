import { isObject, isString } from "lodash";
import {
  api_url,
  bank_acc_url,
  bank_url,
  coin_url,
  loan_url,
  student_url,
  user_url,
  device_change_url,
  student_cc_url,
  salaried_url,
  credit_check_url,
  stdtosal_url,
} from "./api";

export default function getAPIProgressData(
  endpoint,
  method,
  data,
  headers = true,
  onProgress = null
) {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    const token = localStorage.getItem("token");
    const authheaders = {
      "Content-Type": "application/json",
      Authorization: token ? "Bearer " + token : "",
    };
    return new Promise((resolve, reject) => {
      const url = api_url + endpoint;
      const oReq = new XMLHttpRequest();
      oReq.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded * 100) / event.total;
          if (onProgress) {
            onProgress(progress);
          }
        }
      });

      var FormData = require("form-data");
      var form = new FormData();
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).map((k) => form.append(k, data[k]));
      }

      const hData = {
        "Content-Type": "multipart/form-data",
      };

      let options = {
        method: method,
        headers: headers ? authheaders : hData,
        body: form,
      };

      delete options.headers["Content-Type"];

      fetch(url, options)
        .then(function (res) {
          const result = res.json();
          resolve(result);
        })
        .then(function (result) {
          if (result.code === 401) {
            localStorage.clear();
            window.location.reload();
            resolve(result);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}

export function getAPIData(
  endpoint,
  method,
  data,
  headers = false,
  port = "admin"
) {
  const authheaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  return new Promise((resolve, reject) => {
    let query = "";
    let qs = "";

    for (const key in data) {
      if (query !== "") {
        query += "&";
      }
      query += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    }
    const params = {};
    params.method = method.toLowerCase() === "get" ? "get" : "post";
    if (headers) {
      params.headers = headers.headers;
    } else {
      params.headers = authheaders;
    }
    if (params.method === "post") {
      if (
        params.headers &&
        params.headers["Content-Type"] &&
        params.headers["Content-Type"] === "application/json"
      ) {
        params.body = JSON.stringify(data);
      } else {
        params.body = query;
      }
    } else {
      qs = `?${query}`;
    }

    if (
      params.method === "post" &&
      params.headers &&
      params.headers["Content-Type"] &&
      params.headers["Content-Type"] === "application/json"
    ) {
      // console.log(JSON.stringify(data));
    } else {
      let str = "";
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).map((dk) => (str += `${dk}:${data[dk]}\n`));
      }
    }

    var final_url;
    if (port === "admin") {
      final_url = api_url + endpoint + qs;
    } else if (port === "student") {
      final_url = student_url + endpoint + qs;
    } else if (port === "user") {
      final_url = user_url + endpoint + qs;
    } else if (port === "bankAcc") {
      final_url = bank_acc_url + endpoint + qs;
    } else if (port === "loan") {
      final_url = loan_url + endpoint + qs;
    } else if (port === "bank") {
      final_url = bank_url + endpoint + qs;
  } else if (port === "coin") {
      final_url = coin_url + endpoint + qs;
    } else if (port === "device_change") {
      final_url = device_change_url + endpoint + qs;
    } else if (port === "salaried") {
      final_url = salaried_url + endpoint + qs;
    } else if (port === "credit_check") {
      final_url = credit_check_url + endpoint + qs;
    } else if (port === "stu_to_sal") {
      final_url = stdtosal_url + endpoint + qs;
    }

    fetch(final_url, params)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.code === 401) {
          localStorage.clear();
          window.location.reload();
          resolve(responseJson);
        } else if (
          isObject(responseJson) &&
          isString(responseJson.message) &&
          responseJson.code === 402
        ) {
          resolve(responseJson);
          localStorage.clear();
          window.location.reload();
        } else {
          resolve(responseJson);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
