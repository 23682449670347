import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Layout, message, Spin } from "antd";
import Sidebar from "../../components/Sidebar";
import { MenuOutlined } from "@ant-design/icons";
import TableComponent from "../BankAccount/Component/TableComponent";
import CSelectBtn from "../../components/InputFields/CSelectBtn";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import { getAPIData } from "../../apiHelper";
import { bank_acc_url } from "../../api";
import { setHeader } from "../../api";
import axios from "axios";
import { useSelector } from "react-redux";

const { Title } = Typography;
const { Header, Content } = Layout;

const BankAccountApproval = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);

  const role = useSelector((state) => state.userData.accessRoleCode);
  const role_name = useSelector((state) => state.userData.accessRoleName);

  //sorting Btns
  const [totalCount, setTotalCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [followupCount, setFollowupCount] = useState(0);
  const [documentUploadCount, setDocumentUploadCount] = useState(0);

  //Agent List Api
  const [agentListData, setAgentListData] = useState();
  const [agentDrpData, setAgentDrpData] = useState();

  // assign agent
  const [userIds, setUserIds] = useState([]);
  const [selectedJunior, setSelectedJunior] = useState("");
  const [assignLoading, setAssignLoading] = useState(false);

  const loadAgentList = () => {
    setLoading(true);
    const endPoint = "bank-agent/agents-list";
    let drpData = [];
    getAPIData(endPoint, "get", {}, false, "bankAcc")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setAgentListData(response);
          response.map((data) =>
            drpData.push({
              label:
                data?.first_name +
                " " +
                data?.middle_name +
                " " +
                data?.last_name,
              value: data?.user_id,
            })
          );
          setAgentDrpData(drpData);
          // setLoading(false)
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };

  //Assign Profile
  const handleAssignClick = async () => {
    setSpinLoading(true);
    setAssignLoading(true);
    const endPoint = bank_acc_url + "bank-agent/assign-bank-accounts";
    const header = setHeader();
    const data = {
      userIds: JSON.stringify(userIds),
      approver_id: selectedJunior,
    };
    const assign = await axios.post(endPoint, data, header);
    if (assign?.data?.status) {
      loadAgentList();
      message.success(assign?.data?.message);
      setAssignLoading(false);
      setSelectedJunior("");
      setUserIds("");
    } else {
      message.error(assign?.data?.message);
      setAssignLoading(false);
    }
    setSpinLoading(false);
    setAssignLoading(false);
  };

  const agentSelectChange = (juniorId) => {
    setSelectedJunior(juniorId);
  };

  useEffect(() => {
    loadAgentList();
  }, []);

  return loading === true ? (
    <CClickLoading />
  ) : role === "caller" ? (
    <Spin spinning={spinLoading} size="large">
      <div className={`${loading ? "page-loading app" : "app"}`}>
        <Layout
          style={{
            padding: "20px 0px 0px 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title">
                {role_name}'s Bank Account Approval
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: 15,
                borderRadius: "5px",
                backgroundColor: "#DFFEFC",
              }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  <Col className="gutter-row" span={6}></Col>
                  <Col className="gutter-row" span={6}></Col>
                  <Col span={12}></Col>
                  <Col span={5}>
                    <CLoadingButton
                      size="large"
                      value="Total:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={totalCount}
                      block
                    />
                  </Col>
                  <Col span={5}>
                    <CLoadingButton
                      size="large"
                      value="Pending"
                      className="poppins_medium umoney_outlined_btn"
                      countval={pendingCount}
                      block
                    />
                  </Col>
                  <Col span={5}>
                    <CLoadingButton
                      size="large"
                      value="Follow up:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={followupCount}
                      block
                    />
                  </Col>
                  <Col span={5}>
                    <CLoadingButton
                      size="large"
                      value="Approved:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={approvedCount}
                      block
                    />
                  </Col>
                  <Col span={4}>
                    <CLoadingButton
                      size="large"
                      value="Rejected:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={rejectedCount}
                      block
                    />
                  </Col>
                  <Col span={4}>
                    <CLoadingButton
                      size="large"
                      value="Document upload:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={documentUploadCount}
                      block
                    />
                  </Col>
                </Row>
              </div>
              <div className="white_div" style={{ backgroundColor: "white" }}>
                <Row>
                  <Col span={24}>
                    <TableComponent
                      setTotalCount={setTotalCount}
                      setApprovedCount={setApprovedCount}
                      setPendingCount={setPendingCount}
                      setRejectedCount={setRejectedCount}
                      setFollowupCount={setFollowupCount}
                      setDocumentUploadCount={setDocumentUploadCount}
                      role={role}
                      user_ids={(val) => {
                        setUserIds(val);
                      }}
                      onSave={() => {
                        loadAgentList();
                      }}
                      setLoading={setLoading}
                    />
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </Spin>
  ) : (
    <Spin spinning={spinLoading} size="large">
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            padding: "20px 0 20px 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title">
                {role_name}'s Bank Account Approval
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: "0px 0px",
                borderRadius: "5px",
              }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
                  {/* {role === "approver" ? (
                    <Col span={8}></Col>
                  ) : ( */}
                  <Col className="gutter-row" span={6}>
                    <CSelectBtn
                      options={agentDrpData}
                      size="large"
                      classes="poppins_regular filter_select_border"
                      btnvalue="AGENT"
                      placeholder="Select Agent"
                      onChange={agentSelectChange}
                      onClick={handleAssignClick}
                      btnLoading={assignLoading}
                    />
                  </Col>
                  {/* )} */}
                  <Col span={8}></Col>
                  <Col className="gutter-row" span={8}></Col>
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="Total:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={totalCount}
                      block
                    />
                  </Col>
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="Pending:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={pendingCount}
                      block
                    />
                  </Col>
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="Follow up:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={followupCount}
                      block
                    />
                  </Col>
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="Approved:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={approvedCount}
                      block
                    />
                  </Col>
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="Rejected:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={rejectedCount}
                      block
                    />
                  </Col>
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="Document upload:"
                      className="poppins_medium umoney_outlined_btn"
                      countval={documentUploadCount}
                      block
                    />
                  </Col>
                </Row>
              </div>
              <div className="white_div">
                <Row>
                  <Col span={24}>
                    <TableComponent
                      setTotalCount={setTotalCount}
                      setApprovedCount={setApprovedCount}
                      setPendingCount={setPendingCount}
                      setRejectedCount={setRejectedCount}
                      setFollowupCount={setFollowupCount}
                      setDocumentUploadCount={setDocumentUploadCount}
                      role={role}
                      user_ids={(val) => {
                        setUserIds(val);
                      }}
                      onSave={() => {
                        loadAgentList();
                      }}
                      setLoading={setLoading}
                    />
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </Spin>
  );
};
export default BankAccountApproval;
