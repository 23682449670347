import { Col, Modal, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

const CourseModel = (props) => {
  const {
    onSave = () => {},
    courseId,
    modalType,
    modalTitle,
    setModalType,
    course_type,
    isMerge,
  } = props;

  const [form] = Form.useForm();
  const [savebtnLoading, setSaveBtnLoading] = useState(false);
  const [courseData, setCourseData] = useState([]);

  const handleClose = () => {
    setModalType(false);
  };

  if (courseId && _.isEmpty(courseData)) {
    const endPoint = "course/get/" + courseId;
    const data = {
      column: ["course_type", "course_name"],
    };

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCourseData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  const onSubmitData = (data) => {
    setSaveBtnLoading(true);
    var endPoint =
      modalType === "add-course"
        ? "course/create"
        : "course/update/" + courseId;

    if (isMerge) {
      endPoint = "course/merge";
      data.course_id = courseId;
    }
    data.dynamic_data_param = "1";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo===>>", errorInfo);
  };

  useEffect(() => {
    if (modalType === "add-course") {
      form.resetFields();
    } else {
      form.setFieldsValue(courseData);
    }
  }, [form, courseData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={500}
      footer={null}
      closable={true}
      centered={true}
    >
      {/* <div className="white_div boxshadow-div"> */}
      <Form
        name="basic"
        onFinish={(data) => {
          onSubmitData(data);
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Name of course"
              name="course_name"
              rules={[
                {
                  required: true,
                  message: "Name of course is required!",
                },
                {
                  message: "Please Enter Only Characters",
                  pattern: new RegExp(/[a-zA-Z]/),
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="Enter name of course" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Course type"
              name="course_type"
              rules={[
                {
                  required: true,
                  message: "Course type is required!",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Select className="br" size="large" options={course_type} placeholder="Select course type" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={24}>
            <CLoadingButton
              htmlType="submit"
              size="large"
              value=" SAVE"
              className="poppins_medium umoney_modal_filled_btn"
              loading={savebtnLoading}
              block
            />
          </Col>
          <Col className="gutter-row" span={18}></Col>
        </Row>
      </Form>
      {/* </div> */}
    </Modal>
  );
};

export default CourseModel;
