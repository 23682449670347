import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Table, Tag, message } from "antd";
import { getAPIData } from "../../../apiHelper";
import {
  CheckSquareOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined,
  BorderOutlined,
} from "@ant-design/icons";

const TargetDevice = (props) => {
  const loginuser = "2 | 500";
  const count = "5 | 2000";
  const install = "Yes/NO";

  const { userId, deviceId, modalType } = props;

  const { Title } = Typography;

  const [targetData, setTargetData] = useState();
  const [permissionData, setPermissionData] = useState();
  const [lastLoginUserData, setLastLoginUserData] = useState();
  const [currentUserData, setCurrentUserData] = useState();
  const [loginHistory, setLoginHistory] = useState();
  const [primaryData, setPrimaryData] = useState();
  const [syncData, setSyncData] = useState();
  const [overdueData, setOverDueData] = useState();
  const [deviceDetails, setDeviceDetails] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  const loadData = () => {
    setTargetData();
    setPermissionData();
    setLastLoginUserData();
    setCurrentUserData();
    setLoginHistory();
    setPrimaryData();
    setSyncData();
    setOverDueData();
    setDeviceDetails();
    setTableLoading(true);
    const data = { user_id: userId, device_id: deviceId };
    const endPoint = "device/device-linked-data";
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setTargetData(response);
          setPermissionData(response?.current_device_permission);
          setLastLoginUserData([response?.last_login_user]);
          setCurrentUserData([response?.current_primary_user]);
          setLoginHistory(response?.login_history);
          setPrimaryData(response?.past_primary_users);
          setSyncData(response?.data_sync);
          setOverDueData(response?.overdue);
          setDeviceDetails(response?.DeviceDetails);
          setTableLoading(false);
        } else {
          message?.warning(result?.message);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, [props]);

  const CurrentPermissioncolumns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "80%",
    },
    {
      title: "PERMISSION",
      dataIndex: "permission",
      key: "permission",
    },
  ];

  const CurrentPermissiondata = [
    {
      module: "Location",
      permission: (
        <div>
          <span>
            {permissionData?.location === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.location === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      module: "Contacts",
      permission: (
        <div>
          <span>
            {permissionData?.contacts === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.contacts === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      module: "SMS",
      permission: (
        <div>
          <span>
            {permissionData?.sms === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.sms === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      module: "Phone",
      permission: (
        <div>
          <span>
            {permissionData?.phone === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.phone === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      module: "Call Logs",
      permission: (
        <div>
          <span>
            {permissionData?.call_logs === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.call_logs === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      module: "Camera",
      permission: (
        <div>
          <span>
            {permissionData?.camera === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.camera === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      module: "Storage",
      permission: (
        <div>
          <span>
            {permissionData?.storage === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.storage === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
    {
      module: "Installed Applications",
      permission: (
        <div>
          <span>
            {permissionData?.installed_app === "0" ? (
              <CheckSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.installed_app === "1" ? (
              <MinusSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.installed_app === "-1" ? (
              <CloseSquareOutlined style={{ fontSize: "20px" }} />
            ) : permissionData?.installed_app === "2" ? (
              <BorderOutlined style={{ fontSize: "20px" }} />
            ) : (
              "-"
            )}
          </span>
        </div>
      ),
    },
  ];

  const lastLoginColumns = [
    {
      title: "USER DETAILS",
      dataIndex: "userdetails",
      key: "userdetails",
      render: (text, record) => (
        <div>
          <span>
            {record.name} ({record?.user_id})
          </span>
          <br />
          <span>{record.mobile_number}</span>
          <br />
          <span>{record?.email}</span>
        </div>
      ),
    },
    {
      title: "TIME STAMP",
      dataIndex: "loginAt",
      key: "loginAt",
    },
    {
      title: "DEVICE STATUS AT THAT TIME",
      dataIndex: "current_device_status",
      key: "current_device_status",
      render: (text, record) => (
        <div>
          <span>
            {record.current_device_status === "0"
              ? "Not Primary"
              : record.current_device_status === "1"
              ? "Primary"
              : record.current_device_status === "2"
              ? "Login"
              : record.current_device_status === "3"
              ? "Temporary"
              : ""}
          </span>
        </div>
      ),
    },
    {
      title: "USER OVERDUE",
      dataIndex: "user_overdue",
      key: "user_overdue",
    },
  ];

  const currentUserColumns = [
    {
      title: "USER DETAILS",
      dataIndex: "userdetails",
      key: "userdetails",
      render: (text, record) => (
        <div>
          <span>
            {record?.name} ({record?.user_id})
          </span>
          <br />
          <span>{record?.mobile_number}</span>
          <br />
          <span>{record?.email}</span>
        </div>
      ),
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "APPROVAL TIME STAMP",
      dataIndex: "set_as_primaryAt",
      key: "set_as_primaryAt",
    },
    {
      title: "TRANSFER TIME STAMP",
      dataIndex: "set_as_primaryAt",
      key: "set_as_primaryAt",
    },
    {
      title: "USER OVERDUE",
      dataIndex: "user_overdue",
      key: "user_overdue",
    },
    {
      title: "FIRST LOGIN TIME STAMP BY USER",
      dataIndex: "first_login_time",
      key: "first_login_time",
    },
    {
      title: "LAST LOGIN TIME STAMP BY USER",
      dataIndex: "last_login_time",
      key: "last_login_time",
    },
  ];
  const dataSyncColumns = [
    {
      title: "USER DETAILS",
      dataIndex: "userdetails",
      key: "userdetails",
    },
    {
      title: "TIME STAMP",
      dataIndex: "timestamp",
      key: "timestamp",
    },
    {
      title: "CONTACTS",
      dataIndex: "contacts",
      key: "contacts",
    },
    {
      title: "CALL LOGS",
      dataIndex: "calllogs",
      key: "calllogs",
    },
    {
      title: "???",
      dataIndex: "???",
      key: "???",
    },
    {
      title: "???",
      dataIndex: "???",
      key: "???",
    },
    {
      title: "???",
      dataIndex: "???",
      key: "???",
    },
  ];

  const dataSyncData = [];
  for (let i = 1; i <= 3; i++) {
    dataSyncData.push({
      key: i,
      userdetails: (
        <div>
          <span>Name(Id)</span>
          <br />
          <span>Phone No:</span>
          <br />
          <span>Email</span>
        </div>
      ),
      timestamp: "",
      contacts: (
        <span>
          <Tag className="tag-form">EXTRACT</Tag>
        </span>
      ),
      calllogs: (
        <span>
          <Tag className="tag-form">EXTRACT</Tag>
        </span>
      ),
    });
  }

  const overdueDeviceColumns = [
    {
      title: "USER DETAILS",
      dataIndex: "userdetails",
      key: "userdetails",
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "OVERDUE LOAN",
      dataIndex: "overdueloan",
      key: "overdueloan",
    },
    {
      title: "OVERDUE LOAN AMOUNT",
      dataIndex: "overdueloanamount",
      key: "overdueloanamount",
    },
    {
      title: "LAST PAYMENT",
      dataIndex: "lastpayment",
      key: "lastpayment",
    },
  ];

  const loginHistoryColumns = [
    {
      title: "USER DETAILS",
      dataIndex: "userdetails",
      key: "userdetails",
      render: (text, record) => (
        <div>
          <span>
            {record.first_name} {record?.middle_name} {record?.last_name} (
            {record?.user_id})
          </span>
          <br />
          <span>{record.mobile_number}</span>
          <br />
          <span>{record?.email}</span>
        </div>
      ),
    },
    {
      title: "TIME STAMP",
      dataIndex: "loginAt",
      key: "loginAt",
    },
    {
      title: "DEVICE STATUS AT THAT TIME",
      dataIndex: "current_device_status",
      key: "current_device_status",
      render: (text, record) => (
        <div>
          <span>
            {record?.current_device_status === "0"
              ? "Not Primary"
              : record?.current_device_status === "1"
              ? "Primary"
              : record?.current_device_status === "2"
              ? "Login"
              : record?.current_device_status === "3"
              ? "Temporary"
              : ""}
          </span>
        </div>
      ),
    },
    {
      title: "USER OVERDUE",
      dataIndex: "user_overdue",
      key: "user_overdue",
    },
  ];

  const primaryUserHistoryColumns = [
    {
      title: "USER DETAILS",
      dataIndex: "userdetails",
      key: "userdetails",
      render: (text, record) => (
        <div>
          <span>
            {record.first_name} {record?.middle_name} {record?.last_name} (
            {record?.user_id})
          </span>
          <br />
          <span>{record.mobile_number}</span>
          <br />
          <span>{record?.email}</span>
        </div>
      ),
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "APPROVAL TIME STAMP",
      dataIndex: "set_as_primaryAt",
      key: "set_as_primaryAt",
    },
    {
      title: "TRANSFER TIME STAMP",
      dataIndex: "set_as_primaryAt",
      key: "set_as_primaryAt",
    },
    {
      title: "USER OVERDUE",
      dataIndex: "user_overdue",
      key: "user_overdue",
    },
    {
      title: "FIRST LOGIN TIME STAMP BY USER",
      dataIndex: "first_login_time",
      key: "first_login_time",
    },
    {
      title: "LAST LOGIN TIME STAMP BY USER",
      dataIndex: "last_login_time",
      key: "last_login_time",
    },
  ];

  return (
    <div className="poppins_regular">
      <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
        <Col span={3}>
          <Row>Make:</Row>
          <Row>Model:</Row>
          <Row>Variant:</Row>
          <Row>IMEI:</Row>
          <Row>G-AD-ID:</Row>
          <Row>OS:</Row>
        </Col>
        <Col className="poppins-medium" style={{ color: "#009D93" }} span={10}>
          <Row>{deviceDetails?.make ? deviceDetails?.make : "-"}</Row>
          <Row>
            {deviceDetails?.device_model ? deviceDetails?.device_model : "-"}
          </Row>
          <Row>{deviceDetails?.variant ? deviceDetails?.variant : "-"}</Row>
          <Row>{deviceDetails?.imei_no ? deviceDetails?.imei_no : "-"}</Row>
          <Row>{deviceDetails?.g_ad_id ? deviceDetails?.g_ad_id : "-"}</Row>
          <Row>{deviceDetails?.os ? deviceDetails?.os : "-"}</Row>
        </Col>
        <Col span={9}>
          <Row>Login Users Overdue Count and Amount:</Row>
          <Row>Primary users Overdue Count and Amount:</Row>
          <Row>Is app installed?</Row>
        </Col>
        <Col className="poppins-medium" style={{ color: "#009D93" }} span={2}>
          {/* Need to set dynamic */}
          <Row>{loginuser}</Row>
          <Row>{count}</Row>
          <Row>{install}</Row>
        </Col>
      </Row>
      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Current permission status
          </Title>
        </Row>
        <Table
          dataSource={CurrentPermissiondata}
          pagination={false}
          columns={CurrentPermissioncolumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            LAST LOGIN USER DETAILS AND TIMESTAMP
          </Title>
        </Row>
        <Table
          dataSource={lastLoginUserData}
          pagination={false}
          columns={lastLoginColumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Current Primary User Details
          </Title>
        </Row>
        <Table
          dataSource={currentUserData}
          pagination={false}
          columns={currentUserColumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Data sync
          </Title>
        </Row>
        {/* FUNCTIONALITY IS PENDING - missing data in response */}
        <Table
          dataSource={syncData}
          pagination={false}
          columns={dataSyncColumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>
      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Overdue on device
          </Title>
        </Row>
        {/* FUNCTIONALITY IS PENDING - missing data in response */}
        <Table
          dataSource={overdueData}
          pagination={false}
          columns={overdueDeviceColumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>
      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Login history
          </Title>
        </Row>
        <div className="umoney_table">
          <Table
            dataSource={loginHistory}
            columns={loginHistoryColumns}
            style={{
              border: "1px solid #009D93",
              borderRadius: "0px 0px 5px 5px",
              marginBottom: "10px",
              paddingBottom: "2px",
            }}
            loading={tableLoading}
            pagination={{
              position: ["none", "bottomLeft"],
              locale: { items_per_page: "" },
              showSizeChanger: true,
              defaultPageSize: 5,
              pageSizeOptions: [5, 10, 15, 20],
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Row style={{ border: "1px solid #009D93", padding: "10px" }}>
          <Title
            level={5}
            className="poppins_medium"
            style={{ color: " #009D93" }}
          >
            Primary user's history
          </Title>
        </Row>
        {/* FUNCTIONALITY IS PENDING - missing data in response */}
        <Table
          dataSource={primaryData}
          pagination={false}
          columns={primaryUserHistoryColumns}
          style={{
            border: "1px solid #009D93",
            borderRadius: "0px 0px 5px 5px",
            marginBottom: "10px",
            paddingBottom: "2px",
          }}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};

export default TargetDevice;
