import React, { useEffect, useState } from "react";
import { Modal, Form, Col, Row, Input, Select, message } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";

const CollectionDaysModals = (props) => {
  const {
    title,
    open,
    setOpen,
    modalType,
    smsData,
    singleData,
    onFinishApi = () => {},
  } = props;
  const id = singleData?.id;

  const [form] = Form.useForm();

  const typeList = [
    {
      value: "1",
      label: "Calls",
    },
    {
      value: "2",
      label: "SMS",
    },
    {
      value: "3",
      label: "Email",
    },
    {
      value: "4",
      label: "Notification",
    },
  ];

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "Add"
        ? "system-config/collection-days/create"
        : "system-config/collection-days/update/" + id;

    data.dynamic_data_param = "1";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          setOpen(false);
          onFinishApi(result?.status);
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setOpen(false);
    //setModalType("Add");
    console.log("closed");
    form.resetFields();
  };

  useEffect(() => {
    if (!_.isEmpty(singleData) && modalType === "Edit") {
      console.log("helloo");
      form.setFieldsValue(singleData);
    }
  }, [singleData, modalType, form]);

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        footer={null}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={500}
      >
        {modalType === "Add" || modalType === "Edit" ? (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={modalType === "Edit" ? smsData : ""}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Reminder"
                  name="reminder"
                  rules={[{ required: true, message: "Reminder is required!" }]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter reminder" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label={"Before due days"}
                  name="days"
                  rules={[
                    { required: true, message: "Before due days is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter before due days" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    { required: true, message: "Type is required!" },
                    //{ whitespace: true, message: "This field can not contain whitespaces." }
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    style={{ width: "100%" }}
                    disabled={modalType === "Add" ? false : true}
                    options={typeList}
                    placeholder="Enter type"
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : modalType === "add-new-loan" || modalType === "edit-new-loan" ? (
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Minimum days"
                  name="min_days"
                  rules={[
                    { required: true, message: "Minimum days is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter minimum days" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Maximum days"
                  name="max_days"
                  rules={[
                    { required: true, message: "Maximum days is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter maximum days" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  label="Interest rate"
                  name="interest_rate"
                  rules={[
                    { required: true, message: "Interest rate is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter interest rate" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={8}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : modalType === "add-call" ? (
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Calls"
                  name="calls"
                  rules={[
                    { required: true, message: "Calls is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                    {
                      message: "Please Enter Number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter calls" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default CollectionDaysModals;
