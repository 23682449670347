import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { getAPIData } from "../../../apiHelper";
import CLoadingButton from "../../InputFields/CLoadingButton";
import _ from "lodash";

const InformationToUserModal = (props) => {
  const {
    onSave = () => {},
    modalType,
    modalTitle,
    setModalType,
    editId,
    iconList,
  } = props;

  const [form] = Form.useForm();

  const [selectEditData, setSelectEditData] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  console.log("editData======>", selectEditData);
  console.log("editID======>", editId);

  const handleClose = () => {
    setModalType("");
  };

  const moduleData = [
    {
      value: "1",
      label: "KYC",
    },
    {
      value: "2",
      label: "PAN",
    },
    {
      value: "3",
      label: "Personal Information",
    },
    {
      value: "4",
      label: "Student Information",
    },
    {
      value: "5",
      label: "Selfie",
    },
    {
      value: "6",
      label: "Video",
    },
  ];

  if (editId && _.isEmpty(selectEditData)) {
    const data = { info_id: editId };
    const endPoint = "system-config/get-info";
    getAPIData(endPoint, "post", data, false, "admin")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setSelectEditData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  const onFinishFailed = () => {
    console.log("Failed");
  };

  const submitData = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "add-user"
        ? "system-config/add-info"
        : "system-config/update-info";
    getAPIData(
      endPoint,
      "post",
      modalType === "add-user" ? data : { ...data, info_id: editId },
      false,
      "admin"
    )
      .then((result) => {
        if (result?.status === true) {
          onSave();
          setSaveBtnLoading(false);
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  useEffect(() => {
    if (modalType === "add-user") {
      form.resetFields();
    } else {
      form.setFieldsValue(selectEditData);
    }
  }, [form, selectEditData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={1000}
      footer={null}
      closable={true}
      centered={true}
    >
      <div>
        <Form
          name="basic"
          onFinish={submitData}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="SMS Message Body"
                name="sms_message_body"
                rules={[
                  {
                    required: true,
                    message: "Sms message is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the SMS message" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Whatsapp Message Body"
                name="whatsapp_message_body"
                rules={[
                  {
                    required: true,
                    message: "Whatsapp message is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the whatsapp message" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Slug"
                name="slug"
                rules={[
                  {
                    required: true,
                    message: "slug is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter the slug"
                  disabled={modalType === "edit-user" ? true : false}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Dynamic Data param"
                name="dynamic_data_param"
                rules={[
                  {
                    required: true,
                    message: "dynamic data param is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter the dynamic data param"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Module"
                name="module"
                rules={[
                  {
                    required: true,
                    message: "module is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select size="large" options={moduleData} placeholder="Select module" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Content"
                name="content"
                rules={[
                  {
                    required: true,
                    message: "content is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter the content" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Media Id"
                name="media_id"
                rules={[
                  {
                    required: true,
                    message: "Media Id is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select size="large" options={iconList} placeholder="Select media id" />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value=" SAVE"
                className="poppins_medium umoney_modal_filled_btn"
                block
                loading={saveBtnLoading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default InformationToUserModal;
