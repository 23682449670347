import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import { useSelector } from "react-redux";
import Dashboard from "./pages/Dashboard";
import NotFound404 from "./pages/NotFound404";
import UserManagement from "./pages/SuperAdminDashboard/UserManagement";
import RoleManagement from "./pages/SuperAdminDashboard/RoleManagement";
import EmailTemplate from "./pages/Template/EmailTemplate";
import EmailBanner from "./pages/Template/EmailBanner";
import NotificationIcon from "./pages/Template/NotificationIcon";
import NotificationTemplate from "./pages/Template/NotificationTemplate";
import GeneralManagement from "./pages/SuperAdminDashboard/Configuration/GeneralManagement";
import LoanDisbursal from "./pages/SuperAdminDashboard/Configuration/LoanDisbursal";
import TermsAndCondition from "./pages/SuperAdminDashboard/Configuration/TermsAndCondition";
import DeviceChangeFilter from "./pages/SuperAdminDashboard/Configuration/DeviceChangeFilter";
import ReferAndEarnAndCoins from "./pages/SuperAdminDashboard/Configuration/ReferAndEarnAndCoins";
import LoanRepaymentByUser from "./pages/SuperAdminDashboard/Configuration/LoanRepaymentByUser";
import CreditCheckSubmission from "./pages/SuperAdminDashboard/Configuration/CreditCheckSubmission";
import SalaryCreditCheckForm from "./pages/SuperAdminDashboard/Configuration/SalaryCreditCheckForm";
import CurrentlyWorking from "./pages/SuperAdminDashboard/Configuration/CurrentlyWorking";
import DialerConfig from "./pages/SuperAdminDashboard/Configuration/DialerConfig";
import CollectionOrRepayment from "./pages/SuperAdminDashboard/Configuration/CollectionOrRepayment";
import AddBankAccount from "./pages/SuperAdminDashboard/Configuration/AddBankAccount";
import AddUPI from "./pages/SuperAdminDashboard/Configuration/AddUPI";
import ReceivedJobOffer from "./pages/SuperAdminDashboard/Configuration/ReceivedJobOffer";
import LoanExtension from "./pages/SuperAdminDashboard/Configuration/LoanExtension";
import NewRegSalaried from "./pages/SuperAdminDashboard/Configuration/NewRegSalaried";
import BankAccVerification from "./pages/SuperAdminDashboard/Configuration/BankAccVerification";
import CollectionDaysLogic from "./pages/SuperAdminDashboard/Configuration/CollectionDaysLogic";
import College from "./pages/CollegeAndCompany/College";
import Specialization from "./pages/CollegeAndCompany/Specialization";
import Department from "./pages/CollegeAndCompany/Department";
import Designation from "./pages/CollegeAndCompany/Designation";
import Course from "./pages/CollegeAndCompany/Course";
import Company from "./pages/CollegeAndCompany/Company";
import BranchCompany from "./pages/CollegeAndCompany/BranchCompany";
import CollegeWiseLimit from "./pages/SuperAdminDashboard/Admin/CollegeWiseLimit";
import RejectedReasons from "./pages/SuperAdminDashboard/Admin/RejectedReasons";
import DocumentType from "./pages/SuperAdminDashboard/Admin/DocumentType";
import LoanFilterLogic from "./pages/SuperAdminDashboard/Configuration/LoanFilterLogic";
import DeviceFilterLogicAlgo1 from "./pages/SuperAdminDashboard/Configuration/DeviceFilterLogicAlgo1";
import DeviceFilterLogicAlgo2 from "./pages/SuperAdminDashboard/Configuration/DeviceFilterLogicAlgo2";
import DeviceFilterLogicAlgo3 from "./pages/SuperAdminDashboard/Configuration/DeviceFilterLogicAlgo3";
import DialerLogicConfiguration from "./pages/SuperAdminDashboard/Configuration/DialerLogicConfiguration";
import LoanManagementSystem from "./pages/SuperAdminDashboard/Configuration/LoanManagementSystem";
import LimitDeterminationEngine from "./pages/SuperAdminDashboard/Configuration/LimitDeterminationEngine";
import AcquisitonForStudent from "./pages/SuperAdminDashboard/Configuration/AcquisitonForStudent";
import AcquisitonForSalaried from "./pages/SuperAdminDashboard/Configuration/AcquisitonForSalaried";
import CreditCheck from "./pages/SuperAdminDashboard/Configuration/CreditCheck";
import SalaryCreditCheck from "./pages/SuperAdminDashboard/Configuration/SalaryCreditCheck";
import DeviceChange from "./pages/SuperAdminDashboard/Configuration/DeviceChange";
import LoanFilter from "./pages/SuperAdminDashboard/Configuration/LoanFilter";
import Defect from "./pages/SuperAdminDashboard/Configuration/Defect";
import EarnRewardsCoins from "./pages/SuperAdminDashboard/Configuration/EarnRewardsCoins";
import FAQ from "./pages/FaqManagement/Faq";
import FaqCategory from "./pages/FaqManagement/FaqCategory";
import FaqSubCategory from "./pages/FaqManagement/FaqSubCategory";
import Collection from "./pages/SuperAdminDashboard/Configuration/Collection";
import Emailus from "./pages/SuperAdminDashboard/Configuration/Emailus";
import CMS from "./pages/CollegeAndCompany/CMS";
import History from "./pages/StudentOnBoarding//History";
import List from "./pages/StudentOnBoarding/List";
import DetailView from "./pages/StudentOnBoarding/DetailView";
import FaqDashboard from "./pages/FaqManagement/FaqDashboard";
import CoinManagement from "./pages/SuperAdminDashboard/Configuration/CoinManagement";
import BankAccountApproval from "./pages/BankAccount/BankAccountApproval";
import DeviceChangePanel from "./pages/DeviceChange/DeviceChangePanel";
import LoanFilterAdvisor from "./pages/LoanFilter/LoanFilterAdvisor";
import InformationToUser from "./pages/InformationToUser";
import InternalDecision from "./pages/InternalDecision";
import OnBoarding from "./pages/SuperAdminDashboard/Configuration/OnBoarding";
import SmsTemplate from "./pages/Template/SmsTemplate";
import ProofTypeList from "./pages/ProofTypeList";
import Tenure from "./pages/Tenure";
import FeeDisbursal from "./pages/FeeDisbursal";
import SalariedHistory from "./pages/SalariedOnBoarding/SalariedHistory";
import SalariedList from "./pages/SalariedOnBoarding/SalariedList";
import SalariedDetailView from "./pages/SalariedOnBoarding/SalariedDetailView";
import ContactStatus from "./pages/ContactStatus";
import HistoryView from "./pages/StudentToSalaried/HistoryView";
import ListView from "./pages/StudentToSalaried/ListView";
import LoanApplication from "./pages/SuperAdminDashboard/Configuration/LoanApplication";
import DetailviewCreditCheck from "./pages/CreditCheckAdminPanel/DetailviewCreditCheck";
import HistoryCreditCheck from "./pages/CreditCheckAdminPanel/HistoryCreditCheck";
import ListCreditCheck from "./pages/CreditCheckAdminPanel/ListCreditCheck";
import AdvanceCreditCheckDetailView from "./pages/AdvanceCreditCheck/AdvanceCreditCheckDetailView";
import AdvanceCreditCheckList from "./pages/AdvanceCreditCheck/AdvanceCreditCheckList";
import AdvanceCreditCheckHistory from "./pages/AdvanceCreditCheck/AdvanceCreditCheckHistory";
import StudentToSalariedDetailView from "./pages/StudentToSalaried/StudentToSalariedDetailView";
import ResubmittedLogs from "./pages/StudentToSalaried/ResubmittedLogs";
import jwt_decode from "jwt-decode";
import { message } from "antd";
import ThirdPartyApis from "./pages/SuperAdminDashboard/Configuration/ThirdPartyApis";

const App = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const departmentId = useSelector((state) => state.userData.accessDepartment);
  const role_code = useSelector((state) => state.userData.accessRoleCode);
  const login_type = useSelector((state) => state.userData.accessLoginType);

  useEffect(() => {
    if (auth.accessToken === "" && login_type === "") {
      navigate("/login");
    } else if (auth.accessToken === "" && login_type === "dialer-student") {
      navigate("/dialer-detail-view");
    } else if (auth.accessToken === "" && login_type === "dialer-salaried") {
      navigate("/dialer-salaried-detail-view");
    } else if (auth.accessToken === "" && login_type === "dialer-cc") {
      navigate("/dialer-credit-check-detail-view");
    }

    if (auth?.accessToken) {
      const decodedToken = jwt_decode(auth?.accessToken);
      const tokenExpirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
      const currentTime = Date.now();
      const timeUntilExpiration = tokenExpirationTime - currentTime;

      setTimeout(() => {
        localStorage.clear();
        navigate("/login"); // redirect to login page
        message.warning("Session timeout.");
      }, timeUntilExpiration);
    }
  }, [auth.accessToken, navigate, login_type]);

  // const location = useLocation();
  // const pathName = location?.pathname;

  // if (pathName == "/login") {
  //   console.log("");
  // } else {
  //   localStorage.setItem("path", pathName);
  // }

  return (
    <div className="app">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/dialer-detail-view" element={<DetailView />} />
        <Route
          path="/dialer-salaried-detail-view"
          element={<SalariedDetailView />}
        ></Route>
        <Route
          path="/dialer-credit-check-detail-view"
          element={<DetailviewCreditCheck />}
        ></Route>
        {role_code === "superadmin" ? (
          <>
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/role-management" element={<RoleManagement />} />
            <Route path="/reject-reason" element={<RoleManagement />} />
            <Route path="/email-banner" element={<EmailBanner />} />
            <Route path="/email-template" element={<EmailTemplate />} />
            <Route path="/notification-icon" element={<NotificationIcon />} />
            <Route path="/sms-template" element={<SmsTemplate />} />
            <Route
              path="/notification-template"
              element={<NotificationTemplate />}
            />
            <Route path="/general-management" element={<GeneralManagement />} />
            <Route path="/loan-disbursal" element={<LoanDisbursal />} />
            <Route
              path="/terms-and-condition"
              element={<TermsAndCondition />}
            />
            <Route
              path="/device-change-filter"
              element={<DeviceChangeFilter />}
            />
            <Route path="/add-bank-account" element={<AddBankAccount />} />
            <Route path="/add-upi-id" element={<AddUPI />} />
            <Route
              path="/refer-earn-coins"
              element={<ReferAndEarnAndCoins />}
            />
            <Route
              path="/loan-repayment-by-user"
              element={<LoanRepaymentByUser />}
            />
            <Route
              path="/credit-check-submission"
              element={<CreditCheckSubmission />}
            />
            <Route
              path="/salary-credit-check-form"
              element={<SalaryCreditCheckForm />}
            />
            <Route path="/currently-working" element={<CurrentlyWorking />} />
            <Route path="/dialer-config" element={<DialerConfig />} />
            <Route path="/received-job-offer" element={<ReceivedJobOffer />} />
            <Route path="/loan-extension" element={<LoanExtension />} />
            <Route path="/new-reg-salaried" element={<NewRegSalaried />} />
            <Route
              path="/collection-or-repayment"
              element={<CollectionOrRepayment />}
            />
            <Route
              path="/collection-days-logic"
              element={<CollectionDaysLogic />}
            />
            <Route path="/loan-filter-logic" element={<LoanFilterLogic />} />
            <Route
              path="/device-filter-algo-1"
              element={<DeviceFilterLogicAlgo1 />}
            />
            <Route
              path="/device-filter-algo-2"
              element={<DeviceFilterLogicAlgo2 />}
            />
            <Route
              path="/device-filter-algo-3"
              element={<DeviceFilterLogicAlgo3 />}
            />
            <Route
              path="/dialer-logic-config"
              element={<DialerLogicConfiguration />}
            />
            {/* Approver bucket time */}
            <Route
              path="/acquisition-for-student"
              element={<AcquisitonForStudent />}
            />
            <Route
              path="/acquisition-for-salaried"
              element={<AcquisitonForSalaried />}
            />
            <Route path="/credit-check" element={<CreditCheck />} />
            <Route
              path="/salary-credit-check"
              element={<SalaryCreditCheck />}
            />
            <Route path="/device-change" element={<DeviceChange />} />
            <Route
              path="/bank-ac-verification"
              element={<BankAccVerification />}
            />
            <Route path="/loan-filter" element={<LoanFilter />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/user-defect-identification" element={<Defect />} />

            <Route
              path="/loan-management-sys"
              element={<LoanManagementSystem />}
            />
            <Route
              path="/limit-determination-engine"
              element={<LimitDeterminationEngine />}
            />
            <Route path="/coin-management" element={<CoinManagement />} />
            <Route path="/on-boarding" element={<OnBoarding />} />
            <Route path="/third-party-apis" element={<ThirdPartyApis />} />

            {/* S27 - College and Research Team Panel */}
            <Route path="/college-list" element={<College />} />
            <Route path="/course" element={<Course />} />
            <Route path="/company" element={<Company />} />
            <Route path="/branch-company" element={<BranchCompany />} />
            <Route path="/specialization" element={<Specialization />} />
            <Route path="/department" element={<Department />} />
            <Route path="/designation" element={<Designation />} />

            <Route path="/college-wise-limit" element={<CollegeWiseLimit />} />
            <Route path="/rejected-reasons" element={<RejectedReasons />} />
            <Route path="/document-type" element={<DocumentType />} />
            <Route path="/earn-rewards-coins" element={<EarnRewardsCoins />} />
            <Route path="/CMS" element={<CMS />} />
            <Route path="/dashboard" element={<FaqDashboard />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/faq-category" element={<FaqCategory />} />
            <Route path="/faq-sub-category" element={<FaqSubCategory />} />
            <Route path="/email-us" element={<Emailus />} />
            <Route
              path="/information-to-user"
              element={<InformationToUser />}
            />
            <Route path="/internal-decision" element={<InternalDecision />} />
            <Route path="/proof-type-list" element={<ProofTypeList />} />
            <Route path="/tenure-list" element={<Tenure />} />
            <Route path="/fee-disbursal" element={<FeeDisbursal />} />
            <Route path="/contact-status" element={<ContactStatus />} />
            <Route path="/loan-application" element={<LoanApplication />} />
          </>
        ) : (
          <Route path="*" element={<NotFound404 />} />
        )}

        {departmentId === 1 || role_code === "superadmin" ? (
          <>
            <Route path="/history" element={<History />} />
            <Route path="/list" element={<List />} />
            <Route path="/detail-view" element={<DetailView />} />
          </>
        ) : (
          <Route path="*" element={<NotFound404 />} />
        )}
        {departmentId === 5 || role_code === "superadmin" ? (
          <Route path="/device-change-panel" element={<DeviceChangePanel />} />
        ) : (
          <Route path="*" element={<NotFound404 />} />
        )}

        {departmentId === 6 || role_code === "superadmin" ? (
          <Route
            path="/bank-account-approval"
            element={<BankAccountApproval />}
          />
        ) : (
          <Route path="*" element={<NotFound404 />} />
        )}

        {departmentId === 7 || role_code === "superadmin" ? (
          <Route path="/loan-filter-advisor" element={<LoanFilterAdvisor />} />
        ) : (
          <Route path="*" element={<NotFound404 />} />
        )}

        {/* <Route path="/loan-filter-advisor" element={<LoanFilterAdvisor />} /> */}

        {departmentId === 2 || role_code === "superadmin" ? (
          <>
            <Route path="/salaried-history" element={<SalariedHistory />} />
            <Route path="/salaried-list" element={<SalariedList />} />
            <Route
              path="/salaried-detail-view"
              element={<SalariedDetailView />}
            />
          </>
        ) : (
          <Route path="*" element={<NotFound404 />} />
        )}
        {departmentId === 4 || role_code === "superadmin" ? (
          <>
            <Route
              path="/student-to-salaried/history"
              element={<HistoryView />}
            />
            <Route path="/student-to-salaried/list" element={<ListView />} />
            <Route
              path="/student-to-salaried-detailview"
              element={<StudentToSalariedDetailView />}
            />
            <Route
              path="/student-to-salaried-resubmitted-logs"
              element={<ResubmittedLogs />}
            />
          </>
        ) : (
          <Route path="*" element={<NotFound404 />}></Route>
        )}

        {departmentId === 3 ||
        departmentId === 9 ||
        role_code === "superadmin" ? (
          <>
            <Route path="/credit-check/list" element={<ListCreditCheck />} />
            <Route
              path="/credit-check/history"
              element={<HistoryCreditCheck />}
            />
            <Route
              path="/credit-check-detailview"
              element={<DetailviewCreditCheck />}
            />
          </>
        ) : (
          <Route path="*" element={<NotFound404 />}></Route>
        )}

        {departmentId === 11 ||
        departmentId === 10 ||
        role_code === "superadmin" ? (
          <>
            <Route
              path="/advance-credit-check/list"
              element={<AdvanceCreditCheckList />}
            />
            <Route
              path="/advance-credit-check/history"
              element={<AdvanceCreditCheckHistory />}
            />
            <Route
              path="/advance-credit-check-detailview"
              element={<AdvanceCreditCheckDetailView />}
            />
          </>
        ) : (
          <Route path="*" element={<NotFound404 />}></Route>
        )}

        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </div>
  );
};

export default App;
