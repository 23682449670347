import { Col, Modal, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

const AddEmailTemplate = (props) => {
  const {
    onSave = () => {},
    modalType,
    modalTitle,
    setModalType,
    editId,
  } = props;

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [bannerList, setBannerList] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [emailData, setEmailData] = useState([]);

  const [html,setHtml]=useState();

  const onSubmitData = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "add-email-template"
        ? "template/create-email"
        : "template/update-email/" + emailData?.id;

    data.dynamic_data_param = "1";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const is_html = [
    {
      value: "1",
      label: "Yes",
    },
    {
      value: "0",
      label: "No",
    },
  ];

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setModalType(false);
  };

  if (editId && _.isEmpty(emailData)) {
    const endPoint = "template/get-email/" + editId;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setEmailData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  /*
  const convertToSlug = (e) => {
    var text = e.target.value;
    var slug = text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    console.log("slug", slug);
    form.setFieldValue("slug", slug);
  };
  */

  useEffect(() => {
    const loadEmailBanners = () => {
      const endPoint = "template/banner-active-list";
      console.log("endPoint", endPoint);

      getAPIData(endPoint, "get", {}, false)
        .then((result) => {
          console.log("result", result);
          if (result?.status === true) {
            var banner_list = [];
            result?.data.map((banner) =>
              banner_list.push({
                value: banner.id,
                label: banner.url,
              })
            );
            setBannerList(banner_list);
            form.resetFields();
          } else {
            message.warning(result?.message);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    };

    loadEmailBanners();
    if (modalType === "add-email-template") {
      form.resetFields();
    } else {
      form.setFieldsValue(emailData);
    }
  }, [form, emailData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={500}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="poppins_medium" style={{ marginTop: "10px" }}>
        <Form
          name="basic"
          //onFinish={onFinish}
          onFinish={(data) => {
            onSubmitData(data);
          }}
          onFinishFailed={onFinishFailed}
          initialValues={modalType === "edit-email-template" ? emailData : ""}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Message Body"
                name="message_body"
                rules={[
                  { required: true, message: "Message Body is required!" },
                ]}
                className="poppins_medium mb-35"
              >
                <TextArea
                  rows={4}
                  size="large"
                  // onChange={convertToSlug}
                  placeholder="Message body will be here."
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Template Header (Slug)"
                name="slug"
                rules={[{ required: true, message: "Template header is required!" }]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter template Header"
                  disabled={modalType === "edit-email-template" ? true : false}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Is HTML"
                name="is_html"
                rules={[
                  { required: true, message: "Is HTML type is required!" },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  className="br"
                  size="large"
                  options={is_html}
                  style={{ width: "100%" }}
                  onChange={(e)=>setHtml(e)}
                  placeholder="Select is HTML"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
            {
                html==="1" ?
              <Form.Item
                label="Banner"
                name="banner_id"
                className="poppins_medium mb-35"
              >
                <Select
                  className="br"
                  size="large"
                  options={bannerList}
                  style={{ width: "100%" }}
                  placeholder="Select banner"
                />
              </Form.Item> : ""
              }
            </Col>

            <Col className="gutter-row" span={24}>
              <Form.Item>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="Save"
                  className="poppins_medium umoney_btn"
                  loading={saveBtnLoading}
                  block
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEmailTemplate;
