import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import { CloseCircleOutlined } from "@ant-design/icons";
import CLoadingButton from "../InputFields/CLoadingButton";

const DepartMentModals = ({
  modalType,
  modalTitle,
  setModalType,
  isEdit,
  editId,
  setEditCancleIcon,
  setIsEdit,
  setEditId,
  editCancleIcon,
  loadData,
  editData,
}) => {
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [form] = Form.useForm();

  const handleClose = () => {
    setModalType(false);
  };

  const closeEdit = () => {
    form.resetFields();
    setEditCancleIcon(false);
    setIsEdit(false);
    setEditId(Number);
  };

  const onFinish = (data) => {
    setSaveBtnLoading(true);

    const endPoint =
      modalType === "add-department"
        ? "master/department-create"
        : "master/department-update/" + editData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          setSaveBtnLoading(false);
          message.success(result?.message);
          form.resetFields();
          handleClose();
          loadData();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const onFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (modalType === "add-department") {
      form.resetFields();
    } else {
      form.setFieldsValue(editData);
    }
  }, [form, editData, modalType]);
  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label="Enter Department"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Department is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter department name"
                  suffix={
                    editCancleIcon ? (
                      <CloseCircleOutlined onClick={closeEdit} />
                    ) : (
                      ""
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value="SAVE"
                className="poppins_medium umoney_btn"
                loading={saveBtnLoading}
                block
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default DepartMentModals;
