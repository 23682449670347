import types from "./action";

const initialState = {
  accessRole: "",
  accessDepartment: "",
  accessRoleCode: "",
  accessLoginType:"",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_ROLE:
      return {
        ...state,
        accessRole: actions.accessRole,
      };

    case types.SET_ROLE_CODE:
      return {
        ...state,
        accessRoleCode: actions.accessRoleCode,
      };

    case types.SET_ROLE_NAME:
      return {
        ...state,
        accessRoleName: actions.accessRoleName,
      };

    case types.SET_USER_DATA:
      return {
        ...state,
        accessUserData: actions.accessUserData,
      };

    case types.SET_DEPARTMENT:
      return {
        ...state,
        accessDepartment: actions.accessDepartment,
      };

    case types.SET_LOGIN_TYPE:
      return {
        ...state,
        accessLoginType: actions.accessLoginType,
      };
    default:
      return state;
  }
}
