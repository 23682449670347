import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Layout,
  Table,
  Tag,
  message,
  Input,
  Space,
  Button,
} from "antd";
import Sidebar from "../../components/Sidebar";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import CClickLoading from "../../components/InputFields/CClickLoading";
import CSelectBtn from "../../components/InputFields/CSelectBtn";
import { useLocation } from "react-router-dom";
import { getAPIData } from "../../apiHelper";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import CTableSearch from "../../components/InputFields/CTableSearch";
import moment from "moment";
import { credit_check_url, setHeader } from "../../api";
import axios from "axios";
import _ from "lodash";

const { Title } = Typography;
const { Header, Content } = Layout;

const AdvanceCreditCheckList = () => {
  var verifiedApproved = "#3EA467";
  var Pending = "#DE1717";
  var completed = "#ffbf00";
  var checked = "#0000FF";

  const role = useSelector((state) => state.userData.accessRoleCode);

  const navigate = useNavigate();

  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const pathType = pathName[2];

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [top, setTop] = useState("none");
  const [bottom, setBottom] = useState("bottomLeft");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const role = "superadmin"; //"caller","Approver",Supervisor

  const [listData, setListData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  //sort btns
  const [total, setTotal] = useState("");
  const [approved, setApproved] = useState("");
  const [rejected, setRejected] = useState("");
  const [pending, setPending] = useState("");

  //Search functionality
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  //Agent List
  const [agentList, setAgentList] = useState();
  const [agentListData, setAgentListData] = useState();

  //Assign Profile
  const [assignLoading, setAssignLoading] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [selectedJunior, setSelectedJunior] = useState("");

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const loadData = () => {
    setLoading(true);
    setTableLoading(true);
    const endpoint = "cc-advance/list";
    const data = { type: pathType };
    getAPIData(endpoint, "get", data, false, "credit_check")
      .then((result) => {
        console.log("fetch");
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setTableLoading(false);
            setListData(response);

            let arr = [];
            response.map((data) => {
              arr.push({
                total: data.status,
              });
            });

            const totalCount = arr?.length;
            setTotal(totalCount);

            const rejCount = response.filter((data) => data.status === "-1");
            const rejectedCount = rejCount.length;
            setRejected(rejectedCount);

            const penCount = response.filter((data) => data.status === "0");
            const pendedCount = penCount.length;
            setPending(pendedCount);

            const appCount = response.filter((data) => data.status === "2");
            const approvedCount = appCount.length;
            setApproved(approvedCount);
            setLoading(false);
          }
        } else {
          message.warning(result?.message);
          setLoading(false);
          setTableLoading(false);
        }
        setTableLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
        setLoading(false);
        message.error(err);
      });
  };

  const loadAgentList = () => {
    setLoading(true);
    const endPoint = "cc-student/cc-agents-list";
    let array = [];
    getAPIData(endPoint, "get", {}, false, "credit_check")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setAgentList(response);
          response.map((data) =>
            array.push({
              label:
                data?.first_name +
                " " +
                data?.middle_name +
                " " +
                data?.last_name,
              value: data?.user_id,
            })
          );
          setAgentListData(array);
        } else {
          message?.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };

  //Assign Profile
  const handleAssignClick = async () => {
    setAssignLoading(true);
    const endPoint = credit_check_url + "cc-student/assign-cc-profile";
    const header = setHeader();
    const form = new FormData();
    form.append("user_ids", userIds);
    form.append("junior_id", selectedJunior);
    const assign = await axios.post(endPoint, form, header);
    if (assign?.data?.status) {
      message.success(assign?.data?.message);
      setSelectedJunior("");
      setUserIds("");
      setAssignLoading(false);
      loadData();
      setSelectedRowKeys("");
    } else {
      message.error(assign?.data?.message);
    }
    console.log("assign =====>>>>> ", assign);
  };

  useEffect(() => {
    loadData();
    loadAgentList();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys, confirm, dataIndex);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilter: (value, record) =>
      moment(record[dataIndex]).format("DD-MM-YYYY") ===
      value.format("DD-MM-YYYY"),

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CTableSearch
        datePicker
        onDateChange={(e) => {
          setSelectedKeys([e]);
        }}
        onSearch={() => {
          handleSearch(selectedKeys, confirm, dataIndex);
        }}
        onReset={() => clearFilters && handleReset(clearFilters, confirm)}
      />
    ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape={false}
          textToHighlight={text ? text : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchTextProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "USER DETAILS",
      dataIndex: "user_details",
      key: "user_details",
      width: "12%",
      filteredValue: filteredInfo.user_details || null,
      sorter: (a, b) => a?.user_id - b?.user_id,
      ...getColumnSearchTextProps("user_details"),
      render: (_noUse, userDetails) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: userDetails?.user_details }}
            style={{ cursor: "pointer" }}
          />
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/advance-credit-check-detailview?user_id=${record.user_id}`);
          },
        };
      },
    },
    {
      title: "MODULE STATUS",
      dataIndex: "module",
      key: "id",
      width: "20%",
      filteredValue: filteredInfo.module || null,
      render: (text, record) => (
        <>
          {record?.role === "college-student" ? (
            <Tag
              className="module_status"
              color={
                record?.income === "2"
                  ? completed
                  : record?.income === "3"
                  ? checked
                  : record?.income === "4"
                  ? verifiedApproved
                  : Pending
              }
            >
              Income check
            </Tag>
          ) : (
            ""
          )}
          {record?.role === "college-student" ? (
            <Tag
              className="module_status"
              color={
                record?.spending === "2"
                  ? completed
                  : record?.spending === "3"
                  ? checked
                  : record?.spending === "4"
                  ? verifiedApproved
                  : Pending
              }
            >
              Spending check
            </Tag>
          ) : (
            ""
          )}

          <Tag
            className="module_status"
            color={
              record?.assets === "2"
                ? completed
                : record?.assets === "3"
                ? checked
                : record?.assets === "4"
                ? verifiedApproved
                : Pending
            }
          >
            Asset check
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.parent_contact === "2"
                ? completed
                : record?.parent_contact === "3"
                ? checked
                : record?.parent_contact === "4"
                ? verifiedApproved
                : Pending
            }
          >
            Parent contact
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.social_media === "2"
                ? completed
                : record?.social_media === "3"
                ? checked
                : record?.social_media === "4"
                ? verifiedApproved
                : Pending
            }
          >
            Social media
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.cibil === "2"
                ? completed
                : record?.cibil === "3"
                ? checked
                : record?.cibil === "4"
                ? verifiedApproved
                : Pending
            }
          >
            Cibil
          </Tag>
        </>
      ),
    },
    {
      title: "USER ACCESS TIMESTAMP",
      dataIndex: "users_createdAt",
      width: "13%",
      filteredValue: filteredInfo.users_createdAt || null,
      ...getColumnSearchProps("users_createdAt"),
    },
    {
      title: "USER LAST ACTIONED AT",
      dataIndex: "percentage_updatedAt",
      width: "14%",
      filteredValue: filteredInfo.percentage_updatedAt || null,
      ...getColumnSearchTextProps("percentage_updatedAt"),
      render: (text, record) => (
        <span>
          {record?.agentName} <br /> Timestamp - {record?.percentage_updatedAt}
        </span>
      ),
    },
    {
      title: "AGENT LAST ACTIONED",
      dataIndex: "agent_last_action_at",
      width: "10%",
      filteredValue: filteredInfo.agent_last_action_at || null,
      ...getColumnSearchProps("agent_last_action_at"),
    },
    {
      title: "LAST CALL STATUS",
      dataIndex: "CreditCheckDisp",
      width: "13%",
      filteredValue: filteredInfo.CreditCheckDisp || null,
      ...getColumnSearchTextProps("CreditCheckDisp"),
      render: (_noUse, CreditCheckDisp) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: CreditCheckDisp?.CreditCheckDisp
                ? CreditCheckDisp?.CreditCheckDisp
                : "-",
            }}
          />
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => (
        <span>
          {record?.status === "0"
            ? "Pending"
            : record?.status === "1"
            ? "Checked"
            : record?.status === "-1"
            ? "Reject"
            : record?.status === "2"
            ? "Approve"
            : ""}
        </span>
      ),
      filters: [
        { value: "0", label: "Pending", text: "Pending" },
        { value: "-1", label: "Reject", text: "Reject" },
        { value: "2", label: "Approve", text: "Approve" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "CURRENTLY WITH",
      dataIndex: "agentName",
      width: "13%",
      hidden: true,
      filteredValue: filteredInfo.agentName || null,
      ...getColumnSearchTextProps("agentName"),
    },
  ];

  const newColumns = columns.filter((item) => !item.hidden);

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setUserIds(newSelectedRowKeys);
    console.log(newSelectedRowKeys);
  };

  const agentSelectChange = (juniorId) => {
    setSelectedJunior(juniorId);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <div className={`${loading ? "page-loading app" : "app"}`}>
        {loading === true ? <CClickLoading /> : ""}
        <Layout
          style={{
            height: "100%",
            padding: "20px 0 0 20px",
            backgroundColor: "#DFFEFC",
          }}
        >
          <Sidebar open={open} setOpen={setOpen} />
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                backgroundColor: "#009D93",
                borderRadius: "15px 0 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
              <Title level={4} className="poppins_regular umoney_page_title ">
                {" "}
                {role}'s List View{" "}
              </Title>
            </Header>
            <Content
              className="site-layout-background"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="white_div">
                <Row gutter={[16, 16]}>
                  {role === "supervisor_caller" ||
                  role === "supervisor_approver" ||
                  role === "superadmin" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CSelectBtn
                          options={agentListData}
                          size="large"
                          classes="poppins_regular filter_select_border"
                          placeholder="Select agents"
                          btnvalue="ASSIGN TO"
                          onClick={handleAssignClick}
                          // btnLoading={assignLoading}
                          onChange={agentSelectChange}
                        />
                      </Col>
                      <Col span={16}></Col>
                    </>
                  ) : (
                    <>
                      <Col className="gutter-row" span={16}>
                        <Title
                          level={5}
                          className="poppins_medium umoney_title"
                        >
                          Allocated to me:{" "}
                        </Title>
                      </Col>
                      <Col className="gutter-row" span={8}></Col>
                    </>
                  )}
                  {role === "caller" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={total}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pending}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejected}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Forwarded to CC Approver:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                    </>
                  ) : role === "approver" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={total}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Sent to CC Caller:"
                          className="poppins_medium umoney_outlined_btn"
                          countval="xx"
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pending}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={approved}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejected}
                          block
                        />
                      </Col>
                    </>
                  ) : role === "supervisor_caller" ||
                    role === "supervisor_approver" ||
                    role === "superadmin" ? (
                    <>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Total:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={total}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Approved:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={approved}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Rejected:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={rejected}
                          block
                        />
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <CLoadingButton
                          size="large"
                          value="Pending:"
                          className="poppins_medium umoney_outlined_btn"
                          countval={pending}
                          block
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="CLEAR FILTERS"
                      className="poppins_medium umoney_btn"
                      onClick={() => clearFilters()}
                      block
                    />
                  </Col>
                </Row>

                {/* } */}
                <div className="mt-20">
                  <div className="umoney_table">
                    <Table
                      columns={
                        role === "superadmin" ||
                        role === "supervisor_caller" ||
                        role === "supervisor_approver"
                          ? columns
                          : newColumns
                      }
                      dataSource={listData}
                      scroll={{
                        x: 1300,
                      }}
                      pagination={{
                        position: [top, bottom],
                        showSizeChanger: true,
                        locale: { items_per_page: "" },
                      }}
                      rowSelection={
                        role === "superadmin" ||
                        role === "supervisor_caller" ||
                        role === "supervisor_approver"
                          ? rowSelection
                          : ""
                      }
                      loading={tableLoading}
                      rowKey={(record) => record.user_id}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {role === "caller" ? (
                  <Row gutter={[16, 16]} className="mt-20">
                    <Col className="gutter-row" span={12}>
                      <Title level={5} className="poppins_medium umoney_title ">
                        Forwarded to Approver:{" "}
                      </Title>
                    </Col>
                    <Col className="gutter-row" span={12}></Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Total:"
                        className="poppins_medium umoney_outlined_btn"
                        block
                        countval={total}
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Back to CC Caller:"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="With CC Approver:"
                        className="poppins_medium umoney_outlined_btn"
                        countval="xx"
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Approved:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={approved}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Rejected:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={rejected}
                        block
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <CLoadingButton
                        size="large"
                        value="Pending:"
                        className="poppins_medium umoney_outlined_btn"
                        countval={pending}
                        block
                      />
                    </Col>
                    <Col  span={12}></Col>
                    <Col span={6}>
                    <CLoadingButton
                      size="large"
                      value="CLEAR FILTERS"
                      className="poppins_medium umoney_btn"
                      onClick={() => clearFilters()}
                      block
                    />
                  </Col>
                    <Col span={24}>
                      <div className="umoney_table" style={{ marginTop: 20 }}>
                        <Table
                          columns={
                            role === "superadmin" ||
                            role === "supervisor_caller" ||
                            role === "supervisor_approver"
                              ? columns
                              : newColumns
                          }
                          dataSource={listData}
                          scroll={{
                            x: 1300,
                          }}
                          pagination={{
                            position: [top, bottom],
                            showSizeChanger: true,
                            locale: { items_per_page: "" },
                          }}
                          loading={tableLoading}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default AdvanceCreditCheckList;
