import React, { useState, useEffect } from "react";
import { Typography, Table, Col, Row, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import CollectionDaysModals from "../../../components/Modals/CollectionDaysModals";
import { getAPIData } from "../../../apiHelper";
import CommonModals from "../../../components/Modals/CommonModals";

const CollectionDaysLogic = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { Title } = Typography;
  const [callTableData, setCallTableData] = useState([]);
  const [smsTableData, setSmsTableData] = useState([]);
  const [emailTableData, setEmailTableData] = useState([]);
  const [notiTableData, setNotiTableData] = useState([]);

  const [deleteId, setDeleteId] = useState("");
  const [action, setAction] = useState("");
  const [singleData, setSingleData] = useState("");

  //open Modal
  const [modalType, setModalType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const openModal = (type, action, id) => {
    setAction(action);
    setModalType(type);
    setModalTitle(type + " Reminder");
    setModalOpen(true);
    if (type === "Add") {
      setModalTitle(type + " Reminder");
    } else if (type === "Edit") {
      setModalTitle(type + " " + action + " Reminder");
      loadCollectionData(id);
    } else if (type === "delete") {
      setDeleteId(id);
      setModalType(type);
      setModalTitle("Delete Confirmation");
    }
  };

  const loadCollectionData = (id) => {
    setLoading(true);
    const endPoint = "system-config/collection-days/get/" + id;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setSingleData(response);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const endPoint = "system-config/collection-days/list";

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCallTableData(response?.CallList);
          setSmsTableData(response?.SmsList);
          setEmailTableData(response?.EmailList);
          setNotiTableData(response?.NotiList);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  //CALLS
  const callsColumns = [
    {
      title: "REMINDER CALLS",
      dataIndex: "reminder",
      key: "reminder",
    },
    {
      title: "CALL BEFORE DUE DAYS",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("Edit", "call", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", "call", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  //SMS
  const smsColumns = [
    {
      title: "REMINDER SMS",
      dataIndex: "reminder",
      key: "reminder",
    },
    {
      title: "CALL BEFORE DUE DAYS",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("Edit", "Sms", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", "sms", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  //EMAIL
  const emailColumns = [
    {
      title: "REMINDER EMAIL",
      dataIndex: "reminder",
      key: "reminder",
    },
    {
      title: "CALL BEFORE DUE DAYS",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("Edit", "Email", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", "email", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  //NOTIFICATION
  const notiColumns = [
    {
      title: "REMINDER NOTIFICATION",
      dataIndex: "reminder",
      key: "reminder",
    },
    {
      title: "CALL BEFORE DUE DAYS",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <span>
            <EditOutlined
              style={{
                color: "#009D93",
                height: "20px",
                width: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("Edit", "Notification", record?.id)}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{
                color: "red",
                marginLeft: "20px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => openModal("delete", "notification", record?.id)}
            />
          </span>
        </div>
      ),
    },
  ];

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={10}>
                <Title level={5} className="poppins_medium umoney_title">
                  COLLECTION DAYS LOGIC
                </Title>
              </Col>
              <Col span={2}>
                <CLoadingButton
                  size="large"
                  value="ADD"
                  className="poppins_medium umoney_btn"
                  onClick={() => openModal("Add", "call")}
                  block
                />
              </Col>
              <Col span={12}></Col>
            </Row>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={callsColumns}
                dataSource={callTableData}
              />
            </div>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={smsColumns}
                dataSource={smsTableData}
              />
            </div>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={emailColumns}
                dataSource={emailTableData}
              />
            </div>
            <div className="umoney_table mt-20" style={{ width: "50%" }}>
              <Table
                pagination={{
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                columns={notiColumns}
                dataSource={notiTableData}
              />
            </div>
          </div>
        </Content>
        {modalType === "delete" ? (
          <CommonModals
            open={modalOpen}
            title="Delete Confirmation"
            type="collection-days"
            setOpen={setModalOpen}
            modalType={modalType}
            id={deleteId}
            setModalType={setModalType}
            onDelete={(val) => {
              loadData();
            }}
          />
        ) : modalType === "Add" || modalType === "Edit" ? (
          <CollectionDaysModals
            open={modalOpen}
            setOpen={setModalOpen}
            title={modalTitle}
            modalType={modalType}
            action={action}
            singleData={singleData}
            onFinishApi={(val) => {
              loadData();
            }}
          />
        ) : (
          ""
        )}
      </Layout>
    </div>
  );
};

export default CollectionDaysLogic;
