import { Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData } from "../../apiHelper";
import { CloseCircleOutlined } from "@ant-design/icons";
import CLoadingButton from "../InputFields/CLoadingButton";
const LoanDisbursalModals = ({
  modalType,
  modalTitle,
  setModalType,
  setEditCancleIcon,
  editCancleIcon,
  loadUtilizationList,
  editData,
  loadTermList,
  termsCancleIcon,
  termsEditData,
}) => {
  const [utilizationPurpose] = Form.useForm();
  const [termForm] = Form.useForm();
  const [saveUtilBtnLoading, setSaveUtilBtnLoading] = useState(false);
  const [saveTermBtnLoading, setSaveTermBtnLoading] = useState(false);

  console.log("termsEditData=======>", termsEditData);
  console.log("modalType", modalType);

  const handleClose = () => {
    setModalType(false);
  };

  const closeTermsEdit = () => {
    termForm.resetFields();
  };

  const closeEdit = () => {
    setEditCancleIcon(false);
    utilizationPurpose.resetFields();
  };

  const onFailedUtilizationPurpose = () => {};

  const onFailedTerms = (values) => {};

  // Utilization Purpose
  const onFinishUtilizationPurpose = (data) => {
    setSaveUtilBtnLoading(true);

    const endPoint =
      modalType === "add-purpose"
        ? "system-config/utilization/create"
        : "system-config/utilization/update/" + editData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          setSaveUtilBtnLoading(false);
          message.success(result?.message);
          handleClose();
          utilizationPurpose.resetFields();
          loadUtilizationList();
        } else {
          setSaveUtilBtnLoading(false);
          message.warning(result?.message);
        }
        setSaveUtilBtnLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSaveUtilBtnLoading(false);
      });
  };

  //TERMS
  const onFinishTerms = (data) => {
    setSaveTermBtnLoading(true);

    const endPoint =
      modalType === "add-term"
        ? "system-config/terms/create"
        : "system-config/terms/update/" + termsEditData?.id;

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          setSaveTermBtnLoading(false);
          message.success(result?.message);
          termForm.resetFields();
          handleClose();
          loadTermList();
        } else {
          message.warning(result?.message);
          setSaveTermBtnLoading(false);
        }
        setSaveTermBtnLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSaveTermBtnLoading(false);
      });
  };

  useEffect(() => {
    if (modalType === "add-purpose") {
      utilizationPurpose.resetFields();
    } else if (modalType === "edit-purpose") {
      utilizationPurpose.setFieldsValue(editData);
    } else if (modalType === "add-term") {
      termForm.resetFields();
    } else if (modalType === "edit-term") {
      termForm.setFieldsValue(termsEditData);
    }
  }, [utilizationPurpose, editData, modalType, termsEditData, termForm]);

  return (
    <div>
      <Modal
        open={modalType}
        onCancel={handleClose}
        title={modalTitle}
        className="poppins_regular"
        width={500}
        footer={null}
        closable={true}
        centered={true}
      >
        {modalType === "add-purpose" || modalType === "edit-purpose" ? (
          <Form
            name="basic"
            onFinish={onFinishUtilizationPurpose}
            onFinishFailed={onFailedUtilizationPurpose}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={utilizationPurpose}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Utilization name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Utilization name is required!",
                    },
                    {
                      message: "Please Enter Only Characters",
                      pattern: new RegExp(/[a-zA-Z]/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    placeholder="Enter utilization name"
                    suffix={
                      editCancleIcon ? (
                        <CloseCircleOutlined onClick={closeEdit} />
                      ) : (
                        ""
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="SAVE"
                  className="poppins_medium umoney_btn"
                  loading={saveUtilBtnLoading}
                  block
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <Form
            name="basic"
            onFinish={onFinishTerms}
            onFinishFailed={onFailedTerms}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={termForm}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Term name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Term name is required!",
                    },
                    {
                      message: "Please Enter Letters",
                      pattern: new RegExp(/[a-zA-Z]/),
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    placeholder="Enter term name"
                    suffix={
                      termsCancleIcon ? (
                        <CloseCircleOutlined onClick={closeTermsEdit} />
                      ) : (
                        ""
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <CLoadingButton
                  htmlType="submit"
                  size="large"
                  value="SAVE"
                  className="poppins_medium umoney_btn"
                  loading={saveTermBtnLoading}
                  block
                />
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default LoanDisbursalModals;
