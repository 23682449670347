import React, { useState } from "react";
import { Modal, Form, Col, Row, Select, Input, message } from "antd";
import CLoadingButton from "../InputFields/CLoadingButton";
import { getAPIData } from "../../apiHelper";
import { useEffect } from "react";
import _ from "lodash";

const role_type = [
  {
    value: "1",
    label: "App",
  },
  {
    value: "2",
    label: "Admin Panel",
  },
];

const RoleManagementModals = (props) => {
  const {
    title,
    open,
    setOpen,
    modalType,
    roleData,
    onFinishApi = () => {},
  } = props;
  const id = roleData?.id;

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      modalType === "add-role"
        ? "user-agent/create-role"
        : "user-agent/update-role/" + id;
    data.type = "2";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        console.log("result =====>>>>> ", result);
        if (result?.status === true) {
          // var response = result?.data;
          message.success(result?.message);
          form.resetFields();
          setSaveBtnLoading(false);
          setOpen(false);
          onFinishApi(result?.status);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const convertToSlug = (e) => {
    var text = e.target.value;
    var slug = text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    form.setFieldValue("code", slug);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (!_.isEmpty(roleData) && modalType === "edit-role") {
      form.setFieldsValue(roleData);
    }
  }, [form, modalType, roleData]);

  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        mask={true}
        footer={null}
        closable={true}
        className="poppins_regular"
        onCancel={handleClose}
        width={500}
      >
        {modalType === "add-role" || modalType === "edit-role" ? (
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="umoney-form"
            requiredMark={false}
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Role Name"
                  name="name"
                  onChange={convertToSlug}
                  rules={[
                    { required: true, message: "Role name is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input size="large" placeholder="Enter role name" />
                </Form.Item>
              </Col>
              {/* <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Role"
                  name="type"
                  rules={[
                    { required: true, message: "Role type is required!" },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Select
                    className="br"
                    size="large"
                    options={role_type}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col> */}
              <Col className="gutter-row" span={24} style={{ display: "none" }}>
                <Form.Item
                  label="Code"
                  name="code"
                  rules={[
                    { required: true, message: "Code is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <Input
                    size="large"
                    placeholder="Enter Code"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    { required: true, message: "Description is required!" },
                    {
                      whitespace: true,
                      message: "This field can not contain whitespaces.",
                    },
                  ]}
                  className="poppins_medium mb-35"
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter description"
                    size="large"
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={24}>
                <Form.Item>
                  <CLoadingButton
                    htmlType="submit"
                    size="large"
                    value="Save"
                    className="poppins_medium umoney_btn"
                    loading={saveBtnLoading}
                    block
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default RoleManagementModals;
