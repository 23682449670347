import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Table, Modal, Row, Col, Space, Button, message } from "antd";
import _ from "lodash";
import { Input } from "antd";
import ActionHistory from "../../../components/Modals/BankAccount/ActionHistory ";
import ViewChangelog from "../../../components/Modals/BankAccount/ViewChangeLog";
import DocumentModal from "../../../components/Modals/modal/DocumentModal";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import ConfirmStatus from "../../../components/Modals/BankAccount/ConfirmStatus";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ClickToCallModal from "../../../components/Modals/modal/ClickToCallModal";
import ViewApiResponse from "../../../components/Modals/BankAccount/ViewApiResponse";
import moment from "moment";
import CTableSearch from "../../../components/InputFields/CTableSearch";
import CallHistory from "../../../components/Modals/modal/CallHistory";

const TableComponent = ({
  setTotalCount,
  setApprovedCount,
  setPendingCount,
  setRejectedCount,
  setFollowupCount,
  setDocumentUploadCount,
  role = "",
  user_ids = "",
  onSave = () => {},
  setForwardToAppCount,
}) => {
  const [Documentsmodal, setDocumentsmodal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [userId, setUserId] = useState("");
  const [bankId, setBankId] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [modal, setModal] = useState("close");
  const [name, setName] = useState("");
  const [userRoleId, setUserRoleId] = useState("");

  // Filter States
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  //Table Loader
  const [tableLoading, setTableLoading] = useState(false);

  const [altNumber, setAltNumber] = useState("");
  const [regNumber, setRegNumber] = useState("");

  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);

  const pageType = "bank";

  useEffect(() => {
    bankAgentList();
  }, []);

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const openModal = (type) => {
    if (type === "action-history") {
      setModalType(type);
      setModalTitle("Action History");
      setModalWidth(1400);
    } else if (type === "view-change-log") {
      setModalType(type);
      setModalTitle("View Change Log");
      setModalWidth(1600);
    } else if (type === "document") {
      setModalType(type);
      setModalTitle("DOCUMENTS");
      setModalWidth(500);
    } else if (type === "confirm-status") {
      setModalType(type);
      setModalTitle("Confirm Status");
      setModalWidth(600);
    } else if (type === "click-to-call") {
      setModalType(type);
      setModalTitle("Click To  Call");
      setModalWidth(500);
    } else if (type === "call-history") {
      setModalType(type);
      setModalTitle("Call History (static)");
      setModalWidth(1000);
    } else if (type === "view-api-response") {
      setModalType(type);
      setModalTitle("View Api Response");
      setModalWidth(500);
    }
  };

  const handleCloseModal = () => {
    setAccountNumber("");
    setIfscCode("");
    setModalType("");
    setPage(1);
    setCurrent(1);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchDateProps = (dataIndex) => ({
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilter: (value, record) =>
      moment(record[dataIndex]).format("DD-MM-YYYY") ===
      value.format("DD-MM-YYYY"),

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CTableSearch
        datePicker
        onDateChange={(e) => {
          setSelectedKeys([e]);
        }}
        onSearch={() => {
          handleSearch(selectedKeys, confirm, dataIndex);
        }}
        onReset={() => clearFilters && handleReset(clearFilters, confirm)}
      />
    ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape={false}
          textToHighlight={text ? text : ""}
        />
      ) : (
        text
      ),
  });

  const Approvercolumns = [
    {
      title: "REQUEST ID",
      dataIndex: "id",
      width: "7%",
      sorter: (a, b) => a?.id - b?.id,
      filteredValue: filteredInfo.id || null,
      ...getColumnSearchProps("id"),
    },
    {
      title: "REQUEST TIME STAMP",
      dataIndex: "createdAt",
      width: "10%",
      filteredValue: filteredInfo.createdAt || null,
      ...getColumnSearchDateProps("createdAt"),
    },
    {
      title: "USER DETAILS",
      dataIndex: "user_details",
      ...getColumnSearchProps("user_details"),
      width: "13%",
      filteredValue: filteredInfo.user_details || null,
      render: (_noUse, userDetails) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: userDetails?.user_details }}
          />
        );
      },
    },

    {
      title: "DOCUMENT",
      dataIndex: "document",
      key: "operation",
      filteredValue: filteredInfo.document || null,
      width: "10%",
      render: (_noUse, userDetails) => (
        <CLoadingButton
          size="large"
          value="VIEW"
          className="poppins_medium umoney_modal_outlined_btn "
          onClick={() => {
            openModal("document");
            setModal("open");
            setIfscCode(userDetails?.ifsc_code);
            setAccountNumber(userDetails?.acc_number);
            setUserId(userDetails?.user_id);
            setBankId(userDetails?.bank_id);
          }}
          block
        />
      ),
    },
    {
      title: "ACCOUNT DETAILS",
      dataIndex: "account_details",
      width: "14%",
      filteredValue: filteredInfo.account_details || null,
      ...getColumnSearchProps("account_details"),
      render: (_noUse, accDetails) => {
        return (
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: accDetails?.account_details }}
            />
            <CLoadingButton
              size="large"
              value="VIEW"
              className="poppins_medium umoney_modal_outlined_btn "
              onClick={() => {
                openModal("view-api-response");
                setUserId(accDetails?.user_id);
                setName(
                  accDetails?.first_name +
                    "" +
                    accDetails?.middle_name +
                    "" +
                    accDetails?.last_name
                );
                setUserRoleId(accDetails?.role_id);
                setIfscCode(accDetails?.ifsc_code);
                setAccountNumber(accDetails?.acc_number);
              }}
              block
              style={{ marginTop: "10px" }}
            />
          </div>
        );
      },
    },
    {
      title: "COMMENT FROM USER",
      dataIndex: "user_comment",
      width: "13%",
      filteredValue: filteredInfo.user_comment || null,
      ...getColumnSearchProps("user_comment"),
    },

    {
      title: "ACTION",
      dataIndex: "action",
      key: "operation",
      // width: "10%",
      filteredValue: filteredInfo.action || null,
      render: (_noUse, userDetails) => {
        return (
          <div>
            <CLoadingButton
              size="large"
              value="CLICK TO CALL"
              className="poppins_medium umoney_modal_outlined_btn "
              style={{ margin: 5 }}
              onClick={() => {
                openModal("click-to-call");
                setAltNumber(userDetails?.alt_number);
                setRegNumber(userDetails?.mobile_number);
              }}
              block
            />
            <CLoadingButton
              size="large"
              value="TAKE ACTION"
              className="poppins_medium umoney_modal_outlined_btn "
              style={{ margin: 5 }}
              onClick={() => {
                openModal("confirm-status");
                setUserId(userDetails?.user_id);
                setBankId(userDetails?.bank_id);
              }}
              block
            />
          </div>
        );
      },
    },
    {
      title: "LAST ACTIONED TIME STAMP",
      dataIndex: "updatedAt",
      width: "12%",
      filteredValue: filteredInfo.updatedAt || null,
      ...getColumnSearchDateProps("updatedAt"),
      render: (text, record) => (
        <div>
          <span>{record?.updatedAt}</span>
          <br />
          <span>{record?.agentName}</span>
        </div>
      ),
    },
    {
      title: "NEXT FOLLOW-UP TIME STAMP ",
      dataIndex: "followup_ts",
      width: "12%",
      ...getColumnSearchDateProps("followup_ts"),
      filteredValue: filteredInfo.followup_ts || null,
      render: (text, record) => (
        <span>
          {!_.isEmpty(record?.followup_ts) ? record?.followup_ts : "-"}
        </span>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "action_status",
      filteredValue: filteredInfo.action_status || null,
      render: (_noUse, data) => (
        <div>
          <span>
            {!_.isEmpty(data?.action_status)
              ? data?.action_status === "1"
                ? "Approve"
                : data?.action_status === "-1"
                ? "Reject"
                : data?.action_status === "2"
                ? "Follow Up"
                : data?.action_status === "3"
                ? "Document Upload"
                : data?.action_status === "4"
                ? "Pending"
                : ""
              : "-"}
          </span>
        </div>
      ),
      filters: [
        { value: "1", label: "Approve", text: "Approve" },
        { value: "-1", label: "Reject", text: "Reject" },
        { value: "4", label: "Pending", text: "Pending" },
        { value: "2", label: "Follow up", text: "follow up" },
        { value: "3", label: "Document Upload", text: "Document Upload" },
      ],
      filterSearch: true,
      // onFilter: (value, record) =>
      //   record?.action_status != null
      //     ? record.action_status
      //         .toString()
      //         .toLowerCase()
      //         .includes(value.toString().toLowerCase())
      //     : null,
      onFilter: (value, record) =>
        record?.action_status != null
          ? record.action_status.indexOf(value) === 0
          : null,
    },
    {
      title: "REMARKS BY AGENT",
      dataIndex: "remark_by_agent",
      width: "15%",
      filteredValue: filteredInfo.remark_by_agent || null,
      ...getColumnSearchProps("remark_by_agent"),
    },
    {
      title: "HISTORY",
      dataIndex: "history",
      key: "operation",
      // width: "10%",
      filteredValue: filteredInfo.history || null,
      render: (_noUse, userDetails) => (
        <div>
          <CLoadingButton
            size="large"
            value="CALL HISTORY"
            className="poppins_medium umoney_modal_outlined_btn "
            style={{ margin: 5 }}
            onClick={() => {
              openModal("call-history");
            }}
            block
          />
          <CLoadingButton
            size="large"
            value="ACTION HISTORY"
            className="poppins_medium umoney_modal_outlined_btn "
            style={{ margin: 5 }}
            onClick={() => {
              openModal("action-history");
              setUserId(userDetails?.user_id);
              setBankId(userDetails?.bank_id);
              setModal("open");
            }}
            block
          />
          <CLoadingButton
            size="large"
            value="View Change Log "
            className="poppins_medium umoney_modal_outlined_btn "
            style={{ margin: 5 }}
            onClick={() => {
              openModal("view-change-log");
              setModal("open");
              setBankId(userDetails?.bank_id);
            }}
            block
          />
        </div>
      ),
    },
    {
      title: "CURRENTLY WITH",
      dataIndex: "agentName",
      width: "30%",
      filteredValue: filteredInfo.agentName || null,
      ...getColumnSearchProps("agentName"),
      hidden: true,
    },
  ];

  const newColumns = Approvercolumns.filter((item) => !item.hidden);

  const onSelectChange = (newSelectedRowKeys, selectedKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    let dataUser = [];
    selectedKeys.map((data) => {
      dataUser.push({
        user_id: data?.user_id,
        bankId: data?.bank_id,
      });
    });
    // setUserIds(dataUser);
    user_ids(dataUser);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleClick = () => {
    setDocumentsmodal(false);
  };

  const [bankAgentListData, setBankAgentListData] = useState([]);
  const bankAgentList = () => {
    setTableLoading(true);
    const endPoint = `bank-agent/listing`;
    getAPIData(endPoint, "get", {}, false, "bankAcc")
      .then((result) => {
        if (result?.status === true) {
          var res = result?.data;
          if (!_.isEmpty(res) && _.isArray(res)) {
            const total = res?.length;
            setTotalCount(total);
            setBankAgentListData(res);

            // action button state : 1=Approve, -1=Reject, 2=follow-up, 3= document upload, 4= Pending

            const rejCount = res.filter((data) => data.action_status === "-1");
            const rejectedCount = rejCount.length;
            setRejectedCount(rejectedCount);

            const penCount = res.filter((data) => data.action_status === "4");
            const pendedCount = penCount.length;
            setPendingCount(pendedCount);

            const appCount = res.filter((data) => data.action_status === "1");
            const approvedCount = appCount.length;
            setApprovedCount(approvedCount);

            const followCount = res.filter(
              (data) => data.action_status === "2"
            );
            const followupCount = followCount.length;
            setFollowupCount(followupCount);

            const documentupload = res.filter(
              (data) => data.action_status === "3"
            );
            const documentUploadCount = documentupload.length;
            setDocumentUploadCount(documentUploadCount);

            setBankAgentListData(res);
            // setTableLoading(false);
          } else {
            const rejCount = res.filter((data) => data.action_status === "-1");
            const rejectedCount = rejCount.length;
            setRejectedCount(rejectedCount);

            const approvedCount = 10; // Need to set dynamic
            setForwardToAppCount(approvedCount);

            let arr = [];
            res.map((data) => {
              arr.push(data.action_status);
            });
            const total = arr.length;
            setTotalCount(total);
            setBankAgentListData(res);
            setTableLoading(false);
          }
        } else {
          message.warning(result?.message);
          setTableLoading(false);
        }
        setTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
        console.warn(`💥️💥️💥️ ~~ bankAgentList ~~ err -- >`, err);
      });
  };

  return (
    <div>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "20px", marginTop: "-20px" }}
      >
        <Col span={6}>
          <CLoadingButton
            size="large"
            value="CLEAR FILTERS"
            className="poppins_medium umoney_btn"
            onClick={() => clearFilters()}
            block
          />
        </Col>
      </Row>
      <div className="umoney_table">
        <Table
          rowSelection={role === "caller" ? "" : rowSelection}
          columns={role === "approver" ? newColumns : Approvercolumns}
          dataSource={bankAgentListData}
          scroll={{
            x: 2300,
          }}
          rowKey={(record) => record.id}
          pagination={{
            showSizeChanger: true,
            locale: { items_per_page: "" },
          }}
          onChange={handleChange}
          loading={tableLoading}
        />
      </div>
      <Modal
        open={modalType}
        title={modalTitle}
        onCancel={handleCloseModal}
        centered
        width={modalWidth}
        footer={false}
        // destroyOnClose={true}
      >
        {modalType === "action-history" ? (
          <ActionHistory
            user_id={userId}
            bank_id={bankId}
            current={current}
            setCurrent={setCurrent}
            page={page}
            setPage={setPage}
          />
        ) : modalType === "view-change-log" ? (
          <ViewChangelog
            bank_id={bankId}
            modal={modal}
            setModal={setModal}
            current={current}
            setCurrent={setCurrent}
            page={page}
            setPage={setPage}
          />
        ) : modalType === "document" ? (
          <DocumentModal
            handleClick={handleClick}
            user_id={userId}
            bank_id={bankId}
            ifscCode={ifscCode}
            accountNumber={accountNumber}
            modal={modal}
            setModal={setModal}
            handleCloseModal={handleCloseModal}
            onClose={() => setModalType(false)}
            bankAgentList={bankAgentList}
          />
        ) : modalType === "click-to-call" ? (
          <ClickToCallModal regNumber={regNumber} altNumber={altNumber} />
        ) : modalType === "confirm-status" ? (
          <ConfirmStatus
            userId={userId}
            bankId={bankId}
            onSave={() => {
              setModalType("");
              onSave();
            }}
          />
        ) : modalType === "call-history" ? (
          <CallHistory pageType={pageType} />
        ) : modalType === "view-api-response" ? (
          <ViewApiResponse
            user_id={userId}
            user_role_id={userRoleId}
            name={name}
            ifsc_code={ifscCode}
            acc_number={accountNumber}
            modal={modal}
            setModal={setModal}
            handleCloseModal={handleCloseModal}
          />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};
export default TableComponent;
