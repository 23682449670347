import React, { useState, useEffect, useRef } from "react";
import { Col, message, Row, Table, Tag, Input, Space, Button } from "antd";
import { getAPIData } from "../../apiHelper";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CTableSearch from "./CTableSearch";
import CLoadingButton from "./CLoadingButton";
import _ from "lodash";

const CTableData = ({
  setTotalCount,
  setApprovedCount,
  setPendingCount,
  setRejectedCount,
  user_ids,
  pathType,
  setForwardToAppCount,
  setCheckCount,
  role,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [yScroll, setYScroll] = useState(false);
  const [xScroll, setXScroll] = useState(undefined);
  const [ellipsis, setEllipsis] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  var verifiedApproved = "#3EA467";
  var pending = "#DE1717";
  var completed = "#ffbf00";
  var checked = "#0000FF";

  const navigate = useNavigate();

  //Search functionality
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  //Table data
  const [tableData, setTableData] = useState([]);
  // const [selectionType, setSelectionType] = useState("checkbox");

  useEffect(() => {
    const loadListData = () => {
      const endPoint = "student/list";
      const data = { type: pathType };
      getAPIData(endPoint, "get", data, false, "student")
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            if (!_.isEmpty(response) && _.isArray(response)) {
              if (pathType === "list") {
                const rejCount = response.filter(
                  (data) => data.status === "-1"
                );
                const rejectedCount = rejCount.length;
                setRejectedCount(rejectedCount);

                const penCount = response.filter((data) => data.status === "0");
                const pendedCount = penCount.length;
                setPendingCount(pendedCount);

                const appCount = response.filter((data) => data.status === "2");
                const approvedCount = appCount.length;
                setApprovedCount(approvedCount);

                const checkCount = response?.filter(
                  (data) => data.status === "1"
                );
                const checkedCount = checkCount.length;
                setCheckCount(checkedCount);

                // const total = rejectedCount + pendedCount + approvedCount;
                // setTotalCount(total);
                let arr = [];
                response.map((data) => {
                  arr.push({
                    total: data.status,
                  });
                });

                const total = arr?.length;
                setTotalCount(total);

                setTableData(response);
              } else {
                const rejCount = response.filter(
                  (data) => data.status === "-1"
                );
                const rejectedCount = rejCount.length;
                setRejectedCount(rejectedCount);

                const appCount = response.filter((data) => data.status === "2");
                const approvedCount = appCount?.length;
                setApprovedCount(approvedCount);

                const penCount = response.filter((data) => data.status === "0");
                const pendedCount = penCount.length;

                setPendingCount(pendedCount);

                const checkCount = response?.filter(
                  (data) => data.status === "1"
                );
                const checkedCount = checkCount.length;
                setCheckCount(checkedCount);

                let arr = [];
                response.map((data) => {
                  arr.push({
                    total: data.status,
                  });
                });

                const total = arr?.length;
                setTotalCount(total);
                setTotalCount(total);
                setTableData(response);
              }
            }
          } else {
            message.warning(result?.message);
          }
          setTableLoading(false);
        })
        .catch((err) => {
          message.error(err);
          setTableLoading(false);
        });
    };

    loadListData();
  }, [
    pathType,
    setApprovedCount,
    setForwardToAppCount,
    setPendingCount,
    setRejectedCount,
    setTotalCount,
  ]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log(selectedKeys, confirm, dataIndex);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilter: (value, record) =>
      moment(record[dataIndex]).format("DD-MM-YYYY") ===
      value.format("DD-MM-YYYY"),

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CTableSearch
        datePicker
        onDateChange={(e) => {
          setSelectedKeys([e]);
        }}
        onSearch={() => {
          handleSearch(selectedKeys, confirm, dataIndex);
        }}
        onReset={() => clearFilters && handleReset(clearFilters, confirm)}
      />
    ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape={false}
          textToHighlight={text ? text : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchTextProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Search"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : null,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "USER DETAILS",
      dataIndex: "user_details",
      key: "user_details",
      filteredValue: filteredInfo.user_details || null,
      sorter: (a, b) => a?.user_id - b?.user_id,
      ...getColumnSearchTextProps("user_details"),
      render: (_noUse, userDetails) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: userDetails?.user_details }}
            style={{ cursor: "pointer" }}
          />
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            navigate(`/detail-view?user_id=${record.user_id}`);
          },
        };
      },
    },
    {
      title: "MODULE STATUS",
      dataIndex: "module_status",
      key: "module_status",
      width: "20%",
      filteredValue: filteredInfo.module_status || null,
      render: (text, record) => (
        <>
          <Tag
            className="module_status"
            color={
              record?.kyc === 100
                ? completed
                : record?.kyc === 101
                ? checked
                : record?.kyc === 102
                ? verifiedApproved
                : pending
            }
          >
            KYC
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.pan === 100
                ? completed
                : record?.pan === 101
                ? checked
                : record?.pan === 102
                ? verifiedApproved
                : pending
            }
          >
            PAN
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.basic_info === 100
                ? completed
                : record?.basic_info === 101
                ? checked
                : record?.basic_info === 102
                ? verifiedApproved
                : pending
            }
          >
            Personal
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.selfie === 100
                ? completed
                : record?.selfie === 101
                ? checked
                : record?.selfie === 102
                ? verifiedApproved
                : pending
            }
          >
            Selfie
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.selfie_video === 100
                ? completed
                : record?.selfie_video === 101
                ? checked
                : record?.selfie_video === 102
                ? verifiedApproved
                : pending
            }
          >
            Selfie Video
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.college === 100
                ? completed
                : record?.college === 101
                ? checked
                : record?.college === 102
                ? verifiedApproved
                : pending
            }
          >
            Student
          </Tag>
          <Tag
            className="module_status"
            color={
              record?.bits_piece === 100
                ? completed
                : record?.bits_piece === 101
                ? checked
                : record?.bits_piece === 102
                ? verifiedApproved
                : pending
            }
          >
            Bits & Peices
          </Tag>
        </>
      ),
    },
    {
      title: "USER REGISTRATION TIMESTAMP",
      dataIndex: "users_createdAt",
      key: "users_createdAt",
      filteredValue: filteredInfo.users_createdAt || null,
      ...getColumnSearchProps("users_createdAt"),
    },
    {
      title: "USER LAST ACTIONED AT",
      dataIndex: "percentage_updatedAt",
      filteredValue: filteredInfo.percentage_updatedAt || null,
      width: "14%",
      ...getColumnSearchProps("percentage_updatedAt"),
      render: (text, record) => (
        <span>
          {record?.agentName} <br /> Timestamp - {record?.percentage_updatedAt}
        </span>
      ),
    },

    {
      title: "AGENT LAST ACTIONED",
      dataIndex: "agent_last_action_at",
      key: "agent_last_action_at",
      filteredValue: filteredInfo.agent_last_action_at || null,
      ...getColumnSearchProps("agent_last_action_at"),
    },
    {
      title: "LAST CALL STATUS",
      dataIndex: "studentOnbDisp",
      key: "studentOnbDisp",
      filteredValue: filteredInfo.studentOnbDisp || null,
      ...getColumnSearchTextProps("studentOnbDisp"),
      render: (_noUse, studentOnbDisp) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: studentOnbDisp?.studentOnbDisp
                ? studentOnbDisp?.studentOnbDisp
                : "",
            }}
          />
        );
      },
    },
    {
      title: "CURRENTLY WITH",
      dataIndex: "agentName",
      key: "agentName",
      filteredValue: filteredInfo.agentName || null,
      ...getColumnSearchTextProps("agentName"),
      hidden: true,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filteredValue: filteredInfo.status || null,
      render: (text, record) => (
        <span>
          {record?.status === "0"
            ? "Pending"
            : record?.status === "1"
            ? "Checked"
            : record?.status === "-1"
            ? "Reject"
            : record?.status === "2"
            ? "Approve"
            : ""}
        </span>
      ),
      filters: [
        { value: "0", label: "Pending", text: "Pending" },
        { value: "-1", label: "Reject", text: "Reject" },
        { value: "1", label: "Checked", text: "Checked" },
        { value: "2", label: "Approve", text: "Approve" },
      ],
      filterSearch: true,
      onFilter: (value, record) =>
        record.status
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
  ];

  const newColumns = columns.filter((item) => !item.hidden);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    user_ids(newSelectedRowKeys);
    console.log(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const scroll = {};
  if (yScroll) {
    scroll.y = "100vw";
  }
  if (xScroll) {
    scroll.x = "100vw";
  }
  const tableColumns = columns.map((item) => ({
    ...item,
    ellipsis,
  }));
  if (xScroll === "fixed") {
    tableColumns[0].fixed = true;
    tableColumns[tableColumns.length - 1].fixed = "right";
  }

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col span={6}>
          <CLoadingButton
            size="large"
            value="CLEAR FILTERS"
            className="poppins_medium umoney_btn"
            onClick={() => clearFilters()}
            block
          />
        </Col>
      </Row>
      <div className="umoney_table">
        <Table
          // {...tableProps}
          pagination={{
            position: ["none", "bottomLeft"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
          }}
          columns={
            role === "superadmin" ||
            role === "supervisor_caller" ||
            role === "supervisor_approver"
              ? columns
              : newColumns
          }
          dataSource={tableData}
          rowSelection={
            role === "superadmin" ||
            role === "supervisor_caller" ||
            role === "supervisor_approver"
              ? rowSelection
              : ""
          }
          scroll={scroll}
          rowKey={(record) => record.user_id}
          onChange={handleChange}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};

export default CTableData;
