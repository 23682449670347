import React from "react";
import { Col, Form, Input, message, Row } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import { useState } from "react";

const ClearInApp = ({ user_id, pageType, handleClose, loading }) => {
  console.log("pageType", pageType);
  const [form] = Form.useForm();
  const userId = user_id;
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    const endPoint =
      pageType === "salaried"
        ? "student/clear-notifications"
        : pageType === "credit-check-detail"
        ? "cc-student/clear-notifications"
        : "student/clear-notifications";
    console.log("endPoint", endPoint);
    const dataDays = { ...data, userId };
    getAPIData(
      endPoint,
      "post",
      dataDays,
      false,
      pageType === "salaried"
        ? "salaried"
        : pageType === "credit-check-detail"
        ? "credit_check"
        : "student"
    )
      .then((result) => {
        if (result?.status === true) {
          handleClose();
          message.success(result?.message);
          form.resetFields();
          setSaveBtnLoading(false);
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
        setSaveBtnLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const onFinishFailed = () => {
    console.log("fail");
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label="Number of Days"
              name="days"
              size="large"
              rules={[
                {
                  required: true,
                  message: "Days is required!",
                },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Days contains only number.",
                },
                () => ({
                  validator(rule, value) {
                    if (value > 365) {
                      return Promise.reject("Value must be less than 365");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="Enter number of days" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={24}>
            <Form.Item>
              <CLoadingButton
                size="large"
                value="SUBMIT"
                htmlType="submit"
                className="poppins_medium urban_money_btn"
                block
                loading={saveBtnLoading}
                style={{ borderRadius: "5px" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ClearInApp;
