import React, { useEffect, useState } from "react";
import { Table, Input, Form, Row, Col, message, Modal } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";
import InternalDecision from "./InternalDecision";
import TextArea from "antd/lib/input/TextArea";

const VerificationBox = ({
  userId,
  deviceId,
  actionButton,
  handleCloseModal,
  loadData,
}) => {
  console.log(userId, deviceId, actionButton);
  //add btn
  const [loading, setLoading] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [form] = Form.useForm();

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");
  const [id, setId] = useState();

  const openModal = (type) => {
    if (type === "internal-decision") {
      setModalType(type);
      setModalTitle("Internal-Decision");
      setModalWidth(500);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [userId, deviceId, actionButton, loadData]);

  const handleCloseInternal = () => {
    setModalType("");
  };

  const addClick = () => {
    setLoading(true);
    setAddBtnLoading(true);
  };

  const onFinishFailed = () => {
    console.log("error");
  };

  const onFinish = (data) => {
    setAddBtnLoading(true);
    const verifiedData = {
      ...data,
      user_id: userId,
      device_id: deviceId,
      action_button: actionButton,
    };
    const endPoint = "device/add-verification-box";
    getAPIData(endPoint, "post", verifiedData, false, "device_change")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          var response = result?.data?.id;
          setId(response);
          form.resetFields();
          setAddBtnLoading(false);
          openModal("internal-decision");
          handleCloseModal();
        } else {
          message.warning(result?.message);
          setAddBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setAddBtnLoading(false);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="umoney-form"
        requiredMark={false}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Verified Aganist"
              name="verified_aganist"
              size="large"
              rules={[
                { required: true, message: "Verified Aganist is required!" },
                {
                  pattern: /^[0-9]\d*(\.\d+)?$/g,
                  message: "Verified Against contains only number.",
                },
              ]}
              className="poppins_medium mb-35"
            >
              <Input size="large" placeholder="Enter verified aganist" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Comments"
              name="comment"
              size="large"
              rules={[{ required: true, message: "Comment is required!" }]}
              className="poppins_medium mb-35"
            >
              <TextArea size="large" placeholder="Enter comment" rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <CLoadingButton
              htmlType="submit"
              size="large"
              value="ADD"
              onClick={addClick}
              style={{ width: "100%" }}
              className="poppins_medium umoney_btn"
              loading={addBtnLoading}
            />
          </Col>
        </Row>
      </Form>
      <Modal
        open={modalType}
        title={modalTitle}
        footer={false}
        onCancel={handleCloseInternal}
        centered
        width={modalWidth}
      >
        {modalType === "internal-decision" ? (
          <InternalDecision
            deviceId={deviceId}
            userId={userId}
            actionButton={actionButton}
            id={id}
            handleCloseInternal={handleCloseInternal}
            loadData={loadData}
          />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default VerificationBox;
