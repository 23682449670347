import React, { useState, useEffect } from "react";
import { Typography, Col, Row, Form, Input, message } from "antd";
import CClickLoading from "../../../components/InputFields/CClickLoading";
import Layout from "antd/lib/layout/layout";
import Sidebar from "../../../components/Sidebar";
import { Header } from "antd/lib/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import CLoadingButton from "../../../components/InputFields/CLoadingButton";
import { getAPIData } from "../../../apiHelper";

const AcquisitonForStudent = () => {
  const [callerForm] = Form.useForm();
  const [approverForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { Title } = Typography;

  //Save btn
  const [saveCallerBtnLoading, setSaveCallerBtnLoading] = useState(false);
  const [saveApproverBtnLoading, setSaveApproverBtnLoading] = useState(false);

  //CAllER
  const onFinishCallerSubmit = (values) => {
    setSaveCallerBtnLoading(true);
    onSubmit("caller", values);
  };

  const onFailedCallerSubmit = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  //APPROVER
  const onFinishApproverSubmit = (values) => {
    setSaveApproverBtnLoading(true);
    onSubmit("approver", values);
  };

  const onFailedApproverSubmit = (errorInfo) => {
    console.log("errorInfo", errorInfo);
  };

  //On submit
  const onSubmit = (type, values) => {
    let data;
    let cat_array = [];

    if (type === "caller") {
      Object.entries(values).map(([key, value]) =>
        cat_array.push({
          category: "acquisition_for_student",
          key: key,
          value: value,
        })
      );
    } else if (type === "approver") {
      Object.entries(values).map(([key, value]) =>
        cat_array.push({
          category: "acquisition_for_student",
          key: key,
          value: value,
        })
      );
    }

    data = { system_data: cat_array };
    const endPoint = "system-config/create";

    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        if (result?.status === true) {
          if (type === "caller") {
            message.success(result?.message);
            setSaveCallerBtnLoading(false);
            // loadData();
          } else if (type === "approver") {
            message.success(result?.message);
            setSaveApproverBtnLoading(false);
            // loadData();
          }
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    //Load the Data
    const loadData = () => {
      const data = { category_name: "acquisition_for_student" };
      const endPoint = "system-config/get-config-data";

      getAPIData(endPoint, "post", data, false)
        .then((result) => {
          if (result?.status === true) {
            var response = result?.data;
            response.map(async (data) => {
              callerForm.setFieldsValue({ [data?.name]: data?.value });
              approverForm.setFieldsValue({ [data?.name]: data?.value });
            });
          } else {
            message.warning(result?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    };

    loadData();
  }, [approverForm, callerForm]);

  return loading === true ? (
    <CClickLoading />
  ) : (
    <div className={`${loading ? "page-loading app" : "app"}`}>
      {loading === true ? <CClickLoading /> : ""}
      <Layout
        style={{
          height: "100%",
          padding: "20px 0 0 20px",
          backgroundColor: "#DFFEFC",
        }}
      >
        <Sidebar open={open} setOpen={setOpen} />
        <Header className="site-layout-background main-header">
          <MenuOutlined className="trigger" onClick={() => setOpen(true)} />
          <Title level={4} className="poppins_regular umoney_page_title">
            {" "}
            Super Admin Dashboard
          </Title>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: 15,
            backgroundColor: "#DFFEFC",
          }}
        >
          <div className="white_div">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  CALLER
                </Title>
              </Col>
              <Col span={6} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishCallerSubmit}
                  onFinishFailed={onFailedCallerSubmit}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={callerForm}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Bucket time(pending)"
                        name="student_caller_bucket_time"
                        rules={[
                          {
                            required: true,
                            message: "Bucket time is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input size="large" placeholder="Enter bucket time" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveCallerBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              <Col span={24}>
                <Title
                  level={5}
                  style={{ marginTop: "20px" }}
                  className="poppins_medium umoney_title"
                >
                  APPROVER
                </Title>
              </Col>
              <Col span={12} className="poppins_medium boxshadow-div">
                <Form
                  name="basic"
                  onFinish={onFinishApproverSubmit}
                  onFinishFailed={onFailedApproverSubmit}
                  autoComplete="off"
                  className="umoney-form"
                  requiredMark={false}
                  form={approverForm}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Bucket Time (pending)"
                        name="student_approver_bucket_time"
                        rules={[
                          {
                            required: true,
                            message: "Bucket Time is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input size="large" placeholder="Enter bucket Time" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Follow Up Time (pending)"
                        name="student_approver_follow_up_time"
                        rules={[
                          {
                            required: true,
                            message: "Follow Up Time is required!",
                          },
                          {
                            message: "Please Enter Number",
                            pattern: new RegExp(/^[0-9]+$/),
                          },
                        ]}
                        className="poppins_medium mb-35"
                      >
                        <Input
                          size="large"
                          placeholder="Enter follow up time"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <CLoadingButton
                        htmlType="submit"
                        size="large"
                        value="SAVE"
                        className="poppins_medium umoney_btn"
                        loading={saveApproverBtnLoading}
                        block
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default AcquisitonForStudent;
