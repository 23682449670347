import React, { useEffect, useState } from "react";
import { message, Table } from "antd";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

const columns = [
  {
    title: "AGENT NAME",
    dataIndex: "agentName",
    key: "agentName",
    align: "top",
    width: "15%",
  },
  {
    title: "TIMESTAMP",
    dataIndex: "createdAt",
    key: "createdAt",
    width: "10%",
  },
  {
    title: "VERIFIED BY (NUMBER AND COMMENT)",
    dataIndex: "verify",
    key: "verify",
    width: "20%",
    render: (text, record) => {
      return (
        <div>
          {record?.verified_aganist}, {record?.comment}
        </div>
      );
    },
  },
  {
    title: "COMMENTS",
    dataIndex: "comment",
    key: "comment",
    width: "15%",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    width: "8%",
    render: (text, record) =>
      record?.action_button === "1"
        ? "Approve"
        : record?.action_button === "2"
        ? "Reject"
        : record?.action_button === "3"
        ? "Follow up"
        : record?.action_button === "4"
        ? "Set Temp"
        : record?.action_button === "5"
        ? "Pending"
        : "",
  },
  {
    title: "NEXT FOLLOW-UP TIMESTAMP",
    dataIndex: "followup",
    key: "followup",
    width: "20%",
    render: (text, record) => {
      return (
        <div>
          <span>Followup Timestamp: {record?.followup_ts}</span>
          <br />
          <span>Followup Comment: {record?.follow_up_comment}</span>
        </div>
      );
    },
  },
  {
    title: "INFORMATION TO USER",
    dataIndex: "information_to_user_name",
    key: "information_to_user_name",
    width: "10%",
  },
  {
    title: "INTERNAL DECISION",
    dataIndex: "internal_decision_name",
    key: "internal_decision_name",
    width: "10%",
  },
];

const ActionHistory = (props) => {
  const user_id = props?.userId;
  const device_id = props?.deviceId;
  const [actionHistoryData, setActionHistoryData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [totalPage, setTotalPage] = useState();
  const [tableLoading, setTableLoading] = useState();

  const loadActionHistory = () => {
    const endPoint = "device/action-history-log";
    setTableLoading(true);
    setActionHistoryData("");
    const data = {
      user_id: user_id,
      device_id: device_id,
      page: props?.page,
      limit: pageSize,
    };
    getAPIData(endPoint, "post", data, false, "device_change")
      .then((result) => {
        if (result?.status) {
          setActionHistoryData(result?.data?.result);
          setTotalPage(result?.data?.pagination?.totalCount);
          setTableLoading(false);
        } else {
          message.warning(result?.message);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    // if (_.isEmpty(actionHistoryData)) {
    loadActionHistory();
    // }
  }, [user_id, props?.page, pageSize, props]);

  return (
    <div className="umoney_table">
      <Table
        dataSource={actionHistoryData}
        pagination={
          actionHistoryData === ""
            ? false
            : {
                position: ["none", "bottomLeft"],
                showSizeChanger: true,
                locale: { items_per_page: "" },
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                onChange: (page, pageSize) => {
                  props?.setPage(page);
                  setPageSize(pageSize);
                  props?.setCurrent(page);
                },
                total: totalPage,
                current: props?.page,
              }
        }
        columns={columns}
        style={{ marginTop: "10px" }}
        loading={tableLoading}
      />
    </div>
  );
};

export default ActionHistory;
