import { Col, Modal, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import CLoadingButton from "../../InputFields/CLoadingButton";
import Title from "antd/lib/skeleton/Title";
import { getAPIData } from "../../../apiHelper";
import _ from "lodash";

const CollegeModel = (props) => {
  const {
    onSave = () => {},
    modalType,
    modalTitle,
    setModalType,
    no_of_students,
    pincodeList,
    isMerge,
    editId,
  } = props;

  const [form] = Form.useForm();
  const [savebtnLoading, setSaveBtnLoading] = useState(false);
  // const [pincodeList, setPincodeList] = useState(false);

  const [collegeData, setCollegeData] = useState([]);

  const institiute_type = [
    {
      value: "0",
      label: "Government",
    },
    {
      value: "1",
      label: "Private",
    },
  ];

  const affiliation_type = [
    {
      value: "0",
      label: "Affiliated",
    },
    {
      value: "1",
      label: "Deemed",
    },
  ];

  const handleClose = () => {
    setModalType(false);
  };
  const onSubmitData = (data) => {
    setSaveBtnLoading(true);
    var endPoint =
      modalType === "add-college"
        ? "institute/create"
        : "institute/update/" + collegeData?.id;

    if (isMerge) {
      endPoint = "institute/merge";
      data.institute_id = collegeData?.id;
    }
    data.dynamic_data_param = "1";
    getAPIData(endPoint, "post", data, false)
      .then((result) => {
        setSaveBtnLoading(false);
        if (result?.status === true) {
          onSave();
          message.success(result?.message);
          form.resetFields();
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo===>>", errorInfo);
  };

  const getPincodeValue = async (e) => {
    let abcd = await findArrayElementByTitle(pincodeList, e);
    form.setFieldValue("state", abcd?.state);
    form.setFieldValue("district", abcd?.district);
  };

  if (editId && _.isEmpty(collegeData)) {
    const endPoint = "institute/get/" + editId;

    getAPIData(endPoint, "get", {}, false)
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCollegeData(response);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  useEffect(() => {
    // const loadPincodes = () => {
    //   const endPoint = "template/pincodes";
    //   console.log("endPoint", endPoint);

    //   getAPIData(endPoint, "get", {}, false)
    //     .then((result) => {
    //       console.log("result", result);
    //       if (result?.status === true) {
    //         var pincode_list = [];
    //         result?.data.map((banner) =>
    //           pincode_list.push({
    //             value: banner.id,
    //             label: banner.pincode,
    //             state: banner.state,
    //             district: banner.district,
    //           })
    //         );
    //         setPincodeList(pincode_list);
    //       } else {
    //         message.warning(result?.message);
    //       }
    //     })
    //     .catch((err) => {
    //       message.error(err);
    //     });
    // };

    // loadPincodes();
    if (modalType === "add-college") {
      form.resetFields();
    } else {
      form.setFieldsValue(collegeData);
    }
  }, [form, collegeData, modalType]);

  return (
    <Modal
      open={modalType}
      onCancel={handleClose}
      title={modalTitle}
      className="poppins_regular"
      width={1000}
      footer={null}
      closable={true}
      centered={true}
    >
      <div className="white_div boxshadow-div">
        <Form
          name="basic"
          onFinish={(data) => {
            onSubmitData(data);
          }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[16, 16]} xl={2} lg={4} md={6} sm={8}>
            <Col className="gutter-row" span={18}>
              <Form.Item
                label="Name of college"
                name="college_name"
                rules={[
                  {
                    required: true,
                    message: "Name of college is required!",
                  },
                  {
                    message: "Please Enter Only Characters",
                    pattern: new RegExp(/[a-zA-Z]/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter name of college" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="No. of student"
                name="no_of_student"
                rules={[
                  {
                    required: true,
                    message: "No of student is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  className="br"
                  size="large"
                  options={no_of_students}
                  placeholder="Select no. student"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Institiute type"
                name="institute_type"
                rules={[
                  {
                    required: true,
                    message: "Institiute type is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  className="br"
                  size="large"
                  options={institiute_type}
                  placeholder="Select institute type"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Affiliation type"
                name="affiliation_type"
                rules={[
                  {
                    required: true,
                    message: "Affiliation type is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  className="br"
                  size="large"
                  options={affiliation_type}
                  placeholder="Select affiliation type"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Minimum fees"
                name="min_fees_band"
                rules={[
                  {
                    required: true,
                    message: "Minimum fees is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter minimum fees" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum fees"
                name="max_fees_band"
                rules={[
                  {
                    required: true,
                    message: "Maximum fees is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter maximum fees" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Mean fees"
                name="mean_fees_band"
                rules={[
                  {
                    required: true,
                    message: "Mean fees is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter mean fees" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Median fees"
                name="medium_fees_band"
                rules={[
                  {
                    required: true,
                    message: "Median fees is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter median fees" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Title level={5} style={{ fontSize: "18px" }}>
                Address
              </Title>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Address is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter address" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Landmark"
                name="landmark"
                rules={[
                  {
                    required: true,
                    message: "Landmark is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter Landmark" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="State"
                name="state"
                // rules={[
                //   {
                //     required: true,
                //     message: "State is required!",
                //   },
                // ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter state" disabled={true} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="District"
                name="district"
                // rules={[
                //   {
                //     required: true,
                //     message: "District is required!",
                //   },
                // ]}
                className="poppins_medium mb-35"
              >
                <Input
                  size="large"
                  placeholder="Enter district"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pincode"
                name="pincode_id"
                rules={[
                  {
                    required: true,
                    message: "Pincode is required!",
                  },
                  {
                    message: "Please Enter Number",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Select
                  showSearch
                  className="br"
                  size="large"
                  options={pincodeList}
                  onChange={getPincodeValue}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .localeCompare((optionB?.label ?? "").toString())
                  }
                  placeholder="Select pincode"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Website"
                name="website"
                rules={[
                  {
                    required: true,
                    message: "Website is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter website" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Google map link"
                name="google_map_link"
                rules={[
                  {
                    required: true,
                    message: "Google map link is required!",
                  },
                ]}
                className="poppins_medium mb-35"
              >
                <Input size="large" placeholder="Enter google map link" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={18}></Col>

            <Col className="gutter-row" span={6}>
              <CLoadingButton
                htmlType="submit"
                size="large"
                value=" SAVE"
                className="poppins_medium umoney_modal_filled_btn"
                loading={savebtnLoading}
                block
              />
            </Col>
            <Col className="gutter-row" span={18}></Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

async function findArrayElementByTitle(array, value) {
  return array.find((element) => {
    return element.value === value;
  });
}

export default CollegeModel;
