import React, { useState, useEffect } from "react";
import CClickLoading from "../../components/InputFields/CClickLoading";
import {
  Layout,
  Col,
  Row,
  Typography,
  Table,
  Tabs,
  Collapse,
  Form,
  Tag,
  Modal,
  Spin,
  Select,
  Input,
  message,
} from "antd";
import Sidebar from "../../components/Sidebar";
import { MenuOutlined } from "@ant-design/icons";
import CLoadingButton from "../../components/InputFields/CLoadingButton";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { PhoneOutlined } from "@ant-design/icons";
import Images from "../../config/images";
import SalaryCreditBank from "../../components/Accordion/SalaryCreditBank";
import SalaryInformation from "../../components/Accordion/SalaryInformation";
import { getAPIData } from "../../apiHelper";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
import UploadFiles from "../../components/Accordion/UploadFiles";
import { useLocation } from "react-router-dom";
import OfferLetter from "../../components/Accordion/OfferLetter";
import BankStatement from "../../components/Accordion/BankStatement";
import OtherBankStatement from "../../components/Accordion/OtherBankStatement";
import CompanyIdCard from "../../components/Accordion/CompanyIdCard";
import PANFront from "../../components/Accordion/PAN/PANFront";
import SalarySlip from "../../components/Accordion/SalarySlip";
import ResubmittedGraduationDetails from "../../components/Accordion/ResubmittedGraduationDetails";
import StudentIdFront from "../../components/Accordion/StudentId/StudentIdFront";
import StudentIdBack from "../../components/Accordion/StudentId/StudentIdBack";
import { useNavigate } from "react-router-dom";
import RemoveImage from "../../components/Modals/modal/RemoveImage";
import ClearInApp from "../../components/Modals/modal/ClearInApp";
import CallHistory from "../../components/Modals/modal/CallHistory";
import Viewchangelog from "../../components/Modals/modal/Viewchangelog";
import CallStatusLogModal from "../../components/Modals/modal/CommonModal/CallStatusLogModal";
import MatchFoundModal from "../../components/Modals/modal/MatchFoundModal";
import ClickToCallModal from "../../components/Modals/modal/ClickToCallModal";
import AddDocumentAndComment from "../../components/Modals/modal/CommonModal/AddDocumentAndComment";
import CompanyInformation from "../../components/Accordion/CompanyInformation";
import GraduationDetails from "../../components/Accordion/GraduationDetails";
import StudentIdFrontOld from "../../components/Accordion/StudentIdFrontOld";
import StudentIdBackOld from "../../components/Accordion/StudentIdBackOld";

const { user } = Images;

const { Header, Content } = Layout;
const { Title } = Typography;
const { Panel } = Collapse;

// const role = "Caller"; // Approver,Supervisor,Caller



//SMS history
const smscolumns = [
  {
    title: "MODULE",
    dataIndex: "module",
    key: "module",
    width: "10%",
    render: (text, record) => (
      <div>
        <span>
          {moduleOptions.map((opt) =>
            opt.value === record.module ? opt.label : ""
          )}
        </span>
      </div>
    ),
  },
  {
    title: "SMS CONTENT",
    dataIndex: "content",
    key: "content",
    width: "40%",
  },
  {
    title: "TIME STAMP",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "DELIVERY STATUS",
    dataIndex: "delivery_status",
    key: "delivery_status",
    render: (text, record) => (
      <div>
        <span>
          {record.delivery_status === "1"
            ? "Sent"
            : record.delivery_status === "2"
            ? "Delivered"
            : record.delivery_status === "3"
            ? "Undelivered"
            : ""}
        </span>
      </div>
    ),
  },
  {
    title: "AGENT",
    dataIndex: "agent",
    key: "agent",
    width: "20%",
    render: (text, record) => (
      <div>
        <span>
          {record.agent_name} ({record.agent_role_name})
        </span>
      </div>
    ),
  },
];

const moduleOptions = [
  {
    value: "1",
    label: "KYC Details",
  },
  {
    value: "2",
    label: "PAN Details",
  },
  {
    value: "3",
    label: "Personal Information Details",
  },
  {
    value: "4",
    label: "Student Information Details",
  },
  {
    value: "5",
    label: "Selfie Details",
  },
  {
    value: "6",
    label: "Video Details",
  },
];
//Notification
const notificationColumns = [
  {
    title: "MODULE",
    dataIndex: "module",
    key: "module",
    width: "15%",
    render: (text, record) => (
      <div>
        <span>
          {moduleOptions.map((opt) =>
            opt.value === record.module ? opt.label : ""
          )}
        </span>
      </div>
    ),
  },
  {
    title: "COMMENT",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "TIME STAMP",
    dataIndex: "createdAt",
    key: "createdAt",
    width: "14%",
  },
  {
    title: "SEEN",
    dataIndex: "read_ts",
    key: "read_ts",
    width: "10%",
  },
  {
    title: "AGENT",
    dataIndex: "agent",
    key: "agent",
    width: "16%",
    render: (text, record) => (
      <div>
        <span>
          {record.agent_name} ({record.agent_role_name})
        </span>
      </div>
    ),
  },
];

// whatsapp
const whatsappColumns = [
  {
    title: "MODULE",
    dataIndex: "module",
    key: "module",
    render: (text, record) => (
      <div>
        <span>
          {moduleOptions.map((opt) =>
            opt.value === record.module ? opt.label : ""
          )}
        </span>
      </div>
    ),
  },
  {
    title: "COMMENT",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "TIME STAMP",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "AGENT",
    dataIndex: "agent",
    key: "agent",
    render: (text, record) => (
      <div>
        <span>
          {record.agent_name} ({record.agent_role_name})
        </span>
      </div>
    ),
  },
];

const inAppSentData = [];
for (let i = 1; i <= 20; i++) {
  inAppSentData.push({
    key: i,
    module: "KYC details",
    comment: "Please...",
    timestamp: "19-08-2021 | 18:00",
    seen: <Checkbox></Checkbox>,
    agent: "Kabir (Caller - AC5749)",
  });
}

const StudentToSalariedDetailView = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const user_id = queryParameters.get("user_id");
  console.log("user_id", user_id);
  const [callStatusForm] = Form.useForm();
  const [notificationForm] = Form.useForm();
  const [smsForm] = Form.useForm();
  const [form] = Form.useForm();
  const [topForm] = Form.useForm();

  const navigate = useNavigate();
  const pageType = "std-to-sal";

  const type = location?.state?.type;
  // const type = "1";

  // In app sent
  const [clearInAppBtnLoading, setClearInAppBtnLoading] = useState(false);

  //documnet history
  const [documentHistoryAddBtnLoading, setDocumentHistoryAddBtnLoading] =
    useState(false);

  const [callStatusSelectData, setCallStatusSelectData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [selectedTab, setSelectedTab] = useState();

  //currently working data
  const [currentlyWorkingData, setCurrentlyWorkingData] = useState();

  // Notification List api
  const [notificationData, setNotificationData] = useState([]);

  //Table Loading
  const [notificationTableLoading, setNotificationTableLoading] =
    useState(true);
  const [smsTableLoading, setSmsTableLoading] = useState(true);
  const [whatsappTableLoading, setWhatsappTableLoading] = useState(true);

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const [changeLoading, setChangeLoading] = useState(false);

  const [willContinueStudyData, setWillContinueStudyData] = useState();

  const [pincodeList, setPincodeList] = useState();

  //bank statement images
  const [BankStatementImage, setBankStatementImage] = useState();

  const openModal = (type) => {
    if (type === "remove-image") {
      setModalType(type);
      setModalTitle("Remove Image");
      setModalWidth(500);
    } else if (type === "error") {
      setModalType(type);
      setModalTitle("Error");
      setModalWidth(500);
    } else if (type === "clear-in-app") {
      setModalType(type);
      setModalTitle("Clear in App notification");
      setModalWidth(500);
    } else if (type === "call-history") {
      setModalType(type);
      setModalTitle("Call History");
      setModalWidth(1100);
    } else if (type === "view-change-log") {
      setModalType(type);
      setModalTitle("View Change Log");
      setModalWidth(1500);
    } else if (type === "call-status-log") {
      setModalType(type);
      setModalTitle("Call Status Log");
      setModalWidth(1500);
    } else if (type === "match-found") {
      setModalType(type);
      setModalTitle("Match Found");
      setModalWidth(900);
    } else if (type === "click-to-call") {
      setModalType(type);
      setModalTitle("Click To Call");
      setModalWidth(500);
    } else if (type === "click-to-call-1") {
      setModalType(type);
      setModalTitle("Call Status Log");
      setModalWidth(500);
    } else if (type === "add-document") {
      setModalType(type);
      setModalTitle("Add Document type & comment");
      setModalWidth(500);
    }
  };

  const handleClose = () => {
    setModalType("");
  };

  useEffect(() => {
    loadUserData();
    callStatusData();
    loadNotificationListData();
    loadsmsListData();
    loadWhatsappListData();
    loadPincodes();
  }, []);

  useEffect(() => {
    if (type === "4") {
      loadWillContinueData();
    }
    if (type === "1") {
      loadCurrentlyWorkingData();
    }
  }, [type]);

  useEffect(() => {
    if (changeLoading) {
      if (type === "4") {
        loadWillContinueData();
      } else if (type === "1") {
        loadCurrentlyWorkingData();
      }
      loadUserData();
    }
    setChangeLoading(false);
  }, [changeLoading]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [callStatusBtnLoading, setCallStatusBtnLoading] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);

  // send noti
  const [notiSendBtnLoading, setNotiSendBtnLoading] = useState(false);
  const [smsSendBtnLoading, setSmsSendBtnLoading] = useState(false);
  const [whatsappSendBtnLoading, setWhatsappSendBtnLoading] = useState(false);

  // SMS List Api
  const [smsData, setSmsData] = useState([]);

  // whatsapp list api
  const [whatsappData, setWhatsappData] = useState([]);

  // userData api
  const [userData, setUserData] = useState();

  //call status
  const [selectedSubCat, setSelectedSubCat] = useState("");

  const loadUserData = () => {
    setLoading(true);
    const endPoint = `stu-to-sal/stu-to-sal-onboard-data/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          topForm?.setFieldsValue({
            name:
              response?.personalInfo?.first_name +
              " " +
              response?.personalInfo?.middle_name +
              " " +
              response?.personalInfo?.last_name,
            phone_number: response?.personalInfo?.mobile_number,
            alternate_number: response?.personalInfo?.alt_number,
            father_name: response?.personalInfo?.father_name,
            mother_name: response?.personalInfo?.mother_name,
            date_of_birth: response?.personalInfo?.date_of_birth,
            age: response?.personalInfo?.age,
            gender:
              response?.personalInfo?.gender === "f"
                ? "Female"
                : response?.personalInfo?.gender === "m"
                ? "Male"
                : "Other",
            marital_status: response?.personalInfo?.marital_status,
          });
          setUserData(response);
          setLoading(false);
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const loadNotificationListData = () => {
    setNotificationTableLoading(true);
    const endPoint = `stu-to-sal/notification-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "stu_to_sal")
      .then((result) => {
        console.log("result", result);
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setNotificationData(response);
            setNotificationTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setNotificationTableLoading(false);
        }
        setNotificationTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishCallStatus = (data) => {
    setCallStatusBtnLoading(true);
    setSpinLoading(true);
    const dataCallStatus = { ...data, user_id };
    const endPoint = "stu-to-sal/add-disposition";
    getAPIData(endPoint, "post", dataCallStatus, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          callStatusForm.resetFields();
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setCallStatusBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setCallStatusBtnLoading(false);
        setSpinLoading(false);
      });
  };

  const callStatusData = () => {
    const endpoint = "stu-to-sal/get-dispositions";
    let cat = [];
    getAPIData(endpoint, "get", {}, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          setCallStatusSelectData(response);
          response.map((data) =>
            cat.push({
              label: data?.title,
              value: data?.id,
              text: data?.title,
            })
          );
          setCategoryData(cat);
        } else {
          message.warning(result?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const changeCategory = (e) => {
    callStatusForm.setFieldValue("sub_category", "");
    callStatusForm.setFieldValue("sub_sub_category", "");
    const subs = callStatusSelectData.filter((cat) => cat.id === e);
    let subd = [];
    subs.map((subCat) =>
      subCat.stu_onb_2.map((sub) =>
        subd.push({
          value: sub.id,
          label: sub.title,
          text: sub.title,
        })
      )
    );
    setSubCategoryData(subd);
  };

  const changeSubCategory = (e) => {
    callStatusForm.setFieldValue("sub_sub_category", "");
    setSelectedSubCat(e);
    callStatusSelectData.map((subcat) => {
      const subsubcat = subcat.stu_onb_2.filter((cat) => cat.id === e);
      subsubcat.map((sub) => {
        let subsubdata = [];
        sub.stu_onb_3.map((val) => {
          subsubdata.push({
            value: val.id,
            label: val.title,
            text: val.title,
          });
        });
        setSubSubCategoryData(subsubdata);
      });
    });
  };

  //notification drp api
  const loadNotificationModuleData = (value) => {
    const data = {
      type:
        selectedTab === "SMS"
          ? "sms"
          : selectedTab === "WHATSAPP"
          ? "whatsapp"
          : "notification",
      module: value,
    };
    const endPoint = "stu-to-sal/notification-get-one";
    getAPIData(endPoint, "post", data, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("sms_temp_id", response?.id);
            smsForm.setFieldValue("comment", response?.message_body);
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("whatsapp_temp_id", response?.id);
            form.setFieldValue("comment", response?.message_body);
          } else {
            notificationForm.setFieldValue(
              "notification_temp_id",
              response?.id
            );
            notificationForm.setFieldValue("comment", response?.message_body);
          }
        } else {
          message.warning(result?.message);
          if (selectedTab === "SMS") {
            smsForm.setFieldValue("comment", "");
          } else if (selectedTab === "WHATSAPP") {
            form.setFieldValue("comment", "");
          } else {
            notificationForm.setFieldValue("comment", "");
          }
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onFinishNotification = (data) => {
    setNotiSendBtnLoading(true);
    setSpinLoading(true);
    console.log("success =====>>>>> ", data);

    const nData = {
      user_id: user_id,
      notification_temp_id: data?.notification_temp_id,
      comment: data?.comment,
    };

    const endPoint = "stu-to-sal/notificaton-send";
    getAPIData(endPoint, "post", nData, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          notificationForm.resetFields();
          loadNotificationListData();
          setNotiSendBtnLoading(false);
          setSpinLoading(false);
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setNotiSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setNotiSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const onFinishSms = (data) => {
    setSmsSendBtnLoading(true);
    setSpinLoading(true);
    console.log("success =====>>>>> ", data);

    const nData = {
      user_id: user_id,
      sms_temp_id: data?.sms_temp_id,
      comment: data?.comment,
    };

    const endPoint = "stu-to-sal/send-sms";
    getAPIData(endPoint, "post", nData, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
          smsForm.resetFields();
          loadsmsListData();
        } else {
          message.warning(result?.message);
          setSmsSendBtnLoading(false);
          setSpinLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSmsSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  const getTabValue = (value) => {
    if (value === "1") {
      setSelectedTab("Notification");
      smsForm.resetFields();
      form.resetFields();
    } else if (value === "2") {
      setSelectedTab("SMS");
      notificationForm.resetFields();
      form.resetFields();
    } else if (value === "3") {
      setSelectedTab("WHATSAPP");
      smsForm.resetFields();
      notificationForm.resetFields();
    }
  };

  const onFinishWhatsapp = (data) => {
    console.log("success =====>>>>> ", data);
    setWhatsappSendBtnLoading(true);
    setSpinLoading(true);

    const nData = {
      user_id: user_id,
      whatsapp_temp_id: data?.whatsapp_temp_id,
      comment: data?.comment,
    };

    const endPoint = "stu-to-sal/send-whatsapp";
    getAPIData(endPoint, "post", nData, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          form.resetFields();
          setWhatsappSendBtnLoading(false);
          setSpinLoading(false);
          loadWhatsappListData();
        } else {
          message.warning(result?.message);
          setSpinLoading(false);
          setWhatsappSendBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setWhatsappSendBtnLoading(false);
        setSpinLoading(false);
      });
  };

  //SMS List Api
  const loadsmsListData = () => {
    setSmsTableLoading(true);
    const endPoint = `stu-to-sal/get-sent-sms-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          console.log(".then ⋙---------> response", response);
          if (!_.isEmpty(response) && _.isArray(response)) {
            setSmsData(response);
            setSmsTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setSmsTableLoading(false);
        }
        setSmsTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setSmsTableLoading(false);
      });
  };

  //whatsapp List Api
  const loadWhatsappListData = () => {
    setWhatsappTableLoading(true);
    const endPoint = `stu-to-sal/whatsapp-list/${user_id}`;
    getAPIData(endPoint, "get", {}, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true && !_.isEmpty(result?.data)) {
          var response = result?.data;
          if (!_.isEmpty(response) && _.isArray(response)) {
            setWhatsappData(response);
            setWhatsappTableLoading(false);
          }
        }
        if (result?.code === 500) {
          message.error(result?.message);
          setWhatsappTableLoading(false);
        }
        setWhatsappTableLoading(false);
      })
      .catch((err) => {
        message.error(err);
        setWhatsappTableLoading(false);
      });
  };

  
  

  const loadCurrentlyWorkingData = () => {
    setLoading(true);
    const endPoint = `stu-to-sal/user-working-data?user_id=${user_id}`;
    getAPIData(endPoint, "get", {}, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setCurrentlyWorkingData(response);
            setLoading(false);
            response?.bank?.bank_statment_images.map((data) => {
              setBankStatementImage(data);
              console.log("data=======>", data);
            });
          }
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };
  const loadWillContinueData = () => {
    setLoading(true);
    const endPoint = `stu-to-sal/user-continue-study-data?user_id=${user_id}`;
    getAPIData(endPoint, "get", {}, false, "stu_to_sal")
      .then((result) => {
        if (result?.status === true) {
          var response = result?.data;
          if (!_.isEmpty(response)) {
            setWillContinueStudyData(response);
            setLoading(false);
          }
        } else {
          message.warning(result?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };

  console.log("willcontinue==========>", willContinueStudyData);

  //Loading Pincodes
  const loadPincodes = () => {
    const endPoint = "internal-api/get-pincode";
    getAPIData(endPoint, "get", {}, false, "student")
      .then((result) => {
        if (result?.status === true) {
          var pincode_list = [];
          result?.data.map(async (pin, key) => {
            pincode_list.push({
              value: pin.id,
              label: pin.pincode,
              state: pin.state,
              district: pin.district,
            });
          });
          setPincodeList(pincode_list);
        } else {
          message.warning(result?.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return loading === true ? (
    <CClickLoading />
  ) : (
    <>
      <Spin spinning={spinLoading}>
        <div className={`${loading ? "page-loading app" : "app"}`}>
          {loading === true ? <CClickLoading /> : ""}
          <Layout
            style={{
              // height: "100%",
              padding: "20px 0 0 20px",
              backgroundColor: "#DFFEFC",
            }}
          >
            <Sidebar open={open} setOpen={setOpen} />
            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                  backgroundColor: "#009D93",
                  borderRadius: "15px 0 0 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <MenuOutlined
                  className="trigger"
                  onClick={() => setOpen(true)}
                />
                <Title level={4} className="poppins_regular umoney_page_title">
                  {type === "1"
                    ? "Currently Working"
                    : type === "2"
                    ? "Received Job Offer"
                    : type === "4"
                    ? "Will Continue Studying"
                    : ""}
                </Title>
              </Header>
              <Content
                className="site-layout-background"
                style={{
                  padding: 15,
                  backgroundColor: "#DFFEFC",
                }}
              >
                <div className="white_div">
                  <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xl={2} lg={4} md={6} sm={8}>
                      <img
                        src={user}
                        alt="User"
                        style={{ height: "100%", width: "75%" }}
                      />
                    </Col>
                    {type === "1" ? (
                      <Col
                        className="gutter-row"
                        xl={22}
                        lg={20}
                        md={18}
                        sm={16}
                      >
                        <Form
                          name="basic"
                          // onFinish={onFinishNotification}
                          // onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          className="umoney-form"
                          requiredMark={false}
                          form={topForm}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={5}>
                              <Form.Item
                                label="Name"
                                name="name"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Name is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                label="Phone number"
                                name="phone_number"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Phone number is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                label="Alternate number"
                                name="alternate_number"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Alternate number is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                label="Father's Name"
                                name="father_name"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Father name is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Mother's Name"
                                name="mother_name"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Mother name is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                label="Date of Birth"
                                name="date_of_birth"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Date of birth is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                label="Age"
                                name="age"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "Age is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                label="Gender"
                                name="gender"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "gender is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                label="Marital Status"
                                name="marital_status"
                                size="large"
                                rules={[
                                  {
                                    required: true,
                                    message: "marital status is required!",
                                  },
                                ]}
                                className="poppins_medium mb-35"
                              >
                                <Input
                                  size="large"
                                  type="text"
                                  label="Name"
                                  readOnly={true}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}></Col>
                            <Col span={2}></Col>
                          </Row>
                        </Form>
                      </Col>
                    ) : type === "4" ? (
                      <Col
                        className="gutter-row"
                        xl={22}
                        lg={20}
                        md={18}
                        sm={16}
                      >
                        <Row gutter={[16, 32]}>
                          <Col span={24}>
                            <Title
                              level={4}
                              className="poppins_semi umoney_title"
                            >
                              Approved sections:
                            </Title>
                          </Col>
                          <Col span={6}>
                            <CLoadingButton
                              size="large"
                              value="Resubmitted college details"
                              className="poppins_medium br"
                              block
                              style={{
                                background: "#3EA467",
                                color: "#fff",
                                height: "70px",
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ) : type === "2" ? (
                      <Col
                        className="gutter-row"
                        xl={22}
                        lg={20}
                        md={18}
                        sm={16}
                      >
                        <Row gutter={[16, 32]}>
                          <Col span={24}>
                            <Title
                              level={4}
                              className="poppins_semi umoney_title"
                            >
                              Approved sections:
                            </Title>
                          </Col>
                          <Col span={4}>
                            <CLoadingButton
                              size="large"
                              value="Job Information"
                              className="poppins_medium br"
                              block
                              style={{
                                background: "#3EA467",
                                color: "#fff",
                                height: "50px",
                              }}
                            />
                          </Col>
                          <Col span={4}>
                            <CLoadingButton
                              size="large"
                              value="Offer Letter"
                              className="poppins_medium br"
                              block
                              style={{
                                background: "#DE1717",
                                color: "#fff",
                                height: "50px",
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
                {type === "1" ? (
                  <div className="white_div mt-20">
                    <Row gutter={[16, 16]}>
                      <Col
                        className="gutter-row"
                        xl={24}
                        lg={20}
                        md={18}
                        sm={16}
                      >
                        <Row gutter={[16, 16]}>
                          <Col
                            className="gutter-row"
                            xl={12}
                            lg={12}
                            md={24}
                            sm={24}
                          >
                            <Title
                              level={4}
                              className="poppins_semi umoney_title"
                            >
                              Approved sections:
                            </Title>
                          </Col>
                          <Col className="gutter-row" span={10}></Col>
                          <Col
                            className="gutter-row"
                            xl={3}
                            lg={3}
                            md={4}
                            sm={6}
                          >
                            {/* <CLoadingButton
                              size="large"
                              value="Job Information"
                              className="poppins_medium br"
                              block
                              style={{
                                background: "#3EA467",
                                color: "#fff",
                                height: "70px",
                              }}
                            /> */}
                            <Tag
                              size="large"
                              className="poppins_medium br tag-style"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "70px",
                              }}
                            >
                              Job Information
                            </Tag>
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={3}
                            lg={3}
                            md={4}
                            sm={6}
                          >
                            <Tag
                              size="large"
                              className="poppins_medium br tag-style"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "70px",
                              }}
                            >
                              Offer Letter
                            </Tag>
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={3}
                            lg={3}
                            md={4}
                            sm={6}
                          >
                            <Tag
                              size="large"
                              className="poppins_medium br tag-style"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "70px",
                              }}
                            >
                              Bank Statement
                            </Tag>
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={3}
                            lg={3}
                            md={4}
                            sm={6}
                          >
                            <Tag
                              size="large"
                              className="poppins_medium br tag-style"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "70px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <p className="mt-20">Other Bank Statement</p>
                                <p>(Optional)</p>
                              </div>
                            </Tag>
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={4}
                            lg={4}
                            md={5}
                            sm={7}
                          >
                            <Tag
                              size="large"
                              className="poppins_medium br tag-style"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "70px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <p className="mt-20">Comapny ID Card</p>
                                <p>(Optional)</p>
                              </div>
                            </Tag>
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={4}
                            lg={4}
                            md={5}
                            sm={7}
                          >
                            <Tag
                              size="large"
                              className="poppins_medium br tag-style"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "70px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <p className="mt-20">Salary Slip</p>
                                <p>(Optional)</p>
                              </div>
                            </Tag>
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={4}
                            lg={4}
                            md={5}
                            sm={7}
                          >
                            <Tag
                              size="large"
                              className="poppins_medium br tag-style"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                height: "70px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <p className="mt-20">PAN Card</p>
                                <p>(Optional)</p>
                              </div>
                            </Tag>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}

                <div className="white_div mt-20">
                  <Title level={5} className="poppins_medium umoney_title">
                    Call Status
                  </Title>
                  {/* <CallStatusForm /> */}
                  <Form
                    name="basic"
                    onFinish={onFinishCallStatus}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="umoney-form"
                    requiredMark={false}
                    form={callStatusForm}
                  >
                    <Row gutter={[16, 16]}>
                      <Col
                        className="gutter-row"
                        xl={12}
                        lg={12}
                        md={8}
                        sm={6}
                      ></Col>
                      <Col
                        className="gutter-row"
                        xl={12}
                        lg={12}
                        md={16}
                        sm={18}
                      >
                        <Row gutter={[16, 16]}>
                          <Col
                            className="gutter-row"
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                          >
                            {" "}
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={10}
                            lg={10}
                            md={10}
                            sm={10}
                          >
                            <CLoadingButton
                              size="large"
                              value="CALL STATUS LOG"
                              className="poppins_medium umoney_outlined_btn1 br"
                              onClick={() => {
                                openModal("call-status-log");
                                // setModal(true);
                              }}
                              block
                            />
                          </Col>
                          <Col
                            className="gutter-row"
                            xl={10}
                            lg={10}
                            md={10}
                            sm={10}
                          >
                            <CLoadingButton
                              size="large"
                              value="VIEW CHANGE LOG"
                              className="poppins_medium umoney_outlined_btn1 br"
                              block
                              onClick={() => {
                                openModal("view-change-log");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Category"
                          name="category"
                          rules={[
                            {
                              required: true,
                              message: "Category is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={categoryData}
                            onChange={(e) => changeCategory(e)}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Sub Category"
                          name="sub_category"
                          rules={[
                            {
                              required: true,
                              message: "Sub Category is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            value={selectedSubCat}
                            size="large"
                            options={subCategoryData}
                            onChange={(e) => changeSubCategory(e)}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Sub Sub Category"
                          name="sub_sub_category"
                          rules={[
                            {
                              required: true,
                              message: "Sub sub Category is required!",
                            },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Select
                            size="large"
                            options={subSubCategoryData}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={5} lg={5} md={6} sm={12}>
                        <Form.Item
                          label="Comment"
                          name="comment"
                          rules={[
                            { required: true, message: "comment is required!" },
                          ]}
                          className="poppins_medium mb-35"
                        >
                          <Input size="large" type="text" />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xl={4} lg={4} md={6} sm={12}>
                        <Form.Item>
                          <CLoadingButton
                            size="large"
                            value="SUBMIT"
                            htmlType="submit"
                            loading={callStatusBtnLoading}
                            className="poppins_medium umoney_btn"
                            block
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="white_div mt-20">
                  <Row gutter={[16, 16]}>
                    <Col span={14}>
                      <Title level={5} className="poppins_medium umoney_title">
                        Send Notification or SMS or Whatsapp
                      </Title>
                    </Col>
                    <Col span={5}>
                      <CLoadingButton
                        size="large"
                        value="CALL HISTORY"
                        className="poppins_medium umoney_outlined_btn1"
                        block
                        onClick={() => openModal("call-history")}
                      />
                    </Col>
                    <Col span={5}>
                      <CLoadingButton
                        size="large"
                        value="CLICK TO CALL"
                        className="poppins_medium umoney_btn"
                        block
                        icon={<PhoneOutlined rotate={90} size="large" />}
                        onClick={() => openModal("click-to-call")}
                      />
                    </Col>
                  </Row>

                  <div className="tab-title">
                    <Row gutter={[16, 16]}>
                      <Col
                        className="gutter-row"
                        span={24}
                        style={{ paddingLeft: "-10px", paddingRight: "-10px" }}
                      >
                        <Tabs
                          defaultActiveKey="1"
                          type="card"
                          onTabClick={(value) => {
                            getTabValue(value);
                          }}
                        >
                          <Tabs.TabPane tab="NOTIFICATION" key="1">
                            <Row gutter={[16, 16]}>
                              <Col className="gutter-row" span={8}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    In app communication
                                  </Title>
                                  <Form
                                    name="basic"
                                    onFinish={onFinishNotification}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="umoney-form"
                                    requiredMark={false}
                                    form={notificationForm}
                                  >
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Module"
                                        name="module"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Module is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Select
                                          size="large"
                                          options={moduleOptions}
                                          onChange={(value) => {
                                            loadNotificationModuleData(value);
                                          }}
                                          placeholder="Select module"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Comment"
                                        name="comment"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Comment is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <TextArea
                                          size="large"
                                          type="text"
                                          rows={7}
                                          placeholder="Enter comment"
                                        />
                                      </Form.Item>
                                      <Form.Item name="notification_temp_id"></Form.Item>
                                    </div>
                                    <Form.Item>
                                      <CLoadingButton
                                        size="large"
                                        value="SEND"
                                        className="poppins_medium umoney_btn"
                                        block
                                        htmlType="submit"
                                        loading={notiSendBtnLoading}
                                      />
                                    </Form.Item>
                                  </Form>
                                </div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Row gutter={[16, 16]}>
                                    <Col span={20}>
                                      <Title
                                        level={5}
                                        className="poppins_medium umoney_title"
                                      >
                                        In app sent
                                      </Title>
                                    </Col>
                                    <Col span={4}>
                                      <CLoadingButton
                                        size="large"
                                        value="CLEAR IN APP"
                                        className="poppins_medium umoney_btn"
                                        onClick={() => {
                                          openModal("clear-in-app");
                                        }}
                                        block
                                      />
                                    </Col>
                                  </Row>
                                  <div className="umoney_table mt-10">
                                    <Table
                                      columns={notificationColumns}
                                      dataSource={notificationData}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 5,
                                        pageSizeOptions: [5, 10, 15, 20],
                                        locale: { items_per_page: "" },
                                      }}
                                      loading={notificationTableLoading}
                                      scroll={{ y: 200 }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tabs.TabPane>
                          <Tabs.TabPane tab="SMS" key="2">
                            <Row gutter={[16, 16]}>
                              <Col className="gutter-row" span={8}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                    //margin:"10px"
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    SMS templates
                                  </Title>
                                  <Form
                                    name="basic"
                                    onFinish={onFinishSms}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="umoney-form"
                                    requiredMark={false}
                                    form={smsForm}
                                  >
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Module"
                                        name="module"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Module is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Select
                                          size="large"
                                          options={moduleOptions}
                                          onChange={(val) => {
                                            loadNotificationModuleData(val);
                                          }}
                                          placeholder="Select module"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Comment"
                                        name="comment"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Comment is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <TextArea
                                          size="large"
                                          type="text"
                                          rows={7}
                                          placeholder="Enter comment"
                                        />
                                      </Form.Item>
                                      <Form.Item name="sms_temp_id" />
                                    </div>
                                    <Form.Item>
                                      <CLoadingButton
                                        size="large"
                                        value="SEND"
                                        className="poppins_medium umoney_btn"
                                        block
                                        htmlType="submit"
                                        loading={smsSendBtnLoading}
                                      />
                                    </Form.Item>
                                  </Form>
                                  {/* <CLoadingButton
                                  size="large"
                                  value="Send"
                                  className="poppins_medium umoney_btn mt-20"
                                  htmlType="submit"
                                  //loading={loading}
                                  block
                                /> */}
                                </div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    SMS sending history
                                  </Title>
                                  <div className="umoney_table mt-10">
                                    <Table
                                      columns={smscolumns}
                                      dataSource={smsData}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 5,
                                        pageSizeOptions: [5, 10, 15, 20],
                                        locale: { items_per_page: "" },
                                      }}
                                      loading={smsTableLoading}
                                      scroll={{ y: 200 }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tabs.TabPane>
                          <Tabs.TabPane tab="WHATSAPP" key="3">
                            <Row gutter={[16, 16]}>
                              <Col className="gutter-row" span={8}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                    //margin:"10px"
                                  }}
                                >
                                  <Form
                                    name="basic"
                                    onFinish={onFinishWhatsapp}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="umoney-form"
                                    requiredMark={false}
                                    form={form}
                                  >
                                    <Title
                                      level={5}
                                      className="poppins_medium umoney_title"
                                    >
                                      Whatsapp templates
                                    </Title>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Module"
                                        name="module"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Module is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <Select
                                          size="large"
                                          options={moduleOptions}
                                          onChange={(val) => {
                                            loadNotificationModuleData(val);
                                          }}
                                          placeholder="Select module"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="mt-20">
                                      <Form.Item
                                        label="Comment"
                                        name="comment"
                                        size="large"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Comment is required!",
                                          },
                                        ]}
                                        className="poppins_medium mb-35"
                                      >
                                        <TextArea
                                          size="large"
                                          type="text"
                                          rows={7}
                                          placeholder="Enter comment"
                                        />
                                      </Form.Item>
                                      <Form.Item name="whatsapp_temp_id" />
                                    </div>
                                    <CLoadingButton
                                      size="large"
                                      value="SEND"
                                      className="poppins_medium umoney_btn"
                                      htmlType="submit"
                                      loading={whatsappSendBtnLoading}
                                      block
                                    />
                                  </Form>
                                </div>
                              </Col>
                              <Col className="gutter-row" span={16}>
                                <div
                                  style={{
                                    border: "1px solid #009D93",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    height: "100%",
                                  }}
                                >
                                  <Title
                                    level={5}
                                    className="poppins_medium umoney_title"
                                  >
                                    Whatsapp sending history
                                  </Title>
                                  <div className="umoney_table mt-20">
                                    <Table
                                      columns={whatsappColumns}
                                      dataSource={whatsappData}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 5,
                                        pageSizeOptions: [5, 10, 15, 20],
                                        locale: { items_per_page: "" },
                                      }}
                                      loading={whatsappTableLoading}
                                      scroll={{ y: 200 }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tabs.TabPane>
                        </Tabs>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="white_div mt-20">
                  {type === "1" || type === "2" ? (
                    <Row gutter={[16, 16]}>
                      <Col className="gutter-row" span={6}>
                        <div className="border-class scroll_bar">
                          <Title
                            level={5}
                            className="poppins_medium umoney_title p-10"
                          >
                            Images
                          </Title>
                          {type === "1" ? (
                            <>
                              <OfferLetter
                                OfferLetter={currentlyWorkingData?.offerLetter}
                                user_id={user_id}
                                pageType={pageType}
                                isSuccess={(data) => {
                                  setChangeLoading(data);
                                }}
                              />
                              <BankStatement
                                bankImage={BankStatementImage?.path}
                                user_id={user_id}
                                pageType={pageType}
                                isSuccess={(data) => {
                                  setChangeLoading(data);
                                }}
                              />
                              <OtherBankStatement />
                              <CompanyIdCard
                                IdCard={currentlyWorkingData?.IDCard}
                                user_id={user_id}
                                pageType={pageType}
                                isSuccess={(data) => {
                                  setChangeLoading(data);
                                }}
                              />
                              <PANFront
                                PANFrontImage={
                                  currentlyWorkingData?.PAN?.front_img
                                }
                                user_id={user_id}
                                message={message}
                                isSuccess={(data) => {
                                  setChangeLoading(data);
                                }}
                              />
                              <SalarySlip
                                salarySlip={currentlyWorkingData?.pay_slip}
                                user_id={user_id}
                                pageType={pageType}
                                isSuccess={(data) => {
                                  setChangeLoading(data);
                                }}
                              />
                            </>
                          ) : (
                            ""
                          )}
                          {type === "2" ? <OfferLetter /> : ""}
                        </div>
                      </Col>
                      <Col className="gutter-row" span={18}>
                        <div className="border-class">
                          <div style={{ padding: "20px" }}>
                            <Row gutter={[16, 16]}>
                              <Col span={3}>
                                <Title
                                  level={5}
                                  className="poppins_medium color_title"
                                >
                                  <a
                                    style={{
                                      textDecoration: "underline",
                                      color: " #009D93",
                                    }}
                                    href="/student-to-salaried-resubmitted-logs"
                                  >
                                    Count {location?.state?.counts}
                                  </a>
                                </Title>
                              </Col>
                              <Col span={8}>
                                <Col span={24} className="poppins_medium">
                                  {userData?.AgentDetails?.AgentType}
                                </Col>
                                <Col span={24}>
                                  <Title
                                    level={5}
                                    className="poppins_medium color_title"
                                  >
                                    {userData?.AgentDetails?.AgentName}
                                  </Title>
                                </Col>
                              </Col>
                            </Row>
                          </div>
                          <div style={{ padding: "10px" }}>
                            <Title
                              level={4}
                              className="poppins_medium color_title"
                            >
                              Employment Details{" "}
                              {/* <Tag
                                style={{
                                  background: "#DE1717",
                                  color: "#fff",
                                  borderRadius: "3px",
                                  marginLeft: "10px",
                                }}
                              >
                                PENDING
                              </Tag> */}
                            </Title>
                          </div>
                          <Collapse accordion>
                            <Panel header="Company Information" key="3">
                              {/* <CompanyInformation /> */}
                              <CompanyInformation
                                pageType={pageType}
                                comapnyData={currentlyWorkingData?.ComapnyInfo}
                              />
                            </Panel>
                            <Panel header="Other Information" key="4">
                              <Form
                                name="basic"
                                // onFinish={onFinishCallStatus}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                className="umoney-form"
                                requiredMark={false}
                                form={callStatusForm}
                              >
                                <Row gutter={[16, 16]}>
                                  <Col className="gutter-row mt-10" span={8}>
                                    <Form.Item
                                      label="Company website"
                                      name="company_website"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message: "website is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Input
                                        size="large"
                                        defaultValue="hsdjgd"
                                        placeholder="Enter company website"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="gutter-row mt-10" span={8}>
                                    <Form.Item
                                      label="No. of employees"
                                      name="no_of_employees"
                                      size="large"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "number of employee is required!",
                                        },
                                      ]}
                                      className="poppins_medium mb-35"
                                    >
                                      <Input
                                        size="large"
                                        defaultValue="hsdjgd"
                                        placeholder="Enter no. of employees"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </Panel>
                            <Panel header="Salary Information" key="5">
                              <SalaryInformation />
                            </Panel>
                            {type === "1" ? (
                              <Panel
                                header="Salary credits Into Bank (First month or last month)"
                                key="6"
                              >
                                <SalaryCreditBank />
                              </Panel>
                            ) : (
                              ""
                            )}

                            <Panel header="Upload Files" key="7">
                              <UploadFiles
                                pageType={pageType}
                                user_id={user_id}
                              />
                            </Panel>
                          </Collapse>
                        </div>
                      </Col>
                    </Row>
                  ) : type === "4" ? (
                    <Row gutter={[16, 16]}>
                      <Col className="gutter-row" span={6}>
                        <div
                          className="border-class scroll_bar"
                          style={{ height: "435px" }}
                        >
                          <Title
                            level={5}
                            className="poppins_medium umoney_title p-10"
                          >
                            Images
                          </Title>
                          <StudentIdFrontOld
                            stdInfo={willContinueStudyData}
                            user_id={user_id}
                            pageType={pageType}
                            isSuccess={(data) => {
                              setChangeLoading(data);
                            }}
                          />
                          <StudentIdBackOld
                            stdInfo={willContinueStudyData}
                            user_id={user_id}
                            pageType={pageType}
                            isSuccess={(data) => {
                              setChangeLoading(data);
                            }}
                          />
                        </div>

                        <div
                          className="border-class scroll_bar"
                          style={{ height: "435px", marginTop: "75px" }}
                        >
                          <Title
                            level={5}
                            className="poppins_medium umoney_title p-10"
                          >
                            Images
                          </Title>
                          <div className="border-class m-10">
                            <StudentIdFront
                              studentIdFrontImage={
                                willContinueStudyData?.new_student_info?.id_card
                                  ?.front_img
                              }
                              user_id={user_id}
                              pageType={pageType}
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                            />
                            <StudentIdBack
                              studentIdBackImage={
                                willContinueStudyData?.new_student_info?.id_card
                                  ?.back_img
                              }
                              user_id={user_id}
                              pageType={pageType}
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col className="gutter-row" span={18}>
                        <Collapse accordion>
                          <Panel header="Current Graduation Details" key="1">
                            <GraduationDetails
                              stdInfo={willContinueStudyData}
                              pincodeList={pincodeList}
                              user_id={user_id}
                              loading={(data) => {
                                setSpinLoading(data);
                              }}
                              isSuccess={(data) => {
                                setChangeLoading(data);
                              }}
                            />
                          </Panel>
                        </Collapse>
                        {/* <div
                          className="border-class"
                          style={{ padding: "0px 10px" }}
                        >
                          <div className="border-class">
                            <div
                              style={{
                                paddingLeft: "10px",
                                paddingTop: "10px",
                                borderBottom: "1px solid #009D93",
                              }}
                            >
                              <Title
                                level={4}
                                className="Poppins_medium"
                                style={{ color: "#009D93" }}
                              >
                                Current Graduation Details
                              </Title>
                            </div>
                            <div style={{ padding: "20px" }}>
                              <GraduationDetails
                                stdInfo={willContinueStudyData}
                                pincodeList={pincodeList}
                                user_id={user_id}
                                loading={(data) => {
                                  setSpinLoading(data);
                                }}
                                isSuccess={(data) => {
                                  setChangeLoading(data);
                                }}
                              />
                            </div>
                          </div>
                        </div> */}

                        <div style={{ padding: "20px" }}>
                          <Row gutter={[16, 16]}>
                            <Col span={3}>
                              <Title
                                level={5}
                                className="poppins_medium color_title"
                              >
                                <a
                                  style={{
                                    textDecoration: "underline",
                                    color: " #009D93",
                                  }}
                                  href="/student-to-salaried-resubmitted-logs"
                                >
                                  Count {location?.state?.counts}
                                </a>
                              </Title>
                            </Col>
                            <Col span={4}>
                              <Col span={24} className="poppins_medium">
                                {userData?.AgentDetails?.AgentType}
                              </Col>
                              <Col span={24}>
                                <Title
                                  level={5}
                                  className="poppins_medium color_title"
                                >
                                  {userData?.AgentDetails?.AgentName}
                                </Title>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                        <div className="border-class">
                          <Collapse
                            accordion
                            style={{ backgroundColor: "white", border: "none" }}
                          >
                            <Panel
                              header="Resubmitted Graduation Details"
                              key="4"
                            >
                              <ResubmittedGraduationDetails
                                stdInfo={
                                  willContinueStudyData?.new_student_info
                                }
                                pincodeList={pincodeList}
                                user_id={user_id}
                                loading={(data) => {
                                  setSpinLoading(data);
                                }}
                                isSuccess={(data) => {
                                  setChangeLoading(data);
                                }}
                              />
                            </Panel>
                            <Panel header="Upload files" key="7">
                              <UploadFiles
                                pageType={pageType}
                                user_id={user_id}
                              />
                            </Panel>
                          </Collapse>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </Content>
            </Layout>
          </Layout>
          <Modal
            open={modalType}
            title={modalTitle}
            onCancel={handleClose}
            centered
            width={modalWidth}
            footer={false}
          >
            {modalType === "remove-image" ? (
              <RemoveImage />
            ) : modalType === "error" ? (
              <div>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={12}>
                    <Title level={5} className="poppins_regular umoney_title">
                      API call limit exceeded
                    </Title>
                  </Col>
                </Row>
              </div>
            ) : modalType === "clear-in-app" ? (
              <ClearInApp user_id={user_id} handleClose={handleClose} />
            ) : modalType === "call-history" ? (
              <CallHistory userType={pageType} user_id={user_id} />
            ) : modalType === "view-change-log" ? (
              <Viewchangelog
                // page={page}
                // setPage={setPage}
                // current={current}
                // setCurrent={setCurrent}
                user_id={user_id}
              />
            ) : modalType === "call-status-log" ? (
              <CallStatusLogModal
                user_id={user_id}
                // modal={modal}
                // setModal={setModal}
                // current={current}
                // setCurrent={setCurrent}
                // page={page}
                // setPage={setPage}
              />
            ) : modalType === "match-found" ? (
              <MatchFoundModal
              //  matchFoundData={matchFoundData}
              />
            ) : modalType === "click-to-call" ? (
              <ClickToCallModal
                user_id={user_id}
                type={type}
                // altNumber={altNumber}
                // regNumber={regNumber}
              />
            ) : modalType === "add-document" ? (
              <AddDocumentAndComment />
            ) : (
              ""
            )}
          </Modal>
        </div>
      </Spin>
    </>
  );
};

export default StudentToSalariedDetailView;
