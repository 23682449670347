import React, { useState } from "react";
import { Select, Row, Col } from "antd";
import CLoadingButton from "./CLoadingButton";

const CSelectBtn = (props) => {
  const { btnvalue } = props;

  return (
    <div className="umoney-selectBtn">
      <Row>
        <Col className="gutter-row" span={12}>
          <Select
            onChange={props?.onChange}
            options={props?.options}
            placeholder={props?.placeholder}
            size="large"
            style={{ width: "100%" }}
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <CLoadingButton
            size="large"
            value={btnvalue}
            className="poppins_medium m-0 urban_money_btn filter_btn_border"
            loading={props?.btnLoading}
            onClick={props?.onClick}
            block
          />
        </Col>
      </Row>
    </div>
  );
};

export default CSelectBtn;
