import React from "react";
import { Radio, Form, message, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CLoadingButton from "../../InputFields/CLoadingButton";
import { useState } from "react";
import { getAPIData } from "../../../apiHelper";
import moment from "moment";
import { useEffect } from "react";

const Remark = ({ userId, bankId, btnType, onSave = () => {} }) => {
  const user_id = userId;
  const bank_id = bankId;
  const action_button = btnType;

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [userId, bankId, btnType, onSave]);

  const onFinish = (data) => {
    setSaveBtnLoading(true);
    // data.followup_ts = moment(data?.follow_up?._d).format("YYYY-MM-DD");
    data.followup_ts = moment(data?.follow_up).format("YYYY-MM-DD HH:mm:ss");
    const dataRemark = { ...data, user_id, bank_id, action_button };
    const endPoint = "bank-agent/approve-reject-status";
    getAPIData(endPoint, "post", dataRemark, false, "bankAcc")
      .then((result) => {
        if (result?.status === true) {
          message.success(result?.message);
          setSaveBtnLoading(false);
          form.resetFields();
          onSave();
        } else {
          message.warning(result?.message);
          setSaveBtnLoading(false);
        }
      })
      .catch((err) => {
        message.error(err);
        setSaveBtnLoading(false);
      });
  };

  const [form] = Form.useForm();

  const onFinishFailed = (errors) => {
    console.log("errors =====>>>>> ", errors);
  };

  const [value, setValue] = useState("");
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <div>
      <div>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="umoney-form"
          requiredMark={false}
          form={form}
        >
          {btnType === "2" && (
            <Form.Item
              label="Select Date"
              name="follow_up"
              size="large"
              rules={[{ required: true, message: "date is required!" }]}
              className="poppins_medium mb-35"
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                showTime={true}
                onChange={(e) => console.log(e)}
                disabledDate={(current) =>
                  current.isBefore(moment().subtract(1, "day"))
                }
                placeholder="Select follow up date"
              ></DatePicker>
            </Form.Item>
          )}
          <Form.Item
            label="Enter Remarks"
            name="comment"
            size="large"
            rules={[{ required: true, message: "Remarks is required!" }]}
            className="poppins_medium mb-35 mt-20"
          >
            <TextArea rows={4} placeholder="Enter remarks" size="large" />
          </Form.Item>
          {btnType === "1" && (
            <Form.Item
              name="approve_reason"
              size="large"
              rules={[{ required: true, message: "Select reason" }]}
              className="poppins_medium mb-35"
            >
              <Radio.Group
                onChange={onChange}
                value={value}
                style={{ fontSize: 20, marginTop: 10, marginBottom: 10 }}
              >
                <Radio value={1} style={{ fontSize: 18, margin: 2 }}>
                  Username matched with account details
                </Radio>
                <Radio value={2} style={{ fontSize: 18, margin: 2 }}>
                  Username did not match with account details
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item>
            <CLoadingButton
              size="large"
              value=" SUBMIT"
              htmlType="submit"
              className="poppins_medium umoney_modal_filled_btn mt-20"
              block
              loading={saveBtnLoading}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Remark;
